import { FEATURES, SYSTEM_MANAGEMENT_FEATURES } from '@lcms-constants';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { LDAPSettingsComponent } from './ldap-settings/ldap-settings.component';
import { UsersComponent } from './users/users.component';

export const USER_ACCESS_CONSTANTS = {
  USERS: {
    ROUTE: 'users',
    LABEL: 'Users',
  },
  LDAP_SETTINGS: {
    ROUTE: 'ldap-settings',
    LABEL: 'LDAP Settings',
  },
};

export const USER_ACCESS_CHILDREN: ILayoutItem[] = [
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.USER_ACCESS.ROUTE,
    label: '',
    route: USER_ACCESS_CONSTANTS.USERS.ROUTE,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.USER_ACCESS.ROUTE,
    route: USER_ACCESS_CONSTANTS.USERS.ROUTE,
    label: USER_ACCESS_CONSTANTS.USERS.LABEL,
    component: UsersComponent,
    visible: false,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.USER_ACCESS.ROUTE,
    route: USER_ACCESS_CONSTANTS.LDAP_SETTINGS.ROUTE,
    label: USER_ACCESS_CONSTANTS.LDAP_SETTINGS.LABEL,
    component: LDAPSettingsComponent,
    visible: false,
    featureName: FEATURES.SYSTEM_MANAGEMENT,
    featureCheckField: SYSTEM_MANAGEMENT_FEATURES.AUTH_LDAP,
  },
];
