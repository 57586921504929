/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { IRuleService } from './IRuleService';
import { Observable, asyncScheduler, scheduled } from 'rxjs';
import rulesetsData from './mock-data/rulesets.json';
import rulesData from './mock-data/rules.json';

@Injectable()
export class MockRuleService implements IRuleService {
  rulesetTypes: string[] = ['IP', 'CAN', 'Command', 'YARA'];

  /**
   * ============================================ RULESET ============================================
   */

  getRulesets(projectId: any, deviceId: any, type: any): Observable<any> {
    const rulesets = ((rulesetsData?.rulesets as any[]) || []).map((p) => ({
      ...p,
      enabled: (this.rulesetTypes.indexOf(type) + 1) % 2 === 0,
      ruleset_type: type,
      id: this.rulesetTypes.indexOf(type) + 1,
    }));
    return scheduled([rulesets], asyncScheduler);
  }

  createRuleset(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateRuleset(rulesetId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteRuleset(rulesetId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  importRuleset(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  exportRuleset(rulesetId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  /**
   * ============================================ RULE ============================================
   */

  getRules(rulesetId: any): Observable<any> {
    const rules = ((rulesData?.rules as any[]) || []).filter((p) => p.rule_type === this.rulesetTypes[rulesetId - 1]);
    return scheduled([rules], asyncScheduler);
  }

  createRule(rulesetId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateRule(rulesetId: any, ruleId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteRule(rulesetId: any, ruleId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  /**
   * ============================================ DEVICE ============================================
   */

  assignDevice(rulesetId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  removeDevice(rulesetId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
