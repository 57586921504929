import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LDAPSettingService } from '@lcms-services';
import { RoleService } from '@microsec/services';
import { BaseComponent } from '@lcms-components';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { asyncScheduler, forkJoin, Observable, scheduled } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  PROJECT_ROLE: 'project_role',
};

@Component({
  selector: 'app-ldap-group-form',
  templateUrl: './ldap-group-form.component.html',
  styleUrls: ['./ldap-group-form.component.scss'],
})
export class LDAPGroupFormComponent extends BaseComponent implements OnInit {
  isLoading = false;

  FORM_PARAMS = FORM_PARAMS;

  cols: any[] = [
    { field: 'id', header: 'S/N', width: 4, frozen: true },
    { field: 'dn', header: 'Group DN', width: 18 },
    { field: 'selected', header: 'Select', width: 4 },
  ];

  values: any[] = [];

  roles: any[] = [];

  ldap = {
    enabled: false,
    allowGroupMapping: false,
    defaultOrganization: 0,
    isLoading: false,
  };

  form: FormGroup = this.fb.group({
    [FORM_PARAMS.PROJECT_ROLE]: [null, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    public dialogRef: DynamicDialogRef,
    private ldapSettingSrv: LDAPSettingService,
    private roleSrv: RoleService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.checkLDAPSetting().subscribe(() => {
      if (!this.ldap.enabled) {
        this.showInfoMessage('LDAP setting is not enabled, so you cannot proceed this action.');
        this.dialogRef.close(false);
      } else if (!this.ldap.allowGroupMapping) {
        this.showInfoMessage('LDAP setting is not allowing group mapping, so you cannot proceed this action.');
        this.dialogRef.close(false);
      } else if (this.ldap.defaultOrganization !== this.breadcrumbConfig?.organizationId) {
        this.showInfoMessage('LDAP setting is not allowing group mapping, so you cannot proceed this action.');
        this.dialogRef.close(false);
      } else {
        this.getLDAPGroups();
      }
    });
    this.roleSrv.getOrganizationRoles(this.breadcrumbConfig?.organizationId).subscribe({
      next: (rs: any[]) => {
        const roles = rs.map((role) => ({
          value: role.id,
          label: role.name,
          level: role.level,
        }));
        this.roles = this.util.sortObjectArray(roles, 'level', false);
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Submit form
   * @returns
   */
  onSubmit() {
    this.form.get(FORM_PARAMS.PROJECT_ROLE)?.markAsDirty();
    const selectedGroups = this.values.filter((p) => !!p.selected);
    if (!selectedGroups.length) {
      this.showErrorMessage('Please select at least 1 LDAP group.');
      return;
    }
    const formValue = { ...this.form.value };
    const requests: Observable<any>[] = [];
    selectedGroups.forEach((group) => {
      const payload = {
        [FORM_PARAMS.PROJECT_ROLE]: formValue[FORM_PARAMS.PROJECT_ROLE],
        organization: this.breadcrumbConfig?.organizationId,
        ldap_group: group.id,
      };
      requests.push(this.ldapSettingSrv.createLDAGroupLink(payload).pipe(catchError((err) => scheduled([{ error: err }], asyncScheduler))));
    });
    this.isLoading = true;

    forkJoin(requests).subscribe({
      next: (rs: any[]) => {
        const errorsList = rs.filter((p) => !!p.error);
        if (!errorsList.length) {
          this.showSuccessMessage('Link LDAP groups successfully');
        } else {
          rs.forEach((result: any, index: number) => {
            if (!!result.error) {
              this.showErrorMessage(`LDAP group link cannot be created for ${selectedGroups[index]?.dn}`);
            }
          });
        }
        this.dialogRef.close(true);
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Check LDAP Setting if it's enabled or not
   */
  checkLDAPSetting() {
    const obs = new Observable((observe) => {
      this.ldap.enabled = false;
      this.isLoading = true;
      this.ldapSettingSrv
        .getLDAPSetting()
        .pipe(
          finalize(() => {
            this.isLoading = false;
            observe.next();
          }),
        )
        .subscribe({
          next: (data: any) => {
            this.ldap.enabled = data.is_enabled;
            this.ldap.allowGroupMapping = data.is_allow_group_mapping;
            this.ldap.defaultOrganization = data.default_organization;
          },
          error: (err: any) => {
            this.showErrorMessage(err);
          },
        });
    });
    return obs;
  }

  /**
   * Get the LDAP Groups
   */
  getLDAPGroups() {
    this.ldap.isLoading = true;
    this.ldapSettingSrv
      .getLDAPGroups()
      .pipe(
        finalize(() => {
          this.ldap.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.values = (rs?.data as any[]).map((p) => ({
            ...p,
            selected: false,
          }));
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }
}
