import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { userCertificatesSelectors } from '@ngrx-user-certificates';
import { CommonOverviewComponent } from '@microsec/components';
import { BaseComponent } from '@lcms-components';
import { ChartHelper } from '@microsec/utilities';
import { CommonChart } from '@microsec/models';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { COMMON_DOUGHNUT_LAYOUT_OPTIONS, COMMON_LAYOUT_OPTIONS, DATA_BLOCKS, DIAGRAMS } from './overview.config';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  certificates: any[] = [];

  /**
   * Data blocks
   */
  blocks: any[] = [];

  /**
   * Chart data + options
   */
  charts: CommonChart[] = [];

  /**
   * Diagram constants
   */
  DIAGRAMS = DIAGRAMS;

  /**
   * Diagram UIs
   */
  @ViewChild('overviewComponent') overviewComponent!: CommonOverviewComponent;

  certIssuedFilterOption = '- ytd';

  certExpiringFilterOption = '- ytd';

  constructor() {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.subscriptions.push(
      this.store.select(userCertificatesSelectors.managers).subscribe((managers) => {
        const certs = managers.reduce((certs, manager) => [...certs, ...(manager?.certs || [])], []);
        if (!!this.checkPermissionsByScope(USER_ROLE.ADMIN, true)) {
          this.initData(certs);
        }
      }),
    );
  }

  /**
   * Initialize data
   * @param certificates
   */
  initData(certificates?: any[]) {
    this.certificates = certificates?.map((c) => ({ ...c, ca_name: c?.issuer?.CN })) || [];
    this.initTemplates();
    this.generateDataBlocks();
    this.charts.forEach((chart) => {
      this.updateChartData(chart);
    });
    this.redrawDiagrams();
  }

  /**
   * Redraw the diagram UIs
   */
  private redrawDiagrams(chart?: CommonChart) {
    if (!!this.overviewComponent.diagrams) {
      if (!chart) {
        this.overviewComponent.diagrams.forEach((diagram) => {
          setTimeout(() => {
            diagram.redraw();
          });
        });
      } else {
        const diagram = this.overviewComponent.diagrams.find((p) => p.chartConfig.key === chart.key);
        setTimeout(() => {
          diagram?.redraw();
        });
      }
    }
  }

  /**
   * Build up the security objects
   */
  private initTemplates() {
    const charts: any[] = [];
    Object.entries(DIAGRAMS).forEach(([key, value]) => {
      let chartOptions: ChartOptions | null = null;
      switch (value.TYPE) {
        case 'doughnut': {
          chartOptions = this.util.cloneDeepObject(COMMON_DOUGHNUT_LAYOUT_OPTIONS) as ChartOptions;
          break;
        }
        default: {
          chartOptions = this.util.cloneDeepObject(COMMON_LAYOUT_OPTIONS) as ChartOptions;
          break;
        }
      }
      const chart = this.util.cloneDeepObject(
        {
          type: value.TYPE,
          key,
          label: value.LABEL,
          data: {} as ChartData,
          options: chartOptions,
          children: !!value.CHILDREN.length ? this.util.cloneObjectArray(value.CHILDREN, true) : this.getDefaultChartChildren(key),
        },
        true,
      ) as CommonChart;
      if (chart.key === DIAGRAMS.CERTIFICATES_ISSUED.KEY || chart.key === DIAGRAMS.CERTIFICATES_EXPIRING.KEY) {
        chart.eventemitter = new BehaviorSubject(null);
        chart.eventsubscription = chart.eventemitter.asObservable();
      }
      charts.push(chart);
    });
    this.charts = charts;
    this.setupChangeOptionEvents();
  }

  private getDefaultChartChildren(chartKey: string) {
    const results = [];
    switch (chartKey) {
      // case DIAGRAMS.CERTIFICATES_BY_TYPE.KEY: {
      //   results.push(...ChartHelper.autoGenerateChildren(this.certificates, 'type'));
      //   break;
      // }
      case DIAGRAMS.CERTIFICATES_BY_STATUS.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.certificates, 'status'));
        break;
      }
      case DIAGRAMS.CERTIFICATES_BY_CA.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.certificates, 'ca_name'));
        break;
      }
      case DIAGRAMS.CERTIFICATES_BY_TEMPLATE.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.certificates, 'ca_template'));
        break;
      }
      // case DIAGRAMS.CERTIFICATES_BY_LABEL.KEY: {
      //   results.push(...ChartHelper.autoGenerateChildren(this.certificates, 'label'));
      //   break;
      // }
      case DIAGRAMS.KEY_ALGORITHM.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.certificates, 'subject_public_key_algorithm'));
        break;
      }
      case DIAGRAMS.SIGNATURE_ALGORITHM.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.certificates, 'signature_hash_algorithm'));
        break;
      }
      default: {
        break;
      }
    }
    return results;
  }

  /**
   * Replace the header text
   * @param text
   * @returns
   */
  private replaceHeader(chart: CommonChart) {
    if (!!chart.label) {
      switch (chart.key) {
        case DIAGRAMS.CERTIFICATES_ISSUED.KEY: {
          chart.label = chart.label.replace('{0}', new Date().getFullYear().toString());
          break;
        }
        case DIAGRAMS.CERTIFICATES_EXPIRING.KEY: {
          const labels = chart.data?.labels as string[];
          chart.label = chart.label.replace('{0}', `${labels[0]} - ${labels[labels.length - 1]}`);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  /**
   * Generate the data
   * @param chart chart
   * @param isColorKept should keep the colors if dynamic
   */
  updateChartData(chart: CommonChart) {
    const data = chart.data as ChartData;
    data.labels = [];
    data.datasets = [
      {
        data: this.generateData(chart),
        backgroundColor: [],
        borderWidth: [],
        barPercentage: 0.5,
      } as ChartDataset,
    ];
    this.generateLabels(chart, data);
    this.replaceHeader(chart);
  }

  /**
   * Generate the chart data
   * @param chart
   * @returns
   */
  private generateData(chart: CommonChart): number[] {
    const values: number[] = [];
    if (!!chart.key) {
      const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
      children?.forEach((item: any) => {
        switch (chart.key) {
          case DIAGRAMS.CERTIFICATES_ISSUED.KEY: {
            values.push(...ChartHelper.getChartDataByOption(this.certificates, 'not_before', this.certIssuedFilterOption));
            break;
          }
          case DIAGRAMS.CERTIFICATES_EXPIRING.KEY: {
            values.push(...ChartHelper.getChartDataByOption(this.certificates, 'not_after', this.certExpiringFilterOption));
            break;
          }
          // case DIAGRAMS.CERTIFICATES_BY_TYPE.KEY: {
          //   values.push(this.certificates.filter((p: any) => p.type === item.VALUE).length);
          //   break;
          // }
          case DIAGRAMS.CERTIFICATES_BY_STATUS.KEY: {
            values.push(this.certificates.filter((p: any) => p.status === item.VALUE).length);
            break;
          }
          case DIAGRAMS.CERTIFICATES_BY_CA.KEY: {
            values.push(this.certificates.filter((p: any) => p?.ca_name === item.VALUE).length);
            break;
          }
          case DIAGRAMS.CERTIFICATES_BY_TEMPLATE.KEY: {
            values.push(this.certificates.filter((p: any) => p.ca_template === item.VALUE).length);
            break;
          }
          // case DIAGRAMS.CERTIFICATES_BY_LABEL.KEY: {
          //   values.push(this.certificates.filter((p: any) => p.label === item.VALUE).length);
          //   break;
          // }
          case DIAGRAMS.KEY_ALGORITHM.KEY: {
            values.push(this.certificates.filter((p: any) => p.subject_public_key_algorithm === item.VALUE).length);
            break;
          }
          case DIAGRAMS.SIGNATURE_ALGORITHM.KEY: {
            values.push(this.certificates.filter((p: any) => p.signature_hash_algorithm === item.VALUE).length);
            break;
          }
          default: {
            break;
          }
        }
      });
    }
    return values;
  }

  /**
   * Generate the chart labels
   * @param chart
   * @param data
   */

  /**
   * Generate the chart labels
   * @param chart
   * @param data
   */
  private generateLabels(chart: CommonChart, data: ChartData) {
    switch (chart.type) {
      case 'doughnut': {
        if (!!chart.key) {
          const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
          children?.forEach((item: any) => {
            // Labels
            data.labels?.push(item.LABEL);
            const dataset = data.datasets.find((p) => !!p) as ChartDataset;
            // Colors
            if (!!dataset && !!dataset.backgroundColor) {
              (dataset.backgroundColor as string[]).push(item.COLOR);
              (dataset.borderWidth as number[]).push(0);
            }
          });
        }
        break;
      }
      default: {
        switch (chart.key) {
          case DIAGRAMS.CERTIFICATES_ISSUED.KEY: {
            data.labels?.push(...ChartHelper.getChartLabelsByOption(this.certIssuedFilterOption));
            break;
          }
          case DIAGRAMS.CERTIFICATES_EXPIRING.KEY: {
            data.labels?.push(...ChartHelper.getChartLabelsByOption(this.certExpiringFilterOption));
            break;
          }
          default: {
            break;
          }
        }
        if (!!chart.key) {
          (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any) => {
            const dataset = data.datasets.find((p) => !!p) as ChartDataset;
            if (!!dataset) {
              dataset.label = item.LABEL;
              dataset.borderColor = item.COLOR;
              dataset.borderColor = item.COLOR;
              dataset.backgroundColor = item.COLOR;
              dataset.borderWidth = 1;
            }
          });
        }
        break;
      }
    }
  }

  /**
   * Generate block data
   */
  private generateDataBlocks() {
    const blocks: any[] = [];
    Object.entries(this.util.cloneDeepObject(DATA_BLOCKS)).forEach(([key, entry]) => {
      let value = 0;
      switch (key) {
        case DATA_BLOCKS.TOTAL.KEY: {
          value = this.certificates.length;
          break;
        }
        case DATA_BLOCKS.EXPIRING_7D.KEY: {
          value = this.certificates.filter((c) => {
            // Filter expiring certificates in next 7 days
            const nowDate = moment();
            const next7DaysDate = moment().add(7, 'day');
            return nowDate.isSameOrBefore(moment(c.not_after)) && next7DaysDate.isSameOrAfter(moment(c.not_after));
          }).length;
          break;
        }
        case DATA_BLOCKS.EXPIRING_30D.KEY: {
          value = this.certificates.filter((c) => {
            // Filter expiring certificates in next 30 days
            const nowDate = moment();
            const next30DaysDate = moment().add(30, 'day');
            return nowDate.isSameOrBefore(moment(c.not_after)) && next30DaysDate.isSameOrAfter(moment(c.not_after));
          }).length;
          break;
        }
        case DATA_BLOCKS.EXPIRED.KEY: {
          value = this.certificates.filter((c) => {
            const nowDate = moment();
            return nowDate.isAfter(moment(c.not_after));
          }).length;
          break;
        }
        default: {
          break;
        }
      }
      blocks.push({
        value,
        label: (entry as any).LABEL,
        color: (entry as any).COLOR,
      });
    });
    this.blocks = blocks;
  }

  /**
   * Change filter events from the charts
   */
  setupChangeOptionEvents() {
    this.charts.forEach((chart) => {
      if (!!chart.eventemitter) {
        chart.eventsubscription?.subscribe((value) => {
          switch (chart.key) {
            case DIAGRAMS.CERTIFICATES_ISSUED.KEY: {
              this.certIssuedFilterOption = value;
              break;
            }
            case DIAGRAMS.CERTIFICATES_EXPIRING.KEY: {
              this.certExpiringFilterOption = value;
              break;
            }
            default: {
              break;
            }
          }
          this.updateChartData(chart);
          this.redrawDiagrams(chart);
        });
      }
    });
  }

  /**
   * Get legend label
   * @param chart
   * @param label
   * @returns
   */
  getLegendLabel(value: any, label: string) {
    return label;
  }
}
