import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { authenticationSelectors } from '@microsec/ngrx-authentication';
import { BaseComponent } from '@lcms-components';
import { CustomSocket } from '@microsec/interceptors';
import { BASE_API } from '@microsec/constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { Socket } from 'socket.io-client';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';

@Component({
  selector: 'app-terminal-console',
  templateUrl: './terminal-console.component.html',
  styleUrls: ['./terminal-console.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TerminalConsoleComponent extends BaseComponent implements OnInit, OnDestroy {
  term = new Terminal({
    cols: 87,
  });

  fitAddon = new FitAddon();

  container!: HTMLElement;

  cmd = '';

  socketURL = BASE_API;

  terminalEndpoint = '/terminal';

  socket!: Socket;

  termRunning = false;

  token!: string;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  async ngOnInit() {
    this.token = await firstValueFrom(this.store.select(authenticationSelectors.accessToken));
    this.socket = CustomSocket.getTerminalInstance({
      socketUrl: this.socketURL,
      containerName: this.dialogConfig.data.deviceName,
      path: this.terminalEndpoint,
      token: this.token,
    });
    this.term.loadAddon(this.fitAddon);
    this.container = document.getElementById('terminal') as HTMLElement;
    this.term.open(this.container);
    setTimeout(() => {
      this.fitAddon.fit();
    }, 200);
    this.socket.on('connect', () => {
      // 'G5p5...'
      this.term.write('\r\x1b[1;32mConnected\x1b[0m\r\n');
      if (!this.termRunning) {
        this.runTerminal();
        this.termRunning = true;
      }
    });
    this.socket.on('disconnect', () => {
      this.term.write('\r\n\x1b[1;31mConnection lost\x1b[0m\r\n');
    });
    this.socket.on('cmd_from_server', (data) => {
      window.microsec.log(data);
      this.term.write(data.output);
    });
  }

  override ngOnDestroy(): void {
    this.sendCmd('\nexit;\n');
    this.socket.close();
  }

  /**
   * Send command
   * @param cmd
   */
  async sendCmd(cmd: string) {
    this.socket.emit('cmd_from_client', {
      command: cmd,
      extraHeaders: CustomSocket.getTerminalHeader(this.token),
    });
  }

  /**
   * Run terminal
   */
  runTerminal() {
    this.term.options.cursorBlink = true;
    this.term.onKey((e: { key: string; domEvent: KeyboardEvent }) => {
      const ev = e.domEvent;
      window.microsec.log('pressed key', e.key);
      window.microsec.log('event', ev);
      this.sendCmd(e.key);
    });
  }
}
