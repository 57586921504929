import { faBook, faUpload } from '@fortawesome/free-solid-svg-icons';
import { GLOBAL_SETTINGS_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { DetailsComponent } from './details/details.component';
import { LICENSES_CONSTANTS } from './licenses.constants';
import { UploadComponent } from './upload/upload.component';
import { LicensesComponent } from './licenses.component';

export const LICENSES_CHILDREN: ILayoutItem[] = [
  {
    group: GLOBAL_SETTINGS_CONSTANTS.LICENSES.ROUTE,
    route: '',
    label: '',
    icon: faBook,
    component: LicensesComponent,
  },
  {
    group: GLOBAL_SETTINGS_CONSTANTS.LICENSES.ROUTE,
    route: LICENSES_CONSTANTS.DETAILS.ROUTE,
    label: LICENSES_CONSTANTS.DETAILS.LABEL,
    icon: faBook,
    component: DetailsComponent,
  },
  {
    group: GLOBAL_SETTINGS_CONSTANTS.LICENSES.ROUTE,
    route: LICENSES_CONSTANTS.UPLOAD.ROUTE,
    label: LICENSES_CONSTANTS.UPLOAD.LABEL,
    icon: faUpload,
    component: UploadComponent,
  },
];
