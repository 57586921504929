<div class="grid">
  @for (chart of charts; track $index) {
    <div class="col-12 lg:col-6 xl:col-4 chart-container">
      @switch (chart.key) {
        <!-- Device Management -->
        @case (DIAGRAMS.DEVICE_MANAGEMENT.KEY) {
          <microsec-card [isFull]="true">
            <div class="grid data-block">
              @for (block of chart.children; track $index) {
                <div [ngClass]="{ 'col-12': $index === 0, 'col-6': $index !== 0 }">
                  @if ($index === 0) {
                    <div class="font-bold">{{ chart.label }}</div>
                  }
                  <div class="data-block-1 flex align-items-center">
                    <div class="data-block-2 flex flex-column align-items-center">
                      <div class="align-self-center text-xl" [ngStyle]="{ color: block.color }">
                        {{ getLegendValue(chart, $index) }}
                      </div>
                      <div class="align-self-center text-base text-center mt-2">
                        {{ getLegendLabel(getLegendValue(chart, $index), block.label) }}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </microsec-card>
        }
        <!-- Device with Outdated Package -->
        @case (DIAGRAMS.DEVICES_PACKAGE.KEY) {
          <ng-container
            [ngTemplateOutlet]="customChart"
            [ngTemplateOutletContext]="{
              list: devicePackageList,
              chart: chart,
              hasData: !!packageDevices['devices_with_outdated_packages'] && !!packageDevices['devices_with_outdated_packages'].length
            }">
          </ng-container>
          <ng-template #devicePackageList>
            <div class="grid device-chart-list scrollable">
              @for (device of packageDevices['devices_with_outdated_packages']; track $index) {
                <div class="col-12">
                  {{ device }}
                </div>
              }
            </div>
          </ng-template>
        }
        <!-- Others -->
        @default {
          <microsec-card [isFull]="true">
            <microsec-common-chart
              #diagram
              [chartConfig]="chart"
              [legendTextCallback]="getLegendLabel"
              [isLoading]="false"
              [showLegendValues]="true" />
          </microsec-card>
        }
      }
    </div>
  }
</div>

<ng-template #customChart let-chart="chart" let-list="list" let-hasData="hasData">
  <microsec-card [isFull]="true">
    <div class="font-bold">{{ chart.label }}</div>
    @if (!!hasData) {
      <ng-container *ngTemplateOutlet="list" />
    } @else {
      <div class="space-area text-center">No data found</div>
    }
    <!-- Link -->
    @if (!!chart.link) {
      <div class="text-right">
        <label class="link" (click)="openLink(chart.link)"> {{ chart?.link?.label }}<i class="fas fa-angle-right ml-2"></i> </label>
      </div>
    }
  </microsec-card>
</ng-template>
