import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, map, Observable, throwError } from 'rxjs';
import { API } from '../api';
import { IUserCertificateService } from './IUserCertificateService';

const API_USERS = `${API.END_ENTITY_MANAGEMENT}/users`;

@Injectable({
  providedIn: 'root',
})
export class UserCertificateService implements IUserCertificateService {
  constructor(private http: HttpClient) {}

  getManagers(projectId: any): Observable<any> {
    return this.http.get(`${API_USERS}?project_id=${projectId}`).pipe(
      map((result: any) => {
        const userCertificates = ((result?.data as any[]) || []).map((uc) => {
          const certs = (uc?.certs as any[]) || [];
          certs.forEach((cert) => {
            cert.not_before = !!cert.not_before ? moment.utc(cert.not_before).local() : null;
            cert.not_after = !!cert.not_after ? moment.utc(cert.not_after).local() : null;
          });
          uc.certs = certs;
          return uc;
        });
        result.data = userCertificates;
        return result;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }
  updateManager(managerId: any, payload: any): Observable<any> {
    return this.http.put(`${API_USERS}/${managerId}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
  deleteManager(managerId: any): Observable<any> {
    return this.http.delete(`${API_USERS}/${managerId}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
  getManagerDetails(managerId: any): Observable<any> {
    return this.http.get(`${API_USERS}/${managerId}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
