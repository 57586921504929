import { COMMON_CHART_CONFIG } from '@microsec/constants';
import { ChartOptions } from 'chart.js';

export const DIAGRAMS = {
  NETWORK: {
    KEY: 'NETWORK',
    LABEL: 'Overall Network Usage',
    X_LABEL: 'Date/Time',
    Y_LABEL: 'Bytes',
    CHILDREN: [
      {
        KEY: 'bytes_recv',
        LABEL: 'Bytes Receiving',
        COLOR: '#00BD9B',
      },
      {
        KEY: 'bytes_sent',
        LABEL: 'Bytes Sending',
        COLOR: '#9089FA',
      },
      // {
      //   KEY: 'dropin',
      //   LABEL: 'Drop In',
      //   COLOR: '#00BD9B',
      // },
      // {
      //   KEY: 'dropout',
      //   LABEL: 'Drop Out',
      //   COLOR: '#9089FA',
      // },
      // {
      //   KEY: 'errin',
      //   LABEL: 'Error In',
      //   COLOR: '#00BD9B',
      // },
      // {
      //   KEY: 'errout',
      //   LABEL: 'Error Out',
      //   COLOR: '#9089FA',
      // },
      // {
      //   KEY: 'packets_recv',
      //   LABEL: 'Packets Receiving',
      //   COLOR: '#00BD9B',
      // },
      // {
      //   KEY: 'packets_sent',
      //   LABEL: 'Packets Sending',
      //   COLOR: '#9089FA',
      // },
    ],
  },
  CPU: {
    KEY: 'CPU',
    LABEL: 'Average CPU Utilization',
    X_LABEL: 'Date/Time',
    Y_LABEL: 'Percentage(%)',
    CHILDREN: [
      {
        LABEL: 'Average value',
        COLOR: '#00D5FF',
      },
    ],
  },
  RAM: {
    KEY: 'RAM',
    LABEL: 'Average RAM Utilization',
    X_LABEL: 'Date/Time',
    Y_LABEL: 'Percentage(%)',
    CHILDREN: [
      {
        LABEL: 'Average value',
        COLOR: '#DB4CB2',
      },
    ],
  },
  // FILE: {
  //   KEY: 'FILE',
  //   LABEL: 'Average File Operations',
  //   X_LABEL: '',
  //   Y_LABEL: '',
  //   CHILDREN: [
  //     {
  //       LABEL: 'Read',
  //       COLOR: '#00BD9B',
  //     },
  //     {
  //       LABEL: 'Write',
  //       COLOR: '#9089FA',
  //     },
  //   ],
  // },
  // VPN: {
  //   KEY: 'VPN',
  //   LABEL: 'Overall VPN Connection Status',
  //   X_LABEL: 'Date/Time',
  //   Y_LABEL: 'Status',
  //   CHILDREN: [
  //     {
  //       LABEL: 'Average value',
  //       COLOR: '#00BD9B',
  //     },
  //   ],
  // },
  // PROCESSES: {
  //   KEY: 'PROCESSES',
  //   LABEL: 'Average Processes',
  //   X_LABEL: '',
  //   Y_LABEL: '',
  //   CHILDREN: [
  //     {
  //       LABEL: 'Active',
  //       COLOR: '#00BD9B',
  //     },
  //     {
  //       LABEL: 'Idle',
  //       COLOR: '#9089FA',
  //     },
  //   ],
  // },
  // AUTHENTICATIONS: {
  //   KEY: 'AUTHENTICATIONS',
  //   LABEL: 'Average Authentications',
  //   X_LABEL: '',
  //   Y_LABEL: '',
  //   CHILDREN: [
  //     {
  //       LABEL: 'Average value',
  //       COLOR: '#FF5B4E',
  //     },
  //   ],
  // },
};

export const GRANULARITY_REALTIME_OPTIONS = [{ value: 1, label: '1 second' }];

export const ZOOM_OPTIONS = [
  { value: 15, label: '15 seconds' },
  { value: 30, label: '30 seconds' },
  { value: 60, label: '1 minute' },
];

export const COMMON_LAYOUT_OPTIONS: ChartOptions = {
  animation: {
    duration: 0,
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
    decimation: {
      enabled: true,
      algorithm: 'min-max',
    },
  },
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: '',
        color: 'yellow',
      },
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
    y: {
      title: {
        display: true,
        text: '',
        color: 'yellow',
      },
      beginAtZero: true,
      max: undefined,
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
  },
};
