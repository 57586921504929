@if (!!isPage) {
  <microsec-card>
    <div class="grid">
      <div class="col-12 xl:col-6 lg:col-8 md:col-10 sm:col-12">
        <ng-container [ngTemplateOutlet]="formContent" />
      </div>
    </div>
  </microsec-card>
} @else {
  <ng-container [ngTemplateOutlet]="formContent" />
}

<ng-template #formContent>
  <microsec-form-builder
    #fb
    [isPage]="!!isPage"
    [fields]="fields"
    [shouldShowActionButtons]="true"
    [submitButtonLabel]="!isPage && !isEditMode ? 'Submit' : 'Save changes'"
    [cancelButtonLabel]="!isPage ? 'Cancel' : ''"
    [leftActionArea]="!isPage ? null : leftActionArea"
    [additionalConditions]="!!validateProjectUsage()"
    (submitEvent)="onSubmit($event)" />

  <ng-template #leftActionArea>
    <p-button severity="secondary" styleClass="mr-2" type="button" (onClick)="getProjectAndInitForm()" label="Discard" />
  </ng-template>
</ng-template>
