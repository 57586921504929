<microsec-form-builder [isPage]="true" #fb [fields]="fields" [shouldShowActionButtons]="false" />
<ng-template #statusTemplate>
  <div class="flex align-items-center">
    <microsec-truncate-text class="text-value">
      {{ util.getUppercaseFirstLetter(selectedManager.connection_status) }}
    </microsec-truncate-text>
    <microsec-refresh-button (refreshEvent)="emitAction('refresh')" />
  </div>
</ng-template>

<!-- Tokens List -->
<div class="grid">
  <div class="col-12">
    <div class="flex align-items-center justify-content-between">
      <div class="font-bold text-data">Connected Tokens</div>
      <div class="flex align-items-center">
        <microsec-table-column-filter [key]="el.nativeElement.tagName" [cols]="allCols" [(selectedColFields)]="cols" />
      </div>
    </div>
  </div>
  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        [cols]="cols"
        [values]="values"
        [cellTemplate]="cellTemplate"
        [selectable]="false"
        [loading]="isLoading"
        itemName="Token" />
    </microsec-card>
  </div>
</div>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Status -->
    @case ('connection_status') {
      <microsec-truncate-text>
        {{ util.getUppercaseFirstLetter(rowData[col.field]) }}
      </microsec-truncate-text>
    }
    <!-- Login -->
    @case ('logged_in') {
      <microsec-truncate-text>
        {{ rowData[col.field] ? 'Logged In' : 'Logged Out' }}
      </microsec-truncate-text>
    }
    <!-- Actions -->
    @case ('actions') {
      @if (!rowData.logged_in) {
        <!-- Login -->
        <p-button
          severity="success"
          size="small"
          icon="fa fa-sign-in-alt"
          styleClass="mr-2"
          pTooltip="Login"
          tooltipPosition="bottom"
          appendTo="body"
          (onClick)="openCryptokenForm(rowData)" />
      } @else {
        <!-- Logout -->
        <p-button
          severity="danger"
          size="small"
          icon="fa fa-sign-out-alt"
          styleClass="mr-2"
          pTooltip="Logout"
          tooltipPosition="bottom"
          appendTo="body"
          (onClick)="confirmCryptokenLogout(rowData)" />
      }
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
  }
</ng-template>
