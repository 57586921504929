import { AfterViewInit, Component, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { CA_MODE_VALUES } from '@lcms-constants';
import { fromUserCertificatesActions } from '@ngrx-user-certificates';
import { CaManagementService, RaEnrollmentService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { BehaviorSubject, finalize, map, Observable } from 'rxjs';

const FORM_PARAMS = {
  CA_SERVER_ID: 'ca_server_id',
  CA_ID: 'ca_id',
  CA_TEMPLATE_ID: 'ca_template_id',
  CSR: 'csr',
  CSR_CONTENT: 'csr_content',
  /** --------------------------------------------------------------------- */
  ENTITY_TYPE: 'entity_type',
  IS_VIRTUAL: 'is_virtual',
  USES_OTP: 'uses_otp',
  TYPE: 'type',
  ENROLMENT_PROTOCOL: 'enrolment_protocol',
  IS_MANUAL: 'is_manual',
};

@Component({
  selector: 'app-user-certificate-csr-upload-form',
  templateUrl: './user-certificate-csr-upload-form.component.html',
  styleUrls: ['./user-certificate-csr-upload-form.component.scss'],
})
export class UserCertificateCsrUploadFormComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  isClicked = {
    [FORM_PARAMS.CA_SERVER_ID]: false,
    [FORM_PARAMS.CA_ID]: false,
  };

  constructor(
    private caManagementSrv: CaManagementService,
    private raEnrollmentSrv: RaEnrollmentService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initForm();
    setTimeout(() => {
      this.getCAConnections();
    }, 200);
  }

  /**
   * Init form
   */
  initForm() {
    // Setup CSR field
    const csrField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.CSR,
      label: 'Upload CSR',
      field: 'file',
      acceptedFileTypes: ['.pem', '.csr'],
      fieldInfo: 'Upload CSR',
      uploadEvent: new EventEmitter(),
      required: true,
    } as FormItem);
    csrField.uploadEvent?.subscribe((event) => {
      this.form.isLoading = true;
      this.getValidatedFile(event).subscribe((validatedFile: any) => {
        this.form.setControlValue(FORM_PARAMS.CSR, validatedFile);
        this.form.isLoading = false;
      });
    });
    // Setup other fields
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_SERVER_ID,
        label: 'CA Server',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA server',
        fieldInfo: 'CA server',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_ID,
        label: 'CA Certificate',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA Certificate',
        fieldInfo: 'CA Certificate',
        required: true,
        disabled: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_TEMPLATE_ID,
        label: 'CA Template',
        options: [] as any[],
        field: 'dropdown',
        placeholder: 'Select a CA template',
        fieldInfo: 'CA template',
        disabled: true,
        required: true,
      } as FormItem),
      csrField,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CSR_CONTENT,
        defaultValue: '',
        hidden: true,
      } as FormItem),
      /** --------------------------------------------------------------------- */
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENTITY_TYPE,
        defaultValue: 'user',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_VIRTUAL,
        defaultValue: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.USES_OTP,
        defaultValue: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENROLMENT_PROTOCOL,
        defaultValue: 'generic',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_MANUAL,
        defaultValue: true,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        defaultValue: 'enroll',
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;
    setTimeout(() => {
      this.setupEvents();
    }, 100);
  }

  /**
   * Setup general events
   */
  setupEvents() {
    // CA Server change event
    this.form.setChangeEvent(FORM_PARAMS.CA_SERVER_ID, (caConnectionId) => {
      if (!!caConnectionId) {
        this.getCAIntermediateCertificates(caConnectionId);
        // Reset values of CA, CA templates if CA server is clicked
        if (!!this.isClicked[FORM_PARAMS.CA_SERVER_ID]) {
          this.form.setControlValue(FORM_PARAMS.CA_ID, null);
          this.form.setControlValue(FORM_PARAMS.CA_TEMPLATE_ID, null);
        }
      }
    });
    // CA change event
    this.form.setChangeEvent(FORM_PARAMS.CA_ID, (caId) => {
      // Reset value of CA templates if CA is clicked
      if (!!this.isClicked[FORM_PARAMS.CA_ID]) {
        this.form.setControlValue(FORM_PARAMS.CA_TEMPLATE_ID, null);
      }

      const caField = this.fields.find((p) => p.name === FORM_PARAMS.CA_ID);
      const caTemplateField = this.fields.find((p) => p.name === FORM_PARAMS.CA_TEMPLATE_ID);
      if (!!caField && !!caTemplateField) {
        caTemplateField.options = (caField.options?.find((p) => p.id === caId)?.ca_templates as any[]) || [];
        if (!!caTemplateField.options.length) {
          this.form.enableControl(FORM_PARAMS.CA_TEMPLATE_ID);
        } else {
          this.form.setControlValue(FORM_PARAMS.CA_TEMPLATE_ID, null);
          this.form.disableControl(FORM_PARAMS.CA_TEMPLATE_ID);
        }
      }
    });
  }

  /**
   * Get the list of CA connections
   */
  getCAConnections() {
    this.form.isLoading = true;
    const subscription = this.caManagementSrv
      .getCAManagers(this.breadcrumbConfig?.projectId as number)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
          if (!!caServerField) {
            const caServers = this.util.sortObjectArray((rs?.data as any[]) || [], 'name').map((p) => ({
              ...p,
              value: p.id,
              label: p.name,
            }));
            caServerField.options = caServers;
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Get the list of CA intermediate certificates
   * @param caConnectionId
   */
  getCAIntermediateCertificates(caConnectionId: any) {
    const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
    const caField = this.fields.find((p) => p.name === FORM_PARAMS.CA_ID);
    if (!!caServerField && !!caField) {
      const cas: any[] = caServerField.options?.find((caServer) => caServer.value === caConnectionId)?.cas || [];
      const certs = cas
        .filter((p: any) => p.mode === CA_MODE_VALUES.X509)
        .filter((p: any) => (p.type as string).indexOf('intermediate') > -1)
        .map((p: any) => ({
          ...p,
          value: p.id,
          label: `${p.id}: ${p.subject?.CN} (${p.description})`,
          ca_templates: ((p.ca_templates as any[]) || []).map((t) => ({ ...t, value: t.id, label: t.name })),
        }));
      caField.options = this.util.sortObjectArray(certs, 'id');
      // Disable/enable CA certificate list
      if (!!caField.options.length) {
        this.form.enableControl(FORM_PARAMS.CA_ID);
      } else {
        this.form.disableControl(FORM_PARAMS.CA_ID);
        this.form.setControlValue(FORM_PARAMS.CA_TEMPLATE_ID, null);
        this.form.disableControl(FORM_PARAMS.CA_TEMPLATE_ID);
      }
    }
  }

  /**
   * Validate the file with correct format
   * @param event
   * @returns
   */
  private getValidatedFile(event: any) {
    return new Observable((observe) => {
      if (!!event.target && !!event.target.files && !!event.target.files.length) {
        const file = (event.target.files as FileList).item(0) as File;
        const reader = new FileReader();
        reader.onload = (loadedFile) => {
          this.form.setControlValue(FORM_PARAMS.CSR_CONTENT, loadedFile.target?.result);
          observe.next(file);
        };
        reader.readAsText(file);
      } else {
        observe.next(null);
      }
    });
  }

  /**
   * Submit data
   */
  onSubmit() {
    const payload = this.util.cloneDeepObject(this.form.getRawValue());
    payload[FORM_PARAMS.CSR] = this.form?.getControlValue(FORM_PARAMS.CSR_CONTENT);
    delete payload[FORM_PARAMS.CSR_CONTENT];
    this.raEnrollmentSrv
      .createUserCertificateEnrollmentRequest(payload)
      .pipe(
        map((result: any) => {
          if (!!result?.entity_id) {
            const complete$ = new BehaviorSubject(null);
            this.store.dispatch(new fromUserCertificatesActions.UpdateManager({ id: result.entity_id }, { status: 'Enrolled' }, complete$));
            this.subscriptions.push(
              complete$.subscribe((userCertificate: any) => {
                const certs = (userCertificate?.certs as any[]) || [];
                const latestCert = !!certs.length ? certs[certs.length - 1] : null;
                if (!!latestCert) {
                  const requestId = result?.transaction_id;
                  this.util.downloadFileFromText(latestCert?.pem, `user_certificate_${requestId}.pem`);
                }
              }),
            );
            return true;
          }
          return false;
        }),
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (result: any) => {
          if (!!result) {
            this.showSuccessMessage('User certificate has been successfully created');
          } else {
            this.showErrorMessage('Something went wrong while enrolling the user certificate');
          }
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
