import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { RULE_STRATEGIES } from '@lcms-constants';
import { RuleService } from '@lcms-services';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, CREATE_SUCCESS, NUMBER_MAX_LENGTH, SAVE_CHANGES_LABEL, UPDATE_SUCCESS } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, finalize } from 'rxjs';

const FORM_PARAMS = {
  NAME: 'name',
  COMMAND: 'command',
  Strategy: 'action',
  RULE_TYPE: 'rule_type',
  PROJECT_ID: 'project_id',
};

@Component({
  selector: 'app-command-rule-form',
  templateUrl: './command-rule-form.component.html',
  styleUrls: ['./command-rule-form.component.scss'],
})
export class CommandRuleFormComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  @Input() ruleset: any = null;

  @Input() rule: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  NUMBER_MAX_LENGTH = NUMBER_MAX_LENGTH;

  constructor(
    public dialogRef: DynamicDialogRef,
    public ruleSrv: RuleService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initForm();
    if (!!this.rule) {
      this.form.patchValue(this.rule);
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: 'Command name',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COMMAND,
        label: 'Trigger Command',
        field: 'input',
        required: true,
        fieldInfo:
          'Specify commands that will trigger this rule. Regular expressions can be used to create more advanced patterns for matching commands.',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.Strategy,
        label: 'Strategy',
        field: 'dropdown',
        options: this.util.cloneObjectArray(RULE_STRATEGIES),
        placeholder: 'Select a strategy',
        required: true,
        defaultValue: 0,
        fieldInfo: 'Action',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE_TYPE,
        hidden: true,
        defaultValue: this.ruleset?.ruleset_type,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        hidden: true,
        defaultValue: this.breadcrumbConfig?.projectId,
      } as FormItem),
    ];
    this.fields = fields;
  }

  /**
   * Submit the firewall form
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = this.form.getRawValue();
    const request: Observable<any> = !this.rule
      ? this.ruleSrv.createRule(this.ruleset?.id, payload)
      : this.ruleSrv.updateRule(this.ruleset?.id, this.rule?.rule_id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.rule ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'command rule'));
          closeDialog();
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
