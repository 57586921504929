import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from '@microsec/utilities';
import moment from 'moment';
import { catchError, forkJoin, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from '../api';
import { ICAService } from './ICAService';

const API_CAS = `${API.CTMS}/cas`;
const API_CA_ROLES = `${API.CTMS}/ca-roles`;
const API_CA_PERMISSIONS = `${API_CAS}/{0}/permissions`;
const API_CERT = `${API.CTMS}/certificates`;

@Injectable({
  providedIn: 'root',
})
export class CaService implements ICAService {
  cas: any[] = [];

  constructor(private http: HttpClient) {}

  /**
   * =====================================
   * CAs
   * =====================================
   */
  getCASchema() {
    return this.http.get<any>(`${API_CAS}/jsonschema`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getCAs(organizationId: number, projectId: number, type?: string, keyring_id?: any, key_id?: any) {
    let url = `${API_CAS}?organization_id=${organizationId}&project_id=${projectId}`;
    if (!!type) {
      url += `&type=${type}`;
    }
    if (!!keyring_id) {
      url += `&kms_keyring_id=${keyring_id}`;
    }
    if (!!key_id) {
      url += `&kms_key_id=${key_id}`;
    }
    return this.http.get<any>(url).pipe(
      map((rs: any) => {
        let cas = Util.sortObjectArray(rs.data || [], 'id');
        cas = Util.sortObjectArray(cas, 'subject.CN');
        cas.forEach((ca) => {
          ca.not_before = !!ca.not_before ? moment.utc(ca.not_before).local() : null;
          ca.not_after = !!ca.not_after ? moment.utc(ca.not_after).local() : null;
        });
        this.cas = cas;
        return this.cas;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  getCA(caId: number) {
    return this.http.get<any>(`${API_CAS}/${caId}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createCA(payload: any) {
    return this.http
      .post<any>(`${API_CAS}`, payload, {
        headers: new HttpHeaders({ timeout: `${300000}` }),
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createExternalCSR(payload: any) {
    const options: any = { responseType: 'blob' };
    return this.http
      .post<any>(`${API_CAS}/external-csr`, payload, {
        headers: new HttpHeaders({ timeout: `${300000}` }),
        ...options,
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createExternalCA(payload: any) {
    return this.http
      .post<any>(`${API_CAS}/external-enroll`, payload, {
        headers: new HttpHeaders({ timeout: `${300000}` }),
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  importCA(
    organizationId: any,
    projectId: any,
    caRoles: any[],
    root: { cert: any; key: any },
    intItems: { id: any; cert: any; key: any }[],
  ): Observable<any[]> {
    const requests: Observable<any>[] = [];
    if (!!caRoles.length) {
      caRoles.forEach((caRole) => {
        const payload = this.appendPayload(organizationId, projectId, root, intItems, caRole);
        requests.push(this.http.post<any>(`${API_CAS}/import`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error))));
      });
    } else {
      const payload = this.appendPayload(organizationId, projectId, root, intItems);
      requests.push(this.http.post<any>(`${API_CAS}/import`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error))));
    }
    return forkJoin(requests);
  }

  downloadCA(caId: number): Observable<any> {
    const options: any = { responseType: 'blob', observe: 'response' };
    return this.http.get<any>(`${API_CAS}/${caId}/zip`, options).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  downloadCRL(serialNumberNotHex: any, crlType: any): Observable<any> {
    const url = `${API.CTMS}/crl/${serialNumberNotHex}/${crlType}`;
    const options: any = { responseType: 'blob' };
    return this.http.get<any>(url, options).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  private appendPayload(
    organizationId: any,
    projectId: any,
    root: { cert: any; key: any },
    intItems: { id: any; cert: any; key: any }[],
    caRole?: any,
  ) {
    const payload = new FormData();
    payload.append('organization_id', organizationId);
    payload.append('project_id', projectId);
    if (!!caRole) {
      payload.append('ca_role_id', caRole);
    }
    // Keys
    payload.append('keys', root.key, root.key.name);
    intItems.forEach((intItem) => {
      payload.append('keys', intItem.key, intItem.key.name);
    });
    // Certs
    payload.append('certificates', root.cert, root.cert.name);
    intItems.forEach((intItem) => {
      payload.append('certificates', intItem.cert, intItem.cert.name);
    });
    return payload;
  }

  getCARoles(projectId: number, searchTerm?: string): Observable<any> {
    let url = `${API_CA_ROLES}?project_id=${projectId}`;
    if (!!searchTerm) {
      url += `&search=${searchTerm}`;
    }
    return this.http.get<any>(url).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createCARole(payload: any): Observable<any> {
    return this.http.post<any>(`${API_CA_ROLES}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateCARole(roleId: number, payload: any): Observable<any> {
    return this.http.put<any>(`${API_CA_ROLES}/${roleId}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteCARole(roleId: number): Observable<any> {
    return this.http.delete<any>(`${API_CA_ROLES}/${roleId}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * =====================================
   * CA PERMISSIONS
   * =====================================
   */

  setCAPermissionsEnabled(caId: any, value: boolean): Observable<any> {
    return this.http
      .put<any>(`${API_CA_PERMISSIONS.replace('{0}', caId)}/status`, {
        is_permission_enabled: value,
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getCAPermissionsList() {
    return this.http.get<any>(`${API_CAS}/permissions`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getCAPermissions(caId: any) {
    return this.http.get<any>(`${API_CA_PERMISSIONS.replace('{0}', caId)}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createCAPermission(caId: any, payload: any): Observable<any> {
    return this.http
      .post<any>(`${API_CA_PERMISSIONS.replace('{0}', caId)}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateCAPermission(caId: any, permissionId: number, payload: any): Observable<any> {
    return this.http
      .put<any>(`${API_CA_PERMISSIONS.replace('{0}', caId)}/${permissionId}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * =====================================
   * CRL SETTINGS
   * =====================================
   */

  getCRLSettings(caId: number): Observable<any> {
    return this.http.get<any>(`${API_CAS}/${caId}/crl_settings`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateCRLSettings(caId: number, payload: any): Observable<any> {
    return this.http.put<any>(`${API_CAS}/${caId}/crl_settings`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateManualCRL(caId: number): Observable<any> {
    return this.http.post<any>(`${API_CAS}/${caId}/crl_update`, {}).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * =====================================
   * PUBLISHING SETTINGS
   * =====================================
   */

  getPublishingSettings(caId: number): Observable<any> {
    return this.http.get<any>(`${API_CAS}/${caId}/publish_settings`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updatePublishingSettings(caId: number, payload: any): Observable<any> {
    return this.http.put<any>(`${API_CAS}/${caId}/publish_settings`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  publishCRL(caId: number): Observable<any> {
    return this.http.post<any>(`${API_CAS}/${caId}/publish_crl`, {}).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * =====================================
   * Generate Certificate using CSR
   * =====================================
   */
  createCSRCertificate(payload: any): Observable<any> {
    return this.http.post<any>(`${API_CERT}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
