@if (!isLoading) {
  <div class="flex flex-column">
    <div class="text-right mb-2">
      <microsec-refresh-button [lastRefresh]="summaryLastRefresh$ | async" (refreshEvent)="refresh()" />
    </div>
    <router-outlet />
  </div>
} @else {
  <microsec-loading-block />
}
