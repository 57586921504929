import { faTelevision, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FEATURES, PROJECT_MANAGEMENT_GROUPS, SYSTEM_MANAGEMENT_FEATURES } from '@lcms-constants';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { USER_ACCESS_CHILDREN } from '../shared-components/shared-user-access/shared-user-access.config';
import { WorkflowsComponent } from './overview/workflows/workflows.component';
import { UserAccessComponent } from './settings/user-access/user-access.component';
import { WORKFLOWS_CHILDREN } from '@microsec/module-workflows';

export const PROJECT_MANAGEMENT_CHILDREN: ILayoutItem[] = [
  {
    group: PROJECT_MANAGEMENT_GROUPS.OVERVIEW,
    route: PROJECT_MANAGEMENT_CONSTANTS.WORKFLOWS.ROUTE,
    icon: faTelevision,
    label: PROJECT_MANAGEMENT_CONSTANTS.WORKFLOWS.LABEL,
    component: WorkflowsComponent,
    children: WORKFLOWS_CHILDREN,
    featureName: FEATURES.SYSTEM_MANAGEMENT,
    featureCheckField: SYSTEM_MANAGEMENT_FEATURES.WORKFLOW,
    visible: false,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.SETTINGS,
    route: PROJECT_MANAGEMENT_CONSTANTS.USER_ACCESS.ROUTE,
    icon: faUsers,
    label: PROJECT_MANAGEMENT_CONSTANTS.USER_ACCESS.LABEL,
    component: UserAccessComponent,
    children: USER_ACCESS_CHILDREN,
  },
];
