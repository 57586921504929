import { Component, ElementRef, OnInit } from '@angular/core';
import { FIRMWARE_DEVICE_STATUS_VALUES, FIRMWARE_TARGET_DEVICE_VALUES } from '@lcms-constants';
import { devicesSelectors } from '@ngrx-devices';
import { DeviceService, FirmwareService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { PIPE_DATETIME } from '@microsec/constants';
import { finalize } from 'rxjs/operators';
import { FirmwarePushFormComponent } from '../../../firmwares/firmware-info/firmware-assigned-devices/firmware-push-form/firmware-push-form.component';
import { DeviceFirmwareAssignmentFormComponent } from './device-firmware-assignment-form/device-firmware-assignment-form.component';

@Component({
  selector: 'app-device-firmware',
  templateUrl: './device-firmware.component.html',
  styleUrls: ['./device-firmware.component.scss'],
})
export class DeviceFirmwareComponent extends BaseComponent implements OnInit {
  isLoading = false;

  _selectedDevice: any = null;

  get selectedDevice() {
    return this._selectedDevice;
  }

  set selectedDevice(value: any) {
    this._selectedDevice = value;
    this.getDeviceFirmwares();
  }
  cols: any[] = [
    { field: 'name', header: 'Firmware Device', width: 8, frozen: true },
    { field: 'device_version', header: 'Version', width: 6 },
    { field: 'device_status', header: 'Status', width: 6 },
  ];

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  values: any[] = [];

  FIRMWARE_DEVICE_STATUS_VALUES = FIRMWARE_DEVICE_STATUS_VALUES;

  PIPE_DATETIME = PIPE_DATETIME;

  constructor(
    public el: ElementRef,
    private deviceSrv: DeviceService,
    private firmwareSrv: FirmwareService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.store.select(devicesSelectors.selectedDevice).subscribe((selectedDevice) => {
      if (!!selectedDevice) {
        this.selectedDevice = selectedDevice;
      }
    });
  }

  /**
   * Get device's firmware
   */
  getDeviceFirmwares() {
    this.isLoading = true;
    this.firmwareSrv
      .getFirmwares('device_id', this.selectedDevice.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const currentDevice = ((rs?.assigned_devices as any[]) || [])?.find((p) => p.device_id === this.selectedDevice.id) || null;
          const result = {
            ...rs,
            device_status: currentDevice?.current_status,
            device_version: currentDevice?.current_version,
          };
          this.values = !!currentDevice ? [result] : [];
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Open firmware assignment form
   */
  openFirmwareAssignmentForm() {
    const dialog = this.dialogSrv.open(DeviceFirmwareAssignmentFormComponent, {
      header: 'Assign Firmware',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getDeviceFirmwares();
      }
    });
  }

  /**
   * Push firmware to device
   */
  pushToDevice() {
    const firmware = this.values?.[0];
    if (firmware?.target_device !== FIRMWARE_TARGET_DEVICE_VALUES.HIKVISION_CCTV) {
      if (!!this.fotaMQTTUrl) {
        this.deviceSrv.mqtt?.unsafePublish(this.fotaMQTTUrl, JSON.stringify({ action: 'push', firmware_id: firmware?.id }), {
          qos: 1,
          retain: false,
        });
        setTimeout(() => {
          this.showSuccessMessage(`Pushing to device ${this.selectedDevice.common_name}`);
          this.getDeviceFirmwares();
        }, 100);
      } else {
        this.showInfoMessage(`Device ${this.selectedDevice.common_name} has no valid certificate`);
      }
    } else {
      this.pushToHikvisionCCTVDevices(firmware, [this.selectedDevice]);
    }
  }

  pushToHikvisionCCTVDevices(firmware: any, devices?: any[]) {
    this.dialogSrv.open(FirmwarePushFormComponent, {
      data: { firmware, devices: devices?.map((d) => ({ ...d, device_id: d.id, name: d.common_name })) },
      header: 'Push Firmware',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }

  /**
   * Get base mqtt url
   */
  get fotaMQTTUrl() {
    const validCert = this.selectedDevice.certs?.find((cert: any) => cert.status === 'Valid');
    if (!!validCert) {
      const { issued_country } = validCert;
      const { issued_org } = validCert;
      const { issued_org_unit } = validCert;
      return `dev/${issued_country}/${issued_org}/${issued_org_unit}/${this.selectedDevice.common_name}/fota/push`;
    }
    return '';
  }
}
