<div class="grid">
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN] ? leftTemplate : null">
      <ng-template #leftTemplate>
        @if (!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN]) {
          <p-button
            severity="primary"
            [rounded]="true"
            label="Create Project"
            icon="pi pi-plus"
            (onClick)="openProjectForm()"
            [disabled]="!checkProjectQuota"
            [pTooltip]="!!checkProjectQuota ? '' : 'Maximum number of organizations reached'" />
        }
      </ng-template>
    </microsec-common-toolbar>
  </div>

  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [selectable]="false"
        itemName="Project"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getProjects()" />
    <microsec-table-column-filter [key]="constructor.name" [cols]="cols" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Status -->
    @case ('is_active') {
      <p-tag
        [value]="getLabelConfig(rowData[col.field]).label"
        [rounded]="true"
        [style]="{
          'color': getLabelConfig(rowData[col.field]).color,
          'background-color': getLabelConfig(rowData[col.field]).backgroundColor
        }"
        styleClass="status-tag select-none uppercase" />
    }
    <!-- Name -->
    @case ('name') {
      <microsec-truncate-text class="link" [tooltip]="rowData[col.field]" (click)="goToProject(rowData.id)">
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
    <!-- X509 Enabled -->
    @case ('x509Enabled') {
      <p-checkbox [binary]="true" [(ngModel)]="!!rowData.project_usage.acms" [disabled]="true" />
    }
    <!-- MicroPKI enabled -->
    @case ('microPKIEnabled') {
      <p-checkbox [binary]="true" [(ngModel)]="!!rowData.project_usage.micropki" [disabled]="true" />
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
