import { Action } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

export enum UserCertificatesActionTypes {
  INIT_MANAGERS = '[UserCertificates] Init Managers',

  GET_MANAGERS = '[UserCertificates] Get Managers',
  GET_MANAGERS_SUCCESS = '[UserCertificates] Get Managers Success',
  GET_MANAGERS_FAILURE = '[UserCertificates] Get Managers Failure',

  SELECT_MANAGER = '[UserCertificates] Select Manager',

  UPDATE_MANAGER = '[UserCertificates] Update Manager',
  UPDATE_MANAGER_SUCCESS = '[UserCertificates] Update Manager Success',
  UPDATE_MANAGER_FAILURE = '[UserCertificates] Update Manager Failure',

  DELETE_MANAGER = '[UserCertificates] Delete Manager',
  DELETE_MANAGER_SUCCESS = '[UserCertificates] Delete Manager Success',
  DELETE_MANAGER_FAILURE = '[UserCertificates] Delete Manager Failure',

  REFRESH_MANAGERS = '[UserCertificates] Refresh Managers',

  GET_MANAGER_DETAILS = '[UserCertificates] Get Manager Details',
  GET_MANAGER_DETAILS_SUCCESS = '[UserCertificates] Get Manager Details Success',
  GET_MANAGER_DETAILS_FAILURE = '[UserCertificates] Get Manager Details Failure',
}
// Init user certificate managers
class InitManagers implements Action {
  readonly type = UserCertificatesActionTypes.INIT_MANAGERS;
}

// Get all user certificate managers
class GetManagers implements Action {
  readonly type = UserCertificatesActionTypes.GET_MANAGERS;

  constructor(public projectId: any) {}
}

// On get all user certificate manager successfully
class OnGetManagersSuccess implements Action {
  readonly type = UserCertificatesActionTypes.GET_MANAGERS_SUCCESS;

  constructor(
    public projectId: any,
    public managers: any,
    public selectedManager?: any,
  ) {}
}

// On get user certificates manager failed
class OnGetManagersFailure implements Action {
  readonly type = UserCertificatesActionTypes.GET_MANAGERS_FAILURE;

  constructor(public error: any) {}
}

/** SELECT USER CERTIFICATE MANAGER */

// Select a user certificate manager
class SelectManager implements Action {
  readonly type = UserCertificatesActionTypes.SELECT_MANAGER;

  constructor(public selectedManager: any) {}
}

/** UPDATE USER CERTIFICATE MANAGER */

// Update a user certificate manager
class UpdateManager implements Action {
  readonly type = UserCertificatesActionTypes.UPDATE_MANAGER;

  constructor(
    public selectedManager: any,
    public payload: any,
    public successCallback: BehaviorSubject<any> | null = null,
  ) {}
}

// On update a user certificate manager successfully
class OnUpdateManagerSuccess implements Action {
  readonly type = UserCertificatesActionTypes.UPDATE_MANAGER_SUCCESS;

  constructor(
    public result: any,
    public message: string,
    public successCallback: BehaviorSubject<any> | null = null,
  ) {}
}

// On update a user certificate manager failed
class OnUpdateManagerFailure implements Action {
  readonly type = UserCertificatesActionTypes.UPDATE_MANAGER_FAILURE;

  constructor(public error: any) {}
}

/** DELETE USER CERTIFICATE MANAGER */

// Delete a user certificate manager
class DeleteManager implements Action {
  readonly type = UserCertificatesActionTypes.DELETE_MANAGER;

  constructor(public selectedManager: any) {}
}

// On delete a user certificate manager successfully
class OnDeleteManagerSuccess implements Action {
  readonly type = UserCertificatesActionTypes.DELETE_MANAGER_SUCCESS;

  constructor(public message: string) {}
}

// On delete a user certificate manager failed
class OnDeleteManagerFailure implements Action {
  readonly type = UserCertificatesActionTypes.DELETE_MANAGER_FAILURE;

  constructor(public error: any) {}
}

/** REFRESH USER CERTIFICATE MANAGERS */

// Refresh the list of user certificate managers
class RefreshManagers implements Action {
  readonly type = UserCertificatesActionTypes.REFRESH_MANAGERS;
}

/** USER CERTIFICATE DETAILS */

// Get all user certificate managers
class GetManagerDetails implements Action {
  readonly type = UserCertificatesActionTypes.GET_MANAGER_DETAILS;

  constructor(
    public organizationId: any,
    public projectId: any,
    public config: any,
  ) {}
}

// On get all user certificate manager successfully
class OnGetManagerDetailsSuccess implements Action {
  readonly type = UserCertificatesActionTypes.GET_MANAGER_DETAILS_SUCCESS;

  constructor(public manager?: any) {}
}

// On get user certificates manager failed
class OnGetManagerDetailsFailure implements Action {
  readonly type = UserCertificatesActionTypes.GET_MANAGER_DETAILS_FAILURE;

  constructor(public error: any) {}
}

export type UserCertificatesAction =
  | InitManagers
  | GetManagers
  | OnGetManagersSuccess
  | OnGetManagersFailure
  | SelectManager
  | UpdateManager
  | OnUpdateManagerSuccess
  | OnUpdateManagerFailure
  | DeleteManager
  | OnDeleteManagerSuccess
  | OnDeleteManagerFailure
  | RefreshManagers
  | GetManagerDetails
  | OnGetManagerDetailsSuccess
  | OnGetManagerDetailsFailure;

export const fromUserCertificatesActions = {
  InitManagers,
  GetManagers,
  OnGetManagersSuccess,
  OnGetManagersFailure,
  SelectManager,
  UpdateManager,
  OnUpdateManagerSuccess,
  OnUpdateManagerFailure,
  DeleteManager,
  OnDeleteManagerSuccess,
  OnDeleteManagerFailure,
  RefreshManagers,
  GetManagerDetails,
  OnGetManagerDetailsSuccess,
  OnGetManagerDetailsFailure,
};
