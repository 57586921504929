export const USER_CERTIFICATE_STATUSES = {
  NOT_ENROLLED: 'Not Enrolled',
  ENROLLED: 'Enrolled',
  BLOCKED: 'Blocked',
  RETIRED: 'Retired',
};

export const USER_CERTIFICATE_STATUS_TAGS = [
  {
    value: USER_CERTIFICATE_STATUSES.NOT_ENROLLED,
    label: USER_CERTIFICATE_STATUSES.NOT_ENROLLED,
    color: '#888888',
  },
  {
    value: USER_CERTIFICATE_STATUSES.ENROLLED,
    label: USER_CERTIFICATE_STATUSES.ENROLLED,
    color: '#19b775',
  },
  {
    value: USER_CERTIFICATE_STATUSES.BLOCKED,
    label: USER_CERTIFICATE_STATUSES.BLOCKED,
    color: '#c0292a',
  },
  {
    value: USER_CERTIFICATE_STATUSES.RETIRED,
    label: USER_CERTIFICATE_STATUSES.RETIRED,
    color: '#888888',
  },
];
