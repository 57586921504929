import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

const FORM_PARAMS = {
  PASSPHRASE: 'passphrase',
};

@Component({
  selector: 'app-gpg-key-export-form',
  templateUrl: './gpg-key-export-form.component.html',
  styleUrls: ['./gpg-key-export-form.component.scss'],
})
export class GpgKeyExportFormComponent extends BaseComponent implements AfterViewInit {
  keyring: any = null;

  key: any = null;

  fields: FormItem[] = [];

  FORM_PARAMS = FORM_PARAMS;

  @ViewChild('fb') form!: FormBuilderComponent;

  constructor(private dialogConfig: DynamicDialogConfig) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.keyring = this.dialogConfig?.data?.keyring;
    this.key = this.dialogConfig?.data?.key;
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: 'Export a key from KMS.',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PASSPHRASE,
        label: 'Passphrase',
        field: 'password',
        feedback: false,
        fieldInfo: 'Passphrase',
        required: true,
      } as FormItem),
    ];
    this.fields = fields;
  }

  /**
   * Submit the form
   * @param callback
   */
  onSubmit(callback: (rs: any) => void) {
    // const payload = this.util.cloneDeepObject(this.form.getRawValue());
    callback(null);
  }
}
