import { Injectable } from '@angular/core';
import { DeviceService } from '@lcms-services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Util } from '@microsec/utilities';
import { MessageService } from 'primeng/api';
import { asyncScheduler, catchError, map, mergeMap, Observable, scheduled, switchMap, takeUntil, withLatestFrom } from 'rxjs';

import { DevicesAction, DevicesActionTypes, fromDevicesActions } from './devices.actions';
import { devicesSelectors } from './devices.selectors';

@Injectable()
export class DevicesEffects {
  constructor(
    private readonly actions$: Actions<DevicesAction>,
    private readonly toastSrv: MessageService,
    private readonly deviceSrv: DeviceService,
    private readonly store: Store,
  ) {}

  // Get all devices
  getAllDevicesEffect$: Observable<DevicesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActionTypes.GET_ALL_DEVICES),
      withLatestFrom(this.store.select(devicesSelectors.selectedDevice)),
      switchMap(([action, selectedDevice]) => {
        const initialAction = action;
        return this.deviceSrv
          .getDevices(
            action.organizationId,
            action.projectId,
            action.filters,
            action.searchText,
            action.sortConfig,
            action.page,
            action.perPage,
            action.keyringId,
            action.keyId,
          )
          .pipe(
            takeUntil(this.actions$.pipe(ofType(DevicesActionTypes.CANCEL_GET_DEVICES_REQUEST))),
            map((data) => new fromDevicesActions.OnGetAllDevicesSuccess(initialAction, data, selectedDevice)),
            catchError((error) => scheduled([new fromDevicesActions.OnGetAllDevicesFailure({ error })], asyncScheduler)),
          );
      }),
    ),
  );

  // On get all devices failed
  onGetAllDevicesFailureEffect$: Observable<DevicesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActionTypes.GET_ALL_DEVICES_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Update the device
  updateDeviceEffect$: Observable<DevicesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActionTypes.UPDATE_DEVICE),
      switchMap((action) => {
        const selectedDevice = action.selectedDevice;
        return this.deviceSrv.updateDevice(action.selectedDevice?.id, action.payload).pipe(
          map(() => new fromDevicesActions.OnUpdateDeviceSuccess(`Updated device ${selectedDevice?.common_name} successfully`)),
          catchError((error) => scheduled([new fromDevicesActions.OnUpdateDeviceFailure({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // On update the device successfully
  onUpdateDeviceSuccessEffect$: Observable<DevicesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActionTypes.UPDATE_DEVICE_SUCCESS),
      switchMap((action) => {
        Util.showSuccessMessage(this.toastSrv, action.message);
        return scheduled([new fromDevicesActions.RefreshDevices()], asyncScheduler);
      }),
    ),
  );

  // On update device failed
  onUpdateDeviceFailureEffect$: Observable<DevicesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActionTypes.UPDATE_DEVICE_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Delete device
  deleteDeviceEffect$: Observable<DevicesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActionTypes.DELETE_DEVICE),
      switchMap((action) => {
        const selectedDevice = action.selectedDevice;
        return this.deviceSrv.removeDevice(action.selectedDevice?.id, action.isForced).pipe(
          map(() => new fromDevicesActions.OnDeleteDeviceSuccess(`Deleted device ${selectedDevice?.common_name} successfully`)),
          catchError((error) => scheduled([new fromDevicesActions.OnDeleteDeviceFailure({ error }, action.callback)], asyncScheduler)),
        );
      }),
    ),
  );

  // On delete device successfully
  onDeleteDeviceSuccessEffect$: Observable<DevicesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActionTypes.DELETE_DEVICE_SUCCESS),
      switchMap((action) => {
        Util.showSuccessMessage(this.toastSrv, action.message);
        return scheduled([new fromDevicesActions.RefreshDevices()], asyncScheduler);
      }),
    ),
  );

  // On delete device failed
  onDeleteDeviceFailureEffect$: Observable<DevicesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActionTypes.DELETE_DEVICE_FAILURE),
      mergeMap((action) => {
        if (!action.callback) {
          Util.showErrorMessage(this.toastSrv, action.error);
        } else {
          action.callback();
        }
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Refresh the list of devices
  refreshDevicesEffect$: Observable<DevicesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActionTypes.REFRESH_DEVICES),
      withLatestFrom(this.store.select(devicesSelectors.refreshConfig)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([action, refreshConfig]) =>
        scheduled(
          [
            new fromDevicesActions.GetAllDevices(
              refreshConfig.organizationId,
              refreshConfig.projectId,
              refreshConfig.filters,
              refreshConfig.searchText,
              refreshConfig.sortConfig,
              refreshConfig.page,
              refreshConfig.perPage,
              null,
              null,
            ),
          ],
          asyncScheduler,
        ),
      ),
    ),
  );

  // export devices
  exportDevicesEffect$: Observable<DevicesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActionTypes.EXPORT_DEVICES),
      switchMap((action) => {
        return this.deviceSrv.getAllDevices(action.organizationId, action.projectId, action.filters, action.searchText).pipe(
          takeUntil(this.actions$.pipe(ofType(DevicesActionTypes.CANCEL_EXPORT_DEVICES_REQUEST))),
          map((results: any[]) => {
            Util.showSuccessMessage(this.toastSrv, 'Export events successfully');
            return new fromDevicesActions.OnExportDevicesSuccess(results);
          }),
          catchError((error) => scheduled([new fromDevicesActions.OnExportDevicesFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get devices failed
  onExportDevicesFailureEffect$: Observable<DevicesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActionTypes.EXPORT_DEVICES_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );
}
