import { CommonTableComponent } from '@microsec/components';
import { ConfirmationDialogConfig, CommonToolbarResult, ActionMenuItem } from '@microsec/models';
import { DELETE_LABEL } from '@microsec/constants';
import { finalize, BehaviorSubject } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { MICROSEC_DEFAULT_KEYRING, PKI_MANAGEMENT_FEATURES } from '@lcms-constants';
import { KeyringFormComponent } from './keyring-form/keyring-form.component';
import { KmsService } from '@lcms-services';
import { ActivatedRoute, Params } from '@angular/router';

const FIELDS = {
  ID: 'ID',
  NAME: 'Name',
  TYPE: 'Type',
};

@Component({
  selector: 'app-kms',
  templateUrl: './kms.component.html',
  styleUrls: ['./kms.component.scss'],
})
export class KmsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols: any[] = [
    { field: 'id', header: FIELDS.ID, width: 2, frozen: true },
    { field: 'name', header: FIELDS.NAME, width: 10 },
    { field: 'type', header: FIELDS.TYPE, width: 5 },
  ];

  selectedKeyring: any = null;

  keyPurposeAlgorithm: any = null;

  values: any[] = [];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  actionMenuItems: ActionMenuItem[] = [];

  queryParams: Params | null = null;

  constructor(
    private route: ActivatedRoute,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
    if (!!this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.KMS)) {
      this.selectedColFields = (this.cols || []).map((col) => col.field);
      this.actionMenuItems = [
        {
          label: 'Edit',
          icon: 'fas fa-edit',
          visible: ({ rowData }: any) => !(rowData.name === MICROSEC_DEFAULT_KEYRING),
          command: ({ rowData }: any) => this.openKeyringForm(rowData),
        },
        {
          label: 'Delete',
          icon: 'fas fa-trash',
          visible: ({ rowData }: any) => !(rowData.name === MICROSEC_DEFAULT_KEYRING),
          command: ({ rowData }: any) => this.confirmDeletion(rowData),
        },
      ];
      this.getKeyrings();
      this.getKeyPurposeAlgorithms();
    } else {
      this.navigateToNotFoundPage();
    }
  }

  /**
   * Get Key Purpose Algorithms for Generic Keys
   */
  getKeyPurposeAlgorithms() {
    this.isLoading = true;
    this.kmsSrv
      .getKeyPurposeAlgorithm()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.keyPurposeAlgorithm = rs;
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Unselect keyring
   * @param event
   * @param datatable
   * @returns
   */
  unselectKeyring(event: any, datatable: any) {
    if (event?.target !== datatable) {
      return;
    }
    this.selectedKeyring = null;
  }

  /**
   * Get the list of keyrings
   */
  getKeyrings() {
    this.isLoading = true;
    this.kmsSrv
      .getKeyrings(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.values = (rs?.data as any[]) || [];
          let selectedKeyring: any = null;
          if (!!this.queryParams?.['keyring_id']) {
            let id = null;
            try {
              id = Number.parseInt(this.queryParams['keyring_id']);
            } catch {
              id = null;
            }
            selectedKeyring = { id };
            this.router.navigate([], { queryParams: { keyring_id: null }, queryParamsHandling: 'merge' });
          }
          if (!!selectedKeyring) {
            let selectedKeyringIndex = -1;
            selectedKeyring = this.values.find((p, index) => {
              if (p.id === this.selectedKeyring?.id || p.id === selectedKeyring?.id) {
                selectedKeyringIndex = index;
                return true;
              }
              return false;
            });
            if (selectedKeyringIndex >= 0) {
              this.values.splice(selectedKeyringIndex, 1);
              this.values.unshift(selectedKeyring);
              this.selectedKeyring = selectedKeyring;
            }
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Open keyring form for create/edit
   * @param keyring
   */
  openKeyringForm(keyring: any = null) {
    const dialogRef = this.dialogSrv.open(KeyringFormComponent, {
      data: { keyring },
      header: `${!keyring ? 'Create' : 'Edit'} Keyring`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        this.getKeyrings();
      }
    });
  }

  /**
   * Confirm keyring deletion
   * @param keyring
   */
  confirmDeletion(keyring: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Keyring',
      object: keyring,
      objectFieldName: 'name',
      acceptRequest: this.kmsSrv.deleteKeyring(keyring.id),
      next: () => {
        this.showSuccessMessage(`Deleted keyring ${keyring.name} successfully`);
        this.selectedKeyring = null;
        this.getKeyrings();
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }
}
