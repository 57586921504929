import { AfterViewInit, Component, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import {
  CA_MODE_VALUES,
  CERTIFICATE_FORMATS,
  ENROLLMENT_TYPES,
  FILE_FORMATS,
  KEYRING_TYPE_VALUES,
  MICROSEC_DEFAULT_KEYRING,
  PKI_MANAGEMENT_FEATURES,
  PRIVATE_KEY_FORMATS,
  PROJECT_MANAGEMENT_CONSTANTS,
} from '@lcms-constants';
import { CaManagementService, KmsService, RaEnrollmentService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormItem } from '@microsec/models';
import { ORGANIZATION_LEVEL_ROUTE, PROJECT_LEVEL_ROUTE, VALIDATOR_TYPE } from '@microsec/constants';
import { FormBuilderComponent } from '@microsec/components';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { finalize, Observable } from 'rxjs';
import { PROJECT_SETTINGS_CONSTANTS } from '@lcms-products';

const FORM_PARAMS = {
  CA_SERVER_ID: 'ca_server_id',
  CA_ID: 'ca_id',
  TEMPLATE_ID: 'template_id',
  KEYRING_ID: 'keyring_id',
  /** --------------------------------------------------------------------- */
  SERVER_KEYRING_ID: 'server_keyring_id',
  SERVER_KEYGEN: 'server_keygen',
  PRIVATE_KEY_FORMAT: 'private_key_format',
  /** --------------------------------------------------------------------- */
  UPLOAD_KEYRING_ID: 'upload_keyring_id',
  UPLOAD_KEY: 'upload_key',
  PRIVATE_KEY: 'private_key',
  FILE_PRIVATE_KEY: 'file_private_key',
  PASSWORD: 'password',
  /** --------------------------------------------------------------------- */
  FORMAT: 'format',
  FILE_CSR: 'file_csr',
  CSR: 'csr',
  /** --------------------------------------------------------------------- */
  ENROLMENT_PROTOCOL: 'enrolment_protocol',
  IS_VIRTUAL: 'is_virtual',
  IS_MANUAL: 'is_manual',
  USES_OTP: 'uses_otp',
  TYPE: 'type',
};

@Component({
  selector: 'app-device-csr-upload-form',
  templateUrl: './device-csr-upload-form.component.html',
  styleUrls: ['./device-csr-upload-form.component.scss'],
})
export class DeviceCsrUploadFormComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  constructor(
    private caManagementSrv: CaManagementService,
    private raEnrollmentSrv: RaEnrollmentService,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initForm();
    this.getKeyrings();
  }

  /**
   * Initialize the form
   */
  initForm() {
    const serverKeyringField = this.createKeyringField(FORM_PARAMS.SERVER_KEYRING_ID);
    const uploadKeyringField = this.createKeyringField(FORM_PARAMS.UPLOAD_KEYRING_ID, true);

    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_SERVER_ID,
        label: 'CA Server',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA server',
        fieldInfo: 'CA server',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_ID,
        label: 'CA Certificate',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA certificate',
        fieldInfo: 'CA certificate',
        required: true,
        disabled: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TEMPLATE_ID,
        label: 'CA Template',
        options: [] as any[],
        field: 'dropdown',
        required: true,
        placeholder: 'Select a CA template',
        fieldInfo: 'CA template',
        disabled: true,
      } as FormItem),
      /** ---------------------------Server-side Key Generation---------------------------------- */
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SERVER_KEYGEN,
        label: 'Generate Key in Server',
        field: 'checkbox',
        fieldInfo: 'Enable to generate and store private key in server allowing auto key server recovery',
        defaultValue: false,
        hidden: !this.isKMSFeatured,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PRIVATE_KEY_FORMAT,
        label: 'Private Key Format',
        field: 'dropdown',
        options: this.util.cloneObjectArray(PRIVATE_KEY_FORMATS),
        placeholder: 'Select a private key format',
        fieldInfo: 'Private key format',
        defaultValue: FILE_FORMATS.PKCS1,
        hidden: true,
      } as FormItem),
      serverKeyringField,
      /** ---------------------------Upload Key to Server---------------------------------- */
      Object.assign(new FormItem(), {
        field: 'divider',
        hidden: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.UPLOAD_KEY,
        label: 'Upload Private Key to Server',
        field: 'checkbox',
        fieldInfo: 'Enable to upload local private key to server',
        disabled: false,
        hidden: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FILE_PRIVATE_KEY,
        label: 'Private Key',
        field: 'file',
        fieldInfo: 'Upload private key',
        hidden: true,
        uploadEvent: new EventEmitter(),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PRIVATE_KEY,
        defaultValue: '',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PASSWORD,
        label: 'Password',
        field: 'password',
        feedback: false,
        fieldInfo: 'Private key encryption password',
        hidden: true,
        disabled: true,
      } as FormItem),
      uploadKeyringField,
      /** --------------------------------------------------------------------- */
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FORMAT,
        label: 'Certificate Format',
        field: 'dropdown',
        options: this.util.cloneObjectArray(CERTIFICATE_FORMATS),
        placeholder: 'Select a certificate format',
        fieldInfo: 'Available certificate format: PEM or PKCS7',
        required: true,
        defaultValue: FILE_FORMATS.PEM,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FILE_CSR,
        label: 'Upload CSR',
        field: 'file',
        acceptedFileTypes: ['.pem', '.csr'],
        fieldInfo: 'Upload CSR to generate certificate',
        uploadEvent: new EventEmitter(),
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CSR,
        defaultValue: '',
        hidden: true,
      } as FormItem),
      /** --------------------------------------------------------------------- */
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENROLMENT_PROTOCOL,
        defaultValue: 'generic',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_VIRTUAL,
        defaultValue: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_MANUAL,
        defaultValue: true,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.USES_OTP,
        defaultValue: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        defaultValue: ENROLLMENT_TYPES.ENROLL,
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;

    // Subscribe to Keyring refresh

    serverKeyringField.refreshOptionsEvent?.subscribe(() => {
      this.getKeyrings();
    });

    uploadKeyringField.refreshOptionsEvent?.subscribe(() => {
      this.getKeyrings();
    });

    setTimeout(() => {
      // Setup csr file upload event
      this.setupCSRFileUploadEvent();
      // Setup private key file upload event
      this.setupPrivateKeyFileUploadEvent();
      // Setup change events
      this.setupEvents();
      setTimeout(() => {
        this.getCAConnections();
      });
    }, 100);
  }

  /**
   * Set keyring dropdown options based on the kms keyring response
   */
  private setKeyringOptions(field: any, keyrings: any[]) {
    field.options = this.util.cloneObjectArray(keyrings);
    if (!field.options.find((p: any) => p.value === this.form.getControlValue(field.name))) {
      this.form.setControlValue(field.name, null);
    }
  }

  /**
   * Retrive all the keyrings in KMS
   */
  getKeyrings() {
    this.form.isLoading = true;
    this.kmsSrv
      .getKeyrings(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const keyringOptions = this.util.sortObjectArray(
            (
              ((rs.data as any[]) || []).filter(
                (keyring) => keyring.type === KEYRING_TYPE_VALUES.FILESYSTEM && keyring.name !== MICROSEC_DEFAULT_KEYRING,
              ) as any[]
            ).map((p: any) => ({
              value: p.id,
              label: p.name,
            })),
            'label',
          );
          const serverKeyringField = this.fields.find((field) => field.name === FORM_PARAMS.SERVER_KEYRING_ID);
          const uploadKeyringField = this.fields.find((field) => field.name === FORM_PARAMS.UPLOAD_KEYRING_ID);
          if (serverKeyringField) {
            this.setKeyringOptions(serverKeyringField, keyringOptions);
          }
          if (uploadKeyringField) {
            this.setKeyringOptions(uploadKeyringField, keyringOptions);
          }
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Setup CSR file upload event
   */
  setupCSRFileUploadEvent() {
    const fileField = this.fields.find((p) => p.name === FORM_PARAMS.FILE_CSR);
    if (!!fileField) {
      fileField.uploadEvent?.subscribe((event) => {
        this.form.isLoading = true;
        this.getValidatedFile(event, FORM_PARAMS.FILE_CSR, FORM_PARAMS.CSR).subscribe((validatedFile: any) => {
          this.form.patchValue(validatedFile);
          this.form.isLoading = false;
        });
      });
    }
  }

  /**
   * Setup private key file upload event
   */
  setupPrivateKeyFileUploadEvent() {
    const fileField = this.fields.find((p) => p.name === FORM_PARAMS.FILE_PRIVATE_KEY);
    if (!!fileField) {
      fileField.uploadEvent?.subscribe((event) => {
        this.form.isLoading = true;
        this.getValidatedFile(event, FORM_PARAMS.FILE_PRIVATE_KEY, FORM_PARAMS.PRIVATE_KEY).subscribe((validatedFile: any) => {
          this.form.patchValue(validatedFile);
          this.form.isLoading = false;
        });
      });
    }
  }

  /**
   * Validate the file with correct format
   * @param event
   * @param formFile
   * @param formInput
   * @returns
   */
  private getValidatedFile(event: any, formFile: string, formInput: string) {
    return new Observable((observe) => {
      if (!!event.target && !!event.target.files && !!event.target.files.length) {
        const file = (event.target.files as FileList).item(0) as File;
        const reader = new FileReader();
        reader.onload = () => {
          observe.next({
            [formFile]: file,
            [formInput]: reader.result?.toString(),
          });
        };
        reader.readAsText(file);
      } else {
        observe.next({
          [formFile]: null,
          [formInput]: '',
        });
      }
    });
  }

  /**
   * Submit form data
   */
  onSubmitCSRForm() {
    const csrValue = { ...this.form.getRawValue() };
    const payload = this.getPurePayload({ ...csrValue });
    this.form.isLoading = true;
    this.raEnrollmentSrv
      .createCSRCertificate(
        csrValue[FORM_PARAMS.CA_SERVER_ID],
        csrValue[FORM_PARAMS.CA_ID],
        csrValue[FORM_PARAMS.TEMPLATE_ID],
        csrValue[FORM_PARAMS.FORMAT],
        payload,
      )
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (response) => {
          window.microsec.log('CSR result: ', response);
          if (!!response) {
            if (!!response.private_key) {
              const certFile = {
                content: response.certificate,
                filename: `certificate.${FILE_FORMATS.PEM}`,
              };
              const keyFile = {
                content: response.private_key,
                filename: `private_key.${FILE_FORMATS.PEM}`,
              };
              const zip = new JSZip();
              [certFile, keyFile].forEach((file) => {
                zip.file(file.filename, file.content);
              });
              zip.generateAsync({ type: 'blob' }).then((content) => {
                FileSaver.saveAs(content, 'certificate_private_key.zip');
              });
            } else {
              this.util.downloadFileFromText(response.certificate, `certificate.${FILE_FORMATS.PEM}`);
            }
          }
          this.showSuccessMessage('Signed certificate successfully');
        },
        error: (err) => {
          if (!!err?.error?.errors) {
            Object.keys(err?.error?.errors).forEach((key) => {
              const errorMessage = err?.error?.errors[key];
              const formParam = key === FORM_PARAMS.CSR ? FORM_PARAMS.FILE_CSR : key === FORM_PARAMS.PRIVATE_KEY ? FORM_PARAMS.FILE_PRIVATE_KEY : key;
              this.form.getControl(formParam)?.setErrors({
                incorrect: true,
                message: Array.isArray(errorMessage) ? errorMessage.join('<br/>') : errorMessage,
              });
            });
          }
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Generate pure payload
   */
  private getPurePayload(payload: any) {
    // Modify payload based on serverKeygen and uploadKey Checkbox statuses
    const uploadKey = this.form.getControlValue(FORM_PARAMS.UPLOAD_KEY);
    const serverKeygen = this.form.getControlValue(FORM_PARAMS.SERVER_KEYGEN);

    if (!uploadKey) {
      delete payload[FORM_PARAMS.PRIVATE_KEY];
      delete payload[FORM_PARAMS.PASSWORD];
    } else {
      payload[FORM_PARAMS.KEYRING_ID] = payload[FORM_PARAMS.UPLOAD_KEYRING_ID];
    }

    if (!serverKeygen) {
      delete payload[FORM_PARAMS.PRIVATE_KEY_FORMAT];
    } else {
      payload[FORM_PARAMS.KEYRING_ID] = payload[FORM_PARAMS.SERVER_KEYRING_ID];
    }

    // Remove unnecessary payload properties

    delete payload[FORM_PARAMS.FILE_CSR];
    delete payload[FORM_PARAMS.FILE_PRIVATE_KEY];
    delete payload[FORM_PARAMS.SERVER_KEYGEN];
    delete payload[FORM_PARAMS.UPLOAD_KEY];
    delete payload[FORM_PARAMS.CA_SERVER_ID];
    delete payload[FORM_PARAMS.CA_ID];
    delete payload[FORM_PARAMS.TEMPLATE_ID];
    delete payload[FORM_PARAMS.FORMAT];
    delete payload[FORM_PARAMS.SERVER_KEYRING_ID];
    delete payload[FORM_PARAMS.UPLOAD_KEYRING_ID];

    if (!payload[FORM_PARAMS.PRIVATE_KEY]) {
      delete payload[FORM_PARAMS.PRIVATE_KEY];
    }

    if (!payload[FORM_PARAMS.PASSWORD]) {
      delete payload[FORM_PARAMS.PASSWORD];
    }

    if (!payload[FORM_PARAMS.KEYRING_ID]) {
      delete payload[FORM_PARAMS.KEYRING_ID];
    }

    return payload;
  }

  /**
   * Setup change events
   */
  setupEvents() {
    this.form.setChangeEvent(FORM_PARAMS.CA_SERVER_ID, (caConnectionId) => {
      this.getCAIntermediateCertificates(caConnectionId);
    });
    this.form.setChangeEvent(FORM_PARAMS.CA_ID, (caCertId) => {
      const caTemplateField = this.fields.find((p) => p.name === FORM_PARAMS.TEMPLATE_ID);
      if (!!caTemplateField) {
        const caOptions = this.fields.find((p) => p.name === FORM_PARAMS.CA_ID)?.options || [];
        caTemplateField.options = ((caOptions.find((p) => p.id === caCertId)?.ca_templates as any[]) || []).map((p) => ({
          ...p,
          value: p.id,
          label: p.name,
        }));
        if (!!caTemplateField.options.length) {
          this.form.enableControl(FORM_PARAMS.TEMPLATE_ID);
        } else {
          this.form.disableControl(FORM_PARAMS.TEMPLATE_ID);
        }
        this.form.setControlValue(FORM_PARAMS.TEMPLATE_ID, null);
      }
    });
    this.form.setChangeEvent(FORM_PARAMS.PRIVATE_KEY, (privateKey) => {
      if (!!privateKey) {
        this.form.enableControl(FORM_PARAMS.PASSWORD);
      } else {
        this.form.disableControl(FORM_PARAMS.PASSWORD);
      }
    });
    this.form.setChangeEvent(FORM_PARAMS.SERVER_KEYGEN, (isChecked) => {
      if (typeof isChecked === 'boolean') {
        this.form.setControlValidatorsAndVisibility(FORM_PARAMS.SERVER_KEYRING_ID, isChecked ? [VALIDATOR_TYPE.REQUIRED] : []);
        this.form.setControlValidatorsAndVisibility(FORM_PARAMS.PRIVATE_KEY_FORMAT, isChecked ? [VALIDATOR_TYPE.REQUIRED] : []);
        this.form.setControlValue(FORM_PARAMS.TYPE, isChecked ? ENROLLMENT_TYPES.SERVER_KEYGEN : ENROLLMENT_TYPES.ENROLL);

        if (!!isChecked) {
          // Reset value of upload private key fields
          this.form.setControlValue(FORM_PARAMS.UPLOAD_KEYRING_ID, null);
          this.form.setControlValue(FORM_PARAMS.UPLOAD_KEY, null);
          this.form.setControlValue(FORM_PARAMS.FILE_PRIVATE_KEY, null);
          this.form.setControlValue(FORM_PARAMS.PASSWORD, null);
          this.form.disableControl(FORM_PARAMS.UPLOAD_KEY);
        } else {
          this.form.setControlValue(FORM_PARAMS.SERVER_KEYRING_ID, null);
          this.form.enableControl(FORM_PARAMS.UPLOAD_KEY);
        }
      }
    });

    this.form.setChangeEvent(FORM_PARAMS.UPLOAD_KEY, (value) => {
      if (typeof value === 'boolean') {
        const isChecked = !!value;

        this.form.setControlValidatorsAndVisibility(FORM_PARAMS.FILE_PRIVATE_KEY, isChecked ? [VALIDATOR_TYPE.REQUIRED] : []);
        this.form.setControlValidatorsAndVisibility(FORM_PARAMS.UPLOAD_KEYRING_ID, isChecked ? [VALIDATOR_TYPE.REQUIRED] : []);
        this.form.setControlVisibility(FORM_PARAMS.PASSWORD, isChecked);

        if (!isChecked) {
          this.form.setControlValue(FORM_PARAMS.UPLOAD_KEYRING_ID, null);
          this.form.enableControl(FORM_PARAMS.SERVER_KEYGEN);
        } else {
          this.form.setControlValue(FORM_PARAMS.SERVER_KEYGEN, null);
          this.form.disableControl(FORM_PARAMS.SERVER_KEYGEN);
        }
      }
    });
  }
  /**
   * Get the list of CA connections
   */
  getCAConnections() {
    this.form.isLoading = true;
    const subscription = this.caManagementSrv
      .getCAManagers(this.breadcrumbConfig?.projectId as number)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
          if (!!caServerField) {
            caServerField.options = ((rs?.data as any[]) || []).map((p) => ({
              ...p,
              value: p.id,
              label: p.name,
            }));
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Get the list of CA intermediate certificates
   * @param caServerId
   */
  getCAIntermediateCertificates(caServerId: any) {
    const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
    const caField = this.fields.find((p) => p.name === FORM_PARAMS.CA_ID);
    if (!!caServerField && !!caField) {
      const cas: any[] = caServerField.options?.find((caServer) => caServer.value === caServerId)?.cas || [];
      const certs = cas
        .filter((p: any) => p.mode === CA_MODE_VALUES.X509)
        .filter((p: any) => (p.type as string).includes('intermediate'))
        .map((p: any) => ({
          ...p,
          value: p.id,
          label: `${p.id}: ${p.subject?.CN} (${p.description})`,
        }));
      caField.options = this.util.sortObjectArray(certs, 'id');
      // Update selected value for ca_id and ca_template_id
      this.form.enableControl(FORM_PARAMS.CA_ID);
      this.form.enableControl(FORM_PARAMS.TEMPLATE_ID);
      this.form.setControlValue(FORM_PARAMS.CA_ID, null);
      this.form.setControlValue(FORM_PARAMS.TEMPLATE_ID, null);
      if (!caField.options.length) {
        this.form.disableControl(FORM_PARAMS.CA_ID);
      }
      this.form.disableControl(FORM_PARAMS.TEMPLATE_ID);
    }
  }

  /**
   * Create Keyring Field
   */
  private createKeyringField(name: string, isPrivateKeyUpload = false): FormItem {
    return Object.assign(new FormItem(), {
      name: name,
      label: 'KMS Keyring',
      field: 'dropdown',
      options: [] as any[],
      refreshOptionsEvent: new EventEmitter<any>(),
      actionButtons: [
        {
          icon: 'fa fa-plus',
          label: 'Add New Keyring',
          styleClass: 'p-button-success',
          command: () => {
            window.open(
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}` +
                `/${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}` +
                `/${PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE}` +
                `/${PROJECT_SETTINGS_CONSTANTS.KMS.ROUTE}`,
              '_blank',
            );
          },
        },
      ] as any[],
      placeholder: `Select a keyring for ${!isPrivateKeyUpload ? 'key generation' : 'key import'}`,
      fieldInfo: `Select a keyring for ${!isPrivateKeyUpload ? 'key generation' : 'key import'}`,
      defaultValue: null,
      hidden: true,
    } as FormItem);
  }

  /**
   * Check if KMS featured
   */
  get isKMSFeatured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.KMS);
  }

  /**
   * Check if cryptographic token management is featured
   */
  get isCryptokenManagementFeatured() {
    return !!this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.CRYPTOKEN_MANAGEMENT);
  }
}
