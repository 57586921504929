import { Component, OnInit } from '@angular/core';
import { DEVICE_TYPE } from '@lcms-constants';
import { devicesSelectors } from '@ngrx-devices';
import { BaseComponent } from '@lcms-components';
import { TerminalConsoleComponent } from './terminal-console/terminal-console.component';
import { ViewLogsConsoleComponent } from './view-logs-console/view-logs-console.component';

@Component({
  selector: 'app-device-administration',
  templateUrl: './device-administration.component.html',
  styleUrls: ['./device-administration.component.scss'],
})
export class DeviceAdministrationComponent extends BaseComponent implements OnInit {
  selectedDevice: any = null;

  DEVICE_TYPE_LABELS = DEVICE_TYPE;

  async ngOnInit() {
    await this.prepareConfigs();
    this.store.select(devicesSelectors.selectedDevice).subscribe((selectedDevice) => {
      if (!!selectedDevice) {
        this.selectedDevice = selectedDevice;
      }
    });
  }

  /**
   * Open terminal
   */
  openTerminal() {
    this.dialogSrv.open(TerminalConsoleComponent, {
      header: this.selectedDevice.common_name,
      data: { deviceName: this.selectedDevice.common_name },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }

  /**
   * Open view logs
   */
  openViewLogs() {
    this.dialogSrv.open(ViewLogsConsoleComponent, {
      header: this.selectedDevice.common_name,
      data: { deviceName: this.selectedDevice.common_name },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }
}
