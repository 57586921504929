<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="container-border">
    <microsec-details-panel [title]="selectedCAManager?.name" [buttons]="actionButtons" (collapseEvent)="unselectCAManager()">
      <p-tabView [scrollable]="true" styleClass="microsec-dashboard-tab" [(activeIndex)]="tabIndex">
        <p-tabPanel header="Overview">
          <app-ca-connection-overview [selectedCAManager]="selectedCAManager" />
        </p-tabPanel>
        @if (selectedCAManager?.type !== CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA) {
          <p-tabPanel
            header="Imported Devices/Users"
            [disabled]="
              selectedCAManager?.type === CA_MANAGER_TYPE_VALUES.MICROSOFT_ADCS_CA || selectedCAManager?.connection_status === 'disconnected'
            ">
            @if (selectedCAManager?.type !== CA_MANAGER_TYPE_VALUES.MICROSOFT_ADCS_CA) {
              <app-ca-connection-imported-end-entities [selectedCAManager]="selectedCAManager" />
            }
          </p-tabPanel>
        }
      </p-tabView>
    </microsec-details-panel>
  </div>
</microsec-loading-overlay>
