import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { KmsService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { CONNECT_LABEL, CONNECT_SUCCESS } from '@microsec/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  SERIAL_ID: 'serial_id',
  PASSWORD: 'password',
};

@Component({
  selector: 'app-cryptoken-form',
  templateUrl: './cryptoken-form.component.html',
  styleUrls: ['./cryptoken-form.component.scss'],
})
export class CryptokenFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CONNECT_LABEL = CONNECT_LABEL;

  managerId = null;

  token: any = null;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private kmsSrv: KmsService,
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.managerId = this.dialogConfig?.data?.managerId;
    this.token = this.dialogConfig?.data?.token;
    this.initForm();
    if (!!this.token) {
      this.form.patchValue(this.token);
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        label: 'Connect to a selected token (e.g HSM) to be able to create keys and signed payloads.',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SERIAL_ID,
        required: true,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PASSWORD,
        label: 'Password',
        field: 'password',
        feedback: false,
        required: true,
        fieldInfo: "Token's password",
        focused: true,
      } as FormItem),
    ];
  }

  /**
   * Submit the form data
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form.getRawValue() };
    this.form.isLoading = true;
    this.kmsSrv
      .loginCryptoken(this.managerId, this.token?.id, payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(CONNECT_SUCCESS.replace('{0}', 'cryptographic token'));
          closeDialog();
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
