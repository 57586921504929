import { DetailsPanelButtonModel } from '@microsec/models';
import { EDIT_LABEL, DELETE_LABEL } from '@microsec/constants';
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { KEYRING_TYPE_VALUES, MICROSEC_DEFAULT_KEYRING } from '@lcms-constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-keyring-info',
  templateUrl: './keyring-info.component.html',
  styleUrls: ['./keyring-info.component.scss'],
})
export class KeyringInfoComponent extends BaseComponent implements AfterViewInit {
  @Input() isLoading = false;

  _selectedKeyring: any = null;

  @Input() set selectedKeyring(value: any) {
    this._selectedKeyring = value;
    this.prepareActionButtons();
  }

  get selectedKeyring() {
    return this._selectedKeyring;
  }

  @Input() keyPurposeAlgorithm: any = null;

  @Output() selectedKeyringChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() openKeyringFormEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() confirmDeletionEvent: EventEmitter<any> = new EventEmitter<any>();

  KEYRING_TYPE_VALUES = KEYRING_TYPE_VALUES;

  actionButtons: DetailsPanelButtonModel[] = [];

  activeIndex = 0;

  constructor(private route: ActivatedRoute) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.route.queryParams.subscribe((params) => {
      if (!!params?.['generic_key_id']) {
        let genericKeyId = null;
        try {
          genericKeyId = Number.parseInt(params['generic_key_id']);
        } catch {
          genericKeyId = null;
        }
        if (!!genericKeyId) {
          this.activeIndex = 1; // Generic Keys tab is chosen
        }
      }
      const gpgParam = params?.['gpg_key_id'];
      if (!!gpgParam) {
        let gpgKeyId = null;
        try {
          gpgKeyId = Number.parseInt(gpgParam);
        } catch {
          gpgKeyId = null;
        }
        if (!!gpgKeyId || gpgParam === 'create') {
          this.activeIndex = 2; // GPG Keys tab is chosen
        }
      }
    });
    this.prepareActionButtons();
  }

  /**
   * Unselect keyring
   */
  unselectKeyring() {
    this.selectedKeyringChange.emit(null);
  }

  /**
   * Prepare action buttons
   */
  prepareActionButtons() {
    this.actionButtons = [
      {
        label: EDIT_LABEL,
        hidden: this.selectedKeyring?.name === MICROSEC_DEFAULT_KEYRING,
        command: () => {
          this.handleAction('edit');
        },
      },
      {
        label: DELETE_LABEL,
        hidden: this.selectedKeyring?.name === MICROSEC_DEFAULT_KEYRING,
        command: () => {
          this.handleAction('delete');
        },
      },
    ];
  }

  /**
   * Handle events: edit, delete and refresh
   * @param event
   */
  handleAction(action: string) {
    switch (action) {
      case 'edit': {
        this.openKeyringFormEvent.emit(this.selectedKeyring);
        break;
      }
      case 'delete': {
        this.confirmDeletionEvent.emit(this.selectedKeyring);
        break;
      }
      default: {
        break;
      }
    }
  }
}
