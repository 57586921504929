import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DevicesState, DEVICES_FEATURE_KEY } from './devices.reducer';

// Lookup the 'Devices' feature state managed by NgRx
const getDevicesState = createFeatureSelector<DevicesState>(DEVICES_FEATURE_KEY);

// devices
const devices = createSelector(getDevicesState, (state: DevicesState) => state.devices);

// device total page
const totalDeviceNumber = createSelector(getDevicesState, (state: DevicesState) => state.totalDeviceNumber);

// is loading
const isLoading = createSelector(getDevicesState, (state: DevicesState) => state.loading);

// All both X509 and MicroPKI devices
const allDevices = createSelector(getDevicesState, (state: DevicesState) => {
  const results: any[] = [];
  state.devices.forEach((x509Device) => {
    results.push(x509Device);
    results.push(...((x509Device?.children as any[]) || []));
  });
  return results;
});

// lastRefresh
const lastRefresh = createSelector(getDevicesState, (state: DevicesState) => state.lastRefresh);

// lastRefreshDeviceEvents
const lastRefreshDeviceEvents = createSelector(getDevicesState, (state: DevicesState) => state.lastRefreshDeviceEvents);

// selectedDevice
const selectedDevice = createSelector(getDevicesState, (state: DevicesState) => state.selectedDevice);

// Configs
const refreshConfig = createSelector(getDevicesState, (state: DevicesState) => ({
  organizationId: state.organizationId,
  projectId: state.projectId,
  config: state.config,
  filters: state.filters,
  searchText: state.searchText,
  sortConfig: state.sortConfig,
  page: state.page,
  perPage: state.perPage,
}));

export const devicesSelectors = {
  isLoading,
  devices,
  totalDeviceNumber,
  allDevices,
  lastRefresh,
  lastRefreshDeviceEvents,
  selectedDevice,
  refreshConfig,
};
