import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { KEYRING_TYPE_VALUES, PROJECT_MANAGEMENT_CONSTANTS, REPO_DISTROS, REPO_TYPES } from '@lcms-constants';
import { KmsService, RepositoryService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import {
  CREATE_LABEL,
  CREATE_SUCCESS,
  ORGANIZATION_LEVEL_ROUTE,
  PROJECT_LEVEL_ROUTE,
  SAVE_CHANGES_LABEL,
  UPDATE_SUCCESS,
  VALIDATOR_TYPE,
} from '@microsec/constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize, Observable } from 'rxjs';
import { PROJECT_SETTINGS_CONSTANTS } from '@lcms-products';

const FORM_PARAMS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  DISTRO: 'distro',
  SIGNING: 'signing',
  KEYRING_ID: 'keyring_id',
  KEY_ID: 'key_id',
};

@Component({
  selector: 'app-repository-form',
  templateUrl: './repository-form.component.html',
  styleUrls: ['./repository-form.component.scss'],
})
export class RepositoryFormComponent extends BaseComponent implements OnInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  repository: any = null;

  constructor(
    public dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private repositorySrv: RepositoryService,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.repository = this.dialogConfig.data?.repository;
    this.initForm();
    if (!!this.repository) {
      this.form.patchValue(this.repository);
    }
    this.getKeyrings();
  }

  /**
   * Initialize form
   */
  initForm() {
    // Key ring dropdown and Refresh Button

    const keyringField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.KEYRING_ID,
      label: 'Keyring',
      field: 'dropdown',
      placeholder: 'Select a keyring',
      options: [] as any[],
      refreshOptionsEvent: new EventEmitter<any>(),
      actionButtons: [
        {
          icon: 'fa fa-plus',
          label: 'Add New Keyring',
          styleClass: 'p-button-success',
          command: () => {
            window.open(
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}` +
                `/${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}` +
                `/${PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE}` +
                `/${PROJECT_SETTINGS_CONSTANTS.KMS.ROUTE}`,
              '_blank',
            );
          },
        },
      ] as any[],
      defaultValue: null,
      fieldInfo: 'Select Filesystem Type Keyring of the repository',
      required: !this.repository,
      hidden: !!this.repository,
    } as FormItem);

    keyringField.refreshOptionsEvent?.subscribe(() => {
      this.getKeyrings();
    });

    // GPG Key Field with Refresh Button

    const gpgKeyField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.KEY_ID,
      label: 'GPG Key',
      field: 'dropdown',
      placeholder: 'Select a GPG key',
      options: [] as any[],
      refreshOptionsEvent: new EventEmitter<any>(),
      actionButtons: [
        {
          icon: 'fa fa-plus',
          label: 'Add New GPG Keys',
          styleClass: 'p-button-success',
          command: () => {
            const keyringId = this.form.getControlValue(FORM_PARAMS.KEYRING_ID) || null;
            window.open(
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}` +
                `/${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}` +
                `/${PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE}` +
                `/${PROJECT_SETTINGS_CONSTANTS.KMS.ROUTE}` +
                (!!keyringId ? `?keyring_id=${keyringId}` : '') +
                `&gpg_key_id=create`,
              '_blank',
            );
          },
        },
      ] as any[],
      defaultValue: null,
      fieldInfo: 'GPG Key of the repository',
      hidden: true,
    } as FormItem);

    gpgKeyField.refreshOptionsEvent?.subscribe(() => {
      const keyringId = this.form.getControlValue(FORM_PARAMS.KEYRING_ID) || null;
      !!keyringId ? this.getGPGKeys(keyringId) : null;
    });

    // Set Form Fields

    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: 'Create a package repository to host packages for your devices.',
        field: 'text',
        hidden: !!this.repository,
      } as FormItem),
      Object.assign(new FormItem(), {
        label:
          'Edit the Name / Description for the repository. If you wish to change the other attributes (e.g. Repo Type), please create a new repository.',
        field: 'text',
        hidden: !this.repository,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        required: true,
        defaultValue: '',
        focused: true,
        fieldInfo: 'Name of the repository',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        defaultValue: '',
        fieldInfo: 'Description of the repository',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DISTRO,
        label: 'Distro',
        field: 'dropdown',
        placeholder: 'Select an distro',
        options: REPO_DISTROS,
        required: true,
        defaultValue: null,
        fieldInfo: 'Distro of the repository',
        hidden: !!this.repository,
      } as FormItem),
      keyringField,
      gpgKeyField,
    ];

    this.fields = fields;
    this.form?.setChangeEvent(FORM_PARAMS.KEYRING_ID, (value: boolean) => {
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_ID, !!value && !this.repository ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValue(FORM_PARAMS.KEY_ID, null);
      if (!!value) {
        this.getGPGKeys(value);
      }
    });
  }

  /**
   * Get keyrings
   */
  getKeyrings() {
    this.form.isLoading = true;
    this.kmsSrv
      .getKeyrings(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const keyringField = this.fields.find((p) => p.name === FORM_PARAMS.KEYRING_ID);
          if (!!keyringField) {
            keyringField.options = (((rs?.data as any[]) || []).filter((keyring) => keyring.type === KEYRING_TYPE_VALUES.FILESYSTEM) as any[]).map(
              (key) => ({
                ...key,
                value: key.id,
                label: key.name,
              }),
            );
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get GPG keys
   * @param keyringId
   */
  getGPGKeys(keyringId: any) {
    this.form.isLoading = true;
    this.kmsSrv
      .getGPGKeys(keyringId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const gpgKeyField = this.fields.find((p) => p.name === FORM_PARAMS.KEY_ID);
          if (!!gpgKeyField) {
            gpgKeyField.options = ((rs?.data as any[]) || []).map((key) => ({
              ...key,
              value: key.id,
              label: key.label,
            }));
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Submit form
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const formData = this.form.getRawValue();
    const payload = {
      ...formData,
      signing: true,
      type: REPO_TYPES.CUSTOM,
      project_id: this.breadcrumbConfig?.projectId,
    };
    const request: Observable<any> = !this.repository
      ? this.repositorySrv.createRepository(payload)
      : this.repositorySrv.updateRepository(this.repository.id, {
          [FORM_PARAMS.NAME]: formData[FORM_PARAMS.NAME],
          [FORM_PARAMS.DESCRIPTION]: formData[FORM_PARAMS.DESCRIPTION],
        });
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.repository ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'custom repository'));
          closeDialog();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }
}
