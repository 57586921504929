import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { USER_CERTIFICATE_STATUS_TAGS } from '@lcms-constants';
import { userCertificatesSelectors } from '@ngrx-user-certificates';
import { SharedCertificateDetailsComponent } from '@lcms-products';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { ActionMenuItem, FormItem } from '@microsec/models';
import { PIPE_DATETIME, PIPELINE_TYPE } from '@microsec/constants';

const FORM_PARAMS = {
  ISSUED_TO: 'name',
  STATUS: 'status',
  CA: 'ca',
  ENROLLED: 'not_before',
};

const COLS = [
  { field: 'hexSerialNumber', header: 'Serial Number', width: 8 },
  { field: 'status', header: 'Status', width: 3 },
  { field: 'not_before', header: 'Issue Date', width: 3 },
  { field: 'not_after', header: 'Expiry Date', width: 3 },
  // { field: 'actions', header: 'Actions', width: 2 },
];

@Component({
  selector: 'app-user-certificate-overview',
  templateUrl: './user-certificate-overview.component.html',
  styleUrls: ['./user-certificate-overview.component.scss'],
})
export class UserCertificateOverviewComponent extends BaseComponent implements AfterViewInit {
  _selectedManager: any = null;

  get selectedManager() {
    return this._selectedManager;
  }

  set selectedManager(value: any) {
    const selectedManager = this.util.cloneDeepObject(value);
    const certs = ((selectedManager?.certs as any[]) || []).map((p) => ({
      ...p,
      hexSerialNumber: this.util.convertToHexadecimal(p.serial_number),
    }));
    if (!!certs && !!certs.length) {
      const firstCert = this.util.sortObjectArray(certs, 'not_before', true, true)?.[0];
      selectedManager.ca = firstCert?.issuer?.CN;
      selectedManager.not_before = firstCert?.not_before;
    }
    this._selectedManager = selectedManager;
    this._selectedManager.statusText = this.getStatusProperty('label');
    this._selectedManager.statusColor = this.getStatusProperty('color');
    this._selectedManager.statusTooltip = this.getStatusProperty('tooltip');
    this.values = certs;
  }

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  COLS = COLS;

  cols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.cols = COLS.filter((col) => value?.includes(col.field));
  }

  allCols: any[] = this.cols;

  values: any[] = [];

  actionMenuItems: ActionMenuItem[] = [];

  PIPE_DATETIME = PIPE_DATETIME;

  @ViewChild('statusTemplate') statusTemplateRef: TemplateRef<any>;

  constructor(public el: ElementRef) {
    super();
  }

  ngAfterViewInit(): void {
    this.actionMenuItems = [
      {
        label: 'View',
        icon: 'fas fa-eye',
        command: ({ rowData }: any) => this.openCertDetailModal(rowData),
      },
    ];
    this.store.select(userCertificatesSelectors.selectedManager).subscribe((manager) => {
      if (!!manager) {
        this.selectedManager = manager;
        const fields: FormItem[] = [
          Object.assign(new FormItem(), {
            name: FORM_PARAMS.ISSUED_TO,
            label: 'Issued To',
            field: 'label',
            defaultValue: this.selectedManager.name,
          } as FormItem),
          Object.assign(new FormItem(), {
            name: FORM_PARAMS.STATUS,
            label: 'Status',
            field: 'custom',
            customField: this.statusTemplateRef,
          } as FormItem),
        ];
        if (!!((this.selectedManager?.certs as any[]) || []).length) {
          fields.push(
            ...[
              Object.assign(new FormItem(), {
                name: FORM_PARAMS.CA,
                label: 'CA',
                field: 'label',
                defaultValue: this.selectedManager.ca,
              } as FormItem),
              Object.assign(new FormItem(), {
                name: FORM_PARAMS.ENROLLED,
                label: 'Enrolled',
                field: 'label',
                defaultValue: this.selectedManager.not_before,
                pipeline: PIPELINE_TYPE.DATETIME,
              } as FormItem),
            ],
          );
        }
        this.fields = fields;
      }
    });
    this.selectedColFields = [...COLS.map((col) => col.field)];
  }

  /**
   * Open certificate dialog
   * @param cert
   */
  openCertDetailModal(cert: any) {
    this.dialogSrv.open(SharedCertificateDetailsComponent, {
      data: { cert },
      header: 'Certificate Details',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }

  /**
   * Get status properties
   * @param property
   * @returns
   */
  getStatusProperty(property: string) {
    if (!!this.selectedManager?.status) {
      const result = USER_CERTIFICATE_STATUS_TAGS.find((p) => p.value === this.selectedManager.status);
      return (result as any)?.[property];
    }
    return '';
  }
}
