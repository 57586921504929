export const REPO_TYPES = {
  CUSTOM: 'custom',
  MIRROR: 'mirror',
};

export const REPO_DISTROS = [{ value: 'debian', label: 'Debian' }];

export const KEY_POST_TYPE = {
  GENERATE: 'generate',
  IMPORT: 'import',
};

export const KEY_TYPES = [{ value: 'GPG', label: 'GPG' }];

export const REPO_KEY_ALGORITHMS = [
  { value: 'DSA', label: 'DSA' },
  { value: 'RSA', label: 'RSA' },
];

export const KEY_LENGTHS = [
  { value: 1024, label: '1024' },
  { value: 2048, label: '2048' },
];
