<microsec-card>
  <div class="grid">
    <div class="col-12 xl:col-6 lg:col-8 md:col-10 sm:col-12">
      <microsec-form-builder
        #fb
        [isPage]="true"
        [fields]="fields"
        [shouldShowActionButtons]="true"
        [submitButtonLabel]="SAVE_CHANGES_LABEL"
        [cancelButtonLabel]="''"
        (submitEvent)="onSubmit()"
        [leftActionArea]="leftActionArea" />
    </div>
  </div>
</microsec-card>

<ng-template #testConnTemplate>
  <p-button severity="primary" size="small" label="Test Connection" [loading]="buttonLoadingStatuses.testConn" (onClick)="testConnection()" />
</ng-template>

<ng-template #testAuthTemplate>
  <p-button severity="primary" size="small" label="Test Authentication" [loading]="buttonLoadingStatuses.testAuth" (onClick)="testAuthentication()" />
</ng-template>

<ng-template #leftActionArea>
  <p-button severity="primary" label="Reset" styleClass="mr-2" [loading]="buttonLoadingStatuses.reset" (onClick)="confirmReset()" />
  <p-button severity="secondary" label="Discard" styleClass="mr-2" [loading]="buttonLoadingStatuses.discard" (onClick)="confirmDiscard()" />
</ng-template>
