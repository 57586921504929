import { ILayoutItem } from '@microsec/interfaces';
import { DEVICE_RULE_CONSTANTS } from './shared-rules.constants';
import { IpRulesComponent } from './ruleset-types/ip/ip.component';
import { CanRulesComponent } from './ruleset-types/can/can.component';
import { CommandRulesComponent } from './ruleset-types/command/command.component';
import { YaraRulesComponent } from './ruleset-types/yara/yara.component';

export const DEVICE_RULE_CHILDREN: ILayoutItem[] = [
  {
    route: DEVICE_RULE_CONSTANTS.IP.ROUTE,
    label: DEVICE_RULE_CONSTANTS.IP.LABEL,
    component: IpRulesComponent,
  },
  {
    route: DEVICE_RULE_CONSTANTS.CAN.ROUTE,
    label: DEVICE_RULE_CONSTANTS.CAN.LABEL,
    component: CanRulesComponent,
  },
  {
    route: DEVICE_RULE_CONSTANTS.COMMAND.ROUTE,
    label: DEVICE_RULE_CONSTANTS.COMMAND.LABEL,
    component: CommandRulesComponent,
    disabled: true,
  },
  {
    route: DEVICE_RULE_CONSTANTS.YARA.ROUTE,
    label: DEVICE_RULE_CONSTANTS.YARA.LABEL,
    component: YaraRulesComponent,
    disabled: true,
  },
];
