import { Util } from '@microsec/utilities';
import { UserCertificatesAction, UserCertificatesActionTypes } from './user-certificates.actions';

export const USER_CERTIFICATES_FEATURE_KEY = 'user certificates';

export interface UserCertificatesState {
  projectId?: any;
  selectedManager?: any | null;
  managers: any[];
  loading: boolean;
  error?: string | null;
}

export const initialUserCertificatesState: UserCertificatesState = {
  projectId: null,
  selectedManager: null,
  managers: [],
  loading: false,
  error: null,
};

export function userCertificatesReducer(
  state: UserCertificatesState = initialUserCertificatesState,
  action: UserCertificatesAction,
): UserCertificatesState {
  switch (action.type) {
    case UserCertificatesActionTypes.INIT_MANAGERS: {
      state = Util.cloneDeepObject(initialUserCertificatesState);
      break;
    }
    case UserCertificatesActionTypes.GET_MANAGERS: {
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    }
    case UserCertificatesActionTypes.GET_MANAGERS_SUCCESS: {
      const initialConfig = action;
      state = {
        ...state,
        projectId: initialConfig.projectId,
        selectedManager: !!action.selectedManager ? (action.managers as any[])?.find((p) => p.id === action.selectedManager.id) : null,
        managers: action.managers,
        loading: false,
        error: null,
      };
      break;
    }
    case UserCertificatesActionTypes.GET_MANAGERS_FAILURE: {
      const { error } = action;
      state = {
        ...state,
        loading: false,
        error,
      };
      break;
    }
    case UserCertificatesActionTypes.SELECT_MANAGER: {
      const { selectedManager } = action;
      state = {
        ...state,
        selectedManager,
      };
      break;
    }
    case UserCertificatesActionTypes.DELETE_MANAGER_SUCCESS: {
      state = {
        ...state,
        selectedManager: null,
      };
      break;
    }
    default: {
      break;
    }
  }
  return state;
}
