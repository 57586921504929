import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EnvelopeService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { DELETE_LABEL, DUPLICATE_LABEL, EDIT_LABEL, SCOPE, USER_ROLE } from '@microsec/constants';
import { finalize } from 'rxjs/operators';
import { EnvelopeTemplateFormComponent } from './envelope-template-form/envelope-template-form.component';
import { EnvelopeVerificationFormComponent } from './envelope-verification-form/envelope-verification-form.component';
import { CommonTableComponent } from '@microsec/components';
import { ActionMenuItem, CommonToolbarConfiguration, CommonToolbarResult, ConfirmationDialogConfig } from '@microsec/models';
import { BehaviorSubject } from 'rxjs';
import { ENVELOPE_TEMPLATE_FORM_PARAMS as FORM_PARAMS } from './envelope-template-form/envelope-template-form.component';

const FIELDS = {
  ID: 'ID',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  SCOPE: 'Scope',
};

@Component({
  selector: 'app-envelope-templates',
  templateUrl: './envelope-templates.component.html',
  styleUrls: ['./envelope-templates.component.scss'],
})
export class EnvelopeTemplatesComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols = [
    { field: 'id', header: FIELDS.ID, width: 5, frozen: true },
    { field: 'name', header: FIELDS.NAME, width: 15 },
    { field: 'description', header: FIELDS.DESCRIPTION, width: 15 },
    { field: 'scope', header: FIELDS.SCOPE, width: 10 },
  ];

  selectedTemplate: any = null;

  values: any[] = [];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search...',
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    public el: ElementRef,
    private envelopeSrv: EnvelopeService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    let checkedRole = 0;
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        checkedRole = USER_ROLE.ADMIN;
        break;
      }
      case SCOPE.PROJECT: {
        checkedRole = USER_ROLE.READ_ONLY;
        break;
      }
      default: {
        break;
      }
    }
    if (!!this.checkPermissionsByScope(checkedRole, true)) {
      this.selectedColFields = (this.cols || []).map((col) => col.field);
      this.handleFilterObjUpdate();
      this.getTemplates();
      this.envelopeSrv.refreshObs.subscribe((rs) => {
        if (!!rs) {
          this.refreshTemplatesList();
        }
      });
    }

    this.actionsMenuItems = [
      {
        label: DUPLICATE_LABEL,
        icon: 'fas fa-copy',
        visible: () => !!this.permissions,
        command: ({ rowData }) => {
          this.duplicateTemplate(rowData);
        },
      },
      {
        label: EDIT_LABEL,
        icon: 'fas fa-pen-to-square',
        visible: ({ rowData }) => !(rowData?.scope === SCOPE.GLOBAL || rowData?.scope !== this.currentScope || !this.hasPermission),
        command: ({ rowData }) => {
          this.openTemplateForm(rowData);
        },
      },
      {
        label: DELETE_LABEL,
        icon: 'fas fa-trash',
        visible: ({ rowData }) => !(rowData?.scope === SCOPE.GLOBAL || rowData?.scope !== this.currentScope || !this.hasPermission),
        command: ({ rowData }) => {
          this.confirmDeletion(rowData);
        },
      },
    ];
  }

  /**
   * Duplicate as a new template
   */
  duplicateTemplate(template: any) {
    const payload: any = { ...template };

    payload.name += ` (${this.getDuplicateTemplateCount(payload.name)})`;
    delete payload.id;
    delete payload[FORM_PARAMS.SCOPE];

    // Define current scope
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        payload[FORM_PARAMS.ORGANIZATION_ID] = !!payload[FORM_PARAMS.ORGANIZATION_ID]
          ? payload[FORM_PARAMS.ORGANIZATION_ID]
          : this.breadcrumbConfig?.organizationId;
        payload[FORM_PARAMS.PROJECT_ID] = null;
        break;
      }
      case SCOPE.PROJECT: {
        payload[FORM_PARAMS.ORGANIZATION_ID] = !!payload[FORM_PARAMS.ORGANIZATION_ID]
          ? payload[FORM_PARAMS.ORGANIZATION_ID]
          : this.breadcrumbConfig?.organizationId;
        payload[FORM_PARAMS.PROJECT_ID] = !!payload[FORM_PARAMS.PROJECT_ID] ? payload[FORM_PARAMS.PROJECT_ID] : this.breadcrumbConfig?.projectId;
        break;
      }
      default:
        break;
    }

    this.isLoading = true;
    this.envelopeSrv
      .createTemplate(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Template is successfully duplicated');
          this.refreshTemplatesList();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Show confirmation dialog to delete a template
   */
  confirmDeletion(template: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Envelope Template',
      object: template,
      objectFieldName: 'name',
      acceptRequest: this.envelopeSrv.deleteTemplate(template.id),
      next: () => {
        this.showSuccessMessage(`Deleted template ${template.name} successfully`);
        this.refreshTemplatesList();
        this.selectedTemplate = null;
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Get index for the duplicated template
   * @param templateName
   * @returns
   */
  getDuplicateTemplateCount(templateName: string): number {
    const duplicateTemplates = this.values.filter((template) => {
      if (template.name === templateName) {
        return true;
      }
      return template.name.slice(0, template.name.lastIndexOf(' ')) === templateName;
    });
    return duplicateTemplates.length;
  }

  /**
   * Handle filters
   */
  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values.search || '';
      }
    });
  }

  /**
   * Unselect template
   * @param event
   * @param datatable
   * @returns
   */
  unselectTemplate(event: any, datatable: any) {
    if (event?.target !== datatable) {
      return;
    }
    this.selectedTemplate = null;
  }

  /**
   * Get the list of templates
   */
  getTemplates() {
    this.isLoading = true;
    this.values = [];
    this.envelopeSrv
      .getTemplates(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          let values = (rs.data as any[]) || [];
          values = this.util.sortObjectArray(values, 'name');
          values = this.util.sortObjectArray(values, 'scope');
          this.values = values;
          // Reset selected row
          if (!!this.selectedTemplate) {
            this.selectedTemplate = this.values.find((p) => p.id === this.selectedTemplate.id);
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Refresh the list of templates
   */
  refreshTemplatesList() {
    if (!!this.selectedTemplate) {
      this.selectedTemplate = { ...this.selectedTemplate };
    }
    this.getTemplates();
  }

  /**
   * Open template form
   */
  openTemplateForm(template = null) {
    const dialog = this.dialogSrv.open(EnvelopeTemplateFormComponent, {
      header: `${!template ? 'Create' : 'Edit'} ${this.moduleName} Envelope Template`,
      data: {
        selectedTemplate: template,
      },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      this.envelopeSrv.refresh$.next(rs);
    });
  }

  /**
   * Open verification form
   */
  openVerificationForm() {
    this.dialogSrv.open(EnvelopeVerificationFormComponent, {
      header: 'Verify Envelope',
      data: {
        templates: this.values,
      },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }

  /**
   * Get module name
   */
  get moduleName() {
    return this.util?.getUppercaseFirstLetter(this.currentScope);
  }

  /**
   * Check if has permission
   */
  get hasPermission() {
    let result = false;
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        result = (this.permissions as any)[SCOPE.ORGANIZATION][USER_ROLE.ADMIN];
        break;
      }
      case SCOPE.PROJECT: {
        result = (this.permissions as any)[SCOPE.PROJECT][USER_ROLE.ADMIN];
        break;
      }
      default: {
        break;
      }
    }
    return result;
  }
}
