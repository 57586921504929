import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { fromDevicesActions } from '@ngrx-devices';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { SAVE_CHANGES_LABEL } from '@microsec/constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const FORM_PARAMS = {
  LABELS: 'labels',
};

@Component({
  selector: 'app-device-labels-form',
  templateUrl: './device-labels-form.component.html',
  styleUrls: ['./device-labels-form.component.scss'],
})
export class DeviceLabelsFormComponent extends BaseComponent implements AfterViewInit {
  device: any = {};

  labels: any[] = [];

  deviceLabels: any[] = [];

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  @ViewChild('labelTemplate') labelTemplate: any;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.device = this.dialogConfig?.data?.device;
    this.labels = this.dialogConfig?.data?.labels;
    this.deviceLabels = this.device.labels?.map((label: any) => label.id) || [];
    this.initForm();
    this.form.patchValue({ [FORM_PARAMS.LABELS]: this.deviceLabels });
  }

  /**
   * Initialize form
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABELS,
        label: 'Label(s)',
        field: 'multiselect',
        options: this.labels.map((p) => ({
          ...p,
          value: p.id,
          label: p.name,
        })),
        optionTemplate: this.labelTemplate,
        placeholder: 'Select Label(s)',
        fieldInfo: "Device's labels",
        defaultValue: [],
        focused: true,
      } as FormItem),
    ];
  }

  /**
   * Submit form
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form.getRawValue() };
    this.store.dispatch(new fromDevicesActions.UpdateDevice(this.device, payload));
    closeDialog();
  }

  /**
   * Check if label changes
   */
  get isLabelChanges() {
    const sortedFormLabels = (this.form?.getControlValue(FORM_PARAMS.LABELS) as number[])?.sort((a, b) => a - b) || [];
    const sortedDeviceLabels = (this.deviceLabels as number[])?.sort((a, b) => a - b) || [];
    if (sortedFormLabels.length !== sortedDeviceLabels.length) {
      return true;
    }
    return !sortedFormLabels.every((element, index) => {
      return element === sortedDeviceLabels[index];
    });
  }
}
