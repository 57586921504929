import { Component, Input, OnInit } from '@angular/core';
import { SUPPLY_CHAIN_MANAGEMENT_FEATURES } from '@lcms-constants';
import { authenticationSelectors } from '@microsec/ngrx-authentication';
import { EnvelopeService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { MOMENT_DATETIME } from '@microsec/constants';
import { User } from '@microsec/models';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { EnvelopeFormComponent } from './envelope-form/envelope-form.component';
import { ShareEnvelopeFormComponent } from './share-envelope-form/share-envelope-form.component';
@Component({
  selector: 'app-envelopes',
  templateUrl: './envelopes.component.html',
  styleUrls: ['./envelopes.component.scss'],
})
export class EnvelopesComponent extends BaseComponent implements OnInit {
  _selectedTemplate: any = null;

  get selectedTemplate() {
    return this._selectedTemplate;
  }

  @Input() set selectedTemplate(value: any) {
    this._selectedTemplate = value;
    this.getSignedEnvelopes();
  }

  cols = [
    { field: 'id', header: 'S/N', width: 4, frozen: true },
    { field: 'created', header: 'Created', width: 7 },
    { field: 'cert_name', header: 'Selected CA Certificate', width: 10 },
    { field: 'actions', header: 'Actions', width: 8 },
  ];

  values: any[] = [];

  currentUser: User | null = null;

  constructor(private envelopeSrv: EnvelopeService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.currentUser = await firstValueFrom(this.store.select(authenticationSelectors.currentUser));
  }

  /**
   * Get the list of signed envelopes
   */
  getSignedEnvelopes() {
    this.envelopeSrv.getSignedEnvelopes(this.selectedTemplate.id).subscribe({
      next: (rs) => {
        this.values = rs.data.map((data: any) => ({
          ...data,
          created: !!data.created ? moment(data.created).format(MOMENT_DATETIME) : undefined,
        }));
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Download the signed envelope
   * @param envelope
   */
  downloadSignedEnvelope(envelope: any) {
    if (!!envelope) {
      this.envelopeSrv.downloadEnvelope(envelope.id).subscribe({
        next: (rs: any) => {
          this.util.downloadFileFromBlob(rs, this.selectedTemplate?.output_file_name);
          this.showSuccessMessage('Downloaded signed envelope successfully');
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
    }
  }

  /**
   * Open the form to create a new envelope
   */
  openEnvelopeForm() {
    const dialog = this.dialogSrv.open(EnvelopeFormComponent, {
      data: {
        template: this.selectedTemplate,
      },
      header: 'Create Envelope',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getSignedEnvelopes();
      }
    });
  }

  /**
   * Open form to share the envelope
   * @param envelope
   */
  openEnvelopeSharingForm(envelope: any) {
    this.dialogSrv.open(ShareEnvelopeFormComponent, {
      data: { envelope },
      header: 'Share Envelope',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }

  /**
   * Check if the current user is the envelope's owner
   * @param envelope
   * @returns
   */
  checkIsOwner(envelope: any) {
    return envelope?.user_id === this.currentUser?.id;
  }

  /**
   * Get the reason if cannot share the envelope
   * @param envelope
   * @returns
   */
  getShareTooltip(envelope: any) {
    if (!!this.checkIsOwner(envelope)) {
      return 'Share';
    }
    return "Cannot share, because you are not this signed envelope's owner";
  }

  /**
   * Check if the share feature is enabled
   */
  get hasShareFeature() {
    return this.checkSupplyChainManagementFeatureEnabled(SUPPLY_CHAIN_MANAGEMENT_FEATURES.ENVELOPE_SHARING);
  }
}
