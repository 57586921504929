<!-- [additionalConditions]="!!allValidityLambda" -->
<microsec-form-builder
  #fb
  [isPage]="mode === 'view'"
  [fields]="fields"
  [submitButtonLabel]="mode === 'new' ? CREATE_LABEL : SAVE_CHANGES_LABEL"
  (submitEvent)="onSubmit($event)"
  [shouldShowActionButtons]="mode !== 'view'" />

<!-- @if (mode !== 'view') {
  <div class="col-12 flex justify-content-end align-items-center">
    <p-button
      severity="primary"
      size="small"
      label="Validate"
      [loading]="isValidationLambdaLoading[LAMBDA_TYPES.INPUT]"
      [disabled]="!!form.get(FORM_PARAMS.INPUT_VALIDATION_LAMBDA)?.errors?.['trimRequired'] || !!validityLambda[LAMBDA_TYPES.INPUT]"
      (onClick)="toggleValidationLambdaPanel($event, LAMBDA_TYPES.INPUT, form.value[FORM_PARAMS.INPUT_VALIDATION_LAMBDA])" />
    @if (!!validityLambda[LAMBDA_TYPES.INPUT]) {
      <fa-icon class="icon-green ml-2" [icon]="faCheckCircle" pTooltip="Lambda function valid" />
    }
  </div>
} -->

<!-- <p-overlayPanel #validationLambdaPanel [dismissable]="false">
  <ng-template pTemplate>
    <form [formGroup]="validationForm" class="no-wrapper" (ngSubmit)="validateLambda()">
      <div class="grid field">
        <label class="col-12 lg:col-4">Input<microsec-asterisk></microsec-asterisk></label>
        <div class="col-12 lg:col-8">
          <input inputTrimRef type="text" pInputText [formControlName]="VALIDATION_FORM_PARAMS.INPUT" />
        </div>
      </div>
      <div class="flex justify-content-end">
        <p-button severity="secondary" size="small" label="Cancel" styleClass="mr-2" (onClick)="validationLambdaPanel.hide()" />
        <p-button severity="primary" size="small" type="submit" label="Validate" [disabled]="!!validationForm.invalid" />
      </div>
    </form>
  </ng-template>
</p-overlayPanel> -->
