/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { DeviceService } from '@lcms-services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Util } from '@microsec/utilities';
import { MessageService } from 'primeng/api';
import { asyncScheduler, catchError, map, mergeMap, Observable, scheduled, switchMap, takeUntil } from 'rxjs';

import { DeviceSummaryAction, DeviceSummaryActionTypes, fromDeviceSummaryActions } from './device-summary.actions';

@Injectable()
export class DeviceSummaryEffects {
  constructor(
    private readonly actions$: Actions<DeviceSummaryAction>,
    private readonly toastSrv: MessageService,
    private readonly deviceSrv: DeviceService,
  ) {}

  // Get device summary
  getDeviceSummaryEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_DEVICE_SUMMARY),
      switchMap((action) => {
        return this.deviceSrv.getDeviceSummary(action.organizationId, action.projectId).pipe(
          takeUntil(this.actions$.pipe(ofType(DeviceSummaryActionTypes.CANCEL_GET_SUMMARY_REQUEST))),
          map((summary) => new fromDeviceSummaryActions.OnGetDeviceSummarySuccess(summary)),
          catchError((error) => scheduled([new fromDeviceSummaryActions.OnGetDeviceSummaryFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get device summary failed
  onGetDeviceSummaryFailureEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_DEVICE_SUMMARY_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Get enrolled device summary
  getEnrolledDeviceSummaryEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_ENROLLED_DEVICE_SUMMARY),
      switchMap((action) => {
        return this.deviceSrv.getDeviceCertificateSummary(action.organizationId, action.projectId, 'enrolled', action.fromDate, action.toDate).pipe(
          takeUntil(this.actions$.pipe(ofType(DeviceSummaryActionTypes.CANCEL_GET_ENROLLED_DEVICE_SUMMARY_REQUEST))),
          map((result: any) => new fromDeviceSummaryActions.OnGetEnrolledDeviceSummarySuccess(result?.data || [], 'enrolled')),
          catchError((error) => scheduled([new fromDeviceSummaryActions.OnGetEnrolledDeviceSummaryFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get enrolled device summary failed
  onGetEnrolledDeviceSummaryFailureEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_ENROLLED_DEVICE_SUMMARY_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Get expiring device summary
  getExpiringDeviceSummaryEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_EXPIRING_DEVICE_SUMMARY),
      switchMap((action) => {
        return this.deviceSrv.getDeviceCertificateSummary(action.organizationId, action.projectId, 'expiring', action.fromDate, action.toDate).pipe(
          takeUntil(this.actions$.pipe(ofType(DeviceSummaryActionTypes.CANCEL_GET_EXPIRING_DEVICE_SUMMARY_REQUEST))),
          map((result: any) => new fromDeviceSummaryActions.OnGetExpiringDeviceSummarySuccess(result?.data || [], 'expiring')),
          catchError((error) => scheduled([new fromDeviceSummaryActions.OnGetExpiringDeviceSummaryFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get expiring device summary failed
  onGetExpiringDeviceSummaryFailureEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_EXPIRING_DEVICE_SUMMARY_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Get expired device summary
  getExpiredDeviceSummaryEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_EXPIRED_DEVICE_SUMMARY),
      switchMap((action) => {
        return this.deviceSrv.getDeviceCertificateSummary(action.organizationId, action.projectId, 'expired', action.fromDate, action.toDate).pipe(
          takeUntil(this.actions$.pipe(ofType(DeviceSummaryActionTypes.CANCEL_GET_EXPIRED_DEVICE_SUMMARY_REQUEST))),
          map((result: any) => new fromDeviceSummaryActions.OnGetExpiredDeviceSummarySuccess(result?.data || [], 'expired')),
          catchError((error) => scheduled([new fromDeviceSummaryActions.OnGetExpiredDeviceSummaryFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get expired device summary failed
  onGetExpiredDeviceSummaryFailureEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_EXPIRED_DEVICE_SUMMARY_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Get summary CAs
  getSummaryCAsEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_SUMMARY_CAS),
      switchMap((action) => {
        return this.deviceSrv.getSummaryCAs(action.organizationId, action.projectId).pipe(
          takeUntil(this.actions$.pipe(ofType(DeviceSummaryActionTypes.CANCEL_GET_SUMMARY_CAS_REQUEST))),
          map((rs: any) => new fromDeviceSummaryActions.OnGetSummaryCAsSuccess(rs?.data || [])),
          catchError((error) => scheduled([new fromDeviceSummaryActions.OnGetSummaryCAsFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get summary CAs failed
  onGetSummaryCAsFailureEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_DEVICE_SUMMARY_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Get devices per CA
  getDevicesPerCAEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_DEVICES_PER_CA),
      switchMap((action) => {
        return this.deviceSrv.getDevicesPerCA(action.organizationId, action.projectId, action.caId).pipe(
          takeUntil(this.actions$.pipe(ofType(DeviceSummaryActionTypes.CANCEL_GET_DEVICES_PER_CA_REQUEST))),
          map((rs: any) => new fromDeviceSummaryActions.OnGetDevicesPerCASuccess(rs?.data || [])),
          catchError((error) => scheduled([new fromDeviceSummaryActions.OnGetDevicesPerCAFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get devices per CA failed
  onGetDevicesPerCAFailureEffect$: Observable<DeviceSummaryAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceSummaryActionTypes.GET_DEVICES_PER_CA_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );
}
