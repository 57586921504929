import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { PKI_MANAGEMENT_FEATURES } from '@lcms-constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { fromUserCertificatesActions, userCertificatesSelectors } from '@ngrx-user-certificates';
import { BaseComponent } from '@lcms-components';
import { USER_CERTIFICATES_CHILDREN } from './user-certificates.config';

@Component({
  selector: 'app-user-certificates',
  templateUrl: './user-certificates.component.html',
  styleUrls: ['./user-certificates.component.scss'],
})
export class UserCertificatesComponent extends BaseComponent implements OnInit, OnDestroy {
  isInitialized = false;

  isLoading = true;

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.USER_CERTIFICATES)) {
      this.store.dispatch(new fromUserCertificatesActions.InitManagers());
      if (!!this.checkPermissionsByScope(USER_ROLE.ADMIN, true)) {
        const tabItems = this.util.cloneObjectArray(USER_CERTIFICATES_CHILDREN).filter((p) => !!p.label);
        this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.store.dispatch(new fromUserCertificatesActions.GetManagers(this.breadcrumbConfig?.projectId));
      }
      this.store.select(userCertificatesSelectors.isLoading).subscribe((isLoading) => {
        this.isLoading = isLoading;
        if (!!isLoading) {
          this.isInitialized = true;
        }
      });
    } else {
      this.navigateToNotFoundPage();
    }
  }

  override ngOnDestroy(): void {
    this.store.dispatch(new fromUserCertificatesActions.InitManagers());
    this.cleanup({ shouldClearTabItems: true });
  }
}
