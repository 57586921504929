import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { KmsService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { CREATE_LABEL, CREATE_SUCCESS, SAVE_CHANGES_LABEL, UPDATE_SUCCESS } from '@microsec/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  NAME: 'label',
  DESCRIPTION: 'description',
  ADDRESS: 'address',
  ACCESS_TOKEN: 'access_token',
  PROJECT_ID: 'project_id',
};

@Component({
  selector: 'app-cryptoken-manager-form',
  templateUrl: './cryptoken-manager-form.component.html',
  styleUrls: ['./cryptoken-manager-form.component.scss'],
})
export class CryptokenManagerFormComponent extends BaseComponent implements AfterViewInit {
  cryptTokenManager: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.cryptTokenManager = this.dialogConfig?.data?.cryptTokenManager;
    this.initForm();
    if (!!this.cryptTokenManager) {
      this.form.patchValue(this.cryptTokenManager);
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: 'Connect to a cryptographic token manager to manage HSMs and smart cards through LCMS.',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: 'Name',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'input',
        required: true,
        fieldInfo: 'Description',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ADDRESS,
        label: 'Address',
        field: 'input',
        required: true,
        pattern: '(http://|https://)([-a-zA-Z0-9.]){1,256}:([0-9]{1,5})(/.*)?$',
        patternErrorText: 'Please enter the address in the correct format, e.g. https://cryptoken-manager.usec.io:8501',
        fieldInfo: 'Address',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ACCESS_TOKEN,
        label: 'Access Token',
        field: 'password',
        feedback: false,
        required: true,
        fieldInfo: 'Access token',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        hidden: true,
        defaultValue: this.breadcrumbConfig?.projectId,
      } as FormItem),
    ];
    this.fields = fields;
  }

  /**
   * Submit the form data
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form.getRawValue() };
    const id = !this.cryptTokenManager ? null : this.cryptTokenManager?.id;
    const request: Observable<any> = !this.cryptTokenManager
      ? this.kmsSrv.createCryptokenManager(payload)
      : this.kmsSrv.updateCryptokenManager(id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.cryptTokenManager ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'cryptographic token manager'));
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
