import { Component } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { ENROLLMENT_CONFIGS } from './enrollment.config';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss'],
})
export class EnrollmentComponent extends BaseComponent {
  enrollmentOptions: any[] = this.util.cloneObjectArray(ENROLLMENT_CONFIGS);
}
