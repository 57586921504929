<div class="grid">
  <!-- Buttons + Searchbox -->
  <div class="col-12">
    <div class="flex justify-content-between">
      <!-- Buttons -->
      <div>
        <p-button severity="primary" size="small" label="Back-import Certificates" (onClick)="openBackImportForm()" />
      </div>
      <!-- Searchbox -->
      <div class="flex align-items-center justify-content-end">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            [autofocus]="true"
            pInputText
            type="text"
            placeholder="Press ENTER to search ..."
            [(ngModel)]="searchText"
            (keyup)="inputSearch($event)" />
        </span>
        <microsec-refresh-button (refreshEvent)="getBackImportedCertificates()" />
      </div>
    </div>
  </div>

  <!-- Data table -->
  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        [rows]="rows"
        [lazy]="true"
        (lazyLoadEvent)="getBackImportedCertificates($event)"
        [totalRecords]="totalEntityNumber"
        [cols]="cols"
        [values]="values"
        itemName="Entity"
        [cardBackground]="true"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [scrollHeight]="'40vh'"
        [selectable]="false"
        [noSortingCols]="noSortColumns" />
    </microsec-card>
  </div>
</div>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    @case ('cn') {
      <microsec-truncate-text [tooltip]="rowData[col.field]">
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
    @case ('ca') {
      <microsec-truncate-text [tooltip]="rowData[col.field]">
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
    @case ('ra_entity_type') {
      {{ rowData[col.field] | titlecase }}
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
  }
</ng-template>
