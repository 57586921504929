import { FEATURES, PKI_MANAGEMENT_FEATURES, PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { SharedProjectFormComponent } from '../../../shared-components/shared-project-form/shared-project-form.component';
import { CryptokenManagersComponent } from './cryptoken-managers/cryptoken-managers.component';
import { KmsComponent } from './kms/kms.component';

export const PROJECT_SETTINGS_CONSTANTS = {
  PROJECT_INFORMATION: {
    ROUTE: 'project-information',
    LABEL: 'Project Information',
  },
  CONNECTED_CAS: {
    ROUTE: 'connected-cas',
    LABEL: 'Connected CAs',
  },
  DEVICE_ENROLLMENT: {
    ROUTE: 'device-enrollment',
    LABEL: 'Device Enrollment',
  },
  USER_ENROLLMENT: {
    ROUTE: 'user-enrollment',
    LABEL: 'User Enrollment',
  },
  DEVICE_VULNERABILITY_MANAGEMENT: {
    ROUTE: 'device-vulnerability-management',
    LABEL: 'Device Vulnerability Management',
  },
  LABELS: {
    ROUTE: 'labels',
    LABEL: 'Labels',
  },
  CRYPTOKEN_MANAGER: {
    ROUTE: 'cryptoken-manager',
    LABEL: 'Cryptoken Manager',
  },
  KMS: {
    ROUTE: 'kms',
    LABEL: 'KMS',
  },
};

export const PROJECT_SETTINGS_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: PROJECT_SETTINGS_CONSTANTS.PROJECT_INFORMATION.ROUTE,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: PROJECT_SETTINGS_CONSTANTS.PROJECT_INFORMATION.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.PROJECT_INFORMATION.LABEL,
    component: SharedProjectFormComponent,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: PROJECT_SETTINGS_CONSTANTS.CRYPTOKEN_MANAGER.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.CRYPTOKEN_MANAGER.LABEL,
    component: CryptokenManagersComponent,
    featureName: FEATURES.PKI_MANAGEMENT,
    featureCheckField: PKI_MANAGEMENT_FEATURES.CRYPTOKEN_MANAGEMENT,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: PROJECT_SETTINGS_CONSTANTS.KMS.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.KMS.LABEL,
    component: KmsComponent,
    featureName: FEATURES.PKI_MANAGEMENT,
    featureCheckField: PKI_MANAGEMENT_FEATURES.KMS,
  },
];
