import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { CsrGenerateFormComponent } from 'apps/lcms-ra/src/app/products/shared-components/shared-csr-generate-form/csr-generate-form.component';
import { UserCertificateCsrUploadFormComponent } from './user-certificate-csr-upload-form/user-certificate-csr-upload-form.component';

@Component({
  selector: 'app-user-certificate-csr-form',
  templateUrl: './user-certificate-csr-form.component.html',
  styleUrls: ['./user-certificate-csr-form.component.scss'],
})
export class UserCertificateCsrFormComponent extends BaseComponent implements OnInit, AfterViewInit {
  csrOptions: any[] = [
    {
      value: 'generate',
      label: 'Generate CSR directly',
      component: CsrGenerateFormComponent,
    },
    {
      value: 'upload',
      label: 'Upload CSR manually',
      component: UserCertificateCsrUploadFormComponent,
    },
  ];

  selectedOption: any = null;

  ngOnInit(): void {
    const component: CsrGenerateFormComponent = this.csrOptions?.[0]?.component;
    component.type = 'user';
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.selectedOption = this.csrOptions[0].value;
  }
}
