import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceSummaryState, DEVICE_SUMMARY_FEATURE_KEY } from './device-summary.reducer';

// Lookup the 'DeviceSummary' feature state managed by NgRx
const getDeviceSummaryState = createFeatureSelector<DeviceSummaryState>(DEVICE_SUMMARY_FEATURE_KEY);

// is summary loading
const isLoading = createSelector(getDeviceSummaryState, (state: DeviceSummaryState) => state.isLoading);

// summary
const summary = createSelector(getDeviceSummaryState, (state: DeviceSummaryState) => state.summary);

// summary lastRefresh
const lastRefresh = createSelector(getDeviceSummaryState, (state: DeviceSummaryState) => state.lastRefresh);

// CAs
const cas = createSelector(getDeviceSummaryState, (state: DeviceSummaryState) => state.cas);

// is CA loading
const isCALoading = createSelector(getDeviceSummaryState, (state: DeviceSummaryState) => state.isCALoading);

// Enrolled devices
const enrolledDevices = createSelector(getDeviceSummaryState, (state: DeviceSummaryState) => state.deviceResults.enrolled);

// Expiring devices
const expiringDevices = createSelector(getDeviceSummaryState, (state: DeviceSummaryState) => state.deviceResults.expiring);

// Expired devices
const expiredDevices = createSelector(getDeviceSummaryState, (state: DeviceSummaryState) => state.deviceResults.expired);

// Devices per CA
const devicesPerCA = createSelector(getDeviceSummaryState, (state: DeviceSummaryState) => state.devicesPerCA);

export const deviceSummarySelectors = {
  isLoading,
  summary,
  lastRefresh,
  cas,
  isCALoading,
  enrolledDevices,
  expiringDevices,
  expiredDevices,
  devicesPerCA,
};
