import { Component, OnInit } from '@angular/core';
import { MODULE_CONSTANTS } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { GLOBAL_SETTINGS_CONSTANTS } from '@lcms-constants';
import { LICENSES_CONSTANTS } from '../../global-settings/licenses/licenses.constants';
import { BaseComponent } from '@lcms-components';

@Component({
  selector: 'app-shared-license-warning',
  templateUrl: './shared-license-warning.component.html',
  styleUrls: ['./shared-license-warning.component.scss'],
})
export class SharedLicenseWarningComponent extends BaseComponent implements OnInit {
  async ngOnInit() {
    await this.prepareConfigs();
  }

  /**
   * Route to license upload page
   */
  routeToLicensePage() {
    this.router
      .navigateByUrl(`/${MODULE_CONSTANTS.GLOBAL_SETTINGS.ROUTE}/${GLOBAL_SETTINGS_CONSTANTS.LICENSES.ROUTE}/${LICENSES_CONSTANTS.UPLOAD.ROUTE}`)
      .then(() => {
        this.store.dispatch(new fromLayoutActions.RefreshLeftNavbar());
      });
  }

  /**
   * Check if is not license page
   */
  get isNotLicensePage() {
    return this.router.url.indexOf(GLOBAL_SETTINGS_CONSTANTS.LICENSES.ROUTE) === -1;
  }

  /**
   * Check if the application has license
   */
  get hasLicense() {
    let result = false;
    if (!!this.features) {
      Object.values(this.features).forEach((featureTypeValue: any) => {
        result ||= !!Object.values(featureTypeValue).find((p) => !!p);
      });
    } else {
      result = false;
    }
    return result;
  }
}
