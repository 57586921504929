import { Pipe, PipeTransform } from '@angular/core';
import {
  FIREWALL_CAN_VERSIONS,
  CA_KEY_USAGE,
  CA_MANAGER_TYPES,
  CA_MODES,
  RULE_STRATEGIES,
  CA_TYPES,
  COUNTRIES,
  FIRMWARE_DEVICE_STATUSES,
  KEYRING_GENERIC_KEY_TYPES,
  KEYRING_GPG_KEY_TYPES,
  KEYRING_TYPES,
  RULE_YARA_MODES,
  SYMMETRIC_KEY_ALGORITHMS,
  ASYMMETRIC_KEY_ALGORITHMS,
  GPG_KEY_ALGORITHMS,
} from '@lcms-constants';
import { Util } from '@microsec/utilities';

@Pipe({
  name: 'constant',
})
export class ConstantPipe implements PipeTransform {
  transform(value: any, type: any): any {
    let result = null;
    switch (type) {
      case 'country': {
        result = this.getLabel(COUNTRIES, value);
        break;
      }
      case 'ca-type': {
        result = this.getLabel(CA_TYPES, value);
        break;
      }
      case 'ca-mode': {
        result = this.getLabel(CA_MODES, value);
        break;
      }
      case 'ca-key-usage': {
        result = this.getLabel(CA_KEY_USAGE, value);
        break;
      }
      case 'ca-manager-type': {
        result = this.getLabel(CA_MANAGER_TYPES, value);
        break;
      }
      case 'firmware-device-status': {
        result = this.getLabel(FIRMWARE_DEVICE_STATUSES, value);
        break;
      }
      case 'kms-key-algorithm': {
        result = this.getLabel([...SYMMETRIC_KEY_ALGORITHMS, ...ASYMMETRIC_KEY_ALGORITHMS, ...GPG_KEY_ALGORITHMS], value);
        break;
      }
      case 'kms-keyring-type': {
        result = this.getLabel(KEYRING_TYPES, value);
        break;
      }
      case 'kms-key-type': {
        result = this.getLabel([...KEYRING_GENERIC_KEY_TYPES, ...KEYRING_GPG_KEY_TYPES], value);
        break;
      }
      case 'firewall-can-version': {
        result = this.getLabel(FIREWALL_CAN_VERSIONS, value);
        break;
      }
      case 'rule-strategy': {
        result = this.getLabel(RULE_STRATEGIES, value);
        break;
      }
      case 'rule-yara-mode': {
        result = this.getLabel(RULE_YARA_MODES, value);
        break;
      }
      default: {
        break;
      }
    }
    return result;
  }

  private getLabel(constant: { value: any; label: string }[], value: any) {
    const result = constant.find((c) => c.value === value);
    return result ? result.label : !!value ? Util.getUppercaseFirstLetter(value.replace('_', ' ')) : value;
  }
}
