import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MODULE_CONSTANTS, USER_ROLE } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { BaseComponent } from '@lcms-components';
import { ORGANIZATION_MANAGEMENT_CONSTANTS } from '@lcms-constants';

@Component({
  selector: 'app-organization-management',
  templateUrl: './organization-management.component.html',
  styleUrls: ['./organization-management.component.scss'],
})
export class OrganizationManagementComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(private injector: Injector) {
    super();
  }

  async ngOnInit() {
    this.store.dispatch(new fromLayoutActions.SetLeftNavbar(true));
    await this.prepareConfigs();
    const ORGANIZATION_MANAGEMENT_CHILDREN = this.injector.get('ORGANIZATION_MANAGEMENT_CHILDREN');
    this.store.dispatch(new fromLayoutActions.SetModule(MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.ROUTE));
    if (!!this.checkPermissionsByScope(USER_ROLE.ADMIN)) {
      const checkedItems = this.util
        .cloneObjectArray(ORGANIZATION_MANAGEMENT_CHILDREN)
        .filter(
          (p) =>
            p.route === ORGANIZATION_MANAGEMENT_CONSTANTS.USER_ACCESS.ROUTE ||
            p.route === ORGANIZATION_MANAGEMENT_CONSTANTS.ORGANIZATION_SETTINGS.ROUTE ||
            p.route === ORGANIZATION_MANAGEMENT_CONSTANTS.ENVELOPES.ROUTE ||
            p.route === ORGANIZATION_MANAGEMENT_CONSTANTS.LABELS.ROUTE ||
            p.route === ORGANIZATION_MANAGEMENT_CONSTANTS.WORKFLOWS.ROUTE,
        );
      this.store.dispatch(new fromLayoutActions.UpdateReplacedItems(checkedItems.map((item) => ({ ...item, visible: true }))));
    }
  }

  override ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.store.dispatch(new fromLayoutActions.UpdateReplacedItems([]));
  }
}
