import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { USER_CERTIFICATE_STATUS_TAGS } from '@lcms-constants';
import { fromUserCertificatesActions, userCertificatesSelectors } from '@ngrx-user-certificates';
import { BaseComponent } from '@lcms-components';
import { XlsxHelper } from '@microsec/utilities';
import { MOMENT_DATETIME, PIPE_DATETIME, FILE_EXTENSION } from '@microsec/constants';
import moment from 'moment';
import { Table } from 'primeng/table';
import { CommonTableComponent } from '@microsec/components';
import { BehaviorSubject } from 'rxjs';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';

const FIELD = {
  ISSUED_TO: 'Issued To',
  STATUS: 'Status',
  CA: 'CA',
  EXPIRY_DATE: 'Expiry Date',
};

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols = [
    { field: 'name', header: FIELD.ISSUED_TO, width: 15 },
    { field: 'status', header: FIELD.STATUS, width: 15 },
    { field: 'ca', header: FIELD.CA, width: 10 },
    { field: 'not_after', header: FIELD.EXPIRY_DATE, width: 15 },
  ];

  _selectedManager: any = null;

  get selectedManager() {
    return this._selectedManager;
  }

  set selectedManager(value: any) {
    this._selectedManager = value;
    this.store.dispatch(new fromUserCertificatesActions.SelectManager(value));
  }

  values: any[] = [];

  filteredValues: any[] = [];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search', 'filter'],
    searchPlaceholder: 'Search...',
    filters: {
      0: {
        key: 'status',
        label: 'Status',
        type: 'dropdown',
        options: this.util.cloneObjectArray(USER_CERTIFICATE_STATUS_TAGS),
      },
    },
  };

  filterSearch = '';

  PIPE_DATETIME = PIPE_DATETIME;

  constructor(public el: ElementRef) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.handleFilterObjUpdate();
    this.subscriptions.push(
      this.store.select(userCertificatesSelectors.managers).subscribe((managers) => {
        const values = managers.map((manager) => {
          const selectedManager = this.util.cloneDeepObject(manager);
          const certs = (selectedManager?.certs as any[]) || [];
          if (!!certs && !!certs.length) {
            const firstCert = this.util.sortObjectArray(certs, 'not_after', false, true)?.[0];
            selectedManager.ca = firstCert?.issuer?.CN;
            selectedManager.not_after = firstCert?.not_after;
          }
          return selectedManager;
        });
        this.values = values;
        this.filteredValues = this.values;
        this.handleFilterObjUpdate();
      }),
    );
    this.subscriptions.push(
      this.store.select(userCertificatesSelectors.selectedManager).subscribe((manager) => {
        if (!manager) {
          this.selectedManager = null;
        }
      }),
    );
    this.subscriptions.push(
      this.store.select(userCertificatesSelectors.isLoading).subscribe((isLoading) => {
        this.isLoading = isLoading;
      }),
    );
    this.getCertificates();
  }

  /**
   * Handle filter
   */
  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((filterObject) => {
      let filterValues: any[] = this.util.cloneObjectArray(this.values);
      if (!!filterObject) {
        if (filterObject?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          filterValues = this.util.sortObjectArray(this.util.cloneObjectArray(filterValues || []), 'id');
        }
        if (this.filterSearch !== filterObject.search) {
          this.dt?.datatable?.filterGlobal(filterObject.search || '', 'contains');
        }
        this.filterSearch = filterObject.search || '';
        if (!!filterObject?.isFiltered && !!filterObject?.filter?.status) {
          filterValues = filterValues.filter((value) => {
            const status = filterObject.filter.status;
            return value.status === status || !!((value?.children as any[]) || []).find((p) => p.status === status);
          });
        }
      }
      this.filteredValues = filterValues;
    });
  }

  /**
   * Unselect manager
   * @param event
   * @param datatable
   * @returns
   */
  unselectManager(event: any, datatable: any) {
    if (event?.target !== datatable) {
      return;
    }
    this.store.dispatch(new fromUserCertificatesActions.SelectManager(null));
  }

  /**
   * Get certificates
   */
  getCertificates() {
    this.store.dispatch(new fromUserCertificatesActions.GetManagers(this.breadcrumbConfig?.projectId));
  }

  /**
   * Export certificates
   * @param table
   */
  export(table?: Table) {
    if (!!table) {
      XlsxHelper.exportSingleSheet(
        this.certs.map((cert: any) => ({
          'Organization ID': this.breadcrumbConfig?.organizationId,
          'Project ID': this.breadcrumbConfig?.projectId,
          'Issuer': Object.keys(cert?.issuer)
            ?.map((key) => `${key} = ${cert?.issuer[key]}`)
            .join(', '),
          'Subject': Object.keys(cert?.subject)
            ?.map((key) => `${key} = ${cert?.subject[key]}`)
            .join(', '),
          'Valid From': !!cert.not_before ? moment(cert.not_before).format(MOMENT_DATETIME) : '',
          'Valid Until': !!cert.not_after ? moment(cert.not_after).format(MOMENT_DATETIME) : '',
          'Status': cert.status,
          'Fingerprint (MD5)': cert.fingerprint_md5,
        })),
        `User_certificates_${this.breadcrumbConfig?.organizationId}` + `_${this.breadcrumbConfig?.projectId}`,
        FILE_EXTENSION.XLSX,
      );
    }
  }

  /**
   * Get the config for the status label
   * @param status
   * @returns
   */
  getLabelConfig(status: string): any {
    switch (status) {
      case 'back-imported': {
        return {
          label: 'Back-imported',
          value: 'back-imported',
          color: '#3232ff',
        };
      }
      default: {
        const config = USER_CERTIFICATE_STATUS_TAGS.find((p) => p.value === status);
        return !!config
          ? config
          : {
              iconMax: 0,
              iconValue: 0,
              type: '',
              value: '',
              label: '',
              backgroundColor: 'transparent',
              color: 'transparent',
            };
      }
    }
  }

  /**
   * Extract certificates from managers
   */
  get certs() {
    return this.values?.reduce((certs, manager) => [...certs, ...(manager?.certs || [])], []);
  }
}
