import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { KEYRING_GPG_KEY_TYPES } from '@lcms-constants';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { IMPORT_LABEL } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

const FORM_PARAMS = {
  LABEL: 'label',
  TYPE: 'type',
  GPG_KEY: 'gpg_key',
  GPG_KEY_FILE: 'gpg_key_file',
  PASSPHRASE: 'passphrase',
};

@Component({
  selector: 'app-gpg-key-import-form',
  templateUrl: './gpg-key-import-form.component.html',
  styleUrls: ['./gpg-key-import-form.component.scss'],
})
export class GpgKeyImportFormComponent extends BaseComponent implements AfterViewInit {
  keyring: any = null;

  fields: FormItem[] = [];

  FORM_PARAMS = FORM_PARAMS;

  IMPORT_LABEL = IMPORT_LABEL;

  @ViewChild('fb') form!: FormBuilderComponent;

  constructor(private dialogConfig: DynamicDialogConfig) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.keyring = this.dialogConfig?.data?.keyring;
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: 'Import existing keys into KMS.',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Label',
        field: 'input',
        fieldInfo: 'Label',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        label: 'Type',
        field: 'dropdown',
        options: this.util.cloneObjectArray(KEYRING_GPG_KEY_TYPES),
        placeholder: 'Select a key type',
        fieldInfo: 'Type',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.GPG_KEY_FILE,
        label: 'GPG Key',
        field: 'file',
        fieldInfo: 'GPG Key',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.GPG_KEY,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PASSPHRASE,
        label: 'Passphrase',
        field: 'password',
        feedback: false,
        fieldInfo: 'Passphrase',
        required: true,
      } as FormItem),
    ];
    this.fields = fields;
    this.setupChangeEvents();
  }

  setupChangeEvents() {
    const gpgKeyField = this.fields.find((p) => p.name === FORM_PARAMS.GPG_KEY_FILE);
    if (!!gpgKeyField) {
      gpgKeyField.uploadEvent?.subscribe((event) => {
        this.form.isLoading = true;
        if (!!event.target && !!event.target.files && !!event.target.files.length) {
          const file: any = (event.target.files as FileList).item(0);
          this.form.setControlValue(FORM_PARAMS.GPG_KEY_FILE, file);
          const reader = new FileReader();
          reader.onload = () => {
            this.form.isLoading = false;
            this.form.setControlValue(FORM_PARAMS.GPG_KEY, reader.result?.toString());
          };
          reader.readAsText(file);
        } else {
          this.form.isLoading = false;
          this.form.setControlValue(FORM_PARAMS.GPG_KEY_FILE, null);
          this.form.setControlValue(FORM_PARAMS.GPG_KEY, null);
        }
      });
    }
  }

  /**
   * Submit the form
   * @param callback
   */
  onSubmit(callback: (rs: any) => void) {
    const payload = this.util.cloneDeepObject(this.form.getRawValue());
    delete payload[FORM_PARAMS.GPG_KEY_FILE];
    // error: (err) => {
    //   if (!!err?.error?.errors?.[FORM_PARAMS.GPG_KEY]) {
    //     const errors = err.error.errors;
    //     errors[FORM_PARAMS.GPG_KEY_FILE] = errors?.[FORM_PARAMS.GPG_KEY];
    //   }
    //   this.form.showServerErrorMessage(err);
    //   this.showErrorMessage(err);
    // },
    callback(null);
  }
}
