import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { CsrGenerateFormComponent } from '../../../../../shared-components/shared-csr-generate-form/csr-generate-form.component';
import { DeviceCsrUploadFormComponent } from './device-csr-upload-form/device-csr-upload-form.component';

@Component({
  selector: 'app-device-csr-form',
  templateUrl: './device-csr-form.component.html',
  styleUrls: ['./device-csr-form.component.scss'],
})
export class DeviceCsrFormComponent extends BaseComponent implements OnInit, AfterViewInit {
  csrOptions: any[] = [
    {
      value: 'generate',
      label: 'Generate CSR directly',
      component: CsrGenerateFormComponent,
    },
    {
      value: 'upload',
      label: 'Upload CSR manually',
      component: DeviceCsrUploadFormComponent,
    },
  ];

  selectedOption: any = null;

  ngOnInit(): void {
    const component: CsrGenerateFormComponent = this.csrOptions?.[0]?.component;
    component.type = 'device';
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.selectedOption = this.csrOptions[0].value;
  }
}
