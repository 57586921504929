import { EMPTY, finalize, forkJoin } from 'rxjs';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CA_MODE_VALUES, PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { BaseComponent } from '@lcms-components';
import { ConstantPipe } from '@lcms-pipes';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { DOWNLOAD_LABEL, ORGANIZATION_LEVEL_ROUTE, PIPE_DATETIME, PIPELINE_TYPE, PROJECT_LEVEL_ROUTE } from '@microsec/constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PROJECT_SETTINGS_CONSTANTS } from '../../project-management/settings/project-settings/project-settings.config';
import { NavigationEnd } from '@angular/router';
import { KmsService } from '@lcms-services';

const FORM_PARAMS = {
  ID: 'id',
  SERIAL_NUMBER: 'serial_number',
  STATUS: 'status',
  ISSUER: 'issuer',
  VALID_NOT_BEFORE: 'not_before',
  VALID_NOT_AFTER: 'not_after',
  SUBJECT: 'subject',
  CA_ROLE: 'ca_role',
  CA_TEMPLATE: 'ca_template',
  MODE: 'mode',
  CA_TYPE: 'type',
  KEYRING: 'kms_keyring_id',
  KMS_PUBLIC_KEY: 'kms_public_key_id',
  KMS_PRIVATE_KEY: 'kms_private_key_id',
  HAS_PRIVATE_KEY: 'has_private_key',
  PUBLIC_KEY_ALGORITHM: 'subject_public_key_algorithm',
  SIGNATURE_ALGORITHM: 'signature_hash_algorithm',
  SIGNATURE: 'signature',
  MD5_FINGERPRINT: 'fingerprint_md5',
  SHA1_FINGERPRINT: 'fingerprint_sha1',
  SHA256_FINGERPRINT: 'fingerprint_sha256',
};

@Component({
  selector: 'app-shared-certificate-details',
  templateUrl: './shared-certificate-details.component.html',
  styleUrls: ['./shared-certificate-details.component.scss'],
  providers: [ConstantPipe],
})
export class SharedCertificateDetailsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  private _cert = {};

  get cert() {
    return this._cert;
  }

  @Input() set cert(value: any) {
    this._cert = value;
    this.setAdditionalDisplayValues();
    this.initForm();
  }

  @Input() isCA = false;

  CA_MODE_VALUES = CA_MODE_VALUES;

  PIPE_DATETIME = PIPE_DATETIME;

  DOWNLOAD_LABEL = DOWNLOAD_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private constantPipe: ConstantPipe,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngOnInit() {
    this.isLoading = true;
    await this.prepareConfigs();
    this.isLoading = false;
    if (!!this.isPage) {
      this.cert = this.util.cloneDeepObject(this.cert);
    } else {
      this.cert = this.dialogConfig?.data?.cert;
    }
    this.setAdditionalDisplayValues();

    if (!!this.cert[FORM_PARAMS.KEYRING]) {
      this.getKeyringAndKeyName(this.cert[FORM_PARAMS.KEYRING], this.cert[FORM_PARAMS.KMS_PRIVATE_KEY], this.cert[FORM_PARAMS.KMS_PRIVATE_KEY]);
    } else {
      this.initForm();
    }

    // Subscribe to router navigation events when user clicks on url, close dialog
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.dialogRef?.close();
      }
    });
    this.subscriptions.push(routerSubscription);
  }

  /**
   * Parse values to show on the form
   */
  setAdditionalDisplayValues() {
    this.cert.hexSerialNumber = this.util.convertToHexadecimal(this.cert.serial_number);
    if (!!this.cert?.issuer) {
      this.cert.issuerText = Object.keys(this.cert?.issuer)
        .map((key) => `${key} = ${this.cert?.issuer[key]}`)
        .join(', ');
    }
    if (!!this.cert?.subject) {
      this.cert.subjectText = Object.keys(this.cert?.subject)
        .map((key) => `${key} = ${this.cert?.subject[key]}`)
        .join(', ');
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    this.isLoading = true;
    const field: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ID,
        label: 'ID',
        field: 'label',
        defaultValue: this.cert.id,
        hidden: !this.isCA,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SERIAL_NUMBER,
        label: 'Serial Number',
        field: 'copy',
        defaultValue: this.cert.hexSerialNumber,
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.STATUS,
        label: 'Status',
        field: 'label',
        defaultValue: this.cert.status,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ISSUER,
        label: 'Issuer',
        field: 'label',
        defaultValue: this.cert.issuerText,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.VALID_NOT_BEFORE,
        label: 'Valid Not Before',
        field: 'label',
        defaultValue: this.cert.not_before,
        pipeline: PIPELINE_TYPE.DATETIME,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.VALID_NOT_AFTER,
        label: 'Valid Not After',
        field: 'label',
        defaultValue: this.cert.not_after,
        pipeline: PIPELINE_TYPE.DATETIME,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SUBJECT,
        label: 'Subject',
        field: 'label',
        defaultValue: this.cert.subjectText,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_ROLE,
        label: 'CA Role',
        field: 'label',
        defaultValue: this.cert.caRole || '-',
        hidden: !this.isCA,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_TEMPLATE,
        label: 'CA Template',
        field: 'label',
        defaultValue: this.cert.ca_template || this.cert.cert_template,
        hidden: !!this.isCA || this.cert.mode !== CA_MODE_VALUES.X509,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.MODE,
        label: 'Mode',
        field: 'label',
        defaultValue: this.constantPipe?.transform(this.cert.mode, 'ca-mode'),
        hidden: !!this.isCA,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_TYPE,
        label: 'Type',
        field: 'label',
        defaultValue: !!this.isCA
          ? `${this.constantPipe?.transform(this.cert.mode, 'ca-mode')} ${this.constantPipe?.transform(this.cert.type, 'ca-type')}`
          : this.constantPipe?.transform(this.cert.type, 'ca-type'),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEYRING,
        label: 'Keyring',
        field: !!this.cert?.[FORM_PARAMS.KEYRING] ? 'linkchips' : 'label',
        defaultValue: !!this.cert?.kms_keyring_id
          ? [
              {
                label: `${this.cert?.kms_keyring_name ? this.cert.kms_keyring_name : 'keyring'} (${this.cert?.[FORM_PARAMS.KEYRING]})`,
                url:
                  `${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
                  `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
                  `${PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE}/${PROJECT_SETTINGS_CONSTANTS.KMS.ROUTE}` +
                  `?keyring_id=${this.cert?.[FORM_PARAMS.KEYRING]}`,
              },
            ]
          : '-',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KMS_PUBLIC_KEY,
        label: 'Public Key',
        field: !!this.cert?.[FORM_PARAMS.KMS_PUBLIC_KEY] ? 'linkchips' : 'label',
        defaultValue: !!this.cert?.kms_public_key_id
          ? [
              {
                label: `${this.cert?.kms_public_key_name ? this.cert.kms_public_key_name : 'key'} (${this.cert?.[FORM_PARAMS.KMS_PUBLIC_KEY]})`,
                url:
                  `${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
                  `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
                  `${PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE}/${PROJECT_SETTINGS_CONSTANTS.KMS.ROUTE}` +
                  `?keyring_id=${this.cert?.[FORM_PARAMS.KEYRING]}&generic_key_id=${this.cert?.[FORM_PARAMS.KMS_PUBLIC_KEY]}`,
              },
            ]
          : '-',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PUBLIC_KEY_ALGORITHM,
        label: 'Public Key Algorithm',
        field: 'label',
        defaultValue: this.cert.subject_public_key_algorithm,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KMS_PRIVATE_KEY,
        label: 'Private Key',
        field: !!this.cert?.[FORM_PARAMS.KMS_PRIVATE_KEY] ? 'linkchips' : 'label',
        defaultValue: !!this.cert?.[FORM_PARAMS.KMS_PRIVATE_KEY]
          ? [
              {
                label: `${this.cert?.kms_private_key_name ? this.cert.kms_private_key_name : 'key'} (${this.cert?.[FORM_PARAMS.KMS_PRIVATE_KEY]})`,
                url:
                  `${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
                  `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
                  `${PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE}/${PROJECT_SETTINGS_CONSTANTS.KMS.ROUTE}` +
                  `?keyring_id=${this.cert?.[FORM_PARAMS.KEYRING]}&generic_key_id=${this.cert?.[FORM_PARAMS.KMS_PRIVATE_KEY]}`,
              },
            ]
          : '-',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.HAS_PRIVATE_KEY,
        label: 'Has Private Key',
        field: 'checkbox',
        defaultValue: this.cert.has_private_key,
        disabled: true,
        hidden: this.cert.has_private_key === undefined,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNATURE_ALGORITHM,
        label: 'Signature Algorithm',
        field: 'label',
        defaultValue: this.cert.signature_hash_algorithm,
        hidden: this.cert.mode !== CA_MODE_VALUES.X509,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNATURE,
        label: 'Signature',
        field: 'copy',
        defaultValue: this.cert.signature,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.MD5_FINGERPRINT,
        label: 'MD5 Fingerprint',
        field: 'copy',
        defaultValue: this.formatFingerprint(this.cert.fingerprint_md5),
        hidden: this.cert.mode !== CA_MODE_VALUES.X509,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SHA1_FINGERPRINT,
        label: 'SHA-1 Fingerprint',
        field: 'copy',
        defaultValue: this.formatFingerprint(this.cert.fingerprint_sha1),
        hidden: this.cert.mode !== CA_MODE_VALUES.X509,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SHA256_FINGERPRINT,
        label: 'SHA-256 Fingerprint',
        field: 'copy',
        defaultValue: this.formatFingerprint(this.cert.fingerprint_sha256),
        hidden: this.cert.mode !== CA_MODE_VALUES.X509,
      } as FormItem),
    ];
    this.fields = field;
    setTimeout(() => {
      this.isLoading = false;
    });
  }

  /**
   * Set keyring Name, public and private key names
   */
  getKeyringAndKeyName(keyringId: any, publicKeyId?: any, privateKeyId?: any) {
    this.isLoading = true;
    forkJoin([
      this.kmsSrv.getKeyring(keyringId),
      !!publicKeyId ? this.kmsSrv.getGenericKey(keyringId, publicKeyId) : EMPTY,
      !!privateKeyId ? this.kmsSrv.getGenericKey(keyringId, privateKeyId) : EMPTY,
    ])
      .pipe(
        finalize(() => {
          this.initForm();
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: ([keyringData, publicKeyData, privateKeyData]) => {
          const keyringName = keyringData?.data?.name;
          const publicKeyName = publicKeyData?.data?.label;
          const privateKeyName = privateKeyData?.data?.label;
          if (keyringName) {
            this.cert.kms_keyring_name = keyringName;
          }
          if (publicKeyName) {
            this.cert.kms_public_key_name = publicKeyName;
          }
          if (privateKeyName) {
            this.cert.kms_private_key_name = privateKeyName;
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Download the UCRT/PEM file for the certificate
   */
  downloadCertificate() {
    let extension = '';
    switch (this.cert?.mode) {
      case CA_MODE_VALUES.MICROPKI: {
        extension = 'ucrt';
        break;
      }
      default:
        extension = 'pem';
        break;
    }
    this.util.downloadFileFromText(this.cert?.pem, `cert_${this.cert?.id}_${this.cert?.subject?.CN}.${extension}`.replace(/ /g, '_').toLowerCase());
    this.showSuccessMessage('Downloaded certificate successfully');
  }

  /**
   * Format fingerprint
   * @param fingerprintString
   */
  formatFingerprint(fingerprintString: any) {
    if (!!fingerprintString) {
      const pairStringArray: string[] = [];
      let subStr = '';
      for (let index = 0; index < fingerprintString.length; index++) {
        const character = fingerprintString[index];
        subStr += character;
        if (subStr.length === 2) {
          pairStringArray.push(subStr);
          subStr = '';
        } else if (index === fingerprintString.length - 1) {
          pairStringArray.push(subStr);
        }
      }
      return pairStringArray.join(':');
    }
    return '';
  }

  /**
   * Check if is the page
   */
  get isPage() {
    return !this.dialogConfig || !this.dialogConfig?.data || !this.dialogConfig?.data?.cert;
  }
}
