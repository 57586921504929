import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { LOG_TYPES, LOG_TYPE_VALUES } from '@lcms-constants';
import { LogService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { LogViewerComponent } from '@microsec/components';
import { Observable, Subscriber, delay, finalize } from 'rxjs';

@Component({
  selector: 'app-system-logs',
  templateUrl: './system-logs.component.html',
  styleUrls: ['./system-logs.component.scss'],
})
export class SystemLogsComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  textLog = '';

  searchText = '';

  searchLogText = '';

  logTypes: any[] = this.util.cloneObjectArray(LOG_TYPES);

  logType: any = LOG_TYPE_VALUES.RA_ENROLLMENT_API;

  _page = 0;

  set page(value: any) {
    this._page = value;
    if (!!value) {
      setTimeout(() => {
        this.logViewer?.addLoadedPages(value);
      });
    }
  }

  get page() {
    return this._page;
  }

  totalPage = 0;

  @ViewChild('logViewer') logViewer!: LogViewerComponent;

  constructor(private logSrv: LogService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.logType = this.logTypes[0].value;
    this.getSystemLogs();
  }

  /**
   * Get system logs
   * @param event
   */
  getSystemLogs(direction: any = null, callback?: any) {
    this.searchText = '';
    this.searchLogText = '';
    switch (direction) {
      case 'top': {
        this.page = this.totalPage;
        break;
      }
      case 'up': {
        if (this.page < this.totalPage && !this.isLoading) {
          this.page += 1;
        }
        break;
      }
      case 'bottom': {
        this.page = 1;
        break;
      }
      case 'down': {
        if (this.page > 1 && !this.isLoading) {
          this.page -= 1;
        }
        break;
      }
      default: {
        this.page = 1;
        this.logViewer.loadedPages = [];
        break;
      }
    }
    if (
      !this.logViewer?.loadedPages?.includes(this.page) &&
      (!this.isLoading || (!!this.isLoading && (direction === 'top' || direction === 'bottom')))
    ) {
      this.logSrv
        .getLogs(this.logType, this.page)
        .pipe(
          delay(500),
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (rs: any) => {
            this.page = rs?.page;
            this.totalPage = rs?.total_pages;
            switch (direction) {
              case 'top':
              case 'up': {
                this.textLog = (rs?.log || '') + this.textLog;
                setTimeout(() => {
                  if (direction === 'top') {
                    this.getSystemLogs('down', () => {
                      this.logViewer?.scroller?.scrollToTop();
                    });
                  } else if (direction === 'up' && this.logViewer?.scroller?.scrollHeight <= this.logViewer?.scroller?.clientHeight) {
                    this.getSystemLogs('up', () => {
                      this.logViewer?.scroller?.keepCurrentScrollView('up');
                    });
                  } else if (!!callback) {
                    callback();
                  } else {
                    this.logViewer?.scroller?.keepCurrentScrollView('up');
                  }
                });
                break;
              }
              case 'bottom':
              case 'down': {
                this.textLog += rs?.log || '';
                setTimeout(() => {
                  if (direction === 'bottom') {
                    this.getSystemLogs('up', () => {
                      this.logViewer?.scroller?.scrollToLast();
                    });
                  } else if (direction === 'down' && this.logViewer?.scroller?.scrollHeight <= this.logViewer?.scroller?.clientHeight) {
                    this.getSystemLogs('down', () => {
                      this.logViewer?.scroller?.keepCurrentScrollView('down');
                    });
                  } else if (!!callback) {
                    callback();
                  } else {
                    this.logViewer?.scroller?.keepCurrentScrollView('down');
                  }
                });
                break;
              }
              default: {
                if (!!this.logViewer?.scroller) {
                  this.logViewer.scroller.lastScrollerHeight = 0;
                }
                this.textLog = rs?.log || '';
                setTimeout(() => {
                  if (this.logViewer?.scroller?.scrollHeight <= this.logViewer?.scroller?.clientHeight) {
                    this.getSystemLogs('up', () => {
                      this.logViewer?.scroller?.scrollToLast();
                    });
                  } else {
                    this.logViewer?.scroller?.scrollToLast();
                  }
                });
                break;
              }
            }
            if (!!callback) {
              callback();
            }
          },
          error: (err) => {
            this.showErrorMessage(err);
          },
        });
    }
  }

  searchLog(event: any) {
    if (event?.keyCode === 13) {
      this.searchLogText = this.searchText;
    }
  }

  changeLogType(event: any) {
    this.logType = event?.value;
    this.page = 0;
    this.getSystemLogs();
  }

  scrollTo(postion: string) {
    this.isLoading = true;
    this.logViewer.loadedPages = [];
    if (!!this.logViewer?.scroller) {
      this.logViewer.scroller.lastScrollerHeight = 0;
    }
    switch (postion) {
      case 'top': {
        this.textLog = '';
        this.getSystemLogs('top', () => {
          setTimeout(() => {
            this.logViewer?.scroller?.scrollToTop();
          });
        });
        break;
      }
      case 'bottom': {
        this.textLog = '';
        this.getSystemLogs('bottom', () => {
          setTimeout(() => {
            this.logViewer?.scroller?.scrollToLast();
          });
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  downloadAllLogs() {
    this.getAllLogs().subscribe((allLogs) => {
      this.util.downloadFileFromText(allLogs, `System_Logs_${this.util.generateRandomNumberString()}.txt`);
    });
  }

  private getAllLogs() {
    const obs = new Observable<string>((observer) => {
      this.getLogs(observer);
    });
    return obs;
  }

  private getLogs(observer: Subscriber<string>, lastResultLog = '', page = 1) {
    let resultLog = lastResultLog;
    this.logSrv.getLogs(this.logType, page).subscribe((rs) => {
      resultLog = rs?.log + resultLog;
      if (rs?.page < rs?.total_pages) {
        this.getLogs(observer, resultLog, ++page);
      } else {
        observer.next(resultLog);
      }
    });
  }
}
