<!-- TOOLBAR -->
<p-toolbar [styleClass]="'metric-toolbar' + (!!selectedDevice ? ' not-page' : '')">
  <div class="flex align-items-start justify-content-between">
    <div class="grid">
      <!-- Granularity -->
      <div class="col-fixed grid align-items-center">
        <label class="col-fixed mr-2">Granularity</label>
        <div class="col-fixed data-field">
          <p-dropdown
            [options]="granularityOptions"
            optionLabel="label"
            optionValue="value"
            appendTo="body"
            [(ngModel)]="config.granularity"
            (onChange)="changeGranularity($event)"
            [disabled]="!!isLoading" />
        </div>
      </div>

      <!-- Zoom -->
      @if (!!config.realtime) {
        <div class="col-fixed grid align-items-center">
          <label class="col-fixed mr-2">Zoom</label>
          <div class="col-fixed data-field">
            <p-dropdown
              [options]="zoomOptions"
              optionLabel="label"
              optionValue="value"
              appendTo="body"
              [(ngModel)]="config.zoom"
              (onChange)="changeZoom($event)"
              [disabled]="!!isLoading" />
          </div>
        </div>
      }

      <!-- From -->
      @if (!config.realtime) {
        <div class="col-fixed grid align-items-center">
          <label class="col-fixed mr-2">From</label>
          <div class="col-fixed data-field">
            <p-calendar
              [showTime]="true"
              appendTo="body"
              [(ngModel)]="config.from"
              [readonlyInput]="true"
              [maxDate]="config.to"
              (onClose)="changeDateTime()"
              [dateFormat]="PRIMENG_CALENDAR_DATE"
              [disabled]="!!isLoading" />
          </div>
        </div>
      }

      <!-- To -->
      @if (!config.realtime) {
        <div class="col-fixed grid align-items-center">
          <label class="col-fixed mr-2">To</label>
          <div class="col-fixed data-field">
            <p-calendar
              [showTime]="true"
              appendTo="body"
              [(ngModel)]="config.to"
              [readonlyInput]="true"
              [minDate]="config.from"
              (onClose)="changeDateTime()"
              [dateFormat]="PRIMENG_CALENDAR_DATE"
              [disabled]="!!isLoading" />
          </div>
        </div>
      }

      <!-- Realtime -->
      @if (!!selectedDevice) {
        <div class="col-fixed grid align-items-center">
          <label class="col-fixed mr-2">Realtime</label>
          <div class="col-fixed data-field">
            <p-inputSwitch [(ngModel)]="config.realtime" (onChange)="toggleRealTime($event)" [disabled]="!!isLoading" />
          </div>
        </div>
      }
    </div>

    <!-- Refresh Area -->
    @if (!config.realtime) {
      <div class="grid align-items-start line-height-4 refresh-area">
        <div class="col-12 align-items-center text-right">
          <microsec-refresh-button [lastRefresh]="config?.lastRefresh" (refreshEvent)="initData(true)" />
        </div>
      </div>
    }
  </div>
</p-toolbar>

<!-- Chart Areas -->
<div class="grid mt-2">
  @for (chart of charts; track $index) {
    <div
      [ngClass]="{
        'col-12 md:col-6': !selectedDevice,
        'col-12': !!selectedDevice || chart.key === DIAGRAMS.NETWORK.KEY
      }">
      <microsec-card [isFull]="true">
        <microsec-common-chart #diagram [chartConfig]="chart" legendPosition="bottom" [legendPosition]="null" [isLoading]="isLoading" />
      </microsec-card>
    </div>
  }
</div>
