import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DeviceService, PackageService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PackageResultFormComponent } from '../package-result-form/package-result-form.component';

const FORM_PARAMS = {
  NAME: 'name',
  VERSION: 'version',
  DEVICES: 'devices',
};

@Component({
  selector: 'app-package-form',
  templateUrl: './package-form.component.html',
  styleUrls: ['./package-form.component.scss'],
})
export class PackageFormComponent extends BaseComponent implements OnInit {
  isLoading = false;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  mode = '';

  packages: any[] = [];

  selectedDevices: any[] = [];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private deviceSrv: DeviceService,
    private packageSrv: PackageService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.mode = this.dialogConfig?.data?.mode;
    this.packages = this.dialogConfig?.data?.packages;
    // If having packages, get devices
    if (!!this.packages.length) {
      this.getPackagesDevices();
    }
    this.initForm();
  }

  /**
   * Initialize form
   */
  initForm() {
    const devicesField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.DEVICES,
      label: 'Device(s)',
      field: 'lazy-multiselect',
      options: [] as any[],
      required: true,
      placeholder: 'Select device(s)',
      fieldInfo: 'Device(s) package installed on',
      defaultValue: [],
      focused: this.mode === 'delete',
      onShowEvent: new EventEmitter<any>(),
      onChangeObjectEvent: new EventEmitter<any>(),
    } as FormItem);
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: 'Specify a package to be installed on the device(s) (if supported)',
        field: 'text',
        hidden: this.mode !== 'install',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Update the package(s) on the device(s) (if supported)',
        field: 'text',
        hidden: this.mode !== 'update',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Downgrade the package(s) on the device(s) (if supported)',
        field: 'text',
        hidden: this.mode !== 'downgrade',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Delete the package(s) on the device(s)',
        field: 'text',
        hidden: this.mode !== 'delete',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: this.mode === 'install' ? 'Package Name' : 'Package(s) Name',
        field: this.mode === 'install' ? 'input' : 'labelList',
        required: true,
        fieldInfo: this.mode === 'install' ? 'Name of the package' : 'Name of the package(s)',
        defaultValue: this.mode === 'install' ? '' : this.packages.map((pkg) => pkg.name),
        focused: this.mode === 'install',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.VERSION,
        label: 'Package Version',
        fieldInfo: 'Version of the package',
        defaultValue: '',
        focused: this.mode === 'update' || this.mode === 'downgrade',
        hidden: this.mode === 'delete' || this.packages.length > 1,
      } as FormItem),
      devicesField,
    ];
    devicesField.onShowEvent?.subscribe((searchText: any) => {
      this.deviceSrv
        .getAllDevicesNonPaginated(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId, true, searchText)
        .subscribe((result) => {
          devicesField.options = ((result.devices as any[]) || []).map((device) => {
            return {
              ...device,
              value: device.id,
              label: device.common_name,
            };
          });
        });
    });
    devicesField.onChangeObjectEvent?.subscribe((devices) => {
      this.selectedDevices = devices;
    });
    this.fields = fields;
  }

  /**
   * Get package devices
   */
  getPackagesDevices() {
    this.isLoading = true;
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = forkJoin(this.packages.map((pkg) => this.packageSrv.getDevicesByPackage(this.breadcrumbConfig?.projectId, pkg.name)))
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (packagesDevices: any[]) => {
          const devices: any[] = [];
          packagesDevices.forEach((packageDevices) => {
            devices.push(...packageDevices.devices);
          });
          const devicesField = this.fields.find((p) => p.name === FORM_PARAMS.DEVICES);
          if (!!devicesField) {
            devicesField.options = Array.from(new Set(devices.map((a) => a.id))).map((id) => devices.find((a) => a.id === id));
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Submit form
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    this.isLoading = true;
    const formValue = this.form.getRawValue();
    const packages: any[] = [];
    const devices = this.selectedDevices.map((p) => p.id);
    switch (this.mode) {
      case 'install':
        packages.push({
          [FORM_PARAMS.NAME]: formValue[FORM_PARAMS.NAME],
          [FORM_PARAMS.VERSION]: formValue[FORM_PARAMS.VERSION],
          operation: 'installation',
          devices,
        });
        break;
      case 'delete':
        this.packages.forEach((pkg) => {
          packages.push({
            [FORM_PARAMS.NAME]: pkg[FORM_PARAMS.NAME],
            [FORM_PARAMS.VERSION]: formValue[FORM_PARAMS.VERSION],
            operation: 'deletion',
            devices,
          });
        });
        break;
      default:
        this.packages.forEach((pkg) => {
          packages.push({
            [FORM_PARAMS.NAME]: pkg[FORM_PARAMS.NAME],
            [FORM_PARAMS.VERSION]: formValue[FORM_PARAMS.VERSION],
            operation: this.mode,
            devices,
          });
        });
        break;
    }
    const requests: Observable<any>[] = packages.map((packagePayload) =>
      this.packageSrv.postPackageOperation(this.breadcrumbConfig?.projectId, packagePayload),
    );
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (results) => {
          const dialog = this.dialogSrv.open(PackageResultFormComponent, {
            data: {
              type: 'package',
              packages,
              results,
            },
            header: `Package ${this.util.capitalizeWord(packages?.[0]?.operation || '')} Results`,
            width: '60vw',
            height: 'min-content',
            closeOnEscape: true,
          });
          dialog.onClose.subscribe(() => {
            closeDialog();
          });
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get mode name
   */
  get modeName() {
    return this.mode.charAt(0).toUpperCase() + this.mode.slice(1);
  }
}
