import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent extends BaseComponent implements OnInit {
  requestId: any = '';

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  ngOnInit() {
    this.requestId = this.dialogConfig?.data?.requestId;
  }

  /**
   * Copy value
   */
  copyValue() {
    this.util.copyToClipboard(this.requestId);
    this.showSuccessMessage('Copied the request ID');
  }
}
