// EST
export const EST_ENROLLMENT_AUTHENTICATION_MODE_VALUES = {
  MANUAL: 'manual',
  BASIC_AUTH: 'basic_auth',
  BASIC_AUTH_AND_MANUAL: 'basic_auth_and_manual',
};

export const EST_ENROLLMENT_AUTHENTICATION_MODES = [
  { value: EST_ENROLLMENT_AUTHENTICATION_MODE_VALUES.MANUAL, label: 'Manual' },
  {
    value: EST_ENROLLMENT_AUTHENTICATION_MODE_VALUES.BASIC_AUTH,
    label: 'Basic Authentication with OTP',
  },
  {
    value: EST_ENROLLMENT_AUTHENTICATION_MODE_VALUES.BASIC_AUTH_AND_MANUAL,
    label: 'Basic Authentication with OTP + Manual',
  },
];

// SCEP
export const SCEP_ENROLLMENT_AUTHENTICATION_MODE_VALUES = {
  MANUAL: 'manual',
  CHALLENGE_PASSWORD: 'challenge_password',
  CHALLENGE_PASSWORD_AND_MANUAL: 'challenge_password_and_manual',
};

export const SCEP_ENROLLMENT_AUTHENTICATION_MODES = [
  { value: SCEP_ENROLLMENT_AUTHENTICATION_MODE_VALUES.MANUAL, label: 'Manual' },
  {
    value: SCEP_ENROLLMENT_AUTHENTICATION_MODE_VALUES.CHALLENGE_PASSWORD,
    label: 'Basic Authentication with OTP',
  },
  {
    value: SCEP_ENROLLMENT_AUTHENTICATION_MODE_VALUES.CHALLENGE_PASSWORD_AND_MANUAL,
    label: 'Basic Authentication with OTP + Manual',
  },
];

// DEVICE RE-ENROLLMENT STRATEGY
export const DEVICE_REENROLLMENT_STRATEGY_VALUES = {
  DISALLOW: 'disallow',
  ALLOW_NO_REVOKE: 'allow_no_revoke',
  ALLOW_REVOKE: 'allow_revoke',
};

export const DEVICE_REENROLLMENT_STRATEGIES = [
  {
    value: DEVICE_REENROLLMENT_STRATEGY_VALUES.DISALLOW,
    label: 'Disallow device enrollment if device already exists in project (default)',
  },
  {
    value: DEVICE_REENROLLMENT_STRATEGY_VALUES.ALLOW_NO_REVOKE,
    label: 'Allow device enrollment if device already exists in project (auto-approved, no auto-revoke)',
  },
  {
    value: DEVICE_REENROLLMENT_STRATEGY_VALUES.ALLOW_REVOKE,
    label: 'Allow device enrollment if device already exists in project (auto-approved, auto-revoke)',
  },
];

export const ENROLLMENT_TYPES = {
  ENROLL: 'enroll',
  SERVER_KEYGEN: 'serverkeygen',
};
