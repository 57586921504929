import { Component, OnInit, ViewChild } from '@angular/core';
import { fromDevicesActions } from '@ngrx-devices';
import { PackageService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { DELETE_LABEL } from '@microsec/constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize, forkJoin, Observable } from 'rxjs';
import { PackageResultFormComponent } from '../../../../packages/package-result-form/package-result-form.component';

const FORM_PARAMS = {
  NAME: 'name',
  VERSION: 'version',
};

@Component({
  selector: 'app-device-package-form',
  templateUrl: './device-package-form.component.html',
  styleUrls: ['./device-package-form.component.scss'],
})
export class DevicePackageFormComponent extends BaseComponent implements OnInit {
  isLoading = false;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;
  mode = '';

  packages: any[] = [];

  device: any = null;

  DELETE_LABEL = DELETE_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private packageSrv: PackageService,
  ) {
    super();
  }

  ngOnInit() {
    this.mode = this.dialogConfig?.data?.mode;
    this.packages = this.dialogConfig?.data?.packages;
    this.device = this.dialogConfig?.data?.device;
    this.initForm();
  }

  /**
   * Initialize form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: 'Specify a package to be installed on the device (if supported)',
        field: 'text',
        hidden: this.mode !== 'install',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Update the package(s) on the device (if supported)',
        field: 'text',
        hidden: this.mode !== 'update',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Downgrade the package(s) on the device (if supported)',
        field: 'text',
        hidden: this.mode !== 'downgrade',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Delete the package(s) on the device',
        field: 'text',
        hidden: this.mode !== 'delete',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: this.mode === 'install' ? 'Package Name' : 'Package(s) Name',
        field: this.mode === 'install' ? 'input' : 'labelList',
        required: true,
        fieldInfo: this.mode === 'install' ? 'Name of the package' : 'Name of the package(s)',
        defaultValue: this.mode === 'install' ? '' : this.packages.map((pkg) => `${pkg.name} ${pkg.latest_version}`),
        focused: this.mode === 'install',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.VERSION,
        label: 'Package Version',
        fieldInfo: 'Version of the package',
        defaultValue: '',
        focused: this.mode === 'update' || this.mode === 'downgrade',
        hidden: this.mode === 'delete' || this.packages.length > 1,
      } as FormItem),
    ];
    this.fields = fields;
  }

  /**
   * Submit form
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    this.isLoading = true;
    const formValue = this.form.getRawValue();
    const packages: any[] = [];
    switch (this.mode) {
      case 'install':
        packages.push({
          [FORM_PARAMS.NAME]: formValue[FORM_PARAMS.NAME],
          [FORM_PARAMS.VERSION]: formValue[FORM_PARAMS.VERSION],
          operation: 'installation',
        });
        break;
      case 'delete':
        this.packages.forEach((pkg) => {
          packages.push({
            [FORM_PARAMS.NAME]: pkg[FORM_PARAMS.NAME],
            [FORM_PARAMS.VERSION]: formValue[FORM_PARAMS.VERSION],
            operation: 'deletion',
          });
        });
        break;
      default:
        this.packages.forEach((pkg) => {
          packages.push({
            [FORM_PARAMS.NAME]: pkg[FORM_PARAMS.NAME],
            [FORM_PARAMS.VERSION]: formValue[FORM_PARAMS.VERSION],
            operation: this.mode,
          });
        });
        break;
    }

    const requests: Observable<any>[] = packages.map((packagePayload) => this.packageSrv.postDevicePackageOperation(this.device?.id, packagePayload));
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (results) => {
          const dialog = this.dialogSrv.open(PackageResultFormComponent, {
            data: {
              type: 'device',
              packages,
              results,
            },
            header: `Package ${this.util.capitalizeWord(packages?.[0]?.operation || '')} Results`,
            width: '60vw',
            height: 'min-content',
            closeOnEscape: true,
          });
          dialog.onClose.subscribe(() => {
            this.store.dispatch(new fromDevicesActions.RefreshDeviceEvents());
            closeDialog();
          });
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get mode name
   */
  get modeName() {
    return this.mode.charAt(0).toUpperCase() + this.mode.slice(1);
  }
}
