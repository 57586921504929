<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="container-border">
    <microsec-details-panel [title]="selectedTemplate?.name" [buttons]="actionButtons" (collapseEvent)="unselectTemplate()">
      <p-tabView [scrollable]="true" styleClass="microsec-dashboard-tab">
        <p-tabPanel header="Details">
          <app-envelope-template-form
            [templates]="values"
            [selectedTemplate]="selectedTemplate"
            [mode]="'view'"
            (refreshTemplatesListEvent)="refreshTemplatesList()" />
        </p-tabPanel>
        @if (currentScope === SCOPE.PROJECT && permissions[SCOPE.PROJECT][USER_ROLE.READ_ONLY]) {
          <p-tabPanel header="Envelopes">
            <app-envelopes [selectedTemplate]="selectedTemplate" />
          </p-tabPanel>
        }
      </p-tabView>
    </microsec-details-panel>
  </div>
</microsec-loading-overlay>
