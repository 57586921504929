<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="container-border">
    <microsec-details-panel [title]="selectedRepo?.name" [buttons]="actionButtons" (collapseEvent)="unselectRepo()">
      <p-tabView styleClass="microsec-dashboard-tab">
        <p-tabPanel header="Overview">
          <app-repository-overview [selectedRepo]="selectedRepo" />
        </p-tabPanel>
        <p-tabPanel header="Packages">
          <app-repository-packages [selectedRepo]="selectedRepo" (refreshRepositoriesEvent)="getRepositories()" />
        </p-tabPanel>
      </p-tabView>
    </microsec-details-panel>
  </div>
</microsec-loading-overlay>
