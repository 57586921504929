/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Observable, scheduled, asyncScheduler } from 'rxjs';
import { IKmsService } from './IKmsService';
import managersData from './mock-data/managers.json';
import tokensData from './mock-data/tokens.json';
import keyringsData from './mock-data/keyrings.json';
import genericKeysData from './mock-data/generic-keys.json';
import gpgKeysData from './mock-data/gpg-keys.json';
import keyPurposeAlgoData from './mock-data/key-purposes-algorithms.json';

@Injectable({
  providedIn: 'root',
})
export class MockKmsService implements IKmsService {
  /**
   * ========================== CRYPTOGRAPHIC TOKEN MANAGER ==========================
   */

  getCryptokenManagers(projectId: any): Observable<any[]> {
    return scheduled([managersData], asyncScheduler);
  }

  getCryptokenManager(id: any): Observable<any[]> {
    return scheduled([{ ...(managersData as any)[0], id }], asyncScheduler);
  }

  createCryptokenManager(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateCryptokenManager(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteCryptokenManager(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  /**
   * ========================== CONNECTED TOKEN ==========================
   */

  getCryptokens(managerId: any): Observable<any[]> {
    return scheduled([tokensData], asyncScheduler);
  }

  loginCryptoken(managerId: any, tokenId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  logoutCryptoken(managerId: any, tokenId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  /**
   * ========================== KEYRING ==========================
   */

  getKeyrings(projectId: any): Observable<any> {
    return scheduled([keyringsData], asyncScheduler);
  }

  getKeyring(keyringId: any): Observable<any> {
    return scheduled([keyringsData.data.find((p) => p.id === keyringId)], asyncScheduler);
  }

  createKeyring(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateKeyring(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteKeyring(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  /**
   * ========================== GENERIC KEYS ==========================
   */
  getGenericKeys(keyringId: any): Observable<any> {
    // No generic keys for microsec_default_keyring (always keyringId = 1)
    return scheduled(keyringId === 1 ? [] : [genericKeysData], asyncScheduler);
  }

  getGenericKey(keyringId: any, keyId: any): Observable<any> {
    return scheduled([genericKeysData.data.find((p) => p.id === keyId)], asyncScheduler);
  }

  createGenericKey(keyringId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteGenericKey(keyringId: any, keyId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  downloadGenericKey(keyringId: any, keyId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  /**
   * ========================== GPG KEYS ==========================
   */

  getGPGKeys(keyringId: any): Observable<any> {
    return scheduled([gpgKeysData], asyncScheduler);
  }
  getGPGKey(keyringId: any, keyId: any): Observable<any> {
    return scheduled([gpgKeysData.data.find((p) => p.id === keyId)], asyncScheduler);
  }

  createGPGKey(keyringId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateGPGKey(keyringId: any, keyId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteGPGKey(keyringId: any, keyId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  downloadGPGPrivateKey(keyringId: any, keyId: any) {
    return scheduled([{}], asyncScheduler);
  }

  /**
   * ========================== Key Purpose Algorithm ==========================
   */
  getKeyPurposeAlgorithm() {
    return scheduled([keyPurposeAlgoData], asyncScheduler);
  }
}
