import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { DeviceService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { IMPORT_LABEL } from '@microsec/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

const FORM_PARAMS = {
  LABEL: 'label',
  CA_CERTIFICATE_FILE: 'pem_file',
  CA_CERTIFICATE: 'ca_pem',
  PATH_TO_UPLOAD: 'file_path',
  CERT_PATH_HINT: 'cert_path_hint',
};

@Component({
  selector: 'app-dca-import-ca-certificate-form',
  templateUrl: './dca-import-ca-certificate-form.component.html',
  styleUrls: ['./dca-import-ca-certificate-form.component.scss'],
})
export class DcaImportCaCertificateFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  device: any = null;

  IMPORT_LABEL = IMPORT_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private deviceSrv: DeviceService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    this.device = this.dialogConfig.data?.device;
    this.prepareConfigs();
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        field: 'text',
        label: 'Add a new CA certificate to the device trust store.',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Label',
        field: 'input',
        fieldInfo: 'CA Certificate Label',
        required: true,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_CERTIFICATE_FILE,
        label: 'CA Certificate',
        field: 'file',
        fieldInfo: 'CA certificate',
        uploadEvent: new EventEmitter(),
        required: true,
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_CERTIFICATE,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PATH_TO_UPLOAD,
        label: 'Path to Upload',
        field: 'input',
        fieldInfo: 'System path which will be used to copy the CA certificate file to e.g. /tmp/ or /usr/local/share/ca-certificates/.',
        defaultValue: '/tmp/',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CERT_PATH_HINT,
        field: 'text',
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;
    this.setupUploadEvent();
    setTimeout(() => {
      this.setupEvents();
    });
  }

  /**
   * Setup CSR file upload event
   */
  setupUploadEvent() {
    const fileField = this.fields.find((p) => p.name === FORM_PARAMS.CA_CERTIFICATE_FILE);
    if (!!fileField) {
      fileField.uploadEvent?.subscribe((event: any) => {
        this.form.isLoading = true;
        if (!!event.target && !!event.target.files && !!event.target.files.length) {
          const file: any = (event.target.files as FileList).item(0);
          this.form.setControlValue(FORM_PARAMS.CA_CERTIFICATE_FILE, file);
          this.form.setControlValue(FORM_PARAMS.LABEL, file.name.split('.')[0]);
          const reader = new FileReader();
          reader.onload = () => {
            this.form.isLoading = false;
            this.form.setControlValue(FORM_PARAMS.CA_CERTIFICATE, reader.result?.toString());
          };
          reader.readAsText(file);
        } else {
          this.form.isLoading = false;
          this.form.setControlValue(FORM_PARAMS.CA_CERTIFICATE_FILE, null);
          this.form.setControlValue(FORM_PARAMS.CA_CERTIFICATE, null);
        }
      });
    }
  }

  setupEvents() {
    this.form.setChangeEvent(FORM_PARAMS.LABEL, (value) => {
      const certDirectory = this.form.getControlValue(FORM_PARAMS.PATH_TO_UPLOAD);
      this.populateHintField(value, certDirectory);
    });
    this.form.setChangeEvent(FORM_PARAMS.PATH_TO_UPLOAD, (value) => {
      const certLabel = this.form.getControlValue(FORM_PARAMS.LABEL);
      this.populateHintField(certLabel, value);
    });
  }

  private populateHintField(certLabel: string, directoryLabel: string) {
    const hintField = this.fields.find((p) => p.name === FORM_PARAMS.CERT_PATH_HINT);
    this.form.setControlVisibility(FORM_PARAMS.CERT_PATH_HINT, !!hintField && !!directoryLabel && !!certLabel);
    if (!!hintField) {
      hintField.label =
        !!certLabel && !!directoryLabel
          ? `The full certificate path is ${directoryLabel}${directoryLabel.slice(-1) !== '/' ? '/' : ''}${certLabel}.pem`
          : '';
    }
  }

  /**
   * Import CA certificate
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = this.form.getRawValue();
    delete payload[FORM_PARAMS.CA_CERTIFICATE_FILE];
    delete payload[FORM_PARAMS.CERT_PATH_HINT];
    this.form.isLoading = true;
    this.deviceSrv
      .importCACertificate(this.device.id, payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`CA certificate import is being processed.`);
          closeDialog();
        },
        error: (err) => {
          if (!!err?.error?.errors?.[FORM_PARAMS.CA_CERTIFICATE]) {
            const errors = err.error.errors;
            errors[FORM_PARAMS.CA_CERTIFICATE_FILE] = errors?.[FORM_PARAMS.CA_CERTIFICATE];
          }
          this.showErrorMessage(err);
        },
      });
  }
}
