export const DEVICE_OVERVIEW_CONSTANTS = {
  GENERAL: {
    ROUTE: 'general',
    LABEL: 'General',
  },
  SECURITY: {
    ROUTE: 'security',
    LABEL: 'Security',
  },
  CRYPTO_ASSETS: {
    ROUTE: 'crypto-assets',
    LABEL: 'Crypto Assets',
  },
};
