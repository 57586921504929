<div class="grid">
  <div class="col-12 text-data mb-2">
    Please enter the following fields to create a CSR and select a CA and CA template for certificate generation.
  </div>
  <div class="col-12 xl:col-6 lg:col-12 md:col-12 sm:col-12">
    <microsec-form-builder
      #fb
      [isPage]="true"
      [fields]="fields"
      [shouldShowActionButtons]="true"
      submitButtonLabel="Download Certificate"
      [submitButtonStyleClass]="'p-button-primary'"
      (submitEvent)="onSubmit()"
      [cancelButtonLabel]="''" />
  </div>
</div>
