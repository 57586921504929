import { faCogs, faEnvelope, faFolderPlus, faLaptop, faUser } from '@fortawesome/free-solid-svg-icons';
import { DEVICE_MANAGEMENT_FEATURES, FEATURES, PKI_MANAGEMENT_FEATURES, SUPPLY_CHAIN_MANAGEMENT_FEATURES } from '@lcms-constants';
import { ProjectSettingsComponent, PROJECT_MANAGEMENT_CHILDREN as COMMON_PROJECT_MANAGEMENT_CHILDREN } from '@lcms-products';
import { PROJECT_MANAGEMENT_CONSTANTS, PROJECT_MANAGEMENT_GROUPS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { ENVELOPES_CHILDREN } from '../shared-components/shared-envelope/shared-envelope.config';
import { DevicesComponent } from './overview/devices/devices.component';
import { DEVICES_CHILDREN } from './overview/devices/devices.config';
import { EnvelopeManagementComponent } from './overview/envelope-management/envelope-management.component';
import { RepositoryManagementComponent } from './overview/repository-management/repository-management.component';
import { REPOSITORY_MANAGEMENT_CHILDREN } from './overview/repository-management/repository-management.config';
import { UserCertificatesComponent } from './overview/user-certificates/user-certificates.component';
import { USER_CERTIFICATES_CHILDREN } from './overview/user-certificates/user-certificates.config';
import { PROJECT_SETTINGS_CHILDREN } from './settings/project-settings/project-settings.config';

export const PROJECT_MANAGEMENT_CHILDREN: ILayoutItem[] = [
  {
    group: PROJECT_MANAGEMENT_GROUPS.OVERVIEW,
    route: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE,
    icon: faLaptop,
    label: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.LABEL,
    component: DevicesComponent,
    children: DEVICES_CHILDREN,
    visible: false,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.OVERVIEW,
    route: PROJECT_MANAGEMENT_CONSTANTS.USER_CERTIFICATES.ROUTE,
    icon: faUser,
    label: PROJECT_MANAGEMENT_CONSTANTS.USER_CERTIFICATES.LABEL,
    component: UserCertificatesComponent,
    children: USER_CERTIFICATES_CHILDREN,
    featureName: FEATURES.PKI_MANAGEMENT,
    featureCheckField: PKI_MANAGEMENT_FEATURES.USER_CERTIFICATES,
    visible: false,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.OVERVIEW,
    route: PROJECT_MANAGEMENT_CONSTANTS.ENVELOPES.ROUTE,
    icon: faEnvelope,
    label: PROJECT_MANAGEMENT_CONSTANTS.ENVELOPES.LABEL,
    component: EnvelopeManagementComponent,
    children: ENVELOPES_CHILDREN,
    featureName: FEATURES.SUPPLY_CHAIN_MANAGEMENT,
    featureCheckField: SUPPLY_CHAIN_MANAGEMENT_FEATURES.ENVELOPES,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.OVERVIEW,
    route: PROJECT_MANAGEMENT_CONSTANTS.REPOSITORIES.ROUTE,
    icon: faFolderPlus,
    label: PROJECT_MANAGEMENT_CONSTANTS.REPOSITORIES.LABEL,
    component: RepositoryManagementComponent,
    children: REPOSITORY_MANAGEMENT_CHILDREN,
    featureName: FEATURES.DEVICE_MANAGEMENT,
    featureCheckField: DEVICE_MANAGEMENT_FEATURES.PACKAGES,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.SETTINGS,
    route: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    icon: faCogs,
    label: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.LABEL,
    component: ProjectSettingsComponent,
    children: PROJECT_SETTINGS_CHILDREN,
    visible: false,
  },
  ...COMMON_PROJECT_MANAGEMENT_CHILDREN,
];
