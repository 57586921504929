import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { CaManagementService, EnvelopeService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { VERIFY_LABEL } from '@microsec/constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  CA_SERVER_ID: 'ca_server_id',
  CA_ID: 'ca_id',
  ENVELOPE_TEMPLATE_ID: 'envelope_template_id',
  ENVELOPE: 'envelope',
};

@Component({
  selector: 'app-envelope-verification-form',
  templateUrl: './envelope-verification-form.component.html',
  styleUrls: ['./envelope-verification-form.component.scss'],
})
export class EnvelopeVerificationFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  FORM_PARAMS = FORM_PARAMS;

  view: string | null = null;

  resultContent = '';

  envelopeTemplates: any[] = [];

  VERIFY_LABEL = VERIFY_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private envelopeSrv: EnvelopeService,
    private caManagementSrv: CaManagementService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.envelopeTemplates = this.dialogConfig.data?.templates;
    this.getCAConnections();
    this.initFormData();
  }

  /**
   * Initialize the form data
   */
  initFormData() {
    const envelopeField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.ENVELOPE,
      label: 'Envelope',
      field: 'file',
      required: true,
      fieldInfo: 'Envelope',
      uploadEvent: new EventEmitter(),
    } as FormItem);
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        field: 'text',
        label:
          'Verify an envelope that has been created via LCMS previously.' +
          ' It will be validated against the envelope template verification lambda function.',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_SERVER_ID,
        label: 'CA Server',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA server',
        required: true,
        fieldInfo: 'CA server',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_ID,
        label: 'CA Certificate',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA certificate',
        required: true,
        fieldInfo: 'CA certificate',
        defaultValue: null,
        disabled: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENVELOPE_TEMPLATE_ID,
        label: 'Envelope Template',
        field: 'dropdown',
        options: this.envelopeTemplates.map((p) => ({ ...p, value: p.id, label: p.name })),
        placeholder: 'Select an envelope template',
        required: true,
        fieldInfo: 'Envelope template',
        defaultValue: null,
      } as FormItem),
      envelopeField,
    ];
    envelopeField.uploadEvent?.subscribe((event) => this.uploadFile(event));
    this.fields = fields;
    // CA Server change event
    this.form.setChangeEvent(FORM_PARAMS.CA_SERVER_ID, (caConnectionId) => {
      if (!!caConnectionId) {
        this.getCAIntermediateCertificates(caConnectionId);
      } else {
        this.form.setControlValue(FORM_PARAMS.CA_ID, null);
      }
    });
  }

  /**
   * Get the list of CA connections
   */
  getCAConnections() {
    this.form.isLoading = true;
    this.caManagementSrv
      .getCAManagers(this.breadcrumbConfig?.projectId as number)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
          if (!!caServerField) {
            const caServers = this.util.sortObjectArray((rs?.data as any[]) || [], 'name').map((p) => ({
              ...p,
              value: p.id,
              label: p.name,
            }));
            caServerField.options = caServers;
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get the list of CA intermediate certificates
   * @param caConnectionId
   */
  getCAIntermediateCertificates(caConnectionId: any) {
    const caField = this.fields.find((p) => p.name === FORM_PARAMS.CA_ID);
    if (!!caField) {
      this.form.isLoading = true;
      caField.options = [];
      this.caManagementSrv
        .getCACertificates(caConnectionId)
        .pipe(
          finalize(() => {
            this.form.isLoading = false;
          }),
        )
        .subscribe({
          next: (rs: any) => {
            const certs = (rs?.data || [])
              .filter((p: any) => (p.type as string).indexOf('intermediate') > -1)
              .map((p: any) => ({
                ...p,
                value: p.id,
                label: `${p.id}: ${p.subject?.CN} (${p.description})`,
                ca_templates: ((p.ca_templates as any[]) || []).map((t) => ({ ...t, value: t.id, label: t.name })),
              }));
            caField.options = this.util.sortObjectArray(certs, 'id');
            // Disable/enable CA certificate list
            if (!!caField.options.length) {
              this.form.enableControl(FORM_PARAMS.CA_ID);
            } else {
              this.form.disableControl(FORM_PARAMS.CA_ID);
            }
          },
          error: (err) => {
            this.showErrorMessage(err);
            this.form.setControlValue(FORM_PARAMS.CA_ID, null);
            this.form.disableControl(FORM_PARAMS.CA_ID);
          },
        });
    }
  }

  /**
   * Set the file into the saved variables
   * @param event
   */
  uploadFile(event: any) {
    this.form.isLoading = true;
    this.getValidatedFile(event).subscribe((validatedFile: any) => {
      this.form.patchValue(validatedFile);
      this.form.isLoading = false;
    });
  }

  /**
   * Validate the file with correct format
   * @param event
   * @returns
   */
  private getValidatedFile(event: any) {
    return new Observable((observe) => {
      if (!!event.target && !!event.target.files && !!event.target.files.length) {
        const file = (event.target.files as FileList).item(0) as File;
        observe.next({ [FORM_PARAMS.ENVELOPE]: file });
      } else {
        observe.next({ [FORM_PARAMS.ENVELOPE]: null });
      }
    });
  }

  /**
   * Submit the data
   */
  onSubmit() {
    const payload = this.form.getRawValue();
    const envelopeName = payload[FORM_PARAMS.ENVELOPE]?.name;
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]: [string, any]) => {
      formData.append(key, value);
    });
    this.envelopeSrv.verifyEnvelope(formData).subscribe({
      next: (rs: any) => {
        this.form.isLoading = false;
        if (!!rs.is_verified) {
          this.view = 'success';
          this.dialogConfig.header = 'Verification Success';
          this.resultContent = `${envelopeName} has been successfully verified!`;
        } else {
          this.view = 'fail';
          this.dialogConfig.header = 'Verification Fail';
          this.resultContent = `${envelopeName} verification was unsuccessful.`;
        }
      },
      error: (err: any) => {
        this.form.isLoading = false;
        this.view = 'fail';
        this.dialogConfig.header = 'Verification Fail';
        this.resultContent = `${envelopeName} verification was unsuccessful.`;
        this.showErrorMessage(err);
      },
    });
  }
}
