import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { BaseComponent } from '@lcms-components';
import { ENVELOPES_CHILDREN } from '../../../shared-components/shared-envelope/shared-envelope.config';

@Component({
  selector: 'app-organization-envelope-management',
  templateUrl: './envelope-management.component.html',
  styleUrls: ['./envelope-management.component.scss'],
})
export class EnvelopeManagementComponent extends BaseComponent implements OnInit, OnDestroy {
  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkPermissionsByScope(USER_ROLE.ADMIN, true)) {
      const tabItems = this.util.cloneObjectArray(ENVELOPES_CHILDREN).filter((p) => !!p.label);
      this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  override ngOnDestroy(): void {
    this.cleanup({ shouldClearTabItems: true });
  }
}
