import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { API } from '../api';
import { ILogService } from './ILogService';

@Injectable()
export class LogService implements ILogService {
  constructor(private http: HttpClient) {}

  getLogs(type: any, page?: any): Observable<any> {
    return this.http
      .get(`${API.LOG}/${type}/log${!!page ? `?page=${page}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getVersions(): Observable<any> {
    return this.http.get(`${API.LOG}/version`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
