<div class="grid">
  <div class="col-12 pt-4 px-6">
    <microsec-card>
      <microsec-common-table
        [cols]="cols"
        [values]="values"
        [scrollHeight]="'50vh'"
        [cardBackground]="true"
        [cellTemplate]="cellTemplate"></microsec-common-table>
    </microsec-card>
  </div>
  <div class="col-12 pt-2 pb-3 px-6 text-right">
    <p-button severity="secondary" styleClass="mr-2" type="button" (onClick)="dialogRef.close(false)" label="Cancel"> </p-button>
  </div>
</div>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <div class="px-1">
    <microsec-truncate-text [tooltip]="rowData[col.field]">
      {{ rowData[col.field] }}
    </microsec-truncate-text>
  </div>
</ng-template>
