<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="container-border">
    <microsec-details-panel [title]="selectedManager?.name" [buttons]="actionButtons" (collapseEvent)="unselectManager()">
      <p-tabView [scrollable]="true" styleClass="microsec-dashboard-tab">
        <p-tabPanel header="Overview">
          <app-user-certificate-overview />
        </p-tabPanel>
      </p-tabView>
    </microsec-details-panel>
  </div>
</microsec-loading-overlay>
