/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action } from '@ngrx/store';
import { MOMENT_DATE } from '@microsec/constants';
import moment from 'moment';

export enum DeviceSummaryActionTypes {
  INIT = '[Device Summary] Init',
  GET_DEVICE_SUMMARY = '[Device Summary] Get Device Summary',
  GET_DEVICE_SUMMARY_SUCCESS = '[Device Summary] Get Device Summary Success',
  GET_DEVICE_SUMMARY_FAILURE = '[Device Summary] Get Device Summary Failure',
  CANCEL_GET_SUMMARY_REQUEST = '[Device Summary] Cancel Get Summary Request',
  REFRESH_DEVICE_SUMMARY = '[Device Summary] Refresh Device Summary',

  GET_ENROLLED_DEVICE_SUMMARY = '[Device Summary] Get Enrolled Device Summary',
  GET_ENROLLED_DEVICE_SUMMARY_SUCCESS = '[Device Summary] Get Enrolled Device Summary Success',
  GET_ENROLLED_DEVICE_SUMMARY_FAILURE = '[Device Summary] Get Enrolled Device Summary Failure',
  CANCEL_GET_ENROLLED_DEVICE_SUMMARY_REQUEST = '[Device Summary] Cancel Get Enrolled Device Summary Request',

  GET_EXPIRING_DEVICE_SUMMARY = '[Device Summary] Get Expiring Device Summary',
  GET_EXPIRING_DEVICE_SUMMARY_SUCCESS = '[Device Summary] Get Expiring Device Summary Success',
  GET_EXPIRING_DEVICE_SUMMARY_FAILURE = '[Device Summary] Get Expiring Device Summary Failure',
  CANCEL_GET_EXPIRING_DEVICE_SUMMARY_REQUEST = '[Device Summary] Cancel Get Expiring Device Summary Request',

  GET_EXPIRED_DEVICE_SUMMARY = '[Device Summary] Get Expired Device Summary',
  GET_EXPIRED_DEVICE_SUMMARY_SUCCESS = '[Device Summary] Get Expired Device Summary Success',
  GET_EXPIRED_DEVICE_SUMMARY_FAILURE = '[Device Summary] Get Expired Device Summary Failure',
  CANCEL_GET_EXPIRED_DEVICE_SUMMARY_REQUEST = '[Device Summary] Cancel Get Expired Device Summary Request',

  GET_SUMMARY_CAS = '[Device Summary] Get Summary CAs',
  GET_SUMMARY_CAS_SUCCESS = '[Device Summary] Get Summary CAs Success',
  GET_SUMMARY_CAS_FAILURE = '[Device Summary] Get Summary CAs Failure',
  CANCEL_GET_SUMMARY_CAS_REQUEST = '[Device Summary] Cancel Get Summary CAs Request',

  GET_DEVICES_PER_CA = '[Device Summary] Get Devices per CA',
  GET_DEVICES_PER_CA_SUCCESS = '[Device Summary] Get Devices per CA Success',
  GET_DEVICES_PER_CA_FAILURE = '[Device Summary] Get Devices per CA Failure',
  CANCEL_GET_DEVICES_PER_CA_REQUEST = '[Device Summary] Cancel Get Devices per CA Request',
}

// Init
class Init implements Action {
  readonly type = DeviceSummaryActionTypes.INIT;
}

// Get device summary
class GetDeviceSummary implements Action {
  readonly type = DeviceSummaryActionTypes.GET_DEVICE_SUMMARY;

  constructor(
    public organizationId: any,
    public projectId: any,
  ) {}
}

// On get device summary successfully
class OnGetDeviceSummarySuccess implements Action {
  readonly type = DeviceSummaryActionTypes.GET_DEVICE_SUMMARY_SUCCESS;

  constructor(public summary: any) {}
}

// On get device summary failed
class OnGetDeviceSummaryFailed implements Action {
  readonly type = DeviceSummaryActionTypes.GET_DEVICE_SUMMARY_FAILURE;

  constructor(public error: any) {}
}

// Cancel get summary request
class CancelGetSummaryRequest implements Action {
  readonly type = DeviceSummaryActionTypes.CANCEL_GET_SUMMARY_REQUEST;
}

// Refresh device summary
class RefreshDeviceSummary implements Action {
  readonly type = DeviceSummaryActionTypes.REFRESH_DEVICE_SUMMARY;
}

// Get enrolled device summary
class GetEnrolledDeviceSummary implements Action {
  readonly type = DeviceSummaryActionTypes.GET_ENROLLED_DEVICE_SUMMARY;

  constructor(
    public organizationId: any,
    public projectId: any,
    public fromDate: string | null = moment().format(MOMENT_DATE),
    public toDate: string | null = moment().format(MOMENT_DATE),
  ) {}
}

// On get enrolled device summary successfully
class OnGetEnrolledDeviceSummarySuccess implements Action {
  readonly type = DeviceSummaryActionTypes.GET_ENROLLED_DEVICE_SUMMARY_SUCCESS;

  constructor(
    public results: any[],
    public status: string,
  ) {}
}

// On get enrolled device summary failed
class OnGetEnrolledDeviceSummaryFailed implements Action {
  readonly type = DeviceSummaryActionTypes.GET_ENROLLED_DEVICE_SUMMARY_FAILURE;

  constructor(public error: any) {}
}

// Cancel get enrolled device summary request
class CancelGetEnrolledDeviceSummaryRequest implements Action {
  readonly type = DeviceSummaryActionTypes.CANCEL_GET_ENROLLED_DEVICE_SUMMARY_REQUEST;
}

// Get expiring device summary
class GetExpiringDeviceSummary implements Action {
  readonly type = DeviceSummaryActionTypes.GET_EXPIRING_DEVICE_SUMMARY;

  constructor(
    public organizationId: any,
    public projectId: any,
    public fromDate: string | null = moment().format(MOMENT_DATE),
    public toDate: string | null = moment().format(MOMENT_DATE),
  ) {}
}

// On get expiring device summary successfully
class OnGetExpiringDeviceSummarySuccess implements Action {
  readonly type = DeviceSummaryActionTypes.GET_EXPIRING_DEVICE_SUMMARY_SUCCESS;

  constructor(
    public results: any[],
    public status: string,
  ) {}
}

// On get expiring device summary failed
class OnGetExpiringDeviceSummaryFailed implements Action {
  readonly type = DeviceSummaryActionTypes.GET_EXPIRING_DEVICE_SUMMARY_FAILURE;

  constructor(public error: any) {}
}

// Cancel get expiring device summary request
class CancelGetExpiringDeviceSummaryRequest implements Action {
  readonly type = DeviceSummaryActionTypes.CANCEL_GET_EXPIRING_DEVICE_SUMMARY_REQUEST;
}

// Get expired device summary
class GetExpiredDeviceSummary implements Action {
  readonly type = DeviceSummaryActionTypes.GET_EXPIRED_DEVICE_SUMMARY;

  constructor(
    public organizationId: any,
    public projectId: any,
    public fromDate: string | null = moment().format(MOMENT_DATE),
    public toDate: string | null = moment().format(MOMENT_DATE),
  ) {}
}

// On get expired device summary successfully
class OnGetExpiredDeviceSummarySuccess implements Action {
  readonly type = DeviceSummaryActionTypes.GET_EXPIRED_DEVICE_SUMMARY_SUCCESS;

  constructor(
    public results: any[],
    public status: string,
  ) {}
}

// On get expired device summary failed
class OnGetExpiredDeviceSummaryFailed implements Action {
  readonly type = DeviceSummaryActionTypes.GET_EXPIRED_DEVICE_SUMMARY_FAILURE;

  constructor(public error: any) {}
}

// Cancel get expired device summary request
class CancelGetExpiredDeviceSummaryRequest implements Action {
  readonly type = DeviceSummaryActionTypes.CANCEL_GET_EXPIRED_DEVICE_SUMMARY_REQUEST;
}

// Get summary CAs
class GetSummaryCAs implements Action {
  readonly type = DeviceSummaryActionTypes.GET_SUMMARY_CAS;

  constructor(
    public organizationId: any,
    public projectId: any,
  ) {}
}

// On get summary CAs successfully
class OnGetSummaryCAsSuccess implements Action {
  readonly type = DeviceSummaryActionTypes.GET_SUMMARY_CAS_SUCCESS;

  constructor(public cas: any[]) {}
}

// On get summary CAs failed
class OnGetSummaryCAsFailed implements Action {
  readonly type = DeviceSummaryActionTypes.GET_SUMMARY_CAS_FAILURE;

  constructor(public error: any) {}
}

// Cancel get summary CAs request
class CancelGetSummaryCAsRequest implements Action {
  readonly type = DeviceSummaryActionTypes.CANCEL_GET_SUMMARY_CAS_REQUEST;
}

// Get devices by CA
class GetDevicesPerCA implements Action {
  readonly type = DeviceSummaryActionTypes.GET_DEVICES_PER_CA;

  constructor(
    public organizationId: any,
    public projectId: any,
    public caId: any,
  ) {}
}

// On get devices by CA successfully
class OnGetDevicesPerCASuccess implements Action {
  readonly type = DeviceSummaryActionTypes.GET_DEVICES_PER_CA_SUCCESS;

  constructor(public result: any[]) {}
}

// On get devices by CA failed
class OnGetDevicesPerCAFailed implements Action {
  readonly type = DeviceSummaryActionTypes.GET_DEVICES_PER_CA_FAILURE;

  constructor(public error: any) {}
}

// Cancel get devices by CA request
class CancelGetDevicesPerCARequest implements Action {
  readonly type = DeviceSummaryActionTypes.CANCEL_GET_DEVICES_PER_CA_REQUEST;
}

export type DeviceSummaryAction =
  | Init
  // Summary
  | GetDeviceSummary
  | OnGetDeviceSummarySuccess
  | OnGetDeviceSummaryFailed
  | CancelGetSummaryRequest
  | RefreshDeviceSummary
  // Enrolled Device Summary
  | GetEnrolledDeviceSummary
  | OnGetEnrolledDeviceSummarySuccess
  | OnGetEnrolledDeviceSummaryFailed
  | CancelGetEnrolledDeviceSummaryRequest
  // Expiring Device Summary
  | GetExpiringDeviceSummary
  | OnGetExpiringDeviceSummarySuccess
  | OnGetExpiringDeviceSummaryFailed
  | CancelGetExpiringDeviceSummaryRequest
  // Expired Device Summary
  | GetExpiredDeviceSummary
  | OnGetExpiredDeviceSummarySuccess
  | OnGetExpiredDeviceSummaryFailed
  | CancelGetExpiredDeviceSummaryRequest
  // CAs
  | GetSummaryCAs
  | OnGetSummaryCAsSuccess
  | OnGetSummaryCAsFailed
  | CancelGetSummaryCAsRequest
  // Devices per CA
  | GetDevicesPerCA
  | OnGetDevicesPerCASuccess
  | OnGetDevicesPerCAFailed
  | CancelGetDevicesPerCARequest;

export const fromDeviceSummaryActions = {
  Init,
  // Summary
  GetDeviceSummary,
  OnGetDeviceSummarySuccess,
  OnGetDeviceSummaryFailed,
  CancelGetSummaryRequest,
  RefreshDeviceSummary,
  // Enrolled Device Summary
  GetEnrolledDeviceSummary,
  OnGetEnrolledDeviceSummarySuccess,
  OnGetEnrolledDeviceSummaryFailed,
  CancelGetEnrolledDeviceSummaryRequest,
  // Expiring Device Summary
  GetExpiringDeviceSummary,
  OnGetExpiringDeviceSummarySuccess,
  OnGetExpiringDeviceSummaryFailed,
  CancelGetExpiringDeviceSummaryRequest,
  // Expired Device Summary
  GetExpiredDeviceSummary,
  OnGetExpiredDeviceSummarySuccess,
  OnGetExpiredDeviceSummaryFailed,
  CancelGetExpiredDeviceSummaryRequest,
  // CAs
  GetSummaryCAs,
  OnGetSummaryCAsSuccess,
  OnGetSummaryCAsFailed,
  CancelGetSummaryCAsRequest,
  // Devices per CA
  GetDevicesPerCA,
  OnGetDevicesPerCASuccess,
  OnGetDevicesPerCAFailed,
  CancelGetDevicesPerCARequest,
};
