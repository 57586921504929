import { Component } from '@angular/core';
import { RULE_TYPE_VALUES } from '@lcms-constants';

@Component({
  selector: 'app-command-rules',
  templateUrl: './command.component.html',
  styleUrls: ['./command.component.scss'],
})
export class CommandRulesComponent {
  RULE_TYPE_VALUES = RULE_TYPE_VALUES;
}
