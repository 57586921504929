import { Component } from '@angular/core';
import { RULE_TYPE_VALUES } from '@lcms-constants';

@Component({
  selector: 'app-can-rules',
  templateUrl: './can.component.html',
  styleUrls: ['./can.component.scss'],
})
export class CanRulesComponent {
  RULE_TYPE_VALUES = RULE_TYPE_VALUES;
}
