import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { EnvelopeService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { DetailsPanelButtonModel } from '@microsec/models';
import { DELETE_LABEL, DUPLICATE_LABEL, EDIT_LABEL, SCOPE } from '@microsec/constants';

@Component({
  selector: 'app-envelope-template-info',
  templateUrl: './envelope-template-info.component.html',
  styleUrls: ['./envelope-template-info.component.scss'],
})
export class EnvelopeTemplateInfoComponent extends BaseComponent implements AfterViewInit {
  @Input() isLoading = false;

  _selectedTemplate: any = null;

  @Input() set selectedTemplate(value: any) {
    this._selectedTemplate = value;
    this.prepareActionButtons();
  }

  get selectedTemplate() {
    return this._selectedTemplate;
  }

  @Output() selectedTemplateChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() values: any[] = [];

  @Input() hasPermission = false;

  @Output() openTemplateFormEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() refreshTemplatesListEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() duplicateTemplateEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() confirmDeletionEvent: EventEmitter<any> = new EventEmitter<any>();

  actionButtons: DetailsPanelButtonModel[] = [];

  constructor(private envelopeSrv: EnvelopeService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.prepareActionButtons();
  }

  /**
   * Prepare the action buttons
   */
  prepareActionButtons() {
    this.actionButtons = [
      {
        label: DUPLICATE_LABEL,
        hidden: !this.permissions,
        command: () => {
          this.duplicateTemplate();
        },
      },
      {
        label: EDIT_LABEL,
        hidden: this.selectedTemplate?.scope === SCOPE.GLOBAL || this.selectedTemplate?.scope !== this.currentScope || !this.hasPermission,
        command: () => {
          this.openTemplateForm();
        },
      },
      {
        label: DELETE_LABEL,
        hidden: this.selectedTemplate?.scope === SCOPE.GLOBAL || this.selectedTemplate?.scope !== this.currentScope || !this.hasPermission,
        command: () => {
          this.confirmDeletion();
        },
      },
    ];
  }

  /**
   * Open edit form
   */
  openTemplateForm() {
    this.openTemplateFormEvent.emit(this.selectedTemplate);
  }

  /**
   * Duplicate as a new template
   */
  duplicateTemplate() {
    this.isLoading = true;
    setTimeout(() => {
      this.duplicateTemplateEvent.emit(this.selectedTemplate);
      this.isLoading = false;
    });
  }

  /**
   * Show confirmation dialog to delete a template
   */
  confirmDeletion() {
    this.confirmDeletionEvent.emit(this.selectedTemplate);
  }

  /**
   * Refresh the templates list
   */
  refreshTemplatesList() {
    this.refreshTemplatesListEvent.emit();
  }

  /**
   * Unselect the template
   */
  unselectTemplate() {
    this.selectedTemplateChange.emit(null);
  }
}
