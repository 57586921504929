<div class="grid">
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN] ? leftTemplate : null">
      <ng-template #leftTemplate>
        @if (!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN]) {
          <p-button severity="primary" [rounded]="true" label="Link LDAP Group" icon="pi pi-plus" (onClick)="openLDAPGroupLinkForm()" />
        }
      </ng-template>
    </microsec-common-toolbar>
  </div>

  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [selectable]="false"
        itemName="LDAP Group"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getLDAPGroupLinks()" />
    <microsec-table-column-filter [key]="constructor.name" [cols]="cols" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    @case ('project_role') {
      <div class="form dark-form no-wrapper">
        <p-dropdown
          [options]="roles"
          optionLabel="label"
          optionValue="value"
          appendTo="body"
          [(ngModel)]="rowData.project_role"
          [disabled]="!this.permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN]" />
      </div>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
