/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { SCOPE } from '@microsec/constants';
import { Util } from '@microsec/utilities';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import { ILabelService } from './ILabelService';
import labelsData from './mock-data/labels.json';

@Injectable({
  providedIn: 'root',
})
export class MockLabelService implements ILabelService {
  getLabels(orgId: any, projectId: any): Observable<any> {
    let data = Util.cloneObjectArray(labelsData as any[]);
    if (!!orgId && !projectId) {
      data = data.filter((p) => p.scope === SCOPE.ORGANIZATION);
    } else if (!!projectId) {
      data = data.filter((p) => p.scope === SCOPE.ORGANIZATION || p.scope === SCOPE.PROJECT);
    }
    return scheduled([data], asyncScheduler);
  }

  getLabel(id: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  createLabel(payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  updateLabel(id: any, payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  deleteLabel(id: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }
}
