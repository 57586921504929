import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { deviceSummarySelectors } from '@ngrx-device-summary';
import { CommonOverviewComponent } from '@microsec/components';
import { BaseComponent } from '@lcms-components';
import { ChartHelper } from '@microsec/utilities';
import { CommonChart } from '@microsec/models';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { COMMON_DOUGHNUT_LAYOUT_OPTIONS, DATA_BLOCKS, DIAGRAMS } from './crypto-assets.config';

@Component({
  selector: 'app-devices-crypto-assets',
  templateUrl: './crypto-assets.component.html',
  styleUrls: ['./crypto-assets.component.scss'],
})
export class CryptoAssetsComponent extends BaseComponent implements OnInit, OnDestroy {
  summary: any = null;

  /**
   * Data blocks
   */
  blocks: any[] = [];

  /**
   * Chart data + options
   */
  charts: CommonChart[] = [];

  /**
   * Diagram constants
   */
  DIAGRAMS = DIAGRAMS;

  /**
   * Diagram UIs
   */
  @ViewChild('overviewComponent') overviewComponent!: CommonOverviewComponent;

  async ngOnInit() {
    await this.prepareConfigs();
    this.subscriptions.push(
      this.store.select(deviceSummarySelectors.summary).subscribe((summary) => {
        this.summary = summary;
        this.initData();
      }),
    );
  }

  /**
   * Initialize data
   */
  async initData() {
    await this.prepareConfigs();
    if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
      this.initTemplates();
      this.charts?.forEach((chart) => {
        this.updateChartData(chart);
      });
      this.generateDataBlocks();
      this.redrawDiagrams();
    }
  }

  /**
   * Generate block data
   */
  private generateDataBlocks() {
    const blocks: any[] = [];
    Object.entries(this.util.cloneDeepObject(DATA_BLOCKS))?.forEach(([key, entry]) => {
      let value = 0;
      switch (key) {
        case DATA_BLOCKS.KEYS_IN_USE.KEY: {
          value = this.summary?.crypto_assets?.total_keypairs;
          break;
        }
        case DATA_BLOCKS.CERTIFICATES_IN_USE.KEY: {
          value = this.summary?.crypto_assets?.total_client_certs;
          break;
        }
        default: {
          break;
        }
      }
      blocks.push({
        value,
        label: (entry as any).LABEL,
        color: (entry as any).COLOR,
      });
    });
    this.blocks = blocks;
  }

  /**
   * Redraw the diagram UIs
   */
  private redrawDiagrams(chart?: CommonChart) {
    if (!!this.overviewComponent?.diagrams) {
      if (!chart) {
        this.overviewComponent.diagrams?.forEach((diagram) => {
          setTimeout(() => {
            diagram.redraw();
          });
        });
      } else {
        const diagram = this.overviewComponent.diagrams.find((p) => p.chartConfig.key === chart.key);
        setTimeout(() => {
          diagram?.redraw();
        });
      }
    }
  }

  /**
   * Build up the security objects
   */
  private initTemplates() {
    // charts
    const charts: any[] = [];
    Object.entries(DIAGRAMS)?.forEach(([key, value]) => {
      let chartOptions: ChartOptions | null = null;
      switch (value.TYPE) {
        case 'doughnut': {
          chartOptions = this.util.cloneDeepObject(COMMON_DOUGHNUT_LAYOUT_OPTIONS) as ChartOptions;
          break;
        }
        default: {
          break;
        }
      }
      const chart = this.util.cloneDeepObject(
        {
          type: value.TYPE,
          key,
          label: value.LABEL,
          data: {} as ChartData,
          options: chartOptions,
          children: !!value.CHILDREN.length ? this.util.cloneObjectArray(value.CHILDREN, true) : this.getDefaultChartChildren(key),
        },
        true,
      ) as CommonChart;
      charts.push(chart);
    });
    this.charts = charts;
  }

  private getDefaultChartChildren(chartKey: string) {
    const results = [];
    switch (chartKey) {
      case DIAGRAMS.DEVICES_PER_KEY_ALGORITHM.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.crypto_assets?.key_algorithm_distribution, 'key_algorithm'));
        break;
      }
      case DIAGRAMS.DEVICES_PER_SIGNATURE_ALGORITHM.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.crypto_assets?.signature_algorithm_distribution, 'signature_algorithm'));
        break;
      }
      default: {
        break;
      }
    }
    return results;
  }

  /**
   * Generate the data
   * @param chart chart
   */
  updateChartData(chart: CommonChart) {
    const data = chart.data as ChartData;
    data.labels = [];
    data.datasets = [
      {
        data: this.generateData(chart),
        backgroundColor: [],
        borderWidth: [],
        barPercentage: 0.5,
      } as ChartDataset,
    ];
    this.generateLabels(chart, data);
  }

  /**
   * Generate the chart data
   * @param chart
   * @param responseData
   * @returns
   */
  private generateData(chart: CommonChart): number[] {
    const values: number[] = [];
    if (!!chart.key) {
      switch (chart.key) {
        case DIAGRAMS.DEVICES_PER_KEY_ALGORITHM.KEY: {
          values.push(...((this.summary?.crypto_assets?.key_algorithm_distribution as any[]) || []).map((p) => p?.devices?.length || 0));
          break;
        }
        case DIAGRAMS.DEVICES_PER_SIGNATURE_ALGORITHM.KEY: {
          values.push(...((this.summary?.crypto_assets?.signature_algorithm_distribution as any[]) || []).map((p) => p?.devices?.length || 0));
          break;
        }
        default: {
          break;
        }
      }
    }
    return values;
  }

  /**
   * Generate the chart labels
   * @param chart
   * @param data
   */
  private generateLabels(chart: CommonChart, data: ChartData) {
    switch (chart.type) {
      case 'doughnut': {
        if (!!chart.key) {
          const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
          children?.forEach((item: any) => {
            // Labels
            data.labels?.push(item.LABEL);
            const dataset = data.datasets.find((p) => !!p) as ChartDataset;
            // Colors
            if (!!dataset && !!dataset.backgroundColor) {
              (dataset.backgroundColor as string[]).push(item.COLOR);
              (dataset.borderWidth as number[]).push(0);
            }
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Get legend label
   * @param value
   * @param label
   * @returns
   */
  getLegendLabel(value: any, label: string) {
    return label;
  }

  /**
   * Check if should show legend
   * @param chart
   * @returns
   */
  checkChartLegend(chart: CommonChart) {
    return chart.type === 'doughnut' ? 'right' : null;
  }
}
