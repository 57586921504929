<div class="grid">
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN] ? leftTemplate : null">
      <ng-template #leftTemplate>
        <p-button
          severity="success"
          styleClass="ml-2 mr-2"
          pTooltip="Install Package"
          tooltipPosition="bottom"
          appendTo="body"
          (onClick)="openPackageForm('Install')">
          <microsec-svg-icon iconName="icon_install_package" />
        </p-button>
        <p-button
          severity="primary"
          styleClass="mr-2"
          pTooltip="Update"
          tooltipPosition="bottom"
          appendTo="body"
          (onClick)="openPackageForm('Update', selectedPackages)"
          [disabled]="!selectedPackages || !selectedPackages.length">
          <microsec-svg-icon iconName="icon_upgrade" />
        </p-button>
        <microsec-operation-button
          [operation]="DELETE_LABEL"
          icon="fa fa-trash"
          buttonClass="p-button-danger"
          [disabled]="!selectedPackages.length"
          (clickEvent)="openPackageForm('Delete', selectedPackages)"
          (selectedOperationEvent)="openPackageForm('Delete', selectedPackages)"
          (allOperationEvent)="openPackageForm('Delete', values)" />
      </ng-template>
    </microsec-common-toolbar>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-4': !!selectedPackage }" #datatable (click)="unselectPackage($event, datatable)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [(rowSingleSelection)]="selectedPackage"
        [(selection)]="selectedPackages"
        selectionMode="multiple"
        [scrollHeight]="'58vh'"
        itemName="Package"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate"
        dataKey="name" />
    </microsec-card>
  </div>

  @if (!!selectedPackage) {
    <div class="col-12 lg:col-8">
      <app-package-info
        [isLoading]="isLoading"
        [(selectedPackage)]="selectedPackage"
        (openPackageFormEvent)="openPackageForm($event, [selectedPackage])" />
    </div>
  }
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getPackages()" />
    <microsec-table-column-filter [key]="constructor.name" [cols]="cols" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Devices with outdated version -->
    @case ('out_of_date_devices') {
      {{ rowData[col.field] }}
      &nbsp;
      @if (!!rowData?.out_of_date_devices) {
        <span tooltipStyleClass="tooltip-version" [escape]="false" pTooltip="The package installed is outdated">
          <i class="fa fa-exclamation-circle text-red-400"></i>
        </span>
      }
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
