<microsec-card>
  <div class="grid">
    <div class="col-12 xl:col-6 lg:col-8 md:col-10 sm:col-12">
      <microsec-form-builder
        #fb
        [isPage]="true"
        [fields]="fields"
        [shouldShowActionButtons]="true"
        [submitButtonLabel]="SAVE_CHANGES_LABEL"
        [cancelButtonLabel]="''"
        (submitEvent)="onSubmit()"
        [leftActionArea]="leftActionArea" />
    </div>
  </div>
</microsec-card>

<ng-template #testRadiusConnectionTemplate>
  <p-button
    severity="primary"
    label="Test RADIUS Connection"
    [loading]="isTestLoading"
    (onClick)="testRSAConnection()"
    [disabled]="!form || !form.valid" />
</ng-template>

<ng-template #leftActionArea>
  <p-button severity="secondary" label="Discard" styleClass="mr-2" (onClick)="getRSASetting()" />
</ng-template>
