import { Injectable } from '@angular/core';
import { UserCertificateService } from '@lcms-services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Util } from '@microsec/utilities';
import { MessageService } from 'primeng/api';
import { asyncScheduler, catchError, map, mergeMap, Observable, scheduled, switchMap, tap, withLatestFrom } from 'rxjs';
import { fromUserCertificatesActions, UserCertificatesAction, UserCertificatesActionTypes } from './user-certificates.actions';
import { userCertificatesSelectors } from './user-certificates.selectors';

@Injectable()
export class UserCertificatesEffects {
  constructor(
    private readonly actions$: Actions<UserCertificatesAction>,
    private readonly toastSrv: MessageService,
    private readonly userCertificateSrv: UserCertificateService,
    private readonly store: Store,
  ) {}

  // Get managers
  getManagersEffect$: Observable<UserCertificatesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserCertificatesActionTypes.GET_MANAGERS),
      withLatestFrom(this.store.select(userCertificatesSelectors.selectedManager)),
      switchMap(([action, selectedManager]) => {
        return this.userCertificateSrv.getManagers(action.projectId).pipe(
          map((result) => new fromUserCertificatesActions.OnGetManagersSuccess(action.projectId, result?.data, selectedManager)),
          catchError((error) => scheduled([new fromUserCertificatesActions.OnGetManagersFailure({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // On get managers failed
  onGetManagersFailureEffect$: Observable<UserCertificatesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserCertificatesActionTypes.GET_MANAGERS_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Update the manager
  updateManagerEffect$: Observable<UserCertificatesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserCertificatesActionTypes.UPDATE_MANAGER),
      switchMap((action) => {
        const selectedManager = action.selectedManager;
        return this.userCertificateSrv.updateManager(action.selectedManager?.id, action.payload).pipe(
          map(
            (result) =>
              new fromUserCertificatesActions.OnUpdateManagerSuccess(
                result,
                `Updated user ${selectedManager?.name} successfully`,
                action.successCallback,
              ),
          ),
          catchError((error) => scheduled([new fromUserCertificatesActions.OnUpdateManagerFailure({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // On update manager successfully
  onUpdateManagerSuccessEffect$: Observable<UserCertificatesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserCertificatesActionTypes.UPDATE_MANAGER_SUCCESS),
      switchMap((action) => {
        if (!action.successCallback) {
          Util.showSuccessMessage(this.toastSrv, action.message);
        } else {
          action.successCallback.next(action.result);
        }
        return scheduled([new fromUserCertificatesActions.RefreshManagers()], asyncScheduler);
      }),
    ),
  );

  // On update manager failed
  onUpdateManagerFailureEffect$: Observable<UserCertificatesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserCertificatesActionTypes.UPDATE_MANAGER_FAILURE),
      tap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
      }),
    ),
  );

  // Delete manager
  deleteManagerEffect$: Observable<UserCertificatesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserCertificatesActionTypes.DELETE_MANAGER),
      switchMap((action) => {
        const selectedManager = action.selectedManager;
        return this.userCertificateSrv.deleteManager(selectedManager?.id).pipe(
          map(() => new fromUserCertificatesActions.OnDeleteManagerSuccess(`Deleted user ${selectedManager?.name} successfully`)),
          catchError((error) => scheduled([new fromUserCertificatesActions.OnDeleteManagerFailure({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // On delete manager successfully
  onDeleteManagerSuccessEffect$: Observable<UserCertificatesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserCertificatesActionTypes.DELETE_MANAGER_SUCCESS),
      switchMap((action) => {
        Util.showSuccessMessage(this.toastSrv, action.message);
        return scheduled([new fromUserCertificatesActions.RefreshManagers()], asyncScheduler);
      }),
    ),
  );

  // On delete manager failed
  onDeleteManagerFailureEffect$: Observable<UserCertificatesAction> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserCertificatesActionTypes.DELETE_MANAGER_FAILURE),
        tap((action) => {
          Util.showErrorMessage(this.toastSrv, action.error);
        }),
      ),
    { dispatch: false },
  );

  // Refresh the list of user certificates
  refreshUserCertificatesEffect$: Observable<UserCertificatesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserCertificatesActionTypes.REFRESH_MANAGERS),
      withLatestFrom(this.store.select(userCertificatesSelectors.refreshConfig)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([action, refreshConfig]) => scheduled([new fromUserCertificatesActions.GetManagers(refreshConfig.projectId)], asyncScheduler)),
    ),
  );
}
