<microsec-card>
  <div class="grid">
    <div class="col-12 text-data">
      Self service user enrollment dashboard is {{ !!project?.user_entity_enrollment_config?.enabled ? 'enabled' : 'disabled' }}.
    </div>

    <div class="col-12 text-data">
      @if (!!project?.user_entity_enrollment_config?.enabled) {
        <!-- Enabled -->
        <span>Link to user enrollment dashboard:&nbsp;</span>
        <a class="link" [href]="enrollmentLink" target="_blank">
          {{ enrollmentLink }}
        </a>
      } @else {
        <!-- Disabled -->
        <span class="link" (click)="navigateToProjectSettings()"> Enable in Project Settings </span>
      }
    </div>
  </div>
</microsec-card>
