@switch (type) {
  @case (RULE_TYPE_VALUES.IP) {
    <app-ip-rule-form [ruleset]="ruleset" [rule]="rule" />
  }
  @case (RULE_TYPE_VALUES.CAN) {
    <app-can-rule-form [ruleset]="ruleset" [rule]="rule" />
  }
  @case (RULE_TYPE_VALUES.COMMAND) {
    <app-command-rule-form [ruleset]="ruleset" [rule]="rule" />
  }
  @case (RULE_TYPE_VALUES.YARA) {
    <app-yara-rule-form [ruleset]="ruleset" [rule]="rule" />
  }
  @default {}
}
