import { Component } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { DOCUMENTATION_URL, DOMAIN_TOKEN } from '@microsec/constants';

@Component({
  selector: 'app-device-manual-form',
  templateUrl: './device-manual-form.component.html',
  styleUrls: ['./device-manual-form.component.scss'],
})
export class DeviceManualFormComponent extends BaseComponent {
  /**
   * Got to Device Enrollment Documentation
   */
  goToDocumentationDeviceEnrollment() {
    window.open(DOCUMENTATION_URL.replace(DOMAIN_TOKEN, this.currentDomain), '_blank');
  }
}
