/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEVICE_TYPE } from '@lcms-constants';
import moment from 'moment';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { BehaviorSubject, Observable, Subscription, asyncScheduler, scheduled, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { API } from '../api';
import { IDeviceService } from './IDeviceService';
import { MOMENT_DATETIME } from '@microsec/constants';
import { Util } from '@microsec/utilities';

const API_DEVICES = `${API.END_ENTITY_MANAGEMENT}/devices`;
const API_CERTIFICATES = `${API.END_ENTITY_MANAGEMENT}/certificates`;

@Injectable({
  providedIn: 'root',
})
export class DeviceService implements IDeviceService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  refreshObs: Observable<any> = this.refresh$.asObservable();

  deviceEvents$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  deviceEventsObs: Observable<any> = this.deviceEvents$.asObservable();

  mqtt: MqttService | null = null;

  mqttSubscription: Subscription | null = null;

  constructor(private http: HttpClient) {}

  /** ******************************************************************************
   * ****************************** DEVICE CRUD ******************************
   ******************************************************************************** */

  /**
   * Get device summary
   * @param organizationId
   * @param projectId
   */
  getDeviceSummary(organizationId: any, projectId: any): Observable<any> {
    return this.http
      .get<any>(`${API_DEVICES}/summary/devices?organization_id=${organizationId}&proj_id=${projectId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get summary CAs
   * @param organizationId
   * @param projectId
   * @returns
   */
  getSummaryCAs(organizationId: any, projectId: any): Observable<any> {
    return this.http
      .get<any>(`${API_DEVICES}/summary/cas?organization_id=${organizationId}&proj_id=${projectId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get device certificate summary
   * @param organizationId
   * @param projectId
   * @param type
   * @param from
   * @param to
   */
  getDeviceCertificateSummary(organizationId: any, projectId: any, type: any, from: any, to: any): Observable<any> {
    return this.http
      .get<any>(`${API_DEVICES}/summary/certificates?organization_id=${organizationId}&proj_id=${projectId}&type=${type}&from=${from}&to=${to}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get devices by CA
   * @param organizationId
   * @param projectId
   * @param caId
   * @returns
   */
  getDevicesPerCA(organizationId: any, projectId: any, caId: any): Observable<any> {
    return this.http
      .get<any>(`${API_DEVICES}/summary/devices-per-ca?organization_id=${organizationId}&proj_id=${projectId}&ca_id=${caId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get all devices from all types
   * @param organizationId
   * @param projectId
   * @param filters
   * @param searchText
   * @param sortConfig
   * @param page
   * @param perPage
   * @returns
   */
  getDevices(
    organizationId: any = null,
    projectId: any = null,
    filters: any = {},
    searchText: any = '',
    sortConfig: any = null,
    page: any = 1,
    perPage: any = 20,
    keyringId: any = null,
    keyId: any = null,
  ): Observable<any> {
    let url = `${API_DEVICES}?page=${page}&per_page=${perPage}&cert_info=true`;
    if (organizationId) {
      url += `&organization_id=${organizationId}`;
    }
    if (projectId) {
      url += `&proj_id=${projectId}`;
    }
    if (Object.keys(filters)) {
      if (!!filters.status) {
        url += `&status=${filters.status}`;
      }
      if (!!filters.labels && !!(filters.labels as any[]).length) {
        url += `&label_ids=${(filters.labels as any[]).join(',')}`;
      }
      if (!!filters.categories && !!(filters.categories as any[]).length) {
        const categories = filters.categories as any[];
        categories.forEach((category) => {
          url += `&${category}=true`;
        });
      }
    }
    if (!!searchText) {
      url += `&search=${searchText}`;
    }
    if (!!sortConfig) {
      url += `&order_by=${sortConfig.field}&order=${sortConfig.order}`;
    }
    if (!!keyringId) {
      url += `&kms_keyring_id=${keyringId}`;
    }
    if (!!keyId) {
      url += `&kms_key_id=${keyId}`;
    }
    return this.http.get<any>(url).pipe(
      map((deviceResult: any) => {
        // Get raw results, including both X509 and MicroPKI devices
        const rawResults: any[] = ((deviceResult?.devices as any[]) || []).map((d) => {
          const devices = {
            ...d,
            sn: d.id,
            common_name: d.upki_device_id || d.name,
            device_type: !d.gateway_id ? DEVICE_TYPE.X509 : DEVICE_TYPE.MICRO_PKI,
            last_seen: !!d.last_seen ? moment.utc(d.last_seen).local() : null,
            children: [],
          };

          ((devices.certs as any[]) || []).forEach((cert: any) => {
            cert.not_before = !!cert.not_before ? moment.utc(cert.not_before).local() : null;
            cert.not_after = !!cert.not_after ? moment.utc(cert.not_after).local() : null;
          });
          return devices;
        });

        // Get X509 devices
        const x509Devices = rawResults?.filter((d) => !d.gateway_id);
        x509Devices.forEach((d) => {
          // Get MicroPKI devices as X509 device's children
          d.children = rawResults?.filter((p) => p.gateway_id === d.id).map((p, i) => ({ ...p, sn: `${d.id}.${i + 1}` }));
        });
        return { ...deviceResult, devices: x509Devices };
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  /**
   * Get all devices
   * @param organizationId
   * @param projectId
   * @param filters
   * @param searchText
   * @param sortConfig
   * @param page
   * @param previousResults
   * @returns
   */
  getAllDevices(
    organizationId: any = null,
    projectId: any = null,
    filters: any,
    searchText: any,
    sortConfig: any = null,
    page: any = 1,
    previousResults: any[] = [],
  ): Observable<any[]> {
    const currentResults = previousResults;
    return this.getDevices(organizationId, projectId, filters, searchText, sortConfig, page, 1000).pipe(
      mergeMap((result: any) => {
        currentResults.push(...((result?.devices as any[]) || []));
        if (page < result.total_pages) {
          return this.getAllDevices(organizationId, projectId, filters, searchText, page + 1, currentResults);
        } else {
          return scheduled([currentResults], asyncScheduler);
        }
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  /**
   * Get all devices without cert Info and with agent filter
   * @param organizationId
   * @param projectId
   * @param usesAgent
   * @returns
   */
  getAllDevicesNonPaginated(
    organizationId: any = null,
    projectId: any = null,
    usesAgent: boolean | null = null,
    searchText: any = '',
  ): Observable<any> {
    let url = `${API_DEVICES}?organization_id=${organizationId}&proj_id=${projectId}`;
    if (usesAgent !== null) {
      url += `&uses_agent=${usesAgent}`;
    }
    if (!!searchText) {
      url += `&search=${searchText}`;
    }
    return this.http.get<any>(url).pipe(
      map((deviceResult: any) => {
        // Get raw results, including both X509 and MicroPKI devices
        const rawResults: any[] = ((deviceResult?.devices as any[]) || []).map((d) => {
          const devices = {
            ...d,
            sn: d.id,
            common_name: d.upki_device_id || d.name,
            device_type: !d.gateway_id ? DEVICE_TYPE.X509 : DEVICE_TYPE.MICRO_PKI,
            last_seen: !!d.last_seen ? moment.utc(d.last_seen).local() : null,
          };
          return devices;
        });
        return { ...deviceResult, devices: rawResults };
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  /**
   * Get device's information by Id and its certificates
   * @param deviceId
   */
  getDevice(deviceId: any): Observable<any> {
    return this.http.get(`${API_DEVICES}/${deviceId}`).pipe(
      map((device: any) => {
        (device.last_seen = !!device.last_seen ? moment.utc(device.last_seen).local() : null),
          ((device.certs as any[]) || []).forEach((cert) => {
            cert.not_before = !!cert.not_before ? moment.utc(cert.not_before).local() : null;
            cert.not_after = !!cert.not_after ? moment.utc(cert.not_after).local() : null;
          });
        return device;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  /**
   * Create new device
   * @param deviceInfo
   * @returns
   */
  createDevice(deviceInfo: any) {
    const { organization_id, project_id, device_name, protocol, uses_otp, is_manual } = deviceInfo;
    const payload: any = {
      organization_id,
      project_id,
      name: device_name,
      address: '0.0.0.0',
      enrolment_protocol: protocol,
      is_virtual: true,
      uses_otp,
      is_manual,
    };
    return this.http.post<any>(`${API_DEVICES}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Update the device
   */
  updateDevice(deviceId: any, payload: any): Observable<any> {
    return this.http.put(`${API_DEVICES}/${deviceId}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  removeDevice(deviceId: string, isForced = false): Observable<any> {
    return this.http.delete(`${API_DEVICES}/${deviceId}?force=${isForced}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /** ******************************************************************************
   * ****************************** DEVICE MORE INFO ******************************
   ******************************************************************************** */

  /**
   * Get device packages
   * @param deviceId
   */
  getDevicePackages(deviceId: any): Observable<any> {
    return this.http.get<any>(`${API_DEVICES}/${deviceId}/packages`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /** ******************************************************************************
   * ****************************** DEVICE CRYPTO ASSETS ***************************
   ******************************************************************************** */

  /**
   * Get device crypto assets: Cryptokens
   * @param deviceId
   * @param page
   * @param perPage
   * @param search
   * @returns
   */
  getDeviceCryptoAssetTokens(deviceId: any, page: number, perPage: number, search: string): Observable<any> {
    const params: string[] = [];
    if (!!page) {
      params.push(`page=${page}`);
    }
    if (!!perPage) {
      params.push(`per_page=${perPage}`);
    }
    if (!!search) {
      params.push(`label=${search}`);
    }
    return this.http
      .get<any>(`${API_DEVICES}/${deviceId}/cryptokens${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get device crypto assets: Client keys
   * @param deviceId
   * @param page
   * @param perPage
   * @param search
   * @returns
   */
  getDeviceCryptoAssetKeys(deviceId: any, page: number, perPage: number, search: string, filter: any): Observable<any> {
    const headers = new HttpHeaders({
      timeout: (20 * 1000).toString(),
    });
    const params: string[] = [];
    if (!!page) {
      params.push(`page=${page}`);
    }
    if (!!perPage) {
      params.push(`per_page=${perPage}`);
    }
    if (!!search) {
      params.push(`label=${search}`);
    }
    if (Object.keys(filter).length > 0) {
      Object.entries((filter as any) || {}).forEach(([key, value]: [string, any]) => {
        if (!!value) {
          params.push(`${key}=${value}`);
        }
      });
    }
    return this.http
      .get<any>(`${API_DEVICES}/${deviceId}/keys${!!params.length ? `?${params.join('&')}` : ''}`, { headers })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get device crypto assets: Client Certificates
   * @param deviceId
   * @param page
   * @param perPage
   * @param search
   * @returns
   */
  getDeviceCryptoAssetClientCerts(deviceId: any, page: number, perPage: number, search: string): Observable<any> {
    const params: string[] = [];
    if (!!page) {
      params.push(`page=${page}`);
    }
    if (!!perPage) {
      params.push(`per_page=${perPage}`);
    }
    if (!!search) {
      params.push(`label=${search}`);
    }
    return this.http
      .get<any>(`${API_DEVICES}/${deviceId}/client-certificates${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get device crypto assets: Client CA Certificates
   * @param deviceId
   * @param page
   * @param perPage
   * @param search
   * @returns
   */
  getDeviceCryptoAssetCaCerts(deviceId: any, page: number, perPage: number, search: string): Observable<any> {
    const params: string[] = [];
    if (!!page) {
      params.push(`page=${page}`);
    }
    if (!!perPage) {
      params.push(`per_page=${perPage}`);
    }
    if (!!search) {
      params.push(`label=${search}`);
    }
    return this.http
      .get<any>(`${API_DEVICES}/${deviceId}/ca-certificates${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Create Crypto Asset Client Key on device
   * @param deviceId
   * @param payload
   * @returns
   */
  createDeviceCryptoAssetKey(deviceId: any, payload: any): Observable<any> {
    return this.http.post<any>(`${API_DEVICES}/${deviceId}/keys`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Create Crypto Asset Client CA Certificate on device
   * @param deviceId
   * @param payload
   * @returns
   */
  createDeviceCryptoAssetClientCert(deviceId: any, payload: any): Observable<any> {
    return this.http
      .post<any>(`${API_DEVICES}/${deviceId}/client-certificates`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Create Crypto Asset CA Certificate on Device
   * @param deviceId
   * @param payload
   * @returns
   */
  createDeviceCryptoAssetCaCert(deviceId: any, payload: any): Observable<any> {
    return this.http
      .post<any>(`${API_DEVICES}/${deviceId}/ca-certificates`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Create Crypto Asset CA Certificate on Device
   * @param deviceId
   * @param payload
   * @returns
   */
  importDeviceCryptoAssetKey(deviceId: any, payload: any): Observable<any> {
    const headers = new HttpHeaders({
      timeout: (20 * 1000).toString(),
    });
    return this.http
      .post<any>(`${API_DEVICES}/${deviceId}/keys/import`, payload, { headers })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Create Crypto Asset CA Certificate on Device
   * @param deviceId
   * @param payload
   * @returns
   */
  importDeviceCryptoAssetClientCert(deviceId: any, payload: any): Observable<any> {
    const headers = new HttpHeaders({
      timeout: (20 * 1000).toString(),
    });
    return this.http
      .post<any>(`${API_DEVICES}/${deviceId}/client-certificates`, payload, { headers })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Delete Crypto Key Asset from device
   * @param deviceId The ID of the device
   * @param keyId The key ID to be deleted
   * @returns
   */
  deleteDeviceCryptoAssetKey(deviceId: any, keyId: any): Observable<any> {
    return this.http.delete<any>(`${API_DEVICES}/${deviceId}/keys/${keyId}`).pipe(
      catchError((error: any) => {
        return throwError(() => error);
      }),
    );
  }

  /**
   * Delete CA certificate Crypto Asset from device
   * @param deviceId The ID of the device
   * @param certId The CA certificate ID to be deleted
   * @returns
   */
  deleteDeviceCryptoAssetCaCert(deviceId: any, certId: any): Observable<any> {
    return this.http.delete<any>(`${API_DEVICES}/${deviceId}/ca-certificates/${certId}`).pipe(
      catchError((error: any) => {
        return throwError(() => error);
      }),
    );
  }

  /** ******************************************************************************
   * ****************************** DEVICE CA CERTIFICATES *************************
   ******************************************************************************** */

  /**
   * Get device Certificates
   * @returns
   */
  getDeviceCertificates(projectId: any, kmsKeyId?: any, kmsKeyringId?: any, entityType: string = 'device'): Observable<any> {
    const params = [];
    if (!!projectId) {
      params.push(`project_id=${projectId}`);
    }
    if (!!kmsKeyId) {
      params.push(`kms_key_id=${kmsKeyId}`);
    }
    if (!!kmsKeyringId) {
      params.push(`kms_keyring_id=${kmsKeyringId}`);
    }
    if (!!entityType) {
      params.push(`entity_type=${entityType}`);
    }
    return this.http.get<any[]>(`${API_CERTIFICATES}${!!params ? `?${params.join('&')}` : ''}`).pipe(
      map((rs: any) => {
        const certificates = Util.sortObjectArray(rs.certificates || [], 'id');
        certificates.forEach((cert) => {
          cert.not_before = !!cert.issue ? moment.utc(cert.issue).local() : null;
          cert.not_after = !!cert.expiry ? moment.utc(cert.expiry).local() : null;
        });
        rs.certificates = certificates;
        return rs;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  /**
   * Import CA certificate into device
   * @param deviceId
   * @param payload
   * @returns
   */
  importCACertificate(deviceId: any, payload: any): Observable<any> {
    const headers = new HttpHeaders({
      timeout: (20 * 1000).toString(),
    });
    return this.http
      .post<any>(`${API_DEVICES}/${deviceId}/ca-certificates/import`, payload, { headers })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Delete CA certificate from device
   * @param deviceId
   * @param filePath
   * @returns
   */
  deleteCACertificate(deviceId: any, filePath: any): Observable<any> {
    const headers = new HttpHeaders({
      timeout: (20 * 1000).toString(),
    });
    return this.http
      .delete<any>(`${API_DEVICES}/${deviceId}/ca-certificates?file_path=${encodeURI(filePath)}`, { headers })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /** ******************************************************************************
   * ********************** DEVICE CRYPTO ALIAS CONFIGURATION **********************
   ******************************************************************************** */

  /**
   * Create crypto alias into device
   * @param deviceId
   * @param payload
   * @returns
   */
  createCryptoAlias(deviceId: any, payload: any): Observable<any> {
    const headers = new HttpHeaders({
      timeout: (20 * 1000).toString(),
    });
    return this.http
      .post<any>(`${API_DEVICES}/${deviceId}/crypto-aliases`, payload, { headers })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Delete crypto alias from device
   * @param deviceId
   * @param filePath
   * @returns
   */
  deleteCryptoAlias(deviceId: any, filePath: any): Observable<any> {
    const headers = new HttpHeaders({
      timeout: (20 * 1000).toString(),
    });
    return this.http
      .delete<any>(`${API_DEVICES}/${deviceId}/crypto-aliases?file_path=${encodeURI(filePath)}`, { headers })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /** ******************************************************************************
   * ****************************** DEVICE TELEMETRY ******************************
   ******************************************************************************** */

  /**
   * Get device information
   * @param organizationId
   * @param projectId
   * @param deviceId
   * @param fromDate
   * @param toDate
   * @returns
   */
  getDeviceLog(config: {
    organizationId?: number | null;
    projectId?: number | null;
    deviceId?: number;
    fromDate?: any;
    toDate?: any;
    granularity?: string | null;
  }): Observable<any> {
    let url = `${API_DEVICES}${!config.deviceId ? '' : `/${config.deviceId}`}/telemetry`;
    let hasParams = false;
    // Extra slash if having params
    if (!!config.organizationId || !!config.projectId || !!config.fromDate || !!config.toDate) {
      url += '?';
    }
    // If using Metric page, filter by organization id and project id
    if (!config.deviceId) {
      url += `org_id=${config.organizationId}&&proj_id=${config.projectId}`;
      hasParams = true;
    }
    // Filter by from
    const fromMoment = moment(config.fromDate);
    if (!!fromMoment.isValid()) {
      url += `${!!hasParams ? '&' : ''}from=${fromMoment.toISOString()}`;
      hasParams = true;
    }
    // Filter by to
    const toMoment = moment(config.toDate);
    if (!!toMoment.isValid()) {
      url += `${!!hasParams ? '&' : ''}to=${toMoment.toISOString()}`;
      hasParams = true;
    }
    // Add granularity
    if (!!config.granularity) {
      url += `${!!hasParams ? '&' : ''}granularity=${config.granularity}`;
      hasParams = true;
    }
    return this.http.get<any>(url).pipe(
      map((result: any) => {
        const datetimeField = !config.deviceId ? 'timestamp' : 'datetime';
        const logs = (result.data as any[]) || [];
        logs.forEach((log) => this.convertUTCtoLocal(log, datetimeField));
        return { data: logs };
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  private convertUTCtoLocal(log: any, datetimeField: any) {
    const datetime = moment
      .utc(log?.[datetimeField])
      .local()
      .format();
    log[datetimeField] = moment(datetime).local().format(MOMENT_DATETIME);
  }

  sendDeviceInfoMQTTRequest(config: { country: string; org: string; orgUnit: string; deviceName: string }): void {
    this.mqtt?.unsafePublish(`dev/${config.country}/${config.org}/${config.orgUnit}/${config.deviceName}/cmd/rt`, '1', { qos: 2, retain: false });
  }

  startDeviceInfoMQTTSubscription(config: { country: string; org: string; orgUnit: string; deviceName: string; granularity: number }) {
    const obs = new Observable((observer) => {
      this.mqttSubscription = this.mqtt
        ?.observe(`dev/${config.country}/${config.org}/${config.orgUnit}/${config.deviceName}/tel/rt/all`, {
          qos: 2,
        })
        .subscribe((res: IMqttMessage) => {
          const payload = res.payload.toString();
          const data = !!payload ? JSON.parse(payload) : {};
          this.convertUTCtoLocal(data, 'datetime');
          observer.next(data);
        }) as Subscription;
    });
    return obs;
  }

  unsubscribeDeviceInfoMQTTRequest(interval: any): void {
    this.clearDeviceInfoInterval(interval);
    this.mqttSubscription?.unsubscribe();
  }

  clearDeviceInfoInterval(interval: any): void {
    if (!!interval) {
      clearInterval(interval);
    }
  }
}
