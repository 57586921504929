<div class="grid">
  <div class="col-12 text-center mt-5 mb-1 exclamation">
    <fa-icon [icon]="faExclamationCircle" />
  </div>

  <label class="col-12 text-data text-center">
    It is strongly recommended to enable
    <strong>Two-Factor Authentication</strong> on your account
  </label>

  <label class="col-12 text-data text-center mb-2"> You can setup 2FA using an authenticator app. To begin, click the button below </label>

  <div class="col-12 text-center mb-2">
    <p-button [autofocus]="true" severity="primary" styleClass="setup-btn" (onClick)="changeStep(1)" label="Setup 2FA with Authenticator App" />
  </div>

  <div class="col-12">
    <div class="grid footer">
      <div class="col warning vertical-align-top">
        WARNING: 2FA is required to generate QR Code for device enrollment.
        <br />
        Without setting up 2FA, it will be less convenient to enroll devices later on.
        <br />
        If you skip now, you can return to this screen by logging into the dashboard again.
      </div>
      <div class="col-fixed skip-button vertical-align-bottom">
        <p-button severity="success" (onClick)="changeStep(0)" label="Skip for now" />
      </div>
    </div>
  </div>
</div>
