import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MODULE_CONSTANTS } from '@microsec/constants';
import { authenticationSelectors } from '@microsec/ngrx-authentication';
import { BaseComponent } from '@lcms-components';
import { SHORT_TEXT_MAX_LENGTH } from '@microsec/constants';
import { firstValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  CODE: 'code',
};

@Component({
  selector: 'app-login2fa',
  templateUrl: './login2fa.component.html',
  styleUrls: ['./login2fa.component.scss'],
})
export class Login2faComponent extends BaseComponent implements OnInit {
  isLoading = false;

  FORM_PARAMS = FORM_PARAMS;

  form!: FormGroup;

  isWrongAuthenticationCode = false;

  SHORT_TEXT_MAX_LENGTH = SHORT_TEXT_MAX_LENGTH;

  async ngOnInit() {
    await this.prepareConfigs();
    const { username, password } = await firstValueFrom(this.store.select(authenticationSelectors.temporaryCredential));
    if (!username || !password) {
      this.returnToLogin();
    }
    this.form = new FormGroup({
      [FORM_PARAMS.CODE]: new FormControl('', {
        validators: Validators.required,
      }),
    });
  }

  /**
   * Submit
   */
  submit() {
    this.isLoading = true;
    this.form.disable();
    this.authSrv
      .login(this.form.value[FORM_PARAMS.CODE])
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.form.enable();
        }),
      )
      .subscribe({
        next: () => {
          this.form.disable();
          this.clearMessages();
          this.showSuccessMessage('Logged in successfully!');
          setTimeout(() => {
            this.router.navigate([`/${MODULE_CONSTANTS.REDIRECTION.ROUTE}`]);
          });
        },
        error: (err: any) => {
          this.clearMessages();
          if (err?.status === 0) {
            this.showErrorMessage('Invalid Domain Name');
          } else {
            this.showErrorMessage(err);
          }
          this.isWrongAuthenticationCode = true;
        },
      });
  }

  /**
   * Return to login page
   */
  returnToLogin() {
    this.changeRoute(`/${MODULE_CONSTANTS.LOGIN.ROUTE}`);
  }
}
