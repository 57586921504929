/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, of, scheduled } from 'rxjs';
import { ICaTemplatesService } from './ICaTemplatesService';
import caTemplatesData from './mock-data/mock-ca-templates.json';

@Injectable({
  providedIn: 'root',
})
export class MockCaTemplatesService implements ICaTemplatesService {
  getAllCATemplates(): Observable<any> {
    return scheduled([caTemplatesData], asyncScheduler);
  }

  getCATemplatesByOrganization(org_id: any): Observable<any> {
    return scheduled([caTemplatesData], asyncScheduler);
  }

  getCATemplatesByProject(project_id: any): Observable<any> {
    return scheduled([caTemplatesData], asyncScheduler);
  }

  getCATemplate(id: any): Observable<any> {
    return scheduled([caTemplatesData], asyncScheduler);
  }

  createCATemplate(payload: any): Observable<any> {
    return of(null);
  }

  updateCATemplate(id: any, payload: any): Observable<any> {
    return of(null);
  }

  deleteCATemplate(id: any): Observable<any> {
    return of(null);
  }

  getAssignedCATemplates(caId: any): Observable<any> {
    return scheduled(
      [
        {
          data: {
            templates: [],
            default_template_id: null,
          },
        },
      ],
      asyncScheduler,
    );
  }

  updateAssignedCATemplates(caId: any, payload: any): Observable<any> {
    return of(null);
  }

  getUnassignedCATemplates(caId: any): Observable<any> {
    return scheduled([caTemplatesData], asyncScheduler);
  }
}
