/* eslint-disable @typescript-eslint/no-explicit-any */
import { Util } from '@microsec/utilities';
import { DeviceSummaryAction, DeviceSummaryActionTypes } from './device-summary.actions';

export const DEVICE_SUMMARY_FEATURE_KEY = 'deviceSummary';

export interface DeviceSummaryState {
  summary?: any;
  isLoading: boolean;
  error?: string | null;
  lastRefresh?: Date | null;
  cas: any[];
  isCALoading: boolean;
  deviceResults: {
    enrolled: any[];
    expiring: any[];
    expired: any[];
  };
  devicesPerCA: any[];
}

export const initialDeviceSummaryState: DeviceSummaryState = {
  summary: null,
  error: null,
  isLoading: false,
  lastRefresh: null,
  cas: [],
  isCALoading: false,
  deviceResults: {
    enrolled: [],
    expiring: [],
    expired: [],
  },
  devicesPerCA: [],
};

export function deviceSummaryReducer(state: DeviceSummaryState = initialDeviceSummaryState, action: DeviceSummaryAction): DeviceSummaryState {
  switch (action.type) {
    case DeviceSummaryActionTypes.INIT: {
      state = Util.cloneDeepObject(initialDeviceSummaryState);
      break;
    }
    case DeviceSummaryActionTypes.GET_DEVICE_SUMMARY: {
      state = {
        ...state,
        isLoading: true,
        error: null,
        lastRefresh: null,
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_DEVICE_SUMMARY_SUCCESS: {
      state = {
        ...state,
        summary: action.summary,
        isLoading: false,
        error: null,
        lastRefresh: new Date(),
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_DEVICE_SUMMARY_FAILURE: {
      const { error } = action;
      state = {
        ...state,
        isLoading: false,
        error: error,
        lastRefresh: new Date(),
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_ENROLLED_DEVICE_SUMMARY_SUCCESS: {
      state = {
        ...state,
        deviceResults: { ...state.deviceResults, enrolled: action.results },
        error: null,
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_ENROLLED_DEVICE_SUMMARY_FAILURE: {
      const { error } = action;
      state = {
        ...state,
        error: error,
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_EXPIRING_DEVICE_SUMMARY_SUCCESS: {
      state = {
        ...state,
        deviceResults: { ...state.deviceResults, expiring: action.results },
        error: null,
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_EXPIRING_DEVICE_SUMMARY_FAILURE: {
      const { error } = action;
      state = {
        ...state,
        error: error,
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_EXPIRED_DEVICE_SUMMARY_SUCCESS: {
      state = {
        ...state,
        deviceResults: { ...state.deviceResults, expired: action.results },
        error: null,
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_EXPIRED_DEVICE_SUMMARY_FAILURE: {
      const { error } = action;
      state = {
        ...state,
        error: error,
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_SUMMARY_CAS: {
      state = {
        ...state,
        isCALoading: true,
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_SUMMARY_CAS_SUCCESS: {
      state = {
        ...state,
        cas: action.cas,
        isCALoading: false,
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_SUMMARY_CAS_FAILURE: {
      const { error } = action;
      state = {
        ...state,
        error: error,
        isCALoading: false,
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_DEVICES_PER_CA_SUCCESS: {
      state = {
        ...state,
        devicesPerCA: action.result,
        error: null,
      };
      break;
    }
    case DeviceSummaryActionTypes.GET_DEVICES_PER_CA_FAILURE: {
      const { error } = action;
      state = {
        ...state,
        error: error,
      };
      break;
    }
  }
  return state;
}
