@if (!view) {
  <!-- FORM -->
  <microsec-form-builder #fb [fields]="fields" [shouldShowActionButtons]="true" [submitButtonLabel]="VERIFY_LABEL" (submitEvent)="onSubmit()" />
} @else {
  <!-- RESULT -->
  <div class="p-3">
    <div class="field grid text-center">
      <div class="col-12">
        <i
          class="icon pi"
          [ngClass]="{
            'pi-check-circle': view === 'success',
            'pi-times-circle': view !== 'success'
          }"></i>
      </div>
      <div class="col-12 text-data">
        {{ resultContent }}
      </div>
    </div>
    <div class="flex justify-content-end action-buttons pt-3">
      <p-button severity="success" (onClick)="dialogRef.close(false)" label="OK" />
    </div>
  </div>
}
