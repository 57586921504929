import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ruleset-info',
  templateUrl: './ruleset-info.component.html',
  styleUrls: ['./ruleset-info.component.scss'],
})
export class RulesetInfoComponent {
  _selectedRuleset: any = null;

  @Input() set selectedRuleset(value: any) {
    this._selectedRuleset = value;
  }

  get selectedRuleset() {
    return this._selectedRuleset;
  }

  _type = '';

  get type() {
    return this._type;
  }
  @Input() set type(value: any) {
    this._type = value;
  }

  @Output() updateRulesetEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() exportRulesetEvent: EventEmitter<any> = new EventEmitter<any>();

  updateRuleset(event: any) {
    this.updateRulesetEvent.emit(event);
  }

  exportRuleset(ruleset: any) {
    this.exportRulesetEvent.emit(ruleset);
  }
}
