import { Component, OnDestroy, OnInit } from '@angular/core';
import { SYSTEM_MANAGEMENT_FEATURES } from '@lcms-constants';
import { USER_ROLE } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { LDAPSettingService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { firstValueFrom } from 'rxjs';
import { USER_ACCESS_CHILDREN, USER_ACCESS_CONSTANTS } from '../../../shared-components/shared-user-access/shared-user-access.config';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss'],
})
export class UserAccessComponent extends BaseComponent implements OnInit, OnDestroy {
  LDAPSetting: any = {};

  constructor(private ldapSettingSrv: LDAPSettingService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.setTabItems();
  }

  override ngOnDestroy(): void {
    this.cleanup({ shouldClearTabItems: true });
  }

  /**
   * Set tab items
   */
  async setTabItems() {
    if (!!this.checkPermissionsByScope(USER_ROLE.ADMIN, true)) {
      // Get LDAP settings
      const ldapSettings = !!this.isLDAPFeatured ? await firstValueFrom(this.ldapSettingSrv.getLDAPSetting()) : null;
      // Check tabs
      const tabItems = this.util.cloneObjectArray(USER_ACCESS_CHILDREN).filter((p) => !!p.label);
      const checkedItems = tabItems.filter(
        (p) => p.route === USER_ACCESS_CONSTANTS.USERS.ROUTE || p.route === USER_ACCESS_CONSTANTS.LDAP_SETTINGS.ROUTE,
      );
      checkedItems.forEach((item) => {
        const checkedItem = tabItems.find((tab) => tab.route === item.route);
        if (!!checkedItem) {
          switch (checkedItem.route) {
            case USER_ACCESS_CONSTANTS.LDAP_SETTINGS.ROUTE: {
              if (!!ldapSettings && ldapSettings?.is_enabled && ldapSettings?.is_allow_group_mapping) {
                checkedItem.visible = true;
              }
              break;
            }
            default: {
              checkedItem.visible = true;
              break;
            }
          }
        }
      });
      this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  /**
   * Check if LDAP is featured
   */
  get isLDAPFeatured() {
    return this.checkSystemManagementFeatureEnabled(SYSTEM_MANAGEMENT_FEATURES.AUTH_LDAP);
  }
}
