import { Component, OnInit } from '@angular/core';
import { Setting2FAService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { finalize } from 'rxjs/operators';
import { Setup2FAComponent } from './setup2fa/setup2fa.component';

@Component({
  selector: 'app-config2fa',
  templateUrl: './config2fa.component.html',
  styleUrls: ['./config2fa.component.scss'],
})
export class Config2faComponent extends BaseComponent implements OnInit {
  isLoading = true;

  isDisabled = true;

  setting: any = {};

  constructor(private setting2FASrv: Setting2FAService) {
    super();
  }

  ngOnInit() {
    this.setting2FASrv.getOpenGlobalSetting().subscribe({
      next: (response: any) => {
        if (response.is_enabled && response.auth_provider === 'google_auth') {
          this.isDisabled = false;
          this.getUserSetting();
        } else {
          this.navigateToNotFoundPage();
        }
      },
      error: (err: any) => {
        this.showErrorMessage(err);
        this.navigateToNotFoundPage();
      },
    });
  }

  /**
   * Get user setting
   */
  getUserSetting() {
    this.isLoading = true;
    this.setting2FASrv
      .getUserSetting()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          this.setting = response;
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Set user setting
   * @param isEnabled
   */
  setUserSetting(isEnabled: boolean) {
    this.isLoading = true;
    this.authSrv.checkSetup2FA().subscribe({
      next: (is2FANeeded: any) => {
        // if is2FA true, can update user setting directly
        if (!!is2FANeeded) {
          const payload = {
            status: isEnabled,
          };
          this.setting2FASrv
            .updateUserSetting(payload)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              }),
            )
            .subscribe({
              next: () => {
                this.getUserSetting();
                this.showSuccessMessage('Saved event settings successfully!');
              },
              error: (err: any) => {
                this.showErrorMessage(err);
              },
            });
        }
        // if is2FA false, need to setup 2FA before update user setting
        else {
          this.openDialogSetup2FA();
        }
      },
      error: (err: any) => {
        this.isLoading = false;
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Open Setup 2FA dialog
   */
  openDialogSetup2FA() {
    this.dialogSrv.open(Setup2FAComponent, {
      header: '2FA Setup',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }
}
