/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { MockEventMonitorService as SharedMockEventMonitorService } from '@microsec/services';
import { Observable, asyncScheduler, scheduled } from 'rxjs';
import eventCategoriesData from './mock-data/event-categories.json';
import eventsData from './mock-data/events.json';
import { Util } from '@microsec/utilities';

@Injectable()
export class MockEventMonitorService extends SharedMockEventMonitorService {
  override getEvents(config?: { search: any; page: any; perPage: any }): Observable<any> {
    const data = Util.cloneDeepObject(eventsData);
    const results: any[] = [];
    for (let i = 0; i < config?.perPage / 10; i++) {
      const addedNumber = i * 10 + config?.perPage * (config?.page - 1);
      const events = Util.cloneObjectArray((data.events as any[]) || []).map((e) => {
        const event = {
          ...e,
          id: e.id + addedNumber,
        };
        return event;
      });
      results.push(...events);
    }
    data.events = Util.sortObjectArray(results, 'id', false);
    return scheduled([data], asyncScheduler);
  }

  override getEventCategories(): Observable<any> {
    return scheduled([eventCategoriesData], asyncScheduler);
  }
}
