import { Component, Input } from '@angular/core';
import { RULE_TYPE_VALUES } from '@lcms-constants';

@Component({
  selector: 'app-device-rules',
  templateUrl: './device-rules.component.html',
  styleUrls: ['./device-rules.component.scss'],
})
export class DeviceRulesComponent {
  _selectedDevice: any = null;

  get selectedDevice() {
    return this._selectedDevice;
  }

  @Input() set selectedDevice(value: any) {
    this._selectedDevice = value;
  }

  RULE_TYPE_VALUES = RULE_TYPE_VALUES;
}
