<microsec-form-builder
  #fb
  [fields]="fields"
  [shouldShowActionButtons]="true"
  [submitButtonLabel]="CREATE_LABEL"
  (submitEvent)="onSubmit($event)"
  [additionalConditions]="!!form && !!form.getControlValue(FORM_PARAMS.VALID)" />

<ng-template #validateButton>
  @if (!!form) {
    <div class="grid mt-1">
      @if (form.getControlValue(FORM_PARAMS.VALID) === false) {
        <label class="col-12 text-error"> Warning: The text input is invalid! </label>
      }
      <div class="col-12 flex justify-content-end">
        @if (!!form.getControlValue(FORM_PARAMS.VALID)) {
          <p-button severity="secondary" styleClass="mt-2" type="button" (onClick)="enableChangeText()" label="Change" />
        } @else {
          <p-button
            severity="primary"
            styleClass="mt-2"
            type="button"
            [disabled]="!form.getControlValue(FORM_PARAMS.CONTENT)"
            (onClick)="validateText()"
            label="Validate" />
        }
      </div>
    </div>
  }
</ng-template>
