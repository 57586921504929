import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@lcms-shared';
import { LoginModule as CommonLoginModule } from './products/login/login.module';
import { OrganizationManagementModule as CommonOrganizationManagementModule } from './products/organization-management/organization-management.module';
import { ProjectManagementModule as CommonProjectManagementModule } from './products/project-management/project-management.module';
import { GlobalSettingsModule as CommonGlobalSettingsModule } from './products/global-settings/global-settings.module';
import { UserSettingsModule as CommonUserSettingsModule } from './products/user-settings/user-settings.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CommonLoginModule,
    CommonOrganizationManagementModule,
    CommonProjectManagementModule,
    CommonGlobalSettingsModule,
    CommonUserSettingsModule,
  ],
  exports: [
    CommonLoginModule,
    CommonOrganizationManagementModule,
    CommonProjectManagementModule,
    CommonGlobalSettingsModule,
    CommonUserSettingsModule,
  ],
})
export class ProductsModule {
  static forRoot(environment: any): ModuleWithProviders<ProductsModule> {
    return {
      ngModule: ProductsModule,
      providers: [
        {
          provide: 'environment', // you can also use InjectionToken
          useValue: environment,
        },
      ],
    };
  }
}
