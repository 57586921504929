import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppInjector } from '@microsec/utilities';
import 'apps/lcms-ra/src/global';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'codemirror/mode/python/python';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    AppInjector.setInjector(moduleRef.injector);
  })
  .catch((err) => window.microsec.error(err));
