import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@lcms-shared';
import { LDAPGroupFormComponent } from './ldap-settings/ldap-group-form/ldap-group-form.component';
import { LDAPSettingsComponent } from './ldap-settings/ldap-settings.component';
import { UserAccessFormComponent } from './users/user-access-form/user-access-form.component';
import { UsersComponent } from './users/users.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [UsersComponent, UserAccessFormComponent, LDAPSettingsComponent, LDAPGroupFormComponent],
  exports: [UsersComponent, UserAccessFormComponent, LDAPSettingsComponent, LDAPGroupFormComponent],
})
export class SharedUserAccessModule {}
