import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserCertificatesEffects } from './+state/user-certificates.effects';
import * as fromUserCertificates from './+state/user-certificates.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUserCertificates.USER_CERTIFICATES_FEATURE_KEY, fromUserCertificates.userCertificatesReducer),
    EffectsModule.forFeature([UserCertificatesEffects]),
  ],
})
export class NgrxUserCertificatesModule {}
