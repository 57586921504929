<div class="flex justify-content-center login-container">
  <div class="flex flex-column align-self-center wrapper">
    <microsec-card>
      @if (!!form) {
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="large-padding">
            <div class="grid">
              <!-- Title -->
              <label class="col-12 title"> 2FA </label>

              <label class="col-12 text-center note">
                <p class="text-sm font-italic">Enter the code from the two-factor app on your mobile device.</p>
                <p class="text-sm font-italic">If you have lost your device, you may enter one of your recovery codes.</p>
              </label>

              <!-- Code -->
              <label class="col-12"> Two-factor authentication code </label>
              <div class="col-12">
                <input
                  [autofocus]="true"
                  inputTrimRef
                  [maxlength]="SHORT_TEXT_MAX_LENGTH"
                  type="text"
                  pInputText
                  tabindex="1"
                  [formControlName]="FORM_PARAMS.CODE" />
              </div>
              @if (!!isWrongAuthenticationCode) {
                <label class="col-12 text-error"> Please enter the correct authentication code. </label>
              }

              <div class="col-12 flex flex-row justify-content-between align-items-center login-actions">
                <label class="link" (click)="returnToLogin()">&lt; Return to login</label>
                <p-button
                  severity="primary"
                  type="submit"
                  label="Submit"
                  [loading]="!!isLoading"
                  [disabled]="!form.valid || !!isLoading"
                  tabindex="2" />
              </div>
            </div>
          </div>
        </form>
      }
    </microsec-card>
  </div>
</div>
