import { CommonTableComponent } from '@microsec/components';
import {
  MODULE_CONSTANTS,
  USER_ROLE,
  ORGANIZATION_ID_PARAM_ROUTE,
  PROJECT_ID_PARAM_ROUTE,
  FORCE_REMOVE_MESSAGE_TEMPLATE,
  DELETE_LABEL,
  FORCE_DELETE_LABEL,
} from '@microsec/constants';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PROJECT_STATUS_TAGS, QUOTAS_FEATURES } from '@lcms-constants';
import { ProjectService } from '@microsec/services';
import { BaseComponent } from '@lcms-components';
import { ConfirmationDialogConfig, CommonToolbarResult, CommonToolbarConfiguration, ActionMenuItem } from '@microsec/models';
import { finalize, BehaviorSubject } from 'rxjs';
import { SharedProjectFormComponent } from '../../../shared-components/shared-project-form/shared-project-form.component';

const FIELDS = {
  ID: 'ID',
  STATUS: 'Status',
  NAME: 'Name',
  X509_ENABLED: 'X509 Enabled',
  MICROPKI_ENABLED: 'MicroPKI Enabled',
  NUMBER_OF_ENROLLED_DEVICES: '# of Enrolled Devices',
  USERS: 'Users',
  ALERTS: 'Alerts',
};

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols: any[] = [
    { field: 'id', header: FIELDS.ID, width: 5, frozen: true },
    { field: 'is_active', header: FIELDS.STATUS, width: 5, frozen: true },
    { field: 'name', header: FIELDS.NAME, width: 10 },
    { field: 'x509Enabled', header: FIELDS.X509_ENABLED, width: 5 },
    { field: 'microPKIEnabled', header: FIELDS.MICROPKI_ENABLED, width: 5 },
    {
      field: 'enrolledDeviceNumber',
      header: FIELDS.NUMBER_OF_ENROLLED_DEVICES,
      width: 10,
    },
    { field: 'users', header: FIELDS.USERS, width: 6 },
    { field: 'alerts', header: FIELDS.ALERTS, width: 6 },
  ];

  selectedProject: any = null;

  values: any[] = [];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search...',
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    public el: ElementRef,
    private projectSrv: ProjectService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.permissions[this.SCOPE.ORGANIZATION][this.USER_ROLE.ADMIN],
        command: ({ rowData }: any) => this.openProjectForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.ORGANIZATION][this.USER_ROLE.ADMIN],
        command: ({ rowData }: any) => this.openDeleteConfirmation(rowData),
      },
    ];
    this.handleFilterObjUpdate();
    this.getProjects();
  }

  /**
   * Handle filters
   */
  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values.search || '';
      }
    });
  }

  /**
   * Get the list of all projects
   */
  getProjects() {
    this.isLoading = true;
    this.values = [];
    this.projectSrv
      .getProjects(this.breadcrumbConfig?.organizationId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res: any[]) => {
          this.values = res.map((p) => ({ ...p, hasPermission: this.checkPermission(p.user_role, USER_ROLE.ADMIN) }));
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Open a form to create or edit project
   * @param project
   */
  openProjectForm(project = null) {
    const dialogRef = this.dialogSrv.open(SharedProjectFormComponent, {
      data: {
        project: !project ? null : project,
      },
      header: `${!project ? 'Create' : 'Edit'} Project`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        this.getProjects();
      }
    });
  }

  /**
   * Open a delete modal confirmation
   * @param project
   */
  openDeleteConfirmation(project: any) {
    // Normal confirmation
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Project',
      object: project,
      objectFieldName: 'name',
      acceptRequest: this.projectSrv.deleteProject(project.id),
      next: () => {
        this.showSuccessMessage(`Deleted project ${project.name} successfully`);
        this.getProjects();
      },
      error: () => {
        // Force confirmation
        this.confirm({
          isDependenciesDetected: true,
          action: FORCE_DELETE_LABEL,
          customContent: FORCE_REMOVE_MESSAGE_TEMPLATE.replace('{0}', 'project'),
          shouldShowDefaultContent: false,
          acceptRequest: this.projectSrv.deleteProject(project.id, true),
          next: () => {
            this.showSuccessMessage(`Deleted project ${project.name} successfully`);
            this.getProjects();
          },
          error: (err: any) => {
            this.showErrorMessage(err);
          },
        } as ConfirmationDialogConfig);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Get the config for the status label
   * @param status
   * @returns
   */
  getLabelConfig(status: boolean) {
    const config = PROJECT_STATUS_TAGS.find((p) => p.value === status);
    return !!config
      ? config
      : {
          value: '',
          label: '',
          backgroundColor: 'transparent',
          color: 'transparent',
        };
  }

  /**
   * Route to project
   */
  goToProject(project_id: any) {
    this.changeRoute(
      MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE.replace(
        ORGANIZATION_ID_PARAM_ROUTE,
        this.breadcrumbConfig?.organizationId?.toString() as string,
      ).replace(PROJECT_ID_PARAM_ROUTE, project_id),
    );
  }

  /**
   * Check if allow add more project
   */
  get checkProjectQuota() {
    return this.values?.length < this.getQuotaLimitation(QUOTAS_FEATURES.PROJECTS);
  }
}
