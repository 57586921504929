/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Util } from '@microsec/utilities';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import { ICAService } from './ICAService';
import crlSettingsData from './mock-data/ca-crl-settings.json';
import caPermissionsList from './mock-data/ca-permissions-list.json';
import publishingSettingsData from './mock-data/ca-publishing-settings.json';
import caRoles from './mock-data/ca-roles.json';
import casData from './mock-data/cas.json';
import jsonschemaData from './mock-data/json-schema.json';

@Injectable({
  providedIn: 'root',
})
export class MockCaService implements ICAService {
  cas: any[] = [];

  getCASchema(): Observable<any> {
    const data = jsonschemaData;
    return scheduled([data], asyncScheduler);
  }

  getCAs(organizationId: number, projectId: number, type?: string): Observable<any> {
    return scheduled([(casData as any)?.data], asyncScheduler);
  }

  getCA(caId: number): Observable<any> {
    return scheduled([{ ...(casData as any)?.data[0], id: Util.generateRandomNumberString() }], asyncScheduler);
  }

  createCA(payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  createExternalCSR(payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  createExternalCA(payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  importCA(
    organizationId: any,
    projectId: any,
    _caRoles: any[],
    root: { cert: any; key: any; password: string },
    intItems: { id: any; cert: any; key: any }[],
  ): Observable<any[]> {
    return scheduled([_caRoles.map(() => ({ message: 'Imported successfully' }))], asyncScheduler);
  }

  downloadCA(caId: number): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  downloadCRL(serialNumberNotHex: any, crlType: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  getCARoles(projectId: number, searchTerm?: string): Observable<any> {
    return scheduled([caRoles], asyncScheduler);
  }

  createCARole(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateCARole(roleId: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteCARole(roleId: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  setCAPermissionsEnabled(caId: any, value: boolean): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getCAPermissionsList(): Observable<any[]> {
    return scheduled([caPermissionsList], asyncScheduler);
  }

  getCAPermissions(caId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  createCAPermission(caId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateCAPermission(caId: any, permissionId: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getCRLSettings(caId: number): Observable<any> {
    return scheduled([crlSettingsData], asyncScheduler);
  }

  updateCRLSettings(caId: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateManualCRL(caId: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getPublishingSettings(caId: number): Observable<any> {
    return scheduled([publishingSettingsData], asyncScheduler);
  }

  updatePublishingSettings(caId: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  publishCRL(caId: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  createCSRCertificate(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
