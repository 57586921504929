import { AfterViewInit, Component, Input } from '@angular/core';
import { KmsService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormItem } from '@microsec/models';
import { BehaviorSubject, finalize, forkJoin } from 'rxjs';
import { ORGANIZATION_LEVEL_ROUTE, PROJECT_LEVEL_ROUTE } from '@microsec/constants';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { PROJECT_SETTINGS_CONSTANTS } from '@lcms-products';

const FORM_PARAMS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  TYPE: 'type',
  SIGNING_ACTIVE: 'signingActive',
  KEYRING: 'keyring',
  GPG_KEY: 'gpgKey',
  URL: 'url',
};

@Component({
  selector: 'app-repository-overview',
  templateUrl: './repository-overview.component.html',
  styleUrls: ['./repository-overview.component.scss'],
})
export class RepositoryOverviewComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  _selectedRepo: any = null;

  get selectedRepo() {
    return this._selectedRepo;
  }

  @Input() set selectedRepo(value: any) {
    this._selectedRepo = value;
    this._selectedRepo.signingActive = !!value?.signing ? 'Yes' : 'No';
    this.selectedRepo$.next(this._selectedRepo);
  }

  selectedRepo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  structure: any[] = [];

  constructor(private kmsSrv: KmsService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.selectedRepo$.asObservable().subscribe(() => {
      if (!!this.selectedRepo?.signing && !!this.selectedRepo?.keyring_id && !!this.selectedRepo?.key_id) {
        this.getKeyringAndGPGKey(this.selectedRepo.keyring_id, this.selectedRepo.key_id);
      } else {
        this.initForm();
      }
    });
  }

  /**
   * Init the form
   */
  async initForm() {
    await this.prepareConfigs();
    this.structure = [
      { label: 'Name', name: FORM_PARAMS.NAME },
      { label: 'Description', name: FORM_PARAMS.DESCRIPTION },
      { label: 'Type', name: FORM_PARAMS.TYPE },
      { label: 'Signing Active', name: FORM_PARAMS.SIGNING_ACTIVE },
      ...(!!this.selectedRepo?.signing
        ? [
            { label: 'Keyring', name: FORM_PARAMS.KEYRING, field: this.selectedRepo?.[FORM_PARAMS.KEYRING] ? 'linkchips' : 'label' },
            { label: 'GPG Key', name: FORM_PARAMS.GPG_KEY, field: this.selectedRepo?.[FORM_PARAMS.GPG_KEY] ? 'linkchips' : 'label' },
          ]
        : []),
      { label: 'URL', name: FORM_PARAMS.URL, field: 'link' },
    ];
  }

  /**
   * Get keyring and GPG key
   * @param keyringId
   * @param gpgKeyId
   */
  getKeyringAndGPGKey(keyringId: any, gpgKeyId: any) {
    forkJoin([this.kmsSrv.getKeyring(keyringId), this.kmsSrv.getGPGKey(keyringId, gpgKeyId)])
      .pipe(
        finalize(() => {
          this.initForm();
        }),
      )
      .subscribe({
        next: (rs: any[]) => {
          this._selectedRepo[FORM_PARAMS.KEYRING] = [
            {
              label: `${rs?.[0]?.data?.name || rs?.[0]?.name || keyringId} (${keyringId})`,
              url:
                `${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
                `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
                `${PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE}/${PROJECT_SETTINGS_CONSTANTS.KMS.ROUTE}` +
                `?keyring_id=${keyringId}`,
            },
          ];
          this._selectedRepo[FORM_PARAMS.GPG_KEY] = [
            {
              label: `${rs?.[1]?.label || gpgKeyId} (${gpgKeyId})`,
              url:
                `${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
                `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
                `${PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE}/${PROJECT_SETTINGS_CONSTANTS.KMS.ROUTE}` +
                `?keyring_id=${keyringId}&gpg_key_id=${gpgKeyId}`,
            },
          ];
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }
}
