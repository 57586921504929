<microsec-card>
  <div class="font-bold text-xl block mb-3">Device Enrollment Configuration</div>
  <div class="grid">
    <div class="col-12 lg:col-9">
      <microsec-form-builder
        [isPage]="true"
        #fb
        [fields]="fields"
        [shouldShowActionButtons]="true"
        [leftActionArea]="leftActionArea"
        [submitButtonLabel]="SAVE_CHANGES_LABEL"
        [cancelButtonLabel]="''"
        (submitEvent)="onSubmit()" />
    </div>
  </div>
</microsec-card>

<ng-template #leftActionArea>
  <p-button severity="secondary" styleClass="mr-2" type="button" (onClick)="getData()" label="Discard" />
</ng-template>
