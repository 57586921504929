<microsec-layout-table
  [itemName]="type"
  [isLoading]="isLoading"
  [shouldShowCreateButton]="true"
  (openFormEvent)="openForm()"
  [filterKey]="constructor.name"
  [cols]="cols"
  [values]="values"
  [actionMenuItems]="actionMenuItems"
  (getItemsEvent)="getRules()"
  [cellTemplate]="cellTemplate"
  [selectedItem]="null"
  [detailsTemplate]="null" />

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- ======================== IP ======================== -->
    <!-- Source -->
    @case ('src_ip') {
      <microsec-truncate-text [tooltip]="'IP: ' + rowData['src_ip'] + '\nPorts: ' + (!!rowData['src_ports'] ? rowData['src_ports'] : 'anywhere')">
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
    <!-- Destination -->
    @case ('dst_ip') {
      <microsec-truncate-text [tooltip]="'IP: ' + rowData['dst_ip'] + '\nPorts: ' + (!!rowData['dst_ports'] ? rowData['dst_ports'] : 'anywhere')">
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
    <!-- ======================== CAN ======================== -->
    <!-- Target -->
    @case ('target') {
      <div [pTooltip]="'Chain: ' + rowData['chain']">
        {{ rowData[col.field] }}
      </div>
    }
    <!-- CAN Version -->
    @case ('can_version') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'firewall-can-version' }}
      </microsec-truncate-text>
    }
    <!-- ======================== COMMAND ======================== -->
    <!-- Action -->
    @case ('action') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'rule-strategy' }}
      </microsec-truncate-text>
    }
    <!-- ======================== YARA ======================== -->
    <!-- Strategy -->
    @case ('strategy') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'rule-strategy' }}
      </microsec-truncate-text>
    }
    <!-- Mode -->
    @case ('mode') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'rule-yara-mode' }}
      </microsec-truncate-text>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
