import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { API } from '../api';
import { IRepositoryService } from './IRepositoryService';

@Injectable({
  providedIn: 'root',
})
export class RepositoryService implements IRepositoryService {
  /** ---------- Repository ---------- */

  constructor(private http: HttpClient) {}

  getRepositories(projectId: any): Observable<any> {
    return this.http
      .get<any>(`${API.REPO_SERVICES}/repositories?project_id=${projectId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getRepository(id: any): Observable<any> {
    return this.http.get<any>(`${API.REPO_SERVICES}/repository/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createRepository(payload: any): Observable<any> {
    return this.http.post<any>(`${API.REPO_SERVICES}/repositories`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateRepository(id: any, payload: any): Observable<any> {
    return this.http
      .put<any>(`${API.REPO_SERVICES}/repository/${id}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteRepository(id: any): Observable<any> {
    return this.http.delete<any>(`${API.REPO_SERVICES}/repository/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /** ---------- Package ---------- */

  getPackagesByRepository(repoId: any): Observable<any> {
    return this.http
      .get<any>(`${API.REPO_SERVICES}/repositories/${repoId}/packages`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  signPackageByRepository(repoId: any, payload: any): Observable<any> {
    return this.http
      .post<any>(`${API.REPO_SERVICES}/repositories/${repoId}/packages`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getPackageByRepository(repoId: any, packageId: any): Observable<any> {
    return this.http
      .get<any>(`${API.REPO_SERVICES}/repositories/${repoId}/packages/${packageId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deletePackageByRepository(repoId: any, packageId: any): Observable<any> {
    return this.http
      .delete<any>(`${API.REPO_SERVICES}/repositories/${repoId}/packages/${packageId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /** ---------- Agent ---------- */

  /**
   * Get the agent form
   * @param projectId
   */
  getAgentForm(projectId: any): Observable<any> {
    return this.http
      .get<any>(`${API.REPO_SERVICES}/agent/form?project_id=${projectId}&platform=lcms`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Download agent
   * @param payload
   */
  downloadAgent(payload: any): Observable<any> {
    const options: any = { responseType: 'blob', observe: 'response' };
    return this.http
      .post<any>(`${API.REPO_SERVICES}/agent/download`, payload, options)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
