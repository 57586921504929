import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { DEVICE_MANAGEMENT_FEATURES, FEATURES } from '@lcms-constants';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { DetailsComponent } from './details/details.component';
import { DEVICES_CONSTANTS } from './devices.constants';
import { EnrollmentComponent } from './enrollment/enrollment.component';
import { RulesComponent } from './rules/rules.component';
import { FirmwaresComponent } from './firmwares/firmwares.component';
import { MetricsComponent } from './metrics/metrics.component';
import { OverviewComponent } from './overview/overview.component';
import { DEVICE_OVERVIEW_CHILDREN } from './overview/overview.config';
import { PackagesComponent } from './packages/packages.component';
import { DEVICE_RULE_CHILDREN } from '../../../shared-components/shared-rules/shared-rules.config';

export const DEVICES_CHILDREN: ILayoutItem[] = [
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE,
    label: '',
    route: DEVICES_CONSTANTS.OVERVIEW.ROUTE,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE,
    route: DEVICES_CONSTANTS.OVERVIEW.ROUTE,
    label: DEVICES_CONSTANTS.OVERVIEW.LABEL,
    component: OverviewComponent,
    children: DEVICE_OVERVIEW_CHILDREN,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE,
    route: DEVICES_CONSTANTS.DETAILS.ROUTE,
    label: DEVICES_CONSTANTS.DETAILS.LABEL,
    component: DetailsComponent,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE,
    route: DEVICES_CONSTANTS.ENROLLMENT.ROUTE,
    label: DEVICES_CONSTANTS.ENROLLMENT.LABEL,
    component: EnrollmentComponent,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE,
    route: DEVICES_CONSTANTS.METRICS.ROUTE,
    label: DEVICES_CONSTANTS.METRICS.LABEL,
    component: MetricsComponent,
    featureName: FEATURES.DEVICE_MANAGEMENT,
    featureCheckField: DEVICE_MANAGEMENT_FEATURES.TELEMETRY,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE,
    route: DEVICES_CONSTANTS.PACKAGES.ROUTE,
    label: DEVICES_CONSTANTS.PACKAGES.LABEL,
    component: PackagesComponent,
    featureName: FEATURES.DEVICE_MANAGEMENT,
    featureCheckField: DEVICE_MANAGEMENT_FEATURES.PACKAGES,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE,
    route: DEVICES_CONSTANTS.FIRMWARES.ROUTE,
    icon: faNetworkWired,
    label: DEVICES_CONSTANTS.FIRMWARES.LABEL,
    component: FirmwaresComponent,
    featureName: FEATURES.DEVICE_MANAGEMENT,
    featureCheckField: DEVICE_MANAGEMENT_FEATURES.FIRMWARE,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE,
    route: DEVICES_CONSTANTS.RULES.ROUTE,
    label: DEVICES_CONSTANTS.RULES.LABEL,
    component: RulesComponent,
    featureName: FEATURES.DEVICE_MANAGEMENT,
    featureCheckField: DEVICE_MANAGEMENT_FEATURES.FIREWALL,
    children: DEVICE_RULE_CHILDREN,
  },
];
