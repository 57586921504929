import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CA_MODE_VALUES, PKI_MANAGEMENT_FEATURES } from '@lcms-constants';
import { ProjectService } from '@microsec/services';
import { CaManagementService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { MODULE_CONSTANTS, PROJECT_ID_PARAM_ROUTE, USER_ROLE, SAVE_CHANGES_LABEL, SCOPE, UPDATE_SUCCESS, VALIDATOR_TYPE } from '@microsec/constants';
import { asyncScheduler, catchError, finalize, forkJoin, scheduled } from 'rxjs';

const FORM_PARAMS = {
  ENABLED: 'enabled',
  CA_SERVER_ID: 'ca_server_id',
  CA_ID: 'ca_id',
  CA_TEMPLATE_ID: 'ca_template_id',
  // --------------------------------
  ALLOWED_GPS: 'csr_enrolment_allowed_gps',
  ALLOWED_IMEIS: 'csr_enrolment_allowed_imeis',
  ALLOWED_SIM_IDS: 'csr_enrolment_allowed_sim_ids',
  ALLOWED_IPS: 'csr_enrolment_allowed_ips',
};

@Component({
  selector: 'app-user-enrollment',
  templateUrl: './user-enrollment.component.html',
  styleUrls: ['./user-enrollment.component.scss'],
})
export class UserEnrollmentComponent extends BaseComponent implements OnInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  project: any = null;

  isClicked = {
    [FORM_PARAMS.CA_SERVER_ID]: false,
    [FORM_PARAMS.CA_ID]: false,
  };

  FORM_PARAMS = FORM_PARAMS;

  @ViewChild('enrollmentLinkTemplate') enrollmentLinkTemplate: TemplateRef<any>;

  constructor(
    private projectSrv: ProjectService,
    private caManagementSrv: CaManagementService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    const hasPermission = (this.permissions as any)[SCOPE.PROJECT][USER_ROLE.ADMIN];
    if (!!this.isX509Featured && !!hasPermission) {
      this.initForm();
      this.getData();
    } else {
      this.navigateToNotFoundPage();
    }
  }

  /**
   * Init setting form
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        label: 'Configure user self-service enrollment settings:',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENABLED,
        hasNoLabel: true,
        checkboxLabel: 'Enable self-service dashboard?',
        field: 'checkbox',
        defaultValue: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_SERVER_ID,
        label: 'CA Server',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA server',
        fieldInfo: 'CA server',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_ID,
        label: 'CA Certificate',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA Certificate',
        fieldInfo: 'CA Certificate',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_TEMPLATE_ID,
        label: 'CA Template',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA template',
        fieldInfo: 'CA template',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        hasNoLabel: true,
        field: 'custom',
        customField: this.enrollmentLinkTemplate,
        hidden: true,
      } as FormItem),
    ];
    // Enabled change event
    this.form.setChangeEvent(FORM_PARAMS.ENABLED, (enabled) => {
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CA_SERVER_ID, !!enabled ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CA_ID, !!enabled ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CA_TEMPLATE_ID, !!enabled ? [VALIDATOR_TYPE.REQUIRED] : []);
      const linkField = this.fields.find((p) => p.field === 'custom');
      if (!!linkField) {
        linkField.hidden = !enabled;
      }
    });
    // CA Server change event
    this.form.setChangeEvent(FORM_PARAMS.CA_SERVER_ID, (caConnectionId) => {
      if (!!caConnectionId) {
        this.getCAIntermediateCertificates(caConnectionId);
        // Reset values of CA, CA templates if CA server is clicked
        if (!!this.isClicked[FORM_PARAMS.CA_SERVER_ID]) {
          this.form.setControlValue(FORM_PARAMS.CA_ID, null);
          this.form.setControlValue(FORM_PARAMS.CA_TEMPLATE_ID, null);
        }
      }
    });
    // CA change event
    this.form.setChangeEvent(FORM_PARAMS.CA_ID, (caId) => {
      // Reset value of CA templates if CA is clicked
      if (!!this.isClicked[FORM_PARAMS.CA_ID]) {
        this.form.setControlValue(FORM_PARAMS.CA_TEMPLATE_ID, null);
      }
      const caTemplateField = this.fields.find((p) => p.name === FORM_PARAMS.CA_TEMPLATE_ID);
      const caField = this.fields.find((p) => p.name === FORM_PARAMS.CA_ID);
      if (!!caTemplateField && !!caField) {
        caTemplateField.options = (caField.options?.find((p) => p.id === caId)?.ca_templates as any[]) || [];
        if (!!caTemplateField.options.length) {
          this.form.enableControl(FORM_PARAMS.CA_TEMPLATE_ID);
        } else {
          this.form.setControlValue(FORM_PARAMS.CA_TEMPLATE_ID, null);
          this.form.disableControl(FORM_PARAMS.CA_TEMPLATE_ID);
        }
      }
    });
  }

  /**
   * Get all form data
   */
  getData() {
    this.form.isLoading = true;
    Object.keys(this.isClicked).forEach((key) => {
      this.isClicked[key] = false;
    });
    forkJoin([
      this.caManagementSrv.getCAManagers(this.breadcrumbConfig?.projectId).pipe(catchError(() => scheduled([[]], asyncScheduler))),
      this.projectSrv.getProject(this.breadcrumbConfig?.projectId as number),
    ])
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (results: any[]) => {
          const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
          if (!!caServerField) {
            caServerField.options = (results[0]?.data as any[])?.map((p) => ({ ...p, value: p.id, label: p.name }));
          }
          this.project = results[1];
          this.form.patchValue(this.project?.user_entity_enrollment_config);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get the list of CA intermediate certificates
   * @param caConnectionId
   */
  getCAIntermediateCertificates(caConnectionId: any) {
    const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
    const caField = this.fields.find((p) => p.name === FORM_PARAMS.CA_ID);
    if (!!caServerField && !!caField) {
      const cas: any[] = caServerField.options?.find((caServer) => caServer.value === caConnectionId)?.cas || [];
      const certs = cas
        .filter((p: any) => p.mode === CA_MODE_VALUES.X509)
        .filter((p: any) => (p.type as string).indexOf('intermediate') > -1)
        .map((p: any) => ({
          ...p,
          value: p.id,
          label: `${p.id}: ${p.subject?.CN} (${p.description})`,
          ca_templates: ((p.ca_templates as any[]) || []).map((t) => ({ ...t, value: t.id, label: t.name })),
        }));
      caField.options = this.util.sortObjectArray(certs, 'id');
      // Disable/enable CA certificate list
      if (!!caField.options.length) {
        this.form.enableControl(FORM_PARAMS.CA_ID);
      } else {
        this.form.disableControl(FORM_PARAMS.CA_ID);
        this.form.setControlValue(FORM_PARAMS.CA_TEMPLATE_ID, null);
        this.form.disableControl(FORM_PARAMS.CA_TEMPLATE_ID);
      }
    }
  }

  /**
   * Submit data
   */
  onSubmit() {
    const userEnrollment = this.util.cloneDeepObject(this.form.getRawValue());
    if (!userEnrollment[FORM_PARAMS.ENABLED]) {
      userEnrollment[FORM_PARAMS.CA_SERVER_ID] = null;
      userEnrollment[FORM_PARAMS.CA_ID] = null;
      userEnrollment[FORM_PARAMS.CA_TEMPLATE_ID] = null;
    }
    const payload = this.util.cloneDeepObject(this.project);
    if (payload[FORM_PARAMS.ALLOWED_GPS] === null) {
      payload[FORM_PARAMS.ALLOWED_GPS] = [];
    }
    if (payload[FORM_PARAMS.ALLOWED_IMEIS] === null) {
      payload[FORM_PARAMS.ALLOWED_IMEIS] = [];
    }
    if (payload[FORM_PARAMS.ALLOWED_IPS] === null) {
      payload[FORM_PARAMS.ALLOWED_IPS] = [];
    }
    if (payload[FORM_PARAMS.ALLOWED_SIM_IDS] === null) {
      payload[FORM_PARAMS.ALLOWED_SIM_IDS] = [];
    }
    payload.user_entity_enrollment_config = userEnrollment;
    this.projectSrv
      .updateProject(this.project?.id, payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(UPDATE_SUCCESS.replace('{0}', `user certificate enrollment for project ${this.project.name}`));
          this.initForm();
          this.getData();
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get enrollment link
   */
  get enrollmentLink(): string {
    return `${window.location.protocol}//${this.currentDomain}/${MODULE_CONSTANTS.USER_ENROLLMENT.ROUTE.replace(
      PROJECT_ID_PARAM_ROUTE,
      this.breadcrumbConfig?.projectId,
    )}`;
  }

  /**
   * Check if X509 is featured
   */
  get isX509Featured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.X509);
  }
}
