import { Component, OnDestroy, OnInit } from '@angular/core';
import { deviceSummarySelectors, fromDeviceSummaryActions } from '@ngrx-device-summary';
import { BaseComponent } from '@lcms-components';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  summaryLastRefresh$: any = this.store.select(deviceSummarySelectors.lastRefresh);

  selectedIndex = 0;

  async ngOnInit() {
    await this.prepareConfigs();
    this.store.dispatch(new fromDeviceSummaryActions.GetDeviceSummary(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId));
    this.subscriptions.push(
      this.store.select(deviceSummarySelectors.isLoading).subscribe((isLoading) => {
        this.isLoading = isLoading;
      }),
    );
  }

  override ngOnDestroy(): void {
    this.cleanup();
    this.store.dispatch(new fromDeviceSummaryActions.CancelGetSummaryRequest());
  }

  /**
   * Refresh device summary
   */
  refresh() {
    this.store.dispatch(new fromDeviceSummaryActions.GetDeviceSummary(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId));
  }
}
