import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PROJECT_ID_PARAM_ROUTE } from '@microsec/constants';
import { ProjectService } from '@microsec/services';
import { BaseComponent } from '@lcms-components';
import { finalize } from 'rxjs';
import { fromLayoutActions } from '@microsec/ngrx-layout';

@Component({
  selector: 'app-user-enrollment',
  templateUrl: './user-enrollment.component.html',
  styleUrls: ['./user-enrollment.component.scss'],
})
export class UserEnrollmentComponent extends BaseComponent implements OnInit {
  isLoading = false;

  page = 'home';

  project: any = null;

  isAllowed: boolean | null = true;

  constructor(
    private route: ActivatedRoute,
    private projectSrv: ProjectService,
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new fromLayoutActions.SetLeftNavbar(false));
    let projectId = this.route.snapshot.paramMap.get(PROJECT_ID_PARAM_ROUTE.replace(':', '')) as any;
    projectId = parseInt(projectId);
    if (isNaN(projectId)) {
      this.isAllowed = null;
    } else {
      this.isLoading = true;
      this.projectSrv
        .getProject(projectId, true)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (rs) => {
            this.project = rs;
            if (!this.project?.user_entity_enrollment_config?.enabled) {
              this.isAllowed = false;
            }
          },
          error: (err) => {
            this.isAllowed = null;
            window.microsec.log(err);
          },
        });
    }
  }
}
