<microsec-form-builder #fb [fields]="fields" [shouldShowActionButtons]="true" [submitButtonLabel]="'Back-import'" (submitEvent)="onSubmit($event)" />
<ng-template #entitiesSelectorTemplate>
  <div class="grid">
    <!-- Filters + Searchbox -->
    <div class="col-12">
      <div class="flex justify-content-between flex align-items-center my-2">
        <!-- Filters -->
        <div class="grid" style="width: 50%">
          <div class="col my-1" pTooltip="Available in RA?" tooltipPosition="top" appendTo="body">
            <p-dropdown
              [options]="availableInRAOptions"
              [(ngModel)]="availableInRA"
              placeholder="--- All entities ---"
              optionLabel="label"
              optionValue="value"
              appendTo="body"
              (onChange)="changeFilters()" />
          </div>
          <div class="col my-1 entities-filter-list" pTooltip="CA Filters" tooltipPosition="top" appendTo="body">
            <p-multiSelect
              [options]="caOptions"
              [(ngModel)]="caFilters"
              [ngModelOptions]="{ standalone: true }"
              [maxSelectedLabels]="1"
              [selectedItemsLabel]="'{0} CAs selected'"
              optionLabel="label"
              optionValue="value"
              placeholder="--- All CAs ---"
              appendTo="body"
              (onChange)="changeFilters()" />
          </div>
        </div>
        <!-- Searchbox -->
        <div class="flex align-items-center justify-content-end mb-1">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" placeholder="Press ENTER to search ..." [(ngModel)]="searchText" (keyup)="inputSearch($event)" />
          </span>
          <microsec-refresh-button (refreshEvent)="getEntityCertificates()" />
        </div>
      </div>
    </div>
    <!-- Data Table -->
    <div class="col-12">
      <microsec-card [isTableCard]="true">
        <microsec-common-table
          [rows]="rows"
          [lazy]="true"
          (lazyLoadEvent)="getEntityCertificates($event)"
          [totalRecords]="totalEntityNumber"
          [cols]="cols"
          [values]="values"
          itemName="Entity"
          [(selection)]="selectedEntities"
          [loading]="isLoading"
          [cellTemplate]="cellTemplate"
          [selectionMode]="'multiple'"
          [scrollHeight]="'25vh'"
          [noSortingCols]="noSortColumns" />
      </microsec-card>

      <ng-template let-col="col" let-rowData="rowData" #cellTemplate>
        @switch (col.field) {
          @case ('available_in_ra') {
            <p-checkbox [binary]="true" [(ngModel)]="rowData[col.field]" [disabled]="true" />
          }
          @default {
            <microsec-truncate-text>
              {{ rowData[col.field] }}
            </microsec-truncate-text>
          }
        }
      </ng-template>
    </div>
  </div>
</ng-template>
