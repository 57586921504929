<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!deviceForm) {
    <form [formGroup]="deviceForm" (ngSubmit)="onSubmit()">
      <!-- Form -->
      <div class="grid p-border-top">
        <div class="col-12">You can create a virtual X.509 device with preinstalled LCMS Agent to test the capabilities of LCMS.</div>
        <div class="col-1"></div>
        <div class="col-10 p-fluid">
          <div class="field grid">
            <label class="col-12 lg:col-4">Protocol:</label>
            <div class="col-12 lg:col-4">
              <p-dropdown
                [options]="protocols"
                [formControlName]="FORM_PARAMS.PROTOCOL"
                placeholder="Select a protocol"
                optionLabel="label"
                optionValue="value"
                optionDisabled="disabled"
                appendTo="body" />
            </div>
          </div>

          <div class="field grid">
            <label class="col-12 lg:col-4">Device Name:</label>
            <div class="col-12 lg:col-8">
              <input
                inputTrimRef
                [maxlength]="SHORT_TEXT_MAX_LENGTH"
                type="text"
                pInputText
                [formControlName]="FORM_PARAMS.DEVICE_NAME"
                [ngClass]="{
                  'ng-invalid': !!deviceForm.get(FORM_PARAMS.DEVICE_NAME)?.errors
                }" />
            </div>
            @if (!!deviceForm.get(FORM_PARAMS.DEVICE_NAME)?.errors?.['trimRequired'] && !!deviceForm.get(FORM_PARAMS.DEVICE_NAME)?.dirty) {
              <label class="col-12 lg:col-8 lg:col-offset-4 text-error mt-2"> Device Name is required </label>
            }
            @if (!!deviceForm.get(FORM_PARAMS.DEVICE_NAME)?.errors?.['forbidden'] && !!deviceForm.get(FORM_PARAMS.DEVICE_NAME)?.dirty) {
              <label class="col-12 lg:col-8 lg:col-offset-4 text-error mt-2"> Device Name cannot be empty or contain /, # or + </label>
            }
          </div>

          @if (deviceForm.value[FORM_PARAMS.PROTOCOL] === DEVICE_PROTOCOL_VALUES.EST_AGENT) {
            <div class="field grid">
              <div class="col-12">
                <p-checkbox [binary]="true" label="Use VPN" [formControlName]="FORM_PARAMS.VPN_ENABLE" />
              </div>
            </div>
          }

          @if (
            deviceForm.value[FORM_PARAMS.PROTOCOL] === DEVICE_PROTOCOL_VALUES.EST_AGENT ||
            deviceForm.value[FORM_PARAMS.PROTOCOL] === DEVICE_PROTOCOL_VALUES.SCEP
          ) {
            <div class="field grid">
              <div class="col-12">
                <p-checkbox [binary]="true" label="Use OTP" [formControlName]="FORM_PARAMS.USES_OTP" />
              </div>
              @if (!!deviceForm.value[FORM_PARAMS.USES_OTP]) {
                <label class="col-12 lg:col-4">OTP:</label>
                <div class="col-12 lg:col-8">
                  <input
                    inputTrimRef
                    [maxlength]="SHORT_TEXT_MAX_LENGTH"
                    type="text"
                    pInputText
                    [formControlName]="FORM_PARAMS.OTP"
                    placeholder="000000"
                    [ngClass]="{
                      'ng-invalid': !!deviceForm.get(FORM_PARAMS.OTP)?.errors
                    }" />
                </div>
              }
              @if (!!deviceForm.get(FORM_PARAMS.OTP)?.errors?.['required'] && !!deviceForm.get(FORM_PARAMS.OTP)?.dirty) {
                <label class="col-12 lg:col-8 lg:col-offset-4 text-error mt-2"> OTP is required </label>
              }
              @if (!!deviceForm.get(FORM_PARAMS.OTP)?.errors?.['pattern'] && !!deviceForm.get(FORM_PARAMS.OTP)?.dirty) {
                <label class="col-12 lg:col-8 lg:col-offset-4 text-error mt-2"> OTP is a number with 6 digits </label>
              }
            </div>
          }
        </div>
        <div class="col-1"></div>
      </div>

      <!-- Buttons -->
      <div class="flex justify-content-end action-buttons">
        <p-button severity="secondary" styleClass="mr-2" (onClick)="dialogRef.close(false)" label="Cancel" />
        <p-button
          severity="success"
          type="submit"
          [disabled]="!deviceForm.valid"
          label="Submit"
          [disabled]="!permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN]" />
      </div>
    </form>
  }
</microsec-loading-overlay>
