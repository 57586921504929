import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { DEVICE_CATEGORIES, DEVICE_STATUSES, DEVICE_STATUS_TAGS } from '@lcms-constants';
import { LabelService } from '@lcms-services';
import { LayoutTableComponent } from '@microsec/components';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { devicesSelectors, fromDevicesActions } from '@ngrx-devices';
import { LazyLoadEvent } from 'primeng/api';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { BehaviorSubject, finalize, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-ruleset-assigned-devices',
  templateUrl: './ruleset-assigned-devices.component.html',
  styleUrls: ['./ruleset-assigned-devices.component.scss'],
})
export class RulesetAssignedDevicesComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = false;

  _selectedRuleset: any = null;

  @Input() set selectedRuleset(value: any) {
    this._selectedRuleset = value;
    this.store.dispatch(new fromDevicesActions.Init());
    this.getDevices();
  }

  get selectedRuleset() {
    return this._selectedRuleset;
  }

  @Input() type = '';

  cols: any[] = [
    { field: 'enabled', header: 'Enabled', width: 1 },
    { field: 'name', header: 'Device', width: 20 },
  ];

  values: any[] = [];

  @ViewChild('lt') lt!: LayoutTableComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search', 'filter'],
    searchPlaceholder: 'Search...',
    filters: {
      0: {
        key: 'status',
        label: 'Status',
        type: 'dropdown',
        options: this.util.cloneObjectArray(DEVICE_STATUS_TAGS.filter((p) => p.value !== DEVICE_STATUSES.VIRTUAL)),
      },
      1: {
        key: 'categories',
        label: 'Categories',
        type: 'multiselect',
        options: this.util.cloneObjectArray(DEVICE_CATEGORIES),
      },
      2: {
        key: 'labels',
        label: 'Labels',
        type: 'multiselect',
        options: [],
      },
    },
  };

  filterSearch = '';

  filters = {
    status: null,
    categories: [],
    labels: [],
  };

  rows = 20;

  totalDeviceNumber = 0;

  @ViewChild('labelItemTemplate') labelItemTemplate!: TemplateRef<any>;

  @Output() updateRulesetEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private labelSrv: LabelService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.store.dispatch(new fromDevicesActions.Init());
    this.getDevices();
    this.initData();
    this.handleFilterObjUpdate();
    this.getLabels();
    // devices
    this.subscriptions.push(
      this.store.select(devicesSelectors.devices).subscribe((devices) => {
        this.values = ((devices as any[]) || [])
          .map((p) => ({
            ...p,
            enabled: !!((this.selectedRuleset.assigned_device_ids as any[]) || []).includes(p.id),
          }))
          .filter((device) => !!device.uses_agent);
      }),
    );
  }

  override ngOnDestroy(): void {
    this.store.dispatch(new fromDevicesActions.Init());
    this.store.dispatch(new fromDevicesActions.CancelGetDevicesRequest());
  }

  /**
   * Get the list of all X509 devices
   * @param event
   */
  async getDevices(event?: LazyLoadEvent) {
    await this.prepareConfigs();
    const config = await firstValueFrom(this.store.select(devicesSelectors.refreshConfig));
    const { page, perPage } = config;
    this.store.dispatch(
      new fromDevicesActions.GetAllDevices(
        this.breadcrumbConfig?.organizationId,
        this.breadcrumbConfig?.projectId,
        this.filters,
        this.filterSearch,
        {
          field: this.lt?.table?.datatable?.sortField,
          order: this.lt?.table?.datatable?.sortOrder ? this.lt?.table?.datatable?.sortOrder === 1 : true,
        },
        !event ? page : Math.floor((event as any)?.first / (event?.rows as number)) + 1,
        !event ? perPage : event?.rows,
        null,
        null,
      ),
    );
  }

  /**
   * Handle filter
   */
  handleFilterObjUpdate() {
    if (!!this.lt?.toolbar) {
      this.lt.toolbar.configuration = this.filterConfiguration;
      this.lt.toolbar.filterObject$ = this.filterObject$ as any;
    }
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        this.filterSearch = values.search || '';
        if (values?.isSortReset && this.lt?.table?.datatable) {
          this.lt.table.datatable.sortField = null;
          this.lt.table.datatable.sortOrder = 1;
          this.lt.table.datatable.multiSortMeta = null;
          this.lt.table?.datatable.tableService.onSort(null);
        }
        if (!!values?.isFiltered) {
          if (this.lt?.table?.datatable) {
            this.lt.table.datatable.first = 0;
          }
          this.filters = values.filter;
        } else {
          this.filters = { status: null, categories: [], labels: [] };
        }
        this.getDevices();
      }
    });
  }

  /**
   * Get labels
   */
  getLabels() {
    this.isLoading = true;
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.labelSrv
      .getLabels(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const labels: any[] = this.util.sortObjectArray(rs, 'name').map((p) => ({
            ...p,
            value: p?.id,
            label: p?.name,
            color: p?.color,
          }));
          if (!!this.filterConfiguration?.filters?.[2]) {
            this.filterConfiguration.filters[2].itemTemplate = this.labelItemTemplate;
            this.filterConfiguration.filters[2].options = [...labels];
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Initialize data
   */
  initData() {
    this.subscriptions.push(
      ...[
        // devices
        this.store.select(devicesSelectors.devices).subscribe(async (devices) => {
          this.values = devices;
        }),
        // is loading
        this.store.select(devicesSelectors.isLoading).subscribe((isLoading) => {
          this.isLoading = isLoading;
        }),
        // total device number
        this.store.select(devicesSelectors.totalDeviceNumber).subscribe((totalDeviceNumber) => {
          this.totalDeviceNumber = totalDeviceNumber;
        }),
      ],
    );
  }

  /**
   * Update ruleset
   */
  updateRuleset(device: any, checkboxEvent: CheckboxChangeEvent) {
    this.updateRulesetEvent.emit({ [!!checkboxEvent.checked ? 'add' : 'remove']: device?.id });
  }
}
