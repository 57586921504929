@if (!!checkForm) {
  <form [formGroup]="checkForm" class="form no-wrapper" (ngSubmit)="onSubmit()">
    <div class="grid">
      <!-- Note -->
      <p class="col-12 text-base line-height-2 text-justify">Enter request ID to get information about user certificate enrollment status.</p>

      <!-- Check -->
      <div class="col-12">
        <div class="grid align-items-center">
          <label class="col-12 lg:col-3">Request ID<microsec-asterisk /></label>
          <div class="col-12 lg:col-9">
            <input
              type="text"
              pInputText
              [formControlName]="FORM_PARAMS.REQUEST_ID"
              [ngClass]="{
                'ng-invalid ng-dirty': !!checkForm.get(FORM_PARAMS.REQUEST_ID)?.touched && !!checkForm.get(FORM_PARAMS.REQUEST_ID)?.errors
              }" />
          </div>
          @if (!!checkForm.get(FORM_PARAMS.REQUEST_ID)?.touched && !!checkForm.get(FORM_PARAMS.REQUEST_ID)?.errors?.['trimRequired']) {
            <label class="col-12 lg:col-9 lg:col-offset-3 text-error"> Request ID is required </label>
          }
        </div>
        <div class="grid align-items-center">
          <div class="col-12 lg:col-3"></div>
          <div class="col-12 lg:col-5">
            <p-button severity="primary" type="submit" label="Get Request Status" [disabled]="!checkForm.valid" [loading]="!!isLoading" />
          </div>
        </div>
      </div>

      <!-- Status -->
      @if (!!result) {
        <div class="col-12">
          <div class="grid">
            <label class="col-12 lg:col-3">Status</label>
            <div class="col-12 lg:col-9 text-base line-height-2">Certificate is ready for download.</div>
            <label class="col-12 lg:col-3"></label>
            <div class="col-12 lg:col-9 text-base line-height-2">
              <div class="flex justify-content-between align-items-center">
                <div class="inline-flex">Click here to download certificate:</div>
                <p-button severity="primary" label="Download" styleClass="ml-2" [style]="{ width: '8rem' }" (onClick)="download()" />
              </div>
            </div>
          </div>
        </div>
      }
    </div>

    <!-- Buttons -->
    <div class="flex justify-content-between mt-4">
      <div class="text-left align-self-center">
        <div class="link" (click)="goTo('home')">&lt; Back</div>
      </div>
      <div class="text-right align-self-center"></div>
    </div>
  </form>
}
