<div class="grid">
  <div class="col-12">
    <!-- Table Toolbar: Button? Search, Filter, Clear -->
    <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)" [leftTemplate]="leftTemplate" />
    <ng-template #leftTemplate>
      <p-button
        severity="primary"
        [rounded]="true"
        label="Create User"
        icon="pi pi-plus"
        (onClick)="openUserForm()"
        [disabled]="!checkUserQuota"
        [pTooltip]="!!checkUserQuota ? '' : 'Maximum number of users reached'" />
    </ng-template>
  </div>

  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="selectedCols || []"
        [values]="values"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [loading]="isLoading"
        [selectable]="false"
        itemName="User"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getUsers()" />
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- is_enabled -->
    @case ('is_superuser') {
      <p-checkbox [(ngModel)]="rowData[col.field]" [binary]="true" [disabled]="true" />
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
