import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { devicesSelectors } from '@ngrx-devices';
import { DeviceService, PackageService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { CommonTableComponent } from '@microsec/components';
import { finalize } from 'rxjs/operators';
import { DevicePackageFormComponent } from './device-package-form/device-package-form.component';
import { DELETE_LABEL } from '@microsec/constants';
import { ActionMenuItem, CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { BehaviorSubject } from 'rxjs';

const FIELDS = {
  NAME: 'Name',
  VERSION: 'Version',
};

@Component({
  selector: 'app-device-packages',
  templateUrl: './device-packages.component.html',
  styleUrls: ['./device-packages.component.scss'],
})
export class DevicePackagesComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  _selectedDevice: any = null;

  set selectedDevice(value: any) {
    this._selectedDevice = value;
    this.validCert = this._selectedDevice?.certs?.find((cert: any) => cert.status === 'Valid');
    this.selectedPackages = [];
    this.values = [];
    this.getDevicePackages();
  }

  get selectedDevice() {
    return this._selectedDevice;
  }

  validCert: any = null;

  interval = 0;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols: any[] = [
    { field: 'name', header: FIELDS.NAME, width: 15 },
    { field: 'version', header: FIELDS.VERSION, width: 15 },
  ];

  selectedPackages: any[] = [];

  values: any[] = [];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search...',
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  DELETE_LABEL = DELETE_LABEL;

  @Output() devicePackagesEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private deviceSrv: DeviceService,
    private packageSrv: PackageService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Update',
        icon: 'icon_upgrade',
        isSvg: true,
        disabled: ({ rowData }) => rowData['out_of_date_devices'] === 0,
        command: ({ rowData }: any) => this.openPackageForm('Update', [rowData]),
      },
      {
        label: 'Downgrade',
        icon: 'icon_downgrade',
        isSvg: true,
        command: ({ rowData }: any) => this.openPackageForm('Downgrade', [rowData]),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        command: ({ rowData }: any) => this.openPackageForm('Delete', [rowData]),
      },
    ];
    this.handleFilterObjUpdate();
    this.store.select(devicesSelectors.selectedDevice).subscribe((selectedDevice) => {
      if (!!selectedDevice) {
        this.selectedDevice = selectedDevice;
      }
    });
  }

  /**
   * Handle filter
   */
  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((filterObject) => {
      if (!!filterObject) {
        if (filterObject?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== filterObject.search) {
          this.dt?.datatable?.filterGlobal(filterObject.search || '', 'contains');
        }
        this.filterSearch = filterObject.search || '';
      }
    });
  }

  /**
   * Get device packages
   */
  getDevicePackages() {
    this.isLoading = true;
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.packageSrv
      .getDevicePackages(this.selectedDevice.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.values = (rs.packages as any[])?.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          this.devicePackagesEvent.emit(!!this.values.length);
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Install/Modify a package
   * @param mode
   * @param items
   */
  openPackageForm(mode: string, items: any[] | undefined = []) {
    const dialog = this.dialogSrv.open(DevicePackageFormComponent, {
      data: {
        device: this.selectedDevice,
        mode: mode?.toLocaleLowerCase(),
        packages: items,
      },
      header: `${!mode ? 'Install' : mode} Package`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getDevicePackages();
        this.deviceSrv.deviceEvents$.next(null);
      }
    });
  }
}
