import { Component } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { BaseComponent } from '@lcms-components';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-setup2fa',
  templateUrl: './setup2fa.component.html',
  styleUrls: ['./setup2fa.component.scss'],
})
export class Setup2FAComponent extends BaseComponent {
  faCheckCircle = faCheckCircle;

  isLoading = false;

  step = 0;

  qrData: any = null;

  totpCode: string | null = null;

  codes: string[] = [];

  constructor(private dialogRef: DynamicDialogRef) {
    super();
  }

  /**
   * Get the QR Code of the page
   */
  getQRCode() {
    this.isLoading = true;
    this.authSrv
      .getSetup2FAQRcode()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res: any) => {
          this.qrData = res;
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Navigate to tabs, actions
   * @param step
   */
  changeStep(step: any) {
    if (!!step) {
      if (typeof step === 'object') {
        if (step.number === -1) {
          this.step += step.number;
        } else {
          // Step 2: Verify the code and get recovery codes
          this.isLoading = true;
          this.authSrv
            .verifySetupTotpCode(step.code)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              }),
            )
            .subscribe({
              next: (data: any) => {
                // Get the latest valid TOTP code
                this.totpCode = step.code;
                // Set recovery codes
                this.codes = (data?.backup_codes as string[]) || [];
                this.step += step.number;
              },
              error: (err: any) => {
                this.showErrorMessage(err);
              },
            });
        }
      } else {
        this.step += step;
        // Load QR code
        if (this.step === 1) {
          this.getQRCode();
        }
      }
    } else {
      this.dialogRef.close(this.totpCode);
    }
  }
}
