export enum PROJECT_MANAGEMENT_GROUPS {
  OVERVIEW = 'Overview',
  SETTINGS = 'Settings',
  ENROLLMENT = 'Enrollment',
}

export const PROJECT_MANAGEMENT_CONSTANTS = {
  DEVICES: {
    ROUTE: 'devices',
    LABEL: 'Devices',
  },
  USER_CERTIFICATES: {
    ROUTE: 'user-certificates',
    LABEL: 'User Certificates',
  },
  CERTIFICATES: {
    ROUTE: 'certificates',
    LABEL: 'Certificates',
  },
  CHAINS_OF_TRUST: {
    ROUTE: 'chains-of-trust',
    LABEL: 'Chains of Trust',
  },
  ENVELOPES: {
    ROUTE: 'envelopes',
    LABEL: 'Envelopes',
  },
  REPOSITORIES: {
    ROUTE: 'repositories',
    LABEL: 'Repositories',
  },
  WORKFLOWS: {
    ROUTE: 'workflows',
    LABEL: 'Workflows',
  },
  KEYS: {
    ROUTE: 'keys',
    LABEL: 'Keys',
  },
  PROJECT_SETTINGS: {
    ROUTE: 'project-settings',
    LABEL: 'Project Settings',
  },
  USER_ACCESS: {
    ROUTE: 'user-access',
    LABEL: 'User Access',
  },
};
