import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';
import { SharedModule } from '@lcms-shared';
import { Util } from '@microsec/utilities';
import { FormComponent } from './form/form.component';
import { LoginComponent } from './login.component';
import { LOGIN_CHILDREN } from './login.config';
import { Login2faComponent } from './login2fa/login2fa.component';

const routes: Route[] = [
  {
    path: '',
    component: LoginComponent,
    children: Util.createRouteItems(LOGIN_CHILDREN),
  },
];

@NgModule({
  declarations: [LoginComponent, FormComponent, Login2faComponent],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, SharedModule, RouterModule.forChild(routes)],
})
export class LoginModule {}
