import { faCogs, faSuitcase, faUsers } from '@fortawesome/free-solid-svg-icons';
import { ILayoutItem } from '@microsec/interfaces';
import { USER_ACCESS_CHILDREN } from '../shared-components/shared-user-access/shared-user-access.config';
import { OrganizationSettingsComponent } from './settings/organization-settings/organization-settings.component';
import { ProjectsComponent } from './overview/projects/projects.component';
import { ORGANIZATION_MANAGEMENT_CONSTANTS, ORGANIZATION_MANAGEMENT_GROUPS } from '@lcms-constants';
import { UserAccessComponent } from './settings/user-access/user-access.component';

export const ORGANIZATION_MANAGEMENT_CHILDREN: ILayoutItem[] = [
  {
    group: ORGANIZATION_MANAGEMENT_GROUPS.OVERVIEW,
    route: ORGANIZATION_MANAGEMENT_CONSTANTS.PROJECTS.ROUTE,
    icon: faSuitcase,
    label: ORGANIZATION_MANAGEMENT_CONSTANTS.PROJECTS.LABEL,
    component: ProjectsComponent,
  },
  {
    group: ORGANIZATION_MANAGEMENT_GROUPS.SETTINGS,
    route: ORGANIZATION_MANAGEMENT_CONSTANTS.ORGANIZATION_SETTINGS.ROUTE,
    icon: faCogs,
    label: ORGANIZATION_MANAGEMENT_CONSTANTS.ORGANIZATION_SETTINGS.LABEL,
    component: OrganizationSettingsComponent,
    visible: false,
  },
  {
    group: ORGANIZATION_MANAGEMENT_GROUPS.SETTINGS,
    route: ORGANIZATION_MANAGEMENT_CONSTANTS.USER_ACCESS.ROUTE,
    icon: faUsers,
    label: ORGANIZATION_MANAGEMENT_CONSTANTS.USER_ACCESS.LABEL,
    component: UserAccessComponent,
    children: USER_ACCESS_CHILDREN,
    visible: false,
  },
];
