import { BASE_API } from '@microsec/constants';
import { API as SHARED_API, API_VERSION } from '@microsec/services';

export const API = {
  ...SHARED_API,
  END_ENTITY_MANAGEMENT: `${BASE_API}/end-entity-mgmt/${API_VERSION}`,
  ENVELOPE_MANAGEMENT: `${BASE_API}/envelope-manager/${API_VERSION}`,
  CA_MANAGEMENT: `${BASE_API}/ca-manager/${API_VERSION}`,
  FIREWALL_MANAGER: `${BASE_API}/firewall-manager/${API_VERSION}`,
  FOTA_MANAGER: `${BASE_API}/fota-manager/${API_VERSION}`,
  PACKAGE_MANAGER: `${BASE_API}/package-manager/${API_VERSION}`,
  REPO_SERVICES: `${BASE_API}/repo-services/${API_VERSION}`,
  RA_ENROLLMENT: `${BASE_API}/ra-enrolment-api/${API_VERSION}`,
  KMS: `${BASE_API}/kms/${API_VERSION}`,
  LOG: `${BASE_API}/logging-api/${API_VERSION}`,
};
