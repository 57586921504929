import { Component, Input } from '@angular/core';
import { CaManagementService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { PIPE_DATETIME } from '@microsec/constants';
import { LazyLoadEvent } from 'primeng/api';
import { finalize } from 'rxjs';
import { BackImportedFormComponent } from './back-imported-form/back-imported-form.component';

@Component({
  selector: 'app-ca-connection-imported-end-entities',
  templateUrl: './ca-connection-imported-end-entities.component.html',
  styleUrls: ['./ca-connection-imported-end-entities.component.scss'],
})
export class CaConnectionImportedEndEntitiesComponent extends BaseComponent {
  isLoading = false;

  _selectedCAManager: any = null;

  get selectedCAManager() {
    return this._selectedCAManager;
  }

  @Input() set selectedCAManager(value: any) {
    this._selectedCAManager = value;
    this.getBackImportedCertificates();
  }

  values: any[] = [];

  cols: any[] = [
    { field: 'cn', header: 'Common Name', width: 5, frozen: true },
    { field: 'ra_entity_type', header: 'Type', width: 5 },
    { field: 'available_certificates', header: 'Available Certificates', width: 10 },
    { field: 'ca', header: 'CA', width: 5 },
  ];

  searchText = '';

  rows = 20;

  totalEntityNumber = 0;

  PIPE_DATETIME = PIPE_DATETIME;

  constructor(private caManagementSrv: CaManagementService) {
    super();
  }

  /**
   * Get the list of back-imported certificates
   * @param event
   */
  async getBackImportedCertificates(event?: LazyLoadEvent) {
    await this.prepareConfigs();
    this.subscriptions.forEach((s) => s.unsubscribe());
    if ((this.selectedCAManager?.connection_status as string)?.toLowerCase() === 'connected') {
      this.isLoading = true;
      this.subscriptions.push(
        this.caManagementSrv
          .getEntityCertificates(this.selectedCAManager?.id, {
            availableInRA: true,
            commonName: this.searchText,
            backImported: true,
            page: !event ? 1 : Math.floor((event as any)?.first / (event?.rows as number)) + 1,
            perPage: !event ? this.rows : event?.rows,
          })
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
          .subscribe({
            next: (result: any) => {
              this.values = ((result?.data as any[]) || []).map((entity) => ({
                ...entity,
                available_certificates: entity.certs?.length,
                ca: (entity.certs as any[])?.[0].issued_by || '',
              }));
              this.totalEntityNumber = result.total_records;
            },
            error: (err) => {
              this.showErrorMessage(err);
            },
          }),
      );
    } else {
      this.values = [];
    }
  }

  /**
   * Open back-import form
   */
  openBackImportForm() {
    const dialog = this.dialogSrv.open(BackImportedFormComponent, {
      data: {
        selectedCAManager: this.selectedCAManager,
      },
      header: 'Back-import Certificates',
      width: '900px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getBackImportedCertificates();
      }
    });
  }

  /**
   * Input search
   */
  inputSearch(event: any) {
    if (event?.keyCode === 13) {
      this.getBackImportedCertificates();
    }
  }

  /**
   * Get the list of no sort columns
   */
  get noSortColumns() {
    return this.cols.map((p) => p.field);
  }
}
