import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { MessageService } from 'primeng/api';
import { Observable, Subscriber, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API } from '../api';
import { IFirmwareService } from './IFirmwareService';

const API_FIRMWARE = `${API.FOTA_MANAGER}/firmware`;

@Injectable({
  providedIn: 'root',
})
export class FirmwareService implements IFirmwareService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private toastSrv: MessageService,
  ) {}

  getFirmwares(queryField?: any, queryValue?: any) {
    return this.http
      .get<any>(`${API_FIRMWARE}${!!queryField ? `?${queryField}=${queryValue}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getFirmware(id: any) {
    return this.http.get<any>(`${API_FIRMWARE}/${id}?show_device=true`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  uploadFirmware(payload: any) {
    const observer = new Observable((observe) => {
      const requests: Observable<any>[] = [];
      const chunkFiles: any[] = [];
      const chunkSize = 10000000;

      const file = payload.file;
      for (let offset = 0; offset < file.size; offset += chunkSize) {
        const chunkFile = file.slice(offset, offset + chunkSize);
        chunkFile.lastModified = file.lastModified;
        chunkFile.lastModifiedDate = file.lastModifiedDate;
        chunkFile.name = `${file.name}.${Guid.create()}`;
        chunkFiles.push(chunkFile);
      }
      const dzuuid = Guid.create();
      chunkFiles.forEach((chunkFile: any, index: number) => {
        const chunkPayload = {
          dzuuid,
          dzchunkindex: index,
          dztotalchunkcount: chunkFiles.length,
          file: chunkFile,
          target_device: payload.target_device,
          name: payload.name,
          version: payload.version,
          project_id: payload.project_id,
          org_id: payload.org_id,
          signed: +payload.signed,
          sign_mode: payload.sign_mode,
          signing_key_id: payload.signing_key_id,
          signing_envelope_template_id: payload.signing_envelope_template_id,
          ca_server_id: payload.ca_server_id,
          signing_envelope_ca_id: payload.signing_envelope_ca_id,
        };
        requests.push(this.upload(chunkPayload));
      });
      this.store.dispatch(new fromLayoutActions.ToggleProgressBar(true));
      this.store.dispatch(new fromLayoutActions.UpdateProgressBarTitle('Upload Firmware'));
      this.store.dispatch(new fromLayoutActions.UpdateProgressBarInfo(`Uploading chunks: 0/${requests.length}`));
      this.store.dispatch(new fromLayoutActions.UpdateProgressBarValue(0));
      this.uploadChunks(requests, 0, observe);
    });
    return observer;
  }

  private upload(payload: any) {
    const formData = new FormData();
    formData.append('dzuuid', payload.dzuuid);
    formData.append('dzchunkindex', payload.dzchunkindex);
    formData.append('dztotalchunkcount', payload.dztotalchunkcount);
    formData.append('file', payload.file, payload.file?.name);
    formData.append('target_device', payload.target_device);
    formData.append('name', payload.name);
    formData.append('version', payload.version);
    formData.append('project_id', payload.project_id);
    formData.append('org_id', payload.org_id);
    formData.append('signed', payload.signed);
    formData.append('sign_mode', payload.sign_mode);
    formData.append('signing_key_id', payload.signing_key_id);
    formData.append('signing_envelope_template_id', payload.signing_envelope_template_id);
    formData.append('ca_server_id', payload.ca_server_id);
    formData.append('signing_envelope_ca_id', payload.signing_envelope_ca_id);
    return this.http.post<any>(`${API_FIRMWARE}/upload`, formData).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  private uploadChunks(chunkRequests: Observable<any>[], index: number, observe: Subscriber<any>) {
    chunkRequests[index].subscribe({
      next: () => {
        this.store.dispatch(new fromLayoutActions.UpdateProgressBarInfo(`Uploading chunks: ${index + 1}/${chunkRequests.length}`));
        this.store.dispatch(new fromLayoutActions.UpdateProgressBarValue(Math.floor(((index + 1) * 100) / chunkRequests.length)));
        if (index < chunkRequests.length - 1) {
          this.uploadChunks(chunkRequests, index + 1, observe);
        } else {
          this.store.dispatch(new fromLayoutActions.ToggleProgressBar(false));
          observe.next();
        }
      },
      error: (err: any) => {
        this.store.dispatch(new fromLayoutActions.ToggleProgressBar(false));
        this.toastSrv.add({ severity: 'error', summary: 'Error', life: 5000, detail: 'Something went wrong while uploading firmware' });
        observe.error(err);
      },
    });
  }

  updateFirmware(id: any, payload: any) {
    return this.http.put<any>(`${API_FIRMWARE}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteFirmware(id: any, organizationId: any) {
    return this.http
      .delete<any>(`${API_FIRMWARE}/${id}?org_id=${organizationId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  downloadFirmware(id: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/octet-stream');
    return this.http
      .get(`${API_FIRMWARE}/${id}/download`, {
        headers,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  pushHikVisionCCTVDevice(id: any, payload: any) {
    return this.http.post(`${API_FIRMWARE}/${id}/fota`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getSupportedDevices(): Observable<any> {
    return this.http.get<any>(`${API_FIRMWARE}/supported_devices`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
