<!-- Filter -->
<div class="flex justify-content-between align-items-start mb-2">
  <div class="align-items-center" style="width: 100%">
    <span pTooltip="Log Type" tooltipPosition="top" appendTo="body">
      <p-dropdown
        [options]="logTypes"
        [(ngModel)]="logType"
        placeholder="Select a log type"
        optionLabel="label"
        optionValue="value"
        appendTo="body"
        (onChange)="changeLogType($event)" />
    </span>
  </div>
  <div class="flex align justify-content-end">
    <div class="flex">
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          [autofocus]="true"
          pInputText
          [(ngModel)]="searchText"
          type="text"
          placeholder="Press ENTER to search ..."
          (keyup)="searchLog($event)" />
      </div>
      <div class="flex">
        <microsec-refresh-button (refreshEvent)="getSystemLogs()"></microsec-refresh-button>
        <p-button
          severity="primary"
          icon="fa-solid fa-arrows-up-to-line"
          styleClass="ml-2"
          pTooltip="Scroll to Top"
          tooltipPosition="bottom"
          appendTo="body"
          (onClick)="scrollTo('top')"></p-button>
        <p-button
          severity="primary"
          icon="fa-solid fa-arrows-down-to-line"
          styleClass="ml-2"
          pTooltip="Scroll to Last"
          tooltipPosition="bottom"
          appendTo="body"
          (onClick)="scrollTo('bottom')"></p-button>
        <p-button
          severity="primary"
          icon="fa-solid fa-download"
          styleClass="ml-2"
          pTooltip="Download"
          tooltipPosition="bottom"
          appendTo="body"
          (onClick)="downloadAllLogs()"></p-button>
      </div>
    </div>
  </div>
</div>

<!-- Logs -->

<div class="grid">
  <div class="col-12 pt-4">
    <microsec-log-viewer
      #logViewer
      [textLog]="textLog"
      [page]="page"
      [totalPage]="totalPage"
      [searchText]="searchLogText"
      (lastScrollEvent)="getSystemLogs($event)"></microsec-log-viewer>
  </div>
</div>
