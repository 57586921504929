export const RULE_TYPE_VALUES = {
  IP: 'IP',
  CAN: 'CAN',
  COMMAND: 'Command',
  YARA: 'YARA',
};

export const RULE_TYPES = [
  { value: RULE_TYPE_VALUES.IP, label: 'IP' },
  { value: RULE_TYPE_VALUES.CAN, label: 'CAN' },
  { value: RULE_TYPE_VALUES.COMMAND, label: 'Command' },
  { value: RULE_TYPE_VALUES.YARA, label: 'YARA' },
];

// IP Protocol
export const FIREWALL_IP_PROTOCOL_VALUES = {
  TCP: 'TCP',
  UDP: 'UDP',
};

export const FIREWALL_IP_PROTOCOLS = [
  { value: FIREWALL_IP_PROTOCOL_VALUES.TCP, label: FIREWALL_IP_PROTOCOL_VALUES.TCP },
  { value: FIREWALL_IP_PROTOCOL_VALUES.UDP, label: FIREWALL_IP_PROTOCOL_VALUES.UDP },
];

// IP Version
export const FIREWALL_IP_VERSION_VALUES = {
  IPV4: 'IPv4',
  IPV6: 'IPv6',
};

export const FIREWALL_IP_VERSIONS = [
  { value: FIREWALL_IP_VERSION_VALUES.IPV4, label: 'IPv4' },
  { value: FIREWALL_IP_VERSION_VALUES.IPV6, label: 'IPv6' },
];

// CAN Version
export const FIREWALL_CAN_VERSION_VALUES = {
  CLASSIC: 'classic',
  FD: 'fd',
};

export const FIREWALL_CAN_VERSIONS = [
  { value: FIREWALL_CAN_VERSION_VALUES.CLASSIC, label: 'Classic' },
  { value: FIREWALL_CAN_VERSION_VALUES.FD, label: 'FD' },
];

// Target
export const FIREWALL_TARGET_VALUES = {
  ACCEPT: 'ACCEPT',
  DROP: 'DROP',
  QUEUE: 'QUEUE',
  REJECT: 'REJECT',
};
export const FIREWALL_TARGETS = [
  { value: FIREWALL_TARGET_VALUES.ACCEPT, label: FIREWALL_TARGET_VALUES.ACCEPT },
  { value: FIREWALL_TARGET_VALUES.DROP, label: FIREWALL_TARGET_VALUES.DROP },
  { value: FIREWALL_TARGET_VALUES.QUEUE, label: FIREWALL_TARGET_VALUES.QUEUE },
  { value: FIREWALL_TARGET_VALUES.REJECT, label: FIREWALL_TARGET_VALUES.REJECT },
];

// Chains
export const FIREWALL_CHAIN_VALUES = {
  INPUT: 'INPUT',
  OUTPUT: 'OUTPUT',
  FORWARD: 'FORWARD',
};
export const FIREWALL_CHAINS = [
  { value: FIREWALL_CHAIN_VALUES.INPUT, label: FIREWALL_CHAIN_VALUES.INPUT },
  { value: FIREWALL_CHAIN_VALUES.OUTPUT, label: FIREWALL_CHAIN_VALUES.OUTPUT },
  { value: FIREWALL_CHAIN_VALUES.FORWARD, label: FIREWALL_CHAIN_VALUES.FORWARD },
];

// Rules
export const RULE_STRATEGIES = [
  { value: 0, label: 'Alert only' },
  { value: 1, label: 'Alert and kill process' },
];

export const RULE_YARA_MODE_VALUES = {
  FILESYSTEM_SCAN: 'filesystem-scan',
  PROCESS_SCAN: 'process-scan',
  EVENT: 'event',
  NETWORK: 'network',
};

export const RULE_YARA_MODES = [
  { value: RULE_YARA_MODE_VALUES.FILESYSTEM_SCAN, label: 'Filesystem Scan' },
  { value: RULE_YARA_MODE_VALUES.PROCESS_SCAN, label: 'Process Scan' },
  { value: RULE_YARA_MODE_VALUES.EVENT, label: 'Event' },
  { value: RULE_YARA_MODE_VALUES.NETWORK, label: 'Network' },
];
