<microsec-layout-table
  itemName="Repository"
  [cols]="cols"
  [values]="values"
  [shouldShowCreateButton]="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"
  (openFormEvent)="openRepoForm()"
  (getItemsEvent)="getRepositories()"
  [isLoading]="isLoading"
  [cellTemplate]="cellTemplate"
  [actionMenuItems]="actionMenuItems"
  [(selectedItem)]="selectedRepo"
  [cellTemplate]="cellTemplate"
  [detailsTemplate]="detailsTemplate" />

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Repo Signing Active -->
    @case ('signing') {
      <microsec-truncate-text>
        {{ !!rowData[col.field] ? 'Yes' : 'No' }}
      </microsec-truncate-text>
    }
    @case ('packages_count') {
      <microsec-truncate-text>
        {{ rowData[col.field] || '0' }}
      </microsec-truncate-text>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>

<ng-template #detailsTemplate>
  <app-repository-info
    [isLoading]="isLoading"
    [(selectedRepo)]="selectedRepo"
    (openRepoFormEvent)="openRepoForm($event)"
    (openDeleteConfirmationEvent)="openDeleteConfirmation($event)"
    (getRepositoriesEvent)="getRepositories()" />
</ng-template>
