import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { devicesSelectors, fromDevicesActions } from '@ngrx-devices';
import { FirmwareService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { ASSIGN_LABEL } from '@microsec/constants';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  FIRMWARE: 'firmware',
};

@Component({
  selector: 'app-device-firmware-assignment-form',
  templateUrl: './device-firmware-assignment-form.component.html',
  styleUrls: ['./device-firmware-assignment-form.component.scss'],
})
export class DeviceFirmwareAssignmentFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  ASSIGN_LABEL = ASSIGN_LABEL;

  selectedDevice: any = null;

  constructor(private firmwareSrv: FirmwareService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.store.select(devicesSelectors.selectedDevice).subscribe((selectedDevice) => {
      this.selectedDevice = selectedDevice;
      this.initForm();
      this.getFirmwares();
    });
  }

  /**
   * Init the form
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        label: 'Specify a firmware to be installed on the device (if supported).',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FIRMWARE,
        label: 'Firmware',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a firmware',
        required: true,
        fieldInfo: 'Firmware',
        defaultValue: null,
        focused: true,
      } as FormItem),
    ];
  }

  /**
   * Get firmwares list
   */
  getFirmwares() {
    this.form.isLoading = true;
    this.firmwareSrv
      .getFirmwares('project_id', this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const firmwaresField = this.fields.find((p) => p.name === FORM_PARAMS.FIRMWARE);
          if (!!firmwaresField) {
            firmwaresField.options = this.util.sortObjectArray(rs as any[], 'name').map((firmware) => ({
              value: {
                id: firmware.id,
                assignedDevices: firmware.assigned_devices || [],
              },
              label: firmware.name,
            }));
            firmwaresField.defaultValue = null;
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Assign firmware
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form.getRawValue() };
    const assignedDevices = ((payload[FORM_PARAMS.FIRMWARE]?.assignedDevices as any[]) || [])?.map((p) => p.device_id);
    if (!assignedDevices.includes(this.selectedDevice.id)) {
      assignedDevices.push(this.selectedDevice.id);
    }
    this.firmwareSrv
      .updateFirmware(payload[FORM_PARAMS.FIRMWARE]?.id, {
        assigned_devices: assignedDevices,
        org_id: this.breadcrumbConfig?.organizationId,
      })
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Assigned firmware successfully');
          this.store.dispatch(new fromDevicesActions.RefreshDeviceEvents());
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
