import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { OrganizationService } from '@microsec/services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { CREATE_LABEL, CREATE_SUCCESS, SAVE_CHANGES_LABEL, UPDATE_SUCCESS } from '@microsec/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';

const FORM_PARAMS = {
  NAME: 'name',
  ADDRESS: 'address',
  TELEPHONE_NUMBER: 'telephone_number',
};

@Component({
  selector: 'app-shared-organization-form',
  templateUrl: './shared-organization-form.component.html',
  styleUrls: ['./shared-organization-form.component.scss'],
})
export class SharedOrganizationFormComponent extends BaseComponent implements AfterViewInit {
  organization: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private organizationSrv: OrganizationService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.organization = this.dialogConfig?.data?.organization;
    this.initForm();
    if (!!this.organization) {
      this.form.patchValue(this.organization);
      this.form.disableControl(FORM_PARAMS.NAME);
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: 'Name of organization',
        defaultValue: '',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ADDRESS,
        label: 'Address',
        field: 'input',
        fieldInfo: 'Address of organization',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TELEPHONE_NUMBER,
        label: 'Phone number',
        field: 'input',
        fieldInfo: 'Phone number of organization',
        defaultValue: '',
      } as FormItem),
    ];
  }

  /**
   * Submit form
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form.getRawValue() };
    const request: Observable<any> = !this.organization?.id
      ? this.organizationSrv.createOrganization(payload)
      : this.organizationSrv.updateOrganization(this.organization.id, payload);
    request.subscribe({
      next: () => {
        const message = !this.organization ? CREATE_SUCCESS : UPDATE_SUCCESS;
        this.showSuccessMessage(message.replace('{0}', 'organization'));
        closeDialog();
      },
      error: (err: any) => {
        this.form.showServerErrorMessage(err);
        this.showErrorMessage(err);
      },
    });
  }
}
