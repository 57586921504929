import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { Setting2FAService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { finalize } from 'rxjs/operators';
import { CONFIG_2FA_CHILDREN, CONFIG_2FA_CONSTANTS } from './config2fa.config';

@Component({
  selector: 'app-config2fa',
  templateUrl: './config2fa.component.html',
  styleUrls: ['./config2fa.component.scss'],
})
export class Config2faComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(private setting2FASrv: Setting2FAService) {
    super();
  }

  ngOnInit() {
    const tabItems = this.util.cloneObjectArray(CONFIG_2FA_CHILDREN).filter((p) => !!p.label);
    this.setting2FASrv
      .getOpenGlobalSetting()
      .pipe(
        finalize(() => {
          this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
          this.subscriptions.forEach((s) => s.unsubscribe());
        }),
      )
      .subscribe({
        next: (response: any) => {
          if (response.is_enabled && response.auth_provider === 'rsa') {
            tabItems.forEach((item) => {
              if (item.route === CONFIG_2FA_CONSTANTS.RSA.ROUTE) {
                item.disabled = false;
              }
            });
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  override ngOnDestroy(): void {
    this.cleanup({ shouldClearTabItems: true });
  }
}
