import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-package-result-form',
  templateUrl: './package-result-form.component.html',
  styleUrls: ['./package-result-form.component.scss'],
})
export class PackageResultFormComponent extends BaseComponent implements OnInit {
  type = 'package';

  cols: any[] = [];

  values: any[] = [];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  ngOnInit() {
    this.type = this.dialogConfig.data?.type;
    const packages = this.dialogConfig.data?.packages;
    const cols: any[] = [{ field: 'package_name', header: 'Package', width: 8 }];
    const values: any[] = [];
    switch (this.type) {
      case 'package': {
        cols.push({ field: 'device', header: 'Device', width: 8 });
        ((this.dialogConfig.data?.results as any[]) || []).forEach((result, index) => {
          values.push(
            ...((result?.devices as any[]) || []).map((p) => ({
              ...p,
              package_name: packages[index].name,
            })),
          );
        });
        this.values = values;
        break;
      }
      case 'device': {
        values.push(...((this.dialogConfig.data?.results as any[]) || []).map((p, index) => ({ ...p, package_name: packages[index].name })));
        break;
      }
      default: {
        break;
      }
    }
    cols.push(
      ...[
        { field: 'status_code', header: 'Status', width: 3 },
        { field: 'message', header: 'Message', width: 15 },
      ],
    );
    this.cols = cols;
    this.values = values;
  }
}
