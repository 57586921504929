<p-tabView [scrollable]="true" styleClass="microsec-dashboard-tab">
  <p-tabPanel header="Overview">
    <app-ruleset-overview
      [selectedRuleset]="selectedRuleset"
      (updateRulesetEvent)="updateRuleset($event)"
      (exportRulesetEvent)="exportRuleset($event)" />
  </p-tabPanel>
  <p-tabPanel header="Rules">
    <app-ruleset-rules [selectedRuleset]="selectedRuleset" [type]="type" />
  </p-tabPanel>
  <p-tabPanel header="Assigned Devices">
    <app-ruleset-assigned-devices [selectedRuleset]="selectedRuleset" [type]="type" (updateRulesetEvent)="updateRuleset($event)" />
  </p-tabPanel>
</p-tabView>
