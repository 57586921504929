<microsec-layout-table
  itemName="CA Server"
  [shouldShowCreateButton]="false"
  [toolbarActionButtons]="!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN] ? toolbarActionButtons : []"
  (getItemsEvent)="getConnectedCAs()"
  [cols]="cols"
  [values]="values"
  [isLoading]="isLoading"
  [cellTemplate]="cellTemplate"
  [actionMenuItems]="actionMenuItems"
  [(selectedItem)]="selectedCAManager"
  [detailsTemplate]="detailsTemplate" />

<ng-template #detailsTemplate>
  <app-ca-connection-info
    [isLoading]="isLoading"
    [(selectedCAManager)]="selectedCAManager"
    (openCAManagerFormEvent)="openCAManagerForm($event)"
    (confirmDeletionEvent)="confirmDeletion($event)" />
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Type -->
    @case ('type') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'ca-manager-type' }}
      </microsec-truncate-text>
    }
    <!-- Connection Status -->
    @case ('connection_status') {
      <microsec-truncate-text>
        {{ util.getUppercaseFirstLetter(rowData[col.field]) }}
      </microsec-truncate-text>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
