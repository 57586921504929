import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@lcms-components';

@Component({
  selector: 'app-shared-rules',
  templateUrl: './shared-rules.component.html',
  styleUrls: ['./shared-rules.component.scss'],
})
export class SharedRulesComponent extends BaseComponent implements OnInit, OnDestroy {
  async ngOnInit() {
    await this.prepareConfigs();
  }
}
