<div class="grid">
  <div class="col-12">
    <div class="grid justify-content-between">
      <div class="col">
        <p-button
          severity="success"
          size="small"
          [rounded]="true"
          icon="pi pi-plus"
          label="Assign Firmware"
          styleClass="mr-2"
          (onClick)="openFirmwareAssignmentForm()" />
        <p-button
          severity="primary"
          size="small"
          [rounded]="true"
          icon="fa fa-upload"
          label="Push to Device"
          (onClick)="pushToDevice()"
          [disabled]="!values || !values.length" />
      </div>
      <div class="col">
        <div class="flex align-items-center justify-content-end">
          <microsec-refresh-button (refreshEvent)="getDeviceFirmwares()" />&nbsp;
          <microsec-table-column-filter [key]="el.nativeElement.tagName" [cols]="cols" [(selectedColFields)]="selectedColFields" />
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [showContainerHeader]="false"
        [cols]="selectedCols || []"
        [values]="values"
        [cardBackground]="true"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [selectable]="false"
        [paginator]="false" />
    </microsec-card>
  </div>
</div>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    @case ('device_status') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'firmware-device-status' }}
      </microsec-truncate-text>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
  }
</ng-template>
