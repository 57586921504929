import { ActionMenuItem } from '@microsec/models';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { KEYRING_TYPE_VALUES, MICROSEC_DEFAULT_KEYRING } from '@lcms-constants';
import { KmsService } from '@lcms-services';
import { BaseComponent } from '@microsec/components';
import { CREATE_LABEL, DELETE_LABEL, DELETE_SUCCESS, PIPE_DATETIME } from '@microsec/constants';
import { finalize } from 'rxjs';
import { GenericKeyFormComponent } from './generic-key-form/generic-key-form.component';
import { GenericKeyImportFormComponent } from './generic-key-import-form/generic-key-import-form.component';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-keyring-generic-keys',
  templateUrl: './keyring-generic-keys.component.html',
  styleUrls: ['./keyring-generic-keys.component.scss'],
})
export class KeyringGenericKeysComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = false;

  @Input() keyPurpose: any = null;

  _selectedKeyring: any = null;

  @Input() set selectedKeyring(value: any) {
    this._selectedKeyring = value;
    this.selectedKey = null;
    this.getGenericKeys();
  }

  get selectedKeyring() {
    return this._selectedKeyring;
  }

  cols: any[] = [
    { field: 'label', header: 'Label', width: 3, frozen: true },
    { field: 'key_type', header: 'Type', width: 2 },
    { field: 'purpose', header: 'Purpose', width: 4 },
    { field: 'algorithm', header: 'Algorithm', width: 4 },
    { field: 'created', header: 'Created Date', width: 3 },
  ];

  _selectedKey: any = null;

  get selectedKey() {
    return this._selectedKey;
  }

  set selectedKey(value: any) {
    this._selectedKey = value;
  }

  values: any[] = [];

  actionMenuItems: ActionMenuItem[] = [];

  KEYRING_TYPE_VALUES = KEYRING_TYPE_VALUES;

  PIPE_DATETIME = PIPE_DATETIME;

  queryParams: Params | null = null;

  MICROSEC_DEFAULT_KEYRING = MICROSEC_DEFAULT_KEYRING;

  constructor(
    private route: ActivatedRoute,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
    this.actionMenuItems = [
      {
        label: 'Download',
        icon: 'fas fa-download',
        command: ({ rowData }: any) => this.downloadKey(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => this.selectedKeyring?.name !== MICROSEC_DEFAULT_KEYRING,
        command: ({ rowData }: any) => this.confirmDeletion(rowData),
      },
    ];
  }

  /**
   * Get generic keys
   */
  async getGenericKeys() {
    await this.prepareConfigs();
    this.isLoading = true;
    this.kmsSrv
      .getGenericKeys(this.selectedKeyring?.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.values = (rs?.data as any[]) || [];
          let selectedKey: any = null;
          if (!!this.queryParams?.['generic_key_id']) {
            let id = null;
            try {
              id = Number.parseInt(this.queryParams['generic_key_id']);
            } catch {
              id = null;
            }
            selectedKey = { id };
            this.router.navigate([], { queryParams: { generic_key_id: null }, queryParamsHandling: 'merge' });
          }
          if (!!selectedKey) {
            let selectedKeyIndex = -1;
            selectedKey = this.values.find((p, index) => {
              if (p.id === this.selectedKey?.id.toString() || p.id === selectedKey?.id.toString()) {
                selectedKeyIndex = index;
                return true;
              }
              return false;
            });
            if (selectedKeyIndex >= 0) {
              this.values.splice(selectedKeyIndex, 1);
              this.values.unshift(selectedKey);
              this.selectedKey = selectedKey;
            }
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Open key form
   */
  openKeyForm() {
    const dialog = this.dialogSrv.open(GenericKeyFormComponent, {
      header: `${CREATE_LABEL} Generic Key`,
      data: { keyring: this.selectedKeyring, keyPurpose: this.keyPurpose },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getGenericKeys();
      }
    });
  }

  /**
   * Open key import form
   */
  openKeyImportForm() {
    this.dialogSrv.open(GenericKeyImportFormComponent, {
      header: `Import Generic Key`,
      data: { keyring: this.selectedKeyring },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }

  /**
   * Download key export
   * @param key
   */
  downloadKey(key: any) {
    this.kmsSrv.downloadGenericKey(this.selectedKeyring?.id, key?.id).subscribe({
      next: (rs) => {
        if (!!rs) {
          this.showSuccessMessage('Download generic key successfully');
          this.util.downloadFileFromBlob(rs?.body || '', `keyring_${this.selectedKeyring?.id}_key_${key?.id}_${key?.label}_generic_key.pem`);
        } else {
          this.showErrorMessage('Cannot download');
        }
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Confirm key deletion
   * @param key
   */
  confirmDeletion(key: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Generic Key',
      object: key,
      objectFieldName: 'label',
      next: () => {
        this.isLoading = true;
        this.kmsSrv
          .deleteGenericKey(this.selectedKeyring?.id, key?.id)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
          .subscribe({
            next: () => {
              const message = DELETE_SUCCESS.replace('{0}', 'generic key');
              this.showSuccessMessage(message);
              this.selectedKey = null;
              this.getGenericKeys();
            },
            error: (err) => {
              this.showErrorMessage(err);
            },
          });
      },
    });
  }
}
