import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { OrganizationService } from '@microsec/services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { PIPELINE_TYPE } from '@microsec/constants';
import { finalize } from 'rxjs/operators';
import { SharedOrganizationFormComponent } from '../../../shared-components/shared-organization-form/shared-organization-form.component';

const FORM_PARAMS = {
  ID: 'id',
  NAME: 'name',
  ADDRESS: 'address',
  PHONE_NUMBER: 'telephone_number',
  CREATED: 'created',
};

@Component({
  selector: 'app-organization-settings',
  templateUrl: './organization-settings.component.html',
  styleUrls: ['./organization-settings.component.scss'],
})
export class OrganizationSettingsComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  organization: any = {};

  constructor(private organizationSrv: OrganizationService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.getOrganization();
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.organizationSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getOrganization();
      }
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Get the current organization
   */
  getOrganization() {
    this.form.isLoading = true;
    this.organizationSrv
      .getOrganization(this.breadcrumbConfig?.organizationId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.organization = rs;
          this.initForm();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Init the form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ID,
        label: 'Organization ID',
        field: 'label',
        defaultValue: this.organization?.[FORM_PARAMS.ID],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Organization Name',
        field: 'label',
        defaultValue: this.organization?.[FORM_PARAMS.NAME],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ADDRESS,
        label: 'Address',
        field: 'label',
        defaultValue: this.organization?.[FORM_PARAMS.ADDRESS],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PHONE_NUMBER,
        label: 'Phone Number',
        field: 'label',
        defaultValue: this.organization?.[FORM_PARAMS.PHONE_NUMBER],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CREATED,
        label: 'Created On',
        field: 'label',
        pipeline: PIPELINE_TYPE.DATETIME,
        defaultValue: this.organization?.[FORM_PARAMS.CREATED],
      } as FormItem),
    ];
    fields.forEach((field) => field.setFullSize());
    this.fields = fields;
    setTimeout(() => {
      this.form?.form?.enable();
    });
  }

  /**
   * Open the organization form
   */
  openOrganizationForm() {
    this.form.isLoading = false;
    const dialog = this.dialogSrv.open(SharedOrganizationFormComponent, {
      data: { organization: this.organization },
      header: 'Edit Organization',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      this.organizationSrv.refresh$.next(rs);
    });
  }
}
