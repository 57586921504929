<microsec-card>
  <div class="grid">
    <label class="col-12 text-data">
      Manual device enrollment through SCEP, EST, CMP, REST-API or KMIP protocols is possible and instructions can be found in the documentation.
    </label>
    <div class="col-12">
      <p-button severity="primary" label="Documentation" (onClick)="goToDocumentationDeviceEnrollment()" />
    </div>
  </div>
</microsec-card>
