import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@lcms-shared';
import { SharedLicenseWarningModule } from '../shared-components/shared-license-warning/shared-license-warning.module';
import { SharedOrganizationFormModule } from '../shared-components/shared-organization-form/shared-organization-form.module';
import { SharedUserAccessModule } from '../shared-components/shared-user-access/shared-user-access.module';
import { OrganizationManagementComponent } from './organization-management.component';
import { ProjectsComponent } from './overview/projects/projects.component';
import { OrganizationSettingsComponent } from './settings/organization-settings/organization-settings.component';
import { UserAccessComponent } from './settings/user-access/user-access.component';

@NgModule({
  declarations: [OrganizationManagementComponent, ProjectsComponent, UserAccessComponent, OrganizationSettingsComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedOrganizationFormModule,
    SharedUserAccessModule,
    SharedLicenseWarningModule,
  ],
})
export class OrganizationManagementModule {}
