import { Component, OnInit, ViewChild } from '@angular/core';
import { authenticationSelectors } from '@microsec/ngrx-authentication';
import { UserService } from '@microsec/services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { CREATE_LABEL, CREATE_SUCCESS, SAVE_CHANGES_LABEL, TEXT_ONLY_PATTERN, UPDATE_SUCCESS, VALIDATOR_TYPE } from '@microsec/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';

const FORM_PARAMS = {
  USERNAME: 'username',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  PASSWORD: 'password',
  PASSWORD_CONFIRMATION: 'password_confirmation',
  IS_SUPERUSER: 'is_superuser',
  CHANGE_PASSWORD: 'change_password',
};

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent extends BaseComponent implements OnInit {
  user: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private userSrv: UserService,
  ) {
    super();
  }

  async ngOnInit() {
    const currentUser = await firstValueFrom(this.store.select(authenticationSelectors.currentUser));
    this.user = this.dialogConfig.data?.user;
    this.initForm();
    if (!!this.user) {
      this.form.patchValue(this.user, () => {
        // If the current user is editing his profile, disable `Is System Admin User`
        if (currentUser?.id == this.user.id) {
          this.form.disableControl(FORM_PARAMS.IS_SUPERUSER);
        }
      });
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    const dividerField: FormItem = Object.assign(new FormItem(), { field: 'divider' } as FormItem);
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FIRST_NAME,
        label: 'First Name',
        field: 'input',
        required: true,
        pattern: TEXT_ONLY_PATTERN,
        patternErrorText: 'Only upper and lower case alphabetical letters [a-z] and spaces are allowed',
        fieldInfo: 'First name of user',
        defaultValue: '',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LAST_NAME,
        label: 'Last Name',
        field: 'input',
        required: true,
        pattern: TEXT_ONLY_PATTERN,
        patternErrorText: 'Only upper and lower case alphabetical letters [a-z] and spaces are allowed',
        fieldInfo: 'Last name of user',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.EMAIL,
        label: 'Email',
        field: 'input',
        required: true,
        fieldInfo: 'Email of user',
        defaultValue: '',
      } as FormItem),
      dividerField,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.USERNAME,
        label: 'Username',
        field: 'input',
        required: true,
        fieldInfo: 'Username',
        defaultValue: '',
      } as FormItem),
    ];
    const changePasswordField: FormItem = Object.assign(new FormItem(), {
      name: FORM_PARAMS.CHANGE_PASSWORD,
      checkboxLabel: 'Change Password?',
      field: 'checkbox',
      defaultValue: false,
    } as FormItem);
    const passwordField: FormItem = Object.assign(new FormItem(), {
      name: FORM_PARAMS.PASSWORD,
      label: `${!this.user ? 'Password' : `New Password`}`,
      field: 'password',
      required: !this.user,
      fieldInfo: `Password must contain at least 8 characters. Password must be uncommon.`,
      defaultValue: '',
      hidden: !!this.user,
    } as FormItem);
    const confirmationPasswordField: FormItem = Object.assign(new FormItem(), {
      name: FORM_PARAMS.PASSWORD_CONFIRMATION,
      label: 'Confirm Password',
      field: 'password',
      required: !this.user,
      fieldInfo: 'Password confirmation',
      defaultValue: '',
      hidden: !!this.user,
    } as FormItem);
    const isSuperAdminField: FormItem = Object.assign(new FormItem(), {
      name: FORM_PARAMS.IS_SUPERUSER,
      checkboxLabel: 'System Admin',
      field: 'checkbox',
      fieldInfo: 'Tick this if the user is system admin user',
      defaultValue: false,
    } as FormItem);
    if (!this.user) {
      // Create
      fields.push(...[passwordField, confirmationPasswordField, isSuperAdminField]);
    } else {
      // Edit
      fields.push(...[isSuperAdminField, changePasswordField, passwordField, confirmationPasswordField]);
    }
    fields.forEach((field: FormItem) => field.setMediumSize());
    this.fields = fields;
    this.form.setChangeEvent(FORM_PARAMS.CHANGE_PASSWORD, (value: boolean) => {
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.PASSWORD, !!value ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.PASSWORD_CONFIRMATION, !!value ? [VALIDATOR_TYPE.REQUIRED] : []);
    });
  }

  /**
   * Submit form
   * @param closeDialog
   * @returns
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form.getRawValue() };
    delete payload[FORM_PARAMS.CHANGE_PASSWORD];
    payload.is_active = true;
    if (payload[FORM_PARAMS.PASSWORD] !== payload[FORM_PARAMS.PASSWORD_CONFIRMATION]) {
      this.showErrorMessage('Password and password confirmation do not match');
      this.form.isLoading = false;
      return;
    }
    let request;
    if (!this.user) {
      request = this.userSrv.createUser(payload);
    } else {
      request = this.userSrv.updateUser(this.user.id, payload);
    }
    request.subscribe({
      next: () => {
        const message = !this.user ? CREATE_SUCCESS : UPDATE_SUCCESS;
        this.showSuccessMessage(message.replace('{0}', `user ${payload[FORM_PARAMS.USERNAME]}`));
        closeDialog();
      },
      error: (err: any) => {
        this.form.showServerErrorMessage(err);
        this.showErrorMessage(err);
      },
    });
  }
}
