<p-tabView [scrollable]="true" styleClass="microsec-dashboard-tab">
  <p-tabPanel [header]="RULE_TYPE_VALUES.IP">
    <app-rulesets [type]="RULE_TYPE_VALUES.IP" [selectedDevice]="selectedDevice" />
  </p-tabPanel>
  <p-tabPanel [header]="RULE_TYPE_VALUES.CAN">
    <app-rulesets [type]="RULE_TYPE_VALUES.CAN" [selectedDevice]="selectedDevice" />
  </p-tabPanel>
  <p-tabPanel [header]="RULE_TYPE_VALUES.COMMAND">
    <app-rulesets [type]="RULE_TYPE_VALUES.COMMAND" [selectedDevice]="selectedDevice" />
  </p-tabPanel>
  <p-tabPanel [header]="RULE_TYPE_VALUES.YARA">
    <app-rulesets [type]="RULE_TYPE_VALUES.YARA" [selectedDevice]="selectedDevice" />
  </p-tabPanel>
</p-tabView>
