import { ActionMenuItem } from '@microsec/models';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { KmsService } from '@lcms-services';
import { BaseComponent } from '@microsec/components';
import { CREATE_LABEL, DELETE_LABEL, DELETE_SUCCESS, PIPE_DATETIME } from '@microsec/constants';
import { finalize } from 'rxjs';
import { GpgKeyFormComponent } from './gpg-key-form/gpg-key-form.component';
import { GpgKeyImportFormComponent } from './gpg-key-import-form/gpg-key-import-form.component';
import { GpgKeyExportFormComponent } from './gpg-key-export-form/gpg-key-export-form.component';
import { ActivatedRoute, Params } from '@angular/router';
import { MICROSEC_DEFAULT_KEYRING } from '@lcms-constants';

@Component({
  selector: 'app-keyring-gpg-keys',
  templateUrl: './keyring-gpg-keys.component.html',
  styleUrls: ['./keyring-gpg-keys.component.scss'],
})
export class KeyringGpgKeysComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = false;

  _selectedKeyring: any = null;

  @Input() set selectedKeyring(value: any) {
    this._selectedKeyring = value;
    this.selectedKey = null;
    this.getGPGKeys();
  }

  get selectedKeyring() {
    return this._selectedKeyring;
  }

  cols: any[] = [
    { field: 'label', header: 'Label', width: 3, frozen: true },
    { field: 'type', header: 'Type', width: 2 },
    { field: 'key_algorithm', header: 'Algorithm', width: 3 },
    { field: 'key_length', header: 'Size', width: 2 },
    { field: 'created', header: 'Created Date', width: 3 },
  ];

  _selectedKey: any = null;

  get selectedKey() {
    return this._selectedKey;
  }

  set selectedKey(value: any) {
    this._selectedKey = value;
  }

  values: any[] = [];

  actionMenuItems: ActionMenuItem[] = [];

  PIPE_DATETIME = PIPE_DATETIME;

  MICROSEC_DEFAULT_KEYRING = MICROSEC_DEFAULT_KEYRING;

  queryParams: Params | null = null;

  constructor(
    private route: ActivatedRoute,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });

    this.actionMenuItems = [
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => this.selectedKeyring?.name !== MICROSEC_DEFAULT_KEYRING,
        command: ({ rowData }: any) => this.confirmDeletion(rowData),
      },
    ];
  }

  /**
   * Get GPG keys
   */
  async getGPGKeys() {
    await this.prepareConfigs();
    this.isLoading = true;
    this.kmsSrv
      .getGPGKeys(this.selectedKeyring?.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.values = (rs?.data as any[]) || [];
          let selectedKey: any = null;
          if (!!this.queryParams?.['gpg_key_id']) {
            let id = null;
            try {
              id = Number.parseInt(this.queryParams['gpg_key_id']);
            } catch {
              id = null;
            }
            selectedKey = { id };
            this.router.navigate([], { queryParams: { gpg_key_id: null }, queryParamsHandling: 'merge' });
          }
          if (!!selectedKey) {
            let selectedKeyIndex = -1;
            selectedKey = this.values.find((p, index) => {
              if (p.id === this.selectedKey?.id || p.id === selectedKey?.id) {
                selectedKeyIndex = index;
                return true;
              }
              return false;
            });
            if (selectedKeyIndex >= 0) {
              this.values.splice(selectedKeyIndex, 1);
              this.values.unshift(selectedKey);
              this.selectedKey = selectedKey;
            }
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Open key form
   */
  openKeyForm() {
    const dialog = this.dialogSrv.open(GpgKeyFormComponent, {
      header: `${CREATE_LABEL} GPG Key`,
      data: { keyring: this.selectedKeyring },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getGPGKeys();
      }
    });
  }

  /**
   * Open key import form
   */
  openKeyImportForm() {
    this.dialogSrv.open(GpgKeyImportFormComponent, {
      header: `Import GPG Key`,
      data: { keyring: this.selectedKeyring },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }

  /**
   * Open key export form
   * @param key
   */
  openKeyExportForm(key: any) {
    this.dialogSrv.open(GpgKeyExportFormComponent, {
      header: `Export GPG Key`,
      data: { keyring: this.selectedKeyring, key },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }

  /**
   * Confirm key deletion
   * @param key
   */
  confirmDeletion(key: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'GPG Key',
      object: key,
      objectFieldName: 'label',
      next: () => {
        this.isLoading = true;
        this.kmsSrv
          .deleteGPGKey(this.selectedKeyring?.id, key?.id)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
          .subscribe({
            next: () => {
              const message = DELETE_SUCCESS.replace('{0}', 'GPG key');
              this.showSuccessMessage(message);
              this.selectedKey = null;
              this.getGPGKeys();
            },
            error: (err) => {
              this.showErrorMessage(err);
            },
          });
      },
    });
  }
}
