import { AfterViewInit, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { CA_MANAGER_TYPE_VALUES, CA_MODE_VALUES } from '@lcms-constants';
import { SharedCaTemplateFormComponent } from '@lcms-products';
import { CaManagementService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { ConstantPipe } from '@lcms-pipes';
import { PIPE_DATETIME } from '@microsec/constants';
import { finalize } from 'rxjs/operators';
import { ActionMenuItem } from '@microsec/models';

const FORM_PARAMS = {
  ID: 'id',
  NAME: 'name',
  TYPE: 'typeValue',
  DESCRIPTION: 'description',
  ADDRESS: 'address',
  STATUS: 'connection_status',
  IS_SSL: 'is_ssl',
};

@Component({
  selector: 'app-ca-connection-overview',
  templateUrl: './ca-connection-overview.component.html',
  styleUrls: ['./ca-connection-overview.component.scss'],
  providers: [ConstantPipe],
})
export class CaConnectionOverviewComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  _selectedCAManager: any = null;

  get selectedCAManager() {
    return this._selectedCAManager;
  }

  @Input() set selectedCAManager(value: any) {
    value.typeValue = this.constantPipe?.transform(value?.type, 'ca-manager-type');
    this._selectedCAManager = value;
    this.initForm();
    if (!value?.cas) {
      this.getCACertificates();
    } else {
      this.values = (value?.cas || []).map((ca: any) => ({ ...ca, commonName: ca.subject?.CN }));
    }
  }

  cols: any[] = [
    { field: 'id', header: 'ID', width: 5, frozen: true },
    { field: 'commonName', header: 'Common Name', width: 10 },
    { field: 'type', header: 'Type', width: 6 },
    { field: 'mode', header: 'Mode', width: 6 },
    { field: 'expiry', header: 'Expiry', width: 8 },
    { field: 'ca_templates', header: 'CA Templates', width: 10 },
  ];

  values: any[] = [];

  actionMenuItems: ActionMenuItem[] = [];

  structure: any[] = [];

  PIPE_DATETIME = PIPE_DATETIME;

  @ViewChild('statusTemplate') statusTemplate: TemplateRef<any>;

  @ViewChild('caCertificatesTemplate') caCertificatesTemplate: TemplateRef<any>;

  constructor(
    private caManagementSrv: CaManagementService,
    private constantPipe: ConstantPipe,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.actionMenuItems = [
      {
        label: 'Download Certificate',
        icon: 'fas fa-download',
        command: ({ rowData }: any) => {
          this.downloadCertificate(rowData);
        },
      },
      {
        label: 'Download CRL',
        icon: 'fas fa-download',
        command: ({ rowData }: any) => {
          this.downloadCRL(rowData);
        },
      },
    ];
    this.initForm();
  }

  /**
   * Initialize form
   */
  initForm() {
    this.structure = [
      'Details',
      { label: 'ID', name: FORM_PARAMS.ID },
      { label: 'Name', name: FORM_PARAMS.NAME },
      { label: 'Type', name: FORM_PARAMS.TYPE },
      { label: 'Description', name: FORM_PARAMS.DESCRIPTION },
      ...(this.selectedCAManager?.type !== CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA
        ? [
            { label: 'Address', name: FORM_PARAMS.ADDRESS },
            { label: 'Status', field: 'custom', customField: this.statusTemplate },
            { label: 'Is SSL', name: FORM_PARAMS.IS_SSL, field: 'checkbox', disabled: true },
          ]
        : []),
      'Available CA Certificates',
      { hasNoLabel: true, field: 'custom', customField: this.caCertificatesTemplate },
    ];
  }

  /**
   * GET CA Server Detail
   */
  getCaStatus() {
    this.isLoading = true;
    this.caManagementSrv
      .getCAManager(this.selectedCAManager?.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.selectedCAManager.connection_status = rs.data?.connection_status;
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * GET CA Certificates
   */
  getCACertificates() {
    this.isLoading = true;
    this.caManagementSrv
      .getCACertificates(this.selectedCAManager?.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.selectedCAManager.status = 'connected';
          const certs = (rs?.data || []).map((ca: any) => ({ ...ca, commonName: ca.subject?.CN }));
          this.values = this.util.sortObjectArray(certs, 'id');
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Download certificate
   * @param ca
   */
  downloadCertificate(ca: any) {
    let extension = '';
    switch (ca?.mode) {
      case CA_MODE_VALUES.MICROPKI: {
        extension = 'ucrt';
        break;
      }
      default:
        extension = 'pem';
        break;
    }
    this.util.downloadFileFromText(ca?.pem, `cert_${ca?.id}_${ca?.subject?.CN}.${extension}`.replace(/ /g, '_').toLowerCase());
    this.showSuccessMessage('Downloaded certificate successfully');
  }

  /**
   * Download CRL
   * @param ca
   */
  downloadCRL(ca: any) {
    this.caManagementSrv.downloadCRL(this.selectedCAManager?.id, ca?.id).subscribe({
      next: (response: any) => {
        if (!!response) {
          this.util.downloadFileFromBlob(response, `crl_${ca?.id}_${ca?.subject?.CN || 'file'}.crl`);
          this.showSuccessMessage(`Downloaded CRL successfully`);
        }
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Open CA template view form
   * @param caTemplates
   */
  openCATemplateForm(ca: any) {
    this.caManagementSrv.getCATemplates(this.selectedCAManager?.id, ca?.id).subscribe({
      next: (rs: any) => {
        this.dialogSrv.open(SharedCaTemplateFormComponent, {
          data: {
            scope: this.SCOPE.PROJECT,
            caTemplateHelpers: rs?.data?.jsonschema,
            caTemplateData: rs?.data,
            mode: 'view',
          },
          header: 'View CA Template',
          width: '800px',
          height: 'min-content',
          closeOnEscape: true,
        });
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    });
  }
}
