<microsec-card>
  @if (!!permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN]) {
    <div class="grid">
      <div class="col-12 text-data">Please choose between generating CSR from our built-in interface or uploading a pre-generated CSR manually.</div>
      <div class="col-12 mt-2 mb-3">
        <div class="flex">
          @for (csrOption of csrOptions; track $index) {
            <p-radioButton
              labelStyleClass="mr-6 text-data"
              name="csr-option"
              [value]="csrOption.value"
              [label]="csrOption.label"
              [(ngModel)]="selectedOption" />
          }
        </div>
      </div>
    </div>
    <!-- Form -->
    @if (!!selectedOption) {
      @switch (selectedOption) {
        <!-- Generate -->
        @case ('generate') {
          <app-csr-generate-form type="device" />
        }
        <!-- Upload -->
        @case ('upload') {
          <app-device-csr-upload-form />
        }
        <!-- Others -->
        @default {}
      }
    }
  } @else {
    <div class="grid">
      <label class="col-12"> You do not have access to this option. </label>
    </div>
  }
</microsec-card>
