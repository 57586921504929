import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { authenticationSelectors } from '@microsec/ngrx-authentication';
import { devicesSelectors, fromDevicesActions } from '@ngrx-devices';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { DeviceService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { IMqttServiceOptions, MqttService } from 'ngx-mqtt';
import { firstValueFrom } from 'rxjs';
import { DEVICES_CHILDREN } from './devices.config';
import { ILayoutItem } from '@microsec/interfaces';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent extends BaseComponent implements OnInit, OnDestroy {
  isInitialized = false;

  isLoading = true;

  constructor(
    private mqttSrv: MqttService,
    private deviceSrv: DeviceService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
      const tabItems: ILayoutItem[] = this.util.cloneObjectArray(DEVICES_CHILDREN);
      this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));

      this.store.dispatch(new fromDevicesActions.Init());
      this.initDeviceMqtt();
    }
    this.subscriptions.push(
      this.store.select(devicesSelectors.isLoading).subscribe((isLoading) => {
        this.isLoading = isLoading;
        if (!!isLoading) {
          this.isInitialized = true;
        }
      }),
    );
  }

  override ngOnDestroy(): void {
    this.store.dispatch(new fromDevicesActions.Init());
    this.store.dispatch(new fromDevicesActions.CancelGetDevicesRequest());
    this.cleanup({ shouldClearTabItems: true });
    if (!!this.deviceSrv.mqtt) {
      this.deviceSrv.mqtt.disconnect();
    }
    this.deviceSrv.mqtt = null;
  }

  /**
   * Init MQTT Service
   */
  async initDeviceMqtt() {
    if (!this.deviceSrv.mqtt) {
      this.deviceSrv.mqtt = this.mqttSrv;
      const currentUser = await firstValueFrom(this.store.select(authenticationSelectors.currentUser));
      if (!!currentUser) {
        const { username } = currentUser;
        let { token } = currentUser;
        let hostname = this.currentDomain;
        // eslint-disable-next-line no-useless-escape
        hostname = hostname.replace(/\:\d+/g, '');

        // CONNECT
        this.deviceSrv.mqtt?.connect({
          hostname,
          username,
          password: token,
          // interval between two re-connections; Disable auto reconnect by setting to 0
          reconnectPeriod: 500,
        } as IMqttServiceOptions);

        // ON SUCCESS
        this.deviceSrv.mqtt?.onConnect.subscribe((res: any) => {
          window.microsec.log(res);
        });

        // ON FAILED
        this.deviceSrv.mqtt?.onError.subscribe((err: any) => {
          window.microsec.log(err);
          if (err.message === 'Connection refused: Not authorized') {
            this.authSrv.refreshAccessToken().subscribe((res) => {
              token = res.access;
              this.mqttSrv?.connect({
                username,
                password: token,
              } as IMqttServiceOptions);
            });
          }
        });
      }
    }
  }
}
