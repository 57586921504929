import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MODULE_CONSTANTS } from '@microsec/constants';
import { LicenseService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { GLOBAL_SETTINGS_CONSTANTS } from '@lcms-constants';
import { ConfirmationDialogConfig } from '@microsec/models';
import { MOMENT_DATETIME, REVOKE_LABEL } from '@microsec/constants';
import moment from 'moment';
import { finalize } from 'rxjs/operators';
import { LICENSES_CONSTANTS } from '../licenses.constants';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  data: any = {};

  licenseId: any;

  machineId: any;

  constructor(
    public route: ActivatedRoute,
    private licenseSrv: LicenseService,
  ) {
    super();
  }

  ngOnInit() {
    this.licenseId = this.route.snapshot.paramMap.get('licenseId');
    this.getLicense(this.licenseId);
    this.getMachineID();
  }

  /**
   * Get license
   * @param id
   */
  getLicense(id: any) {
    if (!!id) {
      this.isLoading = true;
      this.licenseSrv
        .getLicense(id)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (response: any) => {
            this.data = response;
          },
          error: (err: any) => {
            this.showErrorMessage(err);
          },
        });
    }
  }

  /**
   * Get machine ID
   */
  getMachineID() {
    this.isLoading = true;
    this.licenseSrv
      .getMachineID()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          this.machineId = response.data;
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Confirm revoke all license
   */
  confirmRevokeAllLicenses() {
    this.confirm({
      action: REVOKE_LABEL,
      objectName: 'License',
      customContent: '&nbsp;',
      next: () => {
        this.deleteAllLicenses();
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Delete all licenses
   */
  deleteAllLicenses() {
    this.isLoading = true;
    this.licenseSrv
      .deleteAllLicenses()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Revoked License successfully');
          this.changeRoute(`${MODULE_CONSTANTS.GLOBAL_SETTINGS.ROUTE}/${GLOBAL_SETTINGS_CONSTANTS.LICENSES.ROUTE}`, false, true);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get formatted date
   * @param stringDate
   * @returns
   */
  getFormattedDate(stringDate: string) {
    if (!!stringDate) return moment(stringDate).format(MOMENT_DATETIME);
    return '';
  }

  /**
   * Get from now time
   * @param stringDate
   * @returns
   */
  getFromNowTime(stringDate: string) {
    if (!!stringDate) return moment(stringDate).fromNow();
    return '';
  }

  /**
   * Go to upload page
   */
  goToUpload() {
    this.changeRoute(`${MODULE_CONSTANTS.GLOBAL_SETTINGS.ROUTE}/` + `${GLOBAL_SETTINGS_CONSTANTS.LICENSES.ROUTE}/${LICENSES_CONSTANTS.UPLOAD.ROUTE}`);
  }
}
