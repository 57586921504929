<microsec-card>
  <div class="grid">
    <div class="col-12 xl:col-6 lg:col-8 md:col-10 sm:col-12">
      <microsec-form-builder
        [isPage]="true"
        #fb
        [fields]="fields"
        [shouldShowActionButtons]="true"
        [leftActionArea]="leftActionArea"
        [submitButtonLabel]="SAVE_CHANGES_LABEL"
        [cancelButtonLabel]="''"
        (submitEvent)="onSubmit()" />
    </div>
  </div>
</microsec-card>

<ng-template #leftActionArea>
  <p-button severity="secondary" styleClass="mr-2" type="button" (onClick)="getData()" label="Discard" />
</ng-template>

<ng-template #enrollmentLinkTemplate>
  <div class="grid field mt-2">
    <div class="col-12">
      <span>Link to enrollment dashboard:&nbsp;</span>
      <a class="link" [href]="enrollmentLink" target="_blank">
        {{ enrollmentLink }}
      </a>
    </div>
  </div>
</ng-template>
