export const FEATURES = {
  SYSTEM_MANAGEMENT: 'system-management',
  PKI_MANAGEMENT: 'pki-management',
  DEVICE_MANAGEMENT: 'device-management',
  SUPPLY_CHAIN_MANAGEMENT: 'supply-chain-management',
  QUOTAS: 'quotas',
};

export const SYSTEM_MANAGEMENT_FEATURES = {
  AUTH_LDAP: 'auth-ldap',
  AUTH_RSA_SECURID: 'auth-rsa-securid',
  AUTH_AUTHENTICATOR_APP: 'auth-authenticator-app',
  AUDIT_REPORTS: 'audit-reports', // Not implemented yet
  WORKFLOW: 'workflow',
};

export const PKI_MANAGEMENT_FEATURES = {
  X509: 'x509-acms',
  MICRO_PKI: 'micropki',
  EXTERNAL_CA: 'external-ca',
  PROTOCOL_ACMEV2: 'protocol-acmev2',
  PROTOCOL_EST: 'protocol-est',
  PROTOCOL_SCEP: 'protocol-scep',
  CRYPTOKEN_MANAGEMENT: 'cryptoken-management',
  USER_CERTIFICATES: 'user-certificates',
  KMS: 'kms',
  PROTOCOL_GENERIC: 'protocol-generic',
};

export const DEVICE_MANAGEMENT_FEATURES = {
  TELEMETRY: 'telemetry',
  PACKAGES: 'packages',
  FIRMWARE: 'firmware',
  FIREWALL: 'firewall',
  KEYS: 'keys',
  LCMS_AGENT: 'lcms-agent',
  VIRTUAL_DEVICES: 'virtual-devices',
};

export const SUPPLY_CHAIN_MANAGEMENT_FEATURES = {
  ENVELOPES: 'envelopes',
  ENVELOPE_SHARING: 'envelope-sharing',
  CA_ROLES: 'ca-roles',
};

export const QUOTAS_FEATURES = {
  ORGANIZATIONS: 'organizations',
  PROJECTS: 'projects', // Not implemented yet
  USERS: 'users',
  DEVICES: 'devices', // Not implemented yet
  CERTIFICATES: 'certificates', // Not implemented yet
};
