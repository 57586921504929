import { Component } from '@angular/core';
import { RULE_TYPE_VALUES } from '@lcms-constants';

@Component({
  selector: 'app-ip-rules',
  templateUrl: './ip.component.html',
  styleUrls: ['./ip.component.scss'],
})
export class IpRulesComponent {
  RULE_TYPE_VALUES = RULE_TYPE_VALUES;
}
