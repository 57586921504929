import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { INITIAL_LAMBDAS, LAMBDA_TYPES, OUTPUT_MIME_TYPES } from '@lcms-constants';
import { EnvelopeService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, CREATE_SUCCESS, SAVE_CHANGES_LABEL, SCOPE, UPDATE_SUCCESS } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export const ENVELOPE_TEMPLATE_FORM_PARAMS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  SCOPE: 'scope',
  INPUT_VALIDATION_LAMBDA: 'input_validation_lambda',
  PROCESSING_LAMBDA: 'processing_lambda',
  VERIFICATION_LAMBDA: 'verification_lambda',
  OUTPUT_MIME_TYPE: 'output_mime_type',
  OUTPUT_FILE_NAME: 'output_file_name',
  SIGNATURE_HASH: 'signature_hash',
};

export const VALIDATION_FORM_PARAMS = {
  LAMBDA_TYPE: 'lambda_type',
  INPUT: 'input',
  LAMBDA_FUNCTION: 'lambda_function',
};
@Component({
  selector: 'app-envelope-template-form',
  templateUrl: './envelope-template-form.component.html',
  styleUrls: ['./envelope-template-form.component.scss'],
})
export class EnvelopeTemplateFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  // faCheckCircle = faCheckCircle;

  // faExclamationCircle = faExclamationCircle;

  // VALIDATION_FORM_PARAMS = VALIDATION_FORM_PARAMS;

  LAMBDA_TYPES = LAMBDA_TYPES;

  @Input() templates: any[] = [];

  /**
   * Mode: new/view/edit, including getter and setter
   */
  @Input() mode = 'new';

  /**
   * Selected template
   */
  _selectedTemplate: any = null;

  get selectedTemplate() {
    return this._selectedTemplate;
  }

  @Input() set selectedTemplate(value: any) {
    this._selectedTemplate = value;
    this.initForm();
    if (!!this.selectedTemplate) {
      const selectedTemplate = this.util.cloneDeepObject(this.selectedTemplate);
      delete selectedTemplate[ENVELOPE_TEMPLATE_FORM_PARAMS.INPUT_VALIDATION_LAMBDA];
      delete selectedTemplate[ENVELOPE_TEMPLATE_FORM_PARAMS.PROCESSING_LAMBDA];
      delete selectedTemplate[ENVELOPE_TEMPLATE_FORM_PARAMS.VERIFICATION_LAMBDA];
      this.form?.patchValue(selectedTemplate);
      // setTimeout(() => {
      //   this.validityLambda = {
      //     [LAMBDA_TYPES.INPUT]: true,
      //     [LAMBDA_TYPES.PROCESSING]: true,
      //     [LAMBDA_TYPES.VERIFICATION]: true,
      //   };
      // });
    }
  }

  /**
   * Event emitter for refreshing the list of templates
   */
  @Output() refreshTemplatesListEvent: EventEmitter<any> = new EventEmitter<any>();

  // @ViewChild('validationLambdaPanel') validationLambdaPanel!: OverlayPanel;

  // isValidationLambdaLoading = {
  //   [LAMBDA_TYPES.INPUT]: false,
  //   [LAMBDA_TYPES.PROCESSING]: false,
  //   [LAMBDA_TYPES.VERIFICATION]: false,
  // };

  // validityLambda = {
  //   [LAMBDA_TYPES.INPUT]: false,
  //   [LAMBDA_TYPES.PROCESSING]: false,
  //   [LAMBDA_TYPES.VERIFICATION]: false,
  // };

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private envelopeSrv: EnvelopeService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    const dialogSelectedTemplate = this.dialogConfig.data?.selectedTemplate;
    if (!!dialogSelectedTemplate) {
      this.mode = 'edit';
      this.selectedTemplate = dialogSelectedTemplate;
    } else {
      this.selectedTemplate = !!this.selectedTemplate ? { ...this.selectedTemplate } : null;
    }
    setTimeout(() => {
      this.getHashes();
    });
  }

  /**
   * Get hashes
   */
  getHashes() {
    const hashesField = this.fields.find((p) => p.name === ENVELOPE_TEMPLATE_FORM_PARAMS.SIGNATURE_HASH);
    if (!!hashesField) {
      if (this.mode !== 'view') {
        this.form.isLoading = true;
        this.envelopeSrv
          .getHashes()
          .pipe(
            finalize(() => {
              this.form.isLoading = false;
            }),
          )
          .subscribe({
            next: (rs) => {
              hashesField.options = (rs.hashes as any[])?.map((p) => ({
                value: p,
                label: p,
              }));
            },
            error: (err: any) => {
              this.showErrorMessage(err);
            },
          });
      }
    }
  }

  /**
   * Initialize the form
   */
  initForm() {
    const lambdaValues = {
      [ENVELOPE_TEMPLATE_FORM_PARAMS.INPUT_VALIDATION_LAMBDA]: '',
      [ENVELOPE_TEMPLATE_FORM_PARAMS.PROCESSING_LAMBDA]: '',
      [ENVELOPE_TEMPLATE_FORM_PARAMS.VERIFICATION_LAMBDA]: '',
    };
    Object.keys(lambdaValues).forEach((key) => {
      try {
        lambdaValues[key] =
          this.mode === 'new' ? INITIAL_LAMBDAS[(key as string).toUpperCase()] : this.util.decodeBase64ToLatinString(this.selectedTemplate[key]);
      } catch (ex) {
        this.showErrorMessage(`Cannot get value of ${key}`);
        lambdaValues[key] = '';
      }
    });
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: ENVELOPE_TEMPLATE_FORM_PARAMS.NAME,
        label: 'Name',
        field: this.mode === 'view' ? 'label' : 'input',
        required: true,
        fieldInfo: this.mode === 'view' ? '' : "Envelope template's name",
        defaultValue: '',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: ENVELOPE_TEMPLATE_FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: this.mode === 'view' ? 'label' : 'input',
        fieldInfo: this.mode === 'view' ? '' : "Envelope template's description",
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: ENVELOPE_TEMPLATE_FORM_PARAMS.ORGANIZATION_ID,
        defaultValue: this.breadcrumbConfig?.organizationId,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: ENVELOPE_TEMPLATE_FORM_PARAMS.PROJECT_ID,
        defaultValue: this.breadcrumbConfig?.projectId,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: ENVELOPE_TEMPLATE_FORM_PARAMS.SCOPE,
        label: 'Scope',
        field: 'label',
        defaultValue: '',
        hidden: this.mode !== 'view',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: ENVELOPE_TEMPLATE_FORM_PARAMS.SIGNATURE_HASH,
        label: 'Signature Hash',
        field: this.mode === 'view' ? 'label' : 'dropdown',
        options: [] as any[],
        placeholder: 'Select a signature hash',
        required: true,
        fieldInfo: this.mode === 'view' ? '' : 'Signature hash',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: ENVELOPE_TEMPLATE_FORM_PARAMS.OUTPUT_MIME_TYPE,
        label: 'Output Mime Type',
        field: this.mode === 'view' ? 'label' : 'dropdown',
        options: this.util.cloneObjectArray(OUTPUT_MIME_TYPES),
        placeholder: 'Select an output mime type',
        required: true,
        fieldInfo: this.mode === 'view' ? '' : 'Output mime type',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: ENVELOPE_TEMPLATE_FORM_PARAMS.OUTPUT_FILE_NAME,
        label: 'Output File Name',
        field: this.mode === 'view' ? 'label' : 'input',
        required: true,
        fieldInfo: this.mode === 'view' ? '' : 'Output file name',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: ENVELOPE_TEMPLATE_FORM_PARAMS.INPUT_VALIDATION_LAMBDA,
        label: 'Input Validation Lambda',
        field: 'codemirror',
        codeMirrorMode: this.mode,
        defaultValue: lambdaValues[ENVELOPE_TEMPLATE_FORM_PARAMS.INPUT_VALIDATION_LAMBDA],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: ENVELOPE_TEMPLATE_FORM_PARAMS.PROCESSING_LAMBDA,
        label: 'Processing Lambda',
        field: 'codemirror',
        codeMirrorMode: this.mode,
        defaultValue: lambdaValues[ENVELOPE_TEMPLATE_FORM_PARAMS.PROCESSING_LAMBDA],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: ENVELOPE_TEMPLATE_FORM_PARAMS.VERIFICATION_LAMBDA,
        label: 'Verification Lambda',
        field: 'codemirror',
        codeMirrorMode: this.mode,
        defaultValue: lambdaValues[ENVELOPE_TEMPLATE_FORM_PARAMS.VERIFICATION_LAMBDA],
      } as FormItem),
    ];
    this.fields = fields;
    // Set change events
    // this.form?.setChangeEvent(ENVELOPE_TEMPLATE_FORM_PARAMS.INPUT_VALIDATION_LAMBDA, () => {
    //   this.validityLambda[LAMBDA_TYPES.INPUT] = false;
    // });
    // this.form?.setChangeEvent(ENVELOPE_TEMPLATE_FORM_PARAMS.PROCESSING_LAMBDA, () => {
    //   this.validityLambda[LAMBDA_TYPES.PROCESSING] = false;
    // });
    // this.form?.setChangeEvent(ENVELOPE_TEMPLATE_FORM_PARAMS.VERIFICATION_LAMBDA, () => {
    //   this.validityLambda[LAMBDA_TYPES.VERIFICATION] = false;
    // });
  }

  // initValidationForm(lambdaType: string, lambdaFunction: string) {
  //   this.validationForm = this.fb.group({
  //     [VALIDATION_FORM_PARAMS.LAMBDA_TYPE]: [lambdaType],
  //     [VALIDATION_FORM_PARAMS.LAMBDA_FUNCTION]: [lambdaFunction],
  //     [VALIDATION_FORM_PARAMS.INPUT]: ['', validatorTrimRequired],
  //   });
  // }

  /**
   * Create/Update notification template
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const formValue = { ...this.form.getValue() };
    const payload = {
      ...formValue,
      [ENVELOPE_TEMPLATE_FORM_PARAMS.INPUT_VALIDATION_LAMBDA]: this.util.encodeLatinToBase64String(
        formValue[ENVELOPE_TEMPLATE_FORM_PARAMS.INPUT_VALIDATION_LAMBDA],
      ),
      [ENVELOPE_TEMPLATE_FORM_PARAMS.PROCESSING_LAMBDA]: this.util.encodeLatinToBase64String(
        formValue[ENVELOPE_TEMPLATE_FORM_PARAMS.PROCESSING_LAMBDA],
      ),
      [ENVELOPE_TEMPLATE_FORM_PARAMS.VERIFICATION_LAMBDA]: this.util.encodeLatinToBase64String(
        formValue[ENVELOPE_TEMPLATE_FORM_PARAMS.VERIFICATION_LAMBDA],
      ),
    };
    delete payload[ENVELOPE_TEMPLATE_FORM_PARAMS.SCOPE];
    // Define current scope
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        payload[ENVELOPE_TEMPLATE_FORM_PARAMS.ORGANIZATION_ID] = !!payload[ENVELOPE_TEMPLATE_FORM_PARAMS.ORGANIZATION_ID]
          ? payload[ENVELOPE_TEMPLATE_FORM_PARAMS.ORGANIZATION_ID]
          : this.breadcrumbConfig?.organizationId;
        payload[ENVELOPE_TEMPLATE_FORM_PARAMS.PROJECT_ID] = null;
        break;
      }
      case SCOPE.PROJECT: {
        payload[ENVELOPE_TEMPLATE_FORM_PARAMS.ORGANIZATION_ID] = !!payload[ENVELOPE_TEMPLATE_FORM_PARAMS.ORGANIZATION_ID]
          ? payload[ENVELOPE_TEMPLATE_FORM_PARAMS.ORGANIZATION_ID]
          : this.breadcrumbConfig?.organizationId;
        payload[ENVELOPE_TEMPLATE_FORM_PARAMS.PROJECT_ID] = !!payload[ENVELOPE_TEMPLATE_FORM_PARAMS.PROJECT_ID]
          ? payload[ENVELOPE_TEMPLATE_FORM_PARAMS.PROJECT_ID]
          : this.breadcrumbConfig?.projectId;
        break;
      }
      default:
        break;
    }

    window.microsec.log('Submitted template', payload);
    const request: Observable<any> =
      this.mode === 'new' ? this.envelopeSrv.createTemplate(payload) : this.envelopeSrv.updateTemplate(this.selectedTemplate.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = this.mode === 'new' ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', `template ${payload[ENVELOPE_TEMPLATE_FORM_PARAMS.NAME]}`));
          this.refreshTemplatesListEvent.emit();
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  // toggleValidationLambdaPanel(event: any, lambdaType: string, lambdaFunction: string) {
  //   this.validationLambdaPanel.toggle(event);
  //   if (this.validationLambdaPanel.overlayVisible) {
  //     this.initValidationForm(lambdaType, lambdaFunction);
  //   }
  // }

  // validateLambda() {
  //   const payload = { ...this.validationForm.getRawValue() };
  //   const lambdaType = payload[VALIDATION_FORM_PARAMS.LAMBDA_TYPE];
  //   this.isValidationLambdaLoading[lambdaType] = true;
  //   this.validationLambdaPanel.hide();
  //   this.envelopeSrv
  //     .validateLambdaCode(payload)
  //     .pipe(
  //       finalize(() => {
  //         this.isValidationLambdaLoading[lambdaType] = false;
  //       })
  //     )
  //     .subscribe({
  //       next: (rs: any) => {
  //         this.validityLambda[lambdaType] = rs.validity;
  //       },
  //       error: (err: any) => {
  //         this.showErrorMessage(err);
  //       },
  //     });
  // }

  /**
   * Check if all lambdas are valid
   */
  // get allValidityLambda() {
  //   return (
  //     !!this.validityLambda[LAMBDA_TYPES.INPUT] && !!this.validityLambda[LAMBDA_TYPES.PROCESSING] && !!this.validityLambda[LAMBDA_TYPES.VERIFICATION]
  //   );
  // }
}
