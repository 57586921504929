import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { EnvelopeOverviewComponent } from './envelope-overview/envelope-overview.component';
import { EnvelopeTemplatesComponent } from './envelope-templates/envelope-templates.component';

export const ENVELOPES_CONSTANTS = {
  OVERVIEW: {
    ROUTE: 'overview',
    LABEL: 'Overview',
  },
  TEMPLATES: {
    ROUTE: 'templates',
    LABEL: 'Templates',
  },
};

export const ENVELOPES_CHILDREN: ILayoutItem[] = [
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.ENVELOPES.ROUTE,
    label: '',
    route: ENVELOPES_CONSTANTS.OVERVIEW.ROUTE,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.ENVELOPES.ROUTE,
    route: ENVELOPES_CONSTANTS.OVERVIEW.ROUTE,
    label: ENVELOPES_CONSTANTS.OVERVIEW.LABEL,
    component: EnvelopeOverviewComponent,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.ENVELOPES.ROUTE,
    route: ENVELOPES_CONSTANTS.TEMPLATES.ROUTE,
    label: ENVELOPES_CONSTANTS.TEMPLATES.LABEL,
    component: EnvelopeTemplatesComponent,
  },
];
