import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { DeviceService, FirmwareService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { ASSIGN_LABEL } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  ASSIGNED_DEVICES: 'assigned_devices',
  ORG_ID: 'org_id',
};

@Component({
  selector: 'app-firmware-device-assignment-form',
  templateUrl: './firmware-device-assignment-form.component.html',
  styleUrls: ['./firmware-device-assignment-form.component.scss'],
})
export class FirmwareDeviceAssignmentFormComponent extends BaseComponent implements AfterViewInit {
  firmware: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  ASSIGN_LABEL = ASSIGN_LABEL;

  devices: any[] = [];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private deviceSrv: DeviceService,
    private firmwareSrv: FirmwareService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    this.prepareConfigs();
    this.firmware = this.dialogConfig.data?.firmware;
    this.devices = this.dialogConfig.data?.devices;
    this.initForm();
  }

  /**
   * Init the form
   */
  initForm() {
    const devicesField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.ASSIGNED_DEVICES,
      label: 'Device(s)',
      field: 'lazy-multiselect',
      options: [] as any[],
      placeholder: 'Select device(s)',
      required: true,
      fieldInfo: 'Assign this firmware to the selected device(s)',
      defaultValue: [],
      focused: true,
      onShowEvent: new EventEmitter<any>(),
    } as FormItem);
    const fields = [
      Object.assign(new FormItem(), {
        label: 'Assign firmware to the selected device(s) (if supported).',
        field: 'text',
      } as FormItem),
      devicesField,
    ];
    devicesField.onShowEvent?.subscribe((searchText: any) => {
      this.deviceSrv
        .getDevices(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId, {}, searchText, null, 1, 10)
        .subscribe((result) => {
          devicesField.options = ((result?.devices as any[]) || []).map((device) => ({
            ...device,
            value: device.id,
            label: device.common_name,
          }));
        });
    });
    this.fields = fields;
  }

  /**
   * Assign devices
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const selectedDevices = this.form.getRawValue()?.[FORM_PARAMS.ASSIGNED_DEVICES] || [];
    let deviceIds = [...(this.firmware[FORM_PARAMS.ASSIGNED_DEVICES] as any[]).map((d) => d.device_id), ...selectedDevices];
    deviceIds = deviceIds.filter((val, index, self) => self.indexOf(val) === index);
    this.firmwareSrv
      .updateFirmware(this.firmware.id, {
        [FORM_PARAMS.ASSIGNED_DEVICES]: deviceIds,
        [FORM_PARAMS.ORG_ID]: this.breadcrumbConfig?.organizationId,
      })
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Assigned firmware to device(s) successfully');
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
