<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="container-border">
    <microsec-details-panel [title]="selectedPackage?.name" [buttons]="actionButtons" (collapseEvent)="unselectPackage()">
      <p-tabView [scrollable]="true" styleClass="microsec-dashboard-tab">
        <p-tabPanel header="Overview">
          <microsec-scroller [maxHeight]="maxHeight">
            <app-package-overview [selectedPackage]="selectedPackage" />
          </microsec-scroller>
        </p-tabPanel>
      </p-tabView>
    </microsec-details-panel>
  </div>
</microsec-loading-overlay>
