<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="flex flex-column">
    <div class="px-4">
      @if (mode === 'view') {
        <div class="grid align-items-center px-6 pt-3">
          <label class="col-12 lg:col-3 text-data">Select CA Template<microsec-asterisk /></label>
          <div class="col-10 lg:col-8">
            <p-dropdown
              [options]="viewTemplates"
              [(ngModel)]="selectedViewTemplate"
              styleClass="w-full"
              optionLabel="label"
              optionValue="value"
              placeholder="Select a CA template"
              appendTo="body"
              (onChange)="loadCATemplateForm()" />
          </div>
          <div class="col-2 lg:col-1">
            <span tooltipStyleClass="tooltip-ca-form" pTooltip="To change the CA templates settings, login to CA and navigate to CA Templates page">
              <i class="pi pi-info-circle text-data pr-1"></i>
            </span>
          </div>
        </div>
      }
    </div>
  </div>
  @if (!!caTemplateForm) {
    <form [formGroup]="caTemplateForm" (ngSubmit)="onSubmit()">
      <!-- Form -->
      <div class="grid p-border-top scroll-panel ca-template-form">
        <div class="col-12 px-4">
          <p-tabView [scrollable]="true" styleClass="microsec-dashboard-tab" [(activeIndex)]="activeTab">
            <!-- CA Template Details -->
            <p-tabPanel header="CA Template Details">
              <app-ca-template-details-form
                [formGroupName]="FORM_PARAMS.DETAILS"
                [caTemplateHelpers]="caTemplateHelpers"
                [keySizeOptions]="keySizeOptions"
                [mode]="mode" />
            </p-tabPanel>

            <!-- X.509 Certificate Extensions -->
            <p-tabPanel header="X.509 Certificate Extensions">
              <app-ca-template-extensions-form [formGroupName]="FORM_PARAMS.EXTENSIONS" [caTemplateHelpers]="caTemplateHelpers" [mode]="mode" />
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>

      <!-- Buttons -->
      <div class="flex align-items-center justify-content-between action-buttons">
        @if (mode !== 'view') {
          <!-- New / Edit -->
          <div>
            @if (activeTab === 1) {
              <p-button severity="secondary" styleClass="mr-2" type="button" (onClick)="activeTab = 0" label="Previous" />
            }
          </div>
          <div>
            <p-button severity="secondary" styleClass="mr-2" type="button" (onClick)="dialogRef.close(false)" label="Cancel" />
            @if (activeTab === 1) {
              <p-button
                severity="success"
                type="submit"
                [disabled]="!caTemplateForm || !caTemplateForm.valid || !isAuthorityInformationAccessValid"
                [label]="!!caTemplate ? 'Save' : 'Create'" />
            }
            @if (activeTab === 0) {
              <p-button severity="primary" (onClick)="activeTab = 1" label="Next" />
            }
          </div>
        } @else {
          <!-- View -->
          <div></div>
          <p-button severity="secondary" styleClass="mr-2" type="button" (onClick)="dialogRef.close(false)" label="Close" />
        }
      </div>
    </form>
  }
</microsec-loading-overlay>
