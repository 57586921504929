import { Injectable } from '@angular/core';
import { IRuleService } from './IRuleService';
import { Observable, catchError, map, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { API } from '../api';

const API_RULESET = `${API.FIREWALL_MANAGER}/rulesets`;

@Injectable()
export class RuleService implements IRuleService {
  longTimeoutHeaders = new HttpHeaders({
    timeout: (20 * 1000).toString(),
  });

  constructor(private http: HttpClient) {}

  /**
   * ============================================ RULESET ============================================
   */

  getRulesets(projectId: any, deviceId: any, type: any): Observable<any> {
    let url = `${API_RULESET}?project_id=${projectId}&ruleset_type=${type}`;
    if (!!deviceId) {
      url += `&device_id=${deviceId}`;
    }
    return this.http.get<any>(url).pipe(
      map((result) => result?.rulesets || []),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  createRuleset(payload: any): Observable<any> {
    return this.http.post<any>(`${API_RULESET}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateRuleset(rulesetId: any, payload: any): Observable<any> {
    return this.http.patch<any>(`${API_RULESET}/${rulesetId}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteRuleset(rulesetId: any): Observable<any> {
    return this.http.delete<any>(`${API_RULESET}/${rulesetId}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  importRuleset(payload: any): Observable<any> {
    return this.http.post<any>(`${API_RULESET}/import`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  exportRuleset(rulesetId: any): Observable<any> {
    const options: any = { responseType: 'blob', observe: 'response' };
    return this.http.get<any>(`${API_RULESET}/${rulesetId}/export`, options).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * ============================================ RULE ============================================
   */

  getRules(rulesetId: any): Observable<any> {
    return this.http.get<any>(`${API_RULESET}/${rulesetId}/rules`, { headers: this.longTimeoutHeaders }).pipe(
      map((result) => result?.rules || []),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  createRule(rulesetId: any, payload: any): Observable<any> {
    return this.http
      .post<any>(`${API_RULESET}/${rulesetId}/rules`, payload, { headers: this.longTimeoutHeaders })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateRule(rulesetId: any, ruleId: any, payload: any): Observable<any> {
    return this.http
      .patch<any>(`${API_RULESET}/${rulesetId}/rules/${ruleId}`, payload, { headers: this.longTimeoutHeaders })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteRule(rulesetId: any, ruleId: any): Observable<any> {
    return this.http
      .delete<any>(`${API_RULESET}/${rulesetId}/rules/${ruleId}`, { headers: this.longTimeoutHeaders })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * ============================================ DEVICE ============================================
   */

  assignDevice(rulesetId: any, payload: any): Observable<any> {
    return this.http
      .post<any>(`${API_RULESET}/${rulesetId}/devices`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  removeDevice(rulesetId: any, payload: any): Observable<any> {
    return this.http
      .patch<any>(`${API_RULESET}/${rulesetId}/devices`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
