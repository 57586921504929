<div class="grid">
  <div class="col-12">
    <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)"> </microsec-common-toolbar>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-4 max-height-60': !!selectedManager }" #datatable (click)="unselectManager($event, datatable)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="selectedCols || []"
        [values]="filteredValues"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [(selection)]="selectedManager"
        itemName="User Certificate"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>

  @if (!!selectedManager) {
    <div class="col-12 lg:col-8 max-height-40">
      <app-user-certificate-info [(selectedManager)]="selectedManager" />
    </div>
  }
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getCertificates()" />
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="export($any(dt))" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Status -->
    @case ('status') {
      <app-user-certificate-status-block [config]="getLabelConfig(rowData[col.field])" />
      @if (!!rowData?.back_imported) {
        <app-user-certificate-status-block [config]="getLabelConfig('back-imported')" />
      }
    }
    <!-- Expiry Date -->
    @case ('not_after') {
      <microsec-truncate-text>
        {{ rowData[col.field] | date: PIPE_DATETIME }}
      </microsec-truncate-text>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
