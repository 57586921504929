import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SCOPE } from '@microsec/constants';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API } from '../api';
import { ICaTemplatesService } from './ICaTemplatesService';

const API_CA_TEMPLATES = `${API.CTMS}/ca-templates`;
const API_CA_TEMPLATES_ASSIGNMENT = `${API.CTMS}/cas/{0}/ca-templates`;

@Injectable({
  providedIn: 'root',
})
export class CaTemplatesService implements ICaTemplatesService {
  constructor(private http: HttpClient) {}

  /**
   * Get all CA Templates in global
   */
  getAllCATemplates(): Observable<any> {
    return this.http.get<any>(`${API_CA_TEMPLATES}?scope=${SCOPE.GLOBAL}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get all CA Templates by Organization ID
   * @param org_id
   */
  getCATemplatesByOrganization(org_id: any): Observable<any> {
    return this.http
      .get<any>(`${API_CA_TEMPLATES}?scope=${SCOPE.ORGANIZATION}&organization_id=${org_id}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get all CA Templates by Project ID
   * @param project_id
   */
  getCATemplatesByProject(project_id: any): Observable<any> {
    return this.http
      .get<any>(`${API_CA_TEMPLATES}?scope=${SCOPE.PROJECT}&project_id=${project_id}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get CA Template by ID
   * @param id
   */
  getCATemplate(id: any): Observable<any> {
    return this.http.get<any>(`${API_CA_TEMPLATES}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Create CA Template
   * @param payload
   */
  createCATemplate(payload: any): Observable<any> {
    return this.http.post<any>(`${API_CA_TEMPLATES}/`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Update CA Template
   * @param id
   * @param payload
   */
  updateCATemplate(id: any, payload: any): Observable<any> {
    return this.http.put<any>(`${API_CA_TEMPLATES}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get CA Template by ID
   * @param id
   */
  deleteCATemplate(id: any): Observable<any> {
    return this.http.delete<any>(`${API_CA_TEMPLATES}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get assigned CA templates for a specific CA
   * @param caId
   */
  getAssignedCATemplates(caId: any): Observable<any> {
    return this.http
      .get<any>(`${API_CA_TEMPLATES_ASSIGNMENT.replace('{0}', caId)}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Update assigned CA templates for a specific CA
   * @param caId
   * @param payload
   */
  updateAssignedCATemplates(caId: any, payload: any): Observable<any> {
    return this.http
      .put<any>(`${API_CA_TEMPLATES_ASSIGNMENT.replace('{0}', caId)}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * Get unassigned CA templates for a specific CA
   * @param caId
   */
  getUnassignedCATemplates(caId: any): Observable<any> {
    return this.http
      .get<any>(`${API_CA_TEMPLATES_ASSIGNMENT.replace('{0}', caId)}?unassigned=true`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
