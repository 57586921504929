import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { RuleService } from '@lcms-services';
import { FormBuilderComponent } from '@microsec/components';
import { IMPORT_LABEL } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, finalize } from 'rxjs';

const FORM_PARAMS = {
  FILE: 'file',
  FILE_JSON: 'file_json',
  NAME: 'name',
  DESCRIPTION: 'description',
  RULESET_TYPE: 'ruleset_type',
  ENABLED: 'enabled',
  PROJECT_ID: 'project_id',
  RULESET_AND_RULES: 'ruleset_and_rules',
  RULESET: 'ruleset',
  RULES: 'rules',
};

@Component({
  selector: 'app-ruleset-import-form',
  templateUrl: './ruleset-import-form.component.html',
  styleUrls: ['./ruleset-import-form.component.scss'],
})
export class RulesetImportFormComponent extends BaseComponent implements AfterViewInit {
  type = '';

  ruleset: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  IMPORT_LABEL = IMPORT_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ruleSrv: RuleService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.type = this.dialogConfig.data?.type;
    this.ruleset = this.dialogConfig.data?.ruleset;
    this.initForm();
  }

  initForm() {
    const fileField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.FILE,
      label: 'Upload Ruleset',
      field: 'file',
      fieldInfo: 'Ruleset file',
      required: true,
    } as FormItem);
    const fields: FormItem[] = [
      fileField,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FILE_JSON,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        fieldInfo: 'Name',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'input',
        fieldInfo: 'Description',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENABLED,
        label: 'Enabled',
        field: 'checkbox',
        fieldInfo: 'Is Enabled',
        defaultValue: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULESET_TYPE,
        defaultValue: this.type,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        defaultValue: this.breadcrumbConfig?.projectId,
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;

    fileField.uploadEvent?.subscribe((event) => {
      this.form.isLoading = true;
      this.getValidatedFile(event).subscribe((validatedFile: any) => {
        this.form.setControlValue(FORM_PARAMS.FILE, validatedFile);
        this.form.isLoading = false;
      });
    });
  }

  /**
   * Validate the file with correct format
   * @param event
   * @returns
   */
  private getValidatedFile(event: any) {
    return new Observable((observe) => {
      if (!!event.target && !!event.target.files && !!event.target.files.length) {
        const file = (event.target.files as FileList).item(0) as File;
        const reader = new FileReader();
        reader.onload = (loadedFile) => {
          try {
            const textContent: string = (loadedFile.target?.result as string) || '{}';
            const object = JSON.parse(textContent);
            this.form.setControlValue(FORM_PARAMS.FILE_JSON, object);
            const ruleset = object[FORM_PARAMS.RULESET_AND_RULES].ruleset;
            this.form.setControlValue(FORM_PARAMS.NAME, ruleset[FORM_PARAMS.NAME]);
            this.form.setControlValue(FORM_PARAMS.DESCRIPTION, ruleset[FORM_PARAMS.DESCRIPTION]);
            this.form.setControlValue(FORM_PARAMS.ENABLED, ruleset[FORM_PARAMS.ENABLED]);
            observe.next(file);
          } catch {
            this.showErrorMessage(`The file's format is not correct`);
            this.form.setControlValue(FORM_PARAMS.FILE_JSON, '');
            observe.next(null);
          }
        };
        reader.readAsText(file);
      } else {
        this.form.setControlValue(FORM_PARAMS.FILE_JSON, '');
        observe.next(null);
      }
    });
  }

  /**
   *
   * @param closeDialog
   */
  submit(closeDialog: (result: any) => void) {
    const rulesetFormData = this.form.getRawValue();
    delete rulesetFormData[FORM_PARAMS.FILE];
    delete rulesetFormData[FORM_PARAMS.FILE_JSON];
    const payload = this.form.getControlValue(FORM_PARAMS.FILE_JSON);
    payload[FORM_PARAMS.RULESET_AND_RULES][FORM_PARAMS.RULESET] = rulesetFormData;
    ((payload[FORM_PARAMS.RULESET_AND_RULES][FORM_PARAMS.RULES] as any[]) || []).forEach((rule) => {
      delete rule.rule_id;
    });
    this.ruleSrv
      .importRuleset(payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.showSuccessMessage(`Imported ${this.type} Ruleset ${rulesetFormData?.[FORM_PARAMS.NAME]} successfully`);
          closeDialog(rs);
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }
}
