<microsec-layout-table
  #lt
  itemName="GPG Key"
  [shouldShowCreateButton]="selectedKeyring?.name !== MICROSEC_DEFAULT_KEYRING"
  (openFormEvent)="openKeyForm()"
  [cols]="cols"
  [values]="values"
  [(selectedItem)]="selectedKey"
  [actionMenuItems]="actionMenuItems"
  [isLoading]="isLoading"
  [cellTemplate]="cellTemplate"
  [detailsTemplate]="detailsTemplate"
  [hasSidebarDetails]="true"
  (getItemsEvent)="getGPGKeys()" />

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Type -->
    @case ('type') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'kms-key-type' }}
      </microsec-truncate-text>
    }
    <!-- Key Algorithm -->
    @case ('key_algorithm') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'kms-key-algorithm' }}
      </microsec-truncate-text>
    }
    <!-- Created Date -->
    @case ('created') {
      <microsec-truncate-text>
        {{ rowData[col.field] | date: PIPE_DATETIME }}
      </microsec-truncate-text>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
  }
</ng-template>

<ng-template #detailsTemplate>
  <microsec-details-panel [title]="selectedKey?.label" (collapseEvent)="selectedKey = null">
    <app-gpg-key-details [selectedKeyring]="selectedKeyring" [selectedKey]="selectedKey" />
  </microsec-details-panel>
</ng-template>
