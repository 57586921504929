import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@lcms-shared';
import { SharedProjectFormComponent } from './shared-project-form.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SharedProjectFormComponent],
  exports: [SharedProjectFormComponent],
})
export class SharedProjectFormModule {}
