<microsec-form-builder [isPage]="true" #fb [fields]="fields" [shouldShowActionButtons]="false" />

<div class="grid">
  <div class="col-12">
    @if (app === MICROSEC_LCMS_CA) {
      <label class="text-data font-bold">Associated CA Certificate(s)</label>
    }
    @if (app === MICROSEC_LCMS_RA) {
      <label class="text-data font-bold">Associated Device Certificate(s)</label>
    }
  </div>
  <div class="col-12">
    <microsec-layout-table
      itemName="Certificate"
      [cols]="cols"
      [values]="values"
      (getItemsEvent)="app === MICROSEC_LCMS_CA ? getCertificates() : app === MICROSEC_LCMS_RA ? getDeviceCertificates() : null"
      [actionMenuItems]="actionMenuItems"
      [cellTemplate]="cellTemplate"
      [isLoading]="isLoading" />

    <ng-template let-col="col" let-rowData="rowData" #cellTemplate>
      @switch (col.field) {
        <!-- Issue Date -->
        @case ('not_before') {
          <span [title]="rowData[col.field] | date: PIPE_DATETIME">
            {{ rowData[col.field] | date: PIPE_DATETIME }}
          </span>
        }
        <!-- Expiry Date -->
        @case ('not_after') {
          <span [title]="rowData[col.field] | date: PIPE_DATETIME">
            {{ rowData[col.field] | date: PIPE_DATETIME }}
          </span>
        }
        @default {
          <microsec-truncate-text>
            {{ rowData[col.field] }}
          </microsec-truncate-text>
        }
      }
    </ng-template>
  </div>
</div>
