<microsec-loading-overlay [isLoading]="!!isLoading">
  <microsec-details-panel [title]="selectedKeyring?.name" [buttons]="actionButtons" (collapseEvent)="unselectKeyring()">
    <p-tabView [scrollable]="true" styleClass="microsec-dashboard-tab" [(activeIndex)]="activeIndex">
      <p-tabPanel header="Overview">
        <app-keyring-overview [selectedKeyring]="selectedKeyring" />
      </p-tabPanel>
      <p-tabPanel header="Generic Keys">
        <app-keyring-generic-keys [selectedKeyring]="selectedKeyring" [keyPurpose]="keyPurposeAlgorithm" />
      </p-tabPanel>
      @if (selectedKeyring?.type === KEYRING_TYPE_VALUES.FILESYSTEM) {
        <p-tabPanel header="GPG Keys">
          <app-keyring-gpg-keys [selectedKeyring]="selectedKeyring" />
        </p-tabPanel>
      }
    </p-tabView>
  </microsec-details-panel>
</microsec-loading-overlay>
