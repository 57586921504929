import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { DEVICE_MANAGEMENT_FEATURES } from '@lcms-constants';
import { BaseComponent } from '@lcms-components';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
})
export class MetricsComponent extends BaseComponent implements OnInit, OnDestroy {
  isFeatured = false;

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.TELEMETRY)) {
      if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
        this.isFeatured = true;
      }
    } else {
      this.navigateToNotFoundPage();
    }
  }
}
