<div class="grid">
  <div class="col-12">
    <div class="grid justify-content-between">
      <div>
        @if (permissions[SCOPE.PROJECT][USER_ROLE.READ_ONLY]) {
          <p-button severity="primary" size="small" label="Create Envelope" (onClick)="openEnvelopeForm()" />
        }
      </div>
      <div></div>
    </div>
  </div>
  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        [cols]="cols"
        [values]="values"
        [cardBackground]="true"
        [selectable]="false"
        [cellTemplate]="cellTemplate"
        itemName="Envelope"
    /></microsec-card>
  </div>
</div>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Actions -->
    @case ('actions') {
      @if (permissions[SCOPE.PROJECT][USER_ROLE.READ_ONLY]) {
        @if (!!hasShareFeature) {
          <p-button
            severity="success"
            size="small"
            icon="fas fa-share"
            styleClass="mr-2"
            [pTooltip]="getShareTooltip(rowData)"
            tooltipPosition="bottom"
            appendTo="body"
            (onClick)="openEnvelopeSharingForm(rowData)"
            [disabled]="!checkIsOwner(rowData)" />
        }
        <p-button
          severity="info"
          size="small"
          icon="fas fa-download"
          pTooltip="Download"
          tooltipPosition="bottom"
          appendTo="body"
          (onClick)="downloadSignedEnvelope(rowData)" />
      }
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
  }
</ng-template>
