import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DEVICE_PROTOCOLS, DEVICE_PROTOCOL_VALUES } from '@lcms-constants';
import { DeviceService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { validatorForbiddenCharacter, validatorTrimRequired } from '@microsec/validators';
import { SHORT_TEXT_MAX_LENGTH } from '@microsec/constants';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

const FORM_PARAMS = {
  DEVICE_NAME: 'device_name',
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  VPN_ENABLE: 'vpn_enable',
  USES_OTP: 'uses_otp',
  IS_MANUAL: 'is_manual',
  OTP: 'otp',
  PROTOCOL: 'protocol',
};

@Component({
  selector: 'app-virtual-device-form',
  templateUrl: './virtual-device-form.component.html',
  styleUrls: ['./virtual-device-form.component.scss'],
})
export class VirtualDeviceFormComponent extends BaseComponent implements OnInit {
  isLoading = false;

  FORM_PARAMS = FORM_PARAMS;

  DEVICE_PROTOCOL_VALUES = DEVICE_PROTOCOL_VALUES;

  protocols: any[] = [];

  deviceForm!: FormGroup;

  initValue = {
    [FORM_PARAMS.DEVICE_NAME]: '',
    [FORM_PARAMS.PROJECT_ID]: null,
    [FORM_PARAMS.VPN_ENABLE]: true,
    [FORM_PARAMS.USES_OTP]: false,
    [FORM_PARAMS.OTP]: '',
    [FORM_PARAMS.PROTOCOL]: null,
  };

  SHORT_TEXT_MAX_LENGTH = SHORT_TEXT_MAX_LENGTH;

  constructor(
    private fb: FormBuilder,
    private deviceSrv: DeviceService,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.protocols = this.util.cloneObjectArray(DEVICE_PROTOCOLS).map((p) => {
      const result: any = { ...p };
      result.disabled = !this.checkPKIManagementFeatureEnabled(p.feature);
      return result;
    });
    // Init the device form
    this.deviceForm = this.fb.group({
      [FORM_PARAMS.DEVICE_NAME]: [
        this.initValue[FORM_PARAMS.DEVICE_NAME],
        Validators.compose([validatorForbiddenCharacter(['/', '#', ' ', '+']), validatorTrimRequired]),
      ],
      [FORM_PARAMS.ORGANIZATION_ID]: [this.breadcrumbConfig?.organizationId, Validators.required],
      [FORM_PARAMS.PROJECT_ID]: [this.breadcrumbConfig?.projectId, Validators.required],
      [FORM_PARAMS.VPN_ENABLE]: this.initValue[FORM_PARAMS.VPN_ENABLE],
      [FORM_PARAMS.PROTOCOL]: this.initValue[FORM_PARAMS.PROTOCOL],
      [FORM_PARAMS.USES_OTP]: this.initValue[FORM_PARAMS.USES_OTP],
      [FORM_PARAMS.OTP]: this.initValue[FORM_PARAMS.OTP],
      [FORM_PARAMS.IS_MANUAL]: [false, Validators.required],
    });
    // If otp is enabled, the field is required and only allow a number with 6 digits, else no validation
    this.deviceForm.get(FORM_PARAMS.USES_OTP)?.valueChanges.subscribe((value: any) => {
      this.deviceForm
        .get(FORM_PARAMS.OTP)
        ?.setValidators(value ? Validators.compose([Validators.pattern('[0-9]{6}'), Validators.required]) : Validators.nullValidator);
      if (!value) {
        this.deviceForm.get(FORM_PARAMS.OTP)?.setErrors(null);
      }
    });
  }

  /**
   * Create a new device
   */
  onSubmit() {
    window.microsec.log('Device Info: ', this.deviceForm.value);
    if (!this.protocols.map((p) => p.value).includes(this.deviceForm.value[FORM_PARAMS.PROTOCOL])) {
      this.showErrorMessage('Invalid protocol');
      return;
    }
    this.isLoading = true;
    this.deviceSrv
      .createDevice(this.deviceForm.getRawValue())
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          window.microsec.log(res);
          this.showSuccessMessage('The device is created successfully.');
          this.dialogRef.close(true);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }
}
