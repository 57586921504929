import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { DELETE_LABEL, DOWNLOAD_LABEL } from '@microsec/constants';
import { DetailsPanelButtonModel } from '@microsec/models';

@Component({
  selector: 'app-firmware-info',
  templateUrl: './firmware-info.component.html',
  styleUrls: ['./firmware-info.component.scss'],
})
export class FirmwareInfoComponent extends BaseComponent implements AfterContentInit {
  @Input() isLoading = false;

  _firmware: any = null;

  get firmware() {
    return this._firmware;
  }

  @Input() set firmware(value: any) {
    this._firmware = value;
    this.prepareActionButtons();
  }

  @Output() firmwareChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() actionEvent: EventEmitter<any> = new EventEmitter<any>();

  actionButtons: DetailsPanelButtonModel[] = [];

  async ngAfterContentInit() {
    this.prepareConfigs();
    this.prepareActionButtons();
  }

  /**
   * Unselect firmware
   */
  unselectFirmware() {
    this.firmwareChange.emit(null);
  }

  /**
   * Prepare action buttons
   */
  prepareActionButtons() {
    this.actionButtons = [
      {
        label: DOWNLOAD_LABEL,
        command: () => {
          this.actionEvent.emit('download');
        },
      },
      {
        label: DELETE_LABEL,
        command: () => {
          this.actionEvent.emit('delete');
        },
      },
    ];
  }

  /**
   * Refresh firmware
   */
  refreshFirmware() {
    this.actionEvent.emit('refresh');
  }
}
