<p-panel #form [showHeader]="false">
  <div class="form" style="padding: 0px">
    <div class="grid p-border-top scroll-panel">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="grid">
          <label class="col-12"> Save the request ID to get information about user certificate enrollment status. </label>
          <div class="col-12">
            <div class="p-inputgroup">
              <input type="text" pInputText [(ngModel)]="requestId" disabled />
              <button type="button" pButton title="Copy" icon="pi pi-copy" class="p-button-secondary" (click)="copyValue()"></button>
            </div>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="grid flex justify-content-end action-buttons">
      <p-button severity="secondary" (onClick)="dialogRef.close()" label="OK" />
    </div>
  </div>
</p-panel>
