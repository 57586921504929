import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { API } from '../api';
import { IRaEnrollmentService } from './IRaEnrollmentService';

const API_GENERIC = `${API.RA_ENROLLMENT}/ca/{caServerId}~{caId}/template/{templateId}/generic`;

const API_CERTIFICATES = `${API.RA_ENROLLMENT}/certificates/status/self_enroll/{requestId}`;

@Injectable({
  providedIn: 'root',
})
export class RaEnrollmentService implements IRaEnrollmentService {
  constructor(private http: HttpClient) {}

  createUserCertificateEnrollmentRequest(payload: any): Observable<any> {
    const caServerId = payload.ca_server_id;
    const caId = payload.ca_id;
    const caTemplateId = payload.ca_template_id;
    delete payload.ca_server_id;
    delete payload.ca_id;
    delete payload.ca_template_id;
    return this.http
      .post(`${API_GENERIC.replace('{caServerId}', caServerId).replace('{caId}', caId).replace('{templateId}', caTemplateId)}/csr`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createUserCertificateSelfEnrollmentRequest(payload: any): Observable<any> {
    const caServerId = payload.ca_server_id;
    const caId = payload.ca_id;
    const caTemplateId = payload.ca_template_id;
    delete payload.ca_server_id;
    delete payload.ca_id;
    delete payload.ca_template_id;
    return this.http
      .post(
        `${API_GENERIC.replace('{caServerId}', caServerId).replace('{caId}', caId).replace('{templateId}', caTemplateId)}/csr/self_enroll`,
        payload,
      )
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  checkUserCertificateSelfEnrollmentStatus(requestId: any): Observable<any> {
    return this.http
      .get(`${API_CERTIFICATES.replace('{requestId}', requestId)}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  generateCSR(caServerId: any, caId: any, templateId: any, payload: any): Observable<any> {
    let prefixPath = `/ca/${caServerId}~${caId}`;
    if (!!templateId) {
      prefixPath += `/template/${templateId}`;
    }
    return this.http
      .post(`${API.RA_ENROLLMENT}${prefixPath}/generic/csr-generation`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createCSRCertificate(caServerId: any, caId: any, templateId: any, format: any, payload: any): Observable<any> {
    let prefixPath = `/ca/${caServerId}~${caId}`;
    if (!!templateId) {
      prefixPath += `/template/${templateId}`;
    }
    const params = !!format ? `?format=${format}` : '';
    return this.http
      .post(`${API.RA_ENROLLMENT}${prefixPath}/generic/csr${params}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
