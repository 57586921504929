<div class="grid">
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN] ? leftTemplate : null">
      <ng-template #leftTemplate>
        <p-button
          severity="primary"
          [rounded]="true"
          label="Create Organization"
          icon="pi pi-plus"
          (onClick)="openOrganizationForm()"
          [disabled]="!checkOrganizationQuota"
          [pTooltip]="!!checkOrganizationQuota ? '' : 'Maximum number of organizations reached'" />
      </ng-template>
    </microsec-common-toolbar>
  </div>

  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [selectable]="false"
        itemName="Organization"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getOrganizations()" />
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    @case ('name') {
      <label
        class="link overflow-hidden white-space-nowrap text-overflow-ellipsis"
        [title]="rowData[col.field]"
        (click)="goToOrganization($event, rowData.id)">
        {{ rowData[col.field] }}
      </label>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
