import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { BaseComponent } from '@lcms-components';
import { REPOSITORY_MANAGEMENT_CHILDREN } from './repository-management.config';

@Component({
  selector: 'app-repository-management',
  templateUrl: './repository-management.component.html',
  styleUrls: ['./repository-management.component.scss'],
})
export class RepositoryManagementComponent extends BaseComponent implements OnInit, OnDestroy {
  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
      const tabItems = this.util.cloneObjectArray(REPOSITORY_MANAGEMENT_CHILDREN).filter((p) => !!p.label);
      this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  override ngOnDestroy(): void {
    this.cleanup({ shouldClearTabItems: true });
  }
}
