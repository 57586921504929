<microsec-form-builder
  #fb
  [fields]="fields"
  [shouldShowActionButtons]="true"
  [submitButtonLabel]="SAVE_CHANGES_LABEL"
  (submitEvent)="onSubmit($event)"
  [additionalConditions]="!!isLabelChanges" />

<ng-template #labelTemplate let-data>
  <div class="flex">
    <i class="fas fa-square-full mr-2" [ngStyle]="{ color: data.color }"></i>
    <microsec-truncate-text class="text-data">
      {{ data.name }}
    </microsec-truncate-text>
  </div>
</ng-template>
