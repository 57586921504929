<microsec-form-builder
  #fb
  [fields]="fields"
  [shouldShowActionButtons]="true"
  [submitButtonLabel]="'Submit Request'"
  [submitButtonStyleClass]="'p-button-primary'"
  [cancelButtonLabel]="''"
  [isPage]="true"
  [leftActionArea]="backButton"
  actionButtonsGroupClass="justify-content-between mt-4"
  (submitEvent)="onSubmit()" />

<ng-template #backButton>
  <div class="text-left align-self-center">
    <div class="link" (click)="goTo('home')">&lt; Back</div>
  </div>
</ng-template>
