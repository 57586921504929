import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { KmsService } from '@lcms-services';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, CREATE_SUCCESS } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

const FORM_PARAMS = {
  LABEL: 'label',
  KEY_ALGORITHM: 'algorithm',
  PURPOSE: 'purpose',
  DESCRIPTION: 'description',
};

@Component({
  selector: 'app-generic-key-form',
  templateUrl: './generic-key-form.component.html',
  styleUrls: ['./generic-key-form.component.scss'],
})
export class GenericKeyFormComponent extends BaseComponent implements AfterViewInit {
  keyring: any = null;

  keyPurpose: any = null;

  fields: FormItem[] = [];

  FORM_PARAMS = FORM_PARAMS;

  CREATE_LABEL = CREATE_LABEL;

  @ViewChild('fb') form!: FormBuilderComponent;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.keyring = this.dialogConfig?.data?.keyring;
    this.keyPurpose = this.dialogConfig?.data?.keyPurpose;
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: `Create generic key within keyring.`,
        field: 'text',
        labelStyleClass: 'text-md',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Label',
        field: 'input',
        fieldInfo: 'Label',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PURPOSE,
        label: 'Purpose',
        field: 'dropdown',
        options: (
          Object.keys(this.keyPurpose).map((key) => {
            return { label: key, value: key };
          }) as any[]
        ).filter((option) => (Object.keys(this.keyPurpose[option.value]?.algorithms || {}) as any[]).length > 0),
        placeholder: 'Select Key Purpose',
        fieldInfo: 'Key Purpose',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_ALGORITHM,
        label: 'Key Algorithm',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a key algorithm',
        fieldInfo: 'Key algorithm',
        hidden: true,
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        field: 'text',
        label: '',
        hidden: true,
      }),
    ];
    this.fields = fields;
    this.setupChangeEvents();
  }

  setupChangeEvents() {
    // Key Purpose
    this.form.setChangeEvent(FORM_PARAMS.PURPOSE, (purpose) => {
      this.form.setControlVisibility(FORM_PARAMS.DESCRIPTION, false);
      this.form.setControlVisibility(FORM_PARAMS.KEY_ALGORITHM, true);
      this.form.setControlValue(FORM_PARAMS.KEY_ALGORITHM, null);
      const algorithmField = this.fields.find((p) => p.name === FORM_PARAMS.KEY_ALGORITHM);
      if (!!algorithmField) {
        algorithmField.options = Object.entries((this.keyPurpose[purpose]?.algorithms as any) || {})
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_key, algo]: [string, any]) => {
            return !!(algo.supported_keyring_types as string[]).includes(this.keyring?.type);
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .map(([key, _algo]: [string, any]) => {
            return { label: key, value: key };
          });
      }
    });

    //Key Algorithm
    this.form.setChangeEvent(FORM_PARAMS.KEY_ALGORITHM, (algorithm) => {
      this.form.setControlVisibility(FORM_PARAMS.DESCRIPTION, !!algorithm);
      const descriptionField = this.fields.find((p) => p.name === FORM_PARAMS.DESCRIPTION);
      if (!!descriptionField && !!algorithm) {
        descriptionField.label =
          'This will create ' +
          ((this.keyPurpose[this.form.getControlValue(FORM_PARAMS.PURPOSE) as string].algorithms?.[algorithm].description as string) || '');
      }
    });
  }

  /**
   * Submit the form
   * @param callback
   */
  onSubmit(callback: (rs: any) => void) {
    const payload = this.util.cloneDeepObject(this.form?.getRawValue());
    delete payload[FORM_PARAMS.DESCRIPTION];
    delete payload[FORM_PARAMS.PURPOSE];
    this.kmsSrv
      .createGenericKey(this.keyring?.id, payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const message = CREATE_SUCCESS.replace('{0}', 'Generic Key');
          this.showSuccessMessage(message);
          callback(rs);
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
