import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { WORKFLOWS_CHILDREN } from '@microsec/module-workflows';

@Component({
  selector: 'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.scss'],
})
export class WorkflowsComponent extends BaseComponent implements OnInit, OnDestroy {
  async ngOnInit() {
    await this.prepareConfigs();
    const checkedItems = this.util.cloneObjectArray(WORKFLOWS_CHILDREN).filter((p) => !!p.label);
    checkedItems.forEach((item) => {
      item.visible = true;
    });
    this.store.dispatch(new fromLayoutActions.UpdateTabItems(checkedItems));
  }

  override ngOnDestroy(): void {
    this.cleanup({ shouldClearTabItems: true });
  }
}
