// import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { USER_SETTINGS_CHILDREN as COMMON_USER_SETTINGS_CHILDREN } from '@lcms-products';
// import { USER_SETTINGS_CONSTANTS } from '@lcms-shared';
import { ILayoutItem } from '@microsec/interfaces';
// import { InboxComponent } from './notifications/inbox/inbox.component';

export const USER_SETTINGS_CHILDREN: ILayoutItem[] = [
  ...COMMON_USER_SETTINGS_CHILDREN,
  // {
  //   group: USER_SETTINGS_GROUPS.NOTIFICATIONS,
  //   route: USER_SETTINGS_CONSTANTS.INBOX.ROUTE,
  //   icon: faInbox,
  //   label: USER_SETTINGS_CONSTANTS.INBOX.LABEL,
  //   component: InboxComponent,
  //   visible: false,
  // },
];
