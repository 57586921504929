import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RaEnrollmentService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { finalize, Observable } from 'rxjs';
import { ResultComponent } from './result/result.component';

const FORM_PARAMS = {
  CSR: 'csr',
  CSR_CONTENT: 'csr_content',
  // SEND_EMAIL: 'send_email',
  // EMAIL: 'email',
  /** ------------------------------------------------------ */
  CA_SERVER_ID: 'ca_server_id',
  CA_ID: 'ca_id',
  CA_TEMPLATE_ID: 'ca_template_id',
  /** ------------------------------------------------------ */
  ENTITY_TYPE: 'entity_type',
  IS_VIRTUAL: 'is_virtual',
  USES_OTP: 'uses_otp',
  TYPE: 'type',
  ENROLLMENT_PROTOCOL: 'enrolment_protocol',
  IS_MANUAL: 'is_manual',
};

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
})
export class RequestComponent extends BaseComponent implements OnInit {
  @Input() project: any;

  @Input() page: string;

  @Output() pageChange: EventEmitter<string> = new EventEmitter<string>();

  fields: FormItem[] = [];

  FORM_PARAMS = FORM_PARAMS;

  @ViewChild('fb') form!: FormBuilderComponent;

  constructor(private raEnrollmentSrv: RaEnrollmentService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.initForm();
    const userEnrollment = this.project?.user_entity_enrollment_config;
    Object.keys(userEnrollment).forEach((key) => {
      if (key.indexOf('ca_') > -1) {
        userEnrollment[key.replace('ca_', '')] = userEnrollment[key];
      }
    });
    this.form.patchValue(userEnrollment);
  }

  /**
   * Initialize form
   */
  initForm() {
    const csrField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.CSR,
      label: 'CSR',
      field: 'file',
      acceptedFileTypes: ['.pem', '.csr'],
      uploadEvent: new EventEmitter(),
      fieldInfo: 'CSR',
      required: true,
    } as FormItem);
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: 'Upload your CSR to create a user certificate enrollment request.',
        field: 'text',
      } as FormItem),
      csrField,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CSR_CONTENT,
        hidden: true,
      } as FormItem),
      // Object.assign(new FormItem(),{
      //   name: FORM_PARAMS.SEND_EMAIL,
      //   hidden:true,
      // }as FormItem),
      // Object.assign(new FormItem(),{
      //   name: FORM_PARAMS.EMAIL,
      //   hidden:true,
      // }as FormItem),
      /** ------------------------------------------------------ */
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_SERVER_ID,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_ID,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_TEMPLATE_ID,
        hidden: true,
      } as FormItem),
      /** ------------------------------------------------------ */
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENTITY_TYPE,
        hidden: true,
        defaultValue: 'user',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_VIRTUAL,
        hidden: true,
        defaultValue: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.USES_OTP,
        hidden: true,
        defaultValue: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        hidden: true,
        defaultValue: 'enroll',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENROLLMENT_PROTOCOL,
        hidden: true,
        defaultValue: 'generic',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_MANUAL,
        hidden: true,
        defaultValue: true,
      } as FormItem),
    ];
    this.fields = fields;
    // this.form.setChangeEvent(FORM_PARAMS.SEND_EMAIL, (value) => {
    //   this.form.setControlValidators(FORM_PARAMS.EMAIL, !!value ? VALIDATOR_TYPE.REQUIRED : null);
    // });
    csrField.uploadEvent?.subscribe((event) => {
      this.form.isLoading = true;
      this.getValidatedFile(event).subscribe((validatedFile: any) => {
        this.form.setControlValue(FORM_PARAMS.CSR, validatedFile);
        this.form.isLoading = false;
      });
    });
  }

  /**
   * Validate the file with correct format
   * @param event
   * @returns
   */
  private getValidatedFile(event: any) {
    return new Observable((observe) => {
      if (!!event.target && !!event.target.files && !!event.target.files.length) {
        const file = (event.target.files as FileList).item(0) as File;
        const reader = new FileReader();
        reader.onload = (loadedFile) => {
          this.form.setControlValue(FORM_PARAMS.CSR_CONTENT, loadedFile.target?.result);
          observe.next(file);
        };
        reader.readAsText(file);
      } else {
        this.form.setControlValue(FORM_PARAMS.CSR_CONTENT, '');
        observe.next(null);
      }
    });
  }

  /**
   * Go to a specific page
   * @param page
   */
  goTo(page: string) {
    this.pageChange.emit(page);
  }

  /**
   * Submit form
   */
  onSubmit() {
    this.form.isLoading = true;
    const payload = this.util.cloneDeepObject(this.form.getRawValue());
    payload[FORM_PARAMS.CSR] = this.form.getControlValue(FORM_PARAMS.CSR_CONTENT);
    delete payload[FORM_PARAMS.CSR_CONTENT];

    this.raEnrollmentSrv
      .createUserCertificateSelfEnrollmentRequest(payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          if (!!rs?.transaction_id) {
            this.showSuccessMessage('Created user certificate enrollment request successfully');
            const dialog = this.dialogSrv.open(ResultComponent, {
              data: {
                requestId: rs?.transaction_id,
              },
              header: 'Create User Certificate Enrollment Request',
              width: '800px',
              height: 'min-content',
              closeOnEscape: true,
            });
            dialog.onClose.subscribe(() => {
              this.goTo('home');
            });
          } else {
            this.showSuccessMessage('Cannot create user certificate enrollment request');
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
          this.form.showServerErrorMessage(err);
        },
      });
  }
}
