import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@lcms-shared';
import { SharedOrganizationFormComponent } from './shared-organization-form.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SharedOrganizationFormComponent],
  exports: [SharedOrganizationFormComponent],
})
export class SharedOrganizationFormModule {}
