<microsec-loading-overlay [isLoading]="isLoading">
  <microsec-form-builder
    #fb
    [isPage]="!!isPage"
    [fields]="fields"
    [submitButtonLabel]="''"
    [shouldShowActionButtons]="!isPage"
    [rightActionArea]="rightActionArea" />
</microsec-loading-overlay>

<ng-template #rightActionArea>
  @if (!!cert.pem) {
    <p-button severity="success" [label]="DOWNLOAD_LABEL" (onClick)="downloadCertificate()" />
  }
</ng-template>
