import { CommonTableComponent } from '@microsec/components';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PKI_MANAGEMENT_FEATURES } from '@lcms-constants';
import { KmsService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { ConfirmationDialogConfig, CommonToolbarConfiguration, ActionMenuItem, CommonToolbarResult } from '@microsec/models';
import { DELETE_LABEL } from '@microsec/constants';
import { CryptokenManagerFormComponent } from './cryptoken-manager-form/cryptoken-manager-form.component';
import { BehaviorSubject, finalize } from 'rxjs';

const FIELDS = {
  MANAGER_NAME: 'Manager Name',
  ADDRESS: 'Address',
  STATUS: 'Status',
};

@Component({
  selector: 'app-cryptoken-managers',
  templateUrl: './cryptoken-managers.component.html',
  styleUrls: ['./cryptoken-managers.component.scss'],
})
export class CryptokenManagersComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols: any[] = [
    { field: 'label', header: FIELDS.MANAGER_NAME, width: 12, frozen: true },
    { field: 'address', header: FIELDS.ADDRESS, width: 15 },
    { field: 'connection_status', header: FIELDS.STATUS, width: 10 },
  ];

  selectedManager: any = null;

  values: any[] = [];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search...',
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    public el: ElementRef,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.CRYPTOKEN_MANAGEMENT)) {
      this.selectedColFields = (this.cols || []).map((col) => col.field);
      this.actionsMenuItems = [
        {
          label: 'Edit',
          icon: 'fas fa-edit',
          command: ({ rowData }: any) => this.openCryptokenManagerForm(rowData),
        },
        {
          label: 'Delete',
          icon: 'fas fa-trash',
          command: ({ rowData }: any) => this.confirmDeletion(rowData),
        },
      ];
      this.handleFilterObjUpdate();
      this.getCryptokenManagers();
    } else {
      this.navigateToNotFoundPage();
    }
  }

  /**
   * Update filter
   */
  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values.search || '';
      }
    });
  }

  /**
   * Unselect manager
   * @param event
   * @param datatable
   * @returns
   */
  unselectManager(event: any, datatable: any) {
    if (event?.target !== datatable) {
      return;
    }
    this.selectedManager = null;
  }

  /**
   * Get the list of cryptoken managers
   */
  getCryptokenManagers() {
    this.isLoading = true;
    this.kmsSrv
      .getCryptokenManagers(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.values = rs as any[];
          if (!!this.selectedManager) {
            this.selectedManager = this.values.find((p) => p.id === this.selectedManager.id);
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Open cryptoken manager form for create/edit
   * @param cryptokenManager
   */
  openCryptokenManagerForm(cryptokenManager: any = null) {
    const dialogRef = this.dialogSrv.open(CryptokenManagerFormComponent, {
      data: { cryptTokenManager: cryptokenManager },
      header: `${!cryptokenManager ? 'Connect to' : 'Edit'} Cryptographic Token Manager`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        this.getCryptokenManagers();
      }
    });
  }

  /**
   * Confirm cryptoken manager deletion
   * @param cryptTokenManager
   */
  confirmDeletion(cryptTokenManager: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Cryptographic Token Manager',
      object: cryptTokenManager,
      objectFieldName: 'label',
      acceptRequest: this.kmsSrv.deleteCryptokenManager(cryptTokenManager.id),
      next: () => {
        this.showSuccessMessage(`Deleted cryptographic token manager ${cryptTokenManager.label} successfully`);
        this.selectedManager = null;
        this.getCryptokenManagers();
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Refresh the selected cryptoken manager
   * @param managerId
   */
  refreshCryptokenManager(managerId: any) {
    this.isLoading = true;
    this.kmsSrv
      .getCryptokenManager(managerId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (manager: any) => {
          this.values = this.util.cloneObjectArray(this.values).map((value) => {
            if (value?.id === manager?.id) {
              value = manager;
            }
            return value;
          });
          if (!!this.selectedManager) {
            this.selectedManager = this.values.find((p) => p.id === manager?.id);
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }
}
