import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QRCodeModule } from 'angularx-qrcode';

import { ComponentsModule } from '@microsec/components';
import { DirectivesModule } from '@microsec/directives';
import { PipesModule } from '@microsec/pipes';
import { ComponentsModule as LcmsComponentsModule } from '@lcms-components';
import { PipesModule as LcmsPipesModule } from '@lcms-pipes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    QRCodeModule,
    ComponentsModule,
    DirectivesModule,
    PipesModule,
    LcmsComponentsModule,
    LcmsPipesModule,
  ],
  exports: [RouterModule, FontAwesomeModule, QRCodeModule, ComponentsModule, DirectivesModule, PipesModule, LcmsComponentsModule, LcmsPipesModule],
})
export class SharedModule {
  static forRoot(environment?: any): ModuleWithProviders<SharedModule> {
    if (!!environment) {
      return {
        ngModule: SharedModule,
        providers: [
          {
            provide: 'environment', // you can also use InjectionToken
            useValue: environment,
          },
        ],
      };
    }
    return { ngModule: SharedModule };
  }
}
