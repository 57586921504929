import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { API } from '../api';
import { IKmsService } from './IKmsService';

const API_CRYPTOKEN_MANAGER = `${API.KMS}/cryptoken-managers`;
const API_CRYPTOKEN = `${API_CRYPTOKEN_MANAGER}/{0}/tokens`;

const API_KEYRING = `${API.KMS}/keyrings`;

const API_GENERIC_KEYS = `${API_KEYRING}/{0}/keys`;

const API_GPG_KEYS = `${API_KEYRING}/{0}/gpg-keys`;

const API_KEY_PURPOSE = `${API.KMS}/key-purposes-algorithms`;

@Injectable({
  providedIn: 'root',
})
export class KmsService implements IKmsService {
  constructor(private http: HttpClient) {}

  /**
   * ========================== CRYPTOGRAPHIC TOKEN MANAGER ==========================
   */

  getCryptokenManagers(projectId: any): Observable<any[]> {
    return this.http
      .get<any>(`${API_CRYPTOKEN_MANAGER}?project_id=${projectId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getCryptokenManager(id: any): Observable<any[]> {
    return this.http.get<any>(`${API_CRYPTOKEN_MANAGER}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createCryptokenManager(payload: any): Observable<any> {
    return this.http.post<any>(`${API_CRYPTOKEN_MANAGER}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateCryptokenManager(id: any, payload: any): Observable<any> {
    return this.http.put<any>(`${API_CRYPTOKEN_MANAGER}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteCryptokenManager(id: any): Observable<any> {
    return this.http.delete<any>(`${API_CRYPTOKEN_MANAGER}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * ========================== CONNECTED TOKEN ==========================
   */

  getCryptokens(managerId: any): Observable<any[]> {
    return this.http.get<any>(`${API_CRYPTOKEN.replace('{0}', managerId)}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  loginCryptoken(managerId: any, tokenId: any, payload: any): Observable<any> {
    return this.http
      .post<any>(`${API_CRYPTOKEN.replace('{0}', managerId)}/${tokenId}/login`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  logoutCryptoken(managerId: any, tokenId: any): Observable<any> {
    return this.http
      .post<any>(`${API_CRYPTOKEN.replace('{0}', managerId)}/${tokenId}/logout`, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * ========================== KEYRING ==========================
   */

  getKeyrings(projectId: any): Observable<any> {
    return this.http.get<any>(`${API_KEYRING}?project_id=${projectId}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getKeyring(keyringId: any): Observable<any> {
    return this.http.get<any>(`${API_KEYRING}/${keyringId}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createKeyring(payload: any): Observable<any> {
    return this.http.post<any>(`${API_KEYRING}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateKeyring(id: any, payload: any): Observable<any> {
    return this.http.patch<any>(`${API_KEYRING}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteKeyring(id: any): Observable<any> {
    return this.http.delete<any>(`${API_KEYRING}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * ========================== GENERIC KEYS ==========================
   */

  getGenericKeys(keyringId: any): Observable<any> {
    return this.http.get<any>(API_GENERIC_KEYS.replace('{0}', keyringId)).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getGenericKey(keyringId: any, keyId: any): Observable<any> {
    return this.http
      .get<any>(`${API_GENERIC_KEYS.replace('{0}', keyringId)}/${keyId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createGenericKey(keyringId: any, payload: any): Observable<any> {
    return this.http
      .post<any>(`${API_GENERIC_KEYS.replace('{0}', keyringId)}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteGenericKey(keyringId: any, keyId: any): Observable<any> {
    return this.http
      .delete<any>(`${API_GENERIC_KEYS.replace('{0}', keyringId)}/${keyId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  downloadGenericKey(keyringId: any, keyId: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/octet-stream');
    return this.http
      .get(`${API_GENERIC_KEYS.replace('{0}', keyringId)}/${keyId}/download`, {
        headers,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * ========================== GPG KEYS ==========================
   */

  getGPGKeys(keyringId: any): Observable<any> {
    return this.http.get<any>(API_GPG_KEYS.replace('{0}', keyringId)).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getGPGKey(keyringId: any, keyId: any): Observable<any> {
    return this.http
      .get<any>(`${API_GPG_KEYS.replace('{0}', keyringId)}/${keyId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createGPGKey(keyringId: any, payload: any): Observable<any> {
    return this.http
      .post<any>(`${API_GPG_KEYS.replace('{0}', keyringId)}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteGPGKey(keyringId: any, keyId: any): Observable<any> {
    return this.http
      .delete<any>(`${API_GPG_KEYS.replace('{0}', keyringId)}/${keyId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  downloadGPGPrivateKey(keyringId: any, keyId: any) {
    return this.http
      .get<any>(`${API_GPG_KEYS.replace('{0}', keyringId)}/${keyId}/private`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /**
   * ========================== Key Purpose Algorithm ==========================
   */
  getKeyPurposeAlgorithm() {
    return this.http.get<any>(API_KEY_PURPOSE).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
