<div class="grid device-list">
  <div class="col-12">
    <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)"> </microsec-common-toolbar>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-4': !!selectedDevice || !!selectedSubDevice }" #datatable (click)="unselectDevice($event, datatable)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [rows]="rows"
        [lazy]="true"
        (lazyLoadEvent)="getDevices($event)"
        [totalRecords]="totalDeviceNumber"
        [cols]="selectedCols || []"
        [values]="values"
        [(selection)]="selectedDevice"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [selectable]="true"
        [expansionTemplate]="expansionTemplate"
        itemName="Device"
        [expansionTemplate]="expansionTemplate"
        dataKey="id"
        scrollHeight="55vh"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
      <!-- Expansion Template -->
      <ng-template #expansionTemplate let-rowData="rowData" let-columns="columns" let-dataKey="dataKey">
        @for (item of rowData.children; track $index) {
          <tr
            [pSelectableRow]="rowData"
            (click)="selectSubDevice(item)"
            [ngClass]="{
              'micropki-selected': !!selectedSubDevice ? item.id === selectedSubDevice.id : false
            }">
            <td [style.minWidth.rem]="3" [style.maxWidth.rem]="3" pFrozenColumn [frozen]="true"></td>
            @for (col of columns; track $index) {
              <td [style.minWidth.rem]="col.width" [style.flexBasis.rem]="col.width" pFrozenColumn [frozen]="!!col?.frozen">
                <ng-container
                  [ngTemplateOutlet]="cellTemplate"
                  [ngTemplateOutletContext]="{
                    rowData: item,
                    col: col
                  }">
                </ng-container>
              </td>
            }
          </tr>
        }
      </ng-template>
    </microsec-card>
  </div>

  @if (!!selectedDevice) {
    <div class="col-12 lg:col-8">
      <app-device-info [labels]="labels"></app-device-info>
    </div>
  }
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getDevices()" />
    <microsec-table-column-filter [key]="constructor.name" [cols]="cols" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<!-- Cell Template -->
<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- S/N -->
    @case ('id') {
      @switch (rowData.device_type) {
        <!-- X.509 -->
        @case (DEVICE_TYPE_LABELS.X509) {
          {{ rowData?.sn }}
        }
        <!-- MicroPKI -->
        @case (DEVICE_TYPE_LABELS.MICRO_PKI) {
          <div class="sub-device">{{ rowData?.sn }}</div>
        }
      }
    }
    <!-- Common name -->
    @case ('name') {
      <microsec-truncate-text [tooltip]="rowData?.common_name">
        {{ rowData?.common_name }}
      </microsec-truncate-text>
    }
    <!-- Status -->
    @case ('status') {
      <app-device-status-block [config]="getLabelConfig(rowData[col.field])" />
      @if (!!rowData.is_virtual) {
        <app-device-status-block [config]="getLabelConfig('Virtual')" pTooltip="This is a virtual device" tooltipPosition="bottom" />
      }
      @if (!!rowData.is_manual) {
        <app-device-status-block [config]="getLabelConfig('manual')" pTooltip="This device CSR was uploaded manually" tooltipPosition="bottom" />
      }
      @if (!!rowData.back_imported) {
        <app-device-status-block [config]="getLabelConfig('back-imported')" pTooltip="This device was back-imported" tooltipPosition="bottom" />
      }
    }
    <!-- Label -->
    @case ('labels') {
      <div class="flex flex-wrap">
        @for (lbl of rowData[col.field]; track $index) {
          <app-device-status-block [config]="lbl" [pTooltip]="lbl.description" tooltipPosition="bottom" />
        }
      </div>
    }
    <!-- Device Type -->
    @case ('device_type') {
      @switch (rowData.device_type) {
        <!-- X.509 -->
        @case (DEVICE_TYPE_LABELS.X509) {
          {{ rowData[col.field] }}
          &nbsp;
          @if (rowData?.packages_up_to_date === false) {
            <i class="fa fa-exclamation-circle text-red-400" pTooltip="Package(s) installed on this device are outdated" appendTo="body"></i>
          }
        }
        <!-- MicroPKI -->
        @case (DEVICE_TYPE_LABELS.MICRO_PKI) {
          {{ rowData[col.field] }}
        }
      }
    }
    <!-- Last seen -->
    @case ('last_seen') {
      <span [title]="rowData[col.field] | date: PIPE_DATETIME">
        {{ rowData[col.field] | date: PIPE_DATETIME }}
      </span>
    }
    <!-- Default -->
    @default {
      {{ rowData[col.field] }}
    }
  }
</ng-template>

<ng-template #labelItemTemplate let-item="item">
  <div class="flex" [pTooltip]="item.description" tooltipPosition="right" appendTo="body">
    <i class="fas fa-square-full mr-2" [ngStyle]="{ color: item.color }"></i>
    <microsec-truncate-text class="text-data">
      {{ item.name }}
    </microsec-truncate-text>
  </div>
</ng-template>
