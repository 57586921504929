import { UserCertificateCsrFormComponent } from './user-certificate-csr-form/user-certificate-csr-form.component';
import { UserCertificateDashboardFormComponent } from './user-certificate-dashboard-form/user-certificate-dashboard-form.component';

export const ENROLLMENT_CONFIGS = [
  {
    key: 'csr',
    label: 'Generate/Upload CSR',
    description: 'Enroll users by generating CSR and certificate via our built-in solution or manually uploading a CSR.',
    component: UserCertificateCsrFormComponent,
  },
  {
    key: 'dashboard',
    label: 'Enroll via User Enrollment Dashboard',
    description: 'Allow users to request for certificates by themselves through the enrollment dashboard.',
    component: UserCertificateDashboardFormComponent,
  },
];
