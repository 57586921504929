import { AfterViewInit, Component, ElementRef, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ConstantPipe } from '@lcms-pipes';
import { KmsService } from '@lcms-services';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { PIPELINE_TYPE, PIPE_DATETIME } from '@microsec/constants';
import { FormItem } from '@microsec/models';

const FORM_PARAMS = {
  LABEL: 'label',
  ALGORITHM: 'key_algorithm',
  SIZE: 'key_length',
  EXPIRATION_DATE: 'expiration_date',
  FULL_NAME: 'full_name',
  EMAIL_ADDRESS: 'email_address',
  GPG_KEY_ID: 'gpg_key_id',
  GPG_PUBLIC_KEY: 'pub_key',
  GPG_PRIVATE_KEY: 'private_key',
};

@Component({
  selector: 'app-gpg-key-details',
  templateUrl: './gpg-key-details.component.html',
  styleUrls: ['./gpg-key-details.component.scss'],
  providers: [ConstantPipe],
})
export class GpgKeyDetailsComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = false;

  @Input() selectedKeyring: any = null;

  _selectedKey: any = null;

  @Input() set selectedKey(value: any) {
    this._selectedKey = value;
    this.initForm();
  }

  get selectedKey() {
    return this._selectedKey;
  }

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('downloadPrivateKeyTemplate') downloadPrivateKeyTemplate!: TemplateRef<any>;

  PIPE_DATETIME = PIPE_DATETIME;

  constructor(
    public el: ElementRef,
    private constantPipe: ConstantPipe,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initForm();
  }

  /**
   * Init the form data
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Label',
        field: 'label',
        defaultValue: this.selectedKey?.[FORM_PARAMS.LABEL],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ALGORITHM,
        label: 'Algorithm',
        field: 'label',
        defaultValue: this.constantPipe?.transform(this.selectedKey?.[FORM_PARAMS.ALGORITHM], 'kms-key-algorithm'),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIZE,
        label: 'Size',
        field: 'label',
        defaultValue: this.selectedKey?.[FORM_PARAMS.SIZE],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.EXPIRATION_DATE,
        label: 'Expiration Date',
        field: 'label',
        defaultValue: this.selectedKey?.[FORM_PARAMS.EXPIRATION_DATE] || '-',
        pipeline: PIPELINE_TYPE.DATETIME,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FULL_NAME,
        label: 'Full Name',
        field: 'label',
        defaultValue: this.selectedKey?.[FORM_PARAMS.FULL_NAME],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.EMAIL_ADDRESS,
        label: 'Email Address',
        field: 'label',
        defaultValue: this.selectedKey?.[FORM_PARAMS.EMAIL_ADDRESS],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.GPG_KEY_ID,
        label: 'GPG Key ID',
        field: 'copy',
        defaultValue: this.selectedKey?.[FORM_PARAMS.GPG_KEY_ID],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.GPG_PUBLIC_KEY,
        label: 'GPG Public Key',
        field: 'copy',
        defaultValue: this.selectedKey?.[FORM_PARAMS.GPG_PUBLIC_KEY],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.GPG_PRIVATE_KEY,
        label: 'GPG Private Key',
        field: 'custom',
        customField: this.downloadPrivateKeyTemplate,
      } as FormItem),
    ];
  }

  /**
   * Download private key
   */
  downloadPrivateKey() {
    this.kmsSrv.downloadGPGPrivateKey(this.selectedKeyring?.id, this.selectedKey?.id).subscribe({
      next: (rs) => {
        if (!!rs) {
          this.showSuccessMessage('Download GPG private key successfully');
          this.util.downloadFileFromText(
            rs?.private_key || '',
            `keyring_${this.selectedKeyring?.id}_key_${this.selectedKey?.id}_${this.selectedKey?.label}_gpg_private_key.pem`,
          );
        } else {
          this.showErrorMessage('Cannot download');
        }
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    });
  }
}
