/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import { IRaEnrollmentService } from './IRaEnrollmentService';

@Injectable({
  providedIn: 'root',
})
export class MockRaEnrollmentService implements IRaEnrollmentService {
  createUserCertificateEnrollmentRequest(payload: any): Observable<any> {
    return scheduled(
      [
        {
          status: 'success',
          transaction_id: '123456789',
        },
      ],
      asyncScheduler,
    );
  }

  createUserCertificateSelfEnrollmentRequest(payload: any): Observable<any> {
    return scheduled(
      [
        {
          status: 'success',
          transaction_id: '123456789',
        },
      ],
      asyncScheduler,
    );
  }

  checkUserCertificateSelfEnrollmentStatus(requestId: any): Observable<any> {
    const data = {
      status: 'success',
      certificate:
        '-----BEGIN CERTIFICATE REQUEST-----' +
        'MIIBNjCB3QIBADB+MQswCQYDVQQGEwJTRzESMBAGA1UECAwJU2luZ2Fwb3JlMRIw' +
        'EAYDVQQHDAlTaW5nYXBvcmUxGTAXBgNVBAoMEE1pY3JvU2VjIFB0ZSBMdGQxDTAL' +
        'BgNVBAsMBFRlc3QxHTAbBgNVBAMMFEludCBDQSBUTFMgQ0EgU2VydmVyMFYwEAYH' +
        'KoZIzj0CAQYFK4EEAAoDQgAE/m6JHIVVXmo7WGzLgKv71vxXQ5AMUtzBT+axGG4q' +
        'oRBkB0FP1W9A3+76MyoHIysdyskymdfSo5CNZXdjnB9H8KAAMAoGCCqGSM49BAMC' +
        'A0gAMEUCIE3pezTq5YqV45GROr/PAFza8INNXiO/Wq1RmDe1U+bwAiEA6vWksyKP' +
        'l6H2vGNU1QKCyZawL9rsDjSwh+zIOKmFKPY=' +
        '-----END CERTIFICATE REQUEST-----',
      certificate_format: 'pem',
      errorcode: '',
      errorstring: '',
    };
    switch (requestId) {
      case '1': {
        data.status = 'pending';
        break;
      }
      case '2': {
        data.status = 'error';
        data.errorcode = '500';
        data.errorstring = 'Wrong request ID!!!';
        break;
      }
      default: {
        break;
      }
    }
    return scheduled([data], asyncScheduler);
  }

  generateCSR(caServerId: any, caId: any, templateId: any, payload: any): Observable<any> {
    return scheduled(
      [
        {
          csr: `
            -----BEGIN CERTIFICATE REQUEST-----
            MIIBLDCB1AIBADBTMQwwCgYDVQQDDANhYWExCzAJBgNVBAYTAlNHMQwwCgYDVQQI
            DANhYWExDDAKBgNVBAcMA2FhYTEMMAoGA1UECgwDYWFhMQwwCgYDVQQLDANhYWEw
            WTATBgcqhkjOPQIBBggqhkjOPQMBBwNCAATs3SkdZhdCtLU5P9EBX9U0uh51y414
            x0Brr6apQK+2PIcM9HAHWp1bbT7rBKoMa1nijIRQI5dT07EIh1X1V3sBoB8wHQYJ
            KoZIhvcNAQkOMRAwDjAMBgNVHRMBAf8EAjAAMAoGCCqGSM49BAMCA0cAMEQCIBaW
            qVydukHkQUAWNTWQANuIVrgQ6cfyW0xFqQ9KO5t9AiBOT3xxeJ2FxAoAnzeyJSSZ
            zEHuZdEIvTujVIch/42gEg==
            -----END CERTIFICATE REQUEST-----
          `,
          private_key: `
              ----BEGIN PRIVATE KEY-----
            MIGHAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBG0wawIBAQQgt99ql9+Yvu7eGJx9
            TxKQ0hM+3CgIaFxZ6KoDM65RxM6hRANCAATs3SkdZhdCtLU5P9EBX9U0uh51y414
            x0Brr6apQK+2PIcM9HAHWp1bbT7rBKoMa1nijIRQI5dT07EIh1X1V3sB
            -----END PRIVATE KEY-----
          `,
        },
      ],
      asyncScheduler,
    );
  }

  createCSRCertificate(caServerId: any, caId: any, templateId: any, format: any, payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }
}
