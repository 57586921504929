<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!permissions[SCOPE.PROJECT][USER_ROLE.READ_ONLY]) {
    <div class="flex flex-column gap-4">
      @for (formGroup of formArray; track $index) {
        <microsec-card>
          <div class="grid" [id]="formGroup?.id">
            <div class="col-12">
              <div class="flex justify-content-between">
                <div class="text-data font-bold text-lg">
                  {{ formGroup?.name }}
                </div>
                <div class="switch">
                  @if (!!formGroup?.toggleable) {
                    <p-inputSwitch [(ngModel)]="formGroup.isToggled" [disabled]="formGroup.toggleDisabled" />
                  }
                </div>
              </div>
            </div>
            @if (!formGroup?.toggleable || (!!formGroup?.toggleable && !!formGroup.isToggled)) {
              <div class="col-12 text-value">
                {{ formGroup?.description }}
              </div>
            }
            <div
              class="col-12 xl:col-6 lg:col-12 md:col-12 sm:col-12"
              [ngStyle]="{
                height: !!formGroup?.toggleable && !formGroup.isToggled ? '0px' : 'initial',
                overflow: !!formGroup?.toggleable && !formGroup.isToggled ? 'hidden' : 'unset'
              }">
              @if (!formGroup?.tabs?.length) {
                <!-- Normal form -->
                <microsec-form-builder
                  #fb
                  [id]="formGroup?.id"
                  [isPage]="true"
                  [fields]="formGroup?.fields || []"
                  [shouldShowActionButtons]="false" />
              } @else {
                <!-- Tabs -->
                <p-tabView [scrollable]="true" styleClass="microsec-dashboard-tab">
                  @for (tab of formGroup?.tabs; track $index) {
                    <p-tabPanel [header]="tab.name" [disabled]="tab?.disabled">
                      <microsec-form-builder #fb [id]="tab?.id" [isPage]="true" [fields]="tab?.fields || []" [shouldShowActionButtons]="false" />
                    </p-tabPanel>
                  }
                </p-tabView>
              }
            </div>
          </div>
        </microsec-card>
      }
      <div class="flex align-items-center justify-content-end">
        <p-button severity="primary" label="Download Agent" [disabled]="!!isSubmitButtonDisabled" (onClick)="submitAgentForm()" />
      </div>
    </div>
  } @else {
    <div class="grid">
      <label class="col-12"> You do not have access to this option. </label>
    </div>
  }
</microsec-loading-overlay>
