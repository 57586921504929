<div class="grid">
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN] ? leftTemplate : null">
      <ng-template #leftTemplate>
        <p-button
          severity="success"
          icon="pi pi-plus"
          pTooltip="Assign Device"
          tooltipPosition="bottom"
          appendTo="body"
          (onClick)="openDeviceAssignmentDialog()" />
      </ng-template>
    </microsec-common-toolbar>
  </div>

  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        selectionMode="multiple"
        [(selection)]="selectedDevices"
        itemName="Device"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <p-button
      icon="fa fa-upload"
      severity="primary"
      size="small"
      pTooltip="Push to device(s)"
      tooltipPosition="bottom"
      appendTo="body"
      [disabled]="!selectedDevices || !selectedDevices.length"
      (onClick)="pushToDevices()" />
    <p-button
      severity="danger"
      size="small"
      icon="fa fa-trash"
      pTooltip="Delete"
      tooltipPosition="bottom"
      appendTo="body"
      [disabled]="!selectedDevices || !selectedDevices.length"
      (onClick)="openDeleteConfirmation()" />
    <microsec-table-column-filter [key]="constructor.name" [cols]="cols" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    @case ('current_status') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'firmware-device-status' }}
      </microsec-truncate-text>
    }
    @case ('uploaded') {
      <microsec-truncate-text>
        {{ rowData[col.field] | date: PIPE_DATETIME }}
      </microsec-truncate-text>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
