<div class="grid">
  <div class="col-12 text-data mb-2">Please select a CA and CA template from the list below. Then, upload a CSR file to download certificate.</div>
  <div class="col-12 xl:col-6 lg:col-12 md:col-12 sm:col-12">
    <microsec-form-builder
      #fb
      [isPage]="true"
      [fields]="fields"
      [shouldShowActionButtons]="true"
      submitButtonLabel="Upload CSR / Download Certificate"
      [submitButtonStyleClass]="'p-button-primary'"
      (submitEvent)="onSubmitCSRForm()"
      [cancelButtonLabel]="''" />
  </div>
</div>
