<microsec-layout-table
  #lt
  itemName="Device"
  [isLoading]="isLoading"
  [filterKey]="constructor.name"
  [isServerFiltered]="true"
  [totalRecords]="totalDeviceNumber"
  [cols]="cols"
  [values]="values"
  (getItemsEvent)="getDevices()"
  [cellTemplate]="cellTemplate" />

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Enabled -->
    @case ('enabled') {
      <p-checkbox [binary]="true" [(ngModel)]="rowData.enabled" (onChange)="updateRuleset(rowData, $event)" />
    }
    @default {
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    }
  }
</ng-template>

<ng-template #labelItemTemplate let-item="item">
  <div class="flex" [pTooltip]="item.description" tooltipPosition="right" appendTo="body">
    <i class="fas fa-square-full mr-2" [ngStyle]="{ color: item.color }"></i>
    <microsec-truncate-text class="text-data">
      {{ item.name }}
    </microsec-truncate-text>
  </div>
</ng-template>
