import { AfterViewInit, Component, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { CA_MODE_VALUES, LAMBDA_TYPES } from '@lcms-constants';
import { CaManagementService, EnvelopeService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { CREATE_LABEL, VALIDATOR_TYPE } from '@microsec/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  ENVELOPE_TEMPLATE_ID: 'envelope_template_id',
  PROJECT_ID: 'project_id',
  CA_SERVER_ID: 'ca_server_id',
  CA_ID: 'ca_id',
  PAYLOAD_OPTION: 'payload_option',
  CONTENT: 'content',
  FILE: 'file',
  VALID: 'valid',
};

const VALIDATION_FORM_PARAMS = {
  LAMBDA_TYPE: 'lambda_type',
  INPUT: 'input',
  LAMBDA_FUNCTION: 'lambda_function',
};

const PAYLOAD_OPTIONS = {
  TEXT: 'text',
  FILE: 'file',
};

@Component({
  selector: 'app-envelope-form',
  templateUrl: './envelope-form.component.html',
  styleUrls: ['./envelope-form.component.scss'],
})
export class EnvelopeFormComponent extends BaseComponent implements AfterViewInit {
  template: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('validateButton') validateButton!: TemplateRef<any>;

  CREATE_LABEL = CREATE_LABEL;

  FORM_PARAMS = FORM_PARAMS;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private caManagementSrv: CaManagementService,
    private envelopeSrv: EnvelopeService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.template = this.dialogConfig?.data?.template;
    this.initFormData();
    this.getCAServers();
  }

  /**
   * Initialize the form data
   */
  initFormData() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENVELOPE_TEMPLATE_ID,
        defaultValue: this.template.id,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        defaultValue: this.breadcrumbConfig?.projectId,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_SERVER_ID,
        label: 'CA Server',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA server',
        required: true,
        fieldInfo: 'CA server',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_ID,
        label: 'CA Certificate',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA certificate',
        required: true,
        fieldInfo: 'CA certificate (used for signature creation)',
        defaultValue: null,
        disabled: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Please provide the envelope payload as a text or file.',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PAYLOAD_OPTION,
        hasNoLabel: true,
        field: 'radio',
        options: [{ value: PAYLOAD_OPTIONS.TEXT, label: 'Enter text' }],
        defaultValue: PAYLOAD_OPTIONS.TEXT,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CONTENT,
        hasNoLabel: true,
        label: 'Payload Text',
        field: 'textarea',
        placeholder: 'Please enter the payload text ...',
        required: true,
        defaultValue: '',
        afterFieldTemplate: this.validateButton,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.VALID,
        defaultValue: null,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PAYLOAD_OPTION,
        hasNoLabel: true,
        field: 'radio',
        options: [{ value: PAYLOAD_OPTIONS.FILE, label: 'Upload file' }],
        defaultValue: PAYLOAD_OPTIONS.TEXT,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FILE,
        label: 'Payload File',
        field: 'file',
        uploadEvent: new EventEmitter(),
        defaultValue: null,
        fieldInfo: 'Payload file',
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;
    // Setup file upload event
    this.setupFileUploadEvent();
    // CA Server change event
    this.form.setChangeEvent(FORM_PARAMS.CA_SERVER_ID, (caConnectionId) => {
      this.getCAIntermediateCertificates(caConnectionId);
      this.form.setControlValue(FORM_PARAMS.CA_ID, null);
    });
    // Upload type change event
    this.form.setChangeEvent(FORM_PARAMS.PAYLOAD_OPTION, (option: string) => {
      this.form.patchValue({
        [FORM_PARAMS.FILE]: null,
        [FORM_PARAMS.CONTENT]: '',
        [FORM_PARAMS.VALID]: null,
      });
      switch (option) {
        case PAYLOAD_OPTIONS.TEXT: {
          this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CONTENT, [VALIDATOR_TYPE.REQUIRED]);
          this.form.setControlValidatorsAndVisibility(FORM_PARAMS.FILE, []);
          this.enableChangeText();
          break;
        }
        case PAYLOAD_OPTIONS.FILE: {
          this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CONTENT, []);
          this.form.setControlValidatorsAndVisibility(FORM_PARAMS.FILE, [VALIDATOR_TYPE.REQUIRED]);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  /**
   * Get the list of CA servers
   */
  getCAServers() {
    this.form.isLoading = true;
    this.caManagementSrv
      .getCAManagers(this.breadcrumbConfig?.projectId as number)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
          if (!!caServerField) {
            caServerField.options = ((rs?.data as any[]) || []).map((p) => ({ ...p, value: p.id, label: p.name }));
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get the list of CA intermediate certificates
   * @param caConnectionId
   */
  getCAIntermediateCertificates(caConnectionId: any) {
    const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
    const caField = this.fields.find((p) => p.name === FORM_PARAMS.CA_ID);
    if (!!caServerField && !!caField) {
      const cas: any[] = caServerField.options?.find((caServer) => caServer.value === caConnectionId)?.cas || [];
      const certs = cas
        .filter((p: any) => p.mode === CA_MODE_VALUES.X509)
        .filter((p: any) => (p.type as string).includes('intermediate'))
        .map((p: any) => ({
          ...p,
          value: p.id,
          label: `${p.id}: ${p.subject?.CN} (${p.description})`,
        }));
      caField.options = this.util.sortObjectArray(certs, 'id');
      // Disable/enable CA certificate list
      this.form.setControlValue(FORM_PARAMS.CA_ID, null);
      if (!!caField.options.length) {
        this.form.enableControl(FORM_PARAMS.CA_ID);
      } else {
        this.form.disableControl(FORM_PARAMS.CA_ID);
      }
    }
  }

  /**
   * Setup file upload event
   */
  setupFileUploadEvent() {
    const fileField = this.fields.find((p) => p.name === FORM_PARAMS.FILE);
    fileField?.uploadEvent?.subscribe((event) => {
      this.form.isLoading = true;
      this.getValidatedFile(event).subscribe((validatedFile: any) => {
        this.form.patchValue(validatedFile);
        this.form.isLoading = false;
      });
    });
  }

  /**
   * Validate the file with correct format
   * @param event
   * @returns
   */
  private getValidatedFile(event: any) {
    return new Observable((observe) => {
      if (!!event.target && !!event.target.files && !!event.target.files.length) {
        const file = (event.target.files as FileList).item(0) as Blob;
        observe.next({
          [FORM_PARAMS.FILE]: file,
          [FORM_PARAMS.CONTENT]: '',
          [FORM_PARAMS.VALID]: true,
        });
      } else {
        this.form.markFieldAsTouchedAndDirty(FORM_PARAMS.FILE);
        observe.next({
          [FORM_PARAMS.FILE]: null,
          [FORM_PARAMS.CONTENT]: '',
          [FORM_PARAMS.VALID]: null,
        });
      }
    });
  }

  /**
   * Reset verification for payload text
   */
  enableChangeText(isEnabled = true) {
    if (!!isEnabled) {
      this.form.enableControl(FORM_PARAMS.CONTENT);
      this.form.setControlValue(FORM_PARAMS.CONTENT, '');
      this.form.setControlValue(FORM_PARAMS.VALID, null);
    } else {
      this.form.disableControl(FORM_PARAMS.CONTENT);
      this.form.setControlValue(FORM_PARAMS.VALID, true);
    }
  }

  /**
   * Validate payload text
   */
  validateText() {
    const payload = {
      [VALIDATION_FORM_PARAMS.LAMBDA_TYPE]: LAMBDA_TYPES.INPUT,
      [VALIDATION_FORM_PARAMS.LAMBDA_FUNCTION]: this.template?.input_validation_lambda,
      [VALIDATION_FORM_PARAMS.INPUT]: this.form.getControlValue(FORM_PARAMS.CONTENT),
    };
    this.envelopeSrv
      .validateLambdaCode(payload)
      .pipe()
      .subscribe({
        next: (rs: any) => {
          const check = rs.validity;
          if (!!check) {
            this.showSuccessMessage('The payload text is validated successfully');
            this.enableChangeText(false);
          } else {
            this.form.setControlValue(FORM_PARAMS.VALID, false);
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Submit the data
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const envelopeValue = { ...this.form.getRawValue() };
    const payload = {
      [FORM_PARAMS.ENVELOPE_TEMPLATE_ID]: envelopeValue[FORM_PARAMS.ENVELOPE_TEMPLATE_ID],
      [FORM_PARAMS.CA_SERVER_ID]: envelopeValue[FORM_PARAMS.CA_SERVER_ID],
      [FORM_PARAMS.CA_ID]: envelopeValue[FORM_PARAMS.CA_ID],
      [FORM_PARAMS.CONTENT]: envelopeValue[FORM_PARAMS.CONTENT],
      [FORM_PARAMS.FILE]: envelopeValue[FORM_PARAMS.FILE],
    };
    window.microsec.log('Envelope: ', payload);
    this.envelopeSrv
      .signEnvelope(payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          window.microsec.log('Envelope result: ', response);
          if (!!response) {
            this.util.downloadFileFromBlob(response, this.template?.output_file_name);
            this.showSuccessMessage('The envelope is successfully created and downloaded');
          } else {
            this.showErrorMessage('The envelope is successfully created, but cannot be downloaded');
          }
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
