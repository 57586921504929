<microsec-layout-table
  #dt
  [cols]="cols"
  [values]="values"
  (getItemsEvent)="getPackagesByRepository()"
  itemName="Package"
  [shouldShowCreateButton]="false"
  [toolbarActionButtons]="toolbarActionButtons"
  [(selectedItem)]="selectedPackage"
  [isLoading]="isLoading"
  [actionMenuItems]="actionMenuItems"
  [detailsTemplate]="detailsTemplate"
  [hasSidebarDetails]="true" />

<ng-template #detailsTemplate>
  <microsec-details-panel [title]="selectedPackage?.name" (collapseEvent)="selectedPackage = null">
    <microsec-details-card [item]="selectedPackage" [structure]="structure" />
  </microsec-details-panel>
</ng-template>
