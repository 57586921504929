// =================================== KEYRING ===================================
export const MICROSEC_DEFAULT_KEYRING = 'microsec_default_keyring';

export const KEYRING_TYPE_VALUES = {
  FILESYSTEM: 'filesystem',
  CRYPTOKEN: 'cryptoken',
};

export const KEYRING_TYPES = [
  { value: KEYRING_TYPE_VALUES.FILESYSTEM, label: 'Filesystem' },
  { value: KEYRING_TYPE_VALUES.CRYPTOKEN, label: 'Cryptoken' },
];

// =================================== KEY ===================================

export const KEY_ALGORITHM_VALUES = {
  // Asymmetric
  EC: 'ec',
  ECDSA: 'ecdsa',
  ECC: 'ecc',
  DSA: 'dsa',
  RSA: 'rsa',
  // Symmetric
  AES: 'aes',
  FERNET: 'fernet',
};

const EC_SIZES = [
  { value: 'P-256', label: 'P-256' },
  { value: 'P-384', label: 'P-384' },
  { value: 'P-521', label: 'P-521' },
];

const ECDSA_SIZES = [
  { value: 'P-256', label: 'P-256' },
  { value: 'P-384', label: 'P-384' },
  { value: 'P-521', label: 'P-521' },
];

const ECC_SIZES = [
  { value: 'P-256', label: 'P-256' },
  { value: 'P-384', label: 'P-384' },
  { value: 'P-521', label: 'P-521' },
];

const DSA_SIZES = [
  { value: 1024, label: '1024' },
  { value: 2048, label: '2048' },
  { value: 4096, label: '4096' },
];

const RSA_SIZES = [
  { value: 1024, label: '1024' },
  { value: 2048, label: '2048' },
  { value: 4096, label: '4096' },
];

const AES_SIZES = [
  { value: 128, label: '128' },
  { value: 192, label: '192' },
  { value: 256, label: '256' },
];

const FERNET_SIZES = [
  { value: 128, label: '128' },
  { value: 192, label: '192' },
  { value: 256, label: '256' },
];

export const ASYMMETRIC_KEY_ALGORITHMS = [
  { value: KEY_ALGORITHM_VALUES.EC, label: KEY_ALGORITHM_VALUES.EC.toUpperCase(), options: EC_SIZES },
  { value: KEY_ALGORITHM_VALUES.ECC, label: KEY_ALGORITHM_VALUES.ECC.toUpperCase(), options: ECC_SIZES },
  { value: KEY_ALGORITHM_VALUES.ECDSA, label: KEY_ALGORITHM_VALUES.ECDSA.toUpperCase(), options: ECDSA_SIZES },
  { value: KEY_ALGORITHM_VALUES.DSA, label: KEY_ALGORITHM_VALUES.DSA.toUpperCase(), options: DSA_SIZES },
  { value: KEY_ALGORITHM_VALUES.RSA, label: KEY_ALGORITHM_VALUES.RSA.toUpperCase(), options: RSA_SIZES },
];

export const SYMMETRIC_KEY_ALGORITHMS = [
  { value: KEY_ALGORITHM_VALUES.AES, label: KEY_ALGORITHM_VALUES.AES.toUpperCase(), options: AES_SIZES },
  { value: KEY_ALGORITHM_VALUES.FERNET, label: KEY_ALGORITHM_VALUES.FERNET.toUpperCase(), options: FERNET_SIZES },
];

export const GPG_KEY_ALGORITHMS = [
  { value: KEY_ALGORITHM_VALUES.DSA, label: KEY_ALGORITHM_VALUES.DSA.toUpperCase(), options: DSA_SIZES },
  { value: KEY_ALGORITHM_VALUES.RSA, label: KEY_ALGORITHM_VALUES.RSA.toUpperCase(), options: RSA_SIZES },
];

export const KEYRING_KEY_TYPE_VALUES = {
  GPG: 'gpg',
  SYMMETRIC: 'symmetric',
  ASYMMETRIC: 'asymmetric',
};

export const KEYRING_GENERIC_KEY_TYPES = [
  { value: KEYRING_KEY_TYPE_VALUES.SYMMETRIC, label: 'Symmetric' },
  { value: KEYRING_KEY_TYPE_VALUES.ASYMMETRIC, label: 'Asymmetric' },
];

export const KEYRING_GPG_KEY_TYPES = [{ value: KEYRING_KEY_TYPE_VALUES.GPG, label: 'GPG' }];
