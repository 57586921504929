import { finalize } from 'rxjs';
import { MICROSEC_LCMS_CA, MICROSEC_LCMS_RA, ORGANIZATION_LEVEL_ROUTE, PIPE_DATETIME, PROJECT_LEVEL_ROUTE } from '@microsec/constants';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { KEYRING_TYPE_VALUES, PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ConstantPipe } from '@lcms-pipes';
import { FormBuilderComponent } from '@microsec/components';
import { ActionMenuItem, FormItem } from '@microsec/models';
import { CaService, DeviceService } from '@lcms-services';

const FORM_PARAMS = {
  TYPE: 'type',
  NAME: 'name',
  DESCRIPTION: 'description',
  CRYPTOKEN_MANAGER_INFO: 'cryptoken_manager_info',
  CRYPTOKEN_MANAGER_LABEL: 'cryptoken_manager_label',
  CRYPTOKEN_LABEL: 'cryptoken_label',
  TOKEN_STATUS: 'status',
};

const TABLE_FIELDS = {
  ID: 'ID',
  SERIAL_NUMBER: 'Serial Number',
  STATUS: 'Status',
  VALID_NOT_BEFORE: 'Valid Not Before',
  VALID_NOT_AFTER: 'Valid Not After',
};

@Component({
  selector: 'app-keyring-overview',
  templateUrl: './keyring-overview.component.html',
  styleUrls: ['./keyring-overview.component.scss'],
  providers: [ConstantPipe],
})
export class KeyringOverviewComponent extends BaseComponent implements OnInit {
  isLoading = false;

  _selectedKeyring: any = null;

  @Input() set selectedKeyring(value: any) {
    this._selectedKeyring = value;
  }

  get selectedKeyring() {
    return this._selectedKeyring;
  }

  fields: FormItem[] = [];

  cols = [
    { field: 'hexSerialNumber', header: TABLE_FIELDS.SERIAL_NUMBER, width: 6, frozen: true },
    { field: 'status', header: TABLE_FIELDS.STATUS, width: 3 },
    { field: 'not_before', header: TABLE_FIELDS.VALID_NOT_BEFORE, width: 6 },
    { field: 'not_after', header: TABLE_FIELDS.VALID_NOT_AFTER, width: 6 },
  ];

  certificates: any[] = [];

  selectedCert: any = null;

  actionMenuItems: ActionMenuItem[] = [];

  app: string;

  @ViewChild('fb') form!: FormBuilderComponent;

  MICROSEC_LCMS_CA = MICROSEC_LCMS_CA;

  MICROSEC_LCMS_RA = MICROSEC_LCMS_RA;

  PIPE_DATETIME = PIPE_DATETIME;

  constructor(
    private constantPipe: ConstantPipe,
    private deviceSrv: DeviceService,
    private caSrv: CaService,
    private injector: Injector,
  ) {
    super();
    this.app = this.injector.get('environment').module as string;
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.actionMenuItems = [
      {
        label: 'View Certificate',
        icon: 'fas fa-arrow-up-right-from-square',
        command: ({ rowData }: any) => this.handleCertRowClick(rowData),
      },
    ];
    switch (this.app) {
      case MICROSEC_LCMS_CA: {
        this.getCACertificates();
        break;
      }
      case MICROSEC_LCMS_RA: {
        this.getDeviceCertificates();
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'label',
        defaultValue: this.selectedKeyring?.[FORM_PARAMS.NAME],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'label',
        defaultValue: this.selectedKeyring?.[FORM_PARAMS.DESCRIPTION] || '-',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        label: 'Type',
        field: 'label',
        defaultValue: this.constantPipe.transform(this.selectedKeyring?.[FORM_PARAMS.TYPE], 'kms-keyring-type'),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CRYPTOKEN_MANAGER_LABEL,
        label: 'Cryptoken Manager',
        field: 'label',
        defaultValue: this.selectedKeyring?.[FORM_PARAMS.CRYPTOKEN_MANAGER_INFO]?.[FORM_PARAMS.CRYPTOKEN_MANAGER_LABEL],
        hidden: this.selectedKeyring?.[FORM_PARAMS.TYPE] !== KEYRING_TYPE_VALUES.CRYPTOKEN,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CRYPTOKEN_LABEL,
        label: 'Token',
        field: 'label',
        defaultValue: this.selectedKeyring?.[FORM_PARAMS.CRYPTOKEN_MANAGER_INFO]?.[FORM_PARAMS.CRYPTOKEN_LABEL],
        hidden: this.selectedKeyring?.[FORM_PARAMS.TYPE] !== KEYRING_TYPE_VALUES.CRYPTOKEN,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TOKEN_STATUS,
        label: 'Status',
        field: 'label',
        defaultValue: this.util.getUppercaseFirstLetter(this.selectedKeyring?.[FORM_PARAMS.CRYPTOKEN_MANAGER_INFO]?.[FORM_PARAMS.TOKEN_STATUS]),
        hidden: this.selectedKeyring?.[FORM_PARAMS.TYPE] !== KEYRING_TYPE_VALUES.CRYPTOKEN,
      } as FormItem),
    ];
    this.fields = fields;
  }

  /**
   * Get device certificates (Device only) in RA dashboard
   */
  getDeviceCertificates() {
    this.isLoading = true;
    this.deviceSrv
      .getDeviceCertificates(this.breadcrumbConfig?.projectId, null, this.selectedKeyring.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.initForm();
        }),
      )
      .subscribe({
        next: (rs) => {
          this.certificates = ((rs?.certificates as any[]) || []).map((value) => ({
            ...value,
            hexSerialNumber: this.util.convertToHexadecimal(value.serial_number),
          }));
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get CA certificates
   */
  getCACertificates() {
    this.isLoading = true;
    this.caSrv
      .getCAs(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId, undefined, this.selectedKeyring.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.initForm();
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.certificates = ((rs as any[]) || []).map((value) => ({
            ...value,
            hexSerialNumber: this.util.convertToHexadecimal(value.serial_number),
          }));
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Go To Certificate List Table
   * @param cert
   */
  handleCertRowClick(cert: any) {
    if (cert.id) {
      switch (this.app) {
        case MICROSEC_LCMS_CA: {
          this.router.navigate(
            [
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
                `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
                `${PROJECT_MANAGEMENT_CONSTANTS.CHAINS_OF_TRUST.ROUTE}/cas`,
            ],
            { queryParams: { cert_id: cert.id } },
          );
          break;
        }
        case MICROSEC_LCMS_RA: {
          this.router.navigate(
            [
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
                `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
                `${PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE}/details`,
            ],
            { queryParams: { keyring_id: this.selectedKeyring.id } },
          );
          break;
        }
      }
    }
  }
}
