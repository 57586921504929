import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { CaManagementService, DeviceService, KmsService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import {
  IMPORT_LABEL,
  VALIDATOR_TYPE,
  CREATE_LABEL,
  ORGANIZATION_LEVEL_ROUTE,
  PROJECT_LEVEL_ROUTE,
  STRICT_ALPHANUMERIC_PATTERN,
} from '@microsec/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { FORM_SELECT_OPTIONS, SELECT_OPTIONS_VALUES } from '../device-crypto-assets.config';
import { COUNTRIES, KEYRING_TYPES, KEYRING_TYPE_VALUES, MICROSEC_DEFAULT_KEYRING, PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { PROJECT_SETTINGS_CONSTANTS } from '@lcms-products';

const FORM_PARAMS = {
  OPTION_SELECT: 'option_select',
  // --------- IMPORT OPTION----------
  CLIENT_CERTIFICATE_FILE: 'pem_file',
  CLIENT_CERTIFICATE: 'pem',
  CERTIFICATE_DESTINATION: 'destination',
  PATH_TO_UPLOAD: 'file_path',
  // ---------- CREATE OPTION ------------
  KEY_SOURCE: 'key_source',
  KEYRING_ID: 'keyring_id',
  COMMON_NAME: 'common_name',
  COUNTRY: 'country',
  STATE: 'state',
  LOCALITY: 'locality',
  ORGANIZATION: 'organization',
  ORGANIZATION_UNIT: 'organization_unit',
  // Select Server, cert and template
  CA_SERVER_ID: 'ca_server_id',
  CA_ID: 'ca_id',
  TEMPLATE_ID: 'template_id',

  KEY_DESTINATION: 'key_destination',
  KEY_PATH: 'key_destination_path',
  CERT_DESTINATION_PATH: 'certificate_path',
  // Miscellaneous
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  CA_DIVIDER: 'ca_divider',
  KEYRING_DIVIDER: 'keyring_divider',
};

@Component({
  selector: 'app-dca-create-import-client-certificate-form',
  templateUrl: './dca-create-import-client-certificate-form.component.html',
  styleUrls: ['./dca-create-import-client-certificate-form.component.scss'],
})
export class DcaCreateImportClientCertificateFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  device: any = null;

  FORM_PARAMS = FORM_PARAMS;

  SELECT_OPTIONS_VALUES = SELECT_OPTIONS_VALUES;

  IMPORT_LABEL = IMPORT_LABEL;

  CREATE_LABEL = CREATE_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private deviceSrv: DeviceService,
    private kmsSrv: KmsService,
    private caManagementSrv: CaManagementService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    this.device = this.dialogConfig.data?.device;
    await this.prepareConfigs();
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    const patternErrorText = 'This field cannot have invalid characters. Allowed characters are A-Z a-z 0-9 . _ -';
    const commonNameField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.COMMON_NAME,
      label: 'Common Name',
      field: 'input',
      required: true,
      pattern: STRICT_ALPHANUMERIC_PATTERN,
      patternErrorText,
      fieldInfo: 'Common name',
      defaultValue: '',
      maxLength: 50,
      focused: true,
      onTypeEvent: new EventEmitter<any>(),
    } as FormItem);
    const keyringField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.KEYRING_ID,
      label: 'KMS Keyring',
      field: 'dropdown',
      options: [] as any[],
      refreshOptionsEvent: new EventEmitter<any>(),
      actionButtons: [
        {
          icon: 'fa fa-plus',
          label: 'Add New Keyring',
          styleClass: 'p-button-success',
          command: () => {
            window.open(
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}` +
                `/${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}` +
                `/${PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE}` +
                `/${PROJECT_SETTINGS_CONSTANTS.KMS.ROUTE}`,
              '_blank',
            );
          },
        },
      ] as any[],
      placeholder: 'Select a keyring',
      fieldInfo: 'Keyring',
      defaultValue: null,
      required: true,
    } as FormItem);

    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        field: 'text',
        labelStyleClass: 'font-bold',
        label: 'Create or import a client certificate to the device.',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.OPTION_SELECT,
        hasNoLabel: true,
        layout: 'horizontal',
        field: 'radio',
        options: this.util.cloneObjectArray(FORM_SELECT_OPTIONS),
        defaultValue: SELECT_OPTIONS_VALUES.CREATE,
        required: true,
        focused: true,
      } as FormItem),

      // ------------ IMPORT -------------------
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CLIENT_CERTIFICATE_FILE,
        label: 'Client CA Certificate',
        field: 'file',
        fieldInfo: 'Client CA Certificate',
        uploadEvent: new EventEmitter(),
        required: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CLIENT_CERTIFICATE,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CERTIFICATE_DESTINATION,
        label: 'Destination',
        field: 'dropdown',
        options: this.util.cloneObjectArray(KEYRING_TYPES),
        defaultValue: KEYRING_TYPE_VALUES.FILESYSTEM,
        fieldInfo: 'Client Certificate Destination',
        required: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PATH_TO_UPLOAD,
        label: 'Path to Upload',
        field: 'input',
        fieldInfo: 'Path to upload',
        placeholder: '/tmp',
        required: false,
        hidden: true,
      } as FormItem),
      // --------------- CREATE ---------------------
      commonNameField,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COUNTRY,
        label: 'Country Name',
        field: 'dropdown',
        options: this.util.cloneObjectArray(COUNTRIES),
        filter: true,
        placeholder: 'Select a country',
        required: true,
        fieldInfo: 'Country name',
        defaultValue: 'SG',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.STATE,
        label: 'State or Province Name',
        field: 'input',
        pattern: STRICT_ALPHANUMERIC_PATTERN,
        patternErrorText,
        fieldInfo: 'State or province name',
        required: true,
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LOCALITY,
        label: 'Locality Name',
        field: 'input',
        pattern: STRICT_ALPHANUMERIC_PATTERN,
        patternErrorText,
        required: true,
        fieldInfo: 'Locality name',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ORGANIZATION,
        label: 'Organization Name',
        field: 'input',
        required: true,
        pattern: STRICT_ALPHANUMERIC_PATTERN,
        patternErrorText,
        fieldInfo: 'Organization name',
        defaultValue: '',
        hidden: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ORGANIZATION_UNIT,
        label: 'Organization Unit',
        field: 'input',
        required: true,
        pattern: STRICT_ALPHANUMERIC_PATTERN,
        patternErrorText,
        fieldInfo: 'Organization unit',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_DIVIDER,
        field: 'divider',
        hidden: false,
      } as FormItem),
      // ------------------ CA Inputs --------------------
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_SERVER_ID,
        label: 'CA Server',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA server',
        fieldInfo: 'CA server',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_ID,
        label: 'CA Certificate',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA certificate',
        fieldInfo: 'CA certificate',
        required: true,
        disabled: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TEMPLATE_ID,
        label: 'CA Template',
        options: [] as any[],
        field: 'dropdown',
        placeholder: 'Select a CA template',
        fieldInfo: 'CA template',
        disabled: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEYRING_DIVIDER,
        field: 'divider',
        hidden: false,
      } as FormItem),
      // ---------------------- Key Input Details -----------------------
      keyringField,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_DESTINATION,
        label: 'Key Destination',
        field: 'dropdown',
        fieldInfo: 'Key Destination',
        options: this.util.cloneObjectArray(KEYRING_TYPES),
        defaultValue: KEYRING_TYPE_VALUES.FILESYSTEM,
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_PATH,
        label: 'Key Destination Path',
        field: 'input',
        fieldInfo: 'Key Destination Path',
        placeholder: '/tmp',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CERT_DESTINATION_PATH,
        label: 'Certificate Destination Path',
        field: 'input',
        fieldInfo: 'Certificate Destination Path',
        placeholder: '/tmp',
        required: true,
      } as FormItem),
      // ---------------------- Miscellaneous --------------------------
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ORGANIZATION_ID,
        label: 'Organization ID',
        defaultValue: this.breadcrumbConfig?.organizationId,
        fieldInfo: 'Organization unit',
        disabled: true,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        label: 'Organization ID',
        defaultValue: this.breadcrumbConfig?.projectId,
        fieldInfo: 'Organization unit',
        disabled: true,
        hidden: true,
      } as FormItem),
    ];
    keyringField.refreshOptionsEvent?.subscribe(() => {
      this.getKeyrings();
    });
    this.fields = fields;
    this.setupUploadEvent();
    setTimeout(() => {
      // Setup change events
      this.setupEvents();
      setTimeout(() => {
        this.getCAConnections();
        this.getKeyrings();
      });
    });
  }

  /**
   * Setup CSR file upload event
   */
  setupUploadEvent() {
    const fileField = this.fields.find((p) => p.name === FORM_PARAMS.CLIENT_CERTIFICATE_FILE);
    if (!!fileField) {
      fileField.uploadEvent?.subscribe((event: any) => {
        this.form.isLoading = true;
        if (!!event.target && !!event.target.files && !!event.target.files.length) {
          const file: any = (event.target.files as FileList).item(0);
          this.form.setControlValue(FORM_PARAMS.CLIENT_CERTIFICATE_FILE, file);
          const reader = new FileReader();
          reader.onload = () => {
            this.form.isLoading = false;
            this.form.setControlValue(FORM_PARAMS.CLIENT_CERTIFICATE, reader.result?.toString());
          };
          reader.readAsText(file);
        } else {
          this.form.isLoading = false;
          this.form.setControlValue(FORM_PARAMS.CLIENT_CERTIFICATE_FILE, null);
          this.form.setControlValue(FORM_PARAMS.CLIENT_CERTIFICATE, null);
        }
      });
    }
  }

  /**
   * Setup Change Event
   */
  setupEvents() {
    this.form.setChangeEvent(FORM_PARAMS.OPTION_SELECT, (option) => {
      const importValidator = option === SELECT_OPTIONS_VALUES.IMPORT ? [VALIDATOR_TYPE.REQUIRED] : [];
      const createValidator = option === SELECT_OPTIONS_VALUES.CREATE ? [VALIDATOR_TYPE.REQUIRED] : [];

      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CLIENT_CERTIFICATE_FILE, importValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CERTIFICATE_DESTINATION, importValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.PATH_TO_UPLOAD, importValidator);

      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.COMMON_NAME, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.COUNTRY, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.STATE, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.LOCALITY, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.ORGANIZATION, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.ORGANIZATION_UNIT, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CA_SERVER_ID, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CA_ID, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.TEMPLATE_ID, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEYRING_ID, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_DESTINATION, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_PATH, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CERT_DESTINATION_PATH, createValidator);

      this.form.setControlVisibility(FORM_PARAMS.CA_DIVIDER, option === SELECT_OPTIONS_VALUES.CREATE);
      this.form.setControlVisibility(FORM_PARAMS.KEYRING_DIVIDER, option === SELECT_OPTIONS_VALUES.CREATE);
    });
  }

  /**
   * Import CA certificate
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = this.getPayload();
    this.form.isLoading = true;
    const importForm = this.form.getControlValue(FORM_PARAMS.OPTION_SELECT) === SELECT_OPTIONS_VALUES.IMPORT;
    const observable = !!importForm
      ? this.deviceSrv.importDeviceCryptoAssetClientCert(this.device.id, payload)
      : this.deviceSrv.createDeviceCryptoAssetClientCert(this.device.id, payload);

    observable
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(
            importForm
              ? `Client certificate import is being processed.`
              : 'Certificate creation is being initiated. Check the events tab for details.',
          );
          closeDialog();
        },
        error: (err) => {
          if (!!err?.error?.errors?.[FORM_PARAMS.CLIENT_CERTIFICATE]) {
            // const errors = err.error.errors;
            // errors[FORM_PARAMS.CLIENT_CERTIFICATE_FILE] = errors?.[FORM_PARAMS.CLIENT_CERTIFICATE];
            this.form.showServerErrorMessage(err);
          }
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * modify payload
   */
  getPayload() {
    const payload = this.form.getRawValue();

    delete payload[FORM_PARAMS.CA_DIVIDER];
    delete payload[FORM_PARAMS.KEYRING_DIVIDER];
    delete payload[FORM_PARAMS.OPTION_SELECT];
    delete payload[FORM_PARAMS.CLIENT_CERTIFICATE_FILE];

    switch (this.form.getControlValue(FORM_PARAMS.OPTION_SELECT)) {
      case SELECT_OPTIONS_VALUES.CREATE: {
        delete payload[FORM_PARAMS.CLIENT_CERTIFICATE_FILE];
        delete payload[FORM_PARAMS.CERTIFICATE_DESTINATION];
        delete payload[FORM_PARAMS.PATH_TO_UPLOAD];
        break;
      }
      case SELECT_OPTIONS_VALUES.IMPORT: {
        delete payload[FORM_PARAMS.COMMON_NAME];
        delete payload[FORM_PARAMS.COUNTRY];
        delete payload[FORM_PARAMS.STATE];
        delete payload[FORM_PARAMS.LOCALITY];
        delete payload[FORM_PARAMS.ORGANIZATION];
        delete payload[FORM_PARAMS.ORGANIZATION_UNIT];
        delete payload[FORM_PARAMS.CA_SERVER_ID];
        delete payload[FORM_PARAMS.CA_ID];
        delete payload[FORM_PARAMS.TEMPLATE_ID];
        delete payload[FORM_PARAMS.KEYRING_ID];
        delete payload[FORM_PARAMS.KEY_DESTINATION];
        delete payload[FORM_PARAMS.KEY_PATH];
        delete payload[FORM_PARAMS.CERT_DESTINATION_PATH];
        break;
      }
      default: {
        break;
      }
    }

    return payload;
  }

  /**
   * Get the list of CA connections
   */
  getCAConnections() {
    this.form.isLoading = true;
    this.caManagementSrv
      .getCAManagers(this.breadcrumbConfig?.projectId as number)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
          if (!!caServerField) {
            caServerField.options = ((rs?.data as any[]) || []).map((p) => ({
              ...p,
              value: p.id,
              label: p.name,
            }));
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get Keyrings and Populate Keyring Field on Form
   */
  getKeyrings() {
    this.form.isLoading = true;
    this.kmsSrv
      .getKeyrings(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const keyringField = this.fields.find((field) => field.name === FORM_PARAMS.KEYRING_ID);
          if (!!keyringField) {
            keyringField.options = this.util.sortObjectArray(
              (
                rs?.data.filter(
                  (keyring: any) => keyring.type === KEYRING_TYPE_VALUES.FILESYSTEM && keyring.name !== MICROSEC_DEFAULT_KEYRING,
                ) as any[]
              ).map((p: any) => ({
                value: p.id,
                label: p.name,
              })),
              'label',
            );
            if (!keyringField.options.find((p) => p.value === this.form.getControlValue(FORM_PARAMS.KEYRING_ID))) {
              this.form.setControlValue(FORM_PARAMS.KEYRING_ID, null);
            }
          }
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
