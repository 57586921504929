export const REPOSITORY_MANAGEMENT_CONSTANTS = {
  OVERIVEW: {
    ROUTE: 'overview',
    LABEL: 'Overview',
  },
  CUSTOM: {
    ROUTE: 'custom',
    LABEL: 'Custom Repositories',
  },
  MIRROR: {
    ROUTE: 'mirror',
    LABEL: 'Mirror Repositories',
  },
};

export const MICROSEC_MICROAGENT_REPO = 'microagent_repository';
