import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { fromUserCertificatesActions, userCertificatesSelectors } from '@ngrx-user-certificates';
import { BaseComponent } from '@lcms-components';
import { USER_ROLE, APPROVE_LABEL, BLOCK_LABEL, DELETE_LABEL, RETIRE_LABEL, SCOPE, UNBLOCK_LABEL } from '@microsec/constants';
import { ConfirmationDialogConfig, DetailsPanelButtonModel } from '@microsec/models';

@Component({
  selector: 'app-user-certificate-info',
  templateUrl: './user-certificate-info.component.html',
  styleUrls: ['./user-certificate-info.component.scss'],
})
export class UserCertificateInfoComponent extends BaseComponent implements AfterContentInit {
  isLoading = false;

  @Input() selectedManager: any = null;

  @Output() selectedManagerChange: EventEmitter<any> = new EventEmitter<any>();

  actionButtons: DetailsPanelButtonModel[] = [];

  async ngAfterContentInit() {
    await this.prepareConfigs();
    this.prepareActionButtons();
    this.store.select(userCertificatesSelectors.selectedManager).subscribe((manager) => {
      if (!!manager) {
        this.selectedManager = manager;
        this.prepareActionButtons();
      }
    });
    this.subscriptions.push(
      this.store.select(userCertificatesSelectors.isLoading).subscribe((isLoading) => {
        this.isLoading = isLoading;
      }),
    );
  }

  /**
   * Prepare action buttons
   */
  prepareActionButtons() {
    this.actionButtons = [
      {
        label: APPROVE_LABEL,
        icon: 'fas fa-user-check',
        disabled: this.selectedManager?.status === 'Retired',
        hidden: this.selectedManager?.status !== 'Not Enrolled' || !this.permissions[SCOPE.PROJECT][USER_ROLE.ADMIN],
        command: () => {
          this.updateUserStatus('Enrolled');
        },
      },
      {
        label: UNBLOCK_LABEL,
        hidden: this.selectedManager?.status !== 'Blocked' || !this.permissions[SCOPE.PROJECT][USER_ROLE.ADMIN],
        command: () => {
          this.updateUserStatus('Enrolled');
        },
      },
      {
        isActionButton: true,
        hidden:
          this.selectedManager?.status === 'Not Enrolled' || this.selectedManager?.status === 'Blocked' || this.selectedManager?.status === 'Retired',
        children: [
          {
            label: BLOCK_LABEL,
            disabled: this.selectedManager?.status !== 'Enrolled',
            visible: this.selectedManager?.status !== 'Blocked' && !!this.permissions[SCOPE.PROJECT][USER_ROLE.ADMIN],
            command: () => {
              this.updateUserStatus('Blocked');
            },
          },
          {
            label: RETIRE_LABEL,
            disabled: this.selectedManager?.status !== 'Enrolled',
            visible: !!this.permissions[SCOPE.PROJECT][USER_ROLE.ADMIN],
            command: () => {
              this.updateUserStatus('Retired');
            },
          },
        ],
      },
      {
        label: DELETE_LABEL,
        hidden: !(this.permissions as any)[SCOPE.PROJECT][USER_ROLE.ADMIN],
        command: () => {
          this.confirmUserDeletion();
        },
      },
    ];
  }

  /**
   * Unselect the manager
   */
  unselectManager() {
    this.selectedManagerChange.emit(null);
  }

  /**
   * Update user set's status
   * @param status
   */
  updateUserStatus(status?: string) {
    const executeFn = () => {
      const payload = { status };
      this.store.dispatch(new fromUserCertificatesActions.UpdateManager(this.selectedManager, payload));
    };
    switch (status) {
      case 'Retired': {
        this.confirm({
          action: RETIRE_LABEL,
          objectName: 'User Certificates Set',
          object: this.selectedManager,
          objectFieldName: 'name',
          shouldShowDefaultContent: true,
          customContent: 'You will no longer be able to request certificates for this user.',
          next: () => {
            executeFn();
          },
        } as ConfirmationDialogConfig);
        break;
      }
      case 'Blocked': {
        this.confirm({
          action: BLOCK_LABEL,
          objectName: 'User Certificates Set',
          object: this.selectedManager,
          objectFieldName: 'name',
          shouldShowDefaultContent: true,
          customContent: 'You can unblock the user certificates set afterwards again.',
          next: () => {
            executeFn();
          },
        } as ConfirmationDialogConfig);
        break;
      }
      default: {
        executeFn();
        break;
      }
    }
  }

  /**
   * Confirm user set's deletion
   */
  confirmUserDeletion() {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'User Certificates Set',
      object: this.selectedManager,
      objectFieldName: 'name',
      prepareRequest: () => {
        this.isLoading = true;
      },
      next: () => {
        this.store.dispatch(new fromUserCertificatesActions.DeleteManager(this.selectedManager));
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.showErrorMessage(error);
      },
    } as ConfirmationDialogConfig);
  }
}
