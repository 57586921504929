import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENTATION_URL, DOMAIN_TOKEN, MICROSEC_NAME } from '@microsec/constants';
import { MenuItem } from 'primeng/api';
import { finalize } from 'rxjs';
import { LogService } from '@lcms-services';

@Component({
  template: '',
  styleUrls: ['./base-app-component.component.scss'],
})
export class BaseAppComponent implements OnInit {
  constructor(
    protected titleSrv: Title,
    protected logSrv: LogService,
  ) {}

  customTopNavButtons: MenuItem[] = [
    {
      label: 'Info',
      icon: 'info-circle',
      visible: true,
      items: [
        {
          separator: true,
        },
        {
          label: 'Documentation',
          command: () => {
            const customDomain = localStorage.getItem('domain');
            if (!!customDomain) {
              window.open(DOCUMENTATION_URL.replace(DOMAIN_TOKEN, customDomain), '_blank');
            }
          },
        },
      ],
    },
  ];

  ngOnInit() {
    this.getApplicationVersion();
  }

  getApplicationVersion() {
    let appVersionItem: MenuItem | null = null;
    const appTitle = this.titleSrv.getTitle();
    this.logSrv
      .getVersions()
      .pipe(
        finalize(() => {
          const items: MenuItem[] = [
            {
              label: appTitle.replace(MICROSEC_NAME, '').trim(),
              disabled: true,
              styleClass: 'font-bold',
            },
          ];
          if (!!appVersionItem) {
            items.push(appVersionItem);
          }
          this.customTopNavButtons[0].items?.unshift(...items);
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const versionObject = (rs as any) || {};
          const foundTitle = appTitle.replace(/ /g, '-').toLowerCase();
          const versionArray = ((versionObject?.[foundTitle] as string) || '').split('-');
          if (!!versionArray?.length) {
            appVersionItem = {
              label: `v${versionArray?.[0]} (${versionArray?.[1]})`,
              disabled: true,
              style: { 'margin-top': '-1rem' },
            } as MenuItem;
          }
        },
        error: () => {
          // Do not show the version
        },
      });
  }
}
