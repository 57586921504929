import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './lib/base-component/base.component';
import { BaseAppComponent } from './lib/base-app-component/base-app-component.component';

@NgModule({
  declarations: [BaseAppComponent, BaseComponent],
  imports: [CommonModule],
  exports: [BaseAppComponent, BaseComponent],
})
export class ComponentsModule {}
