<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="container-border">
    <microsec-details-panel [title]="firmware?.name" [buttons]="actionButtons" (collapseEvent)="unselectFirmware()">
      <p-tabView [scrollable]="true" styleClass="microsec-dashboard-tab">
        <p-tabPanel header="Overview">
          <app-firmware-overview [firmware]="firmware" />
        </p-tabPanel>
        <p-tabPanel header="Assigned Devices">
          <app-firmware-assigned-devices [firmware]="firmware" (refreshFirmwareEvent)="refreshFirmware()" />
        </p-tabPanel>
      </p-tabView>
    </microsec-details-panel>
  </div>
</microsec-loading-overlay>
