import { FEATURES, SYSTEM_MANAGEMENT_FEATURES } from '@lcms-constants';
import { GLOBAL_SETTINGS_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { ConfigurationComponent } from './configuration/configuration.component';
import { RsaComponent } from './rsa/rsa.component';

export const CONFIG_2FA_CONSTANTS = {
  CONFIGURATION: {
    ROUTE: 'configuration',
    LABEL: 'Configuration',
  },
  RSA: {
    ROUTE: 'rsa-settings',
    LABEL: 'RSA SecurID Settings',
  },
};

export const CONFIG_2FA_CHILDREN: ILayoutItem[] = [
  {
    group: GLOBAL_SETTINGS_CONSTANTS.CONFIG_2FA.ROUTE,
    label: '',
    route: CONFIG_2FA_CONSTANTS.CONFIGURATION.ROUTE,
  },
  {
    group: GLOBAL_SETTINGS_CONSTANTS.CONFIG_2FA.ROUTE,
    route: CONFIG_2FA_CONSTANTS.CONFIGURATION.ROUTE,
    label: CONFIG_2FA_CONSTANTS.CONFIGURATION.LABEL,
    component: ConfigurationComponent,
  },
  {
    group: GLOBAL_SETTINGS_CONSTANTS.CONFIG_2FA.ROUTE,
    route: CONFIG_2FA_CONSTANTS.RSA.ROUTE,
    label: CONFIG_2FA_CONSTANTS.RSA.LABEL,
    component: RsaComponent,
    disabled: true,
    featureName: FEATURES.SYSTEM_MANAGEMENT,
    featureCheckField: SYSTEM_MANAGEMENT_FEATURES.AUTH_RSA_SECURID,
  },
];
