import { Component, Input, TemplateRef } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { ActionMenuItem, ConfirmationDialogConfig } from '@microsec/models';
import { RuleFormComponent } from './rule-form/rule-form.component';
import { CREATE_LABEL, DELETE_LABEL, EDIT_LABEL } from '@microsec/constants';
import { RuleService } from '@lcms-services';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-ruleset-rules',
  templateUrl: './ruleset-rules.component.html',
  styleUrls: ['./ruleset-rules.component.scss'],
})
export class RulesetRulesComponent extends BaseComponent {
  isLoading = false;

  _selectedRuleset: any = null;

  @Input() set selectedRuleset(value: any) {
    this._selectedRuleset = value;
    this.getRules();
  }

  get selectedRuleset() {
    return this._selectedRuleset;
  }

  _type = '';

  get type() {
    return `${this._type} Rule`;
  }

  @Input() set type(value: any) {
    this._type = value;
    this.initColumns();
  }

  @Input() cellTemplate?: TemplateRef<any> = undefined;

  cols: any[] = [];

  selectedRule: any = null;

  values: any[] = [];

  actionMenuItems: ActionMenuItem[] = [
    {
      label: 'Edit',
      icon: 'fas fa-edit',
      command: ({ rowData }: any) => this.openForm(rowData),
    },
    {
      label: 'Delete',
      icon: 'fas fa-trash',
      command: ({ rowData }: any) => this.confirmDeletion(rowData),
    },
  ];

  constructor(private ruleSrv: RuleService) {
    super();
  }

  /**
   * Open rule form
   * @param rule
   */
  openForm(rule?: any) {
    const dialog = this.dialogSrv.open(RuleFormComponent, {
      header: `${!rule ? CREATE_LABEL : EDIT_LABEL} ${this.type}`,
      data: { type: this._type, ruleset: this.selectedRuleset, rule },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getRules();
      }
    });
  }

  /**
   * Get rules
   */
  getRules() {
    this.isLoading = true;
    this.ruleSrv
      .getRules(this.selectedRuleset?.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (results) => {
          this.values = results;
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get rule columns
   */
  initColumns() {
    switch (this._type) {
      case 'IP': {
        this.cols = [
          { field: 'name', header: 'Name', width: 10 },
          { field: 'ip_protocol', header: 'Protocol', width: 6 },
          { field: 'src_ip', header: 'Source', width: 7 },
          { field: 'dst_ip', header: 'Destination', width: 7 },
          { field: 'target', header: 'Target', width: 5 },
        ];
        break;
      }
      case 'CAN': {
        this.cols = [
          { field: 'name', header: 'Name', width: 10 },
          { field: 'target', header: 'Target', width: 5 },
          { field: 'can_version', header: 'CAN Version', width: 6 },
          { field: 'can_id', header: 'CAN Message ID', width: 7 },
        ];
        break;
      }
      case 'Command': {
        this.cols = [
          { field: 'name', header: 'Name', width: 10 },
          { field: 'command', header: 'Command', width: 10 },
          { field: 'action', header: 'Strategy', width: 10 },
        ];
        break;
      }
      case 'YARA': {
        this.cols = [
          { field: 'name', header: 'Name', width: 10 },
          { field: 'trigger', header: 'Rule', width: 10 },
          { field: 'mode', header: 'Mode', width: 10 },
          { field: 'action', header: 'Strategy', width: 10 },
        ];
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Confirm deletion
   * @param rule
   */
  confirmDeletion(rule?: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Rule',
      object: rule,
      objectFieldName: 'name',
      acceptRequest: this.ruleSrv.deleteRule(this.selectedRuleset?.id, rule?.rule_id),
      next: () => {
        this.showSuccessMessage(`Deleted rule successfully`);
        this.selectedRule = null;
        this.getRules();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }
}
