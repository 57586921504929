import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { SYSTEM_MANAGEMENT_FEATURES } from '@lcms-constants';
import { Setting2FAService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { SAVE_CHANGES_LABEL, SAVE_SUCCESS } from '@microsec/constants';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  RADIUS_SERVER_ADDRESS: 'radius_server_address',
  RADIUS_PORT: 'radius_port',
  RADIUS_CLIENT_PASSWORD: 'radius_client_password',
  AUTH_TYPE: 'auth_type',
};

@Component({
  selector: 'app-rsa',
  templateUrl: './rsa.component.html',
  styleUrls: ['./rsa.component.scss'],
})
export class RsaComponent extends BaseComponent implements AfterViewInit {
  isTestLoading = false;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('testRadiusConnectionTemplate') testRadiusConnectionTemplateComp!: TemplateRef<any>;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(private setting2FASrv: Setting2FAService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initForm();
    this.setting2FASrv.getOpenGlobalSetting().subscribe({
      next: (response: any) => {
        if (
          response.is_enabled &&
          response.auth_provider === 'rsa' &&
          !!this.checkSystemManagementFeatureEnabled(SYSTEM_MANAGEMENT_FEATURES.AUTH_RSA_SECURID)
        ) {
          this.getRSASetting();
        } else {
          this.navigateToNotFoundPage();
        }
      },
      error: (err: any) => {
        this.showErrorMessage(err);
        this.navigateToNotFoundPage();
      },
    });
  }

  /**
   * Initialize the form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RADIUS_SERVER_ADDRESS,
        label: 'RADIUS Server',
        field: 'input',
        required: true,
        fieldInfo: 'RADIUS server',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RADIUS_PORT,
        label: 'RADIUS Port',
        field: 'number',
        required: true,
        fieldInfo: 'RADIUS port',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RADIUS_CLIENT_PASSWORD,
        label: 'RADIUS Client Password',
        field: 'password',
        feedback: false,
        required: true,
        fieldInfo: 'RADIUS client password',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.AUTH_TYPE,
        label: 'Authentication Type',
        field: 'radio',
        options: [
          { value: 'fob', label: 'FoB' },
          { value: 'pinpad', label: 'PinPad' },
        ],
        layout: 'vertical',
        required: true,
        fieldInfo: 'Authentication type',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: '',
        field: 'custom',
        customField: this.testRadiusConnectionTemplateComp,
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
  }

  /**
   * Get RSA Setting
   */
  getRSASetting() {
    this.setting2FASrv
      .getRSASetting()
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          this.form.patchValue(response);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Test RSA connection
   */
  testRSAConnection() {
    this.isTestLoading = true;
    const payload = { ...this.form.getValue() };
    this.setting2FASrv
      .testRSAConnection(payload)
      .pipe(
        finalize(() => {
          this.isTestLoading = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          if (!!response?.message) {
            this.showSuccessMessage(response?.message);
          } else {
            this.showSuccessMessage('Test RSA connection successfully');
          }
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Submit the form data
   */
  onSubmit() {
    const payload = { ...this.form.getValue() };
    this.setting2FASrv
      .updateRSASetting(payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(SAVE_SUCCESS.replace('{0}', 'RSA setting'));
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
