import { AfterContentInit, Component, Input } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { DEVICE_MANAGEMENT_FEATURES, DEVICE_STATUSES, DEVICE_TYPE } from '@lcms-constants';
import { devicesSelectors, fromDevicesActions } from '@ngrx-devices';
import { DeviceService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { ConfirmationDialogConfig, DetailsPanelButtonModel } from '@microsec/models';
import {
  BLOCK_LABEL,
  DELETE_LABEL,
  ENROLL_LABEL,
  FORCE_DELETE_LABEL,
  FORCE_REMOVE_MESSAGE_TEMPLATE,
  RETIRE_LABEL,
  SCOPE,
  UNBLOCK_LABEL,
  UPDATE_LABEL,
} from '@microsec/constants';

@Component({
  selector: 'app-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss'],
})
export class DeviceInfoComponent extends BaseComponent implements AfterContentInit {
  isLoading = false;

  activeIndex = 0;

  @Input() labels: any[] = [];

  selectedDevice: any = null;

  DEVICE_TYPE_LABELS = DEVICE_TYPE;

  X509_DEVICE_STATUSES = DEVICE_STATUSES;

  hasPackages = false;

  maxHeight = '50vh';

  actionButtons: DetailsPanelButtonModel[] = [];

  constructor(private deviceSrv: DeviceService) {
    super();
  }

  async ngAfterContentInit() {
    await this.prepareConfigs();
    this.store.select(devicesSelectors.selectedDevice).subscribe((selectedDevice) => {
      this.selectedDevice = selectedDevice;
      this.prepareActionButtons();
    });
    this.store.select(devicesSelectors.isLoading).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }

  /**
   * Unselect a device
   */
  unselectDevice() {
    this.store.dispatch(new fromDevicesActions.SelectDevice(null));
  }

  /**
   * Prepare action buttons
   */
  prepareActionButtons() {
    this.actionButtons = [];
    switch (this.selectedDevice?.device_type) {
      case DEVICE_TYPE.X509: {
        this.prepareX509DeviceActionButtons();
        break;
      }
      case DEVICE_TYPE.MICRO_PKI: {
        this.prepareMicroPKIDeviceActionButtons();
        break;
      }
      default: {
        break;
      }
    }
    this.actionButtons.push({
      label: DELETE_LABEL,
      hidden: !this.permissions[SCOPE.PROJECT][USER_ROLE.ADMIN],
      command: () => {
        this.confirmDeviceDeletion();
      },
    });
  }

  /**
   * Prepare X509 device action buttons
   */
  private prepareX509DeviceActionButtons() {
    this.actionButtons = [
      {
        label: ENROLL_LABEL,
        svgIcon: 'icon_laptop_enrol',
        disabled: this.selectedDevice?.status === 'Retired',
        hidden:
          !!this.selectedDevice?.uses_otp || this.selectedDevice?.status !== 'Not Enrolled' || !this.permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN],
        command: () => {
          this.updateDeviceStatus('Pending Enrollment');
        },
      },
      {
        label: UNBLOCK_LABEL,
        hidden: this.selectedDevice?.status !== 'Blocked' || !this.permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN],
        command: () => {
          this.updateDeviceStatus('Enrolled');
        },
      },
      {
        isActionButton: true,
        hidden:
          this.selectedDevice?.status === 'Not Enrolled' || this.selectedDevice?.status === 'Blocked' || this.selectedDevice?.status === 'Retired',
        children: [
          {
            label: BLOCK_LABEL,
            disabled: this.selectedDevice?.status !== 'Enrolled',
            visible: this.selectedDevice?.status !== 'Blocked' && !!this.permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN],
            command: () => {
              this.updateDeviceStatus('Blocked');
            },
          },
          {
            label: RETIRE_LABEL,
            disabled: this.selectedDevice?.status !== 'Enrolled',
            visible: !!this.permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN],
            command: () => {
              this.updateDeviceStatus('Retired');
            },
          },
        ],
      },
    ];
  }

  /**
   * Prepare MicroPKI device action buttons
   */
  private prepareMicroPKIDeviceActionButtons() {
    this.actionButtons = [
      {
        label: UNBLOCK_LABEL,
        disabled: !!this.isProcessing(this.selectedDevice?.status) || (this.selectedDevice?.status as string)?.toLowerCase() === 'retired',
        hidden:
          ((this.selectedDevice?.status as string)?.toLowerCase() !== 'blocked' &&
            (this.selectedDevice?.status as string)?.toLowerCase() !== 'unblocking') ||
          !this.permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN],
        command: () => {
          this.updateDeviceStatus('Unblocking');
        },
      },
      {
        isActionButton: true,
        hidden: this.selectedDevice?.status === 'Blocked',
        children: [
          {
            label: BLOCK_LABEL,
            disabled: !!this.isProcessing(this.selectedDevice?.status) || (this.selectedDevice?.status as string)?.toLowerCase() === 'retired',
            visible:
              (this.selectedDevice?.status as string)?.toLowerCase() !== 'blocked' &&
              (this.selectedDevice?.status as string)?.toLowerCase() !== 'unblocking' &&
              !!this.permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN],
            command: () => {
              this.updateDeviceStatus('Blocking');
            },
          },
          {
            label: RETIRE_LABEL,
            disabled: !!this.isProcessing(this.selectedDevice?.status) || (this.selectedDevice?.status as string)?.toLowerCase() === 'retired',
            visible: !!this.permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN],
            command: () => {
              this.updateDeviceStatus('Retiring');
            },
          },
          {
            label: UPDATE_LABEL,
            disabled: !!this.isProcessing(this.selectedDevice?.status) || (this.selectedDevice?.status as string)?.toLowerCase() === 'retired',
            visible: !!this.permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN],
            command: () => {
              this.updateDeviceStatus('Updating');
            },
          },
        ],
      },
    ];
  }

  /**
   * Update device status
   * @param status
   */
  updateDeviceStatus(status?: string) {
    const executeFn = () => {
      if (this.selectedDevice.device_type === DEVICE_TYPE.X509) {
        const payload = { status };
        this.store.dispatch(new fromDevicesActions.UpdateDevice(this.selectedDevice, payload));
      } else {
        this.deviceSrv.mqtt?.unsafePublish(
          `/dev/${this.selectedDevice.x509_issued_country}/${this.selectedDevice.x509_issued_org}/` +
            `${this.selectedDevice.x509_issued_org_unit}/${this.selectedDevice.x509_common_name}/micropki/operation`,
          JSON.stringify({
            upki_device_id: this.selectedDevice.upki_device_id,
            upki_network_id: this.selectedDevice.upki_network_id,
            operation: status?.toLocaleLowerCase(),
            payload: '',
          }),
          { qos: 2, retain: false },
        );
        this.showSuccessMessage(`${status} device is being processed`);
        this.store.dispatch(new fromDevicesActions.RefreshDevices());
      }
    };
    switch (status) {
      case 'Retired':
      case 'Retiring': {
        this.confirm({
          action: RETIRE_LABEL,
          objectName: 'Device',
          object: this.selectedDevice,
          objectFieldName: 'common_name',
          shouldShowDefaultContent: true,
          customContent: 'You will no longer be able to request certificates for this device.',
          next: () => {
            executeFn();
          },
        } as ConfirmationDialogConfig);
        break;
      }
      case 'Blocked':
      case 'Blocking': {
        this.confirm({
          action: BLOCK_LABEL,
          objectName: 'Device',
          object: this.selectedDevice,
          objectFieldName: 'common_name',
          shouldShowDefaultContent: true,
          customContent: 'You can unblock the device afterwards again.',
          next: () => {
            executeFn();
          },
        } as ConfirmationDialogConfig);
        break;
      }
      default: {
        executeFn();
        break;
      }
    }
  }

  /**
   * Check if the device is processing
   * @param status
   * @returns
   */
  isProcessing(status: any) {
    const lowercaseStatus = (status as string)?.toLowerCase();
    return lowercaseStatus === 'blocking' || lowercaseStatus === 'unblocking' || lowercaseStatus === 'retiring' || lowercaseStatus === 'updating';
  }

  /**
   * Confirm device deletion
   */
  confirmDeviceDeletion() {
    // Normal confirmation
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Device',
      object: this.selectedDevice,
      objectFieldName: 'common_name',
      next: () => {
        this.store.dispatch(
          new fromDevicesActions.DeleteDevice(this.selectedDevice, false, () => {
            // Force confirmation
            this.confirm({
              isDependenciesDetected: true,
              action: FORCE_DELETE_LABEL,
              customContent: FORCE_REMOVE_MESSAGE_TEMPLATE.replace('{0}', 'device'),
              shouldShowDefaultContent: false,
              next: () => {
                this.store.dispatch(new fromDevicesActions.DeleteDevice(this.selectedDevice, true));
              },
            } as ConfirmationDialogConfig);
          }),
        );
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Check if the feature is enabled
   * @param tabName
   * @returns
   */
  checkFeature(tabName: string) {
    let result = false;
    switch (tabName) {
      case 'telemetry': {
        result = this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.TELEMETRY);
        break;
      }
      case 'packages': {
        result = this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.PACKAGES);
        break;
      }
      case 'firmware': {
        result = this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.FIRMWARE);
        break;
      }
      case 'firewalls': {
        result = this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.FIREWALL);
        break;
      }
      case 'keys': {
        result = this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.KEYS);
        break;
      }
      default: {
        break;
      }
    }
    return result;
  }

  /**
   * Check if the tab is enabled
   */
  get isTabEnabled() {
    return (
      this.selectedDevice?.device_type === DEVICE_TYPE.X509 &&
      this.selectedDevice?.status === DEVICE_STATUSES.ENROLLED &&
      !this.selectedDevice?.is_manual &&
      !this.selectedDevice?.back_imported &&
      !!this.selectedDevice?.uses_agent
    );
  }
}
