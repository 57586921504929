<div class="grid form dark-form no-wrapper">
  <div class="col-12 lg:col-6">
    <microsec-card [isFull]="true">
      <div class="grid">
        <label class="col-12 text-xl font-bold">Licensed to</label>

        <!-- <label class="col-5 lg:col-3 text-base text-right">Name:</label>
        <label class="col-6 lg:col-8 text-base font-bold ml-2"></label> -->

        <label class="col-5 lg:col-3 text-base text-right">Email:</label>
        <label class="col-6 lg:col-8 text-base font-bold ml-2">{{ data.contact_email }}</label>

        <label class="col-5 lg:col-3 text-base text-right">Organization:</label>
        <label class="col-6 lg:col-8 text-base font-bold ml-2">{{ data.company_name }}</label>
      </div>
    </microsec-card>
  </div>

  <div class="col-12 lg:col-6">
    <microsec-card [isFull]="true">
      <div class="grid">
        <label class="col-12 text-xl font-bold">Download License</label>
        <label class="col-12 text-base font-bold"> Use this option to download current license or upgrade </label>
        <div class="col-12">
          <p-button severity="primary" label="Download License" [disabled]="true" />
          &nbsp;
          <p-button [autofocus]="true" severity="primary" label="Upgrade License" (click)="goToUpload()" />
        </div>
      </div>
    </microsec-card>
  </div>

  <div class="col-12 lg:col-6">
    <microsec-card [isFull]="true">
      <div class="grid">
        <label class="col-12 text-xl font-bold">Details</label>

        <label class="col-5 lg:col-3 text-base text-right">Plan:</label>
        <label class="col-6 lg:col-8 text-base font-bold ml-2">{{ data.name }}</label>

        <label class="col-5 lg:col-3 text-base text-right">Uploaded:</label>
        <label class="col-6 lg:col-8 text-base font-bold ml-2">{{ getFromNowTime(data.modified) }}</label>

        <label class="col-5 lg:col-3 text-base text-right">Started:</label>
        <label class="col-6 lg:col-8 text-base font-bold ml-2">{{ getFormattedDate(data.valids_at) }}</label>

        <label class="col-5 lg:col-3 text-base text-right">Expires:</label>
        <label class="col-6 lg:col-8 text-base font-bold ml-2">{{ getFormattedDate(data.expires_at) }}</label>

        <label class="col-5 lg:col-3 text-base text-right">Machine ID:</label>
        <label class="col-6 lg:col-8 text-base font-bold ml-2">{{ machineId }}</label>
      </div>
    </microsec-card>
  </div>

  <div class="col-12 lg:col-6">
    <microsec-card [isFull]="true">
      <div class="grid">
        <label class="col-12 text-xl font-bold">Revoke License</label>
        <label class="col-12 text-base font-bold"> Disclaimer on what happens if license is removed </label>
        <div class="col-12">
          <p-button severity="primary" label="Revoke License" (click)="confirmRevokeAllLicenses()" />
        </div>
      </div>
    </microsec-card>
  </div>
</div>
