import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { BaseComponent } from '@lcms-components';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss'],
})
export class ProjectSettingsComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(private injector: Injector) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    const PROJECT_MANAGEMENT_CHILDREN: ILayoutItem[] = this.injector.get('PROJECT_MANAGEMENT_CHILDREN');
    const PROJECT_SETTINGS_CHILDREN = PROJECT_MANAGEMENT_CHILDREN.find(
      (item: ILayoutItem) => item.route === PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    )?.children as ILayoutItem[];

    if (!!this.checkPermissionsByScope(USER_ROLE.ADMIN, true)) {
      const checkedItems = this.util.cloneObjectArray(PROJECT_SETTINGS_CHILDREN).filter((p) => !!p.label);
      checkedItems.forEach((item) => {
        item.visible = true;
      });
      this.store.dispatch(new fromLayoutActions.UpdateTabItems(checkedItems));
    }
  }

  override ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.cleanup({ shouldClearTabItems: true });
  }
}
