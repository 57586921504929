import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { API } from '../api';
import { ILabelService } from './ILabelService';

const API_LABEL = `${API.ACCESS_POLICY}/labels`;

@Injectable({
  providedIn: 'root',
})
export class LabelService implements ILabelService {
  constructor(private http: HttpClient) {}

  getLabels(orgId: any, projectId?: any): Observable<any> {
    return this.http
      .get<any>(`${API_LABEL}?organization=${orgId}${!!projectId ? `&project=${projectId}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getLabel(id: any): Observable<any> {
    return this.http.get<any>(`${API_LABEL}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createLabel(payload: any): Observable<any> {
    return this.http.post<any>(`${API_LABEL}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateLabel(id: any, payload: any): Observable<any> {
    return this.http.put<any>(`${API_LABEL}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteLabel(id: any): Observable<any> {
    return this.http.delete<any>(`${API_LABEL}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
