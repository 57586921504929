<div class="grid">
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN] ? leftTemplate : null">
      <ng-template #leftTemplate>
        @if (!!hasPermission) {
          <p-button
            severity="primary"
            [rounded]="true"
            [label]="'Create ' + moduleName + ' Envelope Template'"
            icon="pi pi-plus"
            (onClick)="openTemplateForm()" />
        }
        @if (currentScope === SCOPE.PROJECT && permissions[SCOPE.PROJECT][USER_ROLE.READ_ONLY]) {
          <p-button severity="primary" [rounded]="true" label="Verify Envelope" icon="pi pi-check" (onClick)="openVerificationForm()" />
        }
      </ng-template>
    </microsec-common-toolbar>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-5 max-height-60': !!selectedTemplate }" #datatable (click)="unselectTemplate($event, datatable)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [(selection)]="selectedTemplate"
        itemName="Envelope Template"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>

  @if (!!selectedTemplate) {
    <div class="col-12" [ngClass]="{ 'lg:col-7 max-height-40': !!selectedTemplate }">
      <app-envelope-template-info
        [isLoading]="isLoading"
        [values]="values"
        [(selectedTemplate)]="selectedTemplate"
        [hasPermission]="hasPermission"
        (openTemplateFormEvent)="openTemplateForm($event)"
        (refreshTemplatesListEvent)="refreshTemplatesList()"
        (duplicateTemplateEvent)="duplicateTemplate($event)"
        (confirmDeletionEvent)="confirmDeletion($event)" />
    </div>
  }
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getTemplates()" />
    <microsec-table-column-filter [key]="constructor.name" [cols]="cols" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Scope -->
    @case ('scope') {
      {{ util.getUppercaseFirstLetter(rowData[col.field]) }}
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
