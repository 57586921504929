import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { DEVICE_CATEGORIES, DEVICE_MANAGEMENT_FEATURES, DEVICE_STATUSES, DEVICE_STATUS_TAGS, DEVICE_TYPE } from '@lcms-constants';
import { devicesSelectors, fromDevicesActions } from '@ngrx-devices';
import { LabelService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { PIPE_DATETIME, USER_ROLE } from '@microsec/constants';
import { LazyLoadEvent } from 'primeng/api';
import { Table, TableService } from 'primeng/table';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonTableComponent } from '@microsec/components';
import { ActionMenuItem, CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [Table, TableService],
})
export class DetailsComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  _selectedDevice: any = null;

  get selectedDevice() {
    return this._selectedDevice;
  }

  set selectedDevice(value: any) {
    this._selectedDevice = value;
    this.store.dispatch(new fromDevicesActions.SelectDevice(value));
  }

  _selectedSubDevice: any = null;

  get selectedSubDevice() {
    return this._selectedSubDevice;
  }

  set selectedSubDevice(value: any) {
    this.store.dispatch(new fromDevicesActions.SelectDevice(value));
  }

  cols: any[] = [
    { field: 'id', header: 'S/N', width: 8 },
    { field: 'name', header: 'Common Name', width: 15 },
    { field: 'status', header: 'Status', width: 15 },
    { field: 'device_type', header: 'Type', width: 10 },
    { field: 'labels', header: 'Labels', width: 20 },
    { field: 'last_seen', header: 'Last seen', width: 10 },
  ];

  values: any[] = [];

  DEVICE_TYPE_LABELS = DEVICE_TYPE;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search', 'filter'],
    searchPlaceholder: 'Search...',
    filters: {
      0: {
        key: 'status',
        label: 'Status',
        type: 'dropdown',
        options: this.util.cloneObjectArray(DEVICE_STATUS_TAGS.filter((p) => p.value !== DEVICE_STATUSES.VIRTUAL)),
      },
      1: {
        key: 'categories',
        label: 'Categories',
        type: 'multiselect',
        options: this.util.cloneObjectArray(DEVICE_CATEGORIES),
      },
      2: {
        key: 'labels',
        label: 'Labels',
        type: 'multiselect',
        options: [],
      },
    },
  };

  filterSearch = '';

  filters = {
    status: null,
    categories: [],
    labels: [],
  };

  actionsMenuItems: ActionMenuItem[] = [];

  queryParams: Params | null = null;

  keyringId: any = null;

  keyId: any = null;

  rows = 20;

  totalDeviceNumber = 0;

  PIPE_DATETIME = PIPE_DATETIME;

  @ViewChild('labelItemTemplate') labelItemTemplate!: TemplateRef<any>;

  constructor(
    public el: ElementRef,
    private labelSrv: LabelService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [];
    this.handleFilterObjUpdate();
    this.getLabels();
    if (!!this.queryParams?.['keyring_id'] || !!this.queryParams?.['key_id']) {
      this.keyringId = this.queryParams['keyring_id'];
      this.keyId = this.queryParams['key_id'];
      this.router.navigate([], { queryParams: { keyring_id: null, key_id: null }, queryParamsHandling: 'merge' });
    }
    if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
      await this.getDevices();
      this.initData();
    }
  }

  override ngOnDestroy(): void {
    this.store.dispatch(new fromDevicesActions.Init());
    this.store.dispatch(new fromDevicesActions.CancelGetDevicesRequest());
    this.store.dispatch(new fromDevicesActions.CancelExportDevicesRequest());
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   * Unselect device
   * @param event
   * @param datatable
   * @returns
   */
  unselectDevice(event: any, datatable: any) {
    if (event?.target !== datatable) {
      return;
    }
    this.store.dispatch(new fromDevicesActions.SelectDevice(null));
  }

  /**
   * Get the list of all X509 devices
   * @param event
   */
  async getDevices(event?: LazyLoadEvent) {
    const config = await firstValueFrom(this.store.select(devicesSelectors.refreshConfig));
    const { page, perPage } = config;
    this.store.dispatch(
      new fromDevicesActions.GetAllDevices(
        this.breadcrumbConfig?.organizationId,
        this.breadcrumbConfig?.projectId,
        this.filters,
        this.filterSearch,
        {
          field: this.dt?.datatable?.sortField,
          order: this.dt?.datatable?.sortOrder ? (this.dt?.datatable?.sortOrder === 1 ? 'asc' : 'desc') : 'asc',
        },
        !event ? page : Math.floor((event as any)?.first / (event?.rows as number)) + 1,
        !event ? perPage : event?.rows,
        this.keyringId,
        this.keyId,
      ),
    );
    // Reset keyring ID and key ID to null
    this.keyringId = null;
    this.keyId = null;
  }

  /**
   * Handle filter
   */
  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        this.filterSearch = values.search || '';
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        if (!!values?.isFiltered) {
          if (this.dt?.datatable) {
            this.dt.datatable.first = 0;
          }
          this.filters = values.filter;
        } else {
          this.filters = { status: null, categories: [], labels: [] };
        }
        this.getDevices();
      }
    });
  }

  /**
   * Initialize data
   */
  initData() {
    this.subscriptions.push(
      ...[
        // devices
        this.store.select(devicesSelectors.devices).subscribe(async (devices) => {
          this.values = devices;
        }),
        // is loading
        this.store.select(devicesSelectors.isLoading).subscribe((isLoading) => {
          this.isLoading = isLoading;
        }),
        // total device number
        this.store.select(devicesSelectors.totalDeviceNumber).subscribe((totalDeviceNumber) => {
          this.totalDeviceNumber = totalDeviceNumber;
        }),
        // selected device
        this.store.select(devicesSelectors.selectedDevice).subscribe((selectedDevice) => {
          if (!!selectedDevice) {
            // Scan all X509 devices
            const foundX509Device = this.values.find((p) => p.id === selectedDevice.id);
            if (!!foundX509Device) {
              this._selectedSubDevice = null;
              this.cd.detectChanges();
              return;
            }
            // Scan all MicroPKI devices
            let isFound = false;
            this.values.forEach((x509Device) => {
              if (!isFound) {
                const foundMicroPKIDevice = (x509Device.children as any[]).find((p) => p.id === selectedDevice.id);
                if (!!foundMicroPKIDevice) {
                  isFound = true;
                  this._selectedDevice = selectedDevice;
                  this._selectedSubDevice = selectedDevice;
                }
              }
            });
            if (!isFound) {
              this._selectedSubDevice = null;
            }
          } else {
            this._selectedDevice = null;
            this._selectedSubDevice = null;
          }
          this.cd.detectChanges();
        }),
      ],
    );
  }

  /**
   * Get labels
   */
  getLabels() {
    this.isLoading = true;
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.labelSrv
      .getLabels(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const labels: any[] = this.util.sortObjectArray(rs, 'name').map((p) => ({
            ...p,
            value: p?.id,
            label: p?.name,
            color: p?.color,
          }));
          if (!!this.filterConfiguration?.filters?.[2]) {
            this.filterConfiguration.filters[2].itemTemplate = this.labelItemTemplate;
            this.filterConfiguration.filters[2].options = [...labels];
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Get the config for the status label
   * @param status
   * @returns
   */
  getLabelConfig(status: string): any {
    switch (status) {
      case 'manual': {
        return {
          label: 'Manual',
          value: 'manual',
          color: '#6e5ae8',
        };
      }
      case 'back-imported': {
        return {
          label: 'Back-imported',
          value: 'back-imported',
          color: '#3232ff',
        };
      }
      default: {
        const config = DEVICE_STATUS_TAGS.find((p) => p.value === status);
        return !!config
          ? config
          : {
              iconMax: 0,
              iconValue: 0,
              type: '',
              value: '',
              label: '',
              backgroundColor: 'transparent',
              color: 'transparent',
            };
      }
    }
  }

  /**
   * Select a sub device
   */
  selectSubDevice(device: any) {
    this.selectedSubDevice = !this.selectedSubDevice || this.selectedSubDevice.id !== device?.id ? device : null;
  }

  /**
   * Export device details
   */
  export() {
    this.store.dispatch(
      new fromDevicesActions.ExportDevices(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId, {}, this.filterSearch),
    );
  }

  get labels() {
    return (this.filterConfiguration?.filters?.[2]?.options?.filter((p) => !!p.value) as any[]) || [];
  }

  /**
   * Check if enable virtual device
   */
  get enableVirtualDevice() {
    return this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.VIRTUAL_DEVICES);
  }
}
