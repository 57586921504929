import { AfterViewInit, Component, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { ENTITY_IMPORT_HINT, ENTITY_TYPES, ENTITY_TYPE_VALUES } from '@lcms-constants';
import { CaManagementService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { LazyLoadEvent } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

const FORM_PARAMS = {
  ENTITY_TYPE: 'entity_type',
  COMMON_NAMES: 'cns',
};

@Component({
  selector: 'app-ca-connection-back-imported-form',
  templateUrl: './back-imported-form.component.html',
  styleUrls: ['./back-imported-form.component.scss'],
})
export class BackImportedFormComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  _selectedCAManager: any = null;

  get selectedCAManager() {
    return this._selectedCAManager;
  }

  set selectedCAManager(value: any) {
    this._selectedCAManager = value;
    this.getCACertificates();
    this.getEntityCertificates();
    this.initForm();
  }

  availableInRAOptions: any[] = [
    { value: null, label: '--- All entities ---' },
    { value: true, label: 'Available in RA' },
    { value: false, label: 'Unavailable in RA' },
  ];

  availableInRA: any = null;

  caOptions: any[] = [];

  caFilters: any[] = [];

  cols = [
    { field: 'cn', header: 'Common Name', width: 10, frozen: true },
    { field: 'available_certificates', header: 'Available Certificates', width: 10 },
    { field: 'ca', header: 'CA', width: 5 },
    { field: 'available_in_ra', header: 'Available in RA', width: 5 },
  ];

  values: any[] = [];

  searchText = '';

  rows = 20;

  totalEntityNumber = 0;

  _selectedEntities: any[] = [];

  get selectedEntities() {
    return this._selectedEntities;
  }

  set selectedEntities(values: any[]) {
    this._selectedEntities = values;
    this.form.setControlValue(
      FORM_PARAMS.COMMON_NAMES,
      values.map((p) => p.cn),
    );
  }

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('entitiesSelectorTemplate') entitiesSelectorTemplate: TemplateRef<any>;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private caManagementSrv: CaManagementService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.selectedCAManager = this.dialogConfig?.data?.selectedCAManager;
  }

  /**
   * Initialize form
   */
  initForm() {
    const entityTypeField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.ENTITY_TYPE,
      label: 'Create as',
      field: 'dropdown',
      options: this.util.cloneObjectArray(ENTITY_TYPES),
      required: true,
      fieldInfo: 'Create as devices / users',
      focused: true,
      defaultValue: null,
      onChangeEvent: new EventEmitter<any>(),
    } as FormItem);
    const entitiesField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.COMMON_NAMES,
      label: 'Entities',
      field: 'custom',
      customField: this.entitiesSelectorTemplate,
      required: true,
      fieldInfo: 'Entities',
      defaultValue: [],
    } as FormItem);
    entitiesField.setFullSize();
    const hintField = Object.assign(new FormItem(), {
      field: 'text',
      label: ENTITY_IMPORT_HINT.replace('{0}', 'device').replace('{1}', '0'),
    } as FormItem);
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        field: 'text',
        label: 'Back-import end entity certificates and create device / user entries on LCMS RA.',
      } as FormItem),
      entityTypeField,
      entitiesField,
      hintField,
    ];
    this.fields = fields;
    // Change events
    [FORM_PARAMS.ENTITY_TYPE, FORM_PARAMS.COMMON_NAMES].forEach((formParam) => {
      this.form.setChangeEvent(formParam, () => {
        // Update hint
        hintField.label = ENTITY_IMPORT_HINT.replace('{0}', this.form.getControlValue(FORM_PARAMS.ENTITY_TYPE)).replace(
          '{1}',
          this.selectedEntities.filter((p) => !p.available_in_ra).length?.toString(),
        );
      });
    });
    // Patch value
    this.form.patchValue({ [FORM_PARAMS.ENTITY_TYPE]: ENTITY_TYPE_VALUES.DEVICE });
  }

  /**
   * Get CA certificates
   */
  getCACertificates() {
    this.form.isLoading = true;
    this.caManagementSrv
      .getCACertificates(this.selectedCAManager?.id)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (results) => {
          this.caOptions = ((results?.data as any[]) || [])
            .filter((p) => (p.type as string).indexOf('intermediate') > -1)
            .map((p) => ({
              ...p,
              value: p?.id,
              label: p?.subject?.CN,
            }));
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get the list of entity certificates
   * @param event
   */
  getEntityCertificates(event?: LazyLoadEvent) {
    this.selectedEntities = [];
    this.isLoading = true;
    this.caManagementSrv
      .getEntityCertificates(this.selectedCAManager?.id, {
        availableInRA: this.availableInRA,
        issuedBy: this.caFilters,
        commonName: this.searchText,
        page: !event ? 1 : Math.floor((event as any)?.first / (event?.rows as number)) + 1,
        perPage: !event ? this.rows : event?.rows,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (result: any) => {
          this.values = ((result?.data as any[]) || []).map((entity) => ({
            ...entity,
            available_certificates: entity.certs?.length || 0,
            ca: (entity.certs as any[])?.[0].issued_by || '',
          }));
          this.totalEntityNumber = result.total_records;
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Change filters
   */
  changeFilters() {
    this.getEntityCertificates();
  }

  /**
   * Back-import certificates
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form.getRawValue() };
    payload.organization_id = this.breadcrumbConfig?.organizationId;
    payload.project_id = this.breadcrumbConfig?.projectId;
    this.caManagementSrv.backImportCertificates(this.selectedCAManager?.id, payload).subscribe({
      next: (rs: any) => {
        this.showSuccessMessage(!!rs?.message ? rs.message : 'Back-imported certificates successfully');
        closeDialog();
      },
      error: (err: any) => {
        this.form.showServerErrorMessage(err);
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Input search
   */
  inputSearch(event: any) {
    if (event?.keyCode === 13) {
      this.getEntityCertificates();
    }
  }

  /**
   * Get the list of no sort columns
   */
  get noSortColumns() {
    return this.cols.map((p) => p.field);
  }
}
