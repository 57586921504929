import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MODULE_CONSTANTS, USER_ROLE } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { BaseComponent } from '@lcms-components';
import { GLOBAL_SETTINGS_CONSTANTS } from '@lcms-constants';

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss'],
})
export class GlobalSettingsComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(private injector: Injector) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    const ORGANIZATION_MANAGEMENT_CHILDREN = this.injector.get('GLOBAL_SETTINGS_CHILDREN');
    this.store.dispatch(new fromLayoutActions.SetLeftNavbar(true));
    this.store.dispatch(new fromLayoutActions.SetModule(MODULE_CONSTANTS.GLOBAL_SETTINGS.ROUTE));
    if (!!this.checkPermissionsByScope(USER_ROLE.ADMIN)) {
      const checkedItems = this.util
        .cloneObjectArray(ORGANIZATION_MANAGEMENT_CHILDREN)
        .filter((p) => p.route === GLOBAL_SETTINGS_CONSTANTS.WORKFLOWS.ROUTE);
      this.store.dispatch(new fromLayoutActions.UpdateReplacedItems(checkedItems.map((item) => ({ ...item, visible: true }))));
    }
  }

  override ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.store.dispatch(new fromLayoutActions.UpdateReplacedItems([]));
  }
}
