import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FirmwareService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { PORT_PATTERN, PUSH_LABEL, SERVER_PATTERN } from '@microsec/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

const FORM_PARAMS = {
  DEVICE_ID: 'device_id',
  IP_ADDRESS: 'ip_address',
  PORT: 'port',
  USERNAME: 'username',
  PASSWORD: 'password',
};

@Component({
  selector: 'app-firmware-push-form',
  templateUrl: './firmware-push-form.component.html',
  styleUrls: ['./firmware-push-form.component.scss'],
})
export class FirmwarePushFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  firmware: any = null;

  devices: any[] = [];

  PUSH_LABEL = PUSH_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private firmwareSrv: FirmwareService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    this.firmware = this.dialogConfig?.data?.firmware;
    this.devices = this.dialogConfig?.data?.devices;
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: `Push a firmware to ${this.devices.length < 2 ? 'an assigned device:' : 'assigned devices:'} ${this.devices
          .map((d) => d.name)
          .join(', ')}`,
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IP_ADDRESS,
        label: 'IP Address',
        field: 'input',
        pattern: SERVER_PATTERN,
        patternErrorText: 'Please enter a valid IP Address',
        required: true,
        fieldInfo: 'IP address',
        defaultValue: '',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PORT,
        label: 'Port',
        field: 'number',
        pattern: PORT_PATTERN,
        patternErrorText: 'Please enter a valid Port',
        required: true,
        fieldInfo: 'Port',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.USERNAME,
        label: 'Username',
        field: 'input',
        required: true,
        fieldInfo: 'Username',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PASSWORD,
        label: 'Password',
        field: 'password',
        required: true,
        feedback: false,
        fieldInfo: 'Password',
        defaultValue: '',
      } as FormItem),
    ];
    this.fields = fields;
  }

  /**
   * Push firmware
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form.getValue() };
    const payloadArr = this.devices.map((device: any) => ({
      ...payload,
      [FORM_PARAMS.DEVICE_ID]: device?.device_id,
    }));
    this.firmwareSrv.pushHikVisionCCTVDevice(this.firmware?.id, payloadArr).subscribe({
      next: () => {
        this.showSuccessMessage(`Push firmware to ${this.devices.length === 1 ? `device ${this.devices[0].name}` : `devices`} successfully`);
        closeDialog();
      },
      error: (err: any) => {
        this.form.showServerErrorMessage(err);
        this.showErrorMessage(err);
      },
    });
  }
}
