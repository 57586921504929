import { COMMON_CHART_CONFIG, FUTURE_DATE_RANGE_TOKEN, PAST_DATE_RANGE_TOKEN } from '@microsec/constants';
import { ChartOptions } from 'chart.js';

export const DATA_BLOCKS = {
  TOTAL: {
    KEY: 'TOTAL',
    LABEL: 'Total',
  },
  EXPIRING_7D: {
    KEY: 'EXPIRING_7D',
    LABEL: 'Expiring (<7d)',
    COLOR: '#FF9534',
  },
  EXPIRING_30D: {
    KEY: 'EXPIRING_30D',
    LABEL: 'Expiring (<30d)',
    COLOR: '#FF9534',
  },
  EXPIRED: {
    KEY: 'EXPIRED',
    LABEL: 'Expired',
    COLOR: '#FF9534',
  },
};

export const DIAGRAMS = {
  CERTIFICATES_ISSUED: {
    TYPE: 'bar',
    KEY: 'CERTIFICATES_ISSUED',
    LABEL: `Certificates Issued${PAST_DATE_RANGE_TOKEN}`,
    CHILDREN: [
      {
        LABEL: 'Certificates',
        COLOR: '#36C3DF',
      },
    ],
  },
  CERTIFICATES_EXPIRING: {
    TYPE: 'bar',
    KEY: 'CERTIFICATES_EXPIRING',
    LABEL: `Certificates Expiring${FUTURE_DATE_RANGE_TOKEN}`,
    CHILDREN: [
      {
        LABEL: 'Certificates',
        COLOR: '#DB4CB2',
      },
    ],
  },
  // CERTIFICATES_BY_TYPE: {
  //   TYPE: 'doughnut',
  //   KEY: 'CERTIFICATES_BY_TYPE',
  //   LABEL: 'Certificates by Type',
  //   CHILDREN: [],
  // },
  CERTIFICATES_BY_STATUS: {
    TYPE: 'doughnut',
    KEY: 'CERTIFICATES_BY_STATUS',
    LABEL: 'Certificates by Status ',
    CHILDREN: [],
  },
  CERTIFICATES_BY_CA: {
    TYPE: 'doughnut',
    KEY: 'CERTIFICATES_BY_CA',
    LABEL: 'Certificates by CA',
    CHILDREN: [],
  },
  CERTIFICATES_BY_TEMPLATE: {
    TYPE: 'doughnut',
    KEY: 'CERTIFICATES_BY_TEMPLATE',
    LABEL: 'Certificates by Template',
    CHILDREN: [],
  },
  // CERTIFICATES_BY_LABEL: {
  //   TYPE: 'doughnut',
  //   KEY: 'CERTIFICATES_BY_LABEL',
  //   LABEL: 'Certificates by Label',
  //   CHILDREN: [],
  // },
  KEY_ALGORITHM: {
    TYPE: 'doughnut',
    KEY: 'KEY_ALGORITHM',
    LABEL: 'Key Algorithm ',
    CHILDREN: [],
  },
  SIGNATURE_ALGORITHM: {
    TYPE: 'doughnut',
    KEY: 'SIGNATURE_ALGORITHM',
    LABEL: 'Signature Algorithm',
    CHILDREN: [],
  },
};

export const COMMON_LAYOUT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      title: {
        display: false,
      },
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
    y: {
      title: {
        display: false,
      },
      beginAtZero: true,
      min: 0,
      max: undefined,
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
        stepSize: 1,
        callback: COMMON_CHART_CONFIG.INTEGER_LABEL_ONLY_CALLBACK,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
  },
};

export const COMMON_DOUGHNUT_LAYOUT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context: any): string => ` ${context.raw}`,
      },
    },
  },
};
