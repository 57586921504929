import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SCOPE, UNASSIGN_LABEL, USER_ROLE } from '@microsec/constants';
import { BaseComponent } from '@lcms-components';
import { CommonTableComponent } from '@microsec/components';
import { RoleService, UserService } from '@microsec/services';
import { ReportSpecification, CommonToolbarConfiguration, CommonToolbarResult, ActionMenuItem } from '@microsec/models';

import { finalize } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { UserAccessFormComponent } from './user-access-form/user-access-form.component';

const FIELDS = {
  id: 'ID',
  username: 'Username',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  role: 'Role',
};

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  cols: any[] = [
    { field: 'username', header: 'Username', width: 8, frozen: true },
    { field: 'first_name', header: 'First Name', width: 8 },
    { field: 'last_name', header: 'Last Name', width: 8 },
    { field: 'email', header: 'Email', width: 12 },
    { field: 'role', header: 'Role', width: 8 },
  ];

  excludedColumns = ['Username', 'Role'];

  values: any[] = [];

  roles: any[] = [];

  @ViewChild('dt') dt!: CommonTableComponent;

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private userSrv: UserService,
    private roleSrv: RoleService,
  ) {
    super();
  }

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search...',
    hideClearFilters: false,
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.actionsMenuItems = [
      {
        label: 'Unassign',
        icon: 'fas fa-user-slash',
        visible: () => !!this.hasPermission,
        command: ({ rowData }) => this.deleteUser(rowData),
      },
      {
        label: 'Save',
        icon: 'fa fa-save',
        disabled: ({ rowData }) => rowData.roleId === rowData.originRoleId || !this.hasPermission,
        visible: ({ rowData }) =>
          ((this.currentScope === 'project' && !rowData.roles?.organization?.id) || this.currentScope === 'organization') && !!this.hasPermission,
        command: ({ rowData }) => this.saveUserRole(rowData),
      },
    ];
    this.getRoles().subscribe(() => {
      this.getUsers();
    });
    this.selectedColFields = (this.cols || []).map((col) => col.field);

    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result?.search || '';
      }
    });
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.userSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getUsers();
      }
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Get the list of users assigned to the current organization / project
   */
  getUsers() {
    let request: Observable<any[]>;
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        request = this.userSrv.getUsersByOrganizationId(this.breadcrumbConfig?.organizationId);
        break;
      }
      case SCOPE.PROJECT: {
        request = this.userSrv.getUsersByProjectId(this.breadcrumbConfig?.projectId);
        break;
      }
      default: {
        request = new Observable((observe) => {
          observe.next([]);
        });
        break;
      }
    }
    this.isLoading = true;

    request
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any[]) => {
          this.values = rs.map((p) => {
            const orgRoleId = p.roles?.organization?.id || null; // ID for no-access
            const originRoleId = this.currentScope === SCOPE.ORGANIZATION || orgRoleId ? orgRoleId : p.roles?.project?.id || null;
            const roleId = originRoleId;
            return {
              ...p,
              originRoleId,
              roleId,
            };
          });
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get the list of user roles
   * @returns
   */
  getRoles() {
    const obs = new Observable((observe) => {
      this.isLoading = true;
      let request: Observable<any[]>;
      switch (this.currentScope) {
        case SCOPE.ORGANIZATION: {
          request = this.roleSrv.getOrganizationRoles(this.breadcrumbConfig?.organizationId);
          break;
        }
        case SCOPE.PROJECT: {
          request = this.roleSrv.getProjectRoles(this.breadcrumbConfig?.projectId);
          break;
        }
        default: {
          request = new Observable((o) => {
            o.next([]);
          });
          break;
        }
      }
      request
        .pipe(
          finalize(() => {
            this.isLoading = false;
            observe.next();
          }),
        )
        .subscribe({
          next: (rs: any[]) => {
            const roles = rs.map((role) => ({
              value: role.id,
              label: role.name,
              level: role.level,
            }));
            this.roles = this.util.sortObjectArray(roles, 'level', false);
          },
          error: (err: any) => {
            this.showErrorMessage(err);
          },
        });
    });
    return obs;
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const data = (specification.data || []).map((user) => ({
          ...user,
          role: !!user.is_superuser ? 'Super Admin' : this.roles.find((role) => role.value === user.roleId)?.label || `Role ID: ${user.roleId}`,
        }));
        this.dt.generateReportDialog.exportReport(
          data,
          `${
            this.currentScope === SCOPE.ORGANIZATION
              ? `organization_${this.breadcrumbConfig?.organizationId}_`
              : this.currentScope === SCOPE.PROJECT
                ? `project_${this.breadcrumbConfig?.projectId}_`
                : ''
          }users`,
        );
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  /**
   * Open user assignmnet form
   */
  openUserAccessForm() {
    const dialog = this.dialogSrv.open(UserAccessFormComponent, {
      header: 'Assign User',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      this.userSrv.refresh$.next(rs);
    });
  }

  /**
   * Remove the user from the organization / project
   * @param user
   */
  deleteUser(user: any) {
    let parentId: any = null;
    const payload: any = {};
    let message = `<div>Unassigning a user will remove all access that user has, 
    as well as {message}.</div><br>`;
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        message = message.replace('{message}', 'deleting project memberships and all the assigned roles and permissions');
        parentId = this.breadcrumbConfig?.organizationId;
        break;
      }
      case SCOPE.PROJECT: {
        message = message.replace('{message}', 'deactivating all the assigned roles and permissions in this project');
        parentId = this.breadcrumbConfig?.projectId;
        payload.is_assigned = false;
        break;
      }
      default: {
        break;
      }
    }
    this.confirm({
      action: UNASSIGN_LABEL,
      objectName: 'User',
      customContent: `${message}Are you sure you want to unassign user ${user.username} from this ${this.currentScope}?`,
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: this.userSrv.unassignUserFromScope(this.currentScope, parentId, user.id, payload),
      next: () => {
        this.isLoading = false;
        this.showSuccessMessage(`Unassigned user ${user.username}` + ` from current ${this.currentScope} successfully`);
        this.getUsers();
      },
      error: (err: any) => {
        this.isLoading = false;
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Update the user's role
   * @param user
   */
  saveUserRole(user: any) {
    let parentId: any = null;
    const payload: any = {
      is_active: !!user.roleId,
    };
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        parentId = this.breadcrumbConfig?.organizationId;
        break;
      }
      case SCOPE.PROJECT: {
        parentId = this.breadcrumbConfig?.projectId;
        break;
      }
      default: {
        break;
      }
    }
    this.isLoading = true;
    this.userSrv
      .updateUserRole(this.currentScope, parentId, user.id, payload, user.roleId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Updated role for user ${user.username}` + ` from current ${this.currentScope} successfully`);
          this.getUsers();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get the module name
   */
  get moduleName() {
    return this.util?.getUppercaseFirstLetter(this.currentScope);
  }

  /**
   * Check if the current user has the permission
   */
  get hasPermission() {
    let result = false;
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        result = this.permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN];
        break;
      }
      case SCOPE.PROJECT: {
        result = this.permissions[SCOPE.PROJECT][USER_ROLE.ADMIN];
        break;
      }
      default: {
        break;
      }
    }
    return result;
  }

  override ngOnDestroy() {
    this.cleanup();
    this.userSrv.refresh$.next(null);
  }
}
