import { AfterViewInit, Component } from '@angular/core';
import { DEVICE_MANAGEMENT_FEATURES, PKI_MANAGEMENT_FEATURES } from '@lcms-constants';
import { BaseComponent } from '@lcms-components';
import { ENROLLMENT_CONFIGS } from './enrollment.config';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss'],
})
export class EnrollmentComponent extends BaseComponent implements AfterViewInit {
  enrollmentOptions: any[] = [];

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.getConfigs();
  }

  /**
   * Get configs
   */
  getConfigs() {
    let configs = this.util.cloneObjectArray(ENROLLMENT_CONFIGS);
    if (!this.isLcmsAgentFeatured) {
      configs = configs.filter((p) => p.key !== 'agent');
    }
    if (!this.isVirtualDeviceFeatured) {
      configs = configs.filter((p) => p.key !== 'virtual');
    }
    if (!this.isX509Featured || !this.isProtocolGenericFeatured) {
      configs = configs.filter((p) => p.key !== 'csr');
    }
    this.enrollmentOptions = configs;
  }

  /**
   * Check if X509 featured
   */
  get isX509Featured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.X509);
  }

  /**
   * Check if virtual device featured
   */
  get isVirtualDeviceFeatured() {
    return false; //this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.VIRTUAL_DEVICES);
  }

  /**
   * Check if LCMS agent featured
   */
  get isLcmsAgentFeatured() {
    return this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.LCMS_AGENT);
  }

  /**
   * Check if protocol generic featured
   */
  get isProtocolGenericFeatured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.PROTOCOL_GENERIC);
  }
}
