<microsec-form-builder [isPage]="true" #fb [fields]="fields" [shouldShowActionButtons]="false" />

<ng-template #downloadPrivateKeyTemplate>
  <p-button
    icon="fa fa-download"
    styleClass="p-button-secondary"
    pTooltip="Download private key"
    tooltipPosition="bottom"
    appendTo="body"
    (onClick)="downloadPrivateKey()" />
</ng-template>
