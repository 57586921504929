import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-certificate-status-block',
  templateUrl: './user-certificate-status-block.component.html',
  styleUrls: ['./user-certificate-status-block.component.scss'],
})
export class UserCertificateStatusBlockComponent {
  @Input() config: any = {
    iconMax: 0,
    iconValue: 0,
    label: '',
    value: '',
    type: '',
    color: '',
  };
}
