import {
  AuthenticationService,
  MockAuthenticationService,
  OrganizationService,
  MockOrganizationService,
  ProjectService,
  MockProjectService,
  MockUserService,
  UserService,
  MockRoleService,
  RoleService,
  MockUserInboxService,
  UserInboxService,
  FeatureService,
  WorkflowService,
  MockWorkflowService,
  EventMonitorService,
} from '@microsec/services';
import { CaManagementService } from './ca-management/ca-management.service';
import { MockCaManagementService } from './ca-management/mock-ca-management.service';
import { CaTemplatesService } from './ca-templates/ca-templates.service';
import { MockCaTemplatesService } from './ca-templates/mock-ca-templates.service';
import { CaService } from './ca/ca.service';
import { MockCaService } from './ca/mock-ca.service';
import { DeviceCertificateService } from './device-certificate/device-certificate.service';
import { MockDeviceCertificateService } from './device-certificate/mock-device-certificate.service';
import { DeviceService } from './device/device.service';
import { MockDeviceService } from './device/mock-device.service';
import { EnvelopeService } from './envelope/envelope.service';
import { MockEnvelopeService } from './envelope/mock-envelope.service';
import { MockFeatureService } from './feature/mock-feature.service';
import { FirmwareService } from './firmware/firmware.service';
import { MockFirmwareService } from './firmware/mock-firmware.service';
import { KmsService } from './kms/kms.service';
import { MockKmsService } from './kms/mock-kms.service';
import { LabelService } from './label/label.service';
import { MockLabelService } from './label/mock-label.service';
import { LDAPSettingService } from './ldap/ldap-setting.service';
import { MockLdapSettingService } from './ldap/mock-ldap-setting.service';
import { LicenseService } from './license/license.service';
import { MockLicenseService } from './license/mock-license.service';
import { LogService } from './log/log.service';
import { MockLogService } from './log/mock-log.service';
import { MockPackageService } from './package/mock-package.service';
import { PackageService } from './package/package.service';
import { MockRaEnrollmentService } from './ra-enrollment/mock-ra-enrollment.service';
import { RaEnrollmentService } from './ra-enrollment/ra-enrollment.service';
import { MockRepositoryService } from './repository/mock-repository.service';
import { RepositoryService } from './repository/repository.service';
import { MockSetting2FAService } from './setting-2fa/mock-setting-2fa.service';
import { Setting2FAService } from './setting-2fa/setting-2fa.service';
import { MockUserCertificateService } from './user-certificate/mock-user-certificate.service';
import { UserCertificateService } from './user-certificate/user-certificate.service';
import { MockEventMonitorService } from './event-monitor/mock-event-monitor.service';
import { RuleService } from './rule/rule.service';
import { MockRuleService } from './rule/mock-rule.service';
// #endregion

export function getServiceReplacements(environment: any) {
  const createServiceReplacement = (realSrv: any, mockSrv: any, shouldUseMockData = false) => {
    return {
      provide: realSrv,
      useClass: !!environment.useMockData || !!shouldUseMockData ? mockSrv : realSrv,
    };
  };
  return [
    createServiceReplacement(AuthenticationService, MockAuthenticationService),
    createServiceReplacement(OrganizationService, MockOrganizationService),
    createServiceReplacement(ProjectService, MockProjectService),
    createServiceReplacement(CaService, MockCaService),
    createServiceReplacement(DeviceCertificateService, MockDeviceCertificateService),
    createServiceReplacement(DeviceService, MockDeviceService),
    createServiceReplacement(RoleService, MockRoleService),
    createServiceReplacement(EnvelopeService, MockEnvelopeService),
    createServiceReplacement(EventMonitorService, MockEventMonitorService),
    createServiceReplacement(LicenseService, MockLicenseService),
    createServiceReplacement(FeatureService, MockFeatureService),
    createServiceReplacement(PackageService, MockPackageService),
    createServiceReplacement(UserService, MockUserService),
    createServiceReplacement(UserCertificateService, MockUserCertificateService),
    createServiceReplacement(UserInboxService, MockUserInboxService),
    createServiceReplacement(Setting2FAService, MockSetting2FAService),
    createServiceReplacement(LDAPSettingService, MockLdapSettingService),
    createServiceReplacement(FirmwareService, MockFirmwareService),
    createServiceReplacement(CaTemplatesService, MockCaTemplatesService),
    createServiceReplacement(CaManagementService, MockCaManagementService),
    createServiceReplacement(RaEnrollmentService, MockRaEnrollmentService),
    createServiceReplacement(RepositoryService, MockRepositoryService),
    createServiceReplacement(RaEnrollmentService, MockRaEnrollmentService),
    createServiceReplacement(LabelService, MockLabelService),
    createServiceReplacement(KmsService, MockKmsService),
    createServiceReplacement(LogService, MockLogService),
    createServiceReplacement(WorkflowService, MockWorkflowService),
    createServiceReplacement(RuleService, MockRuleService),
  ];
}
