import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FIRMWARE_TARGET_DEVICE_VALUES, PKI_MANAGEMENT_FEATURES } from '@lcms-constants';
import { devicesSelectors } from '@ngrx-devices';
import { DeviceService, FirmwareService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { ActionMenuItem, CommonToolbarConfiguration, CommonToolbarResult, ConfirmationDialogConfig } from '@microsec/models';
import { DELETE_LABEL, PIPE_DATETIME } from '@microsec/constants';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { FirmwareDeviceAssignmentFormComponent } from './firmware-device-assignment-form/firmware-device-assignment-form.component';
import { FirmwarePushFormComponent } from './firmware-push-form/firmware-push-form.component';
import { CommonTableComponent } from '@microsec/components';

const FIELDS = {
  DEVICE: 'Device',
  CURRENT_VERSION: 'Current Version',
  STATUS: 'Status',
};

@Component({
  selector: 'app-firmware-assigned-devices',
  templateUrl: './firmware-assigned-devices.component.html',
  styleUrls: ['./firmware-assigned-devices.component.scss'],
})
export class FirmwareAssignedDevicesComponent extends BaseComponent implements OnInit {
  isLoading = false;

  _firmware: any = null;

  get firmware() {
    return this._firmware;
  }

  @Input() set firmware(value: any) {
    this._firmware = value;
    this.selectedDevices = [];
    this.values = this.util.sortObjectArray(value?.assigned_devices, 'name');
  }

  @Output() refreshFirmwareEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dt') dt!: CommonTableComponent;

  cols: any[] = [
    { field: 'name', header: FIELDS.DEVICE, width: 8, frozen: true },
    { field: 'current_version', header: FIELDS.CURRENT_VERSION, width: 6 },
    { field: 'current_status', header: FIELDS.STATUS, width: 6 },
  ];

  values: any[] = [];

  /**
   * Contains the list of all devices
   */
  devices: any[] = [];

  selectedDevices: any[] = [];

  PIPE_DATETIME = PIPE_DATETIME;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search...',
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    public el: ElementRef,
    private firmwareSrv: FirmwareService,
    private deviceSrv: DeviceService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.X509)) {
      this.selectedColFields = (this.cols || []).map((col) => col.field);
      this.actionsMenuItems = [
        {
          label: 'Push to Device',
          icon: 'fas fa-upload',
          command: ({ rowData }: any) => this.pushToDevices(rowData),
        },
        {
          label: 'Delete',
          icon: 'fas fa-trash',
          command: ({ rowData }: any) => this.openDeleteConfirmation(rowData),
        },
      ];
      this.handleFilterObjUpdate();
      this.devices = await firstValueFrom(this.store.select(devicesSelectors.devices));
    }
  }
  /**
   * Handle filter
   */
  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values.search || '';
      }
    });
  }

  /**
   * Open device assignment dialog
   */
  openDeviceAssignmentDialog() {
    const dialog = this.dialogSrv.open(FirmwareDeviceAssignmentFormComponent, {
      data: {
        firmware: this.firmware,
        devices: this.devices.map((d) => ({
          value: d.id,
          label: d.common_name,
        })),
      },
      header: 'Assign Firmware to Device(s)',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.refreshFirmware();
      }
    });
  }

  /**
   * Push to devices
   * @param device
   */
  pushToDevices(device?: any) {
    const devices = !device ? this.selectedDevices : [device];
    // Normal device
    if (this.firmware?.target_device !== FIRMWARE_TARGET_DEVICE_VALUES.HIKVISION_CCTV) {
      devices.forEach((d: any) => {
        const url = this.getFotaMQTTUrl(d);
        if (!!url) {
          this.deviceSrv.mqtt?.unsafePublish(url, JSON.stringify({ action: 'push', firmware_id: this.values[0].id }), {
            qos: 1,
            retain: false,
          });
          setTimeout(() => {
            this.showSuccessMessage(`Pushing to device ${d.name}`);
            this.refreshFirmware();
          }, 100);
        } else {
          this.showInfoMessage(`Device ${d.name} has no valid certificate`);
        }
      });
    }
    // hikvision_cctv device
    else {
      this.pushToHikvisionCCTVDevices(devices);
    }
  }

  pushToHikvisionCCTVDevices(devices?: any) {
    const dialog = this.dialogSrv.open(FirmwarePushFormComponent, {
      data: {
        firmware: this.firmware,
        devices,
      },
      header: 'Push Firmware',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.refreshFirmware();
      }
    });
  }

  /**
   * Get base mqtt url
   */
  getFotaMQTTUrl(device: any) {
    const selectedDevice = this.devices.find((d) => d.id === device.device_id);
    const validCert = selectedDevice.certs?.find((cert: any) => cert.status === 'Valid');
    if (!!validCert) {
      const { issued_country } = validCert;
      const { issued_org } = validCert;
      const { issued_org_unit } = validCert;
      return `dev/${issued_country}/${issued_org}/${issued_org_unit}/${selectedDevice.common_name}/fota/push`;
    }
    return '';
  }

  /**
   * Confirm deletion
   * @param device
   */
  openDeleteConfirmation(device?: any) {
    let assignedDeviceIds = (this.firmware.assigned_devices as any[]).map((p) => p.device_id);
    const selectedAssignedDeviceIds = !device ? this.selectedDevices.map((p) => p.device_id) : [device.device_id];
    assignedDeviceIds = assignedDeviceIds.filter((id) => !selectedAssignedDeviceIds.includes(id));
    this.confirm({
      action: DELETE_LABEL,
      objectName: `Device Assignment${!device ? '(s)' : ''}`,
      object: (this.firmware.assigned_devices as any[]).filter((p) => !!selectedAssignedDeviceIds.includes(p.device_id)),
      customContent: `This will delete ${!device ? 'selected device(s)' : 'this device'} from this firmware.`,
      shouldShowDefaultContent: true,
      acceptRequest: this.firmwareSrv.updateFirmware(this.firmware.id, {
        assigned_devices: assignedDeviceIds,
        org_id: this.breadcrumbConfig?.organizationId,
      }),
      next: () => {
        this.showSuccessMessage(`Deleted device assignment${!device ? '(s)' : ''} successfully`);
        this.refreshFirmwareEvent.emit();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Refresh firmware
   */
  refreshFirmware() {
    this.refreshFirmwareEvent.emit();
  }
}
