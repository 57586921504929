import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { DetailsPanelButtonModel } from '@microsec/models';
import { DELETE_LABEL, DOWNGRADE_PACKAGE_LABEL, UPDATE_PACKAGE_LABEL } from '@microsec/constants';

@Component({
  selector: 'app-package-info',
  templateUrl: './package-info.component.html',
  styleUrls: ['./package-info.component.scss'],
})
export class PackageInfoComponent extends BaseComponent implements AfterContentInit {
  @Input() isLoading = false;

  _selectedPackage: any = null;

  get selectedPackage() {
    return this._selectedPackage;
  }

  @Input() set selectedPackage(value: any) {
    this._selectedPackage = value;
    this.prepareActionButtons();
  }

  @Output() selectedPackageChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() openPackageFormEvent: EventEmitter<any> = new EventEmitter<any>();

  actionButtons: DetailsPanelButtonModel[] = [];

  maxHeight = '50vh';

  async ngAfterContentInit() {
    this.prepareConfigs();
    this.prepareActionButtons();
  }

  /**
   * Prepare action buttons
   */
  prepareActionButtons() {
    this.actionButtons = [
      {
        label: UPDATE_PACKAGE_LABEL,
        disabled: this.selectedPackage?.['out_of_date_devices'] === 0,
        command: () => {
          this.openPackageFormEvent.emit('Update');
        },
      },
      {
        label: DOWNGRADE_PACKAGE_LABEL,
        command: () => {
          this.openPackageFormEvent.emit('Downgrade');
        },
      },
      {
        label: DELETE_LABEL,
        command: () => {
          this.openPackageFormEvent.emit('Delete');
        },
      },
    ];
  }

  /**
   * Unselect package
   */
  unselectPackage() {
    this.selectedPackageChange.emit(null);
  }
}
