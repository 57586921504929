import { FIREWALL_CHAINS, FIREWALL_IP_PROTOCOLS, FIREWALL_IP_VERSIONS, FIREWALL_IP_VERSION_VALUES, FIREWALL_TARGETS } from '@lcms-constants';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { RuleService } from '@lcms-services';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import {
  CREATE_LABEL,
  CREATE_SUCCESS,
  IPV4_PATTERN,
  IPV6_PATTERN,
  NUMBER_MAX_LENGTH,
  PORT_RANGE_PATTERN,
  SAVE_CHANGES_LABEL,
  UPDATE_SUCCESS,
  VALIDATOR_TYPE,
} from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, finalize } from 'rxjs';
const FORM_PARAMS = {
  NAME: 'name',
  SRC_IP: 'src_ip',
  SRC_PORTS: 'src_ports',
  DST_IP: 'dst_ip',
  DST_PORTS: 'dst_ports',
  IP_PROTOCOL: 'ip_protocol',
  IP_VERSION: 'ip_version',
  CHAIN: 'chain',
  TARGET: 'target',
  RULE_TYPE: 'rule_type',
  PROJECT_ID: 'project_id',
};

@Component({
  selector: 'app-ip-rule-form',
  templateUrl: './ip-rule-form.component.html',
  styleUrls: ['./ip-rule-form.component.scss'],
})
export class IpRuleFormComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  @Input() ruleset: any = null;

  @Input() rule: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  NUMBER_MAX_LENGTH = NUMBER_MAX_LENGTH;

  constructor(
    public dialogRef: DynamicDialogRef,
    public ruleSrv: RuleService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initForm();
    if (!!this.rule) {
      this.form.patchValue(this.rule);
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: 'Name',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SRC_IP,
        label: 'Source IP (CIDR format)',
        placeholder: 'Leave empty to apply anywhere',
        defaultValue: '',
        pattern: IPV4_PATTERN,
        patternErrorText: 'Please enter a valid Source IP (CIDR format)',
        fieldInfo: 'Firewall rule source IP in CIDR Format, leave empty to apply anywhere',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SRC_PORTS,
        label: 'Source Ports',
        placeholder: 'Leave empty to apply anywhere',
        defaultValue: '',
        pattern: PORT_RANGE_PATTERN,
        patternErrorText: 'Please enter a valid Source Ports',
        fieldInfo: 'Firewall rule source ports, leave empty to apply anywhere',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DST_IP,
        label: 'Destination IP (CIDR format)',
        placeholder: 'Leave empty to apply anywhere',
        defaultValue: '',
        pattern: IPV4_PATTERN,
        patternErrorText: 'Please enter a valid Destination IP (CIDR format)',
        fieldInfo: 'Firewall rule destination IP in CIDR Format, leave empty to apply anywhere',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DST_PORTS,
        label: 'Destination Ports',
        placeholder: 'Leave empty to apply anywhere',
        defaultValue: '',
        pattern: PORT_RANGE_PATTERN,
        patternErrorText: 'Please enter a valid Destination Ports',
        fieldInfo: 'Firewall rule destination ports, leave empty to apply anywhere',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IP_PROTOCOL,
        label: 'IP Protocol',
        field: 'dropdown',
        placeholder: 'Select an IP protocol',
        options: this.util.cloneObjectArray(FIREWALL_IP_PROTOCOLS),
        required: true,
        defaultValue: null,
        fieldInfo: 'Firewall rule IP protocol',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IP_VERSION,
        label: 'IP Version',
        field: 'dropdown',
        options: this.util.cloneObjectArray(FIREWALL_IP_VERSIONS),
        required: true,
        defaultValue: FIREWALL_IP_VERSION_VALUES.IPV4,
        fieldInfo: 'Firewall rule IP version',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CHAIN,
        label: 'Chain',
        field: 'dropdown',
        placeholder: 'Select a chain',
        options: this.util.cloneObjectArray(FIREWALL_CHAINS),
        required: true,
        defaultValue: null,
        fieldInfo: 'Firewall rule chain',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TARGET,
        label: 'Target',
        field: 'dropdown',
        placeholder: 'Select a target',
        options: this.util.cloneObjectArray(FIREWALL_TARGETS),
        required: true,
        defaultValue: null,
        fieldInfo: 'Firewall rule target',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE_TYPE,
        hidden: true,
        defaultValue: this.ruleset?.ruleset_type,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        hidden: true,
        defaultValue: this.breadcrumbConfig?.projectId,
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
    this.form?.setChangeEvent(FORM_PARAMS.IP_VERSION, (value: string) => {
      const pattern = value === FIREWALL_IP_VERSION_VALUES.IPV4 ? IPV4_PATTERN : IPV6_PATTERN;
      this.form.setControlValidators(FORM_PARAMS.SRC_IP, [VALIDATOR_TYPE.PATTERN], [pattern]);
      this.form.setControlValidators(FORM_PARAMS.DST_IP, [VALIDATOR_TYPE.PATTERN], [pattern]);
    });
  }

  /**
   * Submit the firewall form
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    this.isLoading = true;
    const payload = this.form.getRawValue();
    payload[FORM_PARAMS.SRC_IP] = this.setIP(payload[FORM_PARAMS.SRC_IP], payload[FORM_PARAMS.IP_VERSION]);
    payload[FORM_PARAMS.DST_IP] = this.setIP(payload[FORM_PARAMS.DST_IP], payload[FORM_PARAMS.IP_VERSION]);
    const request: Observable<any> = !this.rule
      ? this.ruleSrv.createRule(this.ruleset?.id, payload)
      : this.ruleSrv.updateRule(this.ruleset?.id, this.rule?.rule_id, payload);
    request
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.rule ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'IP rule'));
          closeDialog();
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Set IP
   * @param ip
   * @param ipVersion
   * @returns
   */
  setIP(ip: any, ipVersion: any) {
    if (!!ip && !ip.includes('/')) {
      return `${ip}/${ipVersion === FIREWALL_IP_VERSION_VALUES.IPV4 ? '32' : '128'}`;
    }
    return ip;
  }
}
