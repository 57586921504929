import { ILayoutItem } from '@microsec/interfaces';
import { CustomRepositoriesComponent } from './custom-repositories/custom-repositories.component';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { REPOSITORY_MANAGEMENT_CONSTANTS } from './repository-management.constants';

export const REPOSITORY_MANAGEMENT_CHILDREN: ILayoutItem[] = [
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.REPOSITORIES.ROUTE,
    label: '',
    route: REPOSITORY_MANAGEMENT_CONSTANTS.CUSTOM.ROUTE,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.REPOSITORIES.ROUTE,
    route: REPOSITORY_MANAGEMENT_CONSTANTS.CUSTOM.ROUTE,
    label: REPOSITORY_MANAGEMENT_CONSTANTS.CUSTOM.LABEL,
    component: CustomRepositoriesComponent,
  },
];
