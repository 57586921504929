import { ChartOptions } from 'chart.js';

export const DATA_BLOCKS = {
  KEYS_IN_USE: {
    KEY: 'KEYS_IN_USE',
    LABEL: 'Keys in Use',
  },
  CERTIFICATES_IN_USE: {
    KEY: 'CERTIFICATES_IN_USE',
    LABEL: 'Certificates in Use',
  },
};

export const DIAGRAMS = {
  DEVICES_PER_KEY_ALGORITHM: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_PER_KEY_ALGORITHM',
    LABEL: 'Devices per Key Algorithm',
    CHILDREN: [],
  },
  DEVICES_PER_SIGNATURE_ALGORITHM: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_PER_SIGNATURE_ALGORITHM',
    LABEL: 'Devices per Signature Algorithm',
    CHILDREN: [],
  },
};

export const COMMON_DOUGHNUT_LAYOUT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context: any): string => ` ${context.raw}`,
      },
    },
  },
};
