import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { deviceSummarySelectors, fromDeviceSummaryActions } from '@ngrx-device-summary';
import { CommonOverviewComponent } from '@microsec/components';
import { BaseComponent } from '@lcms-components';
import { ChartHelper } from '@microsec/utilities';
import { CommonChart } from '@microsec/models';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { BehaviorSubject } from 'rxjs';
import { COMMON_BAR_LAYOUT_OPTIONS, COMMON_DOUGHNUT_LAYOUT_OPTIONS, COMMON_LINE_LAYOUT_OPTIONS, DATA_BLOCKS, DIAGRAMS } from './general.config';

@Component({
  selector: 'app-devices-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  summary: any = null;

  /**
   * Data blocks
   */
  blocks: any[] = [];

  /**
   * Chart data + options
   */
  charts: CommonChart[] = [];

  /**
   * Diagram constants
   */
  DIAGRAMS = DIAGRAMS;

  /**
   * Diagram UIs
   */
  @ViewChild('overviewComponent') overviewComponent!: CommonOverviewComponent;

  /**
   * List of CA used in the project
   */
  cas: any[] = [];

  /**
   * Is CA loading
   */
  isCALoading = true;

  /**
   * Filter options
   */
  filterOptions = {
    enrolled: '',
    expiring: '',
    expired: '',
  };

  /**
   * Device results for enrolled / expiring / expired / perCA
   */
  deviceResults: { enrolled: any[]; expiring: any[]; expired: any[]; perCA: any[] } = {
    enrolled: [],
    expiring: [],
    expired: [],
    perCA: [],
  };

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.store.dispatch(new fromDeviceSummaryActions.GetSummaryCAs(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId));
    this.subscriptions.push(
      // summary
      this.store.select(deviceSummarySelectors.summary).subscribe((summary) => {
        this.summary = summary;
      }),
      // is CA loading
      this.store.select(deviceSummarySelectors.isCALoading).subscribe((isCALoading) => {
        this.isCALoading = isCALoading;
      }),
      // CAs
      this.store.select(deviceSummarySelectors.cas).subscribe((cas) => {
        this.cas = cas.map((ca) => ({ ...ca, value: ca.ca_id, label: ca.label }));
        this.initData();
      }),
      // Enrolled devices
      this.store.select(deviceSummarySelectors.enrolledDevices).subscribe((enrolledDevices) => {
        this.deviceResults.enrolled = enrolledDevices;
        const chart = this.charts.find((p) => p.key === DIAGRAMS.DEVICES_ENROLLED.KEY);
        if (!!chart) {
          this.updateChartData(chart);
          this.redrawDiagram(chart);
        }
      }),
      // Expiring devices
      this.store.select(deviceSummarySelectors.expiringDevices).subscribe((expiringDevices) => {
        this.deviceResults.expiring = expiringDevices;
        const chart = this.charts.find((p) => p.key === DIAGRAMS.DEVICES_EXPIRING.KEY);
        if (!!chart) {
          this.updateChartData(chart);
          this.redrawDiagram(chart);
        }
      }),
      // Expired devices
      this.store.select(deviceSummarySelectors.expiredDevices).subscribe((expiredDevices) => {
        this.deviceResults.expired = expiredDevices;
        const chart = this.charts.find((p) => p.key === DIAGRAMS.DEVICES_EXPIRED.KEY);
        if (!!chart) {
          this.updateChartData(chart);
          this.redrawDiagram(chart);
        }
      }),
      // Devices per CA
      this.store.select(deviceSummarySelectors.devicesPerCA).subscribe((devicesPerCA) => {
        this.deviceResults.perCA = devicesPerCA;
        const chart = this.charts.find((p) => p.key === DIAGRAMS.DEVICES_PER_CA.KEY);
        if (!!chart) {
          this.updateChartData(chart);
          this.redrawDiagram(chart);
        }
      }),
    );
  }

  override ngOnDestroy(): void {
    this.store.dispatch(new fromDeviceSummaryActions.CancelGetSummaryCAsRequest());
    this.store.dispatch(new fromDeviceSummaryActions.CancelGetEnrolledDeviceSummaryRequest());
    this.store.dispatch(new fromDeviceSummaryActions.CancelGetExpiringDeviceSummaryRequest());
    this.store.dispatch(new fromDeviceSummaryActions.CancelGetExpiredDeviceSummaryRequest());
    this.store.dispatch(new fromDeviceSummaryActions.CancelGetDevicesPerCARequest());
    this.cleanup();
  }

  /**
   * Initialize data
   */
  async initData() {
    await this.prepareConfigs();
    if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
      this.initTemplates();
      this.charts.forEach((chart) => {
        this.updateChartData(chart);
      });
      this.generateDataBlocks();
      this.redrawDiagram();
    }
  }

  /**
   * Generate block data
   */
  private generateDataBlocks() {
    const blocks: any[] = [];
    Object.entries(this.util.cloneDeepObject(DATA_BLOCKS)).forEach(([key, entry]) => {
      let value = 0;
      switch (key) {
        case DATA_BLOCKS.TOTAL.KEY: {
          value = this.summary?.devices?.total || 0;
          break;
        }
        case DATA_BLOCKS.ENROLLED_TODAY.KEY: {
          value = ((this.summary?.devices?.enrolled_past_day as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.ENROLLED_IN_LAST_7D.KEY: {
          value = ((this.summary?.devices?.enrolled_past_week as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.ENROLLED_IN_LAST_30D.KEY: {
          value = ((this.summary?.devices?.enrolled_past_month as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.ACTIVE_LAST_HOUR.KEY: {
          value = ((this.summary?.devices?.active_past_hour as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.ACTIVE_LAST_MONTH.KEY: {
          value = ((this.summary?.devices?.active_past_month as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.EXPIRING_IN_7D.KEY: {
          value = ((this.summary?.devices?.expiring_next_week as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.EXPIRING_IN_30D.KEY: {
          value = ((this.summary?.devices?.expiring_next_month as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.EXPIRED.KEY: {
          value = ((this.summary?.devices?.expired_total as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.BLOCKED.KEY: {
          value = ((this.summary?.devices?.blocked_total as any[]) || []).length;
          break;
        }
        default: {
          break;
        }
      }
      blocks.push({
        value,
        label: (entry as any).LABEL,
        color: (entry as any).COLOR,
      });
    });
    this.blocks = blocks;
  }

  /**
   * Redraw the diagram UI(s)
   * @param chart
   */
  private redrawDiagram(chart?: CommonChart) {
    if (!!this.overviewComponent?.diagrams) {
      if (!chart) {
        this.overviewComponent.diagrams.forEach((diagram) => {
          setTimeout(() => {
            diagram.redraw();
          });
        });
      } else {
        const diagram = this.overviewComponent.diagrams.find((p) => p.chartConfig.key === chart.key);
        setTimeout(() => {
          diagram?.redraw();
        });
      }
    }
  }

  /**
   * Build up the security objects
   */
  private initTemplates() {
    // charts
    const charts: any[] = [];
    Object.entries(DIAGRAMS).forEach(([key, value]) => {
      let chartOptions: ChartOptions | null = null;
      switch (value.TYPE) {
        case 'doughnut': {
          chartOptions = this.util.cloneDeepObject(COMMON_DOUGHNUT_LAYOUT_OPTIONS) as ChartOptions;
          break;
        }
        case 'bar': {
          chartOptions = this.util.cloneDeepObject(COMMON_BAR_LAYOUT_OPTIONS) as ChartOptions;
          break;
        }
        case 'line': {
          chartOptions = this.util.cloneDeepObject(COMMON_LINE_LAYOUT_OPTIONS) as ChartOptions;
          break;
        }

        default: {
          break;
        }
      }
      const chart = this.util.cloneDeepObject(
        {
          type: value.TYPE,
          key,
          label: value.LABEL,
          data: {} as ChartData,
          options: chartOptions,
          children: !!value.CHILDREN.length ? this.util.cloneObjectArray(value.CHILDREN, true) : this.getDefaultChartChildren(key),
          legendCols: value.TYPE === 'doughnut' ? this.getLegendCols() : [],
          unitsetup: null,
          link: !!(value as any).LINK
            ? {
                ...(value as any).LINK,
                URL: this.getLink((value as any)?.LINK?.URL),
              }
            : null,
        },
        true,
      ) as CommonChart;
      if (
        chart.key === DIAGRAMS.DEVICES_ENROLLED.KEY ||
        chart.key === DIAGRAMS.DEVICES_EXPIRING.KEY ||
        chart.key === DIAGRAMS.DEVICES_EXPIRED.KEY ||
        DIAGRAMS.DEVICES_PER_CA.KEY
      ) {
        chart.eventemitter = new BehaviorSubject(null);
        chart.eventsubscription = chart.eventemitter.asObservable();
      }
      charts.push(chart);
    });
    this.charts = charts;
    this.setupChangeOptionEvents();
  }

  /**
   * Get link
   * @param itemUrl
   * @returns
   */
  private getLink(itemUrl: string) {
    const currentUrlArr = this.router.routerState?.snapshot?.url?.split('/');
    const rootUrlArr = currentUrlArr.filter((p, i) => i !== currentUrlArr.length - 1 && i !== currentUrlArr.length - 2);
    return `${rootUrlArr.join('/')}/${itemUrl}`;
  }

  private getDefaultChartChildren(chartKey: string) {
    const results: any[] = [];
    switch (chartKey) {
      case DIAGRAMS.DEVICES_PER_TYPE.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.chassis_type_distribution || [], 'chassis_type'));
        break;
      }
      case DIAGRAMS.DEVICES_PER_OS.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.os_distribution || [], 'os_type'));
        break;
      }
      case DIAGRAMS.DEVICES_PER_CA.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.cas, 'label', 'value'));
        break;
      }
      default: {
        break;
      }
    }
    return results;
  }

  /**
   * Get the legend columns
   */
  getLegendCols() {
    const results: any[] = [
      { field: 'label', header: 'Status', width: 8 },
      { field: 'percent', header: '%', width: 2 },
      { field: 'counter', header: 'Devices', width: 4 },
    ];
    return results;
  }

  /**
   * Generate the data
   * @param chart chart
   */
  updateChartData(chart: CommonChart, selectedOption: any = null) {
    const data = chart.data as ChartData;
    data.labels = [];
    if (chart.type !== 'line') {
      data.datasets = [
        {
          data: this.generateData(chart),
          backgroundColor: [],
          borderWidth: [],
          barPercentage: 0.5,
        } as ChartDataset,
      ];
    } else {
      let children: any[] = chart.children as any[];
      if (!!selectedOption) {
        children = children.filter((p) => p.VALUE === selectedOption);
      }
      data.datasets = children?.map(
        (p) =>
          ({
            data: this.generateData(chart),
            fill: false,
            borderColor: p.COLOR,
            backgroundColor: p.COLOR,
            borderWidth: 1,
          }) as ChartDataset,
      ) as ChartDataset[];
    }
    this.generateLabels(chart, data);
  }

  /**
   * Generate the chart data
   * @param chart
   * @returns
   */
  private generateData(chart: CommonChart): number[] {
    let values: number[] = [];
    if (!!chart.key) {
      switch (chart.key) {
        case DIAGRAMS.DEVICES_PER_TYPE.KEY: {
          values.push(...(((this.summary?.chassis_type_distribution as any[]) || [])?.map((p) => ((p.devices as any[]) || []).length) || []));
          break;
        }
        case DIAGRAMS.DEVICES_PER_OS.KEY: {
          values.push(...(((this.summary?.os_distribution as any[]) || [])?.map((p) => ((p.devices as any[]) || []).length) || []));
          break;
        }
        case DIAGRAMS.DEVICES_ENROLLED.KEY: {
          values = this.calculateValues(chart);
          break;
        }
        case DIAGRAMS.DEVICES_EXPIRING.KEY: {
          values = this.calculateValues(chart);
          break;
        }
        case DIAGRAMS.DEVICES_EXPIRED.KEY: {
          values = this.calculateValues(chart);
          break;
        }
        case DIAGRAMS.DEVICES_PER_CA.KEY: {
          values = this.calculateValues(chart);
          break;
        }
        default: {
          const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
          children?.forEach((item: any) => {
            switch (chart.key) {
              case DIAGRAMS.DEVICES_BY_STATUS.KEY: {
                values.push(this.summary?.devices?.[item.FIELD_TO_CHECK]?.length || 0);
                break;
              }
              default: {
                break;
              }
            }
          });
          break;
        }
      }
    }
    return values;
  }

  /**
   * Generate the chart labels
   * @param chart
   * @param data
   */
  private generateLabels(chart: CommonChart, data: ChartData) {
    switch (chart.type) {
      case 'doughnut': {
        if (!!chart.key) {
          const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
          children?.forEach((item: any) => {
            // Labels
            data.labels?.push(item.LABEL);
            const dataset = data.datasets.find((p) => !!p) as ChartDataset;
            // Colors
            if (!!dataset && !!dataset.backgroundColor) {
              (dataset.backgroundColor as string[]).push(item.COLOR);
              (dataset.borderWidth as number[]).push(0);
            }
          });
        }
        break;
      }
      case 'bar': {
        switch (chart.key) {
          case DIAGRAMS.DEVICES_ENROLLED.KEY: {
            data.labels?.push(...ChartHelper.getChartLabelsByOption(this.filterOptions.enrolled));
            break;
          }
          case DIAGRAMS.DEVICES_EXPIRING.KEY: {
            data.labels?.push(...ChartHelper.getChartLabelsByOption(this.filterOptions.expiring));
            break;
          }
          case DIAGRAMS.DEVICES_EXPIRED.KEY: {
            data.labels?.push(...ChartHelper.getChartLabelsByOption(this.filterOptions.expired));
            break;
          }
          default: {
            break;
          }
        }
        if (!!chart.key) {
          (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any) => {
            const dataset = data.datasets.find((p) => !!p) as ChartDataset;
            if (!!dataset) {
              dataset.label = item.LABEL;
              dataset.borderColor = item.COLOR;
              dataset.backgroundColor = item.COLOR;
              dataset.borderWidth = 1;
            }
          });
        }
        break;
      }
      case 'line': {
        data.labels?.push(...this.deviceResults.perCA.map((p) => p.timestamp));
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Get legend label
   * @param value
   * @param label
   * @returns
   */
  getLegendLabel(value: any, label: string) {
    return label;
  }

  /**
   * Check if should show legend
   * @param chart
   * @returns
   */
  checkChartLegend(chart: CommonChart) {
    return chart.type === 'doughnut' ? 'right' : null;
  }

  /**
   * Calculate chart values
   */
  calculateValues(chart: CommonChart): number[] {
    const values: number[] = [];
    switch (chart.key) {
      case DIAGRAMS.DEVICES_ENROLLED.KEY: {
        values.push(
          ...ChartHelper.getChartDataByOption(
            this.util.sortObjectArray(this.deviceResults.enrolled, 'timestamp', true, true),
            'timestamp',
            this.filterOptions.enrolled,
            'device_count',
          ),
        );
        break;
      }
      case DIAGRAMS.DEVICES_EXPIRING.KEY: {
        values.push(
          ...ChartHelper.getChartDataByOption(
            this.util.sortObjectArray(this.deviceResults.expiring, 'timestamp', true, true),
            'timestamp',
            this.filterOptions.expiring,
            'device_count',
          ),
        );
        break;
      }
      case DIAGRAMS.DEVICES_EXPIRED.KEY: {
        values.push(
          ...ChartHelper.getChartDataByOption(
            this.util.sortObjectArray(this.deviceResults.expired, 'timestamp', true, true),
            'timestamp',
            this.filterOptions.expired,
            'device_count',
          ),
        );
        break;
      }
      case DIAGRAMS.DEVICES_PER_CA.KEY: {
        values.push(...this.deviceResults.perCA.map((p) => p.device_count));
        break;
      }
      default: {
        break;
      }
    }
    return values;
  }

  /**
   * Change filter events from the charts
   */
  setupChangeOptionEvents() {
    this.charts.forEach((chart) => {
      if (!!chart.eventemitter) {
        chart.eventsubscription?.subscribe((value) => {
          if (!!value) {
            switch (chart.key) {
              case DIAGRAMS.DEVICES_ENROLLED.KEY: {
                const { fromDate, toDate } = ChartHelper.getStartEndDateFromOption(value);
                this.filterOptions.enrolled = value;
                this.store.dispatch(
                  new fromDeviceSummaryActions.GetEnrolledDeviceSummary(
                    this.breadcrumbConfig?.organizationId,
                    this.breadcrumbConfig?.projectId,
                    fromDate,
                    toDate,
                  ),
                );
                break;
              }
              case DIAGRAMS.DEVICES_EXPIRING.KEY: {
                const { fromDate, toDate } = ChartHelper.getStartEndDateFromOption(value);
                this.filterOptions.expiring = value;
                this.store.dispatch(
                  new fromDeviceSummaryActions.GetExpiringDeviceSummary(
                    this.breadcrumbConfig?.organizationId,
                    this.breadcrumbConfig?.projectId,
                    fromDate,
                    toDate,
                  ),
                );
                break;
              }
              case DIAGRAMS.DEVICES_EXPIRED.KEY: {
                const { fromDate, toDate } = ChartHelper.getStartEndDateFromOption(value);
                this.filterOptions.expired = value;
                this.store.dispatch(
                  new fromDeviceSummaryActions.GetExpiredDeviceSummary(
                    this.breadcrumbConfig?.organizationId,
                    this.breadcrumbConfig?.projectId,
                    fromDate,
                    toDate,
                  ),
                );
                break;
              }
              case DIAGRAMS.DEVICES_PER_CA.KEY: {
                this.store.dispatch(
                  new fromDeviceSummaryActions.GetDevicesPerCA(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId, value),
                );
                break;
              }
              default: {
                break;
              }
            }
          }
        });
      }
    });
  }
}
