import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_CERTIFICATES_FEATURE_KEY, UserCertificatesState } from './user-certificates.reducer';

// Lookup the 'User Certificates' feature state managed by NgRx
const getUserCertificatesState = createFeatureSelector<UserCertificatesState>(USER_CERTIFICATES_FEATURE_KEY);

// isLoading
const isLoading = createSelector(getUserCertificatesState, (state: UserCertificatesState) => state.loading);

// managers
const managers = createSelector(getUserCertificatesState, (state: UserCertificatesState) => state.managers);

// selectedManager
const selectedManager = createSelector(getUserCertificatesState, (state: UserCertificatesState) => state.selectedManager);

// Configs
const refreshConfig = createSelector(getUserCertificatesState, (state: UserCertificatesState) => ({
  projectId: state.projectId,
}));

export const userCertificatesSelectors = {
  isLoading,
  managers,
  selectedManager,
  refreshConfig,
};
