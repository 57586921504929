export enum ORGANIZATION_MANAGEMENT_GROUPS {
  OVERVIEW = 'Overview',
  SETTINGS = 'Settings',
}

export const ORGANIZATION_MANAGEMENT_CONSTANTS = {
  PROJECTS: {
    ROUTE: 'projects',
    LABEL: 'Projects',
  },
  ORGANIZATION_SETTINGS: {
    ROUTE: 'organization-settings',
    LABEL: 'Organization Settings',
  },
  USER_ACCESS: {
    ROUTE: 'user-access',
    LABEL: 'User Access',
  },
  ENVELOPES: {
    ROUTE: 'envelopes',
    LABEL: 'Envelopes',
  },
  CA_TEMPLATES: {
    ROUTE: 'ca-templates',
    LABEL: 'CA Templates',
  },
  WORKFLOWS: {
    ROUTE: 'workflows',
    LABEL: 'Workflows',
  },
  LABELS: {
    ROUTE: 'labels',
    LABEL: 'Labels',
  },
};
