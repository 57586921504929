export enum GLOBAL_SETTINGS_GROUPS {
  ORGANIZATION = 'Organization',
  ACCESS_SETTINGS = 'Access Settings',
  NOTIFICATION_SETTINGS = 'Notification Settings',
  LICENSE = 'Licenses',
  MORE = 'More',
  SYSTEM = 'System',
}

export const GLOBAL_SETTINGS_CONSTANTS = {
  ORGANIZATIONS: {
    ROUTE: 'organizations',
    LABEL: 'Organizations',
  },
  USERS: {
    ROUTE: 'users',
    LABEL: 'Users',
  },
  LDAP: {
    ROUTE: 'ldap',
    LABEL: 'LDAP',
  },
  CONFIG_2FA: {
    ROUTE: '2fa',
    LABEL: '2FA',
  },
  EVENT_LOGS: {
    ROUTE: 'event-logs',
    LABEL: 'Event Logs',
  },
  SYSTEM_LOGS: {
    ROUTE: 'system-logs',
    LABEL: 'System Logs',
  },
  LICENSES: {
    ROUTE: 'licenses',
    LABEL: 'Licenses',
  },
  CA_TEMPLATES: {
    ROUTE: 'ca-templates',
    LABEL: 'CA Templates',
  },
  WORKFLOWS: {
    ROUTE: 'workflows',
    LABEL: 'Workflows',
  },
};
