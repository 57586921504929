<microsec-details-card [item]="selectedCAManager" [structure]="structure" />

<ng-template #statusTemplate>
  <div class="flex align-items-center">
    <microsec-truncate-text class="text-value">
      {{ util.getUppercaseFirstLetter(selectedCAManager.connection_status) }}
    </microsec-truncate-text>
    <microsec-refresh-button (refreshEvent)="getCaStatus()" />
  </div>
</ng-template>

<ng-template #caCertificatesTemplate>
  <microsec-card [isNestedCard]="true" [isTableCard]="true">
    <microsec-common-table
      itemName="CA Certificate"
      [loading]="isLoading"
      [cols]="cols"
      [values]="values"
      [actionsMenuItems]="actionMenuItems"
      [cellTemplate]="cellTemplate"
      [containerHeaderRightTemplate]="rightTemplate" />
  </microsec-card>
</ng-template>

<ng-template #rightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getCACertificates()" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Type -->
    @case ('type') {
      <microsec-truncate-text>
        {{ util.getUppercaseFirstLetter(rowData[col.field]) }}
      </microsec-truncate-text>
    }
    <!-- Mode -->
    @case ('mode') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'ca-mode' }}
      </microsec-truncate-text>
    }
    <!-- Expiry -->
    @case ('expiry') {
      <microsec-truncate-text>
        {{ rowData[col.field] | date: PIPE_DATETIME }}
      </microsec-truncate-text>
    }
    <!-- CA Templates -->
    @case ('ca_templates') {
      @if (!!rowData[col.field] && !!rowData[col.field].length) {
        <microsec-truncate-text class="underline cursor-pointer" (click)="openCATemplateForm(rowData)">
          {{ rowData[col.field].length + ' Available' }}
        </microsec-truncate-text>
      } @else {
        N/A
      }
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
  }
</ng-template>
