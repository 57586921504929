import { Component, AfterViewInit, ViewChild, Injector } from '@angular/core';
import { KEYRING_TYPES, KEYRING_TYPE_VALUES } from '@lcms-constants';
import { KmsService } from '@lcms-services';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, CREATE_SUCCESS, MICROSEC_NAME, SAVE_CHANGES_LABEL, UPDATE_SUCCESS, VALIDATOR_TYPE } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, finalize } from 'rxjs';

const FORM_PARAMS = {
  TYPE: 'type',
  NAME: 'name',
  DESCRIPTION: 'description',
  CRYPTOKEN_MANAGER_INFO: 'cryptoken_manager_info',
  CRYPTOKEN_MANAGER_ID: 'cryptoken_manager_id',
  TOKEN_ID: 'cryptoken_id',
  PROJECT_ID: 'project_id',
};

@Component({
  selector: 'app-keyring-form',
  templateUrl: './keyring-form.component.html',
  styleUrls: ['./keyring-form.component.scss'],
})
export class KeyringFormComponent extends BaseComponent implements AfterViewInit {
  keyring: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private kmsSrv: KmsService,
    private injector: Injector,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.keyring = this.dialogConfig?.data?.keyring;
    this.initForm();
    this.setupChangeEvents();
    if (!!this.keyring) {
      this.form.patchValue(this.keyring);
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    const appName = ((this.injector.get('environment')?.module as string) || '').replace(MICROSEC_NAME, '').trim();
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: `Create a new KMS keyring to start creating keys in your ${appName}.`,
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        label: 'Type',
        field: 'dropdown',
        options: this.util.cloneObjectArray(KEYRING_TYPES),
        placeholder: 'Select a type',
        required: true,
        fieldInfo: 'Type',
        defaultValue: KEYRING_TYPE_VALUES.FILESYSTEM,
        disabled: !!this.keyring,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: 'Name',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'input',
        required: false,
        fieldInfo: 'Description',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CRYPTOKEN_MANAGER_ID,
        label: 'Cryptoken Manager',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a cryptoken manager',
        required: !!this.keyring ? this.keyring?.type === KEYRING_TYPE_VALUES.CRYPTOKEN : false,
        fieldInfo: 'Cryptoken manager',
        defaultValue: this.keyring?.[FORM_PARAMS.CRYPTOKEN_MANAGER_INFO]?.[FORM_PARAMS.CRYPTOKEN_MANAGER_ID] || null,
        hidden: !!this.keyring ? this.keyring?.type !== KEYRING_TYPE_VALUES.CRYPTOKEN : true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TOKEN_ID,
        label: 'Token',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a token',
        required: !!this.keyring ? this.keyring?.type === KEYRING_TYPE_VALUES.CRYPTOKEN : false,
        fieldInfo: 'Token',
        defaultValue: this.keyring?.[FORM_PARAMS.CRYPTOKEN_MANAGER_INFO]?.[FORM_PARAMS.TOKEN_ID] || null,
        hidden: !!this.keyring ? this.keyring?.type !== KEYRING_TYPE_VALUES.CRYPTOKEN : true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        hidden: true,
        defaultValue: this.breadcrumbConfig?.projectId,
      } as FormItem),
    ];
    this.fields = fields;
  }

  /**
   * Setup change events
   */
  setupChangeEvents() {
    const cryptokenManagerField = this.fields.find((p) => p.name === FORM_PARAMS.CRYPTOKEN_MANAGER_ID);
    const tokenField = this.fields.find((p) => p.name === FORM_PARAMS.TOKEN_ID);
    // Type
    this.form.setChangeEvent(FORM_PARAMS.TYPE, (type) => {
      const isCryptokenType = type === KEYRING_TYPE_VALUES.CRYPTOKEN;
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CRYPTOKEN_MANAGER_ID, !!isCryptokenType ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.TOKEN_ID, !!isCryptokenType ? [VALIDATOR_TYPE.REQUIRED] : []);
      if (!!isCryptokenType) {
        this.kmsSrv.getCryptokenManagers(this.breadcrumbConfig?.projectId).subscribe({
          next: (managers) => {
            if (!!cryptokenManagerField) {
              cryptokenManagerField.options = managers
                .filter((p) => ((p?.connection_status as string) || '').toLowerCase() === 'connected')
                .map((p) => ({ value: p.id, label: p.label }));
            }
            this.form.setControlValue(
              FORM_PARAMS.CRYPTOKEN_MANAGER_ID,
              !!this.keyring ? this.keyring?.[FORM_PARAMS.CRYPTOKEN_MANAGER_INFO]?.[FORM_PARAMS.CRYPTOKEN_MANAGER_ID] : null,
            );
            this.form.setControlValue(
              FORM_PARAMS.TOKEN_ID,
              !!this.keyring ? this.keyring?.[FORM_PARAMS.CRYPTOKEN_MANAGER_INFO]?.[FORM_PARAMS.TOKEN_ID] : null,
            );
          },
          error: (err) => {
            this.showErrorMessage(err);
          },
        });
      } else {
        this.form.setControlValue(FORM_PARAMS.CRYPTOKEN_MANAGER_ID, null);
        this.form.setControlValue(FORM_PARAMS.TOKEN_ID, null);
      }
    });
    // Cryptoken manager
    this.form.setChangeEvent(FORM_PARAMS.CRYPTOKEN_MANAGER_ID, (managerId) => {
      if (!!managerId) {
        this.kmsSrv.getCryptokens(managerId).subscribe({
          next: (tokens) => {
            if (!!tokenField) {
              const connectedTokens = tokens.filter((p) => ((p?.connection_status as string) || '').toLowerCase() === 'connected');
              tokenField.options = connectedTokens.map((p) => ({ value: p.id, label: p.label }));
              if (!!connectedTokens?.length) {
                this.form.enableControl(FORM_PARAMS.TOKEN_ID);
              } else {
                this.form.disableControl(FORM_PARAMS.TOKEN_ID);
              }
            }
            this.form.setControlValue(
              FORM_PARAMS.TOKEN_ID,
              !!this.keyring ? this.keyring?.[FORM_PARAMS.CRYPTOKEN_MANAGER_INFO]?.[FORM_PARAMS.TOKEN_ID] : null,
            );
          },
          error: (err) => {
            this.showErrorMessage(err);
          },
        });
      } else {
        this.form.disableControl(FORM_PARAMS.TOKEN_ID);
      }
    });
  }

  /**
   * Submit the form data
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form.getRawValue() };
    const id = !this.keyring ? null : this.keyring?.id;
    const request: Observable<any> = !this.keyring ? this.kmsSrv.createKeyring(payload) : this.kmsSrv.updateKeyring(id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.keyring ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'keyring'));
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
