export const USER_CERTIFICATES_CONSTANTS = {
  OVERVIEW: {
    ROUTE: 'overview',
    LABEL: 'Overview',
  },
  DETAILS: {
    ROUTE: 'details',
    LABEL: 'Details',
  },
  ENROLLMENT: {
    ROUTE: 'enrollment',
    LABEL: 'Enrollment',
  },
};
