import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { PKI_MANAGEMENT_FEATURES, PROJECT_STATUSES, PROJECT_STATUS_VALUES, PROJECT_USAGES } from '@lcms-constants';
import { ProjectService } from '@microsec/services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { CREATE_LABEL, CREATE_SUCCESS, SAVE_CHANGES_LABEL, UPDATE_SUCCESS } from '@microsec/constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  PROJECT_USAGE: 'project_usage',
  SCHEME: 'scheme',
  MICROPKI_ORG_ID: 'upki_org_id',
  ORGANIZATION_ID: 'organization_id',
  PROJECT_STATUS: 'project_status',
  IS_ACTIVE: 'is_active',
};

@Component({
  selector: 'app-shared-project-form',
  templateUrl: './shared-project-form.component.html',
  styleUrls: ['./shared-project-form.component.scss'],
})
export class SharedProjectFormComponent extends BaseComponent implements OnInit {
  isLoading = false;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  isPage = false;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  /**
   * the selected project, including getter and setter
   */
  project: any = null;

  public changed: EventEmitter<any> = new EventEmitter();

  constructor(
    private projectSrv: ProjectService,
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    switch (this.dialogConfig?.data?.project) {
      case null: {
        // Creation
        this.isPage = false;
        break;
      }
      case undefined: {
        // Edit - isPage
        this.isPage = true;
        this.project = undefined;
        break;
      }
      default: {
        // Edit - isDialog
        this.isPage = false;
        this.project = this.dialogConfig?.data?.project;
        break;
      }
    }
    if (this.project === undefined) {
      this.getProjectAndInitForm();
    } else {
      this.initForm();
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ORGANIZATION_ID,
        defaultValue: this.breadcrumbConfig?.organizationId,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: 'Name of the project',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'input',
        fieldInfo: 'Description of the project',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_USAGE,
        label: 'Project Usage',
        showRequiredMark: true,
        field: 'custom',
        fieldInfo: 'Project usage',
        children: this.getProjectUsageChildren(),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_STATUS,
        label: 'Status',
        field: 'dropdown',
        fieldInfo: 'Project status',
        hidden: !this.isEditMode,
        options: this.util.cloneObjectArray(PROJECT_STATUSES),
        defaultValue: !this.project?.[FORM_PARAMS.IS_ACTIVE] ? PROJECT_STATUS_VALUES.INACTIVE : PROJECT_STATUS_VALUES.ACTIVE,
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.MICROPKI_ORG_ID,
        label: 'MicroPKI Organization ID',
        field: 'label',
        fieldInfo: 'MicroPKI organization ID',
        hidden: !this.isPage || !this.project?.[FORM_PARAMS.MICROPKI_ORG_ID],
        defaultValue: '',
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
    if (!!this.project) {
      this.form.patchValue(this.project);
    }
  }

  /**
   * Get project usages
   * @returns
   */
  getProjectUsageChildren() {
    const data =
      !this.isEditMode || !this.project[FORM_PARAMS.PROJECT_USAGE]
        ? {
            acms: false,
            micropki: false,
          }
        : this.project[FORM_PARAMS.PROJECT_USAGE];
    return PROJECT_USAGES.map((p) => {
      const item = {
        name: p.value,
        checkboxLabel: p.label,
        field: 'checkbox',
      } as FormItem;
      switch (p.value) {
        case 'acms': {
          item.defaultValue = !this.isEditMode ? !!this.isX509Featured : data[p.value] ? data[p.value] : false;
          item.disabled = !this.isEditMode ? !this.isX509Featured : true;
          break;
        }
        case 'micropki': {
          item.defaultValue = !this.isEditMode ? !!this.isMicroPKIFeatured : data[p.value] ? data[p.value] : false;
          item.disabled = !this.isEditMode ? !this.isX509Featured || !this.isMicroPKIFeatured : true;
          break;
        }
        default: {
          item.disabled = true;
          break;
        }
      }
      return item;
    });
  }

  /**
   * Get project and init the form after that
   */
  getProjectAndInitForm() {
    this.isLoading = true;
    const projectId = this.breadcrumbConfig?.projectId as number;
    this.projectSrv
      .getProject(projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const projectUsage: any[] = [];
          Object.keys(rs.project_usage).forEach((key) => {
            if (!!rs.project_usage[key]) {
              projectUsage.push(key);
            }
          });
          this.project = {
            ...rs,
            id: projectId,
            projectUsage: projectUsage.join(', '),
          };
          this.initForm();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Create/Edit the project and its profile
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const formData = { ...this.form.getRawValue() };
    delete formData[FORM_PARAMS.MICROPKI_ORG_ID];
    const request: Observable<any> = !this.isEditMode
      ? this.projectSrv.createProject(formData)
      : this.projectSrv.updateProject(this.project.id, formData);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res: any) => {
          const message = !this.isEditMode ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'project'));
          this.changed.emit(res);
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Check whether at least a project usage is chosen
   */
  validateProjectUsage() {
    const projectUsage = this.form?.getRawValue()?.[FORM_PARAMS.PROJECT_USAGE] || {};
    return !!Object.keys(projectUsage).find((k) => !!projectUsage[k]);
  }

  /**
   * Check if X509 Devices allowed
   */
  get isX509Featured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.X509);
  }

  /**
   * Check if MicroPKI Devices allowed
   */
  get isMicroPKIFeatured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.MICRO_PKI);
  }

  /**
   * Check if the current form is in edit mode or create mode
   */
  get isEditMode() {
    return !!this.project;
  }
}
