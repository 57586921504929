import { Component, OnDestroy } from '@angular/core';
import { FEATURES } from '@lcms-constants';
import { BaseComponent as SharedBaseComponent } from '@microsec/components';

@Component({
  template: '',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent extends SharedBaseComponent implements OnDestroy {
  constructor() {
    super();
  }

  checkSystemManagementFeatureEnabled(featureName: string) {
    return this.features?.[FEATURES.SYSTEM_MANAGEMENT]?.[featureName] || false;
  }

  checkPKIManagementFeatureEnabled(featureName: string) {
    return this.features?.[FEATURES.PKI_MANAGEMENT]?.[featureName] || false;
  }

  checkDeviceManagementFeatureEnabled(featureName: string) {
    return this.features?.[FEATURES.DEVICE_MANAGEMENT]?.[featureName] || false;
  }

  checkSupplyChainManagementFeatureEnabled(featureName: string) {
    return this.features?.[FEATURES.SUPPLY_CHAIN_MANAGEMENT]?.[featureName] || false;
  }

  getQuotaLimitation(featureName: string) {
    return this.features?.[FEATURES.QUOTAS]?.[featureName] || 0;
  }
}
