import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { CA_MANAGER_TYPE_VALUES } from '@lcms-constants';
import { BaseComponent } from '@lcms-components';
import { DELETE_LABEL, EDIT_LABEL } from '@microsec/constants';
import { DetailsPanelButtonModel } from '@microsec/models';

@Component({
  selector: 'app-ca-connection-info',
  templateUrl: './ca-connection-info.component.html',
  styleUrls: ['./ca-connection-info.component.scss'],
})
export class CaConnectionInfoComponent extends BaseComponent implements AfterViewInit {
  @Input() isLoading = false;

  _selectedCAManager: any = null;

  @Input() set selectedCAManager(value: any) {
    this._selectedCAManager = value;
    if ((value?.connection_status as string).toLowerCase() === 'disconnected' || value?.type === CA_MANAGER_TYPE_VALUES.MICROSOFT_ADCS_CA) {
      this.tabIndex = 0;
    }
    this.prepareActionButtons();
  }

  get selectedCAManager() {
    return this._selectedCAManager;
  }

  @Output() selectedCAManagerChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() openCAManagerFormEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() confirmDeletionEvent: EventEmitter<any> = new EventEmitter<any>();

  tabIndex = 0;

  actionButtons: DetailsPanelButtonModel[] = [];

  CA_MANAGER_TYPE_VALUES = CA_MANAGER_TYPE_VALUES;

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.prepareActionButtons();
  }

  /**
   * Unselect CA manager
   */
  unselectCAManager() {
    this.selectedCAManagerChange.emit(null);
  }

  /**
   * Prepare action buttons
   */
  prepareActionButtons() {
    this.actionButtons = [
      {
        label: EDIT_LABEL,
        hidden: this.selectedCAManager?.type === CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA,
        command: () => {
          this.openCAManagerFormEvent.emit(this.selectedCAManager);
        },
      },
      {
        label: DELETE_LABEL,
        command: () => {
          this.confirmDeletionEvent.emit(this.selectedCAManager);
        },
      },
    ];
  }
}
