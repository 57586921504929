export const DEVICE_RULE_CONSTANTS = {
  IP: {
    ROUTE: 'ip',
    LABEL: 'IP',
  },
  CAN: {
    ROUTE: 'can',
    LABEL: 'CAN',
  },
  COMMAND: {
    ROUTE: 'command',
    LABEL: 'Command',
  },
  YARA: {
    ROUTE: 'yara',
    LABEL: 'YARA',
  },
};
