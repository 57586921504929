/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Util } from '@microsec/utilities';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import jsonschemaData from '../ca/mock-data/json-schema.json';
import { ICaManagementService } from './ICaManagementService';
import caCertificates from './mock-data/ca-certificates.json';
import caConnections from './mock-data/ca-connections.json';
import importedCertificatesResult from './mock-data/imported-certificates.json';
import caTemplatesData from './mock-data/mock-ca-templates.json';

@Injectable({
  providedIn: 'root',
})
export class MockCaManagementService implements ICaManagementService {
  getCAManagers(projectId: number): Observable<any> {
    const caServers = Util.cloneDeepObject(caConnections);
    caServers.data.forEach((caConnection: any) => {
      if (caConnection.connection_status === 'connected') {
        caConnection.cas = caCertificates.data;
      }
    });
    return scheduled([caServers], asyncScheduler);
  }

  getCAManager(managerId: any): Observable<any> {
    const caServers = Util.cloneDeepObject(caConnections);
    caServers.data.forEach((caConnection: any) => {
      caConnection.cas = caCertificates.data;
    });
    return scheduled(
      [
        {
          data: {
            ...((caServers as any).data as any[]).find((p) => p.id === managerId),
            connection_status: 'connected',
          },
        },
      ],
      asyncScheduler,
    );
  }

  createCAManager(payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  editCAManager(managerId: any, payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  deleteCAManager(managerId: any, isForced = false): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  getCACertificates(managerId: any): Observable<any> {
    return scheduled([caCertificates], asyncScheduler);
  }

  getCATemplates(managerId: any, caId: any): Observable<any> {
    const rs: any = { ...caTemplatesData };
    rs.data.jsonschema = jsonschemaData;
    return scheduled([rs], asyncScheduler);
  }

  downloadCRL(managerId: any, caId: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  getEntityCertificates(managerId: any, config: any): Observable<any> {
    const result = Util.cloneDeepObject(importedCertificatesResult);
    let results = result?.data as any[];
    if (config?.availableInRA !== null && config?.availableInRA !== undefined) {
      results = results.filter((p) => p.available_in_ra === config?.availableInRA);
    }
    if (!!(config?.issuedBy || []).length) {
      results = results.filter((p) => !!(p.certs as any[]).find((c) => !!config?.issuedBy.includes(c.id)));
    }
    if (!!config?.commonName) {
      results = results.filter((p) => (p.cn as string) === config.commonName);
    }
    result.data = results;
    return scheduled([result], asyncScheduler);
  }

  backImportCertificates(managerId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
