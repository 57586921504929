import { Util, XlsxHelper } from '@microsec/utilities';
import { MOMENT_DATETIME, FILE_EXTENSION } from '@microsec/constants';
import moment from 'moment';
import { DevicesAction, DevicesActionTypes } from './devices.actions';

export const DEVICES_FEATURE_KEY = 'devices';

export interface DevicesState {
  organizationId?: any;
  projectId?: any;
  config?: any;
  selectedDevice?: any | null;
  devices: any[];
  loading: boolean;
  error?: string | null;
  lastRefresh?: Date | null;
  lastRefreshDeviceEvents?: Date | null;
  filters?: any;
  searchText?: string;
  sortConfig?: any;
  page?: any;
  perPage?: any;
  totalDeviceNumber?: any;
}

export const initialDevicesState: DevicesState = {
  organizationId: null,
  projectId: null,
  config: null,
  selectedDevice: null,
  devices: [],
  loading: false,
  error: null,
  lastRefresh: null,
  lastRefreshDeviceEvents: null,
  filters: null,
  searchText: '',
  sortConfig: null,
  page: 1,
  perPage: 20,
  totalDeviceNumber: 0,
};

export function devicesReducer(state: DevicesState = initialDevicesState, action: DevicesAction): DevicesState {
  switch (action.type) {
    case DevicesActionTypes.INIT: {
      state = Util.cloneDeepObject(initialDevicesState);
      break;
    }
    case DevicesActionTypes.GET_ALL_DEVICES: {
      state = {
        ...state,
        loading: true,
        error: null,
        lastRefresh: null,
      };
      break;
    }
    case DevicesActionTypes.GET_ALL_DEVICES_SUCCESS: {
      const initialConfig = action.payload;
      state = {
        ...state,
        organizationId: initialConfig.organizationId,
        projectId: initialConfig.projectId,
        selectedDevice: !!action.selectedDevice ? ((action.data?.devices as any[]) || [])?.find((p) => p.id === action.selectedDevice.id) : null,
        devices: action.data?.devices || [],
        loading: false,
        error: null,
        lastRefresh: new Date(),
        filters: action.payload.filters,
        searchText: action.payload.searchText,
        sortConfig: action.payload.sortConfig,
        page: action.payload.page,
        perPage: action.payload.perPage,
        totalDeviceNumber: action.data?.total_records,
      };
      break;
    }
    case DevicesActionTypes.GET_ALL_DEVICES_FAILURE: {
      const { error } = action;
      state = {
        ...state,
        loading: false,
        error,
        lastRefresh: new Date(),
      };
      break;
    }
    case DevicesActionTypes.SELECT_DEVICE: {
      const { selectedDevice } = action;
      state = {
        ...state,
        selectedDevice,
      };
      break;
    }
    case DevicesActionTypes.UPDATE_DEVICE: {
      state = {
        ...state,
        loading: true,
        error: null,
        lastRefresh: null,
      };
      break;
    }
    case DevicesActionTypes.UPDATE_DEVICE_SUCCESS: {
      state = {
        ...state,
        loading: false,
        error: null,
        lastRefresh: new Date(),
      };
      break;
    }
    case DevicesActionTypes.UPDATE_DEVICE_FAILURE: {
      const { error } = action;
      state = {
        ...state,
        loading: false,
        error,
        lastRefresh: new Date(),
      };
      break;
    }
    case DevicesActionTypes.REFRESH_DEVICE_EVENTS: {
      state = {
        ...state,
        lastRefreshDeviceEvents: new Date(),
      };
      break;
    }
    case DevicesActionTypes.EXPORT_DEVICES: {
      state = {
        ...state,
        loading: true,
      };
      break;
    }
    case DevicesActionTypes.EXPORT_DEVICES_SUCCESS: {
      const devices: any[] = [];
      action.results.forEach((device: any) => {
        const createDataRow = (data: any) => ({
          'Organization ID': data.organization_id,
          'Project ID': data.project_id,
          'Device ID': data.id,
          'Device Name': data.common_name,
          'Status': data.status,
          'Type': data.device_type,
          'Last Seen': !!data.last_seen ? moment(data.last_seen).format(MOMENT_DATETIME) : '',
          'Commissioning date': !!(data.certs as any[])?.[0]?.not_before
            ? moment((data.certs as any[])?.[0]?.not_before).format(MOMENT_DATETIME)
            : '',
        });
        devices.push(createDataRow(device));
        if (!!device.children && !!(device.children as any[]).length) {
          (device.children as any[]).forEach((subDevice: any) => {
            devices.push(createDataRow(subDevice));
          });
        }
      });
      XlsxHelper.exportSingleSheet(devices, `Devices_${state?.organizationId}` + `_${state?.projectId}`, FILE_EXTENSION.XLSX);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case DevicesActionTypes.EXPORT_DEVICES_FAILURE: {
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    default: {
      break;
    }
  }
  return state;
}
