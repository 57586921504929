import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { DetailsComponent } from './details/details.component';
import { EnrollmentComponent } from './enrollment/enrollment.component';
import { OverviewComponent } from './overview/overview.component';
import { USER_CERTIFICATES_CONSTANTS } from './user-certificates.constants';

export const USER_CERTIFICATES_CHILDREN: ILayoutItem[] = [
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.USER_CERTIFICATES.ROUTE,
    label: '',
    route: USER_CERTIFICATES_CONSTANTS.OVERVIEW.ROUTE,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.USER_CERTIFICATES.ROUTE,
    route: USER_CERTIFICATES_CONSTANTS.OVERVIEW.ROUTE,
    label: USER_CERTIFICATES_CONSTANTS.OVERVIEW.LABEL,
    component: OverviewComponent,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.USER_CERTIFICATES.ROUTE,
    route: USER_CERTIFICATES_CONSTANTS.DETAILS.ROUTE,
    label: USER_CERTIFICATES_CONSTANTS.DETAILS.LABEL,
    component: DetailsComponent,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.USER_CERTIFICATES.ROUTE,
    route: USER_CERTIFICATES_CONSTANTS.ENROLLMENT.ROUTE,
    label: USER_CERTIFICATES_CONSTANTS.ENROLLMENT.LABEL,
    component: EnrollmentComponent,
  },
];
