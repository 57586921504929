import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { FIREWALL_CAN_VERSIONS, FIREWALL_CAN_VERSION_VALUES, FIREWALL_TARGETS, FIREWALL_TARGET_VALUES } from '@lcms-constants';
import { RuleService } from '@lcms-services';
import { FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, CREATE_SUCCESS, NUMBER_MAX_LENGTH, SAVE_CHANGES_LABEL, UPDATE_SUCCESS } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, finalize } from 'rxjs';

const FORM_PARAMS = {
  NAME: 'name',
  CAN_VERSION: 'can_version',
  CAN_ID: 'can_id',
  TARGET: 'target',
  RULE_TYPE: 'rule_type',
  PROJECT_ID: 'project_id',
};

@Component({
  selector: 'app-can-rule-form',
  templateUrl: './can-rule-form.component.html',
  styleUrls: ['./can-rule-form.component.scss'],
})
export class CanRuleFormComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  @Input() ruleset: any = null;

  @Input() rule: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  NUMBER_MAX_LENGTH = NUMBER_MAX_LENGTH;

  canIDs: any[] = [];

  constructor(
    public dialogRef: DynamicDialogRef,
    public ruleSrv: RuleService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initForm();
    if (!!this.rule) {
      this.form.patchValue(this.rule);
      (this.rule?.[FORM_PARAMS.CAN_ID] as any[]).forEach((canId) => {
        this.addCanId(canId);
      });
    } else {
      this.addCanId();
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: 'Name',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CAN_VERSION,
        label: 'CAN Version',
        field: 'dropdown',
        placeholder: 'Select a CAN version',
        options: this.util.cloneObjectArray(FIREWALL_CAN_VERSIONS),
        defaultValue: FIREWALL_CAN_VERSION_VALUES.CLASSIC,
        fieldInfo: 'Firewall rule CAN version',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CAN_ID,
        label: 'CAN Message ID',
        field: 'number',
        defaultValue: null,
        fieldInfo: 'Firewall rule CAN message ID',
        required: true,
        minValue: 0,
        maxValue: 2047,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TARGET,
        label: 'Target',
        field: 'dropdown',
        placeholder: 'Select a target',
        options: this.util
          .cloneObjectArray(FIREWALL_TARGETS)
          .filter((p) => p.value === FIREWALL_TARGET_VALUES.ACCEPT || p.value === FIREWALL_TARGET_VALUES.DROP),
        required: true,
        defaultValue: null,
        fieldInfo: 'Firewall rule target',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE_TYPE,
        hidden: true,
        defaultValue: this.ruleset?.ruleset_type,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        hidden: true,
        defaultValue: this.breadcrumbConfig?.projectId,
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
  }

  /**
   * Submit the firewall form
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = this.form.getRawValue();
    const request: Observable<any> = !this.rule
      ? this.ruleSrv.createRule(this.ruleset?.id, payload)
      : this.ruleSrv.updateRule(this.ruleset?.id, this.rule?.rule_id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.rule ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'CAN rule'));
          closeDialog();
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Add CAN ID
   * @param canId
   */
  addCanId(canId?: any) {
    this.canIDs.push({ key: this.util.generateRandomNumberString(), value: canId });
    this.updateCanIdFormControl();
  }

  /**
   * Remove CAN ID
   * @param index
   */
  removeCanId(index: number) {
    if (this.canIDs.length > 1) {
      this.canIDs.splice(index, 1);
    }
    this.updateCanIdFormControl();
  }

  /**
   * Update CAN ID form control
   */
  updateCanIdFormControl() {
    if (this.canIDs.length === this.canIDs.filter((p) => p.value === null || p.value === '').length) {
      this.form.setControlValue(FORM_PARAMS.CAN_ID, null);
    } else {
      this.form.setControlValue(
        FORM_PARAMS.CAN_ID,
        this.canIDs.map((p) => p.value),
      );
    }
  }
}
