<microsec-form-builder [isPage]="true" #fb [fields]="fields" [shouldShowActionButtons]="false" />

<!-- Bottom panel -->
<div class="grid">
  <label class="col-12 font-bold text-data">Installed on</label>
  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        [loading]="isLoading"
        [cardBackground]="true"
        [cols]="cols"
        [values]="devices"
        [cellTemplate]="cellTemplate"
        itemName="Device" />
    </microsec-card>
    <ng-template let-col="col" let-rowData="rowData" #cellTemplate>
      @switch (col.field) {
        @case ('name') {
          {{ rowData[col.field] }}
          &nbsp;
          @if (!!rowData.out_of_date) {
            <fa-icon
              class="text-red-400"
              [icon]="faExclamationCircle"
              tooltipStyleClass="tooltip-version"
              [escape]="false"
              pTooltip="The package installed on this device is outdated
                <span class='text-cyan-400'>Latest version: {{ rowData.latest_version }}<span>" />
          }
        }
        @case ('operating_system') {
          {{ !!rowData[col.field] ? util.capitalizeWord(rowData[col.field]) : '-' }}
        }
        @default {
          {{ rowData[col.field] }}
        }
      }
    </ng-template>
  </div>
</div>
