/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { SCOPE } from '@microsec/constants';
import { Util } from '@microsec/utilities';
import { asyncScheduler, BehaviorSubject, Observable, scheduled } from 'rxjs';
import { IEnvelopeService } from './IEnvelopeService';
import hashesData from './mock-data/hashes.json';
import signedEnvelopesData from './mock-data/signed-envelopes.json';
import templatesData from './mock-data/templates.json';

@Injectable({
  providedIn: 'root',
})
export class MockEnvelopeService implements IEnvelopeService {
  hashes: any[] = [];

  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  refreshObs: Observable<any> = this.refresh$.asObservable();

  getTemplates(organizationId?: any, projectId?: any): Observable<any> {
    const clonedData = Util.cloneDeepObject(templatesData);
    if (!projectId) {
      clonedData.data = (clonedData.data as any[]).filter((p) => p.scope !== SCOPE.PROJECT);
    }
    return scheduled([clonedData], asyncScheduler);
  }

  createTemplate(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateTemplate(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteTemplate(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getSignedEnvelopes(templateId: any): Observable<any> {
    return scheduled([signedEnvelopesData], asyncScheduler);
  }

  getHashes(): Observable<any> {
    return scheduled([hashesData], asyncScheduler);
  }

  signEnvelope(payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  signCSR(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  checkSchema(schema: string[], content: string): boolean {
    const regex = new RegExp(schema.join('\n'));
    return regex.test(content);
  }

  verifyEnvelope(payload: any): Observable<any> {
    return scheduled([{ is_verified: true }], asyncScheduler);
  }

  downloadEnvelope(envelopeId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  shareEnvelope(envelopeId: any, users: any[]): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  validateLambdaCode(payload: any): Observable<any> {
    return scheduled([{ validity: true }], asyncScheduler);
  }
}
