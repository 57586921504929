@if (!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]) {
  <microsec-card>
    <div class="grid">
      <div class="col-12 text-data">Please select a CA and CA template from the list below. Then, upload a CSR file to download certificate.</div>
      <div class="col-12 mt-2 mb-3">
        <div class="flex">
          @for (csrOption of csrOptions; track $index) {
            <p-radioButton
              labelStyleClass="mr-6 text-data"
              name="csr-option"
              [value]="csrOption.value"
              [label]="csrOption.label"
              [(ngModel)]="selectedOption" />
          }
        </div>
      </div>
    </div>
    <!-- Form -->
    @if (!!selectedOption) {
      @switch (selectedOption) {
        <!-- Generate -->
        @case ('generate') {
          <app-csr-generate-form type="user" />
        }
        <!-- Upload -->
        @case ('upload') {
          <app-user-certificate-csr-upload-form />
        }
        <!-- Others -->
        @default {}
      }
    }
  </microsec-card>
} @else {
  <div class="grid">
    <label class="col-12 text-data"> You do not have access to this option. </label>
  </div>
}
