import { Component, OnInit, ViewChild } from '@angular/core';
import { EnvelopeService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { ObjectPickerHelper } from '@lcms-utilities';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { SCOPE, SHARE_LABEL } from '@microsec/constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  USERS: 'users',
};

@Component({
  selector: 'app-share-envelope-form',
  templateUrl: './share-envelope-form.component.html',
  styleUrls: ['./share-envelope-form.component.scss'],
})
export class ShareEnvelopeFormComponent extends BaseComponent implements OnInit {
  envelope!: any;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  SHARE_LABEL = SHARE_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private envelopeSrv: EnvelopeService,
  ) {
    super();
  }

  async ngOnInit() {
    this.prepareConfigs();
    this.envelope = this.dialogConfig.data?.envelope;
    this.initForm();
  }

  /**
   * Initialize form
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        label: 'You can share this envelope with other user(s).',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.USERS,
        hasNoLabel: true,
        field: 'objectpicker',
        objectPickerSearchScope: SCOPE.PROJECT,
        objectPickerTypes: ['user'],
        defaultValue: [],
        objectPickerSearchConfig: { organizationId: this.breadcrumbConfig?.organizationId, projectId: this.breadcrumbConfig?.projectId },
        objectPickerRequestsFunction: ObjectPickerHelper.getObjectPickerRequestsFunction,
      } as FormItem),
    ];
  }

  /**
   * Share the envelope
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form.getValue() };
    const users: any[] = ((payload?.[FORM_PARAMS.USERS] as any[]) || []).map((p) => p?.id);
    this.envelopeSrv
      .shareEnvelope(this.envelope.id, users)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(
            `The envelope has just been shared with the selected user${users.length === 1 ? '' : 's'}` +
              `. The user${users.length === 1 ? '' : 's'} will receive the notifications`,
          );
          closeDialog();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Check if form value is valid
   */
  get checkValidFormValue() {
    return !!this.form?.getControlValue(FORM_PARAMS.USERS)?.length;
  }
}
