import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@lcms-shared';
import { SharedLicenseWarningComponent } from './shared-license-warning.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [SharedLicenseWarningComponent],
  exports: [SharedLicenseWarningComponent],
})
export class SharedLicenseWarningModule {}
