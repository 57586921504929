import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EnvelopeService } from '@lcms-services';
import { CommonOverviewComponent } from '@microsec/components';
import { BaseComponent } from '@lcms-components';
import { ChartHelper } from '@microsec/utilities';
import { CommonChart } from '@microsec/models';
import { SCOPE, USER_ROLE } from '@microsec/constants';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { asyncScheduler, forkJoin, scheduled } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { COMMON_BAR_LAYOUT_OPTIONS, COMMON_DOUGHNUT_LAYOUT_OPTIONS, DATA_BLOCKS, DIAGRAMS } from './envelope-overview.config';

@Component({
  selector: 'app-envelope-overview',
  templateUrl: './envelope-overview.component.html',
  styleUrls: ['./envelope-overview.component.scss'],
})
export class EnvelopeOverviewComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  templates: any[] = [];

  envelopes: any[] = [];

  /**
   * Data blocks
   */
  blocks: any[] = [];

  /**
   * Chart data + options
   */
  charts: CommonChart[] = [];

  /**
   * Diagram constants
   */
  DIAGRAMS = DIAGRAMS;

  /**
   * Diagram UIs
   */
  @ViewChild('overviewComponent') overviewComponent!: CommonOverviewComponent;

  filterOption = '- ytd';

  constructor(private envelopeSrv: EnvelopeService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
      this.initData();
    }
  }

  /**
   * Initialize data
   */
  initData() {
    this.initTemplates();
    this.isLoading = true;
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions.push(
      this.envelopeSrv
        .getTemplates(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
        .pipe(
          switchMap((res: any) => {
            // Templates
            this.templates = res.data as any[];
            return forkJoin(
              this.templates.map((t) =>
                this.envelopeSrv.getSignedEnvelopes(t.id).pipe(
                  catchError(() => {
                    window.microsec.error(`Cannot get signed envelopes from template ${t.id}`);
                    return scheduled([[]], asyncScheduler);
                  }),
                ),
              ),
            );
          }),
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (envelopesArr: any[]) => {
            const envelopes: any[] = [];
            envelopesArr.forEach((e: any) => envelopes.push(...(!!e.data ? e.data : [])));
            this.envelopes = envelopes;

            this.charts.forEach((chart) => {
              this.updateChartData(chart);
            });
            this.generateDataBlocks();
            this.redrawDiagrams();
          },
          error: (err: any) => {
            this.showErrorMessage(err);
          },
        }),
    );
  }

  /**
   * Generate block data
   */
  private generateDataBlocks() {
    const blocks: any[] = [];
    Object.entries(this.util.cloneDeepObject(DATA_BLOCKS)).forEach(([key, entry]) => {
      let value = 0;
      switch (key) {
        case DATA_BLOCKS.TOTAL_TEMPLATES.KEY: {
          value = this.templates.length;
          break;
        }
        case DATA_BLOCKS.TOTAL_ENVELOPES.KEY: {
          value = this.envelopes.length;
          break;
        }
        default: {
          break;
        }
      }
      blocks.push({ value, label: (entry as any).LABEL });
    });
    this.blocks = blocks;
  }

  /**
   * Redraw the diagram UIs
   */
  private redrawDiagrams() {
    if (!!this.overviewComponent.diagrams) {
      this.overviewComponent.diagrams.forEach((diagram) => {
        setTimeout(() => {
          diagram.redraw();
        });
      });
    }
  }

  /**
   * Build up the security objects
   */
  private initTemplates() {
    // charts
    const charts: any[] = [];
    Object.entries(DIAGRAMS).forEach(([key, value]: [string, any]) => {
      let chartOptions: ChartOptions | null = null;
      switch (value.TYPE) {
        case 'doughnut': {
          chartOptions = this.util.cloneDeepObject(COMMON_DOUGHNUT_LAYOUT_OPTIONS) as ChartOptions;
          break;
        }
        case 'bar': {
          chartOptions = this.util.cloneDeepObject(COMMON_BAR_LAYOUT_OPTIONS) as ChartOptions;
          break;
        }
        default: {
          break;
        }
      }
      const chart = this.util.cloneDeepObject(
        {
          type: value.TYPE,
          key,
          label: value.LABEL,
          data: {} as ChartData,
          options: chartOptions,
          children: this.util.cloneObjectArray(
            value.CHILDREN.filter((p: any) => (this.currentScope === SCOPE.PROJECT ? true : p.LABEL !== SCOPE.PROJECT)),
            true,
          ),
        },
        true,
      ) as CommonChart;
      charts.push(chart);
    });
    this.charts = charts;
  }

  /**
   * Replace the header text
   * @param text
   * @returns
   */
  private replaceHeader(chart: CommonChart) {
    if (!!chart.label) {
      switch (chart.key) {
        case DIAGRAMS.ENVELOPES_CREATED.KEY: {
          chart.label = chart.label.replace('{0}', new Date().getFullYear().toString());
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  /**
   * Generate the data
   * @param chart chart
   * @param isColorKept should keep the colors if dynamic
   */
  updateChartData(chart: CommonChart) {
    const data = chart.data as ChartData;
    data.labels = [];
    data.datasets = [
      {
        data: this.generateData(chart),
        backgroundColor: [],
        borderWidth: [],
        barPercentage: 0.5,
      } as ChartDataset,
    ];
    this.generateLabels(chart, data);
    this.replaceHeader(chart);
  }

  /**
   * Generate the chart data
   * @param chart
   * @param responseData
   * @returns
   */
  private generateData(chart: CommonChart): number[] {
    let values: number[] = [];
    if (!!chart.key) {
      (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any) => {
        switch (chart.key) {
          case DIAGRAMS.ENVELOPE_TEMPLATES_BY_SCOPE.KEY: {
            values.push(
              this.templates.filter((p: any) => (this.currentScope === SCOPE.PROJECT ? true : p.scope !== SCOPE.PROJECT) && p.scope === item.VALUE)
                .length,
            );
            break;
          }
          case DIAGRAMS.ENVELOPES_CREATED.KEY: {
            values = this.calculateValues(chart);
            break;
          }
          default: {
            break;
          }
        }
      });
    }
    return values;
  }

  /**
   * Generate the chart labels
   * @param chart
   * @param data
   */
  private generateLabels(chart: CommonChart, data: ChartData) {
    switch (chart.type) {
      case 'doughnut': {
        if (!!chart.key) {
          (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any) => {
            // Labels
            data.labels?.push(item.LABEL);
            const dataset = data.datasets.find((p) => !!p) as ChartDataset;
            // Colors
            if (!!dataset && !!dataset.backgroundColor) {
              (dataset.backgroundColor as string[]).push(item.COLOR);
              (dataset.borderWidth as number[]).push(0);
            }
          });
        }
        break;
      }
      case 'bar': {
        switch (chart.key) {
          case DIAGRAMS.ENVELOPES_CREATED.KEY: {
            data.labels?.push(...ChartHelper.getChartLabelsByOption(this.filterOption));
            break;
          }
          default: {
            break;
          }
        }
        if (!!chart.key) {
          (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any) => {
            const dataset = data.datasets.find((p) => !!p) as ChartDataset;
            if (!!dataset) {
              dataset.label = item.LABEL;
              dataset.borderColor = item.COLOR;
              dataset.backgroundColor = item.COLOR;
              dataset.borderWidth = 1;
            }
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Get legend label
   * @param chart
   * @param label
   * @returns
   */
  getLegendLabel(value: any, label: string) {
    return label;
  }

  /**
   * Check if should show legend
   * @param chart
   * @returns
   */
  checkChartLegend(chart: CommonChart) {
    return chart.type === 'doughnut' ? 'right' : null;
  }

  /**
   * Calculate chart values
   */
  calculateValues(chart: CommonChart): number[] {
    const values: number[] = [];
    switch (chart.key) {
      case DIAGRAMS.ENVELOPES_CREATED.KEY: {
        values.push(...ChartHelper.getChartDataByOption(this.envelopes, 'created', this.filterOption));
        break;
      }
      default: {
        break;
      }
    }
    return values;
  }

  /**
   * Change filter option from the chart
   */
  changeOptions(value: string) {
    this.filterOption = value;
    this.charts.forEach((chart) => {
      this.updateChartData(chart);
    });
    this.util.updateMaximumChartYAxis(this.charts);
    this.redrawDiagrams();
  }
}
