import { AfterViewInit, Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaTemplatesService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { validatorTrimRequired } from '@microsec/validators';
import { SCOPE } from '@microsec/constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize, Observable } from 'rxjs';

export const CA_TEMPLATE_FORM_PARAMS = {
  DETAILS: 'details',
  NAME: 'name',
  DESCRIPTION: 'description',
  VERSION: 'version',
  KEY_ALGORITHM: 'key_algorithm',
  HASH_ALGORITHM: 'hash_algorithm',
  KEY_SIZE: 'key_size',
  VALIDITY: 'validity',
  EXPECTED_CSR_DN_FIELDS: 'expected_csr_dn_fields',
  EXTENSIONS: 'extensions',
  KEY_USAGE: 'key_usage',
  EXTENDED_KEY_USAGE: 'extended_key_usage',
  BASIC_CONSTRAINTS: 'basic_constraints',
  END_ENTITY: 'end-entity',
  CA: 'ca',
  PATH_LEN_CONSTRAINT: 'path_len_constraint',
  SUBJECT_KEY_IDENTIFIER: 'subject_key_identifier',
  AUTHORITY_KEY_IDENTIFIER: 'authority_key_identifier',
  CERTIFICATE_POLICIES: 'certificate_policies',
  SUBJECT_ALTERNATIVE_NAME: 'subject_alternative_name',
  CRL_DISTRIBUTION_POINT: 'crl_distribution_point',
  FRESHEST_CRL: 'freshest_crl',
  AUTHORITY_INFORMATION_ACCESS: 'authority_information_access',
  CA_ISSUERS: 'ca_issuers',
  OCSP: 'ocsp',
  ENABLED: 'enabled',
  DEFAULT: 'default',
};

export const CA_TEMPLATE_FORM_OPTION_PARAMS = {
  EXTENSIONS: 'extensions',
  KEY_ALGORITHMS: 'key_algorithms',
  KEY_SIZES: 'key_sizes',
  HASH_ALGORITHMS: 'hash_algorithms',
  EXPECTED_CSR_DN_FIELDS: 'expected_csr_dn_fields',
  KEY_USAGES: 'key_usages',
  EXTENDED_KEY_USAGES: 'extended_key_usages',
  KEY_IDENTIFIER_ALGORITHMS: 'key_identifier_algorithms',
  AUTHORITY_INFORMATION_ACCESSES: 'authority_information_accesses',
  SUBJECT_ALTERNATIVE_NAMES: 'subject_alternative_names',
};

@Component({
  selector: 'app-shared-ca-template-form',
  templateUrl: './shared-ca-template-form.component.html',
  styleUrls: ['./shared-ca-template-form.component.scss'],
})
export class SharedCaTemplateFormComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  caTemplateForm?: FormGroup;

  scope = SCOPE.GLOBAL;

  caTemplate: any = null;

  caTemplateHelpers: any = {
    [CA_TEMPLATE_FORM_OPTION_PARAMS.KEY_ALGORITHMS]: [],
    [CA_TEMPLATE_FORM_OPTION_PARAMS.KEY_SIZES]: [],
    [CA_TEMPLATE_FORM_OPTION_PARAMS.HASH_ALGORITHMS]: [],
    [CA_TEMPLATE_FORM_OPTION_PARAMS.EXPECTED_CSR_DN_FIELDS]: [],
    [CA_TEMPLATE_FORM_OPTION_PARAMS.KEY_USAGES]: [],
    [CA_TEMPLATE_FORM_OPTION_PARAMS.EXTENDED_KEY_USAGES]: [],
    [CA_TEMPLATE_FORM_OPTION_PARAMS.KEY_IDENTIFIER_ALGORITHMS]: [],
    [CA_TEMPLATE_FORM_OPTION_PARAMS.SUBJECT_ALTERNATIVE_NAMES]: [],
  };

  keySizeOptions: any[] = [];

  activeTab = 0;

  FORM_PARAMS = CA_TEMPLATE_FORM_PARAMS;

  FORM_OPTION_PARAMS = CA_TEMPLATE_FORM_OPTION_PARAMS;

  mode = 'edit';

  viewTemplates: any[] = [];

  selectedViewTemplate: any = null;

  constructor(
    private fb: FormBuilder,
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private caTemplatesSrv: CaTemplatesService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.scope = this.dialogConfig?.data?.scope;
    this.caTemplate = this.dialogConfig.data.caTemplate;
    this.mode = this.dialogConfig.data.mode || 'edit';
    // Get helpers
    if (this.dialogConfig?.data?.caTemplateHelpers) {
      this.setCATemplateHelpers(this.dialogConfig?.data?.caTemplateHelpers);
    }
    // If the mode is view, get templates and selected template
    if (this.mode === 'view') {
      this.viewTemplates = ((this.dialogConfig.data?.caTemplateData?.templates as any[]) || []).map((p) => ({
        ...p,
        value: p.id,
        label: p.name,
      }));
      this.selectedViewTemplate = this.dialogConfig.data?.caTemplateData?.default_template_id;
    }
    // Loaf form data
    this.initForm();
    this.loadCATemplateForm();
  }

  /**
   * Initialize form
   */
  initForm() {
    this.caTemplateForm = this.fb.group({
      details: this.fb.group({
        [CA_TEMPLATE_FORM_PARAMS.NAME]: ['', validatorTrimRequired],
        [CA_TEMPLATE_FORM_PARAMS.DESCRIPTION]: [''],
        [CA_TEMPLATE_FORM_PARAMS.VERSION]: [null, Validators.required],
        [CA_TEMPLATE_FORM_PARAMS.KEY_ALGORITHM]: [null, Validators.required],
        [CA_TEMPLATE_FORM_PARAMS.HASH_ALGORITHM]: [null, Validators.required],
        [CA_TEMPLATE_FORM_PARAMS.KEY_SIZE]: [{ value: null, disabled: true }, Validators.required],
        [CA_TEMPLATE_FORM_PARAMS.VALIDITY]: [null, Validators.required],
        [CA_TEMPLATE_FORM_PARAMS.EXPECTED_CSR_DN_FIELDS]: [[], Validators.required],
      }),
      [CA_TEMPLATE_FORM_PARAMS.EXTENSIONS]: this.fb.group({
        [CA_TEMPLATE_FORM_PARAMS.KEY_USAGE]: this.fb.group({
          [CA_TEMPLATE_FORM_PARAMS.ENABLED]: [false],
          [CA_TEMPLATE_FORM_PARAMS.DEFAULT]: [[]],
        }),
        [CA_TEMPLATE_FORM_PARAMS.EXTENDED_KEY_USAGE]: this.fb.group({
          [CA_TEMPLATE_FORM_PARAMS.ENABLED]: [false],
          [CA_TEMPLATE_FORM_PARAMS.DEFAULT]: [[]],
        }),
        [CA_TEMPLATE_FORM_PARAMS.BASIC_CONSTRAINTS]: this.fb.group({
          [CA_TEMPLATE_FORM_PARAMS.ENABLED]: [false],
          [CA_TEMPLATE_FORM_PARAMS.DEFAULT]: this.fb.group({
            [CA_TEMPLATE_FORM_PARAMS.CA]: [false],
            [CA_TEMPLATE_FORM_PARAMS.PATH_LEN_CONSTRAINT]: [null],
          }),
        }),
        [CA_TEMPLATE_FORM_PARAMS.AUTHORITY_KEY_IDENTIFIER]: this.fb.group({
          [CA_TEMPLATE_FORM_PARAMS.ENABLED]: [false],
          [CA_TEMPLATE_FORM_PARAMS.DEFAULT]: [null],
        }),
        [CA_TEMPLATE_FORM_PARAMS.SUBJECT_KEY_IDENTIFIER]: this.fb.group({
          [CA_TEMPLATE_FORM_PARAMS.ENABLED]: [false],
          [CA_TEMPLATE_FORM_PARAMS.DEFAULT]: [null],
        }),
        [CA_TEMPLATE_FORM_PARAMS.CERTIFICATE_POLICIES]: this.fb.group({
          [CA_TEMPLATE_FORM_PARAMS.ENABLED]: [false],
          [CA_TEMPLATE_FORM_PARAMS.DEFAULT]: this.fb.array([]),
        }),
        [CA_TEMPLATE_FORM_PARAMS.SUBJECT_ALTERNATIVE_NAME]: this.fb.group({
          [CA_TEMPLATE_FORM_PARAMS.ENABLED]: [false],
          [CA_TEMPLATE_FORM_PARAMS.DEFAULT]: [[]],
        }),
        [CA_TEMPLATE_FORM_PARAMS.CRL_DISTRIBUTION_POINT]: this.fb.group({
          [CA_TEMPLATE_FORM_PARAMS.ENABLED]: [false],
        }),
        [CA_TEMPLATE_FORM_PARAMS.FRESHEST_CRL]: this.fb.group({
          [CA_TEMPLATE_FORM_PARAMS.ENABLED]: [false],
        }),
        [CA_TEMPLATE_FORM_PARAMS.AUTHORITY_INFORMATION_ACCESS]: this.fb.group({
          [CA_TEMPLATE_FORM_PARAMS.ENABLED]: [false],
          [CA_TEMPLATE_FORM_PARAMS.DEFAULT]: this.fb.group({
            [CA_TEMPLATE_FORM_PARAMS.CA_ISSUERS]: [false],
            [CA_TEMPLATE_FORM_PARAMS.OCSP]: [false],
          }),
        }),
      }),
    });
    this.caTemplateForm.get([this.FORM_PARAMS.DETAILS, CA_TEMPLATE_FORM_PARAMS.KEY_ALGORITHM])?.valueChanges.subscribe((value) => {
      const supportedKeySizes = this.caTemplateHelpers[CA_TEMPLATE_FORM_OPTION_PARAMS.KEY_ALGORITHMS].find((item: any) => item.value === value)
        ?.supported_key_sizes;
      this.keySizeOptions = this.caTemplateHelpers[CA_TEMPLATE_FORM_OPTION_PARAMS.KEY_SIZES].filter(
        (item: any) => supportedKeySizes?.includes(item.value),
      );
      if (this.mode !== 'view') {
        const keySizeForm = this.caTemplateForm?.get([this.FORM_PARAMS.DETAILS, CA_TEMPLATE_FORM_PARAMS.KEY_SIZE]);
        keySizeForm?.enable();
        if (!supportedKeySizes?.includes(keySizeForm?.value)) {
          keySizeForm?.setValue(null);
          keySizeForm?.updateValueAndValidity();
        }
      }
    });
    this.caTemplateForm
      .get([
        CA_TEMPLATE_FORM_PARAMS.EXTENSIONS,
        CA_TEMPLATE_FORM_PARAMS.BASIC_CONSTRAINTS,
        CA_TEMPLATE_FORM_PARAMS.DEFAULT,
        CA_TEMPLATE_FORM_PARAMS.CA,
      ])
      ?.valueChanges.subscribe((value) => {
        const formPathLenConstraint = this.caTemplateForm?.get([
          CA_TEMPLATE_FORM_PARAMS.EXTENSIONS,
          CA_TEMPLATE_FORM_PARAMS.BASIC_CONSTRAINTS,
          CA_TEMPLATE_FORM_PARAMS.DEFAULT,
          CA_TEMPLATE_FORM_PARAMS.PATH_LEN_CONSTRAINT,
        ]);
        formPathLenConstraint?.setValidators(!!value ? Validators.required : Validators.nullValidator);
        formPathLenConstraint?.updateValueAndValidity();
      });
    // Set required fields if toggled
    const defaultGroups = [
      CA_TEMPLATE_FORM_PARAMS.KEY_USAGE,
      CA_TEMPLATE_FORM_PARAMS.EXTENDED_KEY_USAGE,
      CA_TEMPLATE_FORM_PARAMS.SUBJECT_ALTERNATIVE_NAME,
      CA_TEMPLATE_FORM_PARAMS.SUBJECT_KEY_IDENTIFIER,
      CA_TEMPLATE_FORM_PARAMS.AUTHORITY_KEY_IDENTIFIER,
    ];
    defaultGroups.forEach((group) => {
      this.caTemplateForm
        ?.get([CA_TEMPLATE_FORM_OPTION_PARAMS.EXTENSIONS, group, CA_TEMPLATE_FORM_PARAMS.ENABLED])
        ?.valueChanges.subscribe((value) => {
          const defaultControl = this.caTemplateForm?.get([CA_TEMPLATE_FORM_OPTION_PARAMS.EXTENSIONS, group, CA_TEMPLATE_FORM_PARAMS.DEFAULT]);
          defaultControl?.setValidators(!!value ? [Validators.required] : [Validators.nullValidator]);
          defaultControl?.updateValueAndValidity();
        });
    });
  }

  /**
   * Set CA template helper
   * @param value
   */
  setCATemplateHelpers(value: any) {
    const helpers: any = {};
    Object.keys(value).forEach((form) => {
      if (form !== CA_TEMPLATE_FORM_OPTION_PARAMS.EXTENSIONS) {
        const options = value[form]?.options;
        helpers[form] = [];
        if (!!options) {
          Object.keys(options).forEach((option) => {
            helpers[form].push({
              label: options[option]?.description,
              value: form === CA_TEMPLATE_FORM_OPTION_PARAMS.KEY_SIZES ? Number(option) : option,
              ...(form === CA_TEMPLATE_FORM_OPTION_PARAMS.KEY_ALGORITHMS && {
                supported_key_sizes: options[option]?.supported_key_sizes || [],
              }),
            });
          });
        }
      }
    });
    this.caTemplateHelpers = helpers;
  }

  /**
   * Submit the form
   */
  onSubmit() {
    this.isLoading = true;
    const formValue = this.caTemplateForm?.getRawValue();
    const extensionsFormValue = formValue[CA_TEMPLATE_FORM_PARAMS.EXTENSIONS];
    const payload = {
      ...formValue.details,
      [CA_TEMPLATE_FORM_PARAMS.EXTENSIONS]: {
        ...extensionsFormValue,
        [CA_TEMPLATE_FORM_PARAMS.KEY_USAGE]: this.setExtensionsPayload(extensionsFormValue[CA_TEMPLATE_FORM_PARAMS.KEY_USAGE]),
        [CA_TEMPLATE_FORM_PARAMS.EXTENDED_KEY_USAGE]: this.setExtensionsPayload(extensionsFormValue[CA_TEMPLATE_FORM_PARAMS.EXTENDED_KEY_USAGE]),
        [CA_TEMPLATE_FORM_PARAMS.BASIC_CONSTRAINTS]: this.setExtensionsPayload(extensionsFormValue[CA_TEMPLATE_FORM_PARAMS.BASIC_CONSTRAINTS]),
        [CA_TEMPLATE_FORM_PARAMS.AUTHORITY_KEY_IDENTIFIER]: this.setExtensionsPayload(
          extensionsFormValue[CA_TEMPLATE_FORM_PARAMS.AUTHORITY_KEY_IDENTIFIER],
        ),
        [CA_TEMPLATE_FORM_PARAMS.SUBJECT_KEY_IDENTIFIER]: this.setExtensionsPayload(
          extensionsFormValue[CA_TEMPLATE_FORM_PARAMS.SUBJECT_KEY_IDENTIFIER],
        ),
        [CA_TEMPLATE_FORM_PARAMS.CERTIFICATE_POLICIES]: this.setExtensionsPayload(extensionsFormValue[CA_TEMPLATE_FORM_PARAMS.CERTIFICATE_POLICIES]),
        [CA_TEMPLATE_FORM_PARAMS.SUBJECT_ALTERNATIVE_NAME]: this.setExtensionsPayload(
          extensionsFormValue[CA_TEMPLATE_FORM_PARAMS.SUBJECT_ALTERNATIVE_NAME],
        ),
        [CA_TEMPLATE_FORM_PARAMS.AUTHORITY_INFORMATION_ACCESS]: this.setExtensionsPayload(
          extensionsFormValue[CA_TEMPLATE_FORM_PARAMS.AUTHORITY_INFORMATION_ACCESS],
        ),
      },
      ...(this.scope === SCOPE.PROJECT && {
        project_id: this.breadcrumbConfig?.projectId as number,
      }),
      ...((this.scope === SCOPE.PROJECT || this.scope === SCOPE.ORGANIZATION) && {
        organization_id: this.breadcrumbConfig?.organizationId as number,
      }),
      scope: this.scope,
    };
    const request: Observable<any> = !this.caTemplate
      ? this.caTemplatesSrv.createCATemplate(payload)
      : this.caTemplatesSrv.updateCATemplate(this.caTemplate.id, payload);
    request
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Saved CA Template successfully');
          this.dialogRef.close(true);
        },
        error: (err) => {
          if (!!err?.error?.errors) {
            const errors = err.error.errors;
            this.showErrorMessageOnForm(errors);
          }
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Show error message on the form
   * @param errors
   */
  showErrorMessageOnForm(errors: any) {
    Object.keys(errors).forEach((form) => {
      if (form === CA_TEMPLATE_FORM_PARAMS.EXTENSIONS) {
        Object.keys(errors[form]).forEach((formChild) => {
          const extensionError = errors[form][formChild];
          const messageArray: string[] = [];
          if (!!extensionError[CA_TEMPLATE_FORM_PARAMS.ENABLED]) {
            messageArray.push(...extensionError[CA_TEMPLATE_FORM_PARAMS.ENABLED]);
          }
          if (!!extensionError[CA_TEMPLATE_FORM_PARAMS.DEFAULT]) {
            if (formChild === CA_TEMPLATE_FORM_PARAMS.BASIC_CONSTRAINTS || formChild === CA_TEMPLATE_FORM_PARAMS.AUTHORITY_INFORMATION_ACCESS) {
              messageArray.push(
                ...Object.keys(extensionError[CA_TEMPLATE_FORM_PARAMS.DEFAULT]).map(
                  (formGrandchild) => extensionError[CA_TEMPLATE_FORM_PARAMS.DEFAULT][formGrandchild],
                ),
              );
            } else {
              messageArray.push(...extensionError[CA_TEMPLATE_FORM_PARAMS.DEFAULT]);
            }
          }
          this.caTemplateForm?.get([form, formChild])?.setErrors({
            incorrect: true,
            message: messageArray.join('<br/>'),
          });
        });
      } else {
        this.caTemplateForm?.get([this.FORM_PARAMS.DETAILS, form])?.setErrors({ incorrect: true, message: errors[form].join('<br/>') });
      }
    });
  }

  /**
   * Set extensions payload
   * @param value
   * @returns
   */
  setExtensionsPayload(value: any) {
    return {
      enabled: value.enabled,
      ...(!!value.enabled && {
        default: value.default,
      }),
    };
  }

  /**
   * Load CA template form
   */
  loadCATemplateForm() {
    if (this.mode === 'view') {
      this.caTemplate = this.viewTemplates.find((p) => p.id === this.selectedViewTemplate);
      (
        this.caTemplateForm?.get([
          CA_TEMPLATE_FORM_PARAMS.EXTENSIONS,
          CA_TEMPLATE_FORM_PARAMS.CERTIFICATE_POLICIES,
          CA_TEMPLATE_FORM_PARAMS.DEFAULT,
        ]) as FormArray
      ).clear();
    }
    if (!!this.caTemplate) {
      this.caTemplateForm?.get(CA_TEMPLATE_FORM_PARAMS.DETAILS)?.patchValue(this.caTemplate);
      this.caTemplateForm?.get(CA_TEMPLATE_FORM_PARAMS.EXTENSIONS)?.patchValue(this.caTemplate[CA_TEMPLATE_FORM_PARAMS.EXTENSIONS]);
      this.caTemplate[CA_TEMPLATE_FORM_PARAMS.EXTENSIONS][CA_TEMPLATE_FORM_PARAMS.CERTIFICATE_POLICIES][CA_TEMPLATE_FORM_PARAMS.DEFAULT]?.forEach(
        (item: any) => {
          this.certPoliciesFormArray?.push(this.fb.control({ value: item, disabled: this.mode === 'view' }, Validators.required));
        },
      );
      this.caTemplateForm?.updateValueAndValidity();
    } else {
      if (this.mode !== 'view') {
        this.caTemplateForm
          ?.get(CA_TEMPLATE_FORM_PARAMS.DETAILS)
          ?.get(CA_TEMPLATE_FORM_PARAMS.EXPECTED_CSR_DN_FIELDS)
          ?.setValue(['ORGANIZATION_NAME', 'ORGANIZATIONAL_UNIT_NAME', 'STATE_OR_PROVINCE_NAME', 'COUNTRY_NAME', 'SERIAL_NUMBER']);
      }
    }
  }

  /**
   * Check if the authority information access valid
   */
  get isAuthorityInformationAccessValid() {
    const formValue = this.caTemplateForm?.get([CA_TEMPLATE_FORM_PARAMS.EXTENSIONS, CA_TEMPLATE_FORM_PARAMS.AUTHORITY_INFORMATION_ACCESS])?.value;
    return (
      !formValue?.[CA_TEMPLATE_FORM_PARAMS.ENABLED] ||
      (!!formValue?.[CA_TEMPLATE_FORM_PARAMS.ENABLED] &&
        (!!formValue?.[CA_TEMPLATE_FORM_PARAMS.DEFAULT][CA_TEMPLATE_FORM_PARAMS.CA_ISSUERS] ||
          !!formValue?.[CA_TEMPLATE_FORM_PARAMS.DEFAULT][CA_TEMPLATE_FORM_PARAMS.OCSP]))
    );
  }

  /**
   * Certificate policies array
   */
  get certPoliciesFormArray() {
    return this.caTemplateForm?.get([
      CA_TEMPLATE_FORM_PARAMS.EXTENSIONS,
      CA_TEMPLATE_FORM_PARAMS.CERTIFICATE_POLICIES,
      CA_TEMPLATE_FORM_PARAMS.DEFAULT,
    ]) as FormArray;
  }
}
