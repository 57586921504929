<div class="grid form">
  <div class="col-12">
    <app-card [isNestedCard]="true">
      <div class="mb-3">
        <label class="font-bold">Event Details</label>
      </div>
      @if (!!event) {
        <div class="flex flex-column mx-3 gap-2 list-fields">
          <div class="grid align-items-center">
            <label class="col-4">Event</label>
            <label class="col-8 text-data overflow-hidden white-space-nowrap text-overflow-ellipsis">{{ event.eventLabel || '-' }}</label>
          </div>
          <div class="grid align-items-center">
            <label class="col-4">Category</label>
            <label class="col-8 text-data">{{ event.categoryLabel || '-' }}</label>
          </div>
          <div class="grid align-items-center">
            <label class="col-4">Criticality</label>
            <label class="col-8 text-data">{{ event.criticalityLabel || '-' }}</label>
          </div>
          <div class="grid align-items-center">
            <label class="col-4">Organization</label>
            <label class="col-8 text-data">{{ event.organizationLabel || '-' }}</label>
          </div>
          <div class="grid align-items-center">
            <label class="col-4">User</label>
            <label class="col-8 text-data">{{ event.userLabel || '-' }}</label>
          </div>
          <div class="grid align-items-center">
            <label class="col-4">Timestamp</label>
            <label class="col-8 text-data">{{ event.timestampLabel || '-' }}</label>
          </div>
          @if (!!event.payload) {
            <ng-container *ngTemplateOutlet="eventDetailsTemplate" />
          }
        </div>
      }
    </app-card>
  </div>
</div>

<ng-template #eventDetailsTemplate>
  <div class="mt-2">
    <app-card>
      <div class="flex justify-content-between align-items-center">
        <label class="font-bold"> More Information </label>
      </div>
      <p-divider></p-divider>
      <app-card [isNoPadding]="true" maxHeight="350px">
        @if (!!event.payload) {
          <ng-container *ngTemplateOutlet="moreInformationTemplate" />
        }
      </app-card>
    </app-card>
  </div>
</ng-template>

<ng-template #moreInformationTemplate>
  <div class="p-4 legend">
    <div class="grid field">
      <label class="col-12">Title</label>
      <div class="col-12 text-grey text-wrap" [pTooltip]="event?.payload?.title" [escape]="false" tooltipPosition="left">
        {{ event.payload.title || '-' }}
      </div>
    </div>

    <div class="grid field">
      @if (!!event.payload.details) {
        <label class="col-12">Details</label>
      }
      <div
        class="col-12 text-grey text-wrap"
        [innerHTML]="event.payload.details"
        [pTooltip]="event.payload.details"
        [escape]="false"
        tooltipPosition="left"
        appendTo="body"></div>
    </div>
  </div>
</ng-template>
