import { Component, OnInit, ViewChild } from '@angular/core';
import { MODULE_CONSTANTS, ORGANIZATION_ID_PARAM_ROUTE } from '@microsec/constants';
import { OrganizationService } from '@microsec/services';
import { BaseComponent } from '@lcms-components';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { CommonTableComponent } from '@microsec/components';

const FIELDS = {
  NAME: 'Name',
  NUMBER_OF_PROJECTS: '# of Projects',
};

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
})
export class OrganizationsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols: any[] = [
    { field: 'name', header: FIELDS.NAME, width: 15, frozen: true },
    { field: 'total_projects', header: FIELDS.NUMBER_OF_PROJECTS, width: 15 },
  ];

  values: any[] = [];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search...',
    hideClearFilters: false,
  };

  filterSearch = '';

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  constructor(private organizationSrv: OrganizationService) {
    super();
  }

  ngOnInit() {
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.handleFilterObjUpdate();
    this.getOrganizations();
    this.organizationSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getOrganizations();
      }
    });
  }

  /**
   * Get organizations
   */
  getOrganizations() {
    this.isLoading = true;
    this.organizationSrv
      .getOrganizations()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any[]) => {
          this.values = rs;
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values.search || '';
      }
    });
  }

  /**
   * Go to organization
   * @param e
   * @param org_id
   */
  goToOrganization(e: MouseEvent, org_id: any) {
    e.preventDefault();
    this.changeRoute(MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.ROUTE.replace(ORGANIZATION_ID_PARAM_ROUTE, org_id));
  }
}
