export const LICENSES_CONSTANTS = {
  DETAILS: {
    ROUTE: ':licenseId/details',
    LABEL: 'License Details',
  },
  UPLOAD: {
    ROUTE: 'upload',
    LABEL: 'License Upload',
  },
};
