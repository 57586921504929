import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RaEnrollmentService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { validatorTrimRequired } from '@microsec/validators';
import { finalize } from 'rxjs';

const FORM_PARAMS = {
  REQUEST_ID: 'request_id',
};

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
})
export class CheckComponent extends BaseComponent implements OnInit {
  @Input() project: any;

  @Input() page: string;

  @Output() pageChange: EventEmitter<string> = new EventEmitter<string>();

  isLoading = false;

  checkForm!: FormGroup;

  result?: any = null;

  FORM_PARAMS = FORM_PARAMS;

  constructor(
    private fb: FormBuilder,
    private raEnrollmentSrv: RaEnrollmentService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.checkForm = this.fb.group({
      [FORM_PARAMS.REQUEST_ID]: ['', validatorTrimRequired],
    });
    this.checkForm.get(FORM_PARAMS.REQUEST_ID)?.valueChanges.subscribe(() => {
      this.result = null;
    });
  }

  /**
   * Go to a page
   * @param page
   */
  goTo(page: string) {
    this.pageChange.emit(page);
  }

  /**
   * Submit form
   */
  onSubmit() {
    this.isLoading = true;
    const requestId = this.util.cloneDeepObject(this.checkForm.getRawValue())?.[FORM_PARAMS.REQUEST_ID];
    this.raEnrollmentSrv
      .checkUserCertificateSelfEnrollmentStatus(requestId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          switch (rs?.status) {
            case 'error': {
              this.showErrorMessage(rs.errorstring);
              this.result = null;
              break;
            }
            case 'pending': {
              this.showErrorMessage('Certificate is not ready.', 'warn');
              this.result = null;
              break;
            }
            default: {
              this.result = rs;
              break;
            }
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Download
   */
  download() {
    if (!!this.result) {
      const requestId = this.util.cloneDeepObject(this.checkForm.getRawValue())?.[FORM_PARAMS.REQUEST_ID];
      this.util.downloadFileFromText(this.result?.certificate, `user_certificate_${requestId}.${this.result?.certificate_format}`);
    }
  }
}
