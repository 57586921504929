import { COMMON_CHART_CONFIG, PAST_DATE_RANGE_TOKEN, SCOPE } from '@microsec/constants';
import { ChartOptions } from 'chart.js';

export const DATA_BLOCKS = {
  TOTAL_TEMPLATES: {
    KEY: 'TOTAL_TEMPLATES',
    LABEL: 'Total Envelope Templates Created',
  },
  TOTAL_ENVELOPES: {
    KEY: 'TOTAL_ENVELOPES',
    LABEL: 'Total Envelopes Created',
  },
};

export const DIAGRAMS = {
  ENVELOPE_TEMPLATES_BY_SCOPE: {
    TYPE: 'doughnut',
    KEY: 'ENVELOPE_TEMPLATES_BY_SCOPE',
    LABEL: 'Envelope Templates by Scope',
    CHILDREN: [
      {
        VALUE: SCOPE.GLOBAL,
        LABEL: SCOPE.GLOBAL,
        COLOR: '#1D3176',
      },
      {
        VALUE: SCOPE.ORGANIZATION,
        LABEL: SCOPE.ORGANIZATION,
        COLOR: '#176BA0',
      },
      {
        VALUE: SCOPE.PROJECT,
        LABEL: SCOPE.PROJECT,
        COLOR: '#00D5FF',
      },
    ],
  },
  ENVELOPES_CREATED: {
    TYPE: 'bar',
    KEY: 'ENVELOPES_CREATED',
    LABEL: `Envelopes Created${PAST_DATE_RANGE_TOKEN}`,
    CHILDREN: [
      {
        LABEL: 'Envelopes',
        COLOR: '#34AEC7',
      },
    ],
  },
};

export const COMMON_DOUGHNUT_LAYOUT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context: any): string => ` ${context.raw}`,
      },
    },
  },
};

export const COMMON_BAR_LAYOUT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      title: {
        display: false,
      },
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
    y: {
      title: {
        display: false,
      },
      beginAtZero: true,
      min: 0,
      max: undefined,
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
        stepSize: 1,
        callback: COMMON_CHART_CONFIG.INTEGER_LABEL_ONLY_CALLBACK,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
  },
};
