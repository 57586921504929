import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { DELETE_LABEL, EDIT_LABEL, SCOPE, USER_ROLE } from '@microsec/constants';
import { DetailsPanelButtonModel } from '@microsec/models';
import { MICROSEC_MICROAGENT_REPO } from '../../repository-management.constants';

@Component({
  selector: 'app-repository-info',
  templateUrl: './repository-info.component.html',
  styleUrls: ['./repository-info.component.scss'],
})
export class RepositoryInfoComponent extends BaseComponent implements AfterViewInit {
  @Input() isLoading = false;

  _selectedRepo: any = null;

  @Input() set selectedRepo(value: any) {
    this._selectedRepo = value;
    this.prepareActionButtons();
  }

  get selectedRepo() {
    return this._selectedRepo;
  }

  @Output() selectedRepoChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() openRepoFormEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() openDeleteConfirmationEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() getRepositoriesEvent: EventEmitter<any> = new EventEmitter<any>();

  actionButtons: DetailsPanelButtonModel[] = [];

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.prepareActionButtons();
  }

  /**
   * Prepare action buttons
   */
  prepareActionButtons() {
    this.actionButtons = [
      {
        label: EDIT_LABEL,
        hidden: !this.permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] || this.selectedRepo?.name === MICROSEC_MICROAGENT_REPO,
        command: () => {
          this.openRepoForm();
        },
      },
      {
        label: DELETE_LABEL,
        hidden: !this.permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] || this.selectedRepo?.name === MICROSEC_MICROAGENT_REPO,
        command: () => {
          this.openDeleteConfirmation();
        },
      },
    ];
  }

  /**
   * Unselect repository
   */
  unselectRepo() {
    this.selectedRepoChange.emit(null);
  }

  /**
   * Open repository form
   */
  openRepoForm() {
    this.openRepoFormEvent.emit(this.selectedRepo);
  }

  /**
   * Open deletion confirmation dialog
   */
  openDeleteConfirmation() {
    this.openDeleteConfirmationEvent.emit(this.selectedRepo);
  }

  /**
   * Get repositories
   */
  getRepositories() {
    this.getRepositoriesEvent.emit();
  }
}
