@if (!isLoading) {
  <div class="flex justify-content-center user-enrollment-container">
    <div class="flex flex-column align-self-center wrapper">
      <microsec-card>
        <div class="large-padding">
          <div class="grid">
            <!-- Title -->
            <label class="col-12 title text-xl text-white"> User Certificate Enrollment </label>

            <!-- Main content -->
            @if (!!isAllowed) {
              <div class="col-12">
                @switch (page) {
                  @case ('request') {
                    <app-request [(page)]="page" [project]="project" />
                  }
                  @case ('check') {
                    <app-check [(page)]="page" [project]="project" />
                  }
                  @default {
                    <app-home [(page)]="page" />
                  }
                }
              </div>
            } @else {
              <div class="col-12">
                <label class="text-base text-justify line-height-2 text-500">
                  @if (isAllowed === false) {
                    <span> Project {{ project.name }} does not allow to create user certificate enrollment request. </span>
                  }
                  @if (isAllowed === null) {
                    <span> This project does not exist. </span>
                  }
                </label>
              </div>
            }
          </div>
        </div>
      </microsec-card>
    </div>
  </div>
}
