import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-device-status-block',
  templateUrl: './device-status-block.component.html',
  styleUrls: ['./device-status-block.component.scss'],
})
export class DeviceStatusBlockComponent {
  @Input() config: any = {
    iconMax: 0,
    iconValue: 0,
    label: '',
    value: '',
    type: '',
    color: '',
  };
}
