@if (!route.children.length) {
  @if (!!license) {
    <!-- Top Info -->
    <div class="header">
      <microsec-card [isFull]="true">
        <div class="flex flex-wrap justify-content-between align-items-center">
          <div class="flex flex-order-0 flex-column lg:flex-row justify-content-between">
            <div class="field mr-4">
              <label class="col text-data text-base">Plan:</label>
              <label class="col text-base font-bold">{{ license.name }}</label>
            </div>
            <div class="field mr-4">
              <label class="col text-data text-base">Expires:</label>
              <label class="col text-base font-bold">{{ license.expires_at | date: PIPE_DATETIME }}</label>
            </div>
            <div class="field mr-4">
              <label class="col text-data text-base">Licensed to:</label>
              <label class="col text-base font-bold">{{ license.contact_email }}</label>
            </div>
          </div>
          <div class="flex flex-order-1 justify-content-end">
            <p-button severity="primary" size="small" [autofocus]="true" styleClass="mr-2" label="Upload new license" (onClick)="goTo('upload')" />
            <p-button severity="primary" size="small" label="Show details" (onClick)="goTo(license.id + '/details')" />
          </div>
        </div>
      </microsec-card>
    </div>

    <div class="grid">
      <div class="col-12">
        <label class="title font-bold"> Features </label>
      </div>
    </div>

    <div class="grid">
      <div class="col-12 lg:col-6">
        <microsec-card [isFull]="true">
          <div class="scrollable">
            @for (feature of allFeatures; track $index) {
              @if (!feature.hidden) {
                <div class="grid">
                  <div class="col-12 border-bottom">
                    <label class="text-base font-bold">
                      {{ feature.name }}
                    </label>
                  </div>
                  @for (subFeature of feature.subFeatures; track $index) {
                    @if (!subFeature.hidden) {
                      <div class="col-12 grid">
                        <label [ngClass]="{ 'text-data': !subFeature.enabled }" class="col text-base">
                          {{ subFeature.name }}
                          <fa-icon class="icon-blue" [icon]="faQuestionCircle" [pTooltip]="subFeature.description" />
                        </label>
                        @if (!!subFeature.enabled) {
                          <div class="col-fixed">
                            <fa-icon class="icon-green" [icon]="faCheck" />
                          </div>
                        }
                      </div>
                    }
                  }
                </div>
              }
            }
          </div>
        </microsec-card>
      </div>
      <div class="col-12 lg:col-6">
        <div class="grid">
          <div class="col-12">
            <microsec-card [isFull]="true">
              <div class="grid">
                <label class="col-12 text-base font-bold">Quotas</label>
                <div class="col-12 flex flex-wrap justify-content-evenly text-center my-2">
                  <div class="grid">
                    <label class="col-12 text-xl font-bold">{{ quotas.organizations.value }}</label>
                    <label class="col-12 text-base">Organizations</label>
                  </div>
                  <div class="grid">
                    <label class="col-12 text-xl font-bold">{{ quotas.projects.value }}</label>
                    <label class="col-12 text-base">Projects</label>
                  </div>
                  <div class="grid">
                    <label class="col-12 text-xl font-bold">{{ quotas.users.value }}</label>
                    <label class="col-12 text-base">Users</label>
                  </div>
                </div>
              </div>
            </microsec-card>
          </div>

          <div class="col-12">
            <microsec-card [isFull]="true">
              <div class="grid">
                <label class="col-12 text-base font-bold">Device Overview</label>
                <div class="col-12 my-2">
                  <div class="grid">
                    <label class="col-12 text-xl font-bold">{{ quotas.devices.value }}</label>
                    <label class="col-12 text-base">Total Devices Licensed</label>
                  </div>
                </div>
              </div>
            </microsec-card>
          </div>
        </div>
      </div>
    </div>
  } @else {
    <div class="license-machine-id">
      <label>Machine ID: {{ machineId }}</label>
    </div>
    <div class="col-12">
      <label class="no-license">No license activated yet.</label>
    </div>
    <div class="col-12">
      <p-button severity="primary" [autofocus]="true" label="Upload new license" (onClick)="goTo('upload')" />
    </div>
  }
}

<router-outlet />
