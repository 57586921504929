import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromDeviceSummary from './+state/device-summary.reducer';
import { DeviceSummaryEffects } from './+state/device-summary.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDeviceSummary.DEVICE_SUMMARY_FEATURE_KEY, fromDeviceSummary.deviceSummaryReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([DeviceSummaryEffects]),
  ],
})
export class NgrxDeviceSummaryModule {}
