<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!form) {
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="grid scroll-panel p-4">
        <div class="col-12">
          <!-- Search box -->
          <div class="col-12 text-right">
            <div class="flex align-items-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  [autofocus]="true"
                  pInputText
                  type="text"
                  (input)="dt?.datatable?.filterGlobal(util.getTextValue($event), 'contains')"
                  placeholder="Search..." />
              </span>
              <microsec-refresh-button (refreshEvent)="getLDAPGroups()" />
            </div>
          </div>

          <!-- LDAP Groups -->
          <div class="col-12">
            <microsec-card [isTableCard]="true">
              <microsec-common-table
                #dt
                [cols]="cols"
                [values]="values"
                itemName="LDAP Group"
                [loading]="!!ldap.isLoading"
                [cellTemplate]="cellTemplate"
                [selectable]="false" />
              <ng-template let-col="col" let-rowData="rowData" #cellTemplate>
                @switch (col.field) {
                  @case ('selected') {
                    <div class="text-center">
                      <p-checkbox
                        [value]="rowData['id']"
                        [binary]="rowData[col.field]"
                        [(ngModel)]="rowData[col.field]"
                        [ngModelOptions]="{ standalone: true }" />
                    </div>
                  }
                  @default {
                    <microsec-truncate-text>
                      {{ rowData[col.field] }}
                    </microsec-truncate-text>
                  }
                }
              </ng-template>
            </microsec-card>
          </div>

          <!-- Role -->
          <div class="col-12">
            <div class="grid align-items-center">
              <div class="col-2">Role<microsec-asterisk></microsec-asterisk></div>
              <div class="col-4">
                <p-dropdown
                  [options]="roles"
                  optionLabel="label"
                  optionValue="value"
                  [formControlName]="FORM_PARAMS.PROJECT_ROLE"
                  placeholder="Select a role"
                  appendTo="body"
                  [ngClass]="{
                    'ng-invalid ng-dirty':
                      !!form.get(FORM_PARAMS.PROJECT_ROLE)?.errors &&
                      !!form.get(FORM_PARAMS.PROJECT_ROLE)?.errors?.['required'] &&
                      (!!form.get(FORM_PARAMS.PROJECT_ROLE)?.dirty || !!form.get(FORM_PARAMS.PROJECT_ROLE)?.touched)
                  }" />
              </div>
              @if (
                !!form.get(FORM_PARAMS.PROJECT_ROLE)?.errors &&
                !!form.get(FORM_PARAMS.PROJECT_ROLE)?.errors?.['required'] &&
                (!!form.get(FORM_PARAMS.PROJECT_ROLE)?.dirty || !!form.get(FORM_PARAMS.PROJECT_ROLE)?.touched)
              ) {
                <label class="col-6 text-error"> Please select a role. </label>
              }
            </div>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <div class="flex justify-content-end action-buttons">
        <p-button severity="secondary" label="Cancel" styleClass="mr-2" (onClick)="dialogRef.close(false)" />
        <p-button severity="success" label="Add" styleClass="" [disabled]="!form || !form.valid" type="submit" />
      </div>
    </form>
  }
</microsec-loading-overlay>
