import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CA_MODE_VALUES, SUPPLY_CHAIN_MANAGEMENT_FEATURES } from '@lcms-constants';
import { CaManagementService, FirmwareService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { UPLOAD_LABEL, VALIDATOR_TYPE } from '@microsec/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

const FORM_PARAMS = {
  NAME: 'name',
  VERSION: 'version',
  TARGET_DEVICE: 'target_device',
  FILE: 'file',
  SIGNED: 'signed',
  SIGN_MODE: 'sign_mode',
  SIGNING_KEY_ID: 'signing_key_id',
  SIGNING_ENVELOPE_TEMPLATE_ID: 'signing_envelope_template_id',
  SIGNING_ENVELOPE_CA_ID: 'signing_envelope_ca_id',
  CA_SERVER_ID: 'ca_server_id',
};

@Component({
  selector: 'app-firmware-form',
  templateUrl: './firmware-form.component.html',
  styleUrls: ['./firmware-form.component.scss'],
})
export class FirmwareFormComponent extends BaseComponent implements OnInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  UPLOAD_LABEL = UPLOAD_LABEL;

  supportedDevices: any[] = [];

  envelopeTemplates: any[] = [];

  caServers: any[] = [];

  intermediateCAs: any[] = [];
  keyPairs: any[] = [];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private caManagementSrv: CaManagementService,
    private firmwareSrv: FirmwareService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.supportedDevices = this.dialogConfig.data?.supportedDevices;
    this.envelopeTemplates = this.dialogConfig.data?.envelopeTemplates;
    this.caServers = this.dialogConfig.data?.caConnections;
    this.initForm();
  }

  /**
   * Init the form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: 'Firmware name',
        defaultValue: '',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.VERSION,
        label: 'Version',
        field: 'input',
        required: true,
        pattern: /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(-[a-zA-Z\d][-a-zA-Z.\d]*)?(\+[a-zA-Z\d][-a-zA-Z.\d]*)?$/,
        patternErrorText: 'Version must be a semantic version string',
        fieldInfo: 'Version information must be a semantic version string, e.g 2.5.1',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TARGET_DEVICE,
        label: 'Target Device',
        field: 'dropdown',
        options: this.supportedDevices,
        required: true,
        placeholder: 'Select a target device',
        fieldInfo: 'Target device',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FILE,
        label: 'Upload Firmware',
        field: 'file',
        acceptedFileTypes: [],
        uploadEvent: new EventEmitter<any>(),
        required: true,
        fieldInfo: 'Upload firmware',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNED,
        checkboxLabel: 'Sign firmware?',
        field: 'checkbox',
        fieldInfo: 'Should sign this firmware?',
        defaultValue: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGN_MODE,
        hasNoLabel: true,
        field: 'radio',
        options: [{ value: 'ca', label: 'Envelope Template' }],
        defaultValue: !!this.isEnvelopeFeatured ? 'ca' : 'keypair',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNING_ENVELOPE_TEMPLATE_ID,
        label: 'Envelope Template',
        field: 'dropdown',
        options: this.envelopeTemplates,
        placeholder: 'Select an envelope template',
        fieldInfo: 'Envelope template',
        defaultValue: null,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_SERVER_ID,
        label: 'CA Server',
        field: 'dropdown',
        options: this.caServers.map((p) => ({ ...p, value: p.id, label: p.name })),
        placeholder: 'Select a CA server',
        fieldInfo: 'CA server',
        defaultValue: null,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNING_ENVELOPE_CA_ID,
        label: 'Signing CA',
        field: 'dropdown',
        options: this.intermediateCAs,
        placeholder: 'Select a CA',
        fieldInfo: 'Signing CA',
        defaultValue: null,
        disabled: true,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGN_MODE,
        hasNoLabel: true,
        field: 'radio',
        options: [{ value: 'keypair', label: 'Key Pair' }],
        defaultValue: !!this.isEnvelopeFeatured ? 'ca' : 'keypair',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNING_KEY_ID,
        label: 'Signing Key',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a key pair',
        fieldInfo: 'Signing key',
        defaultValue: null,
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;
    // File change event
    this.initFileUploadCallback();
    // Change signed event
    this.form.setChangeEvent(FORM_PARAMS.SIGNED, (value: boolean) => {
      this.fields.filter((p) => p.name === FORM_PARAMS.SIGN_MODE)?.forEach((mode) => (mode.hidden = !value));
      const mode = this.form.getControlValue(FORM_PARAMS.SIGN_MODE);
      this.form.setControlValidatorsAndVisibility(
        FORM_PARAMS.SIGNING_ENVELOPE_TEMPLATE_ID,
        !!value ? (mode === 'ca' ? [VALIDATOR_TYPE.REQUIRED] : []) : [],
      );
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CA_SERVER_ID, !!value ? (mode === 'ca' ? [VALIDATOR_TYPE.REQUIRED] : []) : []);
      this.form.setControlValidatorsAndVisibility(
        FORM_PARAMS.SIGNING_ENVELOPE_CA_ID,
        !!value ? (mode === 'ca' ? [VALIDATOR_TYPE.REQUIRED] : []) : [],
      );
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.SIGNING_KEY_ID, !!value ? (mode === 'keypair' ? [VALIDATOR_TYPE.REQUIRED] : []) : []);
    });
    // Change mode event
    this.form.setChangeEvent(FORM_PARAMS.SIGN_MODE, (mode: string) => {
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.SIGNING_ENVELOPE_TEMPLATE_ID, mode === 'ca' ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.CA_SERVER_ID, mode === 'ca' ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.SIGNING_ENVELOPE_CA_ID, mode === 'ca' ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.SIGNING_KEY_ID, mode === 'keypair' ? [VALIDATOR_TYPE.REQUIRED] : []);
    });
    // Change CA Server event
    this.form.setChangeEvent(FORM_PARAMS.CA_SERVER_ID, (caConnectionId) => {
      if (!!caConnectionId) {
        this.getCAIntermediateCertificates(caConnectionId);
      } else {
        this.form.disableControl(FORM_PARAMS.SIGNING_ENVELOPE_CA_ID);
        this.form.setControlValue(FORM_PARAMS.SIGNING_ENVELOPE_CA_ID, null);
      }
    });
  }

  /**
   * Callback after choosing file
   */
  initFileUploadCallback() {
    const fileField = this.fields.find((p) => p.name === FORM_PARAMS.FILE);
    if (!!fileField) {
      fileField.uploadEvent?.subscribe((event: any) => {
        if (!!event.target && !!event.target.files && !!event.target.files.length) {
          const file: any = (event.target.files as FileList).item(0);
          this.form.setControlValue(FORM_PARAMS.FILE, file);
        } else {
          this.form.setControlValue(FORM_PARAMS.FILE, null);
        }
        this.form.getControl(FORM_PARAMS.FILE)?.updateValueAndValidity();
      });
    }
  }

  /**
   * Get the list of CA intermediate certificates
   * @param caConnectionId
   */
  getCAIntermediateCertificates(caConnectionId: any) {
    const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
    const caField = this.fields.find((p) => p.name === FORM_PARAMS.SIGNING_ENVELOPE_CA_ID);
    this.form.setControlValue(FORM_PARAMS.SIGNING_ENVELOPE_CA_ID, null);
    if (!!caServerField && !!caField) {
      const cas: any[] = caServerField.options?.find((caServer) => caServer.value === caConnectionId)?.cas || [];
      this.intermediateCAs = cas
        .filter((p: any) => p.mode === CA_MODE_VALUES.X509)
        .filter((p: any) => (p.type as string).includes('intermediate'))
        .map((p: any) => ({
          ...p,
          value: p.id,
          label: `${p.id}: ${p.subject?.CN} (${p.description})`,
        }));
      caField.options = this.util.sortObjectArray(this.intermediateCAs, 'id');
      if (!!caField.options.length) {
        this.form.enableControl(FORM_PARAMS.SIGNING_ENVELOPE_CA_ID);
      } else {
        this.form.disableControl(FORM_PARAMS.SIGNING_ENVELOPE_CA_ID);
      }
    }
  }

  /**
   * Upload firmware
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form.getRawValue() };
    payload.project_id = this.breadcrumbConfig?.projectId;
    payload.org_id = this.breadcrumbConfig?.organizationId;
    this.firmwareSrv.uploadFirmware(payload).subscribe({
      next: () => {
        this.showSuccessMessage('Uploaded firmware successfully');
        closeDialog();
      },
      error: (err: any) => {
        this.form.showServerErrorMessage(err);
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Check if envelopes featured
   */
  get isEnvelopeFeatured() {
    return this.checkSupplyChainManagementFeatureEnabled(SUPPLY_CHAIN_MANAGEMENT_FEATURES.ENVELOPES);
  }
}
