import { DEVICE_STATUSES } from '@lcms-constants';
import { COMMON_CHART_CONFIG, CUSTOM_UNIT_TOKEN, FUTURE_DATE_RANGE_TOKEN, PAST_DATE_RANGE_TOKEN } from '@microsec/constants';
import { ChartOptions } from 'chart.js';

export const DATA_BLOCKS = {
  TOTAL: {
    KEY: 'TOTAL',
    LABEL: 'Managed in Total',
  },
  ENROLLED_TODAY: {
    KEY: 'ENROLLED_TODAY',
    LABEL: 'Enrolled Today',
    COLOR: '#00AD74',
  },
  ENROLLED_IN_LAST_7D: {
    KEY: 'ENROLLED_IN_LAST_7D',
    LABEL: 'Enrolled in Last 7d',
    COLOR: '#00AD74',
  },
  ENROLLED_IN_LAST_30D: {
    KEY: 'ENROLLED_IN_LAST_30D',
    LABEL: 'Enrolled in Last 30d',
    COLOR: '#00AD74',
  },
  ACTIVE_LAST_HOUR: {
    KEY: 'ACTIVE_LAST_HOUR',
    LABEL: 'Active Last Hour',
    COLOR: '#36C3DF',
  },
  BLOCKED: {
    KEY: 'BLOCKED',
    LABEL: 'Blocked',
    COLOR: '#FB4D58',
  },
  EXPIRING_IN_7D: {
    KEY: 'EXPIRING_IN_7D',
    LABEL: 'Expiring in <7d',
    COLOR: '#FF9534',
  },
  EXPIRING_IN_30D: {
    KEY: 'EXPIRING_IN_30D',
    LABEL: 'Expiring in <30d',
    COLOR: '#FF9534',
  },
  EXPIRED: {
    KEY: 'EXPIRED',
    LABEL: 'Expired',
    COLOR: '#FF9534',
  },
  ACTIVE_LAST_MONTH: {
    KEY: 'ACTIVE_LAST_MONTH',
    LABEL: 'Active Last Month',
    COLOR: '#36C3DF',
  },
};

export const DIAGRAMS = {
  DEVICES_BY_STATUS: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_BY_STATUS',
    LABEL: 'Devices by Status',
    CHILDREN: [
      {
        FIELD_TO_CHECK: 'not_enrolled_total',
        VALUE: DEVICE_STATUSES.NOT_ENROLLED,
        LABEL: DEVICE_STATUSES.NOT_ENROLLED,
        COLOR: '#176BA0',
      },
      {
        FIELD_TO_CHECK: 'pending_total',
        VALUE: 'Pending Enrollment',
        LABEL: DEVICE_STATUSES.PENDING,
        COLOR: '#00D5FF',
      },
      {
        FIELD_TO_CHECK: 'enrolled_total',
        VALUE: DEVICE_STATUSES.ENROLLED,
        LABEL: DEVICE_STATUSES.ENROLLED,
        COLOR: '#01BB83',
      },
      {
        FIELD_TO_CHECK: 'blocked_total',
        VALUE: DEVICE_STATUSES.BLOCKED,
        LABEL: DEVICE_STATUSES.BLOCKED,
        COLOR: '#C02422',
      },
      {
        FIELD_TO_CHECK: 'retired_total',
        VALUE: DEVICE_STATUSES.RETIRED,
        LABEL: DEVICE_STATUSES.RETIRED,
        COLOR: '#FF5C4E',
      },
    ],
  },
  DEVICES_PER_TYPE: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_PER_TYPE',
    LABEL: 'Devices per Type',
    CHILDREN: [],
  },
  DEVICES_PER_OS: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_PER_OS',
    LABEL: 'Devices per OS',
    CHILDREN: [],
  },
  DEVICES_ENROLLED: {
    TYPE: 'bar',
    KEY: 'DEVICES_ENROLLED',
    LABEL: `Devices Enrolled${PAST_DATE_RANGE_TOKEN}`,
    CHILDREN: [
      {
        LABEL: 'Devices',
        COLOR: '#00AD74',
      },
    ],
    LINK: {
      LABEL: 'Manage Devices',
      URL: 'details',
    },
  },
  DEVICES_EXPIRING: {
    TYPE: 'bar',
    KEY: 'DEVICES_EXPIRING',
    LABEL: `Devices Expiring${FUTURE_DATE_RANGE_TOKEN}`,
    CHILDREN: [
      {
        LABEL: 'Devices',
        COLOR: '#FF9534',
      },
    ],
    LINK: {
      LABEL: 'Manage Devices',
      URL: 'details',
    },
  },
  DEVICES_EXPIRED: {
    TYPE: 'bar',
    KEY: 'DEVICES_EXPIRED',
    LABEL: `Devices Expired${PAST_DATE_RANGE_TOKEN}`,
    CHILDREN: [
      {
        LABEL: 'Devices',
        COLOR: '#FB4D58',
      },
    ],
    LINK: {
      LABEL: 'Manage Devices',
      URL: 'details',
    },
  },
  DEVICES_PER_CA: {
    TYPE: 'line',
    KEY: 'DEVICES_PER_CA',
    LABEL: `Devices per CA${CUSTOM_UNIT_TOKEN}`,
    CHILDREN: [],
  },
};

export const COMMON_DOUGHNUT_LAYOUT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context: any): string => ` ${context.raw}`,
      },
    },
  },
};

export const COMMON_BAR_LAYOUT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      title: {
        display: false,
      },
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
    y: {
      title: {
        display: false,
      },
      beginAtZero: true,
      min: 0,
      max: undefined,
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
        stepSize: 1,
        callback: COMMON_CHART_CONFIG.INTEGER_LABEL_ONLY_CALLBACK,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
  },
};

export const COMMON_LINE_LAYOUT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      title: {
        display: false,
      },
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
    y: {
      title: {
        display: false,
      },
      beginAtZero: true,
      min: 0,
      max: undefined,
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
        stepSize: 1,
        callback: COMMON_CHART_CONFIG.INTEGER_LABEL_ONLY_CALLBACK,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
  },
};
