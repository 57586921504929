import { Component, OnInit } from '@angular/core';
import { DEVICE_MANAGEMENT_FEATURES } from '@lcms-constants';
import { RepositoryService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { ActionMenuItem, ConfirmationDialogConfig } from '@microsec/models';
import { DELETE_LABEL } from '@microsec/constants';
import { finalize } from 'rxjs';
import { RepositoryFormComponent } from './repository-form/repository-form.component';
import { MICROSEC_MICROAGENT_REPO } from '../repository-management.constants';

const FIELDS = {
  NAME: 'Name',
  NUMBER_OF_PACKAGES: '# of Packages',
  SIGNING_ACTIVE: 'Signing Active',
};

@Component({
  selector: 'app-custom-repositories',
  templateUrl: './custom-repositories.component.html',
  styleUrls: ['./custom-repositories.component.scss'],
})
export class CustomRepositoriesComponent extends BaseComponent implements OnInit {
  isLoading = false;

  cols = [
    { field: 'name', header: FIELDS.NAME, width: 15 },
    { field: 'packages_count', header: FIELDS.NUMBER_OF_PACKAGES, width: 10 },
    { field: 'signing', header: FIELDS.SIGNING_ACTIVE, width: 15 },
  ];

  selectedRepo: any = null;

  values: any[] = [];

  actionMenuItems: ActionMenuItem[] = [];

  constructor(private repositorySrv: RepositoryService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.PACKAGES)) {
      this.actionMenuItems = [
        {
          label: 'Edit',
          icon: 'fas fa-edit',
          visible: ({ rowData }) => !!this.permissions[this.SCOPE.ORGANIZATION][this.USER_ROLE.ADMIN] && rowData.name !== MICROSEC_MICROAGENT_REPO,
          command: ({ rowData }: any) => this.openRepoForm(rowData),
        },
        {
          label: 'Delete',
          icon: 'fas fa-trash',
          visible: ({ rowData }) => !!this.permissions[this.SCOPE.ORGANIZATION][this.USER_ROLE.ADMIN] && rowData.name !== MICROSEC_MICROAGENT_REPO,
          command: ({ rowData }: any) => this.openDeleteConfirmation(rowData),
        },
      ];
      this.getRepositories();
    } else {
      this.navigateToNotFoundPage();
    }
  }

  /**
   * Unselect repository
   * @param event
   * @param datatable
   * @returns
   */
  unselectRepo(event: any, datatable: any) {
    if (event?.target !== datatable) {
      return;
    }
    this.selectedRepo = null;
  }

  /**
   * Get repositories
   */
  getRepositories() {
    this.isLoading = true;
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.repositorySrv
      .getRepositories(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.values = rs?.repositories as any[];
          if (!!this.selectedRepo) {
            this.selectedRepo = this.values.find((p) => p.id === this.selectedRepo.id);
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Open repository form
   * @param repository
   */
  openRepoForm(repository: any = null) {
    const dialog = this.dialogSrv.open(RepositoryFormComponent, {
      data: { repository },
      header: `${!repository ? 'Create' : 'Edit'} Repository`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getRepositories();
      }
    });
  }

  /**
   * Confirm deletion
   * @param repo
   */
  openDeleteConfirmation(repo: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Repository',
      object: repo,
      acceptRequest: this.repositorySrv.deleteRepository(repo.id),
      next: () => {
        this.showSuccessMessage(`Deleted repository ${repo.name} successfully`);
        if (!!this.selectedRepo && repo.id === this.selectedRepo.id) {
          this.selectedRepo = null;
        }
        this.getRepositories();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }
}
