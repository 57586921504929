/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import { IRepositoryService } from './IRepositoryService';
import * as packagesData from './mock-data/packages.json';
import * as repositoriesData from './mock-data/repositories.json';
import * as agentFormData from './mock-data/agent-form.json';

@Injectable({
  providedIn: 'root',
})
export class MockRepositoryService implements IRepositoryService {
  /** ---------- Repository ---------- */

  getRepositories(projectId: any): Observable<any> {
    return scheduled([repositoriesData as any], asyncScheduler);
  }

  getRepository(id: any): Observable<any> {
    const data = repositoriesData as any;
    const repositoryData = {
      repository: {
        ...(data.repositories as any[]).find((key: any) => key.id === id),
      },
    };
    return scheduled([repositoryData as any], asyncScheduler);
  }

  createRepository(payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  updateRepository(id: any, payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  deleteRepository(id: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  /** ---------- Package ---------- */

  getPackagesByRepository(repoId: any): Observable<any> {
    return scheduled([packagesData as any], asyncScheduler);
  }

  signPackageByRepository(repoId: any, payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  getPackageByRepository(repoId: any, packageId: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  deletePackageByRepository(repoId: any, packageId: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }
  /** ---------- Agent ---------- */

  /**
   * Get the agent form
   * @param projectId
   */
  getAgentForm(projectId: any): Observable<any> {
    return scheduled([agentFormData], asyncScheduler);
  }

  /**
   * Download agent
   * @param payload
   */
  downloadAgent(payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }
}
