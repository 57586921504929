/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEVICE_TYPE } from '@lcms-constants';
import { MOMENT_DATETIME } from '@microsec/constants';
import { Util } from '@microsec/utilities';
import moment from 'moment';
import { MqttService } from 'ngx-mqtt';
import { asyncScheduler, BehaviorSubject, Observable, scheduled, Subscription, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IDeviceService } from './IDeviceService';
import deviceCertSummary from './mock-data/device-cert-summary.json';
import deviceCerts from './mock-data/device-certificates.json';

import deviceCryptoAssetTokens from './mock-data/device-crypto-asset-tokens.json';
import deviceCryptoAssetKeys from './mock-data/device-crypto-asset-keys.json';
import deviceCryptoAssetClientCerts from './mock-data/device-crypto-asset-client-certs.json';
import deviceCryptoAssetCaCerts from './mock-data/device-crypto-asset-ca-certs.json';
import deviceLogs from './mock-data/device-logs.json';
import devicePackages from './mock-data/device-packages.json';
import deviceSummary from './mock-data/device-summary.json';
import microPKIdevices from './mock-data/devices-micropki.json';
import deviceResult from './mock-data/devices.json';
import metricLogs from './mock-data/metric-logs.json';

@Injectable({
  providedIn: 'root',
})
export class MockDeviceService implements IDeviceService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  refreshObs: Observable<any> = this.refresh$.asObservable();

  deviceEvents$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  deviceEventsObs: Observable<any> = this.deviceEvents$.asObservable();

  mqtt: MqttService | null = null;

  mqttSubscription: Subscription | null = null;

  deviceInterval: any = null;

  constructor(public http: HttpClient) {}

  /** ******************************************************************************
   * ****************************** DEVICE CRUD ******************************
   ******************************************************************************** */
  getDeviceSummary(organizationId: any, projectId: any): Observable<any> {
    return scheduled([Util.cloneDeepObject(deviceSummary)], asyncScheduler);
  }

  getSummaryCAs(organizationId: any, projectId: any): Observable<any> {
    return scheduled(
      [
        {
          data: [
            { ca_id: 1, label: 'CA - 1' },
            { ca_id: 2, label: 'CA - 2' },
            { ca_id: 3, label: 'CA - 3' },
            { ca_id: 4, label: 'CA - 4' },
          ],
        },
      ],
      asyncScheduler,
    );
  }

  getDeviceCertificateSummary(organizationId: any, projectId: any, type: any, from: any, to: any): Observable<any> {
    const summary = Util.cloneDeepObject(deviceCertSummary);
    summary.data[0].timestamp = from;
    summary.data[1].timestamp = to;
    return scheduled([summary], asyncScheduler);
  }
  getDevicesPerCA(organizationId: any, projectId: any, caId: any): Observable<any> {
    return scheduled(
      [
        {
          data: [
            { timestamp: '2022-11-08', device_count: 7 },
            { timestamp: '2022-11-09', device_count: 2 },
            { timestamp: '2022-11-30', device_count: 1 },
          ],
        },
      ],
      asyncScheduler,
    );
  }

  getDevices(
    organizationId: any,
    projectId: any,
    filters: any = {},
    searchText: any = '',
    sortConfig: any = null,
    page: any = 1,
    perPage: any = 20,
    keyringId: any = null,
    keyId: any = null,
  ): Observable<any> {
    const deviceData: any = Util.cloneDeepObject(deviceResult);
    deviceData.devices = [...deviceData.devices, ...microPKIdevices];
    (deviceData?.devices as any[]).forEach((device: any) => {
      device.certs = deviceCerts;
    });
    return scheduled([deviceData], asyncScheduler).pipe(
      map((deviceResult: any) => {
        const filteredDevices =
          !!keyringId || !!keyId
            ? (deviceResult?.devices as any[]).filter((device: any) => {
                device.kms_keyring_id === keyringId || device.kms_key_id === keyId;
              })
            : deviceResult?.devices;
        // Get raw results, including both X509 and MicroPKI devices
        let rawResults: any[] = [];
        for (let i = 0; i < perPage / 10; i++) {
          const addedNumber = i * 10 + perPage * (page - 1);
          const deviceRows = Util.cloneObjectArray((filteredDevices as any[]) || []).map((d) => {
            const devices = {
              ...d,
              id: d.id + addedNumber,
              sn: d.id + addedNumber,
              gateway_id: !d.gateway_id ? null : d.gateway_id + addedNumber,
              common_name: d.upki_device_id + addedNumber || (d.name as string).replace(`device-${d.id}`, `device-${d.id + addedNumber}`),
              device_type: !d.gateway_id ? DEVICE_TYPE.X509 : DEVICE_TYPE.MICRO_PKI,
              last_seen: !!d.last_seen ? moment.utc(d.last_seen).local() : null,
              children: [],
            };
            ((devices.certs as any[]) || []).forEach((cert) => {
              cert.not_before = !!cert.not_before ? moment.utc(cert.not_before).local() : null;
              cert.not_after = !!cert.not_after ? moment.utc(cert.not_after).local() : null;
            });
            return devices;
          });
          rawResults.push(...deviceRows);
        }
        rawResults = Util.sortObjectArray(rawResults, 'sn');

        // Get X509 devices
        const x509Devices = rawResults?.filter((d) => !d.gateway_id);
        x509Devices.forEach((d) => {
          // Get MicroPKI devices as X509 device's children
          d.children = rawResults?.filter((p) => p.gateway_id === d.id).map((p, i) => ({ ...p, sn: `${d.id}.${i + 1}` }));
        });
        return { ...deviceResult, devices: x509Devices, total_records: !Object.keys(filters) || !searchText ? deviceResult.total_records : 10 };
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  getAllDevices(
    organizationId: any,
    projectId: any,
    filters: any,
    searchText: any,
    sortConfig: any = null,
    page: any = 1,
    keyringId: any = null,
    keyId: any = null,
    previousResults: any[] = [],
  ): Observable<any> {
    const currentResults = previousResults;
    return this.getDevices(organizationId, projectId, filters, searchText, sortConfig, page, 1000, keyringId, keyId).pipe(
      mergeMap((result: any) => {
        currentResults.push(...((result?.devices as any[]) || []));
        if (page < result.total_pages) {
          return this.getAllDevices(organizationId, projectId, filters, searchText, sortConfig, page + 1, keyringId, keyId, currentResults);
        } else {
          return scheduled([currentResults], asyncScheduler);
        }
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  /**
   * Get all devices without cert Info and with agent filter
   * @param organizationId
   * @param projectId
   * @param usesAgent
   * @returns
   */
  getAllDevicesNonPaginated(
    organizationId: any = null,
    projectId: any = null,
    usesAgent: boolean | null = null,
    searchText: any = '',
  ): Observable<any> {
    const deviceData: any = Util.cloneDeepObject(deviceResult);
    let devices = [...deviceData.devices];
    if (usesAgent !== null) {
      devices = deviceData.devices
        .filter((d: any) => d.uses_agent)
        .map((d: any) => {
          return { ...d, common_name: d.upki_device_id || d.name };
        });
    }
    return scheduled([{ ...deviceData, devices: devices }], asyncScheduler);
  }

  getDevice(deviceId: any): Observable<any> {
    const device = Util.cloneDeepObject(deviceResult?.devices?.find((p) => p.id === deviceId));
    const certs = (deviceCerts as any[]).filter((p) => p.id === deviceId);
    device.certs = certs;
    return scheduled([device], asyncScheduler);
  }

  createDevice(deviceInfo: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  updateDevice(deviceId: any, payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  removeDevice(deviceName: string, isForced = false): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  /** ******************************************************************************
   * ****************************** DEVICE MORE INFO ******************************
   ******************************************************************************** */

  getDevicePackages(deviceId: any): Observable<any> {
    return scheduled([devicePackages], asyncScheduler);
  }

  /** ******************************************************************************
   * ****************************** DEVICE CRYPTO ASSETS ***************************
   ******************************************************************************** */

  getDeviceCryptoAssetTokens(deviceId: any, page: number, perPage: number, search: string): Observable<any> {
    return scheduled([deviceCryptoAssetTokens], asyncScheduler);
  }

  getDeviceCryptoAssetKeys(deviceId: any, page: number, perPage: number, search: string, filter: any): Observable<any> {
    return scheduled([deviceCryptoAssetKeys], asyncScheduler);
  }

  getDeviceCryptoAssetClientCerts(deviceId: any, page: number, perPage: number, search: string): Observable<any> {
    return scheduled([deviceCryptoAssetClientCerts], asyncScheduler);
  }

  getDeviceCryptoAssetCaCerts(deviceId: any, page: number, perPage: number, search: string): Observable<any> {
    return scheduled([deviceCryptoAssetCaCerts], asyncScheduler);
  }

  createDeviceCryptoAssetKey(deviceId: any, payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  createDeviceCryptoAssetClientCert(deviceId: any, payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  createDeviceCryptoAssetCaCert(deviceId: any, payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  importDeviceCryptoAssetKey(deviceId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteDeviceCryptoAssetKey(deviceId: any, keyId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteDeviceCryptoAssetCaCert(deviceId: any, certId: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  /** ******************************************************************************
   * ****************************** DEVICE CA CERTIFICATES *************************
   ******************************************************************************** */

  getDeviceCertificates(projectId: any, kmsKeyId?: any, kmsKeyringId?: any, entityType: string = 'device'): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  importCACertificate(deviceId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteCACertificate(deviceId: any, filePath: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  /** ******************************************************************************
   * ********************** DEVICE CRYPTO ALIAS CONFIGURATION **********************
   ******************************************************************************** */

  createCryptoAlias(deviceId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteCryptoAlias(deviceId: any, filePath: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  /** ******************************************************************************
   * ****************************** DEVICE TELEMETRY ******************************
   ******************************************************************************** */

  getDeviceLog(config: {
    organizationId?: number | null;
    projectId?: number | null;
    deviceId?: number;
    fromDate?: any;
    toDate?: any;
    granularity?: string | null;
  }): Observable<any> {
    let result = {};
    if (!config.deviceId) {
      result = Util.cloneDeepObject(metricLogs);
    } else {
      result = Util.cloneDeepObject(deviceLogs);
    }
    return scheduled([result], asyncScheduler).pipe(
      map((result: any) => {
        const datetimeField = !config.deviceId ? 'timestamp' : 'datetime';
        const logs = (result.data as any[]) || [];
        logs.forEach((log) => this.convertUTCtoLocal(log, datetimeField));
        return { data: logs };
      }),
    );
  }

  private convertUTCtoLocal(log: any, datetimeField: any) {
    const datetime = moment
      .utc(log?.[datetimeField])
      .local()
      .format();
    log[datetimeField] = moment(datetime).local().format(MOMENT_DATETIME);
  }

  startDeviceInfoMQTTSubscription(config: {
    country: string;
    org: string;
    orgUnit: string;
    deviceName: string;
    granularity: number;
  }): Observable<any> {
    const obs = new Observable((observer) => {
      this.deviceInterval = setInterval(() => {
        const result = Util.cloneDeepObject(deviceLogs);
        const logs = Util.cloneObjectArray(result.data);
        const data = logs[Math.floor(Math.random() * logs.length)];
        data.datetime = moment().format(MOMENT_DATETIME).toString();
        this.convertUTCtoLocal(data, 'datetime');
        observer.next(data);
      }, config.granularity * 1000);
    });
    return obs;
  }

  sendDeviceInfoMQTTRequest(
    config: { country: string; org: string; orgUnit: string; deviceName: string },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ): void {}

  unsubscribeDeviceInfoMQTTRequest(interval: any): void {
    this.clearDeviceInfoInterval(interval);
    this.mqttSubscription?.unsubscribe();
  }

  clearDeviceInfoInterval(interval: any): void {
    if (!!interval) {
      clearInterval(interval);
    }
    if (!!this.deviceInterval) {
      clearInterval(this.deviceInterval);
    }
  }
}
