import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API } from '../api';
import { ICaManagementService } from './ICaManagementService';

const API_CA_SERVERS = `${API.CA_MANAGEMENT}/ca-servers`;

@Injectable({
  providedIn: 'root',
})
export class CaManagementService implements ICaManagementService {
  constructor(private http: HttpClient) {}

  getCAManagers(projectId: number): Observable<any> {
    return this.http.get<any>(`${API_CA_SERVERS}?proj_id=${projectId}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getCAManager(managerId: any): Observable<any> {
    return this.http.get<any>(`${API_CA_SERVERS}/${managerId}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createCAManager(payload: any): Observable<any> {
    return this.http.post<any>(`${API_CA_SERVERS}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  editCAManager(managerId: any, payload: any): Observable<any> {
    return this.http.put<any>(`${API_CA_SERVERS}/${managerId}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteCAManager(managerId: any, isForced = false): Observable<any> {
    return this.http
      .delete<any>(`${API_CA_SERVERS}/${managerId}?force=${isForced}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getCACertificates(managerId: any): Observable<any> {
    return this.http.get<any>(`${API_CA_SERVERS}/${managerId}/cas`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getCATemplates(managerId: any, caId: any): Observable<any> {
    return this.http
      .get<any>(`${API_CA_SERVERS}/${managerId}/cas/${caId}/ca_templates`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  downloadCRL(managerId: any, caId: any): Observable<any> {
    const options: any = { responseType: 'blob' };
    return this.http
      .get<any>(`${API_CA_SERVERS}/${managerId}/cas/${caId}/crl`, options)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getEntityCertificates(managerId: any, config: any): Observable<any> {
    const url = `${API_CA_SERVERS}/${managerId}/back-import`;
    const params: string[] = [];
    if (config?.availableInRA !== null && config?.availableInRA !== undefined) {
      params.push(`available_in_ra=${config?.availableInRA}`);
    }
    if (!!config?.issuedBy && !!((config?.issuedBy as any[]) || []).length) {
      params.push(`issued_by=${((config?.issuedBy as any[]) || []).join(',')}`);
    }
    if (!!config?.commonName) {
      params.push(`common_name=${config?.commonName}`);
    }
    if (config?.backImported !== null && config?.backImported !== undefined) {
      params.push(`back_imported=${config?.backImported}`);
    }
    params.push(`page=${!!config?.page ? config.page : 1}`);
    params.push(`per_page=${!!config?.perPage ? config.perPage : 20}`);
    return this.http
      .get<any>(`${url}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  backImportCertificates(managerId: any, payload: any): Observable<any> {
    return this.http
      .post<any>(`${API_CA_SERVERS}/${managerId}/back-import`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
