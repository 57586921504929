import { MODULE_CONSTANTS } from '@microsec/constants';
import { ORGANIZATION_MANAGEMENT_CHILDREN } from './organization-management/organization-management.config';
import { PROJECT_MANAGEMENT_CHILDREN } from './project-management/project-management.config';
import { GLOBAL_SETTINGS_CHILDREN as GLOBAL_SETTINGS_CHILDREN } from './global-settings/global-settings.config';
import { USER_SETTINGS_CHILDREN } from './user-settings/user-settings.config';

export const MODULES = {
  // LCMS RA MODULES
  GLOBAL_SETTINGS: {
    ROUTE: MODULE_CONSTANTS.GLOBAL_SETTINGS.ROUTE,
    LABEL: MODULE_CONSTANTS.GLOBAL_SETTINGS.LABEL,
    CHILDREN: GLOBAL_SETTINGS_CHILDREN,
  },
  ORGANIZATION_MANAGEMENT: {
    ROUTE: MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.ROUTE,
    LABEL: MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.LABEL,
    CHILDREN: ORGANIZATION_MANAGEMENT_CHILDREN,
  },
  PROJECT_MANAGEMENT: {
    ROUTE: MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE,
    LABEL: MODULE_CONSTANTS.PROJECT_MANAGEMENT.LABEL,
    CHILDREN: PROJECT_MANAGEMENT_CHILDREN,
  },
  USER_SETTINGS: {
    ROUTE: MODULE_CONSTANTS.USER_SETTINGS.ROUTE,
    LABEL: MODULE_CONSTANTS.USER_SETTINGS.LABEL,
    CHILDREN: USER_SETTINGS_CHILDREN,
  },
  USER_ENROLLMENT: {
    ROUTE: MODULE_CONSTANTS.USER_ENROLLMENT.ROUTE,
    LABEL: MODULE_CONSTANTS.USER_ENROLLMENT.LABEL,
  },
  // COMMON MODULES
  LOGIN: {
    ROUTE: MODULE_CONSTANTS.LOGIN.ROUTE,
    LABEL: MODULE_CONSTANTS.LOGIN.LABEL,
    CHILDREN: [],
  },
  REDIRECTION: {
    ROUTE: MODULE_CONSTANTS.REDIRECTION.ROUTE,
    LABEL: MODULE_CONSTANTS.REDIRECTION.LABEL,
  },
};
