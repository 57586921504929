import { DeviceAgentFormComponent } from './device-agent-form/device-agent-form.component';
import { DeviceCsrFormComponent } from './device-csr-form/device-csr-form.component';
import { DeviceManualFormComponent } from './device-manual-form/device-manual-form.component';
import { VirtualDeviceFormComponent } from './virtual-device-form/virtual-device-form.component';

export const ENROLLMENT_CONFIGS = [
  {
    key: 'agent',
    label: 'Enroll using MicroAgent<sup>TM</sup>',
    description: 'Enroll device via the installation of MicroAgent<sup>TM</sup>.',
    component: DeviceAgentFormComponent,
  },
  {
    key: 'virtual',
    label: 'Virtual Device',
    description: 'Create virtual X.509 devices with preinstalled LCMS Agent to test the capabilities of LCMS. Click for documentation.',
    component: VirtualDeviceFormComponent,
  },
  {
    key: 'manual',
    label: 'Manual Enrollment',
    description: 'Manual device enrollment through SCEP, EST, CMP, REST-API or KMIP protocols. Click for documentation.',
    component: DeviceManualFormComponent,
  },
  {
    key: 'csr',
    label: 'Generate/Upload CSR',
    description: 'Enroll devices by generating CSR and certificate via our built-in solution or manually uploading a CSR.',
    component: DeviceCsrFormComponent,
  },
];
