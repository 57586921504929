export const CERTIFICATE_STATUSES = {
  VALID: 'Valid',
  HOLD: 'Hold',
  REVOKED: 'Revoked',
  EXPIRED: 'Expired',
};

export const CERTIFICATE_REASONS = {
  [CERTIFICATE_STATUSES.VALID]: [{ value: 'unspecified', label: 'Unspecified' }],
  [CERTIFICATE_STATUSES.HOLD]: [{ value: 'certificateHold', label: 'Hold' }],
  [CERTIFICATE_STATUSES.REVOKED]: [
    { value: 'unspecified', label: 'Unspecified' },
    { value: 'keyCompromise', label: 'Key Compromise' },
    { value: 'cACompromise', label: 'CA Compromise' },
    { value: 'affiliationChanged', label: 'Affiliation Changed' },
    { value: 'superseded', label: 'Superseded' },
    { value: 'cessationOfOperation', label: 'Cease of Operation' },
    { value: 'removeFromCRL', label: 'Remove from CRL' },
    { value: 'privilegeWithdrawn', label: 'Privilege Withdrawn' },
    { value: 'aACompromise', label: 'AA Compromise' },
  ],
};

export const FILE_FORMATS = {
  PEM: 'pem',
  PKCS7: 'pkcs7',
  PKCS1: 'pkcs1',
  PKCS12: 'pkcs12',
  PKCS8: 'pkcs8',
};

export const CERTIFICATE_FORMATS = [
  { value: FILE_FORMATS.PEM, label: 'X.509 (PEM)' },
  { value: FILE_FORMATS.PKCS7, label: `${FILE_FORMATS.PKCS7.toUpperCase()} (PEM)` },
];

export const PRIVATE_KEY_FORMATS = [{ value: FILE_FORMATS.PKCS1, label: `${FILE_FORMATS.PKCS1.toUpperCase()} (PEM)` }];
