import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { LabelService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, CREATE_SUCCESS, SAVE_CHANGES_LABEL, SCOPE, UPDATE_SUCCESS } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';

const FORM_PARAMS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  COLOR: 'color',
  PROJECT_ID: 'project_id',
  ORGANIZATION_ID: 'organization_id',
  SCOPE: 'scope',
};

@Component({
  selector: 'app-label-form',
  templateUrl: './label-form.component.html',
  styleUrls: ['./label-form.component.scss'],
})
export class LabelFormComponent extends BaseComponent implements AfterViewInit {
  label: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public labelSrv: LabelService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.label = this.dialogConfig?.data?.label;
    this.initForm();
    if (!!this.label) {
      this.form?.patchValue(this.label);
    }
  }

  /**
   * Init the form
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: 'Name of label',
        defaultValue: '',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'input',
        required: true,
        fieldInfo: 'Description of label',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COLOR,
        label: 'Color',
        field: 'color',
        required: true,
        fieldInfo: 'Pick a color to display for the label',
        defaultValue: '#ffffff',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SCOPE,
        hidden: true,
        defaultValue: this.currentScope,
      } as FormItem),
    ];
  }

  /**
   * Submit the form
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = { ...this.form?.getRawValue() };
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        payload[FORM_PARAMS.ORGANIZATION_ID] = this.breadcrumbConfig?.organizationId;
        break;
      }
      case SCOPE.PROJECT: {
        payload[FORM_PARAMS.ORGANIZATION_ID] = this.breadcrumbConfig?.organizationId;
        payload[FORM_PARAMS.PROJECT_ID] = this.breadcrumbConfig?.projectId;
        break;
      }
      default:
        break;
    }
    const request: Observable<any> = !this.label ? this.labelSrv.createLabel(payload) : this.labelSrv.updateLabel(this.label.id, payload);
    request.subscribe({
      next: () => {
        const message = !this.label ? CREATE_SUCCESS : UPDATE_SUCCESS;
        this.showSuccessMessage(message.replace('{0}', 'label'));
        closeDialog();
      },
      error: (err: any) => {
        this.form.showServerErrorMessage(err);
        this.showErrorMessage(err);
      },
    });
  }
}
