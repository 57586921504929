<div class="grid">
  <!-- Table Toolbar: Button? Search, Filter, Clear -->
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!hasPermission ? leftTemplate : null"></microsec-common-toolbar>
    <ng-template #leftTemplate>
      <p-button severity="primary" [rounded]="true" [label]="'Assign User to ' + moduleName" icon="pi pi-plus" (onClick)="openUserAccessForm()">
      </p-button>
    </ng-template>
  </div>

  <!-- Data table -->
  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [selectable]="false"
        itemName="User"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getUsers()"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    @case ('role') {
      <div class="form dark-form no-wrapper">
        @if (!rowData.is_superuser) {
          <p-dropdown
            [options]="roles"
            optionLabel="label"
            optionValue="value"
            appendTo="body"
            [(ngModel)]="rowData.roleId"
            [disabled]="currentScope === 'project' && !!rowData.roles?.organization?.id"></p-dropdown>
        } @else {
          <microsec-truncate-text> System Admin </microsec-truncate-text>
        }
      </div>
    }
    @default {
      <microsec-truncate-text> {{ rowData[col.field] || '-' }}</microsec-truncate-text>
    }
  }
</ng-template>
