import { Component, Input } from '@angular/core';
import { BaseComponent } from '@lcms-components';

@Component({
  selector: 'app-event-overview',
  templateUrl: './event-overview.component.html',
  styleUrls: ['./event-overview.component.scss'],
})
export class EventOverviewComponent extends BaseComponent {
  _event = null;

  get event() {
    return this._event;
  }

  @Input() set event(value: any) {
    this._event = value;
  }
}
