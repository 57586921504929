import { PKI_MANAGEMENT_FEATURES } from './feature';

export const DEVICE_TYPE = {
  X509: 'X.509',
  MICRO_PKI: 'MicroPKI',
};

export const DEVICE_STATUSES = {
  NOT_ENROLLED: 'Not Enrolled',
  PENDING: 'Pending',
  ENROLLED: 'Enrolled',
  BLOCKED: 'Blocked',
  EXPIRING: 'Expiring',
  EXPIRED: 'Expired',
  RETIRED: 'Retired',
  VIRTUAL: 'Virtual',
};

export const DEVICE_STATUS_TAGS = [
  {
    value: DEVICE_STATUSES.NOT_ENROLLED,
    label: DEVICE_STATUSES.NOT_ENROLLED,
    color: '#888888',
  },
  {
    value: 'Pending Enrollment',
    label: DEVICE_STATUSES.PENDING,
    color: '#ffa800',
  },
  {
    value: DEVICE_STATUSES.ENROLLED,
    label: DEVICE_STATUSES.ENROLLED,
    color: '#19b775',
  },
  {
    value: DEVICE_STATUSES.BLOCKED,
    label: DEVICE_STATUSES.BLOCKED,
    color: '#c0292a',
  },
  {
    value: DEVICE_STATUSES.RETIRED,
    label: DEVICE_STATUSES.RETIRED,
    color: '#888888',
  },
  {
    value: DEVICE_STATUSES.VIRTUAL,
    label: DEVICE_STATUSES.VIRTUAL,
    color: '#00cde8',
  },
];

export const DEVICE_CATEGORY_VALUES = {
  USES_AGENT: 'uses_agent',
  BACK_IMPORTED: 'back_imported',
  IS_MANUAL: 'is_manual',
};

export const DEVICE_CATEGORIES = [
  { value: DEVICE_CATEGORY_VALUES.USES_AGENT, label: 'Uses agent' },
  { value: DEVICE_CATEGORY_VALUES.BACK_IMPORTED, label: 'Back-imported' },
  { value: DEVICE_CATEGORY_VALUES.IS_MANUAL, label: 'Manual' },
];

// export const MICROPKI_DEVICE_STATUS_CONFIGS = [
//   // Gateway received something from device
//   {
//     label: 'Initialized',
//     value: 'warning_init',
//     color: '#ffa800',
//     tooltip: 'The gateway has received something from the device',
//   },
//   // Established session with commissioning cert
//   {
//     label: 'Session Established',
//     value: 'good_init',
//     color: '#ffa800',
//     tooltip: 'Established session with commissioning certificate',
//   },
//   // ECDH done, fetching cert for device
//   {
//     label: 'Precommissioned',
//     value: 'warning_precommissioned',
//     color: '#ffa800',
//     tooltip: 'ECDH completed, Fetching certificate for device',
//   },
//   // Device commissioned successfully
//   {
//     label: 'Commissioned',
//     value: 'good_commissioned',
//     color: '#19b775',
//     tooltip: 'Device commissioned successfully',
//   },
//   // Service in initial state
//   {
//     label: 'Initialized',
//     value: 'init_pkisetup',
//     color: '#ffa800',
//     tooltip: 'Service is in initial state',
//   },
//   // Cert received from cloud
//   {
//     label: 'Cert Received',
//     value: 'good_pkisetup',
//     color: '#19b775',
//     tooltip: 'Certificate received from server',
//   },
//   {
//     label: 'Blocking',
//     value: 'blocking',
//     color: '#ffa800',
//   },
//   {
//     label: 'Blocked',
//     value: 'blocked',
//     color: '#c0292a',
//   },
//   {
//     label: 'Unblocking',
//     value: 'unblocking',
//     color: '#ffa800',
//   },
//   {
//     label: 'Retiring',
//     value: 'retiring',
//     color: '#ffa800',
//   },
//   {
//     label: 'Retired',
//     value: 'retired',
//     color: '#888888',
//   },
//   {
//     label: 'Updating',
//     value: 'updating',
//     color: '#ffa800',
//   },
// ];

export const DEVICE_PROTOCOL_VALUES = {
  EST_AGENT: 'est_agent',
  ACMEV2: 'acmev2',
  SCEP: 'scep',
};

export const DEVICE_PROTOCOLS = [
  {
    value: DEVICE_PROTOCOL_VALUES.EST_AGENT,
    label: 'EST',
    feature: PKI_MANAGEMENT_FEATURES.PROTOCOL_EST,
  },
  {
    value: DEVICE_PROTOCOL_VALUES.ACMEV2,
    label: 'ACMEv2',
    feature: PKI_MANAGEMENT_FEATURES.PROTOCOL_ACMEV2,
  },
  {
    value: DEVICE_PROTOCOL_VALUES.SCEP,
    label: 'SCEP',
    feature: PKI_MANAGEMENT_FEATURES.PROTOCOL_SCEP,
  },
];
