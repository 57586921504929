/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Util } from '@microsec/utilities';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import { IFirmwareService } from './IFirmwareService';
import firmwares from './mock-data/firmwares.json';
import supportedDevices from './mock-data/supported-devices.json';

@Injectable({
  providedIn: 'root',
})
export class MockFirmwareService implements IFirmwareService {
  getFirmwares(queryField?: any, queryValue?: any) {
    if (queryField !== 'project_id') {
      return scheduled([firmwares[0]], asyncScheduler);
    }
    return scheduled([firmwares], asyncScheduler);
  }

  getFirmware(id: any) {
    const result = Util.cloneDeepObject((firmwares as any[])?.find((p) => p.id === id));
    result.assigned_devices = (result.assigned_devices as any[]).map((device: any) => {
      device.status = 'uptodate';
      return device;
    });
    return scheduled([result], asyncScheduler);
  }

  uploadFirmware(payload: any) {
    return scheduled([{}], asyncScheduler);
  }

  updateFirmware(id: any, payload: any) {
    return scheduled([{}], asyncScheduler);
  }

  deleteFirmware(id: any, organizationId: any) {
    return scheduled([{}], asyncScheduler);
  }

  downloadFirmware(id: any) {
    return scheduled([null], asyncScheduler);
  }

  pushHikVisionCCTVDevice(id: any, payload: any) {
    return scheduled([{}], asyncScheduler);
  }

  getSupportedDevices(): Observable<any> {
    return scheduled([supportedDevices], asyncScheduler);
  }
}
