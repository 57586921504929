/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import { IPackageService } from './IPackageService';
import devicesByPackage from './mock-data/devices-by-package.json';
import packageHistory from './mock-data/package-history.json';
import packagesByDevice from './mock-data/packages-by-device.json';
import packages from './mock-data/packages.json';
import vulnerabilities from './mock-data/vulnerabilities.json';
import vulnerabilitySetting from './mock-data/vulnerability-setting.json';

@Injectable({
  providedIn: 'root',
})
export class MockPackageService implements IPackageService {
  getPackages(projectId: any): Observable<any> {
    return scheduled([packages], asyncScheduler);
  }

  postPackageOperation(projectId: any, payload: any): Observable<any> {
    return scheduled(
      [
        {
          devices: [
            {
              device: 'device_1',
              request_id: '12345',
              status_code: 200,
              message: 'Submitted, but agent has not responded. Check later again',
            },
            {
              device: 'device_2',
              request_id: '12345',
              status_code: 201,
              message: 'Success',
            },
            {
              device: 'device_5',
              request_id: '12345',
              status_code: 500,
              message: 'Agent did not import. Try again',
            },
          ],
        },
      ],
      asyncScheduler,
    );
  }

  getDevicesByPackage(projectId: any, packageName: any): Observable<any> {
    return scheduled([devicesByPackage], asyncScheduler);
  }

  getDevicePackages(deviceId: any): Observable<any> {
    return scheduled([packagesByDevice], asyncScheduler);
  }

  postDevicePackageOperation(deviceId: any, payload: any): Observable<any> {
    return scheduled(
      [
        {
          request_id: '1234',
          message: 'Success',
          status_code: 200,
        },
      ],
      asyncScheduler,
    );
  }

  getDevicePackagesHistory(deviceId: any): Observable<any> {
    return scheduled([packageHistory], asyncScheduler);
  }

  getVulnerabilitiesSettings(projectId: any): Observable<any> {
    return scheduled([vulnerabilitySetting], asyncScheduler);
  }

  updateVulnerabilitiesSettings(projectId: any, payload: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  syncVulnerabilities(projectId: any): Observable<any> {
    return scheduled([null], asyncScheduler);
  }

  getVulnerabilities(
    projectId: any,
    packageName?: string | null,
    deviceId?: number | null,
    urgency?: string | null,
    status?: string | null,
  ): Observable<any> {
    return scheduled([vulnerabilities], asyncScheduler);
  }
}
