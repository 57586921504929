import { Component, Input, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { GRANULARITY_DAY, GRANULARITY_HOUR, GRANULARITY_MINUTE, GRANULARITY_MONTH } from '@lcms-constants';
import { DeviceService } from '@lcms-services';
import { CommonChartComponent } from '@microsec/components';
import { BaseComponent } from '@lcms-components';
import { CommonChart } from '@microsec/models';
import { PRIMENG_CALENDAR_DATE } from '@microsec/constants';
import { Chart, ChartData, ChartDataset, ChartOptions } from 'chart.js';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { COMMON_LAYOUT_OPTIONS, DIAGRAMS, GRANULARITY_REALTIME_OPTIONS, ZOOM_OPTIONS } from './shared-metric.config';

@Component({
  selector: 'app-shared-metric',
  templateUrl: './shared-metric.component.html',
  styleUrls: ['./shared-metric.component.scss'],
})
export class SharedMetricComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  /**
   * Selected device
   */
  _selectedDevice: any = null;

  @Input() set selectedDevice(value: any) {
    this._selectedDevice = value;
    this.validCert = this._selectedDevice?.certs?.find((cert: any) => cert.status === 'Valid');
    setTimeout(() => {
      this.initData();
      if (!!this.selectedDevice) {
        this.getDeviceLastSeenLog();
      }
    });
    if (!!this.config.realtime) {
      this.config.realtime = false;
      this.toggleRealTime({ checked: false });
    }
  }

  get selectedDevice() {
    return this._selectedDevice;
  }

  @Input() lastLog$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  validCert: any = null;

  /**
   * Toolbar values
   */
  config: any = {
    granularity: null,
    zoom: null,
    from: null,
    to: null,
    minDate: null,
    maxDate: null,
    realtime: false,
    realtimeInterval: null,
    lastRefresh: null,
  };

  /**
   * List of raw logs from server based on device last_seen
   */
  deviceLastSeenLogs: any[] = [];

  /**
   * List of raw logs from server
   */
  logs: any[] = [];

  /**
   * List of displayed charts with configurations
   */
  charts: CommonChart[] = [];

  /**
   * List of charts from UI
   */
  uiCharts: Chart[] = [];

  /**
   * Granularity options
   */
  granularityOptions: any[] = this.util.cloneObjectArray(GRANULARITY_REALTIME_OPTIONS);

  /**
   * Zoom options
   */
  zoomOptions: any[] = this.util.cloneObjectArray(ZOOM_OPTIONS);

  /**
   * The list of colors for Network Usage, avoid the replacement after refreshing
   */
  networkColors: any = {};

  DIAGRAMS = DIAGRAMS;

  /**
   * Diagram UIs
   */
  @ViewChildren('diagram') diagrams!: CommonChartComponent[];

  PRIMENG_CALENDAR_DATE = PRIMENG_CALENDAR_DATE;

  constructor(private deviceSrv: DeviceService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.initFilter();
    if (!this.selectedDevice) {
      this.selectedDevice = null;
    }
  }

  override ngOnDestroy(): void {
    this.unsubscribeMQTTRequest(false);
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   * Initialize filter options
   */
  initFilter() {
    this.config.to = new Date();
    this.config.from = new Date();
    (this.config.from as Date).setHours((this.config.from as Date).getHours() - 1);
    this.updateGranularity();
  }

  /**
   * Get device last seen log
   */
  getDeviceLastSeenLog() {
    const lastSeen = moment(this.selectedDevice.last_seen).subtract(1, 'minute').toISOString();
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.isLoading = true;
    const subscription = this.deviceSrv
      .getDeviceLog({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        deviceId: this.selectedDevice.id,
        fromDate: lastSeen,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.deviceLastSeenLogs = this.filterDateTime(rs.data as any[]);
          // BEGIN - Push the last log to Crypto Assets
          if (!!this.deviceLastSeenLogs.length) {
            this.lastLog$.next(this.lastLog);
          }
          // END
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Init the templates, data to show the charts
   * @param shouldInitFilter
   */
  initData(shouldInitFilter = false) {
    if (!!shouldInitFilter) {
      this.initFilter();
    }
    this.isLoading = true;
    this.deviceSrv
      .getDeviceLog({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        deviceId: this.selectedDevice?.id,
        fromDate: this.config.from,
        toDate: this.config.to,
        granularity: this.config.granularity,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.config.lastRefresh = moment();
          window.microsec.log('device logs: ', rs);
          this.logs = this.filterDateTime(rs.data as any[]);
          // BEGIN - Push the last log to Crypto Assets
          if (!!this.logs.length) {
            this.lastLog$.next(this.lastLog);
          }
          // END
          const charts = this.initTemplates();
          charts.forEach((chart: CommonChart) => {
            this.updateChartData(chart);
          });
          this.charts = charts;
          this.redrawDiagrams();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Redraw diagrams
   */
  redrawDiagrams() {
    if (!!this.diagrams) {
      this.diagrams.forEach((diagram) => {
        setTimeout(() => {
          diagram.redraw();
        });
      });
    }
  }

  /**
   * Build up the metric objects
   */
  private initTemplates() {
    const charts: CommonChart[] = [];
    Object.entries(DIAGRAMS).forEach(([key, value]: [string, any]) => {
      const telemetryCondition = !!this.selectedDevice;
      const metricCondition = !this.selectedDevice && key !== DIAGRAMS.NETWORK.KEY;
      if (!!telemetryCondition || !!metricCondition) {
        const chart = this.util.cloneDeepObject({
          type: 'line',
          key,
          label: this.getChartTitle(value.LABEL),
          data: {} as ChartData,
          options: this.getChartOptions(value),
          children: this.util.cloneObjectArray(value.CHILDREN),
        } as CommonChart);
        charts.push(chart);
      }
    });
    return charts;
  }

  /**
   * Generate the data X and Y values
   * @param chart chart
   */
  updateChartData(chart: CommonChart) {
    const data = chart.data as ChartData;
    // X values
    data.labels = this.generateLabel(chart);
    // Y values
    const dataSets = this.generateDataSets(chart);
    dataSets.forEach((dataSet: ChartDataset) => {
      dataSet.data = this.generateData(chart, dataSet);
    });
    data.datasets = dataSets;
  }

  /**
   * Generate the chart options
   * @param chartConstant
   * @returns
   */
  getChartOptions(chartConstant: any): ChartOptions {
    const options = this.util.cloneDeepObject(COMMON_LAYOUT_OPTIONS) as ChartOptions;
    if (!!options && !!options?.scales && !!options?.scales['x'] && !!options?.scales['y']) {
      (options.scales['x'] as any).title.text = chartConstant.X_LABEL;
      (options.scales['y'] as any).title.text = chartConstant.Y_LABEL;
      // if (!this.config.realtime) {
      (options.scales['x'].ticks as any).callback = (value: any, index: number) => {
        const labels = this.generateLabel(chartConstant);
        const label = moment(labels[index], 'L HH:mm:ss').format('HH:mm');
        return label;
      };
      // }
      switch (chartConstant.KEY) {
        case DIAGRAMS.CPU.KEY: {
          options.scales['y'].max = 100 as any;
          break;
        }
        case DIAGRAMS.RAM.KEY: {
          options.scales['y'].max = 100 as any;
          break;
        }
        // case DIAGRAMS.VPN.KEY: {
        //   if (!!options.plugins && !!options.plugins.legend) {
        //     options.plugins.legend.display = false;
        //   }
        //   (options.scales['y'] as any).ticks.callback = (value: any) => {
        //     if (Number.isInteger(value)) {
        //       if (!!value) {
        //         return 'Connected';
        //       }
        //       return 'Not Connected';
        //     }
        //     return null;
        //   };
        //   (options.scales['y'] as any).min = 0;
        //   (options.scales['y'] as any).max = 1;
        //   (options.scales['y'] as any).stepSize = 1;
        //   break;
        // }
        default: {
          break;
        }
      }
    }
    // if (!!this.config?.realtime && !!options?.scales && !!options?.scales?.x) {
    //   options.scales.x.type = 'realtime';
    //   (options.scales.x as any).realtime = {
    //     duration: this.config.zoom * 1000,
    //     refresh: this.config.granularity * 1000,
    //     delay: 1000,
    //   };
    // }
    return options;
  }

  /**
   * Get the X displayed values
   * @returns
   */
  private generateLabel(chartConstant: CommonChart) {
    const results: string[] = [];
    this.logs.forEach((log: any, index: number) => {
      if (
        chartConstant.key !== DIAGRAMS.NETWORK.KEY ||
        (chartConstant.key === DIAGRAMS.NETWORK.KEY && index !== 0) // Exception for Network
      ) {
        results.push(moment(!this.selectedDevice ? log.timestamp : log.datetime).format('L HH:mm:ss'));
      }
    });
    return results;
  }

  /**
   * Generate Y displayed values
   * @param chart chart
   * @param isColorKept should keep the colors if dynamic
   * @returns
   */
  private generateDataSets(chart: CommonChart): ChartDataset[] {
    switch (chart.key) {
      case DIAGRAMS.NETWORK.KEY: {
        if (!this.selectedDevice) {
          return (DIAGRAMS as any)[chart.key as string].CHILDREN.map((p: any) => this.createDataSet(p.LABEL, p.COLOR));
        }
        const results: ChartDataset[] = [];
        this.logs.forEach((log: any) => {
          ((log.interfaces as any[]) || []).forEach((interfaceItem: any) => {
            const interfaceItemName = Object.keys(interfaceItem)[0];
            if (interfaceItemName.indexOf('veth') === -1) {
              DIAGRAMS.NETWORK.CHILDREN.forEach((item: any) => {
                const label = `${interfaceItemName} ${item.LABEL}`;
                if (!results.find((p) => p.label === label)) {
                  const dynamicColor = this.getDynamicColor();
                  const hasExistedColor =
                    !!this.networkColors && this.networkColors[interfaceItemName] && !!this.networkColors[interfaceItemName][item.LABEL];
                  results.push(this.createDataSet(label, !!hasExistedColor ? this.networkColors[interfaceItemName][item.LABEL] : dynamicColor));
                  // Set color in the temporary array, only for network diagrams
                  if (!hasExistedColor) {
                    if (!this.networkColors[interfaceItemName]) {
                      this.networkColors[interfaceItemName] = {};
                    }
                    this.networkColors[interfaceItemName][item.LABEL] = dynamicColor;
                  }
                }
              });
            }
          });
        });
        return results;
      }
      default: {
        return (DIAGRAMS as any)[chart.key as string].CHILDREN.map((p: any) => this.createDataSet(p.LABEL, p.COLOR));
      }
    }
  }

  /**
   * Create the dataset
   * @param label
   * @param color
   * @returns
   */
  private createDataSet(label: string, color: string): ChartDataset {
    return {
      label,
      data: [],
      fill: false,
      borderColor: color,
      backgroundColor: color,
      borderWidth: 1,
    };
  }

  /**
   * Generate Y values
   * @param chart
   * @param dataSet
   * @returns
   */
  private generateData(chart: CommonChart, dataSet: ChartDataset): number[] {
    let results: number[] = [];
    switch (chart.key) {
      case DIAGRAMS.NETWORK.KEY: {
        if (!this.selectedDevice) {
          this.logs.forEach(() => {
            results.push(0);
          });
        } else {
          results = this.calculateNetworkValues(dataSet);
        }
        break;
      }
      case DIAGRAMS.CPU.KEY: {
        results = this.logs.map((log: any) => {
          if (!this.selectedDevice) {
            return log.cpu;
          }
          const utilizations = log.cpu?.current_utilization;
          return !!utilizations && utilizations?.length > 0 ? (utilizations as number[]).reduce((sum, a) => sum + a, 0) / utilizations?.length : 0;
        });
        break;
      }
      case DIAGRAMS.RAM.KEY: {
        results = this.logs.map((log: any) => {
          if (!this.selectedDevice) {
            return log.ram;
          }
          return log.mem?.current_utilization;
        });
        break;
      }
      // case DIAGRAMS.FILE.KEY: {
      // results = (chart.data.labels as string[]).map((p) => this.util
      //   .generateRandomNumber(0, chart.data.labels.length));
      // break;
      // }
      // case DIAGRAMS.VPN.KEY: {
      //   results = this.logs.map((log: any) => {
      //     if (!this.selectedDevice) {
      //       return 0;
      //     }
      //     let vpnString: string = log.vpn;
      //     // eslint-disable-next-line no-useless-escape
      //     vpnString = vpnString.replace(/\"/g, '').toLowerCase();
      //     return (vpnString === 'up') ? 1 : 0;
      //   });
      //   break;
      // }
      // case DIAGRAMS.PROCESSES.KEY: {
      // results = (chart.data.labels as string[]).map((p) => this.util
      //   .generateRandomNumber(0, chart.data.labels.length));
      //   break;
      // }
      // case DIAGRAMS.AUTHENTICATIONS.KEY: {
      //   results = (chart.data.labels as string[]).map((p) => this.util
      //     .generateRandomNumber(0, chart.data.labels.length));
      //   break;
      // }
      default: {
        break;
      }
    }
    return results;
  }

  /**
   * Calculate the network values
   * @param dataSet
   * @returns
   */
  private calculateNetworkValues(dataSet: ChartDataset) {
    const results: number[] = [];
    const labelArr = (dataSet.label as string).split(' ');
    const nwkInterface = labelArr[0];
    const nwkProperty = labelArr.filter((p, index) => !!index).join(' ');
    const filterLogs = this.logs;
    filterLogs.forEach((log: any, index: number) => {
      if (index !== 0) {
        const key = DIAGRAMS.NETWORK.CHILDREN.find((p: any) => p.LABEL === nwkProperty)?.KEY as string;

        // --- Current value
        const currentNetwork: any = (log.interfaces as any[]).find((p) => !!p?.[nwkInterface]);
        const currentValue: any = !!currentNetwork ? currentNetwork?.[nwkInterface]?.[0]?.info?.[key] : 0;

        // --- Previous value
        const previousNetwork: any = (filterLogs[index - 1].interfaces as any[]).find((p) => !!p?.[nwkInterface]);
        const previousValue: any = !!previousNetwork ? previousNetwork?.[nwkInterface]?.[0]?.info?.[key] : 0;

        results.push(currentValue - previousValue);
      }
    });
    return results;
  }

  /** ******************************************************************************
   * *********************************** TOOL BAR ***********************************
   ******************************************************************************** */

  /**
   * Event handler after From/To Date is changed
   * @param event
   */
  changeDateTime() {
    // Only limit up to 1 month
    this.updateGranularity();
    setTimeout(() => {
      this.initData();
    });
  }

  /**
   * Update granularity options
   */
  updateGranularity() {
    const fromMoment = moment(this.config.from);
    const toMoment = moment(this.config.to);
    const duration = moment.duration(toMoment.diff(fromMoment));
    const seconds = duration.asSeconds();
    if (seconds > 2630000) {
      // > 1 month
      this.showInfoMessage('The Datetime gap is too big');
      this.granularityOptions = this.util.cloneObjectArray(GRANULARITY_MONTH);
    } else if (seconds > 86400) {
      this.granularityOptions = this.util.cloneObjectArray(GRANULARITY_MONTH);
    } else if (seconds > 3600) {
      this.granularityOptions = this.util.cloneObjectArray(GRANULARITY_DAY);
    } else if (seconds > 60) {
      this.granularityOptions = this.util.cloneObjectArray(GRANULARITY_HOUR);
    } else {
      this.granularityOptions = this.util.cloneObjectArray(GRANULARITY_MINUTE);
    }
    this.config.granularity = this.granularityOptions[0].value;
  }

  /**
   * Event handler after changing Granularity
   * @param event
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changeGranularity(event: any) {
    if (!this.config.realtime) {
      this.initData();
    } else {
      this.deviceSrv.clearDeviceInfoInterval(this.config.realtimeInterval);
      this.toggleRealTime({ checked: true }, false);
    }
  }

  /**
   * Event handler after changing Zoom
   * @param event
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changeZoom(event: any) {
    this.deviceSrv.clearDeviceInfoInterval(this.config.realtimeInterval);
    this.toggleRealTime({ checked: true }, false);
  }

  /** ******************************************************************************
   * *********************************** REALTIME ***********************************
   ******************************************************************************** */

  /**
   * Toggle the realtime button
   * @param event
   */
  toggleRealTime(event: any, isResetConfig = true) {
    if (!!event.checked) {
      if (!!isResetConfig) {
        this.granularityOptions = this.util.cloneObjectArray(GRANULARITY_REALTIME_OPTIONS);
        this.config.granularity = this.granularityOptions[0].value;
        this.config.zoom = this.zoomOptions[0].value;
      }
      this.logs = [];
      this.charts = [];
      if ((!!this.selectedDevice && !!this.validCert) || !this.selectedDevice) {
        const country = this.validCert?.subject?.C;
        const org = this.validCert?.subject?.O;
        const orgUnit = this.validCert?.subject?.OU;

        const config = {
          country: country,
          org: org,
          orgUnit: orgUnit,
          deviceName: this.selectedDevice?.common_name,
          granularity: this.config?.granularity,
        };
        // Send MQTT requests
        this.sendMQTTRequests();
        // Subscribe the MQTT response
        this.deviceSrv.startDeviceInfoMQTTSubscription(config).subscribe({
          next: (log: any) => {
            this.logs.push(log);
            this.logs = this.filterDateTime(this.logs);

            const charts = this.initTemplates();
            charts.forEach((chart: CommonChart) => {
              this.updateChartData(chart);
              chart.data = this.util.cloneDeepObject(chart.data);
            });
            this.charts = charts;

            this.redrawDiagrams();
          },
          error: (err) => {
            this.showErrorMessage(err);
          },
        });
      } else {
        this.showErrorMessage('There is no valid certificate to run the realtime.');
      }
    } else {
      this.unsubscribeMQTTRequest();
      this.logs = [];
      this.charts = [];
      this.changeDateTime();
      this.initData(true);
    }
  }

  /**
   * Send MQTT requests to update the chart for realtime data
   */
  sendMQTTRequests() {
    this.config.realtimeInterval = setInterval(
      () => {
        const country = this.validCert?.subject?.C;
        const org = this.validCert?.subject?.O;
        const orgUnit = this.validCert?.subject?.OU;
        const config = {
          country: country,
          org: org,
          orgUnit: orgUnit,
          deviceName: this.selectedDevice?.common_name,
        };
        this.deviceSrv.sendDeviceInfoMQTTRequest(config);
      },
      (this.config.granularity as number) * 1000,
    );
  }

  /**
   * Clear interval + unsubscribe mqtt
   */
  unsubscribeMQTTRequest(shouldRefreshData = true) {
    this.deviceSrv.unsubscribeDeviceInfoMQTTRequest(this.config.realtimeInterval);
    if (!!shouldRefreshData) {
      this.initData();
    }
  }

  /** ******************************************************************************
   * ******************************* USEFUL FUNCTIONS *******************************
   ******************************************************************************** */

  /**
   * Filter the logs with the chosen From/To dates
   * @param logs
   * @returns
   */
  private filterDateTime(logs: any[]) {
    let results: any[] = this.util.cloneObjectArray(logs);
    const timestampField = !this.selectedDevice ? 'timestamp' : 'datetime';
    results = this.util.sortObjectArray(results, timestampField, true, true);
    if (!!this.config.realtime && !!results.length) {
      let lastLogTimestamp = moment(results[results.length - 1][timestampField]);
      lastLogTimestamp = lastLogTimestamp.add(-this.config.zoom, 'second');
      const rs: any[] = [];
      results.forEach((item) => {
        if (!rs.find((r) => r[timestampField] === item[timestampField]) && moment(item[timestampField]).isSameOrAfter(lastLogTimestamp)) {
          rs.push(item);
        }
      });
      results = rs;
    }
    return results;
  }

  /**
   * Generate the label in HTML for a page/ a part of a page
   * @param label
   * @returns
   */
  getChartTitle(label?: string) {
    if (!this.selectedDevice) {
      return label;
    }
    return (label as string).replace('Average ', '').replace('Overall ', '');
  }

  /**
   * Generate dynamic colors
   * @returns
   */
  private getDynamicColor() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgb(${r},${g},${b})`;
  }

  /**
   * Get the last item from the log array
   */
  get lastLog(): any {
    if (!!this.logs.length) {
      return this.logs[this.logs.length - 1];
    }
    if (!!this.deviceLastSeenLogs.length) {
      return this.deviceLastSeenLogs[this.deviceLastSeenLogs.length - 1];
    }
    return null;
  }
}
