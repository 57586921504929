import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { PackageService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  NAME: 'name',
  LATEST_VERSION: 'latest_version',
};

@Component({
  selector: 'app-package-overview',
  templateUrl: './package-overview.component.html',
  styleUrls: ['./package-overview.component.scss'],
})
export class PackageOverviewComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  isLoading = false;

  faExclamationCircle = faExclamationCircle;

  _selectedPackage: any = null;

  get selectedPackage() {
    return this._selectedPackage;
  }

  @Input() set selectedPackage(value: any) {
    this._selectedPackage = value;
    this.initForm();
    this.getPackageDevices();
  }

  cols = [
    { field: 'id', header: 'S/N', width: 5, frozen: true },
    { field: 'name', header: 'Device', width: 10 },
    { field: 'operating_system', header: 'OS', width: 10 },
    { field: 'architecture', header: 'Architecture', width: 10 },
    { field: 'version', header: 'Version', width: 10 },
  ];

  devices: any[] = [];

  constructor(private packageSrv: PackageService) {
    super();
  }

  ngAfterViewInit() {
    this.initForm();
  }

  /**
   * Init the form
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'label',
        defaultValue: this.selectedPackage.name,
      } as FormItem),
    ];
  }

  /**
   * Get the devices by packages
   */
  async getPackageDevices() {
    await this.prepareConfigs();
    this.devices = [];
    this.isLoading = true;
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.packageSrv
      .getDevicesByPackage(this.breadcrumbConfig?.projectId, this.selectedPackage.name)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const devices = rs?.devices as any[];
          if (!!devices) {
            this.devices = devices;
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }
}
