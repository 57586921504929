<div class="grid">
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN] ? leftTemplate : null">
      <ng-template #leftTemplate>
        <p-button severity="primary" [rounded]="true" label="Create Label" icon="pi pi-plus" (onClick)="openForm()" />
      </ng-template>
    </microsec-common-toolbar>
  </div>
  <label class="col-12 text-data font-italic text-sm"> Maintain a list of labels that can be assigned to devices for grouping purposes </label>

  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [selectable]="false"
        itemName="Label"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getLabels()" />
    <microsec-table-column-filter [key]="constructor.name" [cols]="cols" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Scope -->
    @case ('scope') {
      {{ util.getUppercaseFirstLetter(rowData[col.field]) }}
    }
    <!-- Color -->
    @case ('color') {
      <span [pTooltip]="rowData[col.field]" tooltipPosition="bottom">
        <i class="fas fa-square-full mr-2" [ngStyle]="{ color: rowData[col.field] }"></i>
      </span>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
