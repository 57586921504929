import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { RuleService } from '@lcms-services';
import { FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, CREATE_SUCCESS, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

const FORM_PARAMS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  RULESET_TYPE: 'ruleset_type',
  ENABLED: 'enabled',
  PROJECT_ID: 'project_id',
};

@Component({
  selector: 'app-ruleset-form',
  templateUrl: './ruleset-form.component.html',
  styleUrls: ['./ruleset-form.component.scss'],
})
export class RulesetFormComponent extends BaseComponent implements AfterViewInit {
  type = '';

  ruleset: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ruleSrv: RuleService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.type = this.dialogConfig.data?.type;
    this.ruleset = this.dialogConfig.data?.ruleset;
    this.initForm();
    if (!!this.ruleset) {
      this.form.patchValue(this.ruleset);
    }
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        fieldInfo: 'Name',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'input',
        fieldInfo: 'Description',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENABLED,
        label: 'Enabled',
        field: 'checkbox',
        fieldInfo: 'Is Enabled',
        defaultValue: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULESET_TYPE,
        defaultValue: this.type,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        defaultValue: this.breadcrumbConfig?.projectId,
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;
  }

  /**
   *
   * @param closeDialog
   */
  submit(closeDialog: (result: any) => void) {
    const payload = this.form?.getRawValue();
    const request = !this.ruleset ? this.ruleSrv.createRuleset(payload) : this.ruleSrv.updateRuleset(this.ruleset?.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const message = CREATE_SUCCESS.replace('{0}', `${this.type} Rule`);
          this.showSuccessMessage(message);
          closeDialog(rs);
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }
}
