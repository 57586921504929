import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, scheduled, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API } from '../api';
import { IPackageService } from './IPackageService';

const API_PROJECT_PACKAGES = `${API.PACKAGE_MANAGER}/project/{0}`;
const API_VULNERABILITIES = `${API.PACKAGE_MANAGER}/vulnerabilities`;
const API_DEVICE = `${API.PACKAGE_MANAGER}/device/{0}`;

@Injectable({
  providedIn: 'root',
})
export class PackageService implements IPackageService {
  constructor(private http: HttpClient) {}

  getPackages(projectId: any): Observable<any> {
    return this.http
      .get<any>(`${API_PROJECT_PACKAGES.replace('{0}', projectId)}/packages`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  postPackageOperation(projectId: any, payload: any): Observable<any> {
    const headers = new HttpHeaders({
      timeout: (20 * 1000).toString(),
    });
    const devices: any[] = payload?.devices || [];
    return this.http.post<any>(`${API.PACKAGE_MANAGER}/project/${projectId}/package-operation`, payload, { headers }).pipe(
      catchError((error: HttpErrorResponse) =>
        scheduled(
          [
            {
              devices: devices.map((p) => ({
                device: `Device-id-${p}`,
                request_id: null,
                status_code: 500,
                message: error?.error?.message || error?.message || 'Sever issue',
              })),
            },
          ],
          asyncScheduler,
        ),
      ),
    );
  }

  getDevicesByPackage(projectId: any, packageName: any): Observable<any> {
    return this.http
      .get<any>(`${API_PROJECT_PACKAGES.replace('{0}', projectId)}/package/${packageName}/devices`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDevicePackages(deviceId: any): Observable<any> {
    return this.http
      .get<any>(`${API_DEVICE.replace('{0}', deviceId)}/package_list`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  postDevicePackageOperation(deviceId: any, payload: any): Observable<any> {
    const headers = new HttpHeaders({
      timeout: (20 * 1000).toString(),
    });
    return this.http.post<any>(`${API.PACKAGE_MANAGER}/device/${deviceId}/package-operation`, payload, { headers }).pipe(
      catchError((error: HttpErrorResponse) =>
        scheduled(
          [
            {
              request_id: null,
              message: error?.error?.message || error?.message || 'Sever issue',
              status_code: 500,
            },
          ],
          asyncScheduler,
        ),
      ),
    );
  }

  getDevicePackagesHistory(deviceId: any): Observable<any> {
    return this.http
      .get<any>(`${API_DEVICE.replace('{0}', deviceId)}/package_history`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getVulnerabilitiesSettings(projectId: any): Observable<any> {
    return this.http
      .get<any>(`${API_VULNERABILITIES}/projects/${projectId}/settings`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateVulnerabilitiesSettings(projectId: any, payload: any): Observable<any> {
    return this.http
      .put<any>(`${API_VULNERABILITIES}/projects/${projectId}/settings`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  syncVulnerabilities(projectId: any): Observable<any> {
    return this.http
      .post<any>(`${API_VULNERABILITIES}/projects/${projectId}/sync`, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getVulnerabilities(
    projectId: any,
    affectedPackage?: string | null,
    deviceId?: number | null,
    urgency?: string | null,
    status?: string | null,
  ): Observable<any> {
    let params = `?project_id=${projectId}`;
    if (!!affectedPackage) {
      params += `&affected_package=${affectedPackage}`;
    }
    if (!!deviceId) {
      params += `&device_id=${deviceId}`;
    }
    if (!!urgency) {
      params += `&urgency=${urgency}`;
    }
    if (!!status) {
      params += `&status=${status}`;
    }
    return this.http.get<any>(`${API_VULNERABILITIES}${params}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
