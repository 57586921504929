<microsec-form-builder #fb [shouldShowActionButtons]="true" [fields]="fields" [submitButtonLabel]="CREATE_LABEL" (submitEvent)="onSubmit($event)" />

<ng-template #neverExpireCheckboxTemplate>
  @if (!!form && !!form.form) {
    <p-checkbox [binary]="true" label="Never" [formControl]="form.getControl(FORM_PARAMS.NEVER_EXPIRED)" />
  }
</ng-template>

<ng-template #noPassphraseTemplate>
  @if (!!form && !!form.form) {
    <p-checkbox [binary]="true" label="None" [formControl]="form.getControl(FORM_PARAMS.NO_PASSPHRASE)" />
  }
</ng-template>
