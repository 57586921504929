import { DcaCreateImportKeyFormComponent } from './dca-create-import-key-form/dca-create-import-key-form.component';
import { DELETE_LABEL, PER_PAGE, PIPE_DATETIME } from '@microsec/constants';
import { Component, OnInit } from '@angular/core';
import {
  CRYPTO_ASSET_FIELD_VALUES,
  CryptoAssetField,
  CRYPTO_ASSET_CONFIG_VALUES,
  DESTINATION_TYPE_VALUES,
  ALL_KEY_ALGORITHM_OPTIONS,
  ALL_KEY_TYPE_OPTIONS,
} from './device-crypto-assets.config';
import { BaseComponent } from '@lcms-components';
import { CommonToolbarConfiguration, CommonToolbarResult, ConfirmationDialogConfig } from '@microsec/models';
import { BehaviorSubject, Observable, finalize } from 'rxjs';
import { devicesSelectors } from '@ngrx-devices';
import { PaginatorState } from 'primeng/paginator';
import { DeviceService } from '@lcms-services';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DcaImportCaCertificateFormComponent } from './dca-import-ca-certificate-form/dca-import-ca-certificate-form.component';
import { DcaCreateImportClientCertificateFormComponent } from './dca-create-import-client-certificate-form/dca-create-import-client-certificate-form.component';
import { KEYRING_TYPES } from '@lcms-constants';

@Component({
  selector: 'app-device-crypto-assets',
  templateUrl: './device-crypto-assets.component.html',
  styleUrls: ['./device-crypto-assets.component.scss'],
})
export class DeviceCryptoAssetsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  isExpanded = false;

  fields: { label: string; value: string }[] = [
    CRYPTO_ASSET_FIELD_VALUES.CRYPTOKEN_INFO,
    CRYPTO_ASSET_FIELD_VALUES.CRYPTO_KEYS,
    CRYPTO_ASSET_FIELD_VALUES.CLIENT_CERTIFICATE,
    CRYPTO_ASSET_FIELD_VALUES.CA_CERTIFICATE,
  ];

  _selectedDevice: any = null;

  get selectedDevice() {
    return this._selectedDevice;
  }

  set selectedDevice(value: any) {
    this._selectedDevice = value;
    this.getCryptoAssetInfo(true);
  }

  _selectedField = CRYPTO_ASSET_FIELD_VALUES.CRYPTOKEN_INFO.value;

  get selectedField() {
    return this._selectedField;
  }

  set selectedField(value: any) {
    this.setFilterConfiguration(value);
    this._selectedField = value;
  }

  values: any[] = [];

  //----------------- Filter/Search -----------------------

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    hideResetSortOption: true,
    showTotalRecords: false,
    searchPlaceholder: 'Search label...',
    filters: {},
  };

  filterSearch = '';

  filter: any = {};

  //----------------- Pagination -----------------------
  page = 1;

  rows = PER_PAGE;

  totalRecords = 0;

  //----------------- Constants -----------------------

  CryptoAssetField = CryptoAssetField;

  CRYPTO_ASSET_CONFIG_VALUES = this.util.cloneDeepObject(CRYPTO_ASSET_CONFIG_VALUES);

  PIPE_DATETIME = PIPE_DATETIME;

  constructor(private deviceSrv: DeviceService) {
    super();
  }

  ngOnInit() {
    this.store.select(devicesSelectors.selectedDevice).subscribe((selectedDevice) => {
      if (!!selectedDevice) {
        this.selectedDevice = selectedDevice;
      }
    });
    this.filterObjectObs.subscribe((filterObject) => {
      if (!!filterObject) {
        this.filterSearch = filterObject.search ?? '';
        if (!!filterObject.isFiltered) {
          this.filter = filterObject.filter;
        } else {
          this.filter = {};
        }
      }
      this.getCryptoAssetInfo(true);
    });
  }

  getCryptoAssetInfo(resetPage = false, resetFilter = false, pageEvent?: PaginatorState) {
    this.isLoading = true;
    if (!!resetPage) {
      this.rows = PER_PAGE;
      this.page = 1;
    }
    if (!!resetFilter) {
      this.filterSearch = '';
      this.filter = {};
    }
    if (!!pageEvent) {
      this.rows = pageEvent.rows || PER_PAGE;
      this.page = Math.floor((pageEvent as any)?.first / (pageEvent?.rows as number)) + 1 || 1;
    }
    let assetObservable: Observable<any> | undefined;
    switch (this.selectedField) {
      case CryptoAssetField.CRYPTOKEN_INFO: {
        assetObservable = this.deviceSrv.getDeviceCryptoAssetTokens(this.selectedDevice.id, this.page, this.rows, this.filterSearch);
        break;
      }
      case CryptoAssetField.CRYPTO_KEYS: {
        assetObservable = this.deviceSrv.getDeviceCryptoAssetKeys(this.selectedDevice.id, this.page, this.rows, this.filterSearch, this.filter);
        break;
      }
      case CryptoAssetField.CLIENT_CERTIFICATE: {
        assetObservable = this.deviceSrv.getDeviceCryptoAssetClientCerts(this.selectedDevice.id, this.page, this.rows, this.filterSearch);
        break;
      }
      case CryptoAssetField.CA_CERTIFICATE: {
        assetObservable = this.deviceSrv.getDeviceCryptoAssetCaCerts(this.selectedDevice.id, this.page, this.rows, this.filterSearch);
        break;
      }
      default: {
        this.showErrorMessage('No Crypto Asset field detected');
        break;
      }
    }
    if (!!assetObservable) {
      assetObservable
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (rs: any) => {
            this.isExpanded = false;
            this.totalRecords = rs.total_records || 0;
            this.page = rs.page || 1;
            const config = CRYPTO_ASSET_CONFIG_VALUES[this.selectedField as CryptoAssetField];

            this.filterConfiguration.totalRecords = rs?.totalRecords || 0;
            this.filterConfiguration.totalRecordsIcon = config.icon;
            this.values = ((rs?.[config.responseKey as string] as any[]) || []).map((value) => {
              const destination = value.destination;
              const destination_metadata = value.destination_metadata;

              switch (destination) {
                case DESTINATION_TYPE_VALUES.FILESYSTEM: {
                  return {
                    ...value,
                    file_path: destination_metadata?.file_path || '-',
                    file_permission: destination_metadata?.file_permission || '-',
                  };
                }
                case DESTINATION_TYPE_VALUES.CRYPTOKEN: {
                  return { ...value, pkcs11_uri: destination_metadata?.pkcs11_uri || '-' };
                }
                default: {
                  return value;
                }
              }
            });
          },
          error: (err) => {
            this.showErrorMessage(err);
          },
        });
    }
  }

  switchField(field: any) {
    this.isLoading = true;
    setTimeout(() => {
      this.selectedField = field.value;
      this.getCryptoAssetInfo(true, true);
    });
  }

  openAssetForm(selectedField: string) {
    let dialog = new DynamicDialogRef();
    switch (selectedField) {
      case CryptoAssetField.CRYPTO_KEYS: {
        dialog = this.dialogSrv.open(DcaCreateImportKeyFormComponent, {
          header: 'Create/Import Key',
          data: { device: this.selectedDevice },
          width: '800px',
          height: 'min-content',
          closeOnEscape: true,
        });
        break;
      }
      case CryptoAssetField.CLIENT_CERTIFICATE: {
        dialog = this.dialogSrv.open(DcaCreateImportClientCertificateFormComponent, {
          header: 'Create/Import Client Certificate',
          data: { device: this.selectedDevice },
          width: '1000px',
          height: 'min-content',
          closeOnEscape: true,
        });
        break;
      }
      case CryptoAssetField.CA_CERTIFICATE: {
        dialog = this.dialogSrv.open(DcaImportCaCertificateFormComponent, {
          header: 'Import CA Certificate',
          data: { device: this.selectedDevice },
          width: '800px',
          height: 'min-content',
          closeOnEscape: true,
        });
        break;
      }
      default: {
        this.showErrorMessage('No create or import action implemented');
        break;
      }
    }
    dialog?.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getCryptoAssetInfo(true, true);
      }
    });
  }

  deleteAsset(event: any, asset: any) {
    event.stopPropagation();
    const config: ConfirmationDialogConfig = {
      action: DELETE_LABEL,
      prepareRequest: () => {
        this.isLoading = true;
      },
      next: () => {
        this.isLoading = false;
        this.showSuccessMessage(`${asset.label} deletion is being initiated. Check the events tab for details.`);
        this.getCryptoAssetInfo(true, true);
      },
      error: (err: any) => {
        this.isLoading = false;
        this.showErrorMessage(err);
      },
    };

    switch (this.selectedField) {
      case CryptoAssetField.CRYPTO_KEYS: {
        config.objectName = 'Crypto Key';
        config.customContent = `Are you sure you want to delete the crypto key below.<p><li><b>${asset.label}</b> from <i>${asset.file_path}</i>
        </li></p><p> in device <b>${this.selectedDevice.name}?</p>`;
        config.acceptRequest = this.deviceSrv.deleteDeviceCryptoAssetKey(this.selectedDevice?.id, asset.id);
        break;
      }
      case CryptoAssetField.CLIENT_CERTIFICATE: {
        break;
      }
      case CryptoAssetField.CA_CERTIFICATE: {
        config.objectName = 'CA Certificate';
        config.customContent = `Are you sure you want to delete the CA certificate below.<p><li><b>${asset.label}</b> from <i>${asset.file_path}</i>
        </li></p><p> in device <b>${this.selectedDevice.name}?</p>`;
        config.acceptRequest = this.deviceSrv.deleteDeviceCryptoAssetCaCert(this.selectedDevice?.id, asset.id);
        break;
      }
    }
    this.confirm(config);
  }

  onPageChange(event: any) {
    this.getCryptoAssetInfo(false, false, event);
  }

  setFilterConfiguration(currentField: string) {
    this.filterConfiguration =
      currentField !== CryptoAssetField.CRYPTO_KEYS
        ? {
            types: ['search'],
            hideResetSortOption: true,
            showTotalRecords: false,
            searchPlaceholder: 'Search label...',
          }
        : {
            types: ['search', 'filter'],
            hideResetSortOption: true,
            showTotalRecords: false,
            searchPlaceholder: 'Search label...',
            filters: {
              0: {
                key: 'key_type',
                label: 'Key Type',
                type: 'dropdown',
                options: this.util.cloneObjectArray(ALL_KEY_TYPE_OPTIONS),
              },
              1: {
                key: 'key_destination',
                label: 'Key Destination',
                type: 'dropdown',
                options: this.util.cloneObjectArray(KEYRING_TYPES),
              },
              2: {
                key: 'key_algorithm',
                label: 'Key Algorithm',
                type: 'dropdown',
                options: this.util.cloneObjectArray(ALL_KEY_ALGORITHM_OPTIONS),
              },
            },
          };
  }
}
