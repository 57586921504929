import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromDevices from './+state/devices.reducer';
import { DevicesEffects } from './+state/devices.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDevices.DEVICES_FEATURE_KEY, fromDevices.devicesReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([DevicesEffects]),
  ],
})
export class NgrxDevicesModule {}
