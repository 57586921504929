import { Component, Input } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { DeviceService } from '@lcms-services';
import { MQTT_COMMAND_VALUES } from '@microsec/constants';

@Component({
  selector: 'app-device-telemetry',
  templateUrl: './device-telemetry.component.html',
  styleUrls: ['./device-telemetry.component.scss'],
})
export class DeviceTelemetryComponent extends BaseComponent {
  @Input() selectedDevice: any = null;

  constructor(private deviceSrv: DeviceService) {
    super();
  }

  telemetryAPICallback = (config: any) => {
    return this.deviceSrv.getDeviceLog(config);
  };

  mqttAPICallback = (command: string, config?: any, interval?: any) => {
    switch (command) {
      case MQTT_COMMAND_VALUES.PUBLISH: {
        return this.deviceSrv.sendDeviceInfoMQTTRequest(config);
      }
      case MQTT_COMMAND_VALUES.SUBSCRIBE: {
        return this.deviceSrv.startDeviceInfoMQTTSubscription(config);
      }
      case MQTT_COMMAND_VALUES.UNSUBSCRIBE: {
        return this.deviceSrv.unsubscribeDeviceInfoMQTTRequest(interval);
      }
      default: {
        this.showErrorMessage('No command provided');
        break;
      }
    }
  };
}
