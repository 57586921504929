import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@lcms-shared';
import { SharedCertificateDetailsComponent } from './shared-certificate-details.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SharedCertificateDetailsComponent],
  exports: [SharedCertificateDetailsComponent],
})
export class SharedCertificateDetailsModule {}
