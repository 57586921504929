import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { DELETE_LABEL, EDIT_LABEL } from '@microsec/constants';
import { DetailsPanelButtonModel } from '@microsec/models';

@Component({
  selector: 'app-cryptoken-manager-info',
  templateUrl: './cryptoken-manager-info.component.html',
  styleUrls: ['./cryptoken-manager-info.component.scss'],
})
export class CryptokenManagerInfoComponent extends BaseComponent implements AfterViewInit {
  @Input() isLoading = false;

  _selectedManager: any = null;

  @Input() set selectedManager(value: any) {
    this._selectedManager = value;
    this.prepareActionButtons();
  }

  get selectedManager() {
    return this._selectedManager;
  }

  @Output() selectedManagerChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() openCryptokenManagerFormEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() confirmDeletionEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() refreshCryptokenManagerEvent: EventEmitter<any> = new EventEmitter<any>();

  actionButtons: DetailsPanelButtonModel[] = [];

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.prepareActionButtons();
  }

  /**
   * Unselect cryptoken manager
   */
  unselectManager() {
    this.selectedManagerChange.emit(null);
  }

  /**
   * Prepare action buttons
   */
  prepareActionButtons() {
    this.actionButtons = [
      {
        label: EDIT_LABEL,
        command: () => {
          this.handleAction('edit');
        },
      },
      {
        label: DELETE_LABEL,
        command: () => {
          this.handleAction('delete');
        },
      },
    ];
  }

  /**
   * Handle events: edit, delete and refresh
   * @param event
   */
  handleAction(action: string) {
    switch (action) {
      case 'edit': {
        this.openCryptokenManagerFormEvent.emit(this.selectedManager);
        break;
      }
      case 'delete': {
        this.confirmDeletionEvent.emit(this.selectedManager);
        break;
      }
      case 'refresh': {
        this.refreshCryptokenManagerEvent.emit(this.selectedManager?.id);
        break;
      }
      default: {
        break;
      }
    }
  }
}
