import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@lcms-shared';
import { SharedLicenseWarningModule } from '../shared-components/shared-license-warning/shared-license-warning.module';
import { SharedEventsModule } from '../shared-components/shared-events/shared-events.module';
import { Config2faComponent } from './access/config2fa/config2fa.component';
import { ConfigurationComponent } from './access/config2fa/configuration/configuration.component';
import { RsaComponent } from './access/config2fa/rsa/rsa.component';
import { LdapComponent } from './access/ldap/ldap.component';
import { UserFormComponent } from './access/users/user-form/user-form.component';
import { UsersComponent } from './access/users/users.component';
import { DetailsComponent } from './licenses/details/details.component';
import { LicensesComponent } from './licenses/licenses.component';
import { UploadComponent } from './licenses/upload/upload.component';
import { EventLogsComponent } from './notification/event-logs/event-logs.component';
import { SystemLogsComponent } from './notification/system-logs/system-logs.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { GlobalSettingsComponent } from './global-settings.component';

@NgModule({
  declarations: [
    GlobalSettingsComponent,
    OrganizationsComponent,
    EventLogsComponent,
    SystemLogsComponent,
    LicensesComponent,
    DetailsComponent,
    UploadComponent,
    UsersComponent,
    UserFormComponent,
    LdapComponent,
    Config2faComponent,
    RsaComponent,
    ConfigurationComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, SharedModule, SharedLicenseWarningModule, SharedEventsModule],
})
export class GlobalSettingsModule {}
