import { AfterViewInit, Component, ElementRef, Injector, Input, OnDestroy, ViewChild } from '@angular/core';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ConstantPipe } from '@lcms-pipes';
import { CaService, DeviceService } from '@lcms-services';
// import { SharedCertificateDetailsComponent } from '@lcms-products';
// import { DeviceCertificateService } from '@lcms-services';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { MICROSEC_LCMS_CA, MICROSEC_LCMS_RA, ORGANIZATION_LEVEL_ROUTE, PIPE_DATETIME, PROJECT_LEVEL_ROUTE } from '@microsec/constants';
import { ActionMenuItem, FormItem } from '@microsec/models';
import { finalize } from 'rxjs';

const FORM_PARAMS = {
  LABEL: 'label',
  KEY_TYPE: 'key_type',
  ALGORITHM: 'algorithm',
  PURPOSE: 'purpose',
  PARTNER_KEY_ID: 'partner_key_id',
  KEY_DATA: 'key_value',
};

@Component({
  selector: 'app-generic-key-details',
  templateUrl: './generic-key-details.component.html',
  styleUrls: ['./generic-key-details.component.scss'],
  providers: [ConstantPipe],
})
export class GenericKeyDetailsComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = false;

  _selectedKey: any = null;

  @Input() set selectedKey(value: any) {
    this._selectedKey = value;
    this.initForm();
  }

  get selectedKey() {
    return this._selectedKey;
  }

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  PIPE_DATETIME = PIPE_DATETIME;

  cols = [
    { field: 'hexSerialNumber', header: 'Serial Number', width: 6, frozen: true },
    { field: 'status', header: 'Status', width: 3 },
    { field: 'not_before', header: 'Issue Date', width: 6 },
    { field: 'not_after', header: 'Expiry Date', width: 6 },
  ];

  values: any[] = [];

  actionMenuItems: ActionMenuItem[] = [];

  app: string;

  MICROSEC_LCMS_CA = MICROSEC_LCMS_CA;

  MICROSEC_LCMS_RA = MICROSEC_LCMS_RA;

  constructor(
    public el: ElementRef,
    private constantPipe: ConstantPipe,
    private deviceSrv: DeviceService,
    private caSrv: CaService,
    private injector: Injector,
  ) {
    super();
    this.app = this.injector.get('environment').module as string;
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();

    this.actionMenuItems = [
      {
        label: 'View Certificate',
        icon: 'fas fa-arrow-up-right-from-square',
        command: ({ rowData }: any) => this.handleCertRowClick(rowData),
      },
    ];

    switch (this.app) {
      case MICROSEC_LCMS_CA: {
        this.getCertificates();
        break;
      }
      case MICROSEC_LCMS_RA: {
        this.getDeviceCertificates();
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Init the form data
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Label',
        field: 'label',
        defaultValue: this.selectedKey?.[FORM_PARAMS.LABEL],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_TYPE,
        label: 'Key Type',
        field: 'label',
        defaultValue: this.util.getUppercaseFirstLetter(this.selectedKey?.[FORM_PARAMS.KEY_TYPE]),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ALGORITHM,
        label: 'Algorithm',
        field: 'label',
        defaultValue: this.selectedKey[FORM_PARAMS.ALGORITHM] || '-',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PURPOSE,
        label: 'Purpose',
        field: 'label',
        defaultValue: this.selectedKey?.[FORM_PARAMS.PURPOSE] || '-',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PARTNER_KEY_ID,
        label: 'Partner Key',
        field: 'label',
        defaultValue: this.selectedKey?.[FORM_PARAMS.PARTNER_KEY_ID] || '-',
      } as FormItem),
      ...(this.selectedKey?.[FORM_PARAMS.KEY_TYPE] === 'public'
        ? [
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.KEY_DATA,
              label: 'Key Data',
              field: 'copy',
              defaultValue: this.selectedKey?.[FORM_PARAMS.KEY_DATA],
            } as FormItem),
          ]
        : []),
    ];
  }

  /**
   * Get device certificates (Device only) in RA dashboard
   */
  getDeviceCertificates() {
    this.isLoading = true;
    this.deviceSrv
      .getDeviceCertificates(this.breadcrumbConfig?.projectId, this.selectedKey.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.initForm();
        }),
      )
      .subscribe({
        next: (rs) => {
          this.values = ((rs?.certificates as any[]) || []).map((value) => ({
            ...value,
            hexSerialNumber: this.util.convertToHexadecimal(value.serial_number),
          }));
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get certificates
   */
  getCertificates() {
    this.isLoading = true;
    this.caSrv
      .getCAs(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId, undefined, undefined, this.selectedKey?.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.values = ((rs as any[]) || []).map((value) => ({
            ...value,
            hexSerialNumber: this.util.convertToHexadecimal(value.serial_number),
          }));
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Go to Certificate Tab
   * @param cert
   */
  handleCertRowClick(cert: any) {
    if (!!cert.id) {
      if (this.app === MICROSEC_LCMS_CA) {
        this.router.navigate(
          [
            `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
              `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
              `${PROJECT_MANAGEMENT_CONSTANTS.CHAINS_OF_TRUST.ROUTE}/cas`,
          ],
          { queryParams: { cert_id: cert?.id } },
        );
      }
      if (this.app === MICROSEC_LCMS_RA) {
        this.router.navigate(
          [
            `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
              `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
              `${PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE}/details`,
          ],
          { queryParams: { key_id: this.selectedKey.id } },
        );
      }
    } else {
      this.showErrorMessage('Unable to view certificate');
    }
  }
}
