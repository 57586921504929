import { FEATURES, PKI_MANAGEMENT_FEATURES } from '@lcms-constants';
import { PROJECT_SETTINGS_CHILDREN as COMMON_PROJECT_SETTINGS_CHILDREN, PROJECT_SETTINGS_CONSTANTS } from '@lcms-products';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { SharedLabelsComponent } from '../../../shared-components/shared-labels/shared-labels.component';
import { ConnectedCAsComponent } from './connected-cas/connected-cas.component';
import { DeviceEnrollmentComponent } from './device-enrollment/device-enrollment.component';
import { UserEnrollmentComponent } from './user-enrollment/user-enrollment.component';

const reorderItems = (items: ILayoutItem[]) => {
  // Project info and Connected CA (if any) will be the first tabs
  const results: ILayoutItem[] = [
    ...items.filter(
      (p) => p.route === PROJECT_SETTINGS_CONSTANTS.PROJECT_INFORMATION.ROUTE || p.route === PROJECT_SETTINGS_CONSTANTS.CONNECTED_CAS.ROUTE,
    ),
  ];
  // Then others
  items
    .filter((p) => ![PROJECT_SETTINGS_CONSTANTS.PROJECT_INFORMATION.ROUTE, PROJECT_SETTINGS_CONSTANTS.CONNECTED_CAS.ROUTE].includes(p.route || ''))
    .forEach((item) => {
      results.push(item);
    });
  return results;
};

export const PROJECT_SETTINGS_CHILDREN: ILayoutItem[] = reorderItems([
  ...COMMON_PROJECT_SETTINGS_CHILDREN,
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: PROJECT_SETTINGS_CONSTANTS.CONNECTED_CAS.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.CONNECTED_CAS.LABEL,
    component: ConnectedCAsComponent,
    visible: false,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: PROJECT_SETTINGS_CONSTANTS.DEVICE_ENROLLMENT.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.DEVICE_ENROLLMENT.LABEL,
    component: DeviceEnrollmentComponent,
    visible: false,
    featureName: FEATURES.PKI_MANAGEMENT,
    featureCheckField: PKI_MANAGEMENT_FEATURES.X509,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: PROJECT_SETTINGS_CONSTANTS.USER_ENROLLMENT.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.USER_ENROLLMENT.LABEL,
    component: UserEnrollmentComponent,
    visible: false,
    featureName: FEATURES.PKI_MANAGEMENT,
    featureCheckField: PKI_MANAGEMENT_FEATURES.X509,
  },
  {
    route: PROJECT_SETTINGS_CONSTANTS.LABELS.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.LABELS.LABEL,
    component: SharedLabelsComponent,
    visible: false,
  },
]);
