<p class="text-base line-height-2 text-justify">
  Please select one of the options below to create a new user certificate enrollment request or to check the request status if you have already
  created a request.
</p>
<div class="grid my-8">
  <div class="col-2"></div>
  <div class="col-8 text-center">
    <p-button severity="primary" label="Create New Request" styleClass="my-2" (onClick)="goTo('request')" />
    <p-button severity="primary" label="Check Request Status" styleClass="my-2" (onClick)="goTo('check')" />
  </div>
  <div class="col-2"></div>
</div>
