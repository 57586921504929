/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { asyncScheduler, map, Observable, scheduled } from 'rxjs';
import { IUserCertificateService } from './IUserCertificateService';
import managers from './mock-data/user-certificate-managers.json';
import details from './mock-data/user-certificate-manager-details.json';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class MockUserCertificateService implements IUserCertificateService {
  getManagers(projectId: any): Observable<any> {
    return scheduled([managers], asyncScheduler).pipe(
      map((result: any) => {
        const userCertificates = ((result?.users as any[]) || []).map((uc) => {
          const certs = (uc?.certs as any[]) || [];
          certs.forEach((cert) => {
            cert.not_before = !!cert.not_before ? moment.utc(cert.not_before).local() : null;
            cert.not_after = !!cert.not_after ? moment.utc(cert.not_after).local() : null;
          });
          uc.certs = certs;
          return uc;
        });
        result.users = userCertificates;
        return result;
      }),
    );
  }
  updateManager(managerId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteManager(managerId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getManagerDetails(managerId: any): Observable<any> {
    const manager = managers?.data?.find((p) => p.id === managerId);
    return scheduled([{ ...details, ...manager }], asyncScheduler);
  }
}
