import { ILayoutItem } from '@microsec/interfaces';
import { CryptoAssetsComponent } from './crypto-assets/crypto-assets.component';
import { GeneralComponent } from './general/general.component';
import { DEVICE_OVERVIEW_CONSTANTS } from './overview.constants';
import { SecurityComponent } from './security/security.component';

export const DEVICE_OVERVIEW_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: DEVICE_OVERVIEW_CONSTANTS.GENERAL.ROUTE,
  },
  {
    route: DEVICE_OVERVIEW_CONSTANTS.GENERAL.ROUTE,
    label: DEVICE_OVERVIEW_CONSTANTS.GENERAL.LABEL,
    component: GeneralComponent,
  },
  {
    route: DEVICE_OVERVIEW_CONSTANTS.SECURITY.ROUTE,
    label: DEVICE_OVERVIEW_CONSTANTS.SECURITY.LABEL,
    component: SecurityComponent,
  },
  {
    route: DEVICE_OVERVIEW_CONSTANTS.CRYPTO_ASSETS.ROUTE,
    label: DEVICE_OVERVIEW_CONSTANTS.CRYPTO_ASSETS.LABEL,
    component: CryptoAssetsComponent,
  },
];
