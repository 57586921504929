import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API } from '../api';
import { IEnvelopeService } from './IEnvelopeService';

const API_ENVELOPE = `${API.ENVELOPE_MANAGEMENT}/envelope`;
const API_ENVELOPE_TEMPLATE = `${API_ENVELOPE}/templates`;

@Injectable({
  providedIn: 'root',
})
export class EnvelopeService implements IEnvelopeService {
  hashes: any[] = [];

  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  refreshObs: Observable<any> = this.refresh$.asObservable();

  constructor(private http: HttpClient) {}

  getTemplates(organizationId?: any, projectId?: any): Observable<any> {
    let url = `${API_ENVELOPE_TEMPLATE}`;
    if (!!organizationId) {
      url += `?organization_id=${organizationId}`;
      if (!!projectId) {
        url += `&project_id=${projectId}`;
      }
    }
    return this.http.get<any>(url).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createTemplate(payload: any): Observable<any> {
    return this.http.post<any>(`${API_ENVELOPE_TEMPLATE}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateTemplate(id: any, payload: any): Observable<any> {
    return this.http.put<any>(`${API_ENVELOPE_TEMPLATE}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteTemplate(id: any): Observable<any> {
    return this.http.delete<any>(`${API_ENVELOPE_TEMPLATE}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getSignedEnvelopes(templateId: any): Observable<any> {
    return this.http
      .get<any>(`${API_ENVELOPE_TEMPLATE}/${templateId}/signed-envelopes`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getHashes(): Observable<any> {
    return this.http.get<any>(`${API_ENVELOPE}/hashes`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  signEnvelope(payload: any) {
    let finalPayload = null;
    if (!!payload?.content) {
      finalPayload = { ...payload, payload: payload?.content };
      delete finalPayload.content;
      delete finalPayload.file;
    } else if (!!payload?.file) {
      finalPayload = new FormData();
      finalPayload.append('envelope_template_id', payload.envelope_template_id);
      finalPayload.append('ca_server_id', payload.ca_server_id);
      finalPayload.append('ca_id', payload.ca_id);
      finalPayload.append('file', payload.file);
    }
    return this.http
      .post(`${API_ENVELOPE}/sign-file`, finalPayload, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  signCSR(payload: any): Observable<any> {
    return this.http
      .post(`${API_ENVELOPE}/sign-csr`, payload, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  checkSchema(schema: string[], content: string): boolean {
    const schemaString = schema.join('\n');
    const regex = new RegExp(schemaString.replace('\\\\', '\\'));
    return regex.test(content);
  }

  verifyEnvelope(payload: any): Observable<any> {
    return this.http.post<any>(`${API_ENVELOPE}/verify`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  downloadEnvelope(envelopeId: any): Observable<any> {
    const options: any = {
      responseType: 'blob',
      observe: 'response',
    };
    return this.http
      .get<any>(`${API_ENVELOPE}/${envelopeId}/download`, options)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  shareEnvelope(envelopeId: any, users: any[]): Observable<any> {
    return this.http
      .post<any>(`${API_ENVELOPE}/${envelopeId}/share`, { user: users })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  validateLambdaCode(payload: any): Observable<any> {
    return this.http.post<any>(`${API_ENVELOPE_TEMPLATE}/validation`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
}
