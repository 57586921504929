import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {
  DEVICE_REENROLLMENT_STRATEGIES,
  EST_ENROLLMENT_AUTHENTICATION_MODES,
  PKI_MANAGEMENT_FEATURES,
  SCEP_ENROLLMENT_AUTHENTICATION_MODES,
} from '@lcms-constants';
import { ProjectService } from '@microsec/services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { USER_ROLE, IPV4_RANGE_PATTERN, SAVE_CHANGES_LABEL, SCOPE, VALIDATOR_TYPE } from '@microsec/constants';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Setting2FAService } from '@lcms-services';

const FORM_PARAMS = {
  EST_ENROLLMENT_TYPE: 'est_enrolment_type',
  SCEP_ENROLLMENT_TYPE: 'scep_enrolment_type',
  DEVICE_REENROLLMENT_STRATEGY: 'device_re_enrol_strategy',
  IMEIS: 'csr_enrolment_allowed_imeis',
  SIM_IDS: 'csr_enrolment_allowed_sim_ids',
  GPS: 'csr_enrolment_allowed_gps',
  GPS_RANGE: 'csr_enrolment_allowed_gps_range',
  IPS: 'csr_enrolment_allowed_ips',
};

@Component({
  selector: 'app-device-enrollment',
  templateUrl: './device-enrollment.component.html',
  styleUrls: ['./device-enrollment.component.scss'],
})
export class DeviceEnrollmentComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  project: any = null;

  globalSetting: any = null;

  constructor(
    private projectSrv: ProjectService,
    private setting2FASrv: Setting2FAService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    const hasPermission = (this.permissions as any)[SCOPE.PROJECT][USER_ROLE.ADMIN];
    if (!!this.isX509Featured && !!hasPermission) {
      this.getData();
    } else {
      this.navigateToNotFoundPage();
    }
  }

  /**
   * Get all form data
   */
  getData() {
    this.form.isLoading = true;
    forkJoin({
      projectData: this.projectSrv.getProject(this.breadcrumbConfig?.projectId as number),
      globalSettingData: this.setting2FASrv.getGlobalSetting(),
    })
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.project = rs.projectData;
          this.globalSetting = rs.globalSettingData;
          this.initForm();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Init form
   */
  initForm() {
    const estEnrollmentAuthenticationModes = this.util.cloneObjectArray(EST_ENROLLMENT_AUTHENTICATION_MODES);
    const scepEnrollmentAuthenticationModes = this.util.cloneObjectArray(SCEP_ENROLLMENT_AUTHENTICATION_MODES);
    const deviceReEnrollStrategyOptions = this.util.cloneObjectArray(DEVICE_REENROLLMENT_STRATEGIES);
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.EST_ENROLLMENT_TYPE,
        label: 'EST Enrollment Authentication Mode',
        labelStyleClass: 'font-bold',
        subLabel: 'Select Enrollment Authentication Mode for EST.',
        field: 'radio',
        options: estEnrollmentAuthenticationModes,
        layout: 'vertical',
        defaultValue: !!this.project[FORM_PARAMS.EST_ENROLLMENT_TYPE]
          ? this.project[FORM_PARAMS.EST_ENROLLMENT_TYPE]
          : estEnrollmentAuthenticationModes[0].value,
        disabled: !this.globalSetting?.device_enrollment || false,
        fieldInfo: !this.globalSetting?.device_enrollment ? 'Global 2FA Device Enrollment is disabled' : null,
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SCEP_ENROLLMENT_TYPE,
        label: 'SCEP Enrollment Authentication Mode',
        labelStyleClass: 'font-bold',
        subLabel: 'Select Enrollment Authentication Mode for SCEP.',
        field: 'radio',
        options: scepEnrollmentAuthenticationModes,
        layout: 'vertical',
        defaultValue: !!this.project[FORM_PARAMS.SCEP_ENROLLMENT_TYPE]
          ? this.project[FORM_PARAMS.SCEP_ENROLLMENT_TYPE]
          : scepEnrollmentAuthenticationModes[0].value,
        disabled: !this.globalSetting?.device_enrollment || false,
        fieldInfo: !this.globalSetting?.device_enrollment ? 'Global 2FA Device Enrollment is disabled' : null,
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DEVICE_REENROLLMENT_STRATEGY,
        label: 'Device Re-Enrollment Strategy',
        labelStyleClass: 'font-bold',
        subLabel: 'LCMS can be configured to allow devices to be enrolled from different hosts.<br/>This setting is disabled by default.',
        field: 'radio',
        options: deviceReEnrollStrategyOptions,
        layout: 'vertical',
        defaultValue: !!this.project[FORM_PARAMS.DEVICE_REENROLLMENT_STRATEGY]
          ? this.project[FORM_PARAMS.DEVICE_REENROLLMENT_STRATEGY]
          : deviceReEnrollStrategyOptions[0].value,
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IMEIS,
        label: 'Allowed Device IMEIs',
        labelStyleClass: 'font-bold',
        subLabel: 'Enter device IMEIs for devices that are allowed for enrollment.<br/>Leave empty to allow all device IMEIs.',
        field: 'chip',
        fieldStyleClass: 'w-8',
        defaultValue: this.project[FORM_PARAMS.IMEIS] || [],
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIM_IDS,
        label: 'Allowed SIM IDs (ICCIDs)',
        labelStyleClass: 'font-bold',
        subLabel: 'Enter SIM IDs for devices that are allowed for enrollment.<br/>Leave empty to allow all SIM IDs.',
        field: 'chip',
        fieldStyleClass: 'w-8',
        defaultValue: this.project[FORM_PARAMS.SIM_IDS] || [],
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.GPS,
        label: 'Allowed GPS Areas',
        labelStyleClass: 'font-bold',
        subLabel:
          'Enter GPS coordinates (Decimal Degrees) for devices that are allowed for enrollment.<br/>' +
          'Example: 1.3282426;103.8731246<br/>' +
          'Leave empty to allow all locations.',
        field: 'chip',
        chipPattern: /^(-?\d+(\.\d+)?);(-?\d+(\.\d+)?)$/,
        fieldStyleClass: 'w-8',
        defaultValue: this.project[FORM_PARAMS.GPS] || [],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.GPS_RANGE,
        label: 'Allowed GPS Range (km)',
        labelStyleClass: 'font-bold',
        subLabel: 'Enter GPS ranges from the coordinates for devices that are allowed for enrollment.',
        field: 'number',
        fieldStyleClass: 'w-8',
        defaultValue: this.project[FORM_PARAMS.GPS_RANGE],
        required: !!((this.project[FORM_PARAMS.GPS] as any[]) || []).length,
        hidden: !((this.project[FORM_PARAMS.GPS] as any[]) || []).length,
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IPS,
        label: 'Allowed IP Ranges',
        labelStyleClass: 'font-bold',
        subLabel: 'Enter IP ranges for devices that are allowed for enrollment.<br/>Leave empty to allow all IP ranges.',
        field: 'chip',
        chipPattern: IPV4_RANGE_PATTERN,
        fieldStyleClass: 'w-8',
        defaultValue: this.project[FORM_PARAMS.IPS] || [],
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
    this.setupChangeEvents();
  }

  setupChangeEvents() {
    this.form.setChangeEvent(FORM_PARAMS.GPS, (value: any[]) => {
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.GPS_RANGE, !!value.length ? [VALIDATOR_TYPE.REQUIRED] : []);
    });
  }

  /**
   * Update device enrollment configuration
   */
  onSubmit() {
    const project = {
      ...this.project,
      ...this.form.getRawValue(),
    };
    if (!((project[FORM_PARAMS.GPS] as any[]) || []).length) {
      project[FORM_PARAMS.GPS_RANGE] = 0;
    }
    this.projectSrv
      .updateProject(this.breadcrumbConfig?.projectId as number, project)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Updated device enrollment for project ${this.project.name} successfully`);
          this.getData();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Check if X509 is featured
   */
  get isX509Featured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.X509);
  }
}
