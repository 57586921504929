<microsec-loading-overlay [isLoading]="isLoading">
  <div class="grid">
    <div class="col-12"><microsec-form-builder [isPage]="true" #fb [fields]="fields" [shouldShowActionButtons]="false" /></div>
    <!-- In CA Dashboard, Render Chain of Trust certificates links on Keyring overview -->
    <!-- In RA Dashboard, Render Devices certificate links on Keyring overview -->
    <div class="col-12">
      @if (app === MICROSEC_LCMS_CA) {
        <label class="text-data font-bold">Associated CA Certificate(s)</label>
      }
      @if (app === MICROSEC_LCMS_RA) {
        <label class="text-data font-bold">Associated Device Certificate(s)</label>
      }
    </div>
    <div class="col-12">
      <microsec-layout-table
        itemName="Certificate"
        [cols]="cols || []"
        [values]="certificates"
        (getItemsEvent)="app === MICROSEC_LCMS_CA ? getCACertificates() : app === MICROSEC_LCMS_RA ? getDeviceCertificates() : null"
        [actionMenuItems]="actionMenuItems"
        [cellTemplate]="cellTemplate" />
    </div>
  </div>
</microsec-loading-overlay>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Mode -->
    @case ('mode') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'ca-mode' }}
      </microsec-truncate-text>
    }
    <!-- Valid not before -->
    @case ('not_before') {
      <microsec-truncate-text>
        {{ rowData[col.field] | date: PIPE_DATETIME }}
      </microsec-truncate-text>
    }
    <!-- Valid not after -->
    @case ('not_after') {
      <microsec-truncate-text>
        {{ rowData[col.field] | date: PIPE_DATETIME }}
      </microsec-truncate-text>
    }
    @default {
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    }
  }
</ng-template>
