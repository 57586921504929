import { Action } from '@ngrx/store';

export enum DevicesActionTypes {
  INIT = '[Devices] Init',

  GET_ALL_DEVICES = '[Devices] Get Devices',
  GET_ALL_DEVICES_SUCCESS = '[Devices] Get Devices Success',
  GET_ALL_DEVICES_FAILURE = '[Devices] Get Devices Failure',
  CANCEL_GET_DEVICES_REQUEST = '[Devices] Cancel Get Devices Request',

  SELECT_DEVICE = '[Devices] Select Device',

  UPDATE_DEVICE = '[Devices] Update Device',
  UPDATE_DEVICE_SUCCESS = '[Devices] Update Device Success',
  UPDATE_DEVICE_FAILURE = '[Devices] Update Device Failure',

  DELETE_DEVICE = '[Devices] Delete Device',
  DELETE_DEVICE_SUCCESS = '[Devices] Delete Device Success',
  DELETE_DEVICE_FAILURE = '[Devices] Delete Device Failure',

  REFRESH_DEVICES = '[Devices] Refresh Devices',

  REFRESH_DEVICE_EVENTS = '[Devices] Refresh Device Events',

  EXPORT_DEVICES = '[Devices] Export Devices',
  EXPORT_DEVICES_SUCCESS = '[Devices] Export Devices Success',
  EXPORT_DEVICES_FAILURE = '[Devices] Export Devices Failure',
  CANCEL_EXPORT_DEVICES_REQUEST = '[Devices] Cancel Export Devices Request',
}
// Init
class Init implements Action {
  readonly type = DevicesActionTypes.INIT;
}

// Get all devices
class GetAllDevices implements Action {
  readonly type = DevicesActionTypes.GET_ALL_DEVICES;

  constructor(
    public organizationId: any,
    public projectId: any,
    public filters: any,
    public searchText: any,
    public sortConfig: any,
    public page: any,
    public perPage: any,
    public keyringId: any,
    public keyId: any,
  ) {}
}

// On get all device successfully
class OnGetAllDevicesSuccess implements Action {
  readonly type = DevicesActionTypes.GET_ALL_DEVICES_SUCCESS;

  constructor(
    public payload: {
      organizationId: any;
      projectId: any;
      filters: any;
      searchText: any;
      sortConfig: any;
      page: any;
      perPage: any;
    },
    public data: any,
    public selectedDevice?: any,
  ) {}
}

// On get devices failed
class OnGetAllDevicesFailure implements Action {
  readonly type = DevicesActionTypes.GET_ALL_DEVICES_FAILURE;

  constructor(public error: any) {}
}

class CancelGetDevicesRequest implements Action {
  readonly type = DevicesActionTypes.CANCEL_GET_DEVICES_REQUEST;
}

/** SELECT DEVICE */

// Select a device
class SelectDevice implements Action {
  readonly type = DevicesActionTypes.SELECT_DEVICE;

  constructor(public selectedDevice: any) {}
}

/** UPDATE DEVICE */

// Update a device
class UpdateDevice implements Action {
  readonly type = DevicesActionTypes.UPDATE_DEVICE;

  constructor(
    public selectedDevice: any,
    public payload: any,
  ) {}
}

// On update a device successfully
class OnUpdateDeviceSuccess implements Action {
  readonly type = DevicesActionTypes.UPDATE_DEVICE_SUCCESS;

  constructor(public message: string) {}
}

// On update a device failed
class OnUpdateDeviceFailure implements Action {
  readonly type = DevicesActionTypes.UPDATE_DEVICE_FAILURE;

  constructor(public error: any) {}
}

/** DELETE DEVICE */

// Delete a device
class DeleteDevice implements Action {
  readonly type = DevicesActionTypes.DELETE_DEVICE;

  constructor(
    public selectedDevice: any,
    public isForced: boolean = false,
    public callback: any = null,
  ) {}
}

// On delete a device successfully
class OnDeleteDeviceSuccess implements Action {
  readonly type = DevicesActionTypes.DELETE_DEVICE_SUCCESS;

  constructor(public message: string) {}
}

// On delete a device failed
class OnDeleteDeviceFailure implements Action {
  readonly type = DevicesActionTypes.DELETE_DEVICE_FAILURE;

  constructor(
    public error: any,
    public callback: any = null,
  ) {}
}

/** REFRESH */

// Refresh the list of devices
class RefreshDevices implements Action {
  readonly type = DevicesActionTypes.REFRESH_DEVICES;
}

// Refresh the list of device events
class RefreshDeviceEvents implements Action {
  readonly type = DevicesActionTypes.REFRESH_DEVICE_EVENTS;
}

// Export devices
class ExportDevices implements Action {
  readonly type = DevicesActionTypes.EXPORT_DEVICES;

  constructor(
    public organizationId: any,
    public projectId: any,
    public filters: any,
    public searchText: any,
  ) {}
}

// On export devices successfully
class OnExportDevicesSuccess implements Action {
  readonly type = DevicesActionTypes.EXPORT_DEVICES_SUCCESS;

  constructor(public results: any[]) {}
}

// On export devices failed
class OnExportDevicesFailed implements Action {
  readonly type = DevicesActionTypes.EXPORT_DEVICES_FAILURE;

  constructor(public error: any) {}
}

// Cancel export devices request
class CancelExportDevicesRequest implements Action {
  readonly type = DevicesActionTypes.CANCEL_EXPORT_DEVICES_REQUEST;
}

export type DevicesAction =
  | Init
  | GetAllDevices
  | OnGetAllDevicesSuccess
  | OnGetAllDevicesFailure
  | CancelGetDevicesRequest
  | SelectDevice
  | UpdateDevice
  | OnUpdateDeviceSuccess
  | OnUpdateDeviceFailure
  | DeleteDevice
  | OnDeleteDeviceSuccess
  | OnDeleteDeviceFailure
  | RefreshDevices
  | RefreshDeviceEvents
  | ExportDevices
  | OnExportDevicesSuccess
  | OnExportDevicesFailed
  | CancelExportDevicesRequest;

export const fromDevicesActions = {
  Init,
  GetAllDevices,
  OnGetAllDevicesSuccess,
  OnGetAllDevicesFailure,
  CancelGetDevicesRequest,
  SelectDevice,
  UpdateDevice,
  OnUpdateDeviceSuccess,
  OnUpdateDeviceFailure,
  DeleteDevice,
  OnDeleteDeviceSuccess,
  OnDeleteDeviceFailure,
  RefreshDevices,
  RefreshDeviceEvents,
  ExportDevices,
  OnExportDevicesSuccess,
  OnExportDevicesFailed,
  CancelExportDevicesRequest,
};
