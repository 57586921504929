import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { KmsService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { ConfirmationDialogConfig, FormItem } from '@microsec/models';
import { LOGOUT_LABEL } from '@microsec/constants';
import { finalize } from 'rxjs/operators';
import { CryptokenFormComponent } from './cryptoken-form/cryptoken-form.component';

const FORM_PARAMS = {
  NAME: 'label',
  DESCRIPTION: 'description',
  ADDRESS: 'address',
  STATUS: 'connection_status',
};

@Component({
  selector: 'app-cryptoken-manager-overview',
  templateUrl: './cryptoken-manager-overview.component.html',
  styleUrls: ['./cryptoken-manager-overview.component.scss'],
})
export class CryptokenManagerOverviewComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  _selectedManager: any = null;

  get selectedManager() {
    return this._selectedManager;
  }

  @Input() set selectedManager(value: any) {
    this._selectedManager = value;
    this.initForm();
    if (!!value && (value?.connection_status as string)?.toLowerCase() === 'connected') {
      this.getCryptokens(value.id);
    } else {
      this.values = [];
    }
  }

  cols: any[] = [
    { field: 'label', header: 'Label', width: 4, frozen: true },
    { field: 'types', header: 'Type', width: 5 },
    { field: 'serial_id', header: 'Serial #', width: 7 },
    { field: 'connection_status', header: 'Connection', width: 4 },
    { field: 'logged_in', header: 'Login', width: 5 },
    { field: 'actions', header: 'Actions', width: 3 },
  ];

  allCols: any[] = this.cols;

  values: any[] = [];

  @Output() actionEvent = new EventEmitter<any>();

  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;

  constructor(
    public el: ElementRef,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.initForm();
  }

  /**
   * Initialize form
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'label',
        defaultValue: this.selectedManager?.[FORM_PARAMS.NAME],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'label',
        defaultValue: this.selectedManager?.[FORM_PARAMS.DESCRIPTION],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ADDRESS,
        label: 'Address',
        field: 'label',
        defaultValue: this.selectedManager?.[FORM_PARAMS.ADDRESS],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.STATUS,
        label: 'Status',
        field: 'custom',
        customField: this.statusTemplate,
        defaultValue: this.selectedManager?.[FORM_PARAMS.STATUS],
      } as FormItem),
    ];
  }

  /**
   * Get cryptokens
   * @param managerId
   */
  getCryptokens(managerId: any) {
    this.isLoading = true;
    this.kmsSrv
      .getCryptokens(managerId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const tokens = rs || [];
          this.values = this.util.sortObjectArray(tokens, 'id');
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Open cryptoken form
   * @param token
   */
  openCryptokenForm(token: any) {
    const dialogRef = this.dialogSrv.open(CryptokenFormComponent, {
      data: {
        managerId: this.selectedManager.id,
        token,
      },
      header: 'Login to Token',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        this.getCryptokens(this.selectedManager.id);
      }
    });
  }

  /**
   * Confirm cryptoken logout
   * @param token
   */
  confirmCryptokenLogout(token: any) {
    this.confirm({
      action: LOGOUT_LABEL,
      objectName: 'Cryptographic Token',
      object: token,
      objectFieldName: 'label',
      acceptRequest: this.kmsSrv.logoutCryptoken(this.selectedManager.id, token.id),
      next: () => {
        this.showSuccessMessage(`Logged out cryptographic token ${token.label} successfully`);
        this.getCryptokens(this.selectedManager.id);
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Emit action
   * @param action
   */
  emitAction(action: string) {
    this.actionEvent.emit(action);
  }
}
