import { ChartOptions } from 'chart.js';

export const DIAGRAMS = {
  DEVICE_MANAGEMENT: {
    TYPE: 'doughnut',
    FIELD: 'devices',
    KEY: 'DEVICE_MANAGEMENT',
    LABEL: 'Device Management',
    CHILDREN: [
      {
        FIELD: 'total',
        LABEL: 'Total Devices',
        COLOR: '#FFFFFF',
      },
      {
        FIELD: 'agent',
        LABEL: 'Managed Devices',
        COLOR: '#049D6B',
      },
      {
        FIELD: 'agentless',
        LABEL: 'Unmanaged Devices',
        COLOR: '#C02422',
      },
    ],
  },
  DEVICES_PACKAGE: {
    TYPE: 'doughnut',
    FIELD: 'packages',
    KEY: 'DEVICES_PACKAGE',
    LABEL: 'Devices with Outdated Packages',
    CHILDREN: [
      {
        FIELD: 'devices_without_outdated_packages',
        LABEL: 'Device{0} with Updated Packages',
        COLOR: '#00Ad74',
      },
      {
        FIELD: 'devices_with_outdated_packages',
        LABEL: 'Device{0} with Outdated Packages',
        COLOR: '#C02422',
      },
    ],
    LINK: {
      LABEL: 'Manage Packages',
      URL: 'packages',
    },
  },
  RULES_GLOBAL: {
    TYPE: 'doughnut',
    FIELD: 'rule',
    KEY: 'RULES_GLOBAL',
    LABEL: 'Rules (Global)',
    CHILDREN: [
      {
        FIELD: 'IP_total_secure_rules_count',
        LABEL: 'IP Secure Rule{0}',
        COLOR: '#176BA0',
      },
      {
        FIELD: 'IP_total_unsecure_rules_count',
        LABEL: 'IP Unsecure Rule{0}',
        COLOR: '#00D5FF',
      },
      {
        FIELD: 'CAN_total_rules_count',
        LABEL: 'CAN Rule{0}',
        COLOR: '#01BB83',
      },
      {
        FIELD: 'COMMAND_total_rules_count',
        LABEL: 'COMMAND Rule{0}',
        COLOR: '#C02422',
      },
    ],
    LINK: {
      LABEL: 'Manage Rules',
      URL: 'rules',
    },
  },
  RULES_INDIVIDUAL: {
    TYPE: 'doughnut',
    FIELD: 'rule',
    KEY: 'RULES_INDIVIDUAL',
    LABEL: 'Rules (Individual)',
    CHILDREN: [
      {
        FIELD: 'IP_total_secure_rules_count',
        LABEL: 'IP Secure Rule{0}',
        COLOR: '#176BA0',
      },
      {
        FIELD: 'IP_total_unsecure_rules_count',
        LABEL: 'IP Unsecure Rule{0}',
        COLOR: '#00D5FF',
      },
      {
        FIELD: 'CAN_total_rules_count',
        LABEL: 'CAN Rule{0}',
        COLOR: '#01BB83',
      },
      {
        FIELD: 'COMMAND_total_rules_count',
        LABEL: 'COMMAND Rule{0}',
        COLOR: '#C02422',
      },
    ],
    LINK: {
      LABEL: 'Manage Rules',
      URL: 'rules',
    },
  },
  CRYPTOGRAPHIC_KEYS_KEY_ALGORITHMS: {
    TYPE: 'doughnut',
    FIELD: 'crypto_assets',
    KEY: 'CRYPTOGRAPHIC_KEYS_KEY_ALGORITHMS',
    LABEL: 'Cryptographic Keys (Key Algorithms)',
    CHILDREN: [
      {
        FIELD: 'devices_with_weak_key_algorithms',
        LABEL: 'Device{0} with weak key algorithms',
        COLOR: '#176BA0',
      },
      {
        FIELD: 'devices_without_weak_key_algorithms',
        LABEL: 'Device{0} with strong key algorithms',
        COLOR: '#00D5FF',
      },
    ],
  },
  CRYPTOGRAPHIC_KEYS_SIGNATURE_ALGORITHMS: {
    TYPE: 'doughnut',
    FIELD: 'crypto_assets',
    KEY: 'CRYPTOGRAPHIC_KEYS_SIGNATURE_ALGORITHMS',
    LABEL: 'Cryptographic Keys (Signature Algorithms)',
    CHILDREN: [
      {
        FIELD: 'devices_with_weak_signature_algorithms',
        LABEL: 'Device{0} with weak signature algorithms',
        COLOR: '#176BA0',
      },
      {
        FIELD: 'devices_without_weak_signature_algorithms',
        LABEL: 'Device{0} with strong signature algorithms',
        COLOR: '#00D5FF',
      },
    ],
  },
};

export const COMMON_LAYOUT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context: any): string => ` ${context.raw}`,
      },
    },
  },
};
