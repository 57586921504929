import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DEVICE_MANAGEMENT_FEATURES, SUPPLY_CHAIN_MANAGEMENT_FEATURES } from '@lcms-constants';
import { CaManagementService, EnvelopeService, FirmwareService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { ActionMenuItem, CommonToolbarConfiguration, CommonToolbarResult, ConfirmationDialogConfig } from '@microsec/models';
import { DELETE_LABEL, PIPE_DATETIME, SCOPE, USER_ROLE } from '@microsec/constants';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { FirmwareFormComponent } from './firmware-form/firmware-form.component';
import { CommonTableComponent } from '@microsec/components';

const FIELDS = {
  NAME: 'Name',
  TARGET_DEVICE: 'Target Device',
  VERSION: 'Version',
  UPLOADED: 'Uploaded',
};

@Component({
  selector: 'app-firmwares',
  templateUrl: './firmwares.component.html',
  styleUrls: ['./firmwares.component.scss'],
})
export class FirmwaresComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols: any[] = [
    { field: 'name', header: FIELDS.NAME, width: 10, frozen: true },
    { field: 'target_device', header: FIELDS.TARGET_DEVICE, width: 8 },
    { field: 'version', header: FIELDS.VERSION, width: 6 },
    { field: 'created_date', header: FIELDS.UPLOADED, width: 6 },
  ];

  selectedFirmware: any = null;

  values: any[] = [];

  supportedDevices: any[] = [];

  envelopeTemplates: any[] = [];

  caConnections: any[] = [];

  PIPE_DATETIME = PIPE_DATETIME;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search...',
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    public el: ElementRef,
    private envelopeSrv: EnvelopeService,
    private caManagementSrv: CaManagementService,
    private firmwareSrv: FirmwareService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.FIRMWARE)) {
      if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
        this.getQueriedItems().subscribe(() => {
          this.selectedColFields = (this.cols || []).map((col) => col.field);
          this.actionsMenuItems = [
            {
              label: 'Download',
              icon: 'fas fa-download',
              command: ({ rowData }: any) => this.downloadFirmware(rowData),
            },
            {
              label: 'Delete',
              icon: 'fas fa-trash',
              command: ({ rowData }: any) => this.openDeleteConfirmation(rowData),
            },
          ];
          this.handleFilterObjUpdate();
          this.getFirmwares();
        });
      }
    } else {
      this.router.navigate(['/not-found']);
    }
  }

  /**
   * Handle filter
   */
  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values.search || '';
      }
    });
  }

  /**
   * Unselect firmware
   * @param event
   * @param datatable
   * @returns
   */
  unselectFirmware(event: any, datatable: any) {
    if (event?.target !== datatable) {
      return;
    }
    this.selectedFirmware = null;
  }

  /**
   * Get all firmwares from project
   */
  getFirmwares() {
    this.isLoading = true;
    this.firmwareSrv
      .getFirmwares('project_id', this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.values = this.util.sortObjectArray(rs as any[], 'name').map((firmware: any) => ({
            ...firmware,
            targetDeviceName: this.supportedDevices.find((p) => p.value === firmware.target_device)?.label || firmware.target_device,
            signingEnvelopeTemplateDisplayname:
              this.envelopeTemplates.find((p) => p.value === firmware.signing_envelope_template_id)?.label || firmware.signing_envelope_template_id,
            signingEnvelopeCADisplayname:
              this.caConnections.find((p) => p.id === firmware.signing_envelope_ca_id)?.name || firmware.signing_envelope_ca_id,
          }));
          if (!!this.selectedFirmware) {
            this.selectedFirmware = this.values.find((p) => p.id === this.selectedFirmware.id);
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get supported devices, envelope templates and intermediate CAs list
   */
  getQueriedItems() {
    this.isLoading = true;
    const requests = [this.firmwareSrv.getSupportedDevices().pipe(catchError((err) => this.showErrorMessage(err)))];
    if (!!this.isEnvelopeFeatured) {
      requests.push(
        ...[
          this.envelopeSrv
            .getTemplates(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
            .pipe(catchError((err) => this.showErrorMessage(err))),
          this.caManagementSrv.getCAManagers(this.breadcrumbConfig?.projectId as number).pipe(catchError((err) => this.showErrorMessage(err))),
        ],
      );
    }
    return forkJoin(requests).pipe(
      switchMap((results: any[]) => {
        this.getSupportDevices(results[0]);
        if (!!this.isEnvelopeFeatured) {
          this.getEnvelopeTemplates(results[1]);
          this.caConnections = (results[2]?.data as any[]) || [];
        }
        return results;
      }),
      finalize(() => {
        this.isLoading = false;
      }),
    );
  }

  /**
   * Get the list of support devices
   * @param rawData
   */
  private getSupportDevices(rawData: any) {
    this.supportedDevices = ((rawData as any[]) || []).map((device: any) => ({
      ...device,
      value: device.name,
      label: device.name,
    }));
  }

  /**
   * Get the list of envelope templates
   * @param rawData
   */
  private getEnvelopeTemplates(rawData: any) {
    const templates = ((rawData?.data as any[]) || [])
      .filter(
        (p) =>
          p.scope === SCOPE.GLOBAL ||
          (p.scope === SCOPE.ORGANIZATION && p.organization_id === this.breadcrumbConfig?.organizationId) ||
          (p.scope === SCOPE.PROJECT &&
            p.organization_id === this.breadcrumbConfig?.organizationId &&
            p.project_id === this.breadcrumbConfig?.projectId),
      )
      .map((p) => ({
        value: p.id,
        label: p.name,
      }));
    this.envelopeTemplates = this.util.sortObjectArray(templates, 'label');
  }

  /**
   * Open firmware form
   */
  openFirmwareForm() {
    const dialog = this.dialogSrv.open(FirmwareFormComponent, {
      data: {
        supportedDevices: this.supportedDevices,
        envelopeTemplates: this.envelopeTemplates,
        caConnections: this.caConnections,
      },
      header: 'Upload Firmware',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getFirmwares();
      }
    });
  }

  /**
   * Handle actions: delete, download, refresh
   * @param event
   */
  handleAction(action: string) {
    switch (action) {
      case 'delete': {
        this.openDeleteConfirmation(this.selectedFirmware);
        break;
      }
      case 'download': {
        this.downloadFirmware(this.selectedFirmware);
        break;
      }
      case 'refresh': {
        this.getFirmwares();
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Open firmware removal form
   * @param firmware
   */
  openDeleteConfirmation(firmware: any = null) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Firmware',
      object: firmware,
      acceptRequest: this.firmwareSrv.deleteFirmware(firmware.id, this.breadcrumbConfig?.organizationId),
      next: () => {
        this.showSuccessMessage('Deleted firmware successfully');
        this.selectedFirmware = null;
        this.getFirmwares();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Download firmware file
   * @param firmware
   */
  downloadFirmware(firmware: any) {
    this.firmwareSrv.downloadFirmware(firmware.id).subscribe({
      next: (rs) => {
        if (!!rs) {
          this.showSuccessMessage(`Downloaded firmware ${firmware.name} successfully`);
          this.util.downloadClientFile(firmware.name, rs);
        } else {
          this.showErrorMessage(`Cannot downloaded firmware ${firmware.name}`);
        }
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Check if envelopes featured
   */
  get isEnvelopeFeatured() {
    return this.checkSupplyChainManagementFeatureEnabled(SUPPLY_CHAIN_MANAGEMENT_FEATURES.ENVELOPES);
  }
}
