import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BaseComponent } from '@lcms-components';

@Component({
  selector: 'app-ruleset-overview',
  templateUrl: './ruleset-overview.component.html',
  styleUrls: ['./ruleset-overview.component.scss'],
})
export class RulesetOverviewComponent extends BaseComponent implements AfterViewInit {
  _selectedRuleset: any = null;

  @Input() set selectedRuleset(value: any) {
    this._selectedRuleset = value;
  }

  get selectedRuleset() {
    return this._selectedRuleset;
  }

  structure: any[] = [];

  @ViewChild('enableButtonTemplate') enableButtonTemplate!: TemplateRef<any>;

  @ViewChild('exportButtonTemplate') exportButtonTemplate!: TemplateRef<any>;

  @Output() updateRulesetEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() exportRulesetEvent: EventEmitter<any> = new EventEmitter<any>();

  ngAfterViewInit() {
    this.structure = [
      { label: 'Name', name: 'name' },
      { label: 'Description', name: 'description' },
      { label: 'Enabled', field: 'custom', customField: this.enableButtonTemplate },
      { label: 'Export Ruleset', field: 'custom', customField: this.exportButtonTemplate },
    ];
  }

  updateRuleset(event: any) {
    this.updateRulesetEvent.emit(event);
  }

  exportRuleset() {
    this.exportRulesetEvent.emit(this.selectedRuleset);
  }
}
