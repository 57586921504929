<microsec-card>
  <div class="grid">
    <div class="col-12 lg:col-9">
      <microsec-form-builder
        [isPage]="true"
        #fb
        [fields]="fields"
        [shouldShowActionButtons]="true"
        [leftActionArea]="leftActionArea"
        [submitButtonLabel]="SAVE_CHANGES_LABEL"
        [cancelButtonLabel]="''"
        (submitEvent)="onSubmit()" />
    </div>
    <ng-template #leftActionArea>
      <p-button severity="secondary" styleClass="mr-2" type="button" (onClick)="getGlobalSetting()" label="Discard" />
    </ng-template>
  </div>
</microsec-card>

<!-- Events Table -->
<ng-template #eventTableField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="grid field">
        <label class="col-12 font-bold">Tick those events for which you require a user to use 2FA:</label>
        <div class="col-12">
          <microsec-common-table [cols]="cols" [values]="events" [cardBackground]="true" [cellTemplate]="cellTemplate" [paginator]="false" />
          <ng-template let-col="col" let-rowData="rowData" #cellTemplate>
            @switch (col.field) {
              <!-- isRequired -->
              @case ('isRequired') {
                @if (rowData['key'] === FORM_PARAMS.USER_LOGIN) {
                  <p-checkbox
                    [binary]="true"
                    [formControlName]="FORM_PARAMS.USER_LOGIN"
                    [disabled]="!this.form.getControlValue(FORM_PARAMS.AUTH_PROVIDER)" />
                }
                @if (rowData['key'] === FORM_PARAMS.DEVICE_ENROLLMENT) {
                  <p-checkbox
                    [binary]="true"
                    [formControlName]="FORM_PARAMS.DEVICE_ENROLLMENT"
                    [disabled]="!this.form.getControlValue(FORM_PARAMS.AUTH_PROVIDER)" />
                }
              }
              @default {
                <microsec-truncate-text>
                  {{ rowData[col.field] }}
                </microsec-truncate-text>
              }
            }
          </ng-template>
        </div>
      </div>
    </ng-container>
  }
</ng-template>
