import { AfterViewInit, Component } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { RULE_TYPE_VALUES } from '@lcms-constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-rule-form',
  templateUrl: './rule-form.component.html',
  styleUrls: ['./rule-form.component.scss'],
})
export class RuleFormComponent extends BaseComponent implements AfterViewInit {
  type = '';

  ruleset: any = null;

  rule: any = null;

  RULE_TYPE_VALUES = RULE_TYPE_VALUES;

  constructor(private dialogConfig: DynamicDialogConfig) {
    super();
  }

  ngAfterViewInit(): void {
    this.type = this.dialogConfig.data?.type;
    this.ruleset = this.dialogConfig.data?.ruleset;
    this.rule = this.dialogConfig.data?.rule || null;
  }
}
