import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { FormItem } from '@microsec/models';
import { OK_LABEL } from '@microsec/constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-access-token-detail',
  templateUrl: './access-token-detail.component.html',
  styleUrls: ['./access-token-detail.component.scss'],
})
export class AccessTokenDetailComponent extends BaseComponent implements OnInit {
  fields: FormItem[] = [];

  OK_LABEL = OK_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  ngOnInit() {
    this.fields = [
      Object.assign(new FormItem(), {
        label: "Save the access token and keep it secure. You won't be able to download it again.",
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: 'token',
        hasNoLabel: true,
        label: 'Access Token',
        field: 'copy',
        defaultValue: this.dialogConfig?.data?.token,
        focused: true,
      } as FormItem),
    ];
  }
}
