<microsec-card>
  <microsec-form-builder
    [isPage]="true"
    #fb
    [fields]="fields"
    [shouldShowActionButtons]="true"
    [submitButtonLabel]="'Edit'"
    [submitButtonStyleClass]="'p-button-primary'"
    (submitEvent)="openOrganizationForm()"
    [cancelButtonLabel]="''" />
</microsec-card>
