import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FEATURES } from '@lcms-constants';
import { LicenseService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { PIPE_DATETIME } from '@microsec/constants';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],
})
export class LicensesComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  machineId: any;

  allFeatures: any[] = [];

  license: any;

  quotas: any = {};

  instances: any = {};

  faCheck = faCheck;

  faQuestionCircle = faQuestionCircle;

  PIPE_DATETIME = PIPE_DATETIME;

  constructor(
    public route: ActivatedRoute,
    private licenseSrv: LicenseService,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.route.children.length) {
      this.getMachineID();
      this.getAllLicenses();
    }
  }

  /**
   * Get machine ID
   */
  getMachineID() {
    this.isLoading = true;
    this.licenseSrv
      .getMachineID()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          this.machineId = response.data;
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get all licenses
   */
  getAllLicenses() {
    this.isLoading = true;
    this.licenseSrv
      .getAllLicenses()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          const licenses = this.util.sortObjectArray((response?.data as any[]) || [], 'modified', false, true);
          this.license = licenses[0];
          if (!!this.license) {
            const features: any[] = [];
            Object.keys(this.license.features).forEach((key1) => {
              const featureTypes = this.license.features[key1];
              const subFeaturesTypes = featureTypes['sub-features'];
              const subFeatures: any[] = [];
              Object.keys(subFeaturesTypes).forEach((key2) => {
                subFeatures.push(subFeaturesTypes[key2]);
              });
              if (key1 === FEATURES.QUOTAS) {
                this.quotas = subFeaturesTypes;
              } else {
                features.push({
                  ...featureTypes,
                  subFeatures,
                  hidden: Object.keys(subFeaturesTypes).every((key) => subFeaturesTypes[key].hidden === true),
                });
              }
            });
            this.allFeatures = features;
          }
        },
        error: (err: any) => {
          this.license = null;
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Go to a page
   * @param target
   */
  goTo(target: string) {
    const currentUrl: string = this.router.routerState.snapshot.url;
    this.changeRoute(`${currentUrl}/${target}`);
  }
}
