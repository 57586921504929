import { Component } from '@angular/core';
import { RULE_TYPE_VALUES } from '@lcms-constants';

@Component({
  selector: 'app-yara-rules',
  templateUrl: './yara.component.html',
  styleUrls: ['./yara.component.scss'],
})
export class YaraRulesComponent {
  RULE_TYPE_VALUES = RULE_TYPE_VALUES;
}
