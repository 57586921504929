import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { SYSTEM_MANAGEMENT_FEATURES } from '@lcms-constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { OrganizationService } from '@microsec/services';
import { LDAPSettingService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { ConfirmationDialogConfig, FormItem } from '@microsec/models';
import { DISCARD_LABEL, DN_PATTERN, PORT_PATTERN, RESET_LABEL, SAVE_CHANGES_LABEL, SERVER_PATTERN, VALIDATOR_TYPE } from '@microsec/constants';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  IS_ENABLED: 'is_enabled',
  SERVER_URL: 'server_url',
  PORT: 'port',
  BASE_DN: 'base_dn',
  IS_SSL_ENABLED: 'is_ssl_enabled',
  IS_AUTO_CREATE_SYSTEM_USER: 'is_auto_create_system_user',
  DEFAULT_ORGANIZATION: 'default_organization',
  IS_ALLOW_GROUP_MAPPING: 'is_allow_group_mapping',
  AUTHENTICATION_USERNAME: 'authentication_username',
  AUTHENTICATION_PASSWORD: 'authentication_password',
  IS_SAVE_PASSWORD: 'is_save_password',
};

@Component({
  selector: 'app-ldap',
  templateUrl: './ldap.component.html',
  styleUrls: ['./ldap.component.scss'],
})
export class LdapComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @ViewChild('topTemplate') topTemplate?: TemplateRef<any>;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('testConnTemplate') testConnTemplate!: TemplateRef<any>;

  @ViewChild('testAuthTemplate') testAuthTemplate!: TemplateRef<any>;

  isLoading = false;

  buttonLoadingStatuses = {
    testConn: false,
    testAuth: false,
    reset: false,
    discard: false,
  };

  organizations: any[] = [];

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    private organizationSrv: OrganizationService,
    private ldapSettingSrv: LDAPSettingService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.store.dispatch(
      new fromLayoutActions.SetCommonLayoutConfig({
        rightArea: this.topTemplate,
      }),
    );
    if (!!this.checkSystemManagementFeatureEnabled(SYSTEM_MANAGEMENT_FEATURES.AUTH_LDAP)) {
      this.initForm();
      this.getOrganizations().subscribe(() => {
        this.isLoading = true;
        this.ldapSettingSrv
          .getLDAPSetting()
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
          .subscribe({
            next: (data: any) => {
              if (!data.default_organization) {
                data.default_organization = this.organizations[0].value;
              }
              this.form.patchValue(data);
              this.form.getControl(FORM_PARAMS.IS_ALLOW_GROUP_MAPPING)?.updateValueAndValidity();
              this.form.getControl(FORM_PARAMS.IS_AUTO_CREATE_SYSTEM_USER)?.updateValueAndValidity();
            },
            error: (err: any) => {
              this.form.showServerErrorMessage(err);
              this.showErrorMessage(err);
            },
          });
      });
    } else {
      this.navigateToNotFoundPage();
    }
  }

  override ngOnDestroy(): void {
    this.store.dispatch(
      new fromLayoutActions.SetCommonLayoutConfig({
        rightArea: null,
      }),
    );
  }

  /**
   * Get organizations
   * @returns
   */
  getOrganizations() {
    const obs = new Observable((observe) => {
      this.isLoading = true;
      this.organizationSrv
        .getOrganizations()
        .pipe(
          finalize(() => {
            this.isLoading = false;
            observe.next();
          }),
        )
        .subscribe({
          next: (rs: any[]) => {
            let organizations: any[] = [];
            organizations = rs?.map((p) => ({
              value: p.id,
              label: p.name,
            }));
            organizations = this.util.sortObjectArray(organizations, 'label');
            organizations.unshift({
              value: null,
              label: '--- Empty ---',
            });
            this.organizations = organizations;
            this.setOrganizationFieldOptions();
          },
          error: (err: any) => {
            this.showErrorMessage(err);
          },
        });
    });
    return obs;
  }

  /**
   * Initialize the form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_ENABLED,
        hasNoLabel: true,
        checkboxLabel: 'LDAP Authentication Enabled?',
        field: 'checkbox',
        defaultValue: false,
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SERVER_URL,
        label: 'LDAP Server',
        pattern: SERVER_PATTERN,
        patternErrorText: 'Please enter a valid LDAP Server',
        required: true,
        defaultValue: '',
        fieldInfo: 'LDAP server E.g. 192.168.4.83 or ldap.example.com',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PORT,
        label: 'LDAP Port',
        pattern: PORT_PATTERN,
        patternErrorText: 'Please enter a valid LDAP Port',
        required: true,
        fieldInfo: 'LDAP port',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.BASE_DN,
        label: 'Base DN',
        pattern: DN_PATTERN,
        patternErrorText: 'Please enter a valid Base DN',
        fieldInfo: 'Base DN E.g. CN=Users,DC=microsec,DC=com',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_SSL_ENABLED,
        checkboxLabel: 'LDAPS enabled?',
        field: 'checkbox',
        defaultValue: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        label: '',
        field: 'custom',
        customField: this.testConnTemplate,
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_AUTO_CREATE_SYSTEM_USER,
        hasNoLabel: true,
        checkboxLabel: 'Auto Create System Users',
        field: 'checkbox',
        defaultValue: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DEFAULT_ORGANIZATION,
        label: 'Default Organization',
        field: 'dropdown',
        required: true,
        options: [] as any[],
        defaultValue: null,
        placeholder: 'Select an organization',
        fieldInfo: 'Default organization',
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_ALLOW_GROUP_MAPPING,
        hasNoLabel: true,
        checkboxLabel: 'Allow group mapping?',
        field: 'checkbox',
        defaultValue: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.AUTHENTICATION_USERNAME,
        label: 'Administrator DN or Username',
        pattern: `${DN_PATTERN}|[\\w\\.\\-\\@]+`,
        patternErrorText: 'Please enter a valid Administrator DN or Username',
        required: true,
        fieldInfo: 'Administrator DN or username E.g. CN=Users,DC=microsec,DC=com or username',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.AUTHENTICATION_PASSWORD,
        label: 'Bind Password',
        field: 'password',
        required: true,
        fieldInfo: 'Bind password',
        feedback: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_SAVE_PASSWORD,
        hasNoLabel: true,
        checkboxLabel: 'Save Password?',
        field: 'checkbox',
        fieldInfo: 'Save Password',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: '',
        field: 'custom',
        customField: this.testAuthTemplate,
      } as FormItem),
    ];
    this.fields = fields;
    this.form.setChangeEvent(FORM_PARAMS.IS_ALLOW_GROUP_MAPPING, (value: boolean) => {
      this.form.setControlValidators(
        FORM_PARAMS.AUTHENTICATION_USERNAME,
        !!value ? [VALIDATOR_TYPE.PATTERN, VALIDATOR_TYPE.REQUIRED] : [],
        !!value ? [`^(?:${DN_PATTERN}$|^([\\w\\.\\-\\@]+))$`] : [],
      );
      this.form.setControlValidators(FORM_PARAMS.AUTHENTICATION_PASSWORD, !!value ? [VALIDATOR_TYPE.REQUIRED] : []);
      // Is save password
      if (!!value) {
        this.form.setControlValue(FORM_PARAMS.IS_SAVE_PASSWORD, true);
        this.form.disableControl(FORM_PARAMS.IS_SAVE_PASSWORD);
      } else {
        this.form.enableControl(FORM_PARAMS.IS_SAVE_PASSWORD);
      }
      // Create system user
      if (!!value) {
        this.form.setControlValue(FORM_PARAMS.IS_AUTO_CREATE_SYSTEM_USER, true);
        this.form.disableControl(FORM_PARAMS.IS_AUTO_CREATE_SYSTEM_USER);
      } else {
        this.form.enableControl(FORM_PARAMS.IS_AUTO_CREATE_SYSTEM_USER);
      }
    });
    this.form.setChangeEvent(FORM_PARAMS.IS_AUTO_CREATE_SYSTEM_USER, (value: boolean) => {
      this.form.setControlValidators(FORM_PARAMS.DEFAULT_ORGANIZATION, !!value ? [VALIDATOR_TYPE.REQUIRED] : []);
    });
  }

  /**
   * Submit form data
   */
  onSubmit() {
    this.form.isLoading = true;
    const ldapFormValue = { ...this.form.getRawValue() };
    this.ldapSettingSrv
      .save(ldapFormValue)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          this.showSuccessMessage(response?.message);
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Test ldap connection
   */
  testConnection() {
    this.isLoading = true;
    this.buttonLoadingStatuses.testConn = true;
    const ldapFormValue = { ...this.form.getRawValue() };
    const data = {
      [FORM_PARAMS.SERVER_URL]: ldapFormValue[FORM_PARAMS.SERVER_URL],
      [FORM_PARAMS.PORT]: ldapFormValue[FORM_PARAMS.PORT],
      [FORM_PARAMS.BASE_DN]: ldapFormValue[FORM_PARAMS.BASE_DN],
      [FORM_PARAMS.IS_SSL_ENABLED]: ldapFormValue[FORM_PARAMS.IS_SSL_ENABLED],
    };
    this.ldapSettingSrv
      .testConnection(data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.buttonLoadingStatuses.testConn = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          this.showSuccessMessage(response?.message);
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Test ldap authentication
   */
  testAuthentication() {
    this.isLoading = true;
    this.buttonLoadingStatuses.testAuth = true;
    const ldapFormValue = { ...this.form.getRawValue() };
    const data = {
      [FORM_PARAMS.SERVER_URL]: ldapFormValue[FORM_PARAMS.SERVER_URL],
      [FORM_PARAMS.PORT]: ldapFormValue[FORM_PARAMS.PORT],
      [FORM_PARAMS.BASE_DN]: ldapFormValue[FORM_PARAMS.BASE_DN],
      [FORM_PARAMS.IS_SSL_ENABLED]: ldapFormValue[FORM_PARAMS.IS_SSL_ENABLED],
      [FORM_PARAMS.AUTHENTICATION_USERNAME]: ldapFormValue[FORM_PARAMS.AUTHENTICATION_USERNAME],
      [FORM_PARAMS.AUTHENTICATION_PASSWORD]: ldapFormValue[FORM_PARAMS.AUTHENTICATION_PASSWORD],
    };
    this.ldapSettingSrv
      .testAuthentication(data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.buttonLoadingStatuses.testAuth = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          this.showSuccessMessage(response?.message);
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Set organization field options
   */
  setOrganizationFieldOptions() {
    const organizationField = this.fields.find((p) => p.name === FORM_PARAMS.DEFAULT_ORGANIZATION);
    if (!!organizationField) {
      organizationField.options = this.organizations;
    }
  }

  /**
   * Confirm saving
   */
  confirmSave() {
    this.confirm({
      action: SAVE_CHANGES_LABEL,
      objectName: 'LDAP Setting',
      customContent: '',
      next: () => {
        this.onSubmit();
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Discard the changes
   */
  confirmDiscard() {
    this.confirm({
      action: DISCARD_LABEL,
      objectName: 'LDAP Setting',
      shouldShowDefaultContent: false,
      customContent: '&nbsp;',
      prepareRequest: () => {
        this.isLoading = true;
        this.buttonLoadingStatuses.discard = true;
      },
      acceptRequest: this.ldapSettingSrv.getLDAPSetting().pipe(
        finalize(() => {
          this.isLoading = false;
          this.buttonLoadingStatuses.discard = false;
        }),
      ),
      next: (data: any) => {
        if (!data.default_organization) {
          if (!!this.organizations.length) {
            data.default_organization = this.organizations[0].value;
          } else {
            this.organizations.unshift({
              value: null,
              label: '--- Empty ---',
            });
          }
        }
        this.setOrganizationFieldOptions();
        this.form.patchValue(data);
        this.form.getControl(FORM_PARAMS.IS_ALLOW_GROUP_MAPPING)?.updateValueAndValidity();
        this.form.getControl(FORM_PARAMS.IS_AUTO_CREATE_SYSTEM_USER)?.updateValueAndValidity();
        this.showSuccessMessage('Discarded changes successfully');
      },
      error: (err: any) => {
        this.form.showServerErrorMessage(err);
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Reset the changes
   */
  confirmReset() {
    this.confirm({
      action: RESET_LABEL,
      objectName: 'LDAP Setting',
      shouldShowDefaultContent: false,
      customContent: '&nbsp;',
      prepareRequest: () => {
        this.isLoading = true;
        this.buttonLoadingStatuses.reset = true;
      },
      acceptRequest: this.ldapSettingSrv.reset().pipe(
        finalize(() => {
          this.isLoading = false;
          this.buttonLoadingStatuses.reset = false;
        }),
      ),
      next: (data: any) => {
        if (!data.default_organization) {
          if (!!this.organizations.length) {
            data.default_organization = this.organizations[0].value;
          } else {
            this.organizations.unshift({
              value: null,
              label: '--- Empty ---',
            });
          }
        }
        this.form.patchValue(data);
        this.form.getControl(FORM_PARAMS.IS_ALLOW_GROUP_MAPPING)?.updateValueAndValidity();
        this.form.getControl(FORM_PARAMS.IS_AUTO_CREATE_SYSTEM_USER)?.updateValueAndValidity();
        this.showSuccessMessage('The LDAP Setting has been reset successfully');
      },
      error: (err: any) => {
        this.form.showServerErrorMessage(err);
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }
}
