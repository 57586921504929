import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MODULE_CONSTANTS } from '@microsec/constants';
import { LicenseService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { GLOBAL_SETTINGS_CONSTANTS } from '@lcms-constants';
import { validatorTrimRequired } from '@microsec/validators';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  OPTION: 'option',
  FILE: 'file',
  KEY: 'key',
  IS_AGREE: 'is_agree',
};

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent extends BaseComponent implements OnInit {
  isLoading = false;

  FORM_PARAMS = FORM_PARAMS;

  uploadForm!: FormGroup;

  constructor(
    public route: ActivatedRoute,
    private fb: FormBuilder,
    private licenseSrv: LicenseService,
  ) {
    super();
  }

  ngOnInit() {
    this.initFormData();
  }

  /**
   * Initialize form data
   */
  initFormData() {
    this.uploadForm = this.fb.group({
      [FORM_PARAMS.OPTION]: ['file'],
      [FORM_PARAMS.FILE]: [null, Validators.required],
      [FORM_PARAMS.KEY]: [''],
      [FORM_PARAMS.IS_AGREE]: [false],
    });
    this.uploadForm.get(FORM_PARAMS.OPTION)?.valueChanges.subscribe((value) => {
      this.uploadForm.patchValue({
        [FORM_PARAMS.FILE]: null,
        [FORM_PARAMS.KEY]: '',
      });
      this.uploadForm.get(FORM_PARAMS.FILE)?.setValidators(value === 'file' ? [Validators.required] : [Validators.nullValidator]);
      this.uploadForm.get(FORM_PARAMS.KEY)?.setValidators(value === 'key' ? [validatorTrimRequired] : [Validators.nullValidator]);
      this.uploadForm.get(FORM_PARAMS.FILE)?.updateValueAndValidity();
      this.uploadForm.get(FORM_PARAMS.KEY)?.updateValueAndValidity();
    });
  }

  /**
   * Submit form
   */
  onSubmit() {
    this.isLoading = true;
    let file;
    const fd = new FormData();
    if (this.uploadForm.value[FORM_PARAMS.OPTION] === 'file') {
      file = this.uploadForm.value[FORM_PARAMS.FILE];
    } else {
      const blob: any = new Blob([this.uploadForm.value[FORM_PARAMS.KEY]], {
        type: '',
      });
      blob.lastModifiedDate = new Date();
      blob.name = 'file.license';
      file = <File>blob;
    }
    fd.append('file', file);
    this.licenseSrv
      .uploadLicense(fd)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Uploaded License successfully');
          this.changeRoute(`${MODULE_CONSTANTS.GLOBAL_SETTINGS.ROUTE}/${GLOBAL_SETTINGS_CONSTANTS.LICENSES.ROUTE}`, false, true);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Set the file into the saved variables
   * @param event
   */
  uploadFile(event: any) {
    const validatedFile = this.getValidatedFile(event);
    this.uploadForm.patchValue({
      [FORM_PARAMS.FILE]: validatedFile,
    });
  }

  /**
   * Validate the file with correct format
   * @param event
   * @returns
   */
  private getValidatedFile(event: any) {
    if (!!event.target && !!event.target.files && !!event.target.files.length) {
      const file = (event.target.files as FileList).item(0);
      return file;
    }
    this.uploadForm.get(FORM_PARAMS.FILE)?.markAllAsTouched();
    return null;
  }
}
