import { AfterViewInit, Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { DEVICE_STATUS_TAGS } from '@lcms-constants';
import { devicesSelectors } from '@ngrx-devices';
import { SharedCertificateDetailsComponent } from '@lcms-products';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { ActionMenuItem, FormItem } from '@microsec/models';
import { PIPE_DATETIME, PIPELINE_TYPE } from '@microsec/constants';
import { DeviceLabelsFormComponent } from './device-labels-form/device-labels-form.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

const FORM_PARAMS = {
  COMMON_NAME: 'common_name',
  STATUS: 'status',
  TYPE: 'type',
  LABELS: 'labels',
  CHASSIS_TYPE: 'chassis_type',
  OS: 'os',
  MANUFACTURER: 'manufacturer',
  MODEL: 'model',
  LAST_SEEN: 'last_seen',
  COMMISSION_DATE: 'commission_date',
  IMEI: 'imei',
  IP: 'ip',
  SIM_ID: 'sim_id',
  KEYRING: 'keyring_id',
  KEY: 'key_id',
};

const COLS = [
  { field: 'status', header: 'Status', width: 10, frozen: true },
  { field: 'hexSerialNumber', header: 'Serial Number', width: 15 },
  { field: 'not_after', header: 'Expiry Date', width: 15 },
];

@Component({
  selector: 'app-device-overview',
  templateUrl: './device-overview.component.html',
  styleUrls: ['./device-overview.component.scss'],
})
export class DeviceOverviewComponent extends BaseComponent implements AfterViewInit {
  _selectedDevice: any = null;

  isLoading = false;

  @Input() labels: any[] = [];

  selectedLabels: any[] = [];

  get selectedDevice() {
    return this._selectedDevice;
  }

  set selectedDevice(value: any) {
    this._selectedDevice = this.util.cloneDeepObject(value);
    this._selectedDevice.statusText = this.getStatusProperty('label');
    this._selectedDevice.statusColor = this.getStatusProperty('color');
    this._selectedDevice.statusTooltip = this.getStatusProperty('tooltip');
    this.values =
      (this._selectedDevice?.certs as any[])
        ?.sort((a: any, b: any) => a.id - b.id)
        .map((c) => ({
          ...c,
          hexSerialNumber: this.util.convertToHexadecimal(c.serial_number),
        })) || [];
    this.commissionDate = !!this.values?.length ? this.values[0].not_before : null;
    this.selectedLabels = this.selectedDevice.labels;
  }

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  COLS = COLS;

  cols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.cols = COLS.filter((col) => value?.includes(col.field));
  }

  values: any[] = [];

  commissionDate: any;

  actionMenuItems: ActionMenuItem[] = [];

  @ViewChild('statusTemplate') statusTemplateRef: TemplateRef<any>;

  @ViewChild('labelsTemplate') labelsTemplateRef: TemplateRef<any>;

  PIPE_DATETIME = PIPE_DATETIME;

  constructor(
    public el: ElementRef,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.selectedColFields = [...COLS.map((col) => col.field)];
    this.actionMenuItems = [
      {
        label: 'View',
        icon: 'fas fa-eye',
        command: ({ rowData }: any) => this.openCertDetailModal(rowData),
      },
    ];
    this.store.select(devicesSelectors.selectedDevice).subscribe((selectedDevice) => {
      if (!!selectedDevice) {
        this.selectedDevice = selectedDevice;
        this.initForm();
      }
    });
    this.store.select(devicesSelectors.isLoading).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }

  /**
   * Initialize a form
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COMMON_NAME,
        label: 'Common Name',
        field: 'label',
        defaultValue: this.selectedDevice?.common_name,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.STATUS,
        label: 'Status',
        field: 'custom',
        customField: this.statusTemplateRef,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        label: 'Type',
        field: 'label',
        defaultValue: this.selectedDevice?.device_type,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABELS,
        label: 'Labels',
        field: 'custom',
        customField: this.labelsTemplateRef,
      } as FormItem),
      ...(!!this.selectedDevice?.uses_agent
        ? [
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.CHASSIS_TYPE,
              label: 'Chassis Type',
              field: 'label',
              defaultValue: this.selectedDevice?.[FORM_PARAMS.CHASSIS_TYPE] || '-',
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.OS,
              label: 'OS',
              field: 'label',
              defaultValue: this.selectedDevice?.[FORM_PARAMS.OS] || '-',
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.MANUFACTURER,
              label: 'Manufacturer',
              field: 'label',
              defaultValue: this.selectedDevice?.[FORM_PARAMS.MANUFACTURER] || '-',
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.MODEL,
              label: 'Model',
              field: 'label',
              defaultValue: this.selectedDevice?.[FORM_PARAMS.MODEL] || '-',
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.IMEI,
              label: 'IMEI',
              field: 'label',
              defaultValue: this.selectedDevice?.[FORM_PARAMS.IMEI] || '-',
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.IP,
              label: 'IP Address',
              field: 'label',
              defaultValue: this.selectedDevice?.[FORM_PARAMS.IP] || '-',
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.SIM_ID,
              label: 'SIM ID',
              field: 'label',
              defaultValue: this.selectedDevice?.[FORM_PARAMS.SIM_ID] || '-',
            } as FormItem),
          ]
        : []),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LAST_SEEN,
        label: 'Last Seen',
        field: 'label',
        defaultValue: this.selectedDevice?.last_seen,
        pipeline: PIPELINE_TYPE.DATETIME,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COMMISSION_DATE,
        label: 'Commission Date',
        field: 'label',
        defaultValue: this.commissionDate,
        pipeline: PIPELINE_TYPE.DATETIME,
      } as FormItem),
    ];
  }

  /**
   * Open dialog for assign labels for device
   * @param device
   */
  openDeviceLabelsForm(device: any) {
    this.dialogSrv.open(DeviceLabelsFormComponent, {
      data: {
        labels: this.labels,
        device: device,
      },
      header: 'Edit Device Label(s)',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }

  /**
   * Open certificate dialog
   * @param cert
   */
  openCertDetailModal(cert: any) {
    this.dialogSrv.open(SharedCertificateDetailsComponent, {
      data: { cert, dialogRef: this.dialogRef },
      header: 'Certificate Details',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }

  /**
   * Get status properties
   * @param property
   * @returns
   */
  getStatusProperty(property: string) {
    if (!!this.selectedDevice?.status) {
      const result = DEVICE_STATUS_TAGS.find((p) => p.value === this.selectedDevice.status);
      return (result as any)?.[property];
    }
    return '';
  }
}
