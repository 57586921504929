import { Component, OnInit, ViewChild } from '@angular/core';
import { LabelService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { ActionMenuItem, CommonToolbarConfiguration, CommonToolbarResult, ConfirmationDialogConfig } from '@microsec/models';
import { USER_ROLE, DELETE_LABEL, SCOPE } from '@microsec/constants';
import { BehaviorSubject, finalize } from 'rxjs';
import { LabelFormComponent } from './label-form/label-form.component';
import { CommonTableComponent } from '@microsec/components';

const FIELDS = {
  LABEL: 'Label',
  DESCRIPTION: 'Description',
  SCOPE: 'Scope',
  COLOR: 'Color',
};

@Component({
  selector: 'app-shared-labels',
  templateUrl: './shared-labels.component.html',
  styleUrls: ['./shared-labels.component.scss'],
})
export class SharedLabelsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols: any[] = [
    { field: 'name', header: FIELDS.LABEL, width: 10 },
    { field: 'description', header: FIELDS.DESCRIPTION, width: 15 },
    { field: 'scope', header: FIELDS.SCOPE, width: 10 },
    { field: 'color', header: FIELDS.COLOR, width: 8 },
  ];

  values: any[] = [];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search...',
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(private labelSrv: LabelService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.hasPermission,
        command: ({ rowData }: any) => this.openForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.hasPermission,
        command: ({ rowData }: any) => this.openDeleteConfirmation(rowData),
      },
    ];
    this.handleFilterObjUpdate();
    this.getLabels();
  }

  /**
   * Handle filter
   */
  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values.search || '';
      }
    });
  }

  /**
   * Get labels
   */
  getLabels() {
    this.isLoading = true;
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.labelSrv
      .getLabels(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const labels: any[] = this.util.sortObjectArray(this.util.sortObjectArray(rs, 'name'), 'scope');
          this.values = labels;
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Open form
   * @param label
   */
  openForm(label?: any) {
    const dialog = this.dialogSrv.open(LabelFormComponent, {
      data: {
        label: label,
      },
      header: !label ? 'Create Label' : 'Edit Label',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getLabels();
      }
    });
  }

  /**
   * Confirm deletion
   * @param label
   */
  openDeleteConfirmation(label: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Label',
      object: label,
      objectFieldName: 'name',
      acceptRequest: this.labelSrv.deleteLabel(label.id),
      next: () => {
        this.showSuccessMessage(`Deleted label ${label.name} successfully`);
        this.getLabels();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Check if has permission
   */
  get hasPermission() {
    let result = false;
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        result = (this.permissions as any)[SCOPE.ORGANIZATION][USER_ROLE.ADMIN];
        break;
      }
      case SCOPE.PROJECT: {
        result = (this.permissions as any)[SCOPE.PROJECT][USER_ROLE.ADMIN];
        break;
      }
      default: {
        break;
      }
    }
    return result;
  }
}
