<microsec-form-builder [isPage]="true" #fb [fields]="fields" [shouldShowActionButtons]="false" />

<div class="grid">
  <!-- Bottom panel -->
  <div class="col-12">
    <div class="flex align-items-center justify-content-between">
      <div class="font-bold text-data">Issued Certificates</div>
    </div>
  </div>
  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        [cols]="cols"
        [values]="values"
        itemName="Certificate"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionMenuItems"
        [selectable]="false"
        [containerHeaderRightTemplate]="rightTemplate" />
    </microsec-card>
  </div>
</div>

<ng-template #rightTemplate>
  <div class="flex align-items-center">
    <microsec-table-column-filter [key]="el.nativeElement.tagName" [cols]="COLS" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Expiry Date -->
    @case ('not_after') {
      <span [title]="rowData[col.field] | date: PIPE_DATETIME">
        {{ rowData[col.field] | date: PIPE_DATETIME }}
      </span>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
  }
</ng-template>

<ng-template #statusTemplate>
  <div
    [pTooltip]="selectedDevice?.statusTooltip"
    tooltipPosition="bottom"
    appendTo="body"
    [style]="{ color: selectedDevice?.statusColor + '!important' }"
    class="pt-2">
    {{ selectedDevice?.statusText }}
  </div>
</ng-template>

<ng-template #labelsTemplate>
  <div class="lex flex-wrap align-items-center">
    @for (lbl of selectedDevice?.labels; track $index) {
      <app-device-status-block [config]="lbl" [pTooltip]="lbl.description" tooltipPosition="bottom" />
    }
    @if (!!permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN]) {
      <p-button
        severity="secondary"
        size="small"
        [autofocus]="true"
        [ngClass]="{ 'ml-2': !!selectedDevice?.labels }"
        icon="fa fa-edit"
        tooltipPosition="bottom"
        appendTo="body"
        (onClick)="openDeviceLabelsForm(selectedDevice)"
        pTooltip="Edit Labels" />
    }
  </div>
</ng-template>
