import { Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { deviceSummarySelectors } from '@ngrx-device-summary';
import { CommonChartComponent } from '@microsec/components';
import { BaseComponent } from '@lcms-components';
import { ChartLink, CommonChart } from '@microsec/models';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { COMMON_LAYOUT_OPTIONS, DIAGRAMS } from './security.config';

@Component({
  selector: 'app-devices-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
})
export class SecurityComponent extends BaseComponent implements OnInit, OnDestroy {
  summary: any = null;

  /**
   * Chart data + options
   */
  charts: CommonChart[] = [];

  /**
   * Diagram constants
   */
  DIAGRAMS = DIAGRAMS;

  /**
   * Diagram UIs
   */
  @ViewChildren('diagram') diagrams!: CommonChartComponent[];

  packageDevices: { [key: string]: any } = {};

  async ngOnInit() {
    await this.prepareConfigs();
    this.subscriptions.push(
      this.store.select(deviceSummarySelectors.summary).subscribe((summary) => {
        this.summary = summary;
        this.getPackageDevices();
        this.initData();
      }),
    );
  }

  /**
   * Initialize data
   */
  async initData() {
    await this.prepareConfigs();
    if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
      this.initTemplates();
      // Draw charts
      this.charts.forEach((chart) => {
        this.updateChartData(chart);
      });
      this.redrawDiagrams();
    }
  }

  /**
   * Redraw the diagram UIs
   */
  private redrawDiagrams() {
    if (!!this.diagrams) {
      this.diagrams.forEach((diagram) => {
        setTimeout(() => {
          diagram.redraw();
        });
      });
    }
  }

  /**
   * Build up the security objects
   */
  private initTemplates() {
    // charts
    const charts: any[] = [];
    Object.entries(DIAGRAMS).forEach(([key, value]) => {
      const chart = this.util.cloneDeepObject(
        {
          type: value.TYPE,
          key,
          label: value.LABEL,
          data: {} as ChartData,
          options: this.util.cloneDeepObject(COMMON_LAYOUT_OPTIONS) as ChartOptions,
          children: this.util.cloneObjectArray(value.CHILDREN, true),
          link: !!(value as any).LINK
            ? {
                ...(value as any).LINK,
                URL: this.getLink((value as any)?.LINK?.URL),
              }
            : null,
        },
        true,
      ) as CommonChart;
      charts.push(chart);
    });
    this.charts = charts;
  }

  /**
   * Get link
   * @param itemUrl
   * @returns
   */
  private getLink(itemUrl: string) {
    const currentUrlArr = this.router.routerState?.snapshot?.url?.split('/');
    const rootUrlArr = currentUrlArr.filter((p, i) => i !== currentUrlArr.length - 1 && i !== currentUrlArr.length - 2);
    return `${rootUrlArr.join('/')}/${itemUrl}`;
  }

  /**
   * Get package devices
   */
  getPackageDevices() {
    const packageDevices: any = {};
    if (!!this.summary?.packages) {
      Object.keys(this.summary?.packages).forEach((key) => {
        if (!!this.summary?.packages[key]) {
          const devices: any[] = ((this.summary?.packages[key] as any[]) || []).map((p) => p.label || `Unknown device ${p.id}`);
          packageDevices[key] = devices;
        }
      });
      this.packageDevices = packageDevices;
    }
  }

  /**
   * Generate the data
   * @param chart chart
   */
  updateChartData(chart: any) {
    const data = chart.data as ChartData;
    data.labels = [];
    data.datasets = [
      {
        data: this.generateData(chart),
        backgroundColor: [],
        borderWidth: [],
      } as ChartDataset,
    ];
    this.generateLabels(chart, data);
  }

  /**
   * Generate the chart labels
   * @param chart
   * @param data
   */
  private generateLabels(chart: any, data: ChartData) {
    (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any) => {
      // Labels
      data.labels?.push(item.LABEL);
      const dataset = data.datasets.find((p) => !!p) as ChartDataset;
      // Colors
      if (!!dataset && !!dataset.backgroundColor) {
        (dataset.backgroundColor as string[]).push(item.COLOR);
        (dataset.borderWidth as number[]).push(0);
      }
    });
  }

  /**
   * Generate the chart data
   * @param chart
   * @returns
   */
  private generateData(chart: any): number[] {
    const values: number[] = [];
    const parentField: string = (DIAGRAMS as any)[chart.key].FIELD;
    switch (chart.key) {
      case DIAGRAMS.DEVICE_MANAGEMENT.KEY: {
        (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any, index: number) => {
          if (!index) {
            values.push(this.summary?.[parentField]?.[item.FIELD]);
          } else {
            values.push(this.summary?.[parentField]?.[item.FIELD]?.length);
          }
        });
        break;
      }
      case DIAGRAMS.DEVICES_PACKAGE.KEY: {
        (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any) => {
          values.push(this.summary?.[parentField]?.[item.FIELD]?.length);
        });
        break;
      }
      case DIAGRAMS.RULES_GLOBAL.KEY: {
        (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any) => {
          const globalRules = this.summary?.[parentField]?.global;
          const ruleFieldArr = ((item.FIELD as string) || '').split('_');
          const ruleCountField = ruleFieldArr.filter((p, index) => !!index).join('_');
          values.push(globalRules?.[ruleFieldArr?.[0]]?.[ruleCountField]);
        });
        break;
      }
      case DIAGRAMS.RULES_INDIVIDUAL.KEY: {
        (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any) => {
          const individualRules = this.summary?.[parentField]?.individual;
          const ruleFieldArr = ((item.FIELD as string) || '').split('_');
          const ruleCountField = ruleFieldArr.filter((p, index) => !!index).join('_');
          values.push(individualRules?.[ruleFieldArr?.[0]]?.[ruleCountField]);
        });
        break;
      }
      case DIAGRAMS.CRYPTOGRAPHIC_KEYS_KEY_ALGORITHMS.KEY: {
        (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any) => {
          values.push(this.summary?.[parentField]?.[item.FIELD]?.length);
        });
        break;
      }
      case DIAGRAMS.CRYPTOGRAPHIC_KEYS_SIGNATURE_ALGORITHMS.KEY: {
        (DIAGRAMS as any)[chart.key].CHILDREN.forEach((item: any) => {
          values.push(this.summary?.[parentField]?.[item.FIELD]?.length);
        });
        break;
      }
      default: {
        break;
      }
    }
    return values;
  }

  /**
   * Get legend value
   */
  getLegendValue(chart: any, index: number) {
    return !!chart?.data?.datasets && !!chart?.data?.datasets?.length ? chart?.data?.datasets[0].data[index] : 0;
  }

  /**
   * Get total value
   */
  getTotalValue(chart: any) {
    return !!chart?.data?.datasets && !!chart?.data?.datasets?.length ? chart?.data?.datasets[0].data.reduce((a: any, b: any) => a + b, 0) : 0;
  }

  /**
   * Get legend label
   * @param chart
   * @param index
   * @param label
   * @returns
   */
  getLegendLabel(value: any, label: string) {
    return value === 1 ? label.replace('Devices', 'Device') : label;
  }

  /**
   * Check if has data
   * @param object
   * @returns
   */
  checkHasData(object: any) {
    if (!!object) {
      return !Object.values(object).every((item: any) => !item.length);
    }
    return false;
  }

  /**
   * Open the link provided for the chart
   * @param link
   * @returns
   */
  openLink(link: ChartLink) {
    if (!!link.url) {
      if (link.url[0] === '/') {
        this.changeRoute(link.url);
        return;
      }
      window.location.assign(link.url);
      return;
    }
    this.showInfoMessage('Coming soon ...');
  }
}
