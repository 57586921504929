<div class="grid">
  <div class="col-12">
    <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)" [leftTemplate]="leftTemplate">
      <ng-template #leftTemplate>
        <p-button severity="primary" [rounded]="true" label="Connect to Manager" icon="pi pi-plus" (onClick)="openCryptokenManagerForm()" />
      </ng-template>
    </microsec-common-toolbar>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-4 max-height-60': !!selectedManager }" #datatable (click)="unselectManager($event, datatable)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [(selection)]="selectedManager"
        itemName="Cryptoken Manager"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>

  @if (!!selectedManager) {
    <div class="col-12 lg:col-8 max-height-40">
      <app-cryptoken-manager-info
        [(selectedManager)]="selectedManager"
        (openCryptokenManagerFormEvent)="openCryptokenManagerForm($event)"
        (confirmDeletionEvent)="confirmDeletion($event)"
        (refreshCryptokenManagerEvent)="refreshCryptokenManager($event)" />
    </div>
  }
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getCryptokenManagers()" />
    <microsec-table-column-filter [key]="constructor.name" [cols]="cols" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <!-- Connection Status -->
  @switch (col.field) {
    @case ('connection_status') {
      <microsec-truncate-text>
        {{ util.getUppercaseFirstLetter(rowData[col.field]) }}
      </microsec-truncate-text>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
