export const GRANULARITY_MINUTE = [
  { value: '1 sec', label: '1 second' },
  { value: '5 sec', label: '5 seconds' },
  { value: '15 sec', label: '15 seconds' },
];

export const GRANULARITY_HOUR = [
  { value: '1 min', label: '1 minute' },
  { value: '5 min', label: '5 minutes' },
  { value: '15 min', label: '15 minutes' },
];

export const GRANULARITY_DAY = [{ value: '1 hour', label: '1 hour' }];

export const GRANULARITY_MONTH = [{ value: '1 day', label: '1 day' }];
