import { faBuilding, faKey, faLock, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { USER_SETTINGS_CONSTANTS, USER_SETTINGS_GROUPS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { AccessTokensComponent } from './developer/access-tokens/access-tokens.component';
import { Config2faComponent } from './preferences/config2fa/config2fa.component';
import { OrganizationsComponent } from './preferences/organizations/organizations.component';
import { PasswordComponent } from './preferences/password/password.component';

export const USER_SETTINGS_CHILDREN: ILayoutItem[] = [
  {
    group: USER_SETTINGS_GROUPS.PREFERENCES,
    route: USER_SETTINGS_CONSTANTS.ORGANIZATIONS.ROUTE,
    icon: faBuilding,
    label: USER_SETTINGS_CONSTANTS.ORGANIZATIONS.LABEL,
    component: OrganizationsComponent,
  },
  {
    group: USER_SETTINGS_GROUPS.PREFERENCES,
    route: USER_SETTINGS_CONSTANTS.CONFIG_2FA.ROUTE,
    icon: faUserCog,
    label: USER_SETTINGS_CONSTANTS.CONFIG_2FA.LABEL,
    component: Config2faComponent,
    visible: false,
  },
  {
    group: USER_SETTINGS_GROUPS.PREFERENCES,
    route: USER_SETTINGS_CONSTANTS.PASSWORD.ROUTE,
    icon: faLock,
    label: USER_SETTINGS_CONSTANTS.PASSWORD.LABEL,
    component: PasswordComponent,
  },
  {
    group: USER_SETTINGS_GROUPS.DEVELOPER,
    route: USER_SETTINGS_CONSTANTS.ACCESS_TOKENS.ROUTE,
    icon: faKey,
    label: USER_SETTINGS_CONSTANTS.ACCESS_TOKENS.LABEL,
    component: AccessTokensComponent,
  },
];
