<microsec-card>
  <microsec-loading-overlay [isLoading]="!!isLoading">
    @if (!!uploadForm) {
      <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" class="no-wrapper dark-form">
        <div class="p-fluid">
          <div class="field">Please select the option to upload the license file</div>

          <!-- Upload option -->
          <div class="field grid">
            <div class="col-2"></div>
            <div class="col-fixed field-radiobutton">
              <p-radioButton [autofocus]="true" name="option" value="file" [formControlName]="FORM_PARAMS.OPTION" />
              <label for="uploadFIle">Upload .msec_license file</label>
            </div>
          </div>
          <div class="field grid">
            <div class="col-2"></div>
            <div class="col-fixed field-radiobutton">
              <p-radioButton name="option" value="key" [formControlName]="FORM_PARAMS.OPTION" />
              <label>Enter license key</label>
            </div>
          </div>

          <!-- License File/Key -->
          <div class="field grid align-items-start">
            <label for="licenseFile" class="col-2">License File</label>
            @if (uploadForm.value[FORM_PARAMS.OPTION] === 'file') {
              <div class="col grid">
                <div class="col-4">
                  <input
                    type="text"
                    pInputText
                    [disabled]="true"
                    [value]="!!uploadForm.value[FORM_PARAMS.FILE] ? uploadForm.value[FORM_PARAMS.FILE]?.name : 'No file selected'" />
                </div>
                <microsec-upload-file-button (uploadEvent)="uploadFile($event)" />
              </div>
            }
            @if (uploadForm.value[FORM_PARAMS.OPTION] === 'key') {
              <div class="col">
                <textarea [rows]="10" pInputTextarea id="licenseFile" [formControlName]="FORM_PARAMS.KEY"></textarea>
              </div>
            }
          </div>

          <!-- Agreement -->
          <div class="field grid">
            <div class="col-2"></div>
            <div class="col-fixed field-checkbox">
              <p-checkbox [binary]="true" [formControlName]="FORM_PARAMS.IS_AGREE" />
              <label for="is_agree">By uploading this license, I agree to comply with the Terms and Condition</label>
            </div>
          </div>

          <!-- Submit -->
          <div class="field grid">
            <div class="col-2"></div>
            <p-button
              severity="primary"
              size="small"
              class="col-fixed"
              label="Upload"
              type="submit"
              [disabled]="!uploadForm || !uploadForm.valid || !uploadForm.value[FORM_PARAMS.IS_AGREE]" />
          </div>
        </div>
      </form>
    }
  </microsec-loading-overlay>
</microsec-card>
