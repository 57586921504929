<div class="grid">
  <!-- Top panel -->
  <div class="col-12">
    <microsec-form-builder [isPage]="true" #fb [fields]="fields" [shouldShowActionButtons]="false" />
  </div>

  <!-- Bottom panel -->
  @if (!!selectedManager?.certs?.length) {
    <div class="col-12">
      <div class="flex align-items-center justify-content-between">
        <div class="text-data font-bold">Issued Certificates</div>
      </div>
    </div>
    <div class="col-12 legend">
      <microsec-card [isTableCard]="true">
        <microsec-common-table
          [cols]="cols"
          [values]="values"
          itemName="Certificate"
          [cellTemplate]="cellTemplate"
          [actionsMenuItems]="actionMenuItems"
          [selectable]="false"
          [containerHeaderRightTemplate]="rightTemplate" />
      </microsec-card>
    </div>
  }
</div>

<ng-template #rightTemplate>
  <div class="flex align-items-center">
    <microsec-table-column-filter [key]="el.nativeElement.tagName" [cols]="COLS" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Issue Date -->
    @case ('not_before') {
      <span [title]="rowData[col.field] | date: PIPE_DATETIME">
        {{ rowData[col.field] | date: PIPE_DATETIME }}
      </span>
    }
    <!-- Expiry Date -->
    @case ('not_after') {
      <span [title]="rowData[col.field] | date: PIPE_DATETIME">
        {{ rowData[col.field] | date: PIPE_DATETIME }}
      </span>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    }
  }
</ng-template>

<ng-template #statusTemplate>
  <div
    [pTooltip]="selectedManager?.statusTooltip"
    tooltipPosition="bottom"
    appendTo="body"
    [style]="{ color: selectedManager?.statusColor + '!important' }"
    class="pt-2">
    {{ selectedManager?.statusText }}
  </div>
</ng-template>
