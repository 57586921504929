import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { Store } from '@ngrx/store';
import { UserInboxService } from '@microsec/services';
import { MICROSEC_LCMS_RA } from '@microsec/constants';
import { MODULES } from './products/modules';
import { BaseAppComponent } from '@microsec/components';
import { BaseAppComponent as WrapperBaseAppComponent } from '@lcms-components';
import { LogService } from '@lcms-services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends WrapperBaseAppComponent implements OnInit, AfterViewInit {
  @ViewChild('baseApp') baseApp!: BaseAppComponent;

  constructor(
    private store: Store,
    private userInboxSrv: UserInboxService,
    titleSrv: Title,
    logSrv: LogService,
  ) {
    super(titleSrv, logSrv);
    // Others
    this.titleSrv.setTitle(MICROSEC_LCMS_RA);
    this.store.dispatch(new fromLayoutActions.SetModules(MODULES));
  }

  ngAfterViewInit(): void {
    this.baseApp.sidebarDisplayCallback = this.checkInboxDisplay;
  }

  /**
   * Check and show the Inbox
   */
  checkInboxDisplay() {
    // setTimeout(async () => {
    //   const isUserInboxLoaded = await firstValueFrom(this.store.select(layoutSelectors.isUserInboxLoaded));
    //   if (
    //     !isUserInboxLoaded &&
    //     !!this.checkSupplyChainManagementFeatureEnabled(SUPPLY_CHAIN_MANAGEMENT_FEATURES.ENVELOPE_SHARING)
    //   ) {
    //     Get user inbox items - to be implement later
    //     this.userInboxSrv.getUserInboxItems().subscribe({
    //       next: (rs: any[]) => {
    //         const userInboxItems = rs.filter((p) => !p.is_seen);
    //         if (!!userInboxItems.length) {
    //           this._toastSrv.add({
    //             severity: 'warn', summary: 'Inbox', life: 5000,
    //             detail: `You have ${userInboxItems.length}`
    //               + ` unread message${(userInboxItems.length <= 1) ? '' : 's'}. Please check your inbox.`,
    //           });
    //         }
    //       },
    //       error: (err) => {
    //         window.microsec.error(err);
    //         this._toastSrv.add({
    //           severity: 'error', summary: 'Inbox', life: 3000,
    //           detail: 'Cannot get your inbox messages',
    //         });
    //       },
    //     });
    //     this.store.dispatch(new fromLayoutActions.SetUserInboxLoaded(true));
    //   }
    // });
  }
}
