import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  @Input() page: string;

  @Output() pageChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Go to a page
   * @param page
   */
  goTo(page: string) {
    this.pageChange.emit(page);
  }
}
