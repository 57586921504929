import { Component, OnInit, ViewChild } from '@angular/core';
import { LDAPSettingService } from '@lcms-services';
import { RoleService } from '@microsec/services';
import { BaseComponent } from '@lcms-components';
import { CommonTableComponent } from '@microsec/components';
import { ActionMenuItem, CommonToolbarConfiguration, CommonToolbarResult, ConfirmationDialogConfig } from '@microsec/models';
import { REMOVE_LABEL, SCOPE, USER_ROLE } from '@microsec/constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LDAPGroupFormComponent } from './ldap-group-form/ldap-group-form.component';

const FIELDS = {
  LDAP_CN: 'LDAP CN',
  DEFAULT_ROLE: 'Default Role',
};

@Component({
  selector: 'app-ldap-settings',
  templateUrl: './ldap-settings.component.html',
  styleUrls: ['./ldap-settings.component.scss'],
})
export class LDAPSettingsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols: any[] = [
    { field: 'group_dn', header: FIELDS.LDAP_CN, width: 20, frozen: true },
    { field: 'project_role', header: FIELDS.DEFAULT_ROLE, width: 8 },
  ];

  values: any[] = [];

  roles: any[] = [];

  LDAPSetting: any = {};

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search...',
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private ldapSrv: LDAPSettingService,
    private roleSrv: RoleService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.ldapSrv
      .getLDAPSetting()
      .pipe(
        finalize(() => {
          if (!!this.checkPermissionsByScope(USER_ROLE.ADMIN, true)) {
            if (this.currentScope === SCOPE.ORGANIZATION && this.LDAPSetting?.is_enabled && this.LDAPSetting?.is_allow_group_mapping) {
              this.getRoles().subscribe(() => {
                this.selectedColFields = (this.cols || []).map((col) => col.field);
                this.actionsMenuItems = [
                  {
                    label: 'Remove Link',
                    icon: 'fas fa-users-slash',
                    visible: () => !!this.permissions[this.SCOPE.ORGANIZATION][this.USER_ROLE.ADMIN],
                    command: ({ rowData }: any) => this.confirmLDAPGroupRemoval(rowData),
                  },
                  {
                    label: 'Save',
                    icon: 'fas fa-save',
                    disabled: ({ rowData }) => rowData.project_role === rowData.originRoleId,
                    visible: () => !!this.permissions[this.SCOPE.ORGANIZATION][this.USER_ROLE.ADMIN],
                    command: ({ rowData }: any) => this.updateLDAPGroupLinkRole(rowData),
                  },
                ];
                this.handleFilterObjUpdate();
                this.getLDAPGroupLinks();
              });
            } else {
              this.navigateToNotAuthorizedPage();
            }
          }
        }),
      )
      .subscribe({
        next: (data: any) => {
          this.LDAPSetting = data;
        },
        error: (err: any) => {
          this.navigateToNotAuthorizedPage();
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Handle filters
   */
  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values.search || '';
      }
    });
  }

  /**
   * Get LDAP group links
   */
  getLDAPGroupLinks() {
    this.isLoading = true;
    this.values = [];
    this.ldapSrv
      .getLDAPGroupLinks(this.breadcrumbConfig?.organizationId as number)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any[]) => {
          this.values = this.util.sortObjectArray(rs, 'group_dn').map((p) => ({
            ...p,
            originRoleId: p.project_role,
          }));
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Open LDAP group link form
   */
  openLDAPGroupLinkForm() {
    const dialog = this.dialogSrv.open(LDAPGroupFormComponent, {
      header: 'Link LDAP Group',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getLDAPGroupLinks();
      }
    });
  }

  /**
   * Update LDAP group link role
   * @param ldapGroup
   */
  updateLDAPGroupLinkRole(ldapGroup: any) {
    this.isLoading = true;
    this.ldapSrv
      .updateLDAGroupLink(ldapGroup.id, {
        ldap_group: ldapGroup.ldap_group,
        organization: ldapGroup.organization,
        project_role: ldapGroup.project_role,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Updated link for LDAP group ${ldapGroup.group_dn} successfully`);
          this.getLDAPGroupLinks();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Confirm LDAP group removal
   * @param ldapGroup
   */
  confirmLDAPGroupRemoval(ldapGroup: any) {
    this.confirm({
      action: REMOVE_LABEL,
      objectName: 'LDAP Group Link',
      object: ldapGroup,
      objectFieldName: 'group_dn',
      acceptRequest: this.ldapSrv.deleteLDAGroupLink(ldapGroup.id),
      next: () => {
        this.showSuccessMessage(`Deleted link for LDAP group ${ldapGroup.group_dn} successfully`);
        this.getLDAPGroupLinks();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Get roles
   * @returns
   */
  getRoles() {
    const obs = new Observable((observe) => {
      this.isLoading = true;
      this.roleSrv
        .getOrganizationRoles(this.breadcrumbConfig?.organizationId)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            observe.next();
          }),
        )
        .subscribe({
          next: (rs: any[]) => {
            const roles = rs.map((role) => ({
              value: role.id,
              label: role.name,
              level: role.level,
            }));
            this.roles = this.util.sortObjectArray(roles, 'level', false);
          },
          error: (err: any) => {
            this.showErrorMessage(err);
          },
        });
    });
    return obs;
  }
}
