<microsec-layout-table
  itemName="Keyring"
  [(selectedItem)]="selectedKeyring"
  (getItemsEvent)="getKeyrings()"
  [shouldShowCreateButton]="true"
  (openFormEvent)="openKeyringForm()"
  [cols]="selectedCols || []"
  [values]="values"
  [isLoading]="isLoading"
  [cellTemplate]="cellTemplate"
  [actionMenuItems]="actionMenuItems"
  [detailsTemplate]="detailsTemplate" />

<ng-template #detailsTemplate>
  <app-keyring-info
    [(selectedKeyring)]="selectedKeyring"
    [keyPurposeAlgorithm]="keyPurposeAlgorithm"
    (openKeyringFormEvent)="openKeyringForm($event)"
    (confirmDeletionEvent)="confirmDeletion($event)" />
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Type -->
    @case ('type') {
      <microsec-truncate-text>
        {{ rowData[col.field] | constant: 'kms-keyring-type' }}
      </microsec-truncate-text>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
